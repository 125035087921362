import { fade, createTheme } from '@material-ui/core/styles';
import primary from '@material-ui/core/colors/indigo';
import secondary from '@material-ui/core/colors/amber';
import error from '@material-ui/core/colors/red';
import { constants } from 'assets/Config.js';


const defaultTheme = createTheme();

const themes = {
    origins: {
        palette: {
            type: "light",
            text: {
                primary: "#212529",
                secondary: "#212529",
                
            }, 
            primary: {
                  light: '#396183',
                  main: '#133959',
                  dark: '#16456d',
                  hover: '#507492',
                  contrastText: '#fff',
            },
            secondary: secondary,
            error: error,
            
            // Used by `getContrastText()` to maximize the contrast between the background and
            // the text.
            contrastThreshold: 3,
            // Used to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
        },
        shape: {
            borderRadius: 2
        },
       
        
        overrides: {
            MuiExpansionPanelDetails : {
               root: {
                    background: primary['100'],
               },
            },
            
            MuiExpansionPanelSummary : {
                root: {
                    background: primary['700'],
                    color: defaultTheme.palette.getContrastText(primary['700']), 
        
                },
            },
            MuiIcon: {
                root: {
                    color: "#ffffff",
                },
                colorPrimary: {
                    color: "#ffffff",
                }
            },
            MuiListItemIcon: {
                root: {
                    color: "#212529",
                    colorPrimary: "orange",
                }
            }, 
            MuiCardContent: {
                root: {
                    color: "#212529",
                },
            },
            
         },
        custom: {
            DrawerNavMenu: {
                width: 320
            },
            platformCloudbar: {
                width: constants.cloudbar?64:0
            }
        }
    },
    dartce: {
         palette: {
            type: "light",
            text: {
                primary: "#002e54",
                secondary: "#002e54"
            }, 
            primary: {
                  light: '#c0d6ed',
                  main: '#aecae7',
                  dark: '#98b6d5',
                  hover: '#deeeff',
                  contrastText: '#002e54',
            },
            secondary: secondary,
            error: error,
            
            // Used by `getContrastText()` to maximize the contrast between the background and
            // the text.
            contrastThreshold: 3,
            // Used to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
        },
        shape: {
            borderRadius: 2
        },
       
        
        overrides: {
            MuiExpansionPanelDetails : {
               root: {
                    background: primary['100'],
               },
            },
            
            MuiExpansionPanelSummary : {
                root: {
                    background: primary['700'],
                    color: defaultTheme.palette.getContrastText(primary['700']), 
        
                },
            },
            MuiIcon: {
                root: {
                    color: "#002e54",
                },
                colorPrimary: {
                    color: "#002e54",
                }
            },
            MuiListItemIcon: {
                root: {
                    color: "#212529",
                    colorPrimary: "orange",
                }
            }, 
            MuiCardContent: {
                root: {
                    color: "#212529",
                },
            },
            
         },
        custom: {
            DrawerNavMenu: {
                width: 320
            },
            platformCloudbar: {
               width: constants.cloudbar?64:0
            }
        
        }
    }
}
/*
const originsTheme = createMuiTheme({
    palette: {
        type: "light",
        text: {
            primary: "#212529",
            secondary: "#212529"
        }, 
        primary: {
              light: '#77b0e2',
              main: '#133959',
              dark: '#16456d',
              contrastText: '#fff',
        },
        secondary: secondary,
        error: error,
        
        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        contrastThreshold: 3,
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    shape: {
        borderRadius: 2
    },
   
    
    overrides: {
        MuiExpansionPanelDetails : {
           root: {
                background: primary['100'],
           },
        },
        
        MuiExpansionPanelSummary : {
            root: {
                background: primary['700'],
                color: defaultTheme.palette.getContrastText(primary['700']), 
    
            },
        },
        MuiIcon: {
            root: {
                color: "#ffffff",
            },
            colorPrimary: {
                color: "#ffffff",
            }
        },
        MuiListItemIcon: {
            root: {
                color: "#212529",
                colorPrimary: "orange",
            }
        }, 
        MuiCardContent: {
            root: {
                color: "#212529",
            },
        },
        
     },
    custom: {
        DrawerNavMenu: {
            width: 320
        },
        platformCloudbar: {
            width: 64
        }
    }
});

const dartceTheme = createMuiTheme({
    palette: {
        type: "light",
        text: {
            primary: "#002e54",
            secondary: "#002e54"
        }, 
        primary: {
              light: '#deeeff',
              main: '#deeeff',
              dark: '#deeeff',
              contrastText: '#002e54',
        },
        secondary: secondary,
        error: error,
        
        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        contrastThreshold: 3,
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    shape: {
        borderRadius: 2
    },
   
    
    overrides: {
        MuiExpansionPanelDetails : {
           root: {
                background: primary['100'],
           },
        },
        
        MuiExpansionPanelSummary : {
            root: {
                background: primary['700'],
                color: defaultTheme.palette.getContrastText(primary['700']), 
    
            },
        },
        MuiIcon: {
            root: {
                color: "#002e54",
            },
            colorPrimary: {
                color: "#002e54",
            }
        },
        MuiListItemIcon: {
            root: {
                color: "#212529",
                colorPrimary: "orange",
            }
        }, 
        MuiCardContent: {
            root: {
                color: "#212529",
            },
        },
        
     },
    custom: {
        DrawerNavMenu: {
            width: 320
        },
        platformCloudbar: {
            width: 64
        }
    }
});

const darkTheme = createMuiTheme({
    palette: {
        type: "dark",
        text: {
            primary: "#ffffff",
            secondary: "#ffffff"
        }, 
        primary: {
              light: '#77b0e2',
              main: '#2a7fc9',
              dark: '#001429',
              contrastText: '#fff',
        },
        secondary: secondary,
        error: error,

        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        contrastThreshold: 3,
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    shape: {
        borderRadius: 6
    },
    
    overrides: {
        MuiExpansionPanelDetails : {
           root: {
                background: primary['100'],
           },
        },
        
        MuiExpansionPanelSummary : {
            root: {
                background: primary['700'],
                color: defaultTheme.palette.getContrastText(primary['700']), 
    
            },
        },
        MuiListItemIcon: {
            root: {
                color: "#ffffff",
            },
        }, 
        MuiCardContent: {
            root: {
                color: "#ffffff",
            },
        }, 
     },
    
});
*/
const baseTheme = createTheme(themes[constants.theme]);

export { baseTheme  }
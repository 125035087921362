/**
 * Operational view for viewing and editing the reports system and menu
 */

import React, {useContext} from 'react';
import { Logger } from 'aws-amplify';
import AppContext               from 'pac-responsive-ui-framework/core/AppContext';
import View from 'pac-responsive-ui-framework/core/View';
import Typography from '@material-ui/core/Typography';

import AGGrid from '../components/AGGrid';

import Service from '../services/DataFilterService';


const logger = new Logger('OpsDataFilter');

export default function OpsDataFilter(props) {
    logger.debug(props);
    const [footer, setFooter] = React.useState(null);
    const [key, setKey] = React.useState(0);
    var app_context = useContext(AppContext);

    const service = new Service();
    //service.setPermissions(app_context.state.user.session.acl_role);

    /*
    if (props.recordID !== "origins" && props.recordID !== "dartce") {
        logger.debug("invalid Record ID", props.recordID);
        return (<Home/>);
    }
    */



    var header = <Typography variant="h5">Data Filters</Typography>;

    return (
        <View title={props.title} fixedContent={true} key={key}>
            <AGGrid
                service={service}
                //recordID={props.recordID}
                columnDefs={service.getColumnDefs()}
                filename="user_list"
                header={header}
            />
       </View>
    );
}



export default function GCSExclusionPaddingRenderer() {
}

GCSExclusionPaddingRenderer.prototype.init = function (params) {
    this.eGui = document.createElement('span');
    var result = "";
    if (Number.isInteger(params.data['TAKEOFFPADDING']) 
            || Number.isInteger(params.data['LANDINGPADDING'])
            || Number.isInteger(params.data['TAKEOFFPADDINGINT'])
            || Number.isInteger(params.data['LANDINGPADDINGINT'])
            || Number.isInteger(params.data['FLEXIPADSKIPPED'])
            
    ) {
        if (Number.isInteger(params.data['TAKEOFFPADDING'])) result = params.data['TAKEOFFPADDING'] + ", ";
        else  result = "-, ";
        if (Number.isInteger(params.data['LANDINGPADDING'])) result += params.data['LANDINGPADDING'] + ", ";
        else  result += "-, ";
        if (Number.isInteger(params.data['TAKEOFFPADDINGINT'])) result += params.data['TAKEOFFPADDINGINT'] + ", ";
        else  result += "-, ";
        if (Number.isInteger(params.data['LANDINGPADDINGINT'])) result += params.data['LANDINGPADDINGINT'] + ", ";
        else  result += "-, ";
        if (Number.isInteger(params.data['FLEXIPADSKIPPED'])) result += params.data['FLEXIPADSKIPPED'];
        else  result += "0";
    }
    this.eGui.innerHTML = result;
    
};

GCSExclusionPaddingRenderer.prototype.getGui = function () {
    return this.eGui;
};


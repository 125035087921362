/**
 *  See https://react-leaflet.js.org/docs/start-introduction
 *      https://geojson.io/ (build your own map)
 *      https://en.wikipedia.org/wiki/GeoJSON
 *      https://datatracker.ietf.org/doc/html/rfc7946
 *      https://leaflet-extras.github.io/leaflet-providers/preview/ (geotiles)
 */ 
import React, {useState, useEffect} from 'react';
 
import { Logger } from 'aws-amplify';
//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
//import { ThemeProvider } from '@material-ui/styles';
//import { baseTheme } from 'pac-responsive-ui-framework/themes/Main';
import 'leaflet/dist/leaflet.css';
import {
  Polygon, Polyline, Marker, CircleMarker, Circle,
  MapContainer, TileLayer, Popup, Tooltip, 
  LayersControl, LayerGroup, GeoJSON, SVGOverlay, useMap, text } from 'react-leaflet'
import L from 'leaflet';


const logger = new Logger('component/Map');


L.Icon.Default.imagePath='leaflet/images/';


var IconDeparture = new L.Icon({
  iconUrl: '/leaflet/images/marker_takeoff.png',
  //shadowUrl: '/leaflet/images/marker-shadow.png',
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -3],
  //shadowSize: [41, 41]
});

var IconArrival = new L.Icon({
  iconUrl: '/leaflet/images/marker_landing.png',
  //shadowUrl: '/leaflet/images/marker-shadow.png',
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -3],
  //shadowSize: [41, 41]
});

var IconDepartureArrival = new L.Icon({
  iconUrl: '/leaflet/images/marker_takeoff_landing.png',
  //shadowUrl: '/leaflet/images/marker-shadow.png',
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -3],
  //shadowSize: [41, 41]
});

/**
 * props.embedded = true - simplifies the screen, sets the bg to white, and no logo
 */
export default function Map(props) {
  logger.debug("props", props);
  var counter = 0;
  const [map, setMap] = useState(null);
  
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the list of layers to put a separator between the layers and flights
  
    var manual_separator = document.getElementById("manual_separator");
    if (!manual_separator) {
      var x = document.getElementsByClassName("leaflet-control-layers-selector");
      if (x.length > 3) {
        console.log("useEffect", x);
        var elem = document.createElement('div');
        elem.className = 'leaflet-control-layers-separator';
        elem.id="manual_separator";
        //const newContent = document.createTextNode("Hi there and greetings!");
        //elem.appendChild(newContent);
        x[x.length -3].parentNode.insertBefore(elem, x[x.length -3]);
      }
    }
  });
  
  var bounds_array = [];

  var feature_collection_name;
  
  const parseData = (data, _i) => {
    logger.debug("parseData", data);
    
    if (!data) return;
    
    if (Array.isArray(data)) {
      var results = [];
      for(var i = 0; i < data.length; i++) {
        var r = parseData(data[i], i);
        if (r) {
          results.push(r);
        }
      }
      logger.debug("results", results);
      return results;
    }
    else {
   
      
      if (data['type'] === "FeatureCollection") {
        logger.debug("featureCollection", data);
        if (typeof data['properties'] !== "undefined") { 
          if (typeof data['properties']['name'] !== "undefined") {
            logger.debug("found FeatureCollection with name", data['properties'], data['features']);
            feature_collection_name = data['properties']['name'];
            var checked = true;
            if (data['properties']['checked'] == false) {
              checked = false;
            }
            var key = 'polyline_' + _i;
            if (data['properties']['key']) {
              key = data['properties']['key'];
            }
            logger.debug('FeatureCollection key', key);
            
            return ( 
              <LayersControl.Overlay 
                  key={key}
                  checked={checked} 
                  name={data['properties']['name']}
              >
                <LayerGroup options={data['properties']} >
                  {parseData(data['features'], _i)}
                </LayerGroup>
              </LayersControl.Overlay>
            );
          }
        }
      }
      else if (data['type'] === 'Feature' || data['type'] === 'GeometryCollection') {
        logger.debug("found " + data['type']);
        var options;
        var tooltip;
        var popup;
        var properties;
        
        if (typeof data['properties'] !== "undefined") {
          logger.debug("data['properties'] data", data);
          options = getOptions(data['properties']);
          tooltip = getTooltip(data['properties']['name']);
          popup = getPopup(data['properties']['details'], data['properties']['name']);
          properties = data['properties'];
          
          if (data['properties']['center']) {
            bounds_array.push(data['geometry']['coordinates']);
          }
        }
        
        if (typeof data['geometry'] !== "undefined") {
          return parseGeometryType(data['geometry'], options, tooltip, popup, properties);
        }
        else if (typeof data['geometries'] !== "undefined") {
          var results = [];
          for(var j = 0; j < data['geometries'].length; j++) {
            results.push(parseGeometryType(data['geometries'][j], options, tooltip, popup, properties));
          }
          return results;
        }
      }
      else {
       
        return parseGeometryType(
          data, 
          options, 
          tooltip,
          popup,
          properties
        );
      }
    }
    return null;
  };
  

  
  const getTooltip = (name) => {
    if (typeof name === "string") {
      name = <div dangerouslySetInnerHTML={{__html: name}} />;
      return <Tooltip>{name}</Tooltip>;
    }
  };
  
  const getPopup = (details, name = null) => {
    
    if (typeof details !== "undefined") {
      if (details === name) {
        return (
          <Popup>
            <p><b>{name}</b></p>
          </Popup>
        ) 
      }
      details = <span dangerouslySetInnerHTML={{__html: details}} />;
      return (
        <Popup>
          <p>{details}</p>
        </Popup>
      )
      
    }
    return null;
  }
  
  const getOptions = (properties) => {
    var options = {};

    if (typeof properties['stroke'] !== "undefined") {
      options['color'] = properties['stroke']
    }
    if (typeof properties['stroke-width'] !== "undefined") {
      options['weight'] = properties['stroke-width'];
    }
    if (typeof properties['stroke-opacity'] !== "undefined") {
      options['opacity'] = properties['stroke-opacity'];
    }
    if (typeof properties['fill'] !== "undefined") {
      options['fillColor'] = properties['fill'];
    }
    if (typeof properties['fill-opacity'] !== "undefined") {
      options['fillOpacity'] = properties['fill-opacity'];
    }
    if (typeof properties['radius'] !== "undefined") {
      options['radius'] = properties['radius'];
    }
    if (typeof properties['icon'] !== "undefined") {
      options['icon'] = properties['icon'];
    }
    if (typeof properties['ref-id'] !== "undefined") {
      options['refId'] = properties['ref-id'];
    }
    if (typeof properties['z-layer'] !== "undefined") {
      options['zLayer'] = properties['z-layer'];
    }
    
    
    return options;
  }
  
  /**
   * Passing options, tooltip, popup only because they are created at the higher level 
   * so not recreated at every compoent
   */ 
  const parseGeometryType = (data, options, tooltip, popup, properties) => {
    counter++;
    if (Array.isArray(data)) {
      logger.debug("parseGeometry array1", data);
      var result = [];
      for (var i = 0; i < data.length; i++) {
        result.push(parseGeometryType(data[i], options, tooltip, popup, properties ));
      }
      return result;      
    }
    
    var type = data['type'];
    var coordinates = data['coordinates'];
    var eventHandlers = null;
    if (typeof properties['mouseover'] !== "undefined") {
      eventHandlers = {};
      eventHandlers['mouseover'] = (event) => {
        logger.debug("mouseover", event);
        
        var layer = event.target;
        layer.setStyle({
          weight: 5
        });
        return true;
      };
      eventHandlers['mouseout'] = (event) => {
        logger.debug("mouseout", event);
        
        var layer = event.target;
        layer.setStyle({
          weight: 2
        });
        return true;
      };
    }
    logger.debug("parseGeometryType type", type);
    if (type === 'Polygon') {
      logger.debug("found Polygon");
      var positions = [];
      // this is only to flip long and lat values
      if (coordinates) {
        for (var j = 0; j < coordinates.length; j++) {
          positions[j] = [];
          for (var k = 0; k < coordinates[j].length; k++) {
            positions[j].push([coordinates[j][k][1], coordinates[j][k][0]]);
          }
        }
      }
      logger.debug("polygon points", positions, options);
      return <Polygon key={'polygon_' + counter} pathOptions={options} positions={positions} eventHandlers={eventHandlers}>{popup}{tooltip}</Polygon>;
    }
    else if (type === 'LineString') {
      logger.debug("found LineString", coordinates[0]);
      positions =[];
      // this is only to flip long and lat values
      for (var k = 0; k < coordinates.length; k++) {
        positions.push([coordinates[k][1], coordinates[k][0]]);
      }
      tooltip = getTooltip(feature_collection_name);
      if (eventHandlers)
        return <Polyline key={'polyline_' + counter} pathOptions={options} positions={positions} eventHandlers={eventHandlers} >{popup}{tooltip}</Polyline>;
      else 
        return <Polyline key={'polyline_' + counter} pathOptions={options} positions={positions} >{popup}{tooltip}</Polyline>;
      
    }
    else if (type === 'Point') {
      logger.debug("found Point", options);
      var icon = null;
      if (typeof options['icon'] !== "undefined") { 
       if (options['icon'] === "departure") {
          icon = IconDeparture;
        }
        else  if (options['icon'] === "arrival") {
          icon = IconArrival;
        }
         else  if (options['icon'] === "departure_arrival") {
          icon = IconDepartureArrival;
        }
      }
      if (icon) {
        return <Marker key={'marker' + counter} position={[coordinates[1], coordinates[0]]} icon={icon}>
          {popup}
          {tooltip}
        </Marker>;
      }
      else {
        return <Marker position={[coordinates[1], coordinates[0]]}>
          {popup}
          {tooltip}
        </Marker>;
      }
    }
    else if (type === 'Circle') {
      logger.debug("found Circle");
      if (!data['radius'])  data['radius'] = 6;
      return <Circle center={[coordinates[1], coordinates[0]]} radius={data['radius']} pathOptions={options}>
        {popup}
        {tooltip}
      </Circle>;
    }
    else if (type === 'CircleMarker') {
      logger.debug("found Circle");
     
      
      return <CircleMarker key={'circlemarker_' + counter} center={[coordinates[1], coordinates[0]]} radius={data['radius']} pathOptions={options}>
        {popup}
        {tooltip}
      </CircleMarker>;
    }
    else if (type === 'MultiPoint') {
      logger.debug("found MultiPoint");
      var results = [];
      for (var k = 0; k < coordinates.length; k++) {
        results.push(parseGeometryType({type: "Point", coordinates: coordinates[k]}, options, tooltip, popup, properties));
      }
      return results;
    }
    else if (type === 'MultiLineString') {
      logger.debug("found MultiLineString");
      var results = [];
      tooltip = getTooltip(feature_collection_name);
      
      for (var k = 0; k < coordinates.length; k++) {
        results.push(parseGeometryType({type: "LineString", coordinates: coordinates[k]}, options, tooltip, popup, properties));
      }
      return results;
    }
    else if (type === 'MultiPolygon') {
      logger.debug("found MultiPolygon");
      var results = [];
      for (var k = 0; k < coordinates.length; k++) {
        results.push(parseGeometryType({type:"Polygon", coordinates: coordinates[k]}, options, tooltip, popup, properties));
      }
      return results;
    }
  }
  
  /**
   * UNUSED
   * 
   * The built in GeoJSON function
   * This does work but without meta data
   * 
   */
   /*
  const getGeoJson = (data) => {
    return <GeoJSON key="geojsonkey" data={data} />
  }
  */
  
  // data loading
  //var map_data = require('../data/DataMap.json');
  var geodata = props.data;
  
  logger.debug(geodata);
  //var c_lat = 0;
  //var c_lng = 0;
  
  var parsedData;
  var bounds;
  if (geodata) {
    /*
    parsedData = 
      <>
              <LayersControl.Overlay 
                  key="select_all"
                  checked={true} 
                  name="Select All"
              >
                <LayerGroup>
                </LayerGroup>
              </LayersControl.Overlay>
          {parseData(geodata)}
          </>;
    */
    parsedData = parseData(geodata);
    bounds = new L.LatLngBounds(bounds_array);
    map.fitBounds([
        [bounds['_northEast']['lng'], bounds['_northEast']['lat']],
        [bounds['_southWest']['lng'], bounds['_southWest']['lat']]
      ]);
    
    
    
    /*
    map.eachLayer( function(layer)  {
        logger.debug("layer0", layer.options.zLayer, layer);
        if (layer.options.zLayer === 0) {
          layer.bringToBack();
        }
        if (layer.options.zLayer === 5) {
          layer.bringToFront();
        }
    });
    */
   
    /**
     * This takes the z-order and reorders the layers.
     * This needs to be run at the load time and whenever a layer is hidden and reshown
     */
    const orderLayers = (map) => {
      var new_layer_order = {};
      map.eachLayer( function(layer)  {
        if (!new_layer_order[layer.options.zLayer]) {
          new_layer_order[layer.options.zLayer] = [];
        }
        new_layer_order[layer.options.zLayer].push(layer);
      });
      Object.keys(new_layer_order)
      .sort()
      .forEach(function(v, i) {
        if (v !== "undefined") {
          new_layer_order[v].forEach((layer) => {
            layer.bringToFront();
          });
        }
      });
    };
      
    orderLayers(map);
    
    map.on('overlayadd',  (e) => {
      logger.debug("overlayadd hidden", map, e);
      if (e.layer.options.options.flightSet === true) { // this is set in the MapService class 
        
        // check if any of the ids are hidden.  
        var visible_ids = []
        map.eachLayer( function(layer)  {
          if (layer.options.options && layer.options.options.id) {
            visible_ids.push(layer.options.options.id);
          }
        });
        logger.debug("overlayadd ids", visible_ids );
        
        var  reverse_array = [];
        
        // reverse the list of layered elements and remove any elements where the flight is hidden 
        Object.entries(e.layer._layers).forEach(([key, value]) => {
          //logger.debug("overlayadd layers", value.options.refId );
          if (visible_ids.indexOf(value.options.refId) != -1) {
            reverse_array.unshift(value);
          }
          else {
            value.remove();
          }
        });
        
        Object.entries(e.layer._layers).forEach(([key, value]) => {
          logger.debug("overlayadd layers", value.options.refId );
        });
        
        // Reset the z-index of the layers
        logger.debug("reverse array", reverse_array);
        Object.entries(reverse_array).forEach(([key, value]) => {
          
          //value.bringToBack();
        });
      }
      
      // show any layers associated with an id
      if (e.layer.options.options.id) {
        logger.debug("overlayadd 1", map);
        map.eachLayer( function(layer) {
          //logger.debug("overlayadd 2", layer);
          if (layer._layers) { // have to dig into the sublayers
          
            var  reverse_array = [];
            Object.entries(layer._layers).forEach(([key, value]) => {
              if (value.options.refId == e.layer.options.options.id) {
                //logger.debug("overlayadd", "found");
                value.addTo(map);
                reverse_array.unshift(value);
                
              }
            });
            // move each element to the back 
            Object.entries(reverse_array).forEach(([key, value]) => {
          
              //value.bringToBack();
            });
          }
         
         });
       }
       
       orderLayers(map);
      /*       
      // attempt to set the z index 
      var z_layers = [];
      map.eachLayer( function(layer) {
        if (typeof layer.options.zLayer !== "undefined") { 
          if (typeof z_layers[layer.options.zLayer] === 'undefined') {
            z_layers[layer.options.zLayer] = [];
          }
          logger.debug("Layer 1", layer, layer.options.zLayer, layer.options.options);
          z_layers[layer.options.zLayer].push(layer);
        }
      });
      z_layers.forEach(layers => {
        layers.forEach(l => {
         // l.bringToFront();
        });
      });
      logger.debug("Layer 2", z_layers);
       
      
      //logger.debug("reverse_array", reverse_array);
       
      //e.layer.bringToBack();
      */
    });
    
    // Remove any elements that have a refId which matches the flight ID
     map.on('overlayremove',  (e) => {
        // hide any layers associated with an id
       if (e.layer.options.options.id) {
         map.eachLayer( function(layer) {
          if (layer.options.refId == e.layer.options.options.id) {
            layer.remove();
          }
         });
       }
     });
  }
  
  
  

  
  return (
    <React.Fragment>
    <MapContainer  whenCreated={setMap} center={[0,0]} zoom={2} worldCopyJump={false} scrollWheelZoom={false} style={{ height: "100%", marginTop:"64px" }}>
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="Flat View">
          <TileLayer
            attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}{r}.png?api_key=87a9dd2a-d5c5-4446-9517-908c7c938ded"
            minZoom="0"
	          maxZoom="20"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Terrain View">
          <TileLayer
            attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png?api_key=87a9dd2a-d5c5-4446-9517-908c7c938ded"
            minZoom="0"
	          maxZoom="18"
          />
        </LayersControl.BaseLayer>
        {parsedData}
      </LayersControl>
    </MapContainer>
    <div id='message_text' style={{
        zIndex:400, 
        position: 'fixed', 
        top: '72px', 
        left: '64px', 
        width: '600px', 
        //border: '3px solid #73AD21', 
        //backgroundColor: 'white',
        fontSize: 24
    }} dangerouslySetInnerHTML={{__html: props.message}} ></div>
    { 
    <div id='message_text' style={{
        zIndex:400, 
        position: 'fixed', 
        bottom: '8px', 
        left: '12px', 
        //width: '600px', 
        border: '2px solid #aeaeae', 
        backgroundColor: 'white',
        fontSize: 24,
        borderRadius: 4,
    }}><img width="120px" src={require("../assets/map-legend.png")} /></div>
     }
    {props.overlay}
    </React.Fragment>
  );
}
import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import CloseIcon from '@material-ui/icons/Close';
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import DrawerTheme          from '../themes/Drawer';

import AppContext           from './AppContext';

const useStyles = makeStyles((theme) => ({
 
  drawer: {
    width: theme.custom.DrawerNavMenu.width,
    [theme.breakpoints.up('sm')]: {
      width: theme.custom.DrawerNavMenu.width + theme.custom.platformCloudbar.width,
    },
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.custom.DrawerNavMenu.width,
    [theme.breakpoints.up('sm')]: {
      width: theme.custom.DrawerNavMenu.width + theme.custom.platformCloudbar.width,
    },
    backgroundColor: "#f2f4f6",
    
  },
  header_text: {
    paddingLeft: 16,
    paddingTop: 20,
    paddingBottom: 32,
    fontFamily: "Inter, sans-serif",
    fontWeight: "bold",
    fontSize: "20px",
    
  },
  close_button: {
    marginTop: 20,
    maxWidth: '36px', maxHeight: '36px', minWidth: '36px', minHeight: '36px',
    
  },
  drawerHeader: {
    //display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.custom.platformCloudbar.width,
     }
     /*
    "&:after": {
      position: 'absolute',
      content: '""',
      top: 62,
      left: 88,
      width: 26,
      height: 2,
      bottom: '0px',
      background: "rgb(0, 0, 0)",
      borderBottom: '3px solid rgb(42, 127, 201)'
    },
    */
  },
  drawerContent: {
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.custom.platformCloudbar.width,
    },
    //paddingTop: "8px", 
    paddingLeft: "8px",
    height: "100%",
    overflow: "auto"
  }
}));

export default function DrawerNavMenu(props) {
  const app_context   = useContext(AppContext);  
  const classes = useStyles();
  const theme = useTheme();
  return (
      <DrawerTheme>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={props.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
       
      
        <div className={classes.drawerHeader}>
          <Grid
            justifyContent="space-between" 
            container 
            spacing={0}
          >
            <Grid item>
              <Typography variant="h4" className={classes.header_text}>
                {app_context.state.title}
              </Typography>
            </Grid>
      
            <Grid item>
               <Tooltip title="Close Menu">
                 <Button 
                    onClick={props.handleDrawerClose} 
                    className={classes.close_button} 
                    size="small">
                    <CloseIcon />
                  </Button>
                </Tooltip>
            </Grid>
          </Grid>
          
         
        </div>
        <div className={classes.drawerContent}>
            {props.children}
        </div>
      </Drawer>
      </DrawerTheme>     
      
  );
}

export default
[
{ operator_icao_code: "A2A", registration_name: "VQ-BSK", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "1201" },
{ operator_icao_code: "AAL", registration_name: "N906AN", ship_number: "3AF", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N909AN", ship_number: "3AJ", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N932AN", ship_number: "3BJ", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N963AN", ship_number: "3CU", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N967AN", ship_number: "3CY", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N771AN", ship_number: "7AB", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50755" },
{ operator_icao_code: "AAL", registration_name: "N931AN", ship_number: "3BH", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N935AN", ship_number: "3BM", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N948AN", ship_number: "3CC", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N966AN", ship_number: "3CX", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N970AN", ship_number: "3DC", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N974AN", ship_number: "3DG", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N976AN", ship_number: "3DJ", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N962AN", ship_number: "3CT", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N879NN", ship_number: "3HM", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N928AN", ship_number: "3BE", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N929AN", ship_number: "3BF", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N943AN", ship_number: "3BW", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N965AN", ship_number: "3CW", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N964AN", ship_number: "3CV", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N934AN", ship_number: "3BL", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N971AN", ship_number: "3DD", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N975AN", ship_number: "3DH", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N973AN", ship_number: "3DF", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N972AN", ship_number: "3DE", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N969AN", ship_number: "3DB", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N968AN", ship_number: "3DA", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N939AN", ship_number: "3BS", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N938AN", ship_number: "3BR", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N937AN", ship_number: "3BP", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N936AN", ship_number: "3BN", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N953AN", ship_number: "3CH", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N952AA", ship_number: "3CG", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N978AN", ship_number: "3DL", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N930AN", ship_number: "3BG", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N933AN", ship_number: "3BK", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N944AN", ship_number: "3BX", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N951AA", ship_number: "3CF", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N950AN", ship_number: "3CE", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N949AN", ship_number: "3CD", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N947AN", ship_number: "3CB", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N946AN", ship_number: "3CA", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N945AN", ship_number: "3BY", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N961AN", ship_number: "3CS", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N960AN", ship_number: "3CR", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N959AN", ship_number: "3CP", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N958AN", ship_number: "3CN", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N957AN", ship_number: "3CM", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N956AN", ship_number: "3CL", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N955AN", ship_number: "3CK", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N954AN", ship_number: "3CJ", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N942AN", ship_number: "3BV", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N941AN", ship_number: "3BU", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N940AN", ship_number: "3BT", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N676AN", ship_number: "5EH", type_subtype: "757-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N678AN", ship_number: "5EK", type_subtype: "757-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N783AN", ship_number: "7AP", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50757" },
{ operator_icao_code: "AAL", registration_name: "N782AN", ship_number: "7AN", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50783" },
{ operator_icao_code: "AAL", registration_name: "N781AN", ship_number: "7AM", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50781" },
{ operator_icao_code: "AAL", registration_name: "N780AN", ship_number: "7AL", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50762" },
{ operator_icao_code: "AAL", registration_name: "N779AN", ship_number: "7AK", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50785" },
{ operator_icao_code: "AAL", registration_name: "N778AN", ship_number: "7AJ", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50772" },
{ operator_icao_code: "AAL", registration_name: "N777AN", ship_number: "7AH", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50768" },
{ operator_icao_code: "AAL", registration_name: "N789AN", ship_number: "7AW", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50753" },
{ operator_icao_code: "AAL", registration_name: "N793AN", ship_number: "7BB", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50743" },
{ operator_icao_code: "AAL", registration_name: "N792AN", ship_number: "7BA", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50776" },
{ operator_icao_code: "AAL", registration_name: "N791AN", ship_number: "7AY", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50749" },
{ operator_icao_code: "AAL", registration_name: "N790AN", ship_number: "7AX", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50687" },
{ operator_icao_code: "AAL", registration_name: "N784AN", ship_number: "7AR", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50771" },
{ operator_icao_code: "AAL", registration_name: "N776AN", ship_number: "7AG", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50774" },
{ operator_icao_code: "AAL", registration_name: "N775AN", ship_number: "7AF", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50750" },
{ operator_icao_code: "AAL", registration_name: "N774AN", ship_number: "7AE", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50731" },
{ operator_icao_code: "AAL", registration_name: "N773AN", ship_number: "7AD", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50770" },
{ operator_icao_code: "AAL", registration_name: "N772AN", ship_number: "7AC", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50773" },
{ operator_icao_code: "AAL", registration_name: "N770AN", ship_number: "7AA", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50778" },
{ operator_icao_code: "AAL", registration_name: "N786AN", ship_number: "7AT", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50703" },
{ operator_icao_code: "AAL", registration_name: "N785AN", ship_number: "7AS", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50717" },
{ operator_icao_code: "AAL", registration_name: "N794AN", ship_number: "7BC", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50784" },
{ operator_icao_code: "AAL", registration_name: "N766AN", ship_number: "7CA", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50766" },
{ operator_icao_code: "AAL", registration_name: "N762AN", ship_number: "7BX", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50668" },
{ operator_icao_code: "AAL", registration_name: "N787AL", ship_number: "7AU", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50722" },
{ operator_icao_code: "AAL", registration_name: "N761AJ", ship_number: "7BW", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50720" },
{ operator_icao_code: "AAL", registration_name: "N765AN", ship_number: "7BY", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50718" },
{ operator_icao_code: "AAL", registration_name: "N788AN", ship_number: "7AV", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50787" },
{ operator_icao_code: "AAL", registration_name: "N757AN", ship_number: "7BS", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50710" },
{ operator_icao_code: "AAL", registration_name: "N758AN", ship_number: "7BT", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50764" },
{ operator_icao_code: "AAL", registration_name: "N798AN", ship_number: "7BG", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50735" },
{ operator_icao_code: "AAL", registration_name: "N901AN", ship_number: "3AA", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N902AN", ship_number: "3AB", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N903AN", ship_number: "3AC", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N904AN", ship_number: "3AD", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N907AN", ship_number: "3AG", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N908AN", ship_number: "3AH", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N910AN", ship_number: "3AK", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N912AN", ship_number: "3AL", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N913AN", ship_number: "3AM", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N915AN", ship_number: "3AP", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N917AN", ship_number: "3AS", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N918AN", ship_number: "3AT", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N919AN", ship_number: "3AU", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N920AN", ship_number: "3AV", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N921AN", ship_number: "3AW", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N922AN", ship_number: "3AX", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N923NN", ship_number: "3KJ", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N924AN", ship_number: "3BA", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N925AN", ship_number: "3BB", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N926NN", ship_number: "3KM", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N927AN", ship_number: "3BD", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N905AN", ship_number: "3AE", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N914AN", ship_number: "3AN", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N916AN", ship_number: "3AR", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N279AY", ship_number: "279", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50638" },
{ operator_icao_code: "AAL", registration_name: "N280AY", ship_number: "280", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50631" },
{ operator_icao_code: "AAL", registration_name: "N987AN", ship_number: "3DT", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N989AN", ship_number: "3DU", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N990AN", ship_number: "3DV", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N991AN", ship_number: "3DW", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N992AN", ship_number: "3DX", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N283AY", ship_number: "283", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50637" },
{ operator_icao_code: "AAL", registration_name: "N281AY", ship_number: "281", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50624" },
{ operator_icao_code: "AAL", registration_name: "N282AY", ship_number: "282", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50640" },
{ operator_icao_code: "AAL", registration_name: "N810NN", ship_number: "3EK", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N808NN", ship_number: "3EH", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N815NN", ship_number: "3ER", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N816NN", ship_number: "3ES", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N817NN", ship_number: "3ET", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N818NN", ship_number: "3EU", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N820NN", ship_number: "3EW", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N823NN", ship_number: "3FA", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N822NN", ship_number: "3EY", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N824NN", ship_number: "3FB", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N826NN", ship_number: "3FD", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N285AY", ship_number: "285", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50644" },
{ operator_icao_code: "AAL", registration_name: "N284AY", ship_number: "284", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50629" },
{ operator_icao_code: "AAL", registration_name: "N821NN", ship_number: "3EX", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N827NN", ship_number: "3FE", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N829NN", ship_number: "3FF", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N830NN", ship_number: "3FG", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N831NN", ship_number: "3FH", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N832NN", ship_number: "3FJ", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N833NN", ship_number: "3FK", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N835NN", ship_number: "3FM", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N836NN", ship_number: "3FN", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N848NN", ship_number: "3GC", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N847NN", ship_number: "3GB", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N849NN", ship_number: "3GD", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N850NN", ship_number: "3GE", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N851NN", ship_number: "3GF", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N852NN", ship_number: "3GG", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N853NN", ship_number: "3GH", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N854NN", ship_number: "3GJ", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N855NN", ship_number: "3GK", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N856NN", ship_number: "3GL", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N857NN", ship_number: "3GM", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N858NN", ship_number: "3GN", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N859NN", ship_number: "3GP", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N860NN", ship_number: "3GR", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N865NN", ship_number: "3GW", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N862NN", ship_number: "3GT", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N863NN", ship_number: "3GU", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N864NN", ship_number: "3GV", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N834NN", ship_number: "3FL", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N837NN", ship_number: "3FP", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N839NN", ship_number: "3FS", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N840NN", ship_number: "3FT", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N841NN", ship_number: "3FU", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N842NN", ship_number: "3FV", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N843NN", ship_number: "3FW", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N844NN", ship_number: "3FX", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N845NN", ship_number: "3FY", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N846NN", ship_number: "3GA", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N868NN", ship_number: "3HA", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N867NN", ship_number: "3GY", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N861NN", ship_number: "3GS", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N869NN", ship_number: "3HB", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N871NN", ship_number: "3HC", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N872NN", ship_number: "3HD", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N870NN", ship_number: "3HE", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N874NN", ship_number: "3HF", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N873NN", ship_number: "3HG", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N875NN", ship_number: "3HH", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N876NN", ship_number: "3HJ", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N877NN", ship_number: "3HK", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N878NN", ship_number: "3HL", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N880NN", ship_number: "3HN", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N881NN", ship_number: "3HP", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N882NN", ship_number: "3HR", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N883NN", ship_number: "3HS", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N884NN", ship_number: "3HT", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N885NN", ship_number: "3HU", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N886NN", ship_number: "3HV", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N887NN", ship_number: "3HW", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N889NN", ship_number: "3HX", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N890NN", ship_number: "3HY", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N891NN", ship_number: "3JA", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N892NN", ship_number: "3JB", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N893NN", ship_number: "3JC", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N894NN", ship_number: "3JD", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N895NN", ship_number: "3JE", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N896NN", ship_number: "3JF", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N717AN", ship_number: "7LA", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50642" },
{ operator_icao_code: "AAL", registration_name: "N719AN", ship_number: "7LC", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50694" },
{ operator_icao_code: "AAL", registration_name: "N720AN", ship_number: "7LD", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50625" },
{ operator_icao_code: "AAL", registration_name: "N721AN", ship_number: "7LE", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50716" },
{ operator_icao_code: "AAL", registration_name: "N722AN", ship_number: "7LF", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50676" },
{ operator_icao_code: "AAL", registration_name: "N723AN", ship_number: "7LG", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50675" },
{ operator_icao_code: "AAL", registration_name: "N724AN", ship_number: "7LH", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50682" },
{ operator_icao_code: "AAL", registration_name: "N725AN", ship_number: "7LJ", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50641" },
{ operator_icao_code: "AAL", registration_name: "N726AN", ship_number: "7LK", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50645" },
{ operator_icao_code: "AAL", registration_name: "N898NN", ship_number: "3JH", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N899NN", ship_number: "3JJ", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N901NN", ship_number: "3JK", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N902NN", ship_number: "3JL", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N903NN", ship_number: "3JM", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N904NN", ship_number: "3JN", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N907NN", ship_number: "3JS", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N718AN", ship_number: "7LB", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50665" },
{ operator_icao_code: "AAL", registration_name: "N908NN", ship_number: "3JT", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N897NN", ship_number: "3JG", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N910NN", ship_number: "3JV", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N912NN", ship_number: "3JW", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N913NN", ship_number: "3JX", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N914NN", ship_number: "3JY", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N915NN", ship_number: "3KA", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N916NN", ship_number: "3KB", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N917NN", ship_number: "3KC", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N905NN", ship_number: "3JP", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N909NN", ship_number: "3JU", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N918NN", ship_number: "3KD", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N920NN", ship_number: "3KF", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N287AY", ship_number: "287", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50622" },
{ operator_icao_code: "AAL", registration_name: "N921NN", ship_number: "3KG", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N922NN", ship_number: "3KH", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N286AY", ship_number: "286", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50627" },
{ operator_icao_code: "AAL", registration_name: "N924NN", ship_number: "3KK", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N925NN", ship_number: "3KL", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N927NN", ship_number: "3KN", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N727AN", ship_number: "7LL", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50647" },
{ operator_icao_code: "AAL", registration_name: "N728AN", ship_number: "7LM", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50659" },
{ operator_icao_code: "AAL", registration_name: "N729AN", ship_number: "7LN", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50669" },
{ operator_icao_code: "AAL", registration_name: "N730AN", ship_number: "7LP", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50652" },
{ operator_icao_code: "AAL", registration_name: "N731AN", ship_number: "7LR", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50670" },
{ operator_icao_code: "AAL", registration_name: "N732AN", ship_number: "7LS", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50662" },
{ operator_icao_code: "AAL", registration_name: "N288AY", ship_number: "288", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50623" },
{ operator_icao_code: "AAL", registration_name: "N928NN", ship_number: "3KP", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N929NN", ship_number: "3KR", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N293AY", ship_number: "293", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50636" },
{ operator_icao_code: "AAL", registration_name: "N932NN", ship_number: "3KU", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N933NN", ship_number: "3KV", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N936NN", ship_number: "3KY", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N937NN", ship_number: "3LA", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N938NN", ship_number: "3LB", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N289AY", ship_number: "289", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50630" },
{ operator_icao_code: "AAL", registration_name: "N290AY", ship_number: "290", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50634" },
{ operator_icao_code: "AAL", registration_name: "N291AY", ship_number: "291", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50620" },
{ operator_icao_code: "AAL", registration_name: "N926AN", ship_number: "3BC", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N795AN", ship_number: "7BD", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50763" },
{ operator_icao_code: "AAL", registration_name: "N796AN", ship_number: "7BE", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50760" },
{ operator_icao_code: "AAL", registration_name: "N797AN", ship_number: "7BF", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50767" },
{ operator_icao_code: "AAL", registration_name: "N768AA", ship_number: "7CC", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50733" },
{ operator_icao_code: "AAL", registration_name: "N767AJ", ship_number: "7CB", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50693" },
{ operator_icao_code: "AAL", registration_name: "N799AN", ship_number: "7BH", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50775" },
{ operator_icao_code: "AAL", registration_name: "N750AN", ship_number: "7BJ", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50725" },
{ operator_icao_code: "AAL", registration_name: "N751AN", ship_number: "7BK", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50779" },
{ operator_icao_code: "AAL", registration_name: "N752AN", ship_number: "7BL", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50719" },
{ operator_icao_code: "AAL", registration_name: "N753AN", ship_number: "7BM", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50706" },
{ operator_icao_code: "AAL", registration_name: "N760AN", ship_number: "7BV", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50777" },
{ operator_icao_code: "AAL", registration_name: "N759AN", ship_number: "7BU", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50701" },
{ operator_icao_code: "AAL", registration_name: "N756AM", ship_number: "7BR", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50686" },
{ operator_icao_code: "AAL", registration_name: "N754AN", ship_number: "7BN", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50715" },
{ operator_icao_code: "AAL", registration_name: "N755AN", ship_number: "7BP", type_subtype: "777-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50746" },
{ operator_icao_code: "AAL", registration_name: "N292AY", ship_number: "292", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50635" },
{ operator_icao_code: "AAL", registration_name: "N800AN", ship_number: "8AA", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50617" },
{ operator_icao_code: "AAL", registration_name: "N802AN", ship_number: "8AC", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50654" },
{ operator_icao_code: "AAL", registration_name: "N803AL", ship_number: "8AD", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50657" },
{ operator_icao_code: "AAL", registration_name: "N804AN", ship_number: "8AE", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50666" },
{ operator_icao_code: "AAL", registration_name: "N810AN", ship_number: "8AL", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50619" },
{ operator_icao_code: "AAL", registration_name: "N809AA", ship_number: "8AK", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50616" },
{ operator_icao_code: "AAL", registration_name: "N808AN", ship_number: "8AJ", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50684" },
{ operator_icao_code: "AAL", registration_name: "N807AA", ship_number: "8AH", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50677" },
{ operator_icao_code: "AAL", registration_name: "N806AA", ship_number: "8AG", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50656" },
{ operator_icao_code: "AAL", registration_name: "N805AN", ship_number: "8AF", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50664" },
{ operator_icao_code: "AAL", registration_name: "N733AR", ship_number: "7LT", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50673" },
{ operator_icao_code: "AAL", registration_name: "N734AR", ship_number: "7LU", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50632" },
{ operator_icao_code: "AAL", registration_name: "N735AT", ship_number: "7LV", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50683" },
{ operator_icao_code: "AAL", registration_name: "N736AT", ship_number: "7LW", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50690" },
{ operator_icao_code: "AAL", registration_name: "N801AC", ship_number: "8AB", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50692" },
{ operator_icao_code: "AAL", registration_name: "N835AN", ship_number: "8LS", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50678" },
{ operator_icao_code: "AAL", registration_name: "N819AN", ship_number: "8AW", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50680" },
{ operator_icao_code: "AAL", registration_name: "N820AL", ship_number: "8LA", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50697" },
{ operator_icao_code: "AAL", registration_name: "N831AA", ship_number: "8LM", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50671" },
{ operator_icao_code: "AAL", registration_name: "N838AA", ship_number: "8LV", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50618" },
{ operator_icao_code: "AAL", registration_name: "N839AA", ship_number: "8LW", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50626" },
{ operator_icao_code: "AAL", registration_name: "N906NN", ship_number: "3JR", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N930NN", ship_number: "3KS", type_subtype: "737-400", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N931NN", ship_number: "3KT", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N934NN", ship_number: "3KW", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N919NN", ship_number: "3KE", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N811AB", ship_number: "8AM", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50621" },
{ operator_icao_code: "AAL", registration_name: "N812AA", ship_number: "8AN", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50628" },
{ operator_icao_code: "AAL", registration_name: "N813AN", ship_number: "8AP", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50633" },
{ operator_icao_code: "AAL", registration_name: "N814AA", ship_number: "8AR", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50639" },
{ operator_icao_code: "AAL", registration_name: "N815AA", ship_number: "8AS", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50658" },
{ operator_icao_code: "AAL", registration_name: "N816AA", ship_number: "8AT", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50691" },
{ operator_icao_code: "AAL", registration_name: "N817AN", ship_number: "8AU", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50672" },
{ operator_icao_code: "AAL", registration_name: "N818AL", ship_number: "8AV", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50688" },
{ operator_icao_code: "AAL", registration_name: "N822AN", ship_number: "8LC", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50646" },
{ operator_icao_code: "AAL", registration_name: "N821AN", ship_number: "8LB", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50689" },
{ operator_icao_code: "AAL", registration_name: "N823AN", ship_number: "8LD", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50614" },
{ operator_icao_code: "AAL", registration_name: "N824AN", ship_number: "8LE", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50643" },
{ operator_icao_code: "AAL", registration_name: "N825AA", ship_number: "8LF", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50653" },
{ operator_icao_code: "AAL", registration_name: "N923AN", ship_number: "3AY", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N866NN", ship_number: "3GX", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N827AN", ship_number: "8LH", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50661" },
{ operator_icao_code: "AAL", registration_name: "N826AN", ship_number: "8LG", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50651" },
{ operator_icao_code: "AAL", registration_name: "N828AA", ship_number: "8LJ", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50663" },
{ operator_icao_code: "AAL", registration_name: "N829AN", ship_number: "8LK", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50649" },
{ operator_icao_code: "AAL", registration_name: "N830AN", ship_number: "8LL", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50681" },
{ operator_icao_code: "AAL", registration_name: "N832AA", ship_number: "8LN", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50674" },
{ operator_icao_code: "AAL", registration_name: "N833AA", ship_number: "8LP", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50679" },
{ operator_icao_code: "AAL", registration_name: "N935NN", ship_number: "3KX", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAL", registration_name: "N834AA", ship_number: "8LR", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50650" },
{ operator_icao_code: "AAL", registration_name: "N836AA", ship_number: "8LT", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50685" },
{ operator_icao_code: "AAL", registration_name: "N837AN", ship_number: "8LU", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50615" },
{ operator_icao_code: "AAL", registration_name: "N840AN", ship_number: "8LX", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50648" },
{ operator_icao_code: "AAL", registration_name: "N841AN", ship_number: "8LY", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50667" },
{ operator_icao_code: "AAR", registration_name: "HL7635", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7516", ship_number: "", type_subtype: "767-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7506", ship_number: "", type_subtype: "767-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7515", ship_number: "", type_subtype: "767-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7514", ship_number: "", type_subtype: "767-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7418", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7428", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7700", ship_number: "", type_subtype: "777-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7732", ship_number: "", type_subtype: "777-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7736", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7739", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7740", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7528", ship_number: "", type_subtype: "767-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7754", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7756", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7755", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7741", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7746", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7747", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7775", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7792", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7793", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7791", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7794", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7795", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8256", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8257", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8258", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8254", ship_number: "", type_subtype: "777-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8265", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8259", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8267", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8277", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8278", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8279", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8282", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8284", ship_number: "", type_subtype: "777-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8286", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8293", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7625", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7626", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8004", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8018", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7634", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7248", ship_number: "", type_subtype: "767-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8038", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8039", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8059", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8060", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8071", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7640", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8074", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL7641", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8078", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55759" },
{ operator_icao_code: "AAR", registration_name: "HL8079", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55760" },
{ operator_icao_code: "AAR", registration_name: "HL7578", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55761" },
{ operator_icao_code: "AAR", registration_name: "HL7579", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55762" },
{ operator_icao_code: "AAR", registration_name: "HL7771", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55757" },
{ operator_icao_code: "AAR", registration_name: "HL8308", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55758" },
{ operator_icao_code: "AAR", registration_name: "HL8359", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55764" },
{ operator_icao_code: "AAR", registration_name: "HL8360", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55765" },
{ operator_icao_code: "AAR", registration_name: "HL8361", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55766" },
{ operator_icao_code: "AAR", registration_name: "D-AVXV", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8364", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AAR", registration_name: "HL8362", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55767" },
{ operator_icao_code: "AAR", registration_name: "HL8381", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55768" },
{ operator_icao_code: "AAR", registration_name: "HL8382", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55769" },
{ operator_icao_code: "ABB", registration_name: "OO-ABA", ship_number: "", type_subtype: "340-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ABB", registration_name: "OO-ABB", ship_number: "", type_subtype: "340-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ABB", registration_name: "OO-ABD", ship_number: "", type_subtype: "340-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ABB", registration_name: "OO-ABE", ship_number: "", type_subtype: "340-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ABY", registration_name: "A6-ATA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ABY", registration_name: "A6-ATC", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ABY", registration_name: "A6-ATB", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ABY", registration_name: "A6-ATD", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIVW", ship_number: "743", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FNNW", ship_number: "747", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIVX", ship_number: "744", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FNNQ", ship_number: "745", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FNNU", ship_number: "746", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHPQ", ship_number: "801", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHPT", ship_number: "802", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHPU", ship_number: "803", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHPV", ship_number: "804", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHPX", ship_number: "805", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHPY", ship_number: "806", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHQQ", ship_number: "807", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHQY", ship_number: "808", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FNOG", ship_number: "832", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FNOE", ship_number: "831", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FNOH", ship_number: "833", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FNOI", ship_number: "834", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FGDT", ship_number: "835", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FGDX", ship_number: "836", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FGDZ", ship_number: "837", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FGEI", ship_number: "838", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FGEO", ship_number: "839", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FGFZ", ship_number: "840", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FPQB", ship_number: "841", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FGHZ", ship_number: "842", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIUA", ship_number: "701", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIUF", ship_number: "702", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIUJ", ship_number: "703", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIVK", ship_number: "704", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FNND", ship_number: "705", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FNNH", ship_number: "706", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FITL", ship_number: "731", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FITU", ship_number: "732", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FITW", ship_number: "733", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIUL", ship_number: "734", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIUR", ship_number: "735", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIUV", ship_number: "736", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIUW", ship_number: "737", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIVM", ship_number: "738", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FRAM", ship_number: "739", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIVQ", ship_number: "740", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIVR", ship_number: "741", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FIVS", ship_number: "742", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FJZS", ship_number: "748", type_subtype: "777-300", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FKSV", ship_number: "843", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FKAU", ship_number: "749", type_subtype: "777-300", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FRSA", ship_number: "844", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FRSE", ship_number: "845", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FRSI", ship_number: "846", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FRSO", ship_number: "847", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FRTU", ship_number: "850", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FRTW", ship_number: "851", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FRSR", ship_number: "848", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FRTG", ship_number: "849", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FSBV", ship_number: "852", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FVLQ", ship_number: "853", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FVLU", ship_number: "854", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FVLX", ship_number: "855", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FVLZ", ship_number: "856", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FVNB", ship_number: "857", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FVND", ship_number: "858", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-FVNF", ship_number: "859", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GEFA", ship_number: "939", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GEGC", ship_number: "940", type_subtype: "330-300", system_assigned: "eX3", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GEGI", ship_number: "941", type_subtype: "330-300", system_assigned: "eX3", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GROV", ship_number: "101", type_subtype: "220-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GFUR", ship_number: "934", type_subtype: "330-300", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GEGP", ship_number: "942", type_subtype: "330-300", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GJXN", ship_number: "103", type_subtype: "220-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GJXE", ship_number: "102", type_subtype: "220-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GJXW", ship_number: "105", type_subtype: "220-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GJXV", ship_number: "104", type_subtype: "220-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GKUG", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GKUH", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GFAF", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHKR", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHLM", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHKW", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GFAH", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GHKX", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GFAJ", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GJYA", ship_number: "", type_subtype: "220-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACA", registration_name: "C-GJXY", ship_number: "106", type_subtype: "220-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACI", registration_name: "F-OHSD", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACI", registration_name: "F-OJSB", ship_number: "", type_subtype: "320-230", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ACI", registration_name: "F-OJSE", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ADO", registration_name: "VS722", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ADO", registration_name: "VS723", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ADO", registration_name: "VS724", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ADO", registration_name: "VS726", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ADO", registration_name: "VS727", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ADO", registration_name: "VS732", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AEA", registration_name: "EC-JPF", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51489" },
{ operator_icao_code: "AEA", registration_name: "EC-LXR", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51494" },
{ operator_icao_code: "AEA", registration_name: "EC-JQG", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51488" },
{ operator_icao_code: "AEA", registration_name: "EC-JZL", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51499" },
{ operator_icao_code: "AEA", registration_name: "EC-KOM", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51498" },
{ operator_icao_code: "AEA", registration_name: "EC-KTG", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51492" },
{ operator_icao_code: "AEA", registration_name: "EC-LVL", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51496" },
{ operator_icao_code: "AEA", registration_name: "EC-LQP", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51493" },
{ operator_icao_code: "AEA", registration_name: "EC-LQO", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51495" },
{ operator_icao_code: "AEA", registration_name: "EC-MIG", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51481" },
{ operator_icao_code: "AEA", registration_name: "EC-MIH", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51482" },
{ operator_icao_code: "AEA", registration_name: "EC-MHL", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51497" },
{ operator_icao_code: "AEA", registration_name: "EC-MLT", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "C: Commercial", antenna_gen: "GEN3",xid: "51480" },
{ operator_icao_code: "AEA", registration_name: "EC-MMX", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51483" },
{ operator_icao_code: "AEA", registration_name: "EC-MMY", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51478" },
{ operator_icao_code: "AEA", registration_name: "EC-MNS", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51477" },
{ operator_icao_code: "AEA", registration_name: "EC-MPE", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51479" },
{ operator_icao_code: "AEA", registration_name: "EC-MOM", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51484" },
{ operator_icao_code: "AEA", registration_name: "EC-MPG", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXConnect,eXO", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51470" },
{ operator_icao_code: "AEA", registration_name: "EC-MPS", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXConnect,eXO", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51471" },
{ operator_icao_code: "AEA", registration_name: "EC-MQP", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXConnect,eXO", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51469" },
{ operator_icao_code: "AEA", registration_name: "EC-MSZ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51476" },
{ operator_icao_code: "AEA", registration_name: "EC-MTI", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51475" },
{ operator_icao_code: "AEA", registration_name: "EC-MUZ", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "eXConnect,eXO", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51472" },
{ operator_icao_code: "AEA", registration_name: "EC-MVY", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXConnect,eXO", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51473" },
{ operator_icao_code: "AEA", registration_name: "EC-MXM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXConnect", system_reported: "eXConnect,eXO", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51474" },
{ operator_icao_code: "AEA", registration_name: "EC-NBL", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AEA", registration_name: "EC-NBX", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51486" },
{ operator_icao_code: "AEA", registration_name: "EC-NBM", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "51485" },
{ operator_icao_code: "AEA", registration_name: "EC-NBV", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AEA", registration_name: "EC-NCY", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51487" },
{ operator_icao_code: "AEA", registration_name: "EC-NEI", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51491" },
{ operator_icao_code: "AEA", registration_name: "AE469", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AEA", registration_name: "EC-NFM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51490" },
{ operator_icao_code: "AEA", registration_name: "EC-NGM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51500" },
{ operator_icao_code: "AEA", registration_name: "EC-NGN", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51501" },
{ operator_icao_code: "AFL", registration_name: "VP-BLX", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BLY", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BBE", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BBF", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BBG", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BCU", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BCV", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BQX", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BNS", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BQZ", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BQY", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BMV", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BMY", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BMX", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BPI", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BPJ", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BDE", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BDD", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BPK", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BRF", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BRH", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BRR", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BZA", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BZB", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BON", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BVO", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BWF", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BWA", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BWB", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BWD", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BWE", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BCG", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BGG", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BGI", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BGN", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BCD", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BWC", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BCF", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BVP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BMB", ship_number: "YT2121", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BMD", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BMI", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BML", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BMM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BNQ", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BMO", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BNP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BNC", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BPF", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BKA", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BKE", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BKF", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BKK", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BKN", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BSB", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BHB", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VP-BFB", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BHC", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BHD", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BHQ", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BHR", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BHW", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BHT", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BHV", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BHU", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BHX", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "VQ-BFY", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57709" },
{ operator_icao_code: "AFL", registration_name: "F-WZNE", ship_number: "429", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFL", registration_name: "F-WZFR", ship_number: "428", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNH", ship_number: "WE031", type_subtype: "777-300ER", system_assigned: "eXL", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNI", ship_number: "WE032", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNJ", ship_number: "WE033", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNK", ship_number: "WE034", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNL", ship_number: "WE251", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNO", ship_number: "WE252", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNN", ship_number: "WE253", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQY", ship_number: "WD536", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "5793" },
{ operator_icao_code: "AFR", registration_name: "F-GSQD", ship_number: "WD524", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQE", ship_number: "WD525", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPT", ship_number: "WB330", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQH", ship_number: "WD528", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPU", ship_number: "WB331", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPG", ship_number: "WB316", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQB", ship_number: "WD522", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPV", ship_number: "WB332", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQC", ship_number: "WD523", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQJ", ship_number: "WD530", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPX", ship_number: "WB333", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPC", ship_number: "WB313", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNP", ship_number: "WE035", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNQ", ship_number: "WE036", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPY", ship_number: "WB334", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPS", ship_number: "WB329", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPH", ship_number: "WB318", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQG", ship_number: "WD527", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPK", ship_number: "WB321", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPR", ship_number: "WB328", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNE", ship_number: "WD541", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQL", ship_number: "WD532", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "NOT-WD521", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQA", ship_number: "WD521", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNR", ship_number: "WE037", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQF", ship_number: "WD526", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPI", ship_number: "WB319", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQI", ship_number: "WD529", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQK", ship_number: "WD531", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPJ", ship_number: "WB320", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNB", ship_number: "WD538", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNS", ship_number: "WE038", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNA", ship_number: "WD537", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQM", ship_number: "WD533", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNT", ship_number: "WE039", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQV", ship_number: "WD535", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNU", ship_number: "WE040", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSQU", ship_number: "WD534", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZND", ship_number: "WD540", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GZNC", ship_number: "WD539", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-HRBA", ship_number: "ZB246", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57619" },
{ operator_icao_code: "AFR", registration_name: "F-HRBB", ship_number: "ZB332", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57613" },
{ operator_icao_code: "AFR", registration_name: "F-HRBC", ship_number: "ZB247", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57618" },
{ operator_icao_code: "AFR", registration_name: "F-HRBD", ship_number: "ZB248", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57617" },
{ operator_icao_code: "AFR", registration_name: "F-HRBE", ship_number: "ZB333", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57620" },
{ operator_icao_code: "AFR", registration_name: "F-GSPL", ship_number: "WB322", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPA", ship_number: "WB311", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPB", ship_number: "WB312", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPN", ship_number: "WB324", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPO", ship_number: "WB325", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPF", ship_number: "WB317", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPZ", ship_number: "WB335", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPP", ship_number: "WB326", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPD", ship_number: "WB314", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPQ", ship_number: "WB327", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPE", ship_number: "WB315", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-GSPM", ship_number: "WB323", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AFR", registration_name: "F-HRBF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57621" },
{ operator_icao_code: "AFR", registration_name: "F-HRBG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57616" },
{ operator_icao_code: "AFR", registration_name: "F-HRBH", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57614" },
{ operator_icao_code: "AFR", registration_name: "F-HRBI", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57615" },
{ operator_icao_code: "AFR", registration_name: "F-HRBJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57623" },
{ operator_icao_code: "AHY", registration_name: "4K-AZ81", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AHY", registration_name: "4K-AZ82", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWCF", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZFQ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51433" },
{ operator_icao_code: "AIB", registration_name: "F-WZFW", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWSV", ship_number: "", type_subtype: "380-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWCX", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWCE", ship_number: "", type_subtype: "320-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZFU", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1424" },
{ operator_icao_code: "AIB", registration_name: "F-WZFZ", ship_number: "160", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "NOTF-WZGA", ship_number: "314", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZFX", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZGN", ship_number: "403", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51413" },
{ operator_icao_code: "AIB", registration_name: "NOTF-WZFD", ship_number: "189", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1384" },
{ operator_icao_code: "AIB", registration_name: "F-WZFF", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51432" },
{ operator_icao_code: "AIB", registration_name: "L-380", ship_number: "", type_subtype: "330-", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "NOT-FWZGY", ship_number: "", type_subtype: "350-900", system_assigned: "eFX,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZGH", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZNK", ship_number: "312", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "51451" },
{ operator_icao_code: "AIB", registration_name: "F-WZNB", ship_number: "73", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZNN", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZNJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZNV", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "NOT-DAIXF", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1378" },
{ operator_icao_code: "AIB", registration_name: "F-WZFP", ship_number: "105", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZGX", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWKY", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZGR", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZFC", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZGB", ship_number: "402", type_subtype: "350-1000", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWYH", ship_number: "", type_subtype: "330-", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "NOTNOTF-WZFB", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZGO", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51416" },
{ operator_icao_code: "AIB", registration_name: "F-WZFN", ship_number: "414", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51439" },
{ operator_icao_code: "AIB", registration_name: "F-WZNI", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWKF", ship_number: "1814", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZFS", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51441" },
{ operator_icao_code: "AIB", registration_name: "F-WWYI", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "MSN1818", ship_number: "", type_subtype: "330-", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AYAV", ship_number: "EI-LRA", type_subtype: "321-100", system_assigned: "eX1,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVZL", ship_number: "", type_subtype: "321-100", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1412" },
{ operator_icao_code: "AIB", registration_name: "D-AYAL", ship_number: "", type_subtype: "321-100", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1414" },
{ operator_icao_code: "AIB", registration_name: "D-AVYM", ship_number: "", type_subtype: "321-100", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1415" },
{ operator_icao_code: "AIB", registration_name: "NOTD-AYAM", ship_number: "", type_subtype: "321-100", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1416" },
{ operator_icao_code: "AIB", registration_name: "D-AYAS", ship_number: "", type_subtype: "321-100", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1417" },
{ operator_icao_code: "AIB", registration_name: "F-WZGK", ship_number: "302", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZHH", ship_number: "170", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51407" },
{ operator_icao_code: "AIB", registration_name: "F-WZNX", ship_number: "179", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWIC", ship_number: "8009", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZGZ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51419" },
{ operator_icao_code: "AIB", registration_name: "NOTF-WWIW", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWTW", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1440" },
{ operator_icao_code: "AIB", registration_name: "F-WWIU", ship_number: "8070", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWSL", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "NOT9V-SMU", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "NOTF-WZGT", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZNU", ship_number: "215", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95472" },
{ operator_icao_code: "AIB", registration_name: "F-WZFG", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZNO", ship_number: "404", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZFH", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWSM", ship_number: "", type_subtype: "380-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWSU", ship_number: "", type_subtype: "380-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZNY", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AYAY", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51424" },
{ operator_icao_code: "AIB", registration_name: "F-WWIZ", ship_number: "MSN8419", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZHE", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "NOTD-AVZM", ship_number: "", type_subtype: "321-230", system_assigned: "eX1,eXW-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51415" },
{ operator_icao_code: "AIB", registration_name: "D-AVXJ", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWAX", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZNT", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "AT-AIB60", ship_number: "", type_subtype: "350-900", system_assigned: "eX1", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51464" },
{ operator_icao_code: "AIB", registration_name: "D-AVYF", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVXN", ship_number: "", type_subtype: "321-210", system_assigned: "eXO-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWIY", ship_number: "9457", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZFJ", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AYAT", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWAF", ship_number: "", type_subtype: "380-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVXU", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AYAU", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "51426" },
{ operator_icao_code: "AIB", registration_name: "D-AZBS", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AUBH", ship_number: "", type_subtype: "320-200", system_assigned: "eX1,eXW-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVYY", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVZS", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "NOTF-WZMG", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZMF", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AYAH", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "DTC4GCSNOT", ship_number: "", type_subtype: "350-900", system_assigned: "eXW-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVXR", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AYAZ", ship_number: "TC-LSF", type_subtype: "321-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AZAU", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVZD", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWBM", ship_number: "", type_subtype: "320-200", system_assigned: "eXO-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "NOTF-WWBW", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZFI", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWYFNO", ship_number: "1934", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWKU", ship_number: "1936", type_subtype: "330-200", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZNM", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51431" },
{ operator_icao_code: "AIB", registration_name: "F-WWIP", ship_number: "", type_subtype: "320-200", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "IBE01-05", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "A6-XWB", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZNA", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZMY", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVSD", ship_number: "", type_subtype: "321-200", system_assigned: "eXW-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AYAP", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AYAD", ship_number: "", type_subtype: "321-200", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZMB", ship_number: "", type_subtype: "300-100", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVXF", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVXD", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW-eXConnect", system_reported: "eXConnect", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "51427" },
{ operator_icao_code: "AIB", registration_name: "D-AVXE", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AZAR", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eX1,eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVXS", ship_number: "", type_subtype: "321-200", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZGM", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51446" },
{ operator_icao_code: "AIB", registration_name: "F-WWBI", ship_number: "9519", type_subtype: "320-200", system_assigned: "eX1-eXConnect", system_reported: "eX1,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51417" },
{ operator_icao_code: "AIB", registration_name: "D-AVVS", ship_number: "", type_subtype: "320-200", system_assigned: "eXConnect,eXO", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51422" },
{ operator_icao_code: "AIB", registration_name: "F-WWDY", ship_number: "", type_subtype: "320-200", system_assigned: "eXConnect,eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWBJ", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZMZ", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZFL", ship_number: "388", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51450" },
{ operator_icao_code: "AIB", registration_name: "D-AVXO", ship_number: "9376", type_subtype: "321-200", system_assigned: "eX1,eXW-eXConnect", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AYAF", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AYAQ", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW-eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51411" },
{ operator_icao_code: "AIB", registration_name: "F-WWIR", ship_number: "", type_subtype: "320-200", system_assigned: "eX1,eXConnect", system_reported: "eX1,eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWIV", ship_number: "", type_subtype: "320-200", system_assigned: "eX1-eXConnect", system_reported: "eX1,eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZGD", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51423" },
{ operator_icao_code: "AIB", registration_name: "F-WWDQ", ship_number: "", type_subtype: "320-200", system_assigned: "eX1-eXConnect", system_reported: "eX1,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51435" },
{ operator_icao_code: "AIB", registration_name: "F-WZHG", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51436" },
{ operator_icao_code: "AIB", registration_name: "F-WWBN", ship_number: "", type_subtype: "320-200", system_assigned: "eX1-eXConnect", system_reported: "eX1", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51442" },
{ operator_icao_code: "AIB", registration_name: "F-WWDN", ship_number: "", type_subtype: "320-200", system_assigned: "eX1-eXConnect", system_reported: "eX1,eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVZH", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW-eXConnect", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AZAE", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51406" },
{ operator_icao_code: "AIB", registration_name: "D-AUAM", ship_number: "", type_subtype: "320-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eX1,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51428" },
{ operator_icao_code: "AIB", registration_name: "NOTN230HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZGE", ship_number: "413", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WWIS", ship_number: "10072", type_subtype: "320-200", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "F-WZHI", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIB", registration_name: "D-AVXP", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51412" },
{ operator_icao_code: "AIB", registration_name: "D-AYAK", ship_number: "", type_subtype: "321-200", system_assigned: "eXW-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51452" },
{ operator_icao_code: "AIC", registration_name: "VT-AIE", ship_number: "", type_subtype: "747-400", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIC", registration_name: "VT-GHA", ship_number: "", type_subtype: "300-100", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-ILY", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-VIP", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-BIC", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-MTO", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-YES", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-CRM", ship_number: "", type_subtype: "320-214", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-CBA", ship_number: "", type_subtype: "320-214", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-MBA", ship_number: "", type_subtype: "320-214", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-JPB", ship_number: "", type_subtype: "321-210", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-AJB", ship_number: "", type_subtype: "321-210", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-GEO", ship_number: "", type_subtype: "321-210", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-JOE", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54365" },
{ operator_icao_code: "AIJ", registration_name: "XA-MAP", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54370" },
{ operator_icao_code: "AIJ", registration_name: "XA-NEO", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54368" },
{ operator_icao_code: "AIJ", registration_name: "XA-JIL", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54367" },
{ operator_icao_code: "AIJ", registration_name: "XA-RLM", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO", system_reported: "eXConnect,eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "XA-VMM", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54366" },
{ operator_icao_code: "AIJ", registration_name: "XA-DBR", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54371" },
{ operator_icao_code: "AIJ", registration_name: "XA-PGA", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO-eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54372" },
{ operator_icao_code: "AIJ", registration_name: "XA-WJS", ship_number: "", type_subtype: "320-200", system_assigned: "eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54373" },
{ operator_icao_code: "AIJ", registration_name: "XA-ARG", ship_number: "", type_subtype: "320-200", system_assigned: "eXO-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54374" },
{ operator_icao_code: "AIJ", registration_name: "F-WWBINOPE", ship_number: "", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIJ", registration_name: "D-AVVZ", ship_number: "", type_subtype: "320-200", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXConnect,eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AIZ", registration_name: "4X-AGK", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56270" },
{ operator_icao_code: "AIZ", registration_name: "4X-AGH", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56269" },
{ operator_icao_code: "AIZ", registration_name: "4X-AGN", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56271" },
{ operator_icao_code: "AIZ", registration_name: "4X-EGH", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ALK", registration_name: "4R-ABM", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ALK", registration_name: "4R-ABN", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ALK", registration_name: "4R-ABO", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMC", registration_name: "94-NEC", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMA", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMJ", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMK", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMB", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMC", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AME", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N961AM", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "58081" },
{ operator_icao_code: "AMX", registration_name: "N964AM", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "58082" },
{ operator_icao_code: "AMX", registration_name: "N965AM", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "58083" },
{ operator_icao_code: "AMX", registration_name: "N966AM", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "58084" },
{ operator_icao_code: "AMX", registration_name: "N967AM", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "58085" },
{ operator_icao_code: "AMX", registration_name: "XA-AMG", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N950AM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N782AM", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "58079" },
{ operator_icao_code: "AMX", registration_name: "N783AM", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "58080" },
{ operator_icao_code: "AMX", registration_name: "XA-AMN", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMX", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "58077" },
{ operator_icao_code: "AMX", registration_name: "XA-DRA", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AML", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N825AM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N875AM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-MIA", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-ZAM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-JOY", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N342AM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMR", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "58078" },
{ operator_icao_code: "AMX", registration_name: "N845AM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMS", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMO", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N957AM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMU", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N958AM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMV", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N183AM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "58093" },
{ operator_icao_code: "AMX", registration_name: "XA-ADL", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "58086" },
{ operator_icao_code: "AMX", registration_name: "XA-ADC", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "58087" },
{ operator_icao_code: "AMX", registration_name: "XA-ADD", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "58088" },
{ operator_icao_code: "AMX", registration_name: "N438AM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "58091" },
{ operator_icao_code: "AMX", registration_name: "XA-ADG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "58089" },
{ operator_icao_code: "AMX", registration_name: "N377AR", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N368AR", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-ADU", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-ADT", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-ADH", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "58090" },
{ operator_icao_code: "AMX", registration_name: "XA-MAG", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-MAK", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N446AM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "58092" },
{ operator_icao_code: "AMX", registration_name: "XA-MAO", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-MAQ", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-AMZ", ship_number: "", type_subtype: "737-700", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-ADV", ship_number: "", type_subtype: "737-700", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-MAT", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-DRC", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N128AM", ship_number: "833", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "58094" },
{ operator_icao_code: "AMX", registration_name: "XA-MAU", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-MAW", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-SSR", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-OCC", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "XA-MFG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AMX", registration_name: "N115AM", ship_number: "", type_subtype: "737-MAX9", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA734A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA603A", ship_number: "", type_subtype: "767-300EREM", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA604A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX,eXConnect", system_reported: "eFX-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6981" },
{ operator_icao_code: "ANA", registration_name: "JA605A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX,eXConnect", system_reported: "eFX-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6982" },
{ operator_icao_code: "ANA", registration_name: "JA606A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX,eXConnect", system_reported: "eFX-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6983" },
{ operator_icao_code: "ANA", registration_name: "JA607A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX,eXConnect", system_reported: "eFX-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6984" },
{ operator_icao_code: "ANA", registration_name: "JA608A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX-eXConnect", system_reported: "eFX-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6985" },
{ operator_icao_code: "ANA", registration_name: "JA609A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX,eXConnect", system_reported: "eFX-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6986" },
{ operator_icao_code: "ANA", registration_name: "JA610A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX,eXConnect", system_reported: "eFX-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7053" },
{ operator_icao_code: "ANA", registration_name: "JA611A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX-eXConnect", system_reported: "eFX-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7054" },
{ operator_icao_code: "ANA", registration_name: "JA8098", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA8958", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA8962", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA702A", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA703A", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA704A", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA706A", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA707A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6996" },
{ operator_icao_code: "ANA", registration_name: "JA756A", ship_number: "", type_subtype: "777-300", system_assigned: "2000E,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7017" },
{ operator_icao_code: "ANA", registration_name: "JA757A", ship_number: "", type_subtype: "777-300", system_assigned: "2000E,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7018" },
{ operator_icao_code: "ANA", registration_name: "JA754A", ship_number: "", type_subtype: "777-300", system_assigned: "2000E,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7015" },
{ operator_icao_code: "ANA", registration_name: "JA751A", ship_number: "", type_subtype: "777-300", system_assigned: "2000E,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7012" },
{ operator_icao_code: "ANA", registration_name: "JA753A", ship_number: "", type_subtype: "777-300", system_assigned: "2000E,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7014" },
{ operator_icao_code: "ANA", registration_name: "JA752A", ship_number: "", type_subtype: "777-300", system_assigned: "2000E,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7013" },
{ operator_icao_code: "ANA", registration_name: "JA755A", ship_number: "", type_subtype: "777-300", system_assigned: "2000E,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7016" },
{ operator_icao_code: "ANA", registration_name: "JA708A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6997" },
{ operator_icao_code: "ANA", registration_name: "JA710A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6999" },
{ operator_icao_code: "ANA", registration_name: "JA705A", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA403A", ship_number: "", type_subtype: "747-400", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA709A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6998" },
{ operator_icao_code: "ANA", registration_name: "JA612A", ship_number: "", type_subtype: "767-300", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA731A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA732A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA613A", ship_number: "", type_subtype: "767-300", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA614A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX,eXConnect", system_reported: "eFX-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6991" },
{ operator_icao_code: "ANA", registration_name: "JA733A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA715A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7004" },
{ operator_icao_code: "ANA", registration_name: "JA716A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7005" },
{ operator_icao_code: "ANA", registration_name: "JA717A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7006" },
{ operator_icao_code: "ANA", registration_name: "JA736A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA777A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA778A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA615A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX-eXConnect", system_reported: "eFX-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6992" },
{ operator_icao_code: "ANA", registration_name: "JA616A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX,eXConnect", system_reported: "eFX-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6993" },
{ operator_icao_code: "ANA", registration_name: "JA735A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA780A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA779A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA781A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA782A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA783A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA617A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX,eXConnect", system_reported: "eFX-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6994" },
{ operator_icao_code: "ANA", registration_name: "JA618A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eFX-eXConnect", system_reported: "eFX-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6995" },
{ operator_icao_code: "ANA", registration_name: "JA784A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA785A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA786A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA787A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA788A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51580" },
{ operator_icao_code: "ANA", registration_name: "JA789A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA619A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA620A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA621A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA622A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA623A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA801A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2-eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6915" },
{ operator_icao_code: "ANA", registration_name: "JA802A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6916" },
{ operator_icao_code: "ANA", registration_name: "JA624A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA625A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA807A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2-eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6919" },
{ operator_icao_code: "ANA", registration_name: "JA805A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6862" },
{ operator_icao_code: "ANA", registration_name: "JA626A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA627A", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA741A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7007" },
{ operator_icao_code: "ANA", registration_name: "JA742A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7008" },
{ operator_icao_code: "ANA", registration_name: "JA804A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6918" },
{ operator_icao_code: "ANA", registration_name: "JA806A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6863" },
{ operator_icao_code: "ANA", registration_name: "JA808A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6920" },
{ operator_icao_code: "ANA", registration_name: "JA803A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6917" },
{ operator_icao_code: "ANA", registration_name: "JA809A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7019" },
{ operator_icao_code: "ANA", registration_name: "JA810A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7020" },
{ operator_icao_code: "ANA", registration_name: "JA811A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7021" },
{ operator_icao_code: "ANA", registration_name: "JA812A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7022" },
{ operator_icao_code: "ANA", registration_name: "JA813A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6864" },
{ operator_icao_code: "ANA", registration_name: "JA814A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6865" },
{ operator_icao_code: "ANA", registration_name: "JA815A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6856" },
{ operator_icao_code: "ANA", registration_name: "JA816A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7023" },
{ operator_icao_code: "ANA", registration_name: "JA743A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7009" },
{ operator_icao_code: "ANA", registration_name: "JA818A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7025" },
{ operator_icao_code: "ANA", registration_name: "JA819A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7026" },
{ operator_icao_code: "ANA", registration_name: "JA820A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA822A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6867" },
{ operator_icao_code: "ANA", registration_name: "JA823A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA824A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7028" },
{ operator_icao_code: "ANA", registration_name: "JA744A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7010" },
{ operator_icao_code: "ANA", registration_name: "JA745A", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7011" },
{ operator_icao_code: "ANA", registration_name: "JA827A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA825A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7029" },
{ operator_icao_code: "ANA", registration_name: "JA828A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA829A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6857" },
{ operator_icao_code: "ANA", registration_name: "JA830A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7030" },
{ operator_icao_code: "ANA", registration_name: "JA831A", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6858" },
{ operator_icao_code: "ANA", registration_name: "JA834A", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6860" },
{ operator_icao_code: "ANA", registration_name: "JA832A", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6859" },
{ operator_icao_code: "ANA", registration_name: "JA817A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7024" },
{ operator_icao_code: "ANA", registration_name: "JA821A", ship_number: "", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7027" },
{ operator_icao_code: "ANA", registration_name: "JA833A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7031" },
{ operator_icao_code: "ANA", registration_name: "JA872A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6891" },
{ operator_icao_code: "ANA", registration_name: "JA837A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6882" },
{ operator_icao_code: "ANA", registration_name: "JA873A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6885" },
{ operator_icao_code: "ANA", registration_name: "JA875A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6892" },
{ operator_icao_code: "ANA", registration_name: "JA839A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6883" },
{ operator_icao_code: "ANA", registration_name: "JA838A", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6890" },
{ operator_icao_code: "ANA", registration_name: "JA840A", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6886" },
{ operator_icao_code: "ANA", registration_name: "JA836A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6881" },
{ operator_icao_code: "ANA", registration_name: "JA874A", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6888" },
{ operator_icao_code: "ANA", registration_name: "JA835A", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6861" },
{ operator_icao_code: "ANA", registration_name: "JA876A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6887" },
{ operator_icao_code: "ANA", registration_name: "JA790A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA791A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA792A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA871A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6884" },
{ operator_icao_code: "ANA", registration_name: "JA877A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6889" },
{ operator_icao_code: "ANA", registration_name: "JA878A", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6893" },
{ operator_icao_code: "ANA", registration_name: "JA712A", ship_number: "", type_subtype: "777-200", system_assigned: "2000E,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7001" },
{ operator_icao_code: "ANA", registration_name: "JA713A", ship_number: "", type_subtype: "777-200", system_assigned: "2000E,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7002" },
{ operator_icao_code: "ANA", registration_name: "JA714A", ship_number: "", type_subtype: "777-200", system_assigned: "2000E,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7003" },
{ operator_icao_code: "ANA", registration_name: "JA51AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6945" },
{ operator_icao_code: "ANA", registration_name: "JA52AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6946" },
{ operator_icao_code: "ANA", registration_name: "JA53AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6947" },
{ operator_icao_code: "ANA", registration_name: "JA54AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6948" },
{ operator_icao_code: "ANA", registration_name: "JA55AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6949" },
{ operator_icao_code: "ANA", registration_name: "JA56AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6950" },
{ operator_icao_code: "ANA", registration_name: "JA57AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6951" },
{ operator_icao_code: "ANA", registration_name: "JA58AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6952" },
{ operator_icao_code: "ANA", registration_name: "JA59AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6953" },
{ operator_icao_code: "ANA", registration_name: "JA60AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6954" },
{ operator_icao_code: "ANA", registration_name: "JA61AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6955" },
{ operator_icao_code: "ANA", registration_name: "JA62AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6956" },
{ operator_icao_code: "ANA", registration_name: "JA63AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6957" },
{ operator_icao_code: "ANA", registration_name: "JA64AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6958" },
{ operator_icao_code: "ANA", registration_name: "JA65AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6959" },
{ operator_icao_code: "ANA", registration_name: "JA66AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6960" },
{ operator_icao_code: "ANA", registration_name: "JA67AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA68AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6962" },
{ operator_icao_code: "ANA", registration_name: "JA69AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6963" },
{ operator_icao_code: "ANA", registration_name: "JA70AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6964" },
{ operator_icao_code: "ANA", registration_name: "JA71AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6965" },
{ operator_icao_code: "ANA", registration_name: "JA72AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6966" },
{ operator_icao_code: "ANA", registration_name: "JA73AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6967" },
{ operator_icao_code: "ANA", registration_name: "JA74AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6968" },
{ operator_icao_code: "ANA", registration_name: "JA75AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6969" },
{ operator_icao_code: "ANA", registration_name: "JA76AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6970" },
{ operator_icao_code: "ANA", registration_name: "JA77AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6971" },
{ operator_icao_code: "ANA", registration_name: "JA78AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6972" },
{ operator_icao_code: "ANA", registration_name: "JA79AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6973" },
{ operator_icao_code: "ANA", registration_name: "JA80AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6974" },
{ operator_icao_code: "ANA", registration_name: "JA81AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6975" },
{ operator_icao_code: "ANA", registration_name: "JA82AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6976" },
{ operator_icao_code: "ANA", registration_name: "JA83AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6977" },
{ operator_icao_code: "ANA", registration_name: "JA84AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6978" },
{ operator_icao_code: "ANA", registration_name: "JA85AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "6979" },
{ operator_icao_code: "ANA", registration_name: "JA86AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6980" },
{ operator_icao_code: "ANA", registration_name: "JA711A", ship_number: "", type_subtype: "777-200", system_assigned: "2000E,eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7000" },
{ operator_icao_code: "ANA", registration_name: "JA879A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6871" },
{ operator_icao_code: "ANA", registration_name: "JA880A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6903" },
{ operator_icao_code: "ANA", registration_name: "JA882A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6873" },
{ operator_icao_code: "ANA", registration_name: "JA883A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6874" },
{ operator_icao_code: "ANA", registration_name: "JA884A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6875" },
{ operator_icao_code: "ANA", registration_name: "JA885A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6876" },
{ operator_icao_code: "ANA", registration_name: "JA886A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6877" },
{ operator_icao_code: "ANA", registration_name: "JA887A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6878" },
{ operator_icao_code: "ANA", registration_name: "JA888A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6879" },
{ operator_icao_code: "ANA", registration_name: "JA890A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6880" },
{ operator_icao_code: "ANA", registration_name: "JA856A", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA858A", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA857A", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA891A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6901" },
{ operator_icao_code: "ANA", registration_name: "JA892A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6902" },
{ operator_icao_code: "ANA", registration_name: "JA893A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6894" },
{ operator_icao_code: "ANA", registration_name: "JA894A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6895" },
{ operator_icao_code: "ANA", registration_name: "JA895A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6896" },
{ operator_icao_code: "ANA", registration_name: "JA896A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6897" },
{ operator_icao_code: "ANA", registration_name: "JA897A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6898" },
{ operator_icao_code: "ANA", registration_name: "JA898A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6899" },
{ operator_icao_code: "ANA", registration_name: "JA899A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6900" },
{ operator_icao_code: "ANA", registration_name: "JA111A", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXO-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7032" },
{ operator_icao_code: "ANA", registration_name: "JA211A", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51648" },
{ operator_icao_code: "ANA", registration_name: "D-AXAS", ship_number: "", type_subtype: "320-100", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA112A", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXO-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "7033" },
{ operator_icao_code: "ANA", registration_name: "JA113A", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXO-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7034" },
{ operator_icao_code: "ANA", registration_name: "JA114A", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXO-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7035" },
{ operator_icao_code: "ANA", registration_name: "JA212A", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51649" },
{ operator_icao_code: "ANA", registration_name: "JA463A", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA213A", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51650" },
{ operator_icao_code: "ANA", registration_name: "JA136A", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7038" },
{ operator_icao_code: "ANA", registration_name: "JA131A", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7043" },
{ operator_icao_code: "ANA", registration_name: "JA464A", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "ANATEST7", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA132A", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7044" },
{ operator_icao_code: "ANA", registration_name: "JA381A", ship_number: "", type_subtype: "380-800", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51684" },
{ operator_icao_code: "ANA", registration_name: "JA900A", ship_number: "", type_subtype: "787-10", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51682" },
{ operator_icao_code: "ANA", registration_name: "JA382A", ship_number: "", type_subtype: "380-800", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51573" },
{ operator_icao_code: "ANA", registration_name: "JA903A", ship_number: "", type_subtype: "787-10", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA383A", ship_number: "", type_subtype: "380-800", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51572" },
{ operator_icao_code: "ANA", registration_name: "JA902A", ship_number: "", type_subtype: "787-10", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA901A", ship_number: "", type_subtype: "787-10", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51683" },
{ operator_icao_code: "ANA", registration_name: "JA904A", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA921A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6872" },
{ operator_icao_code: "ANA", registration_name: "JA923A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51571" },
{ operator_icao_code: "ANA", registration_name: "JA924A", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA926A", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA927A", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA928A", ship_number: "", type_subtype: "787-9", system_assigned: "eX2,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51635" },
{ operator_icao_code: "ANA", registration_name: "JA929A", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA930A", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA931A", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA133A", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7045" },
{ operator_icao_code: "ANA", registration_name: "JA134A", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7036" },
{ operator_icao_code: "ANA", registration_name: "JA135A", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7037" },
{ operator_icao_code: "ANA", registration_name: "JA465A", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA214A", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51651" },
{ operator_icao_code: "ANA", registration_name: "JA215A", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51652" },
{ operator_icao_code: "ANA", registration_name: "JA137A", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7039" },
{ operator_icao_code: "ANA", registration_name: "JA138A", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7040" },
{ operator_icao_code: "ANA", registration_name: "JA140A", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7042" },
{ operator_icao_code: "ANA", registration_name: "JA87AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXO-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6987" },
{ operator_icao_code: "ANA", registration_name: "D-AVVD", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA462A", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA460A", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "D-AVYP", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA216A", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51653" },
{ operator_icao_code: "ANA", registration_name: "JA88AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXO-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6988" },
{ operator_icao_code: "ANA", registration_name: "JA89AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXO-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6989" },
{ operator_icao_code: "ANA", registration_name: "JA90AN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXO,eXW", system_reported: "eXO-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6990" },
{ operator_icao_code: "ANA", registration_name: "JA859A", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA139A", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7041" },
{ operator_icao_code: "ANA", registration_name: "JA217A", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51654" },
{ operator_icao_code: "ANA", registration_name: "JA461A", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA855A", ship_number: "", type_subtype: "Q400-", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA141A", ship_number: "", type_subtype: "321-200N", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "7046" },
{ operator_icao_code: "ANA", registration_name: "JA793A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51565" },
{ operator_icao_code: "ANA", registration_name: "JA932A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51685" },
{ operator_icao_code: "ANA", registration_name: "JA794A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51566" },
{ operator_icao_code: "ANA", registration_name: "JA218A", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51655" },
{ operator_icao_code: "ANA", registration_name: "JA219A", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51656" },
{ operator_icao_code: "ANA", registration_name: "F-WWSH", ship_number: "", type_subtype: "380-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA795A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51567" },
{ operator_icao_code: "ANA", registration_name: "JA220A", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51657" },
{ operator_icao_code: "ANA", registration_name: "JA922A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51575" },
{ operator_icao_code: "ANA", registration_name: "JA796A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "51568" },
{ operator_icao_code: "ANA", registration_name: "JA797A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51569" },
{ operator_icao_code: "ANA", registration_name: "F-WWBO", ship_number: "", type_subtype: "320-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA798A", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51570" },
{ operator_icao_code: "ANA", registration_name: "F-WWDT", ship_number: "", type_subtype: "320-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANA", registration_name: "JA222A", ship_number: "", type_subtype: "320-200N", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51658" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJN", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJA", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH,eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJB", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH,eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJC", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH,eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJD", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJE", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJF", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJG", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJH", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJI", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJM", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJO", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXL,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OAB", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXL,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJQ", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXL,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJR", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKA", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKB", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXL,eXW", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKC", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXL,eXW", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKD", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXL,eXW", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKE", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXL,eXW", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKF", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXL,eXW", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKG", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXL,eXW", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKH", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXL,eXW", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJS", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OJK", ship_number: "", type_subtype: "320-200", system_assigned: "eFxH", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXA", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXB", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXC", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXD", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXE", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZE", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXF", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXL,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXG", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXH", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXI", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZD", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXJ", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZH", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZC", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXK", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXL", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZI", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZK", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OXM", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZL", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZN", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZQ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKI", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-OKT", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ANZD11", ship_number: "", type_subtype: "320-100", system_assigned: "eX1,eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "D-AZAK", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "D-AVZU", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NNA", ship_number: "", type_subtype: "321-200N", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NNC", ship_number: "", type_subtype: "321-200N", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NNB", ship_number: "", type_subtype: "321-200N", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "D-AUBR", ship_number: "", type_subtype: "320-200", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "D-AVYL", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NND", ship_number: "", type_subtype: "321-200N", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NHA", ship_number: "", type_subtype: "320-200N", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "D-AVZF", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NHC", ship_number: "8833", type_subtype: "320-200N", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NHB", ship_number: "", type_subtype: "320-200N", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NNE", ship_number: "", type_subtype: "321-200N", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NNF", ship_number: "", type_subtype: "321-200N", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NNG", ship_number: "", type_subtype: "321-200N", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NZR", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "D-AVVF", ship_number: "", type_subtype: "320-200", system_assigned: "eX1,eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ANZ", registration_name: "ZK-NHD", ship_number: "", type_subtype: "320-200N", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARA", registration_name: "5N-MJN", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARA", registration_name: "5N-MJO", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARA", registration_name: "5N-MJP", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARA", registration_name: "5N-MJQ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-FQC", ship_number: "MSN37744", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-FQB", ship_number: "MSN36886", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-FUA", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-FUB", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-FQZ", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-FUC", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-FVH", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-FVI", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-FVM", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-FVN", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-FVO", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GFQ", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GHQ", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GIF", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GGQ", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GKU", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GKT", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GKS", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GUB", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GUC", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GVA", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GVC", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GVB", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GVD", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-GVE", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-HKU", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-HKV", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ARG", registration_name: "LV-HKW", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ASL", registration_name: "YU-APG", ship_number: "", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "5929" },
{ operator_icao_code: "ASL", registration_name: "YU-APH", ship_number: "", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "5930" },
{ operator_icao_code: "ASL", registration_name: "YU-API", ship_number: "", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "5921" },
{ operator_icao_code: "ASL", registration_name: "YU-APJ", ship_number: "", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "5922" },
{ operator_icao_code: "ASL", registration_name: "YU-APA", ship_number: "", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "5923" },
{ operator_icao_code: "ASL", registration_name: "YU-APB", ship_number: "", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "5924" },
{ operator_icao_code: "ASL", registration_name: "YU-APC", ship_number: "", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "5925" },
{ operator_icao_code: "ASL", registration_name: "YU-APD", ship_number: "", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "5926" },
{ operator_icao_code: "ASL", registration_name: "YU-APE", ship_number: "", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "5927" },
{ operator_icao_code: "ASL", registration_name: "YU-APF", ship_number: "", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "5928" },
{ operator_icao_code: "ASL", registration_name: "YU-ARA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "5931" },
{ operator_icao_code: "ASV", registration_name: "HL8255", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ASV", registration_name: "HL8280", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ASV", registration_name: "HL8281", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ASV", registration_name: "HL7790", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ASV", registration_name: "HL8266", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ATC", registration_name: "5H-TCG", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3073" },
{ operator_icao_code: "ATC", registration_name: "ZA958", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ATC", registration_name: "5H-TCH", ship_number: "", type_subtype: "Q400-", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56013" },
{ operator_icao_code: "ATC", registration_name: "5H-TCI", ship_number: "", type_subtype: "Q400-", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56014" },
{ operator_icao_code: "ATC", registration_name: "5H-TCJ", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3074" },
{ operator_icao_code: "ATC", registration_name: "ATC-56029", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56029" },
{ operator_icao_code: "AUA", registration_name: "OE-LAX", ship_number: "", type_subtype: "767-300EREM", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LPA", ship_number: "", type_subtype: "777-200ER", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LPB", ship_number: "", type_subtype: "777-200ER", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LPC", ship_number: "", type_subtype: "777-200ER", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LAE", ship_number: "", type_subtype: "767-300EREM", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LAW", ship_number: "", type_subtype: "767-300EREM", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LAO", ship_number: "", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LAN", ship_number: "", type_subtype: "330-220", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LAM", ship_number: "", type_subtype: "330-220", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LAP", ship_number: "", type_subtype: "330-220", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LAL", ship_number: "", type_subtype: "340-310", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LAY", ship_number: "", type_subtype: "767-300ER", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LAZ", ship_number: "", type_subtype: "767-300", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUA", registration_name: "OE-LPD", ship_number: "", type_subtype: "777-200ER", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUH", registration_name: "A6-SIL", ship_number: "", type_subtype: "777-300", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1129" },
{ operator_icao_code: "AUH", registration_name: "A6-ALN", ship_number: "", type_subtype: "777-200", system_assigned: "eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "1127" },
{ operator_icao_code: "AUH", registration_name: "A6-AUH", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1126" },
{ operator_icao_code: "AUH", registration_name: "A6-UAE", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUH", registration_name: "A6-PFE", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1205" },
{ operator_icao_code: "AUH", registration_name: "A6-PFC", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50478" },
{ operator_icao_code: "AUI", registration_name: "UR-GOA", ship_number: "", type_subtype: "777-200", system_assigned: "eXConnect,eXL", system_reported: "eX3-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUI", registration_name: "UR-GOB", ship_number: "", type_subtype: "777-200", system_assigned: "eXConnect,eXL", system_reported: "eX3-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUI", registration_name: "UR-GOC", ship_number: "", type_subtype: "777-200", system_assigned: "eXConnect,eXL", system_reported: "eX3-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUI", registration_name: "UR-GOD", ship_number: "", type_subtype: "777-200", system_assigned: "eXConnect,eXL", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CDY", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CDZ", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CET", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CEU", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CEV", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CHO", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CHQ", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CHR", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CHS", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CID", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CIE", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CIF", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CIG", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CIH", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CKZ", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CMA", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CMB", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CPI", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CPJ", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-CPK", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-FPT", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-FPS", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-GIK", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AUT", registration_name: "LV-GIQ", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N647AV", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N664AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N691AV", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "HC-CSA", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "HC-CSB", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N968AV", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N969AV", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N961AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N980AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N398AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N992AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N401AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N411AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N426AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N446AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N451AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "HC-CSF", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N973AV", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N416AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N422AV", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N281AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N284AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N345AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "HC-CJM", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N481AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "HC-CJW", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "HC-CJV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N567AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N599AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N975AV", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N763AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "HC-CTX", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N821AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N862AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N939AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N557AV", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N519AV", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N195AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N342AV", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N686TA", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N688TA", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N689TA", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N536AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N538AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N454AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N477AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N280AV", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N562AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N632AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N279AV", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N603AV", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N694AV", ship_number: "", type_subtype: "319-110", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N695AV", ship_number: "", type_subtype: "319-110", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N696AV", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N723AV", ship_number: "", type_subtype: "319-110", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N724AV", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N697AV", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N726AV", ship_number: "", type_subtype: "319-110", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N725AV", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N728AV", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N730AV", ship_number: "", type_subtype: "319-110", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N780AV", ship_number: "ZA665", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N782AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N781AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N729AV", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N740AV", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N783AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N784AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N741AV", ship_number: "", type_subtype: "319-110", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N742AV", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N743AV", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N745AV", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N744AV", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N785AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N746AV", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N747AV", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N786AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N748AV", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N750AV", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "HC-CRU", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N791AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N751AV", ship_number: "", type_subtype: "319-110", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N792AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N753AV", ship_number: "MSN7318", type_subtype: "319-110", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N793AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N755AV", ship_number: "7437", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N794AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N795AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N804AV", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N803AV", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N796AV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVA", registration_name: "N974AV", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AVN", registration_name: "YJ-AV8", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXN", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXH", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXI", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXJ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXM", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXP", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXR", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AYD", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXZ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXU", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXQ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXT", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXW", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXX", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AYA", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AYB", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AYC", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-AXV", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-GHB", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-GHC", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-GHE", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AXB", registration_name: "VT-GHF", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZA", registration_name: "EI-ISB", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZA", registration_name: "EI-DBL", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55278" },
{ operator_icao_code: "AZA", registration_name: "EI-DBM", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55277" },
{ operator_icao_code: "AZA", registration_name: "EI-ISA", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZA", registration_name: "EI-ISD", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZA", registration_name: "EI-ISE", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZA", registration_name: "EI-ISO", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZA", registration_name: "I-DISU", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZA", registration_name: "EI-EJL", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55292" },
{ operator_icao_code: "AZA", registration_name: "EI-DBK", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZA", registration_name: "EI-DDH", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55281" },
{ operator_icao_code: "AZA", registration_name: "EI-EJG", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55284" },
{ operator_icao_code: "AZA", registration_name: "EI-EJH", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55285" },
{ operator_icao_code: "AZA", registration_name: "EI-EJJ", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55289" },
{ operator_icao_code: "AZA", registration_name: "EI-EJI", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55286" },
{ operator_icao_code: "AZA", registration_name: "EI-EJK", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55290" },
{ operator_icao_code: "AZA", registration_name: "EI-EJM", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55291" },
{ operator_icao_code: "AZA", registration_name: "EI-EJN", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55293" },
{ operator_icao_code: "AZA", registration_name: "EI-EJO", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55294" },
{ operator_icao_code: "AZA", registration_name: "EI-EJP", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55295" },
{ operator_icao_code: "AZA", registration_name: "EI-DIP", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55287" },
{ operator_icao_code: "AZA", registration_name: "EI-DIR", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55288" },
{ operator_icao_code: "AZA", registration_name: "EI-FNI", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55282" },
{ operator_icao_code: "AZA", registration_name: "EI-WLA", ship_number: "", type_subtype: "777-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55283" },
{ operator_icao_code: "AZU", registration_name: "PR-AIV", ship_number: "MSN532", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-AIZ", ship_number: "MSN527", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-AIY", ship_number: "MSN365", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-AIW", ship_number: "MSN462", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-AIX", ship_number: "MSN372", type_subtype: "330-200", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-AIU", ship_number: "MSN494", type_subtype: "330-200", system_assigned: "eX3,eX3-eXConnect", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-AIT", ship_number: "MSN529", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-ANZ", ship_number: "1876", type_subtype: "330-900", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51554" },
{ operator_icao_code: "AZU", registration_name: "PR-YYA", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-AIS", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-YYL", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-YYB", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-PJN", ship_number: "", type_subtype: "E190-E2", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "F-WWYF", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51555" },
{ operator_icao_code: "AZU", registration_name: "F-WWCK", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51556" },
{ operator_icao_code: "AZU", registration_name: "PR-ANY", ship_number: "", type_subtype: "330-900", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51559" },
{ operator_icao_code: "AZU", registration_name: "F-WWYO", ship_number: "", type_subtype: "330-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51533" },
{ operator_icao_code: "AZU", registration_name: "PR-YYI", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PS-AEA", ship_number: "", type_subtype: "E190-E2", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PS-AEB", ship_number: "", type_subtype: "E190-E2", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PS-AEC", ship_number: "", type_subtype: "E190-E2", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PS-AED", ship_number: "", type_subtype: "E190-E2", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PS-AEE", ship_number: "", type_subtype: "E190-E2", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PS-AEF", ship_number: "", type_subtype: "E190-E2", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PS-AEG", ship_number: "", type_subtype: "E190-E2", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-YYJ", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "AZU", registration_name: "PR-YYK", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAH", registration_name: "A9C-HAK", ship_number: "", type_subtype: "747-400F", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1209" },
{ operator_icao_code: "BAV", registration_name: "VN-A596", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAV", registration_name: "VN-A598", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAV", registration_name: "VN-A819", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAV", registration_name: "VN-A818", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAV", registration_name: "VN-A829", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAV", registration_name: "VN-A599", ship_number: "", type_subtype: "320-200N", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAV", registration_name: "D-AZAB", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVF", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVS", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVV", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVX", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVR", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVW", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-BYGF", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-BYGD", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVH", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVY", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-BYGG", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-BYGE", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVZ", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-BYGA", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-BYGB", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-BYGC", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVI", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-VIIO", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-VIIP", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-VIIR", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-VIIT", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-VIIU", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-VIIX", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVO", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVT", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVA", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-VIIV", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVC", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-VIIW", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVE", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVP", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-YMMC", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-VIIY", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-XWBA", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55629" },
{ operator_icao_code: "BAW", registration_name: "G-XWBB", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55630" },
{ operator_icao_code: "BAW", registration_name: "G-YMMF", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-CIVU", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-YMMD", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-YMMB", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-BNLY", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-XWBC", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55631" },
{ operator_icao_code: "BAW", registration_name: "G-YMME", ship_number: "", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-XWBD", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55632" },
{ operator_icao_code: "BAW", registration_name: "G-CIVB", ship_number: "", type_subtype: "747-400", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BAW", registration_name: "G-XWBE", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55633" },
{ operator_icao_code: "BAW", registration_name: "G-XWBF", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55634" },
{ operator_icao_code: "BBA", registration_name: "C-FBCS", ship_number: "", type_subtype: "220-300", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54461" },
{ operator_icao_code: "BBA", registration_name: "C-FOUY", ship_number: "", type_subtype: "220-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BBA", registration_name: "C-FOVH", ship_number: "", type_subtype: "220-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54472" },
{ operator_icao_code: "BBA", registration_name: "C-FPBE", ship_number: "", type_subtype: "220-300", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54462" },
{ operator_icao_code: "BBA", registration_name: "C-FPBQ", ship_number: "", type_subtype: "220-300", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54463" },
{ operator_icao_code: "BBA", registration_name: "C-FOVK", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54464" },
{ operator_icao_code: "BBA", registration_name: "C-FOVP", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54465" },
{ operator_icao_code: "BBA", registration_name: "55062", ship_number: "", type_subtype: "220-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BBA", registration_name: "C-FOVX", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54471" },
{ operator_icao_code: "BBA", registration_name: "C-FOWU", ship_number: "", type_subtype: "220-300", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54468" },
{ operator_icao_code: "BBA", registration_name: "C-FOWF", ship_number: "", type_subtype: "220-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BBA", registration_name: "SU-GFI", ship_number: "", type_subtype: "220-300", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54473" },
{ operator_icao_code: "BBC", registration_name: "S2-AJS", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1313" },
{ operator_icao_code: "BBC", registration_name: "S2-AJT", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1314" },
{ operator_icao_code: "BBC", registration_name: "S2-AJV", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1315" },
{ operator_icao_code: "BBC", registration_name: "S2-AJU", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57040" },
{ operator_icao_code: "BBC", registration_name: "S2-AJX", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57038" },
{ operator_icao_code: "BBC", registration_name: "S2-AJY", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57039" },
{ operator_icao_code: "BEJ", registration_name: "B2385", ship_number: "", type_subtype: "340-300", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2386", ship_number: "", type_subtype: "340-300", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2387", ship_number: "", type_subtype: "340-300", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2388", ship_number: "", type_subtype: "340-300", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2389", ship_number: "", type_subtype: "340-300", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2390", ship_number: "", type_subtype: "340-300", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2467", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2468", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2469", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2470", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2471", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2472", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2443", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2445", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2447", ship_number: "", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2059", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2060", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2061", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2063", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2064", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2065", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2066", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2067", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2068", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B2069", ship_number: "", type_subtype: "777-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEJ", registration_name: "B-1219", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEL", registration_name: "OO-SSH", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEL", registration_name: "OO-SSI", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEL", registration_name: "OO-SFC", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEL", registration_name: "OO-SFG", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEL", registration_name: "OO-SFE", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BEL", registration_name: "OO-SFF", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BER", registration_name: "D-ALPA", ship_number: "", type_subtype: "330-220", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BER", registration_name: "D-ABCM", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BER", registration_name: "D-ABCO", ship_number: "", type_subtype: "321-100", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BLX", registration_name: "SE-RFT", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BLX", registration_name: "SE-RFU", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BLX", registration_name: "SE-RNC", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BLX", registration_name: "G-OBYG", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BLX", registration_name: "SE-RFR", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BLX", registration_name: "SE-RFX", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BLX", registration_name: "SE-RFY", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BMA", registration_name: "G-WWBM", ship_number: "", type_subtype: "330-240", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BMA", registration_name: "G-WWBD", ship_number: "", type_subtype: "330-240", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BMA", registration_name: "G-WWBB", ship_number: "", type_subtype: "330-240", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC036", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC040", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C702", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C703", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1A023", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54285" },
{ operator_icao_code: "BOE", registration_name: "ZB980", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB908", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1A027", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54291" },
{ operator_icao_code: "BOE", registration_name: "1C706", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54292" },
{ operator_icao_code: "BOE", registration_name: "1C704", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54286" },
{ operator_icao_code: "BOE", registration_name: "1A028", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1A029", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1A030", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54298" },
{ operator_icao_code: "BOE", registration_name: "1A031", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54299" },
{ operator_icao_code: "BOE", registration_name: "1A032", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54300" },
{ operator_icao_code: "BOE", registration_name: "IA022", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1A022", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54284" },
{ operator_icao_code: "BOE", registration_name: "ZC038", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "AT-BOE67", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54358" },
{ operator_icao_code: "BOE", registration_name: "1A409", ship_number: "", type_subtype: "737-800", system_assigned: "eXW-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54293" },
{ operator_icao_code: "BOE", registration_name: "1C707", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54280" },
{ operator_icao_code: "BOE", registration_name: "ZB276", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB802", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZA955", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB981", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54175" },
{ operator_icao_code: "BOE", registration_name: "ZB982", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB984", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1A025", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54289" },
{ operator_icao_code: "BOE", registration_name: "WE768", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C946", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "B-205N", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC039", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "YW210", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "YW211", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB801", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB466", ship_number: "", type_subtype: "787-9", system_assigned: "CMS,eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB990", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB909", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE803", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC041", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB967", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "B-208E", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB804", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C947", ship_number: "", type_subtype: "737-800", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C708", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C709", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "C-FM", ship_number: "", type_subtype: "737-800", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1D466", ship_number: "", type_subtype: "737-900", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54306" },
{ operator_icao_code: "BOE", registration_name: "1A413", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54307" },
{ operator_icao_code: "BOE", registration_name: "1C710", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54308" },
{ operator_icao_code: "BOE", registration_name: "ZC009", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C711", ship_number: "", type_subtype: "737-800", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54309" },
{ operator_icao_code: "BOE", registration_name: "ZC043", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "NOTZB898", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE042", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1D467", ship_number: "", type_subtype: "737-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54310" },
{ operator_icao_code: "BOE", registration_name: "1A694", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1A695", ship_number: "", type_subtype: "737-800", system_assigned: "3000,eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C712", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54311" },
{ operator_icao_code: "BOE", registration_name: "ZB899", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE090", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB838", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC044", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB915", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "NOTZE196", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB74", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "Z774", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C713", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54312" },
{ operator_icao_code: "BOE", registration_name: "ZC116", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54243" },
{ operator_icao_code: "BOE", registration_name: "1C714", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54313" },
{ operator_icao_code: "BOE", registration_name: "ZB805", ship_number: "", type_subtype: "300-100", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C715", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54272" },
{ operator_icao_code: "BOE", registration_name: "NOT1K001", ship_number: "", type_subtype: "300-100", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54314" },
{ operator_icao_code: "BOE", registration_name: "ZB916", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB926", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC126", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE086", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1K351", ship_number: "HL8348", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1D468", ship_number: "", type_subtype: "737-900", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54316" },
{ operator_icao_code: "BOE", registration_name: "ZE004", ship_number: "", type_subtype: "300-100", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C716", ship_number: "", type_subtype: "737-800", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54274" },
{ operator_icao_code: "BOE", registration_name: "ZB840", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC117", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB900", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB117", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE197", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C717", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54277" },
{ operator_icao_code: "BOE", registration_name: "1D469", ship_number: "", type_subtype: "737-900", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54275" },
{ operator_icao_code: "BOE", registration_name: "ZB119", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "YU929", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE198", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZA876", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB841", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE220", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE166", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1K401", ship_number: "", type_subtype: "737-800", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54317" },
{ operator_icao_code: "BOE", registration_name: "ZE0085", ship_number: "", type_subtype: "787-9", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC211", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1K352", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54269" },
{ operator_icao_code: "BOE", registration_name: "ZB586", ship_number: "C-FVNF", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE091", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC127", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB917", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE221", ship_number: "", type_subtype: "787-9", system_assigned: "CMS,eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB744", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54264" },
{ operator_icao_code: "BOE", registration_name: "ZB901", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1K002", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE005", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZA956", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54197" },
{ operator_icao_code: "BOE", registration_name: "1C718", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54279" },
{ operator_icao_code: "BOE", registration_name: "ZE222", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "EW-528PA", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1D470", ship_number: "", type_subtype: "737-900", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54270" },
{ operator_icao_code: "BOE", registration_name: "ZC013", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE006", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE223", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB806", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54200" },
{ operator_icao_code: "BOE", registration_name: "1B210", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C719", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54278" },
{ operator_icao_code: "BOE", registration_name: "ZE170", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54202" },
{ operator_icao_code: "BOE", registration_name: "ZB927", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE096", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54199" },
{ operator_icao_code: "BOE", registration_name: "1A702", ship_number: "", type_subtype: "737-300", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB918", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB807", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54203" },
{ operator_icao_code: "BOE", registration_name: "1A958", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE224", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54205" },
{ operator_icao_code: "BOE", registration_name: "ZB902", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE225", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54206" },
{ operator_icao_code: "BOE", registration_name: "1A959", ship_number: "", type_subtype: "737-800", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB887", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54224" },
{ operator_icao_code: "BOE", registration_name: "ZB120", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC014", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "XA-MAZ", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB969", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB808", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54208" },
{ operator_icao_code: "BOE", registration_name: "ZB991", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1K354", ship_number: "", type_subtype: "737-800", system_assigned: "eXO-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54281" },
{ operator_icao_code: "BOE", registration_name: "1B211", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1A703", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1A704", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB121", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC212", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB937", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB809", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54210" },
{ operator_icao_code: "BOE", registration_name: "ZA968", ship_number: "JA846J", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54211" },
{ operator_icao_code: "BOE", registration_name: "ZE171", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54209" },
{ operator_icao_code: "BOE", registration_name: "ZA957", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB938", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C948", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54334" },
{ operator_icao_code: "BOE", registration_name: "ZB277", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE110", ship_number: "XA-MFG", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB810", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1C949", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54318" },
{ operator_icao_code: "BOE", registration_name: "ZC213", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54232" },
{ operator_icao_code: "BOE", registration_name: "ZB278", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB939", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZA959", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE426", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB940", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "WE805", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1A707", ship_number: "", type_subtype: "737-800", system_assigned: "eXO-eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1A414", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54282" },
{ operator_icao_code: "BOE", registration_name: "1A960", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "Z941B", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZA969", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54213" },
{ operator_icao_code: "BOE", registration_name: "ZC067", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC015", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54236" },
{ operator_icao_code: "BOE", registration_name: "WE586", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB433", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54215" },
{ operator_icao_code: "BOE", registration_name: "ZB279", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZA970", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54214" },
{ operator_icao_code: "BOE", registration_name: "1B213", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC214", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54238" },
{ operator_icao_code: "BOE", registration_name: "ZB942", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZA964", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC045", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC046", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54240" },
{ operator_icao_code: "BOE", registration_name: "WE769", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "1K355", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54283" },
{ operator_icao_code: "BOE", registration_name: "ZE226", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54216" },
{ operator_icao_code: "BOE", registration_name: "ZE380", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54241" },
{ operator_icao_code: "BOE", registration_name: "WE587", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC068", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54242" },
{ operator_icao_code: "BOE", registration_name: "WE806", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC101", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54222" },
{ operator_icao_code: "BOE", registration_name: "ZE227", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54217" },
{ operator_icao_code: "BOE", registration_name: "XA-HSB", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE350", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB970", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54248" },
{ operator_icao_code: "BOE", registration_name: "ZB435", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54177" },
{ operator_icao_code: "BOE", registration_name: "ZA990", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54176" },
{ operator_icao_code: "BOE", registration_name: "ZE016", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC047", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54247" },
{ operator_icao_code: "BOE", registration_name: "ZE415", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB986", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54178" },
{ operator_icao_code: "BOE", registration_name: "ZE351", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54250" },
{ operator_icao_code: "BOE", registration_name: "1C950", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54336" },
{ operator_icao_code: "BOE", registration_name: "ZE017", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54252" },
{ operator_icao_code: "BOE", registration_name: "ZE230", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "WE771", ship_number: "", type_subtype: "777-300", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB971", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54253" },
{ operator_icao_code: "BOE", registration_name: "ZE115", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54254" },
{ operator_icao_code: "BOE", registration_name: "ZE352", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB844", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB903", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54258" },
{ operator_icao_code: "BOE", registration_name: "ZE381", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "54257" },
{ operator_icao_code: "BOE", registration_name: "ZB944", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54231" },
{ operator_icao_code: "BOE", registration_name: "ZE353", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZA966", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZA971", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54182" },
{ operator_icao_code: "BOE", registration_name: "ZE044", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE481", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54259" },
{ operator_icao_code: "BOE", registration_name: "ZE416", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54183" },
{ operator_icao_code: "BOE", registration_name: "2352U", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZC016", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54263" },
{ operator_icao_code: "BOE", registration_name: "ZB919", ship_number: "", type_subtype: "220-100", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54262" },
{ operator_icao_code: "BOE", registration_name: "ZB421", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54186" },
{ operator_icao_code: "BOE", registration_name: "ZE231", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54185" },
{ operator_icao_code: "BOE", registration_name: "ZC102", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54266" },
{ operator_icao_code: "BOE", registration_name: "ZC129", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE232", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54187" },
{ operator_icao_code: "BOE", registration_name: "ZC216", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54267" },
{ operator_icao_code: "BOE", registration_name: "ZB987", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54188" },
{ operator_icao_code: "BOE", registration_name: "ZB845", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE045", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54268" },
{ operator_icao_code: "BOE", registration_name: "ZE354", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54265" },
{ operator_icao_code: "BOE", registration_name: "ZA863", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54190" },
{ operator_icao_code: "BOE", registration_name: "ZE355", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54223" },
{ operator_icao_code: "BOE", registration_name: "ZC217", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54221" },
{ operator_icao_code: "BOE", registration_name: "ZE234", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54192" },
{ operator_icao_code: "BOE", registration_name: "ZB920", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54225" },
{ operator_icao_code: "BOE", registration_name: "ZE233", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54191" },
{ operator_icao_code: "BOE", registration_name: "ZE075", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54193" },
{ operator_icao_code: "BOE", registration_name: "ZE356", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54226" },
{ operator_icao_code: "BOE", registration_name: "ZC103", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54227" },
{ operator_icao_code: "BOE", registration_name: "ZC225", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZB280", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE417", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54194" },
{ operator_icao_code: "BOE", registration_name: "ZB945", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOE", registration_name: "ZE357", ship_number: "", type_subtype: "220-100", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54229" },
{ operator_icao_code: "BOE", registration_name: "ZE312", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54228" },
{ operator_icao_code: "BOE", registration_name: "ZC069", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54230" },
{ operator_icao_code: "BOE", registration_name: "ZE007", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54195" },
{ operator_icao_code: "BOV", registration_name: "CP-2880", ship_number: "", type_subtype: "767-300", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOV", registration_name: "CP-2881", ship_number: "", type_subtype: "767-300", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOV", registration_name: "CP-3017", ship_number: "", type_subtype: "767-300", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BOV", registration_name: "CP-3086", ship_number: "", type_subtype: "767-300", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BRU", registration_name: "IC240", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSA", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSB", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSC", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSD", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSE", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSF", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSG", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-AAS", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-AAR", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSH", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSI", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSJ", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSK", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSL", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSM", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-CSN", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-AAO", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-AAP", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-AAQ", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTI", registration_name: "YL-AAT", ship_number: "", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTK", registration_name: "PK-LBG", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTK", registration_name: "PK-LBM", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTK", registration_name: "PK-LBH", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTK", registration_name: "PK-LBO", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "BTK", registration_name: "PK-LBJ", ship_number: "", type_subtype: "737-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "C2A", registration_name: "P4-XTL", ship_number: "", type_subtype: "777-200", system_assigned: "eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50486" },
{ operator_icao_code: "CAL", registration_name: "B-18301", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18302", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18303", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18305", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18306", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18210", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "3000i", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18211", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "3000i", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18212", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "3000i", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18215", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "3000i", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18307", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18308", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18309", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18310", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18351", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18311", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18312", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18315", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18352", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18316", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18317", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18353", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18355", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18391", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18392", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18208", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "F-WWYK", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18357", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18356", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18358", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18359", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18651", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18652", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18653", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18655", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18656", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18657", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18360", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18361", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18051", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51859" },
{ operator_icao_code: "CAL", registration_name: "NOTF-WWCP", ship_number: "1539", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18052", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51860" },
{ operator_icao_code: "CAL", registration_name: "B-18053", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51861" },
{ operator_icao_code: "CAL", registration_name: "B-18055", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51862" },
{ operator_icao_code: "CAL", registration_name: "B-18001", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51853" },
{ operator_icao_code: "CAL", registration_name: "B-18002", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51854" },
{ operator_icao_code: "CAL", registration_name: "B-18003", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51855" },
{ operator_icao_code: "CAL", registration_name: "B-18005", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51856" },
{ operator_icao_code: "CAL", registration_name: "B-18006", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51857" },
{ operator_icao_code: "CAL", registration_name: "B-18007", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51858" },
{ operator_icao_code: "CAL", registration_name: "B-18004", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18901", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51863" },
{ operator_icao_code: "CAL", registration_name: "B-18902", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51864" },
{ operator_icao_code: "CAL", registration_name: "B-18903", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51865" },
{ operator_icao_code: "CAL", registration_name: "B-18905", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51866" },
{ operator_icao_code: "CAL", registration_name: "B-18906", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51867" },
{ operator_icao_code: "CAL", registration_name: "B-18907", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51868" },
{ operator_icao_code: "CAL", registration_name: "B-18908", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51869" },
{ operator_icao_code: "CAL", registration_name: "B-18909", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51870" },
{ operator_icao_code: "CAL", registration_name: "B-18910", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51871" },
{ operator_icao_code: "CAL", registration_name: "B-18912", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51872" },
{ operator_icao_code: "CAL", registration_name: "B-18915", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51873" },
{ operator_icao_code: "CAL", registration_name: "B-18916", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51874" },
{ operator_icao_code: "CAL", registration_name: "B-18917", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51875" },
{ operator_icao_code: "CAL", registration_name: "B-18918", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51876" },
{ operator_icao_code: "CAL", registration_name: "B-18658", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18659", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18660", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18661", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18662", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18663", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18665", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18666", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CAL", registration_name: "B-18667", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6676", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6677", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6791", ship_number: "", type_subtype: "321-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6742", ship_number: "", type_subtype: "321-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6731", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6733", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6745", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6773", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6741", ship_number: "", type_subtype: "321-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6792", ship_number: "", type_subtype: "321-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5553", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5570", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5582", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5583", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5447", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5572", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5585", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5621", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5622", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5639", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5642", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6823", ship_number: "", type_subtype: "321-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6825", ship_number: "", type_subtype: "321-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6848", ship_number: "", type_subtype: "321-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6822", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6767", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6793", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6828", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6846", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6847", ship_number: "", type_subtype: "320-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6883", ship_number: "", type_subtype: "321-200", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5296", ship_number: "", type_subtype: "737-700", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5696", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6961", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5297", ship_number: "", type_subtype: "737-300", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5729", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-6973", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-9919", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5848", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5849", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1909", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5803", ship_number: "", type_subtype: "737-700", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5851", ship_number: "MSN41313", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-5793", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1947", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1945", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1816", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1946", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1942", ship_number: "40023", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1956", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1855", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1833", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1957", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-2485", ship_number: "", type_subtype: "747-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1876", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1958", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1959", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1978", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1976", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-2486", ship_number: "", type_subtype: "747-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-2487", ship_number: "RC068", type_subtype: "747-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-2479", ship_number: "RC069", type_subtype: "747-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1877", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-7879", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-7878", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-7877", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-2480", ship_number: "", type_subtype: "747-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-2482", ship_number: "", type_subtype: "747-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-7800", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-7898", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-7899", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1591", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1466", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1467", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1396", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1086", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "96726" },
{ operator_icao_code: "CCA", registration_name: "B-7832", ship_number: "ZB051", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1431", ship_number: "ZB055", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1468", ship_number: "ZB058", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1469", ship_number: "ZB059", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1368", ship_number: "ZB060", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1220", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CCA", registration_name: "B-1085", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "96725" },
{ operator_icao_code: "CCA", registration_name: "B-1083", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "96721" },
{ operator_icao_code: "CCA", registration_name: "B-1081", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "96722" },
{ operator_icao_code: "CCA", registration_name: "B-1082", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "96723" },
{ operator_icao_code: "CCA", registration_name: "B-1080", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "96724" },
{ operator_icao_code: "CCA", registration_name: "B-307A", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "96728" },
{ operator_icao_code: "CCA", registration_name: "B-307C", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "96727" },
{ operator_icao_code: "CCA", registration_name: "B-308C", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "96729" },
{ operator_icao_code: "CCA", registration_name: "B-308M", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "96730" },
{ operator_icao_code: "CDG", registration_name: "B-5728", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-5755", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-5757", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-5758", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-5856", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-1987", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-1988", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-1730", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-1983", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-1982", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-1985", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-1986", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-1731", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-1745", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-1743", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CDG", registration_name: "B-1746", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6430", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9907", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6427", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6439", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6591", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6592", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6642", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6643", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6668", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6753", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6755", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6886", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6923", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6925", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6926", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6927", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6930", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6951", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9903", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9921", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9927", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9950", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-5903", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95446" },
{ operator_icao_code: "CES", registration_name: "B-6646", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9970", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9942", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9943", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9946", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9941", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9945", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9906", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9947", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-6450", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9905", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9971", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-9975", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "NOTB-1947", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-5799", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-5808", ship_number: "", type_subtype: "737-700", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-1900", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-1948", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-2001", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95480" },
{ operator_icao_code: "CES", registration_name: "B-1812", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-1813", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-1933", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-5815", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-1967", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-2002", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95484" },
{ operator_icao_code: "CES", registration_name: "B-2003", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95482" },
{ operator_icao_code: "CES", registration_name: "B-2005", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95483" },
{ operator_icao_code: "CES", registration_name: "B-5902", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95448" },
{ operator_icao_code: "CES", registration_name: "B-5908", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11474" },
{ operator_icao_code: "CES", registration_name: "B-5920", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95704" },
{ operator_icao_code: "CES", registration_name: "B-5921", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95714" },
{ operator_icao_code: "CES", registration_name: "B-5926", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95721" },
{ operator_icao_code: "CES", registration_name: "B-5930", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95723" },
{ operator_icao_code: "CES", registration_name: "B-5931", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95720" },
{ operator_icao_code: "CES", registration_name: "B-5936", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95724" },
{ operator_icao_code: "CES", registration_name: "B-5937", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95726" },
{ operator_icao_code: "CES", registration_name: "B-5938", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95452" },
{ operator_icao_code: "CES", registration_name: "B-6537", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95443" },
{ operator_icao_code: "CES", registration_name: "B-6538", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11484" },
{ operator_icao_code: "CES", registration_name: "B-6543", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "95492" },
{ operator_icao_code: "CES", registration_name: "B-6545", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95715" },
{ operator_icao_code: "CES", registration_name: "B-6546", ship_number: "", type_subtype: "330-240", system_assigned: "eX3", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95450" },
{ operator_icao_code: "CES", registration_name: "B-5941", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95463" },
{ operator_icao_code: "CES", registration_name: "B-5942", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95467" },
{ operator_icao_code: "CES", registration_name: "B-5943", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95698" },
{ operator_icao_code: "CES", registration_name: "B-5949", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95444" },
{ operator_icao_code: "CES", registration_name: "B-5952", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95447" },
{ operator_icao_code: "CES", registration_name: "B-6099", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95454" },
{ operator_icao_code: "CES", registration_name: "B-6082", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95705" },
{ operator_icao_code: "CES", registration_name: "B-6083", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95700" },
{ operator_icao_code: "CES", registration_name: "B-6085", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95710" },
{ operator_icao_code: "CES", registration_name: "B-6095", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95708" },
{ operator_icao_code: "CES", registration_name: "B-6096", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95711" },
{ operator_icao_code: "CES", registration_name: "B-6097", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "95725" },
{ operator_icao_code: "CES", registration_name: "B-6100", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95699" },
{ operator_icao_code: "CES", registration_name: "B-6506", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95460" },
{ operator_icao_code: "CES", registration_name: "B-6507", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95457" },
{ operator_icao_code: "CES", registration_name: "B-5953", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95488" },
{ operator_icao_code: "CES", registration_name: "B-2020", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95486" },
{ operator_icao_code: "CES", registration_name: "B-2021", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95473" },
{ operator_icao_code: "CES", registration_name: "B-2022", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95727" },
{ operator_icao_code: "CES", registration_name: "B-2023", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95485" },
{ operator_icao_code: "CES", registration_name: "B-2025", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95477" },
{ operator_icao_code: "CES", registration_name: "B-7343", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95717" },
{ operator_icao_code: "CES", registration_name: "B-7347", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95718" },
{ operator_icao_code: "CES", registration_name: "B-7368", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95537" },
{ operator_icao_code: "CES", registration_name: "B-7369", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95478" },
{ operator_icao_code: "CES", registration_name: "B-5962", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11511" },
{ operator_icao_code: "CES", registration_name: "B-7349", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95474" },
{ operator_icao_code: "CES", registration_name: "B-5969", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11512" },
{ operator_icao_code: "CES", registration_name: "B-7365", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95475" },
{ operator_icao_code: "CES", registration_name: "B-5968", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95493" },
{ operator_icao_code: "CES", registration_name: "B-7367", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95449" },
{ operator_icao_code: "CES", registration_name: "B-5973", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95459" },
{ operator_icao_code: "CES", registration_name: "B-5975", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95461" },
{ operator_icao_code: "CES", registration_name: "B-5976", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95462" },
{ operator_icao_code: "CES", registration_name: "CEA-1788", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-8968", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95469" },
{ operator_icao_code: "CES", registration_name: "B-8231", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11518" },
{ operator_icao_code: "CES", registration_name: "B-7868", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95471" },
{ operator_icao_code: "CES", registration_name: "B-7881", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95476" },
{ operator_icao_code: "CES", registration_name: "B-7883", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95481" },
{ operator_icao_code: "CES", registration_name: "B-7882", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95479" },
{ operator_icao_code: "CES", registration_name: "B-8226", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95445" },
{ operator_icao_code: "CES", registration_name: "B-8967", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95451" },
{ operator_icao_code: "CES", registration_name: "B-8972", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95713" },
{ operator_icao_code: "CES", registration_name: "F-WWCS", ship_number: "1809", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-8862", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95468" },
{ operator_icao_code: "CES", registration_name: "B-8971", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95712" },
{ operator_icao_code: "CES", registration_name: "B-8970", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95470" },
{ operator_icao_code: "CES", registration_name: "B-005C", ship_number: "1816", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-5961", ship_number: "", type_subtype: "330-", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95719" },
{ operator_icao_code: "CES", registration_name: "B-8863", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95729" },
{ operator_icao_code: "CES", registration_name: "B-005D", ship_number: "1841", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-1041", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95441" },
{ operator_icao_code: "CES", registration_name: "B-1049", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95453" },
{ operator_icao_code: "CES", registration_name: "B-1066", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95455" },
{ operator_icao_code: "CES", registration_name: "B-1111", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11531" },
{ operator_icao_code: "CES", registration_name: "B-1073", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95458" },
{ operator_icao_code: "CES", registration_name: "B-300Q", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95466" },
{ operator_icao_code: "CES", registration_name: "B-300P", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95465" },
{ operator_icao_code: "CES", registration_name: "B-1112", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11532" },
{ operator_icao_code: "CES", registration_name: "B-1113", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11533" },
{ operator_icao_code: "CES", registration_name: "B-303D", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95464" },
{ operator_icao_code: "CES", registration_name: "B-206K", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11534" },
{ operator_icao_code: "CES", registration_name: "B-304A", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "95701" },
{ operator_icao_code: "CES", registration_name: "B-304D", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95703" },
{ operator_icao_code: "CES", registration_name: "B-208P", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11463" },
{ operator_icao_code: "CES", registration_name: "F-WWIWNOT", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-304N", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95706" },
{ operator_icao_code: "CES", registration_name: "F-WZNF", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-305X", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95702" },
{ operator_icao_code: "CES", registration_name: "B-208X", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11462" },
{ operator_icao_code: "CES", registration_name: "ZB898", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-306Y", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95707" },
{ operator_icao_code: "CES", registration_name: "B-308E", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95751" },
{ operator_icao_code: "CES", registration_name: "B-209N", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95709" },
{ operator_icao_code: "CES", registration_name: "B-307Y", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95750" },
{ operator_icao_code: "CES", registration_name: "B-20AJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95722" },
{ operator_icao_code: "CES", registration_name: "B-20CD", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95442" },
{ operator_icao_code: "CES", registration_name: "B-1401", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-20D8", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95752" },
{ operator_icao_code: "CES", registration_name: "D-AXAL", ship_number: "", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-30CW", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "95456" },
{ operator_icao_code: "CES", registration_name: "B-20EG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CES", registration_name: "B-30FM", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CFG", registration_name: "D-ABUZ", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CFG", registration_name: "D-ABUA", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CFG", registration_name: "D-ABUB", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CFG", registration_name: "D-ABUC", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CFG", registration_name: "D-ABUD", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CFG", registration_name: "D-ABUE", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CFG", registration_name: "D-ABUF", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CFG", registration_name: "D-ABUH", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CFG", registration_name: "D-ABUI", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7880", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12209" },
{ operator_icao_code: "CHH", registration_name: "B-7172", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1505", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1502", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1503", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6062", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6060", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1501", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1799", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6066", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6808", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6809", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6061", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6063", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6065", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7171", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5623", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1728", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1992", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1725", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1726", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1727", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1729", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1733", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1735", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1783", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1785", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1786", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1787", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1795", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1796", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1797", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1798", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7618", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1905", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1927", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1928", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1929", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1995", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1996", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1997", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5288", ship_number: "", type_subtype: "737-700", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5289", ship_number: "", type_subtype: "737-700", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5611", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5620", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5625", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5636", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5637", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5638", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5661", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5662", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5663", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5685", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5686", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5687", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5711", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5712", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5713", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5797", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5798", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5813", ship_number: "", type_subtype: "737-700", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5852", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5853", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-5855", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6943", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7835", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12210" },
{ operator_icao_code: "CHH", registration_name: "B-7837", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12211" },
{ operator_icao_code: "CHH", registration_name: "B-7839", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12212" },
{ operator_icao_code: "CHH", registration_name: "B-8117", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1539", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12213" },
{ operator_icao_code: "CHH", registration_name: "B-1540", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12214" },
{ operator_icao_code: "CHH", registration_name: "B-1543", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1546", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7173", ship_number: "YT560", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7175", ship_number: "", type_subtype: "737-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7886", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7616", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1541", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7639", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7619", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1793", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1902", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6998", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12218" },
{ operator_icao_code: "CHH", registration_name: "B-6969", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12217" },
{ operator_icao_code: "CHH", registration_name: "B-7302", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7667", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12220" },
{ operator_icao_code: "CHH", registration_name: "B-1547", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1548", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1549", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1538", ship_number: "YV030", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1545", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-9015", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6088", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6116", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-8016", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-8118", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-8659", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-8776", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6089", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1499", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1345", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12232" },
{ operator_icao_code: "CHH", registration_name: "B-1341", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1342", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1343", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12235" },
{ operator_icao_code: "CHH", registration_name: "B-1135", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12236" },
{ operator_icao_code: "CHH", registration_name: "B-7398", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7377", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7378", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7400", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1488", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1492", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1493", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1496", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1498", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-6103", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7379", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-7399", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1346", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1487", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1489", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1491", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1490", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1495", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1497", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1390", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1372", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1389", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1375", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1373", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1386", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1213", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1215", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1133", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12238" },
{ operator_icao_code: "CHH", registration_name: "B-1132", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E1: EMS (Prototype)", antenna_gen: "GEN1",xid: "12237" },
{ operator_icao_code: "CHH", registration_name: "B-1102", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1138", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12240" },
{ operator_icao_code: "CHH", registration_name: "B-1119", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12239" },
{ operator_icao_code: "CHH", registration_name: "B-1139", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1140", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12241" },
{ operator_icao_code: "CHH", registration_name: "B-206R", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12242" },
{ operator_icao_code: "CHH", registration_name: "B-207J", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "12243" },
{ operator_icao_code: "CHH", registration_name: "B-207V", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "C: Commercial", antenna_gen: "GEN1",xid: "12244" },
{ operator_icao_code: "CHH", registration_name: "B-207U", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "C: Commercial", antenna_gen: "GEN1",xid: "12245" },
{ operator_icao_code: "CHH", registration_name: "B-1100", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1101", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1103", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1130", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1131", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1141", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1387", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1388", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-206F", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-206G", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-206H", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-206Q", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1542", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "ZB804NOT", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1069", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "B-1070", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CHH", registration_name: "N708LF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1725CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1726CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1727CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1728CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1729CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1730CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1821CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1823CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1824CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1822CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1825CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1826CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1827CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1828CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1829CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1830CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1831CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1832CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1833CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1834CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1835CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1836CMP", ship_number: "YS355", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1837CMP", ship_number: "YS356", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1838CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1839CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1840CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1841CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1842CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1843CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1845CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1844CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1846CMP", ship_number: "YS365", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1847CMP", ship_number: "YS366", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1848CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1849CMP", ship_number: "YS367", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1851CMP", ship_number: "YS368", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1856CMP", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-1857CMP", ship_number: "YV413", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-9901CMP", ship_number: "", type_subtype: "737-900", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-9903CMP", ship_number: "", type_subtype: "737-900", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-9904CMP", ship_number: "", type_subtype: "737-900", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-9905CMP", ship_number: "", type_subtype: "737-900", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CMP", registration_name: "HP-9906CMP", ship_number: "", type_subtype: "737-900", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HLP", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HLQ", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HLR", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HLS", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HLU", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HLV", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HLW", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNM", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNN", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNO", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNH", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNG", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNF", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNE", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNI", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNJ", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNK", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HLD", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HLF", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HLH", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNP", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAE", ship_number: "7801CA", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAF", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNQ", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAD", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAJ", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAK", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPT", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAL", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPV", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAM", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPW", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPX", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAP", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAQ", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAR", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAX", ship_number: "1366", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPY", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KPZ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAN", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAO", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LAZ", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LBA", ship_number: "1409", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LBB", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LBC", ship_number: "1443", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQT", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQV", ship_number: "B77W", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQW", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQX", ship_number: "WE602", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LBJ", ship_number: "1618", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQY", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-KQZ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-CXB", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LRA", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50459" },
{ operator_icao_code: "CPA", registration_name: "B-LRB", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50428" },
{ operator_icao_code: "CPA", registration_name: "B-LRC", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50430" },
{ operator_icao_code: "CPA", registration_name: "B-LRD", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50432" },
{ operator_icao_code: "CPA", registration_name: "B-LRE", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50437" },
{ operator_icao_code: "CPA", registration_name: "B-LRF", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50443" },
{ operator_icao_code: "CPA", registration_name: "B-LRG", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50440" },
{ operator_icao_code: "CPA", registration_name: "B-LRI", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50441" },
{ operator_icao_code: "CPA", registration_name: "B-LRJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50436" },
{ operator_icao_code: "CPA", registration_name: "B-LRK", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50438" },
{ operator_icao_code: "CPA", registration_name: "B-LRL", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50433" },
{ operator_icao_code: "CPA", registration_name: "B-LRM", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50457" },
{ operator_icao_code: "CPA", registration_name: "NOTF-WZGK", ship_number: "38", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "F-WZGL", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "F-WZFB", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LRN", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50455" },
{ operator_icao_code: "CPA", registration_name: "B-LRP", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50454" },
{ operator_icao_code: "CPA", registration_name: "B-LRO", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50434" },
{ operator_icao_code: "CPA", registration_name: "B-LRS", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50456" },
{ operator_icao_code: "CPA", registration_name: "B-LRT", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50435" },
{ operator_icao_code: "CPA", registration_name: "B-LRR", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50431" },
{ operator_icao_code: "CPA", registration_name: "B-LRU", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50429" },
{ operator_icao_code: "CPA", registration_name: "B-LRV", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50458" },
{ operator_icao_code: "CPA", registration_name: "B-LRX", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50439" },
{ operator_icao_code: "CPA", registration_name: "NOTF-WZNA", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXA", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50417" },
{ operator_icao_code: "CPA", registration_name: "B-LXB", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50414" },
{ operator_icao_code: "CPA", registration_name: "B-LXC", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50413" },
{ operator_icao_code: "CPA", registration_name: "B-LXI", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50422" },
{ operator_icao_code: "CPA", registration_name: "B-LXN", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXF", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50419" },
{ operator_icao_code: "CPA", registration_name: "B-LXG", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50420" },
{ operator_icao_code: "CPA", registration_name: "B-LXH", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50421" },
{ operator_icao_code: "CPA", registration_name: "B-LXJ", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50423" },
{ operator_icao_code: "CPA", registration_name: "B-LXK", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50424" },
{ operator_icao_code: "CPA", registration_name: "B-LXL", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50425" },
{ operator_icao_code: "CPA", registration_name: "B-LXM", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXO", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXP", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXQ", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXR", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXS", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXT", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXU", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXV", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXW", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXX", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXY", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXZ", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LRQ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50460" },
{ operator_icao_code: "CPA", registration_name: "F-WZGSNOT", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNS", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "NOF-WZGW", ship_number: "169", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LXD", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50418" },
{ operator_icao_code: "CPA", registration_name: "B-LXE", ship_number: "", type_subtype: "350-1000", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50416" },
{ operator_icao_code: "CPA", registration_name: "F-WZH", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNU", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "F-WWBW", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-HNV", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LQA", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50426" },
{ operator_icao_code: "CPA", registration_name: "B-HNW", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "B-LQB", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50427" },
{ operator_icao_code: "CPA", registration_name: "B-HNX", ship_number: "WB649", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CPA", registration_name: "F-WZGQ", ship_number: "", type_subtype: "350-1000", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50445" },
{ operator_icao_code: "CPA", registration_name: "F-WZFO", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50444" },
{ operator_icao_code: "CPA", registration_name: "F-WZFD", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50446" },
{ operator_icao_code: "CQN", registration_name: "D-AZAP", ship_number: "", type_subtype: "321-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LHD", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LNC", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LND", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LNE", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LNG", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LNF", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LNI", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LPB", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LPC", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LNR", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LNS", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LNU", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "B-LNT", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRK", registration_name: "F-WWAW", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRL", registration_name: "F-HJAZ", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CRL", registration_name: "F-HROK", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-6518", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-6517", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-6535", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-5923", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-5929", ship_number: "MSN1432", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-5945", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-8468", ship_number: "1746", type_subtype: "330-240", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-5960", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-8332", ship_number: "", type_subtype: "300-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-8962", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-8589", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSC", registration_name: "B-8690", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "90209" },
{ operator_icao_code: "CSN", registration_name: "B-5587", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5596", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5597", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5609", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5275", ship_number: "", type_subtype: "737-700", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5281", ship_number: "", type_subtype: "737-700", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5586", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5598", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6775", ship_number: "", type_subtype: "320-214", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6776", ship_number: "", type_subtype: "320-214", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6782", ship_number: "", type_subtype: "320-214", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6783", ship_number: "", type_subtype: "320-214", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6785", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6817", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6815", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6813", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6812", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6816", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6827", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6761", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6762", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6786", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6826", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6136", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6137", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6138", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6139", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6140", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5917", ship_number: "", type_subtype: "330-320", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "11185" },
{ operator_icao_code: "CSN", registration_name: "B-2725", ship_number: "ZA380", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-2733", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5922", ship_number: "", type_subtype: "330-320", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "11186" },
{ operator_icao_code: "CSN", registration_name: "B-5928", ship_number: "", type_subtype: "330-320", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "11187" },
{ operator_icao_code: "CSN", registration_name: "B-5283", ship_number: "", type_subtype: "737-700", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5285", ship_number: "", type_subtype: "737-700", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5290", ship_number: "", type_subtype: "737-700", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5291", ship_number: "", type_subtype: "737-700", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5640", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5641", ship_number: "", type_subtype: "737-700", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5643", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5645", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5646", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5675", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5676", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5677", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5678", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5697", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5698", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5699", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5715", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5716", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5717", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5718", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6766", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6895", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6896", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6897", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6908", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6909", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6910", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6911", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6912", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6913", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6945", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6946", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6975", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-6977", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-2732", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-2727", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-2736", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-2726", ship_number: "ZA381", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-2735", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-2737", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5939", ship_number: "", type_subtype: "330-320", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11188" },
{ operator_icao_code: "CSN", registration_name: "B-2787", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5940", ship_number: "", type_subtype: "330-320", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11189" },
{ operator_icao_code: "CSN", registration_name: "B-5951", ship_number: "", type_subtype: "330-320", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11190" },
{ operator_icao_code: "CSN", registration_name: "B-2788", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5959", ship_number: "", type_subtype: "330-320", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11191" },
{ operator_icao_code: "CSN", registration_name: "B-5965", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11192" },
{ operator_icao_code: "CSN", registration_name: "B-5966", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11193" },
{ operator_icao_code: "CSN", registration_name: "B-5970", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-8359", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-5967", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11194" },
{ operator_icao_code: "CSN", registration_name: "B-1410", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1411", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1409", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-8363", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11197" },
{ operator_icao_code: "CSN", registration_name: "B-8362", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11196" },
{ operator_icao_code: "CSN", registration_name: "B-1408", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1403", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1413", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-8361", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11195" },
{ operator_icao_code: "CSN", registration_name: "B-1405", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1406", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "1A678", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-8365", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11198" },
{ operator_icao_code: "CSN", registration_name: "B-8366", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11199" },
{ operator_icao_code: "CSN", registration_name: "B-1407", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "1A680", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1402", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "1A681", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1203", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1363", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1062", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11200" },
{ operator_icao_code: "CSN", registration_name: "R-6522", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1367", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1365", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1362", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1242", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E1: EMS (Prototype)", antenna_gen: "GEN1",xid: "11203" },
{ operator_icao_code: "CSN", registration_name: "B-1237", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "NOTF-WWCLNOT", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1063", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11201" },
{ operator_icao_code: "CSN", registration_name: "B-1238", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1283", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "NOTTF-WWCD", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1243", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11204" },
{ operator_icao_code: "CSN", registration_name: "B-1236", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1297", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11207" },
{ operator_icao_code: "CSN", registration_name: "B-1065", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11202" },
{ operator_icao_code: "CSN", registration_name: "B-1239", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1293", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11205" },
{ operator_icao_code: "CSN", registration_name: "B-1241", ship_number: "", type_subtype: "737-900", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1295", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1169", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11206" },
{ operator_icao_code: "CSN", registration_name: "B-1240", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1128", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "", antenna_gen: "GEN1",xid: "11208" },
{ operator_icao_code: "CSN", registration_name: "B-1286", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1285", ship_number: "", type_subtype: "737-800", system_assigned: "eXO-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1299", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1167", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11209" },
{ operator_icao_code: "CSN", registration_name: "B-1168", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11214" },
{ operator_icao_code: "CSN", registration_name: "B-300V", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11210" },
{ operator_icao_code: "CSN", registration_name: "B-300U", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "91217" },
{ operator_icao_code: "CSN", registration_name: "B-1121", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1296", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1126", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6504" },
{ operator_icao_code: "CSN", registration_name: "B-1122", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1127", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1298", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1165", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "F-WWCV", ship_number: "1894", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1166", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-005A", ship_number: "1885", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1155", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1170", ship_number: "", type_subtype: "737-800", system_assigned: "eXO-eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6507" },
{ operator_icao_code: "CSN", registration_name: "B-1153", ship_number: "", type_subtype: "300-100", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1125", ship_number: "", type_subtype: "300-100", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-205M", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-206A", ship_number: "", type_subtype: "300-100", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-1175", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "F-WWBF", ship_number: "", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-205L", ship_number: "", type_subtype: "787-8", system_assigned: "eXO-eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-205P", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-305M", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "NOTZB967", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-303H", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-301L", ship_number: "8636", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-301K", ship_number: "", type_subtype: "320-100", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-209A", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-305N", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "F-WWBU", ship_number: "", type_subtype: "300-100", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-000U", ship_number: "8806", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-305E", ship_number: "8739", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-305C", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-209E", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "91219" },
{ operator_icao_code: "CSN", registration_name: "B-209X", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "91220" },
{ operator_icao_code: "CSN", registration_name: "B-20AA", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "91221" },
{ operator_icao_code: "CSN", registration_name: "B-209D", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "91218" },
{ operator_icao_code: "CSN", registration_name: "B-306H", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-209S", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-209T", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-306G", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-305Q", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-306K", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-306J", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-20C6", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "91222" },
{ operator_icao_code: "CSN", registration_name: "F-WWDR", ship_number: "9035", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-20CJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "91223" },
{ operator_icao_code: "CSN", registration_name: "B-20D7", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "91224" },
{ operator_icao_code: "CSN", registration_name: "B20C7", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-20AU", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-209U", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-309X", ship_number: "9133", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-307S", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-309K", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-308D", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-20D3", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-20C9", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-20C8", ship_number: "", type_subtype: "737-800", system_assigned: "eXO-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-309Y", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-308U", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-30AK", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-30A8", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-309M", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "N233BE", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-000V", ship_number: "9535", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-007L", ship_number: "9579", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-20EP", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-20EW", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSN", registration_name: "B-30DG", ship_number: "", type_subtype: "320-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5670", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5671", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5673", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5690", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6806", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6807", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6833", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6835", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6853", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6855", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6856", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6857", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6935", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6937", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6938", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6939", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-9909", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5736", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5737", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5771", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5772", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5773", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5775", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5776", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5778", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5859", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5860", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-9908", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-9910", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-9938", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-9939", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-9979", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-9980", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-8865", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1516", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1517", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1518", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1519", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1601", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1602", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1683", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1685", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1710", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1711", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1712", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1713", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1755", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1756", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1757", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1758", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1759", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1841", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1842", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1935", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1936", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1937", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1938", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1939", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1940", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1941", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1972", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1973", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1993", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-8077", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-8078", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-8079", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-8219", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-8220", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-8409", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-2666", ship_number: "", type_subtype: "737-700", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-2667", ship_number: "", type_subtype: "737-700", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-2668", ship_number: "", type_subtype: "737-700", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-2669", ship_number: "", type_subtype: "737-700", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-2678", ship_number: "", type_subtype: "737-700", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5073", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5075", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5078", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5079", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5186", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5187", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5317", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5322", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5345", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5357", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5377", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5378", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5379", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-5410", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6153", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6159", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6165", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6196", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6197", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6286", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6296", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6297", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6312", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6313", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6315", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6316", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6351", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6352", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6357", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6358", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6359", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6360", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6377", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6392", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6550", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6563", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6565", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6566", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6567", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6568", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6569", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6570", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6571", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6589", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6613", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6615", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6647", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6648", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6649", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6650", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6690", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6691", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6692", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6720", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6721", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6740", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6749", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6750", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6780", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6781", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-7083", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-7749", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-7750", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-1017", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-6722", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CSZ", registration_name: "B-302E", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CXA", registration_name: "B-2768", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "11953" },
{ operator_icao_code: "CXA", registration_name: "B-2760", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "11955" },
{ operator_icao_code: "CXA", registration_name: "B-2761", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "11956" },
{ operator_icao_code: "CXA", registration_name: "B-2762", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "11957" },
{ operator_icao_code: "CXA", registration_name: "B-2763", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "11958" },
{ operator_icao_code: "CXA", registration_name: "B-2769", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "11962" },
{ operator_icao_code: "CXA", registration_name: "B-1566", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11954" },
{ operator_icao_code: "CXA", registration_name: "B-1567", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11959" },
{ operator_icao_code: "CXA", registration_name: "B-7838", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11961" },
{ operator_icao_code: "CXA", registration_name: "B-7836", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11960" },
{ operator_icao_code: "CXA", registration_name: "B-1356", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11963" },
{ operator_icao_code: "CXA", registration_name: "B-1357", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "11964" },
{ operator_icao_code: "CXA", registration_name: "B-1537", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CYP", registration_name: "5B-DBS", ship_number: "505", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CYP", registration_name: "5B-DBT", ship_number: "526", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "CYP", registration_name: "5B-DCG", ship_number: "", type_subtype: "320-200", system_assigned: "Other", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VJA", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VKM", ship_number: "YV383", type_subtype: "737-800", system_assigned: "DMPES,eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VJC", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VJV", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VJW", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VJX", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VJY", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VJZ", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VJB", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VKK", ship_number: "YV381", type_subtype: "737-800", system_assigned: "DMPES,eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VKL", ship_number: "YV382", type_subtype: "737-800", system_assigned: "DMPES,eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VKN", ship_number: "YV384", type_subtype: "737-800", system_assigned: "DMPES,eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VKO", ship_number: "YV385", type_subtype: "737-800", system_assigned: "DMPES,eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VKP", ship_number: "YV386", type_subtype: "737-800", system_assigned: "DMPES,eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VKQ", ship_number: "YV387", type_subtype: "737-800", system_assigned: "DMPES,eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VKR", ship_number: "YV388", type_subtype: "737-800", system_assigned: "DMPES,eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VKS", ship_number: "YG721", type_subtype: "737-800", system_assigned: "DMPES,eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAH", registration_name: "7T-VKT", ship_number: "YG722", type_subtype: "737-800", system_assigned: "DMPES,eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N685DA", ship_number: "685", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6708D", ship_number: "6708", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6709", ship_number: "6709", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6715C", ship_number: "6715", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1611B", ship_number: "1611", type_subtype: "767-300ER", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6716C", ship_number: "6716", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N67171", ship_number: "6717", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1613B", ship_number: "1613", type_subtype: "767-300ER", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N152DL", ship_number: "1502", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N154DL", ship_number: "1504", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N155DL", ship_number: "1505", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N156DL", ship_number: "1506", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N684DA", ship_number: "684", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N693DL", ship_number: "693", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N694DL", ship_number: "694", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N695DL", ship_number: "695", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N696DL", ship_number: "696", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N697DL", ship_number: "697", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N698DL", ship_number: "698", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N699DL", ship_number: "699", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6700", ship_number: "6700", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6701", ship_number: "6701", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6702", ship_number: "6702", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6703D", ship_number: "6703", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6704Z", ship_number: "6704", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6705Y", ship_number: "6705", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6706Q", ship_number: "6706", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6707A", ship_number: "6707", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6710E", ship_number: "6710", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6711M", ship_number: "6711", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6712B", ship_number: "6712", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6713Y", ship_number: "6713", type_subtype: "757-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N6714Q", ship_number: "6714", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N392DA", ship_number: "3722", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N393DA", ship_number: "3723", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N394DA", ship_number: "3724", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N687DL", ship_number: "687", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N692DL", ship_number: "692", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N690DL", ship_number: "690", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N689DL", ship_number: "689", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N688DL", ship_number: "688", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1607B", ship_number: "1607", type_subtype: "767-300ER", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1609", ship_number: "1609", type_subtype: "767-300ER", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1612T", ship_number: "1612", type_subtype: "767-300ER", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1610D", ship_number: "1610", type_subtype: "767-300ER", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1608", ship_number: "1608", type_subtype: "767-300ER", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N192DN", ship_number: "192", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1605", ship_number: "1605", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1604R", ship_number: "1604", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1603", ship_number: "1603", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1602", ship_number: "1602", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N169DZ", ship_number: "1601", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N198DN", ship_number: "198", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N196DN", ship_number: "196", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N172DZ", ship_number: "1702", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N171DZ", ship_number: "1701", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N197DN", ship_number: "197", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N195DN", ship_number: "195", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N194DN", ship_number: "194", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N193DN", ship_number: "193", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N176DZ", ship_number: "1706", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N175DZ", ship_number: "1705", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N174DZ", ship_number: "1704", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N173DZ", ship_number: "1703", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N178DZ", ship_number: "1708", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N16065", ship_number: "1606", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N177DZ", ship_number: "1707", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N825MH", ship_number: "1801", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N866DA", ship_number: "7007", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N840MH", ship_number: "1816", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N827MH", ship_number: "1803", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N839MH", ship_number: "1815", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N829MH", ship_number: "1805", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3768", ship_number: "3768", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N830MH", ship_number: "1806", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N863DA", ship_number: "7004", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N862DA", ship_number: "7003", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N864DA", ship_number: "7005", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N865DA", ship_number: "7006", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N832MH", ship_number: "1808", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N845MH", ship_number: "1821", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N844MH", ship_number: "1820", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N843MH", ship_number: "1819", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N842MH", ship_number: "1818", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N841MH", ship_number: "1817", type_subtype: "767-400", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3771K", ship_number: "3771", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N37700", ship_number: "3770", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3769L", ship_number: "3769", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N867DA", ship_number: "7008", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N382DA", ship_number: "3712", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3744F", ship_number: "3744", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3753", ship_number: "3753", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3755D", ship_number: "3755", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3756", ship_number: "3756", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3764D", ship_number: "3764", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3767", ship_number: "3767", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N387DA", ship_number: "3717", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N391DA", ship_number: "3721", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N377DA", ship_number: "3707", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N397DA", ship_number: "3727", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N371DA", ship_number: "3701", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3754A", ship_number: "3754", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3765", ship_number: "3765", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3766", ship_number: "3766", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N854NW", ship_number: "3354", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N802NW", ship_number: "3302", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N803NW", ship_number: "3303", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N804NW", ship_number: "3304", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N805NW", ship_number: "3305", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N806NW", ship_number: "3306", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N807NW", ship_number: "3307", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N808NW", ship_number: "3308", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N809NW", ship_number: "3309", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N810NW", ship_number: "3310", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N811NW", ship_number: "3311", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N813NW", ship_number: "3313", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N814NW", ship_number: "3314", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N815NW", ship_number: "3315", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N816NW", ship_number: "3316", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N817NW", ship_number: "3317", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N818NW", ship_number: "3318", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N819NW", ship_number: "3319", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N820NW", ship_number: "3320", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N821NW", ship_number: "3321", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N823NW", ship_number: "3323", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N851NW", ship_number: "3351", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N853NW", ship_number: "3353", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N855NW", ship_number: "3355", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N857NW", ship_number: "3357", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N856NW", ship_number: "3356", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N852NW", ship_number: "3352", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N342NW", ship_number: "3242", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N550NW", ship_number: "5650", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N551NW", ship_number: "5651", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N552NW", ship_number: "5652", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N553NW", ship_number: "5653", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N554NW", ship_number: "5654", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N346NB", ship_number: "3146", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N365NB", ship_number: "3165", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N302NB", ship_number: "3102", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N584NW", ship_number: "5804", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N594NW", ship_number: "5814", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N595NW", ship_number: "5815", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N581NW", ship_number: "5801", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N585NW", ship_number: "5805", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N593NW", ship_number: "5813", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N592NW", ship_number: "5812", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N591NW", ship_number: "5811", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N590NW", ship_number: "5810", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N589NW", ship_number: "5809", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N588NW", ship_number: "5808", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N587NW", ship_number: "5807", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N586NW", ship_number: "5806", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N583NW", ship_number: "5803", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N582NW", ship_number: "5802", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N596NW", ship_number: "5816", type_subtype: "757-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N801NW", ship_number: "3301", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N858NW", ship_number: "3358", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N859NW", ship_number: "3359", type_subtype: "330-200", system_assigned: "eX1", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N860NW", ship_number: "3360", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N812NW", ship_number: "3312", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N861NW", ship_number: "3361", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N187DN", ship_number: "187", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N191DN", ship_number: "191", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N860DA", ship_number: "7001", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N181DN", ship_number: "181", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N190DN", ship_number: "190", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N199DN", ship_number: "199", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N185DN", ship_number: "185", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N180DN", ship_number: "180", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N182DN", ship_number: "182", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N183DN", ship_number: "183", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N184DN", ship_number: "184", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N186DN", ship_number: "186", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N188DN", ship_number: "188", type_subtype: "767-300ER", system_assigned: "eX1,eX2", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N189DN", ship_number: "189", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1200K", ship_number: "1200", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N1201P", ship_number: "1201", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N394DL", ship_number: "1521", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N171DN", ship_number: "171", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N172DN", ship_number: "172", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N174DN", ship_number: "174", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N175DN", ship_number: "175", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N176DN", ship_number: "176", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N177DN", ship_number: "177", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N178DN", ship_number: "178", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N179DN", ship_number: "179", type_subtype: "767-300ER", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N861DA", ship_number: "7002", type_subtype: "777-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3745B", ship_number: "3745", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3746H", ship_number: "3746", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3747D", ship_number: "3747", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3748Y", ship_number: "3748", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3749D", ship_number: "3749", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3750D", ship_number: "3750", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3751B", ship_number: "3751", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3757D", ship_number: "3757", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3758Y", ship_number: "3758", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3759", ship_number: "3759", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3760C", ship_number: "3760", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3761R", ship_number: "3761", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3762Y", ship_number: "3762", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3763D", ship_number: "3763", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N713TW", ship_number: "6809", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N701DN", ship_number: "7101", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N718TW", ship_number: "6815", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N702DN", ship_number: "7102", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N711ZX", ship_number: "6814", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N705TW", ship_number: "6811", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N722TW", ship_number: "6816", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N706TW", ship_number: "6804", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N723TW", ship_number: "6817", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N702TW", ship_number: "6801", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N703TW", ship_number: "6802", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N707TW", ship_number: "6805", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N709TW", ship_number: "6806", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N710TW", ship_number: "6807", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N712TW", ship_number: "6808", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N721TW", ship_number: "6810", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N717TW", ship_number: "6812", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N727TW", ship_number: "6813", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3752", ship_number: "3752", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N704X", ship_number: "6803", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N301DQ", ship_number: "3601", type_subtype: "737-700", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N302DQ", ship_number: "3602", type_subtype: "737-700", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N303DQ", ship_number: "3603", type_subtype: "737-700", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N304DQ", ship_number: "3604", type_subtype: "737-700", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N305DQ", ship_number: "3605", type_subtype: "737-700", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N306DQ", ship_number: "3606", type_subtype: "737-700", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N703DN", ship_number: "7103", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N704DK", ship_number: "7104", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N705DN", ship_number: "7105", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N307DQ", ship_number: "3607", type_subtype: "737-700", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N706DN", ship_number: "7106", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N707DN", ship_number: "7107", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N708DN", ship_number: "7108", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N308DE", ship_number: "3608", type_subtype: "737-700", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N309DE", ship_number: "3609", type_subtype: "737-700", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N310DE", ship_number: "3610", type_subtype: "737-700", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N709DN", ship_number: "7109", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N710DN", ship_number: "7110", type_subtype: "777-200LR", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3772H", ship_number: "3772", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3773D", ship_number: "3773", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N624AG", ship_number: "6818", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N801DZ", ship_number: "3801", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N803DN", ship_number: "3803", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N802DN", ship_number: "3802", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N806DN", ship_number: "3806", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N804DN", ship_number: "3804", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N807DN", ship_number: "3807", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N808DN", ship_number: "3808", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N809DN", ship_number: "3809", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N810DN", ship_number: "3810", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N805DN", ship_number: "3805", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N811DZ", ship_number: "3811", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N812DN", ship_number: "3812", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N813DN", ship_number: "3813", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N825DN", ship_number: "3825", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N816DN", ship_number: "3816", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N817DN", ship_number: "3817", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N814DN", ship_number: "3814", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N815DN", ship_number: "3815", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N818DA", ship_number: "3818", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N819DN", ship_number: "3819", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N820DN", ship_number: "3820", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N821DN", ship_number: "3821", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N822DN", ship_number: "3822", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N823DN", ship_number: "3823", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N824DN", ship_number: "3824", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N826DN", ship_number: "3826", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N827DN", ship_number: "3827", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N828DN", ship_number: "3828", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N829DN", ship_number: "3829", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N830DN", ship_number: "3830", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N831DN", ship_number: "3831", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N548US", ship_number: "5648", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N832DN", ship_number: "3832", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N535US", ship_number: "5635", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N837DN", ship_number: "3837", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N833DN", ship_number: "3833", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N834DN", ship_number: "3834", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N835DN", ship_number: "3835", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N836DN", ship_number: "3836", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N838DN", ship_number: "3838", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N839DN", ship_number: "3839", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N539US", ship_number: "5639", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N822NW", ship_number: "3322", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N537US", ship_number: "5637", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N824NW", ship_number: "3324", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N379DA", ship_number: "3709", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N840DN", ship_number: "3840", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N842DN", ship_number: "3842", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N829NW", ship_number: "3329", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N380DA", ship_number: "3710", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N841DN", ship_number: "3841", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N371NB", ship_number: "3171", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N381DN", ship_number: "3711", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N843DN", ship_number: "3843", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3742C", ship_number: "3742", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N844DN", ship_number: "3844", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N383DN", ship_number: "3713", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N557NW", ship_number: "5657", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N845DN", ship_number: "3845", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N339NB", ship_number: "3139", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N384DA", ship_number: "3714", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N540US", ship_number: "5640", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N542US", ship_number: "5642", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N382DN", ship_number: "3082", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N543US", ship_number: "5643", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N846DN", ship_number: "3846", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N385DN", ship_number: "3715", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N342NB", ship_number: "3142", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N386DA", ship_number: "3716", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N849DN", ship_number: "3849", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N848DN", ship_number: "3848", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N556NW", ship_number: "5656", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N847DN", ship_number: "3847", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N343NB", ship_number: "3143", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N825NW", ship_number: "3325", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N545US", ship_number: "5645", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N318NB", ship_number: "3118", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N378DA", ship_number: "3708", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N555NW", ship_number: "5655", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N851DN", ship_number: "3851", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N360NB", ship_number: "3160", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3740C", ship_number: "3740", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N319NB", ship_number: "3119", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N317NB", ship_number: "3117", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N345NB", ship_number: "3145", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N826NW", ship_number: "3326", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3741S", ship_number: "3741", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N850DN", ship_number: "3850", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N396DA", ship_number: "3726", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N388DA", ship_number: "3718", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N547US", ship_number: "5647", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N347NB", ship_number: "3147", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N389DA", ship_number: "3719", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N549US", ship_number: "5649", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N320NB", ship_number: "3120", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N355NB", ship_number: "3155", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N390DA", ship_number: "3720", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N855DN", ship_number: "3855", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N536US", ship_number: "5636", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N852DN", ship_number: "3852", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N853DN", ship_number: "3853", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N541US", ship_number: "5641", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N348NB", ship_number: "3148", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N857DZ", ship_number: "3857", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N854DN", ship_number: "3854", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N538US", ship_number: "5638", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N349NB", ship_number: "3149", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N827NW", ship_number: "3327", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N301DV", ship_number: "3001", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N353NB", ship_number: "3153", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N858DZ", ship_number: "3858", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N546US", ship_number: "5646", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N399DA", ship_number: "3729", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N859DN", ship_number: "3859", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N856DN", ship_number: "3856", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N323NB", ship_number: "3123", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N398DA", ship_number: "3728", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N544US", ship_number: "5644", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N321NB", ship_number: "3121", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N302DN", ship_number: "3002", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N322NB", ship_number: "3122", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N395DN", ship_number: "3725", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N303DN", ship_number: "3003", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N357NB", ship_number: "3157", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N686DA", ship_number: "686", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N364NB", ship_number: "3164", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N301NB", ship_number: "3101", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3730B", ship_number: "3730", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N900PC", ship_number: "691", type_subtype: "757-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N324NB", ship_number: "3124", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N860DN", ship_number: "3860", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N828NW", ship_number: "3328", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N861DN", ship_number: "3861", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N305DN", ship_number: "3005", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N330NB", ship_number: "3130", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N363NB", ship_number: "3163", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3733Z", ship_number: "3733", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N331NB", ship_number: "3131", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N863DN", ship_number: "3863", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N862DN", ship_number: "3862", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N304DN", ship_number: "3004", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N306DN", ship_number: "3006", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N864DN", ship_number: "3864", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N329NB", ship_number: "3129", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N307DX", ship_number: "3007", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N328NB", ship_number: "3128", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3731T", ship_number: "3731", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N359NB", ship_number: "3159", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N308DN", ship_number: "3008", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N309DN", ship_number: "3009", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N325NB", ship_number: "3125", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N358NB", ship_number: "3158", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3732J", ship_number: "3732", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N866DN", ship_number: "3866", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N310DN", ship_number: "3010", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N372DA", ship_number: "3702", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N865DN", ship_number: "3865", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N313DN", ship_number: "3013", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3735D", ship_number: "3735", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N361NB", ship_number: "3161", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N311DN", ship_number: "3011", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N327NB", ship_number: "3127", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N867DN", ship_number: "3867", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N375DA", ship_number: "3705", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N680DA", ship_number: "680", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N314NB", ship_number: "3114", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N332NB", ship_number: "3132", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N362NB", ship_number: "3162", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3734B", ship_number: "3734", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N373DA", ship_number: "3703", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N368NB", ship_number: "3168", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N868DN", ship_number: "3868", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N312DN", ship_number: "3012", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3739P", ship_number: "3739", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N354NB", ship_number: "3154", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N344NB", ship_number: "3144", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N374DA", ship_number: "3704", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N314DN", ship_number: "3014", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N869DN", ship_number: "3869", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N315DN", ship_number: "3015", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N340NB", ship_number: "3140", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N376DA", ship_number: "3706", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N336NB", ship_number: "3136", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N870DN", ship_number: "3870", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N830NW", ship_number: "3330", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N871DN", ship_number: "3871", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N341NB", ship_number: "3141", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3736C", ship_number: "3736", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3743H", ship_number: "3743", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N671DN", ship_number: "671", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3737C", ship_number: "3737", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N337NB", ship_number: "3137", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N872DN", ship_number: "3872", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N338NB", ship_number: "3138", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N670DN", ship_number: "670", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N3738B", ship_number: "3738", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N369NB", ship_number: "3169", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N316DN", ship_number: "3016", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N873DN", ship_number: "3873", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N681DA", ship_number: "681", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N351NB", ship_number: "3151", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N378NW", ship_number: "3278", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N317DN", ship_number: "3017", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N333NB", ship_number: "3133", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N334NB", ship_number: "3134", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N352NB", ship_number: "3152", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N674DL", ship_number: "674", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N335NB", ship_number: "3135", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N876DN", ship_number: "3876", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N370NB", ship_number: "3170", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N318DX", ship_number: "3018", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N673DL", ship_number: "673", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N675DL", ship_number: "675", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N672DL", ship_number: "672", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N326NB", ship_number: "3126", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N678DL", ship_number: "678", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N501DN", ship_number: "3501", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N319DN", ship_number: "3019", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N875DN", ship_number: "3875", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N874DN", ship_number: "3874", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N320DN", ship_number: "3020", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N321DH", ship_number: "3021", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N315NB", ship_number: "3115", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N831NW", ship_number: "3331", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N819DX", ship_number: "6819", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N354NW", ship_number: "3254", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N679DA", ship_number: "679", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N340NW", ship_number: "3240", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N877DN", ship_number: "3877", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N682DA", ship_number: "682", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N344NW", ship_number: "3244", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N502DN", ship_number: "3502", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N683DA", ship_number: "683", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N676DL", ship_number: "676", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N358NW", ship_number: "3258", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N353NW", ship_number: "3253", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N356NW", ship_number: "3256", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N881DN", ship_number: "3881", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N322DN", ship_number: "3022", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N347NW", ship_number: "3247", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N316NB", ship_number: "3116", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N325DN", ship_number: "3025", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N879DN", ship_number: "3879", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N878DN", ship_number: "3878", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N665DN", ship_number: "665", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N348NW", ship_number: "3248", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N324DX", ship_number: "3024", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N883DN", ship_number: "3883", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N323DN", ship_number: "3023", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N880DN", ship_number: "3880", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N317US", ship_number: "3217", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N669DN", ship_number: "669", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N884DN", ship_number: "3884", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N327DN", ship_number: "3027", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N882DN", ship_number: "3882", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N349NW", ship_number: "3249", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N357NW", ship_number: "3257", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N659DL", ship_number: "659", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N326DN", ship_number: "3026", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N375NC", ship_number: "3275", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N326US", ship_number: "3226", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N328DN", ship_number: "3028", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N352NW", ship_number: "3252", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N505DN", ship_number: "3505", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N886DN", ship_number: "3886", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N343NW", ship_number: "3243", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N506DN", ship_number: "3506", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N329DN", ship_number: "3029", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N658DL", ship_number: "658", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N820DX", ship_number: "6820", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N359NW", ship_number: "3259", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N334NW", ship_number: "3234", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N823DX", ship_number: "6823", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N887DN", ship_number: "3887", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N355NW", ship_number: "3255", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N885DN", ship_number: "3885", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N503DN", ship_number: "3503", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N330DX", ship_number: "3030", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N668DN", ship_number: "668", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N325US", ship_number: "3225", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N888DU", ship_number: "3888", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N329NW", ship_number: "3229", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N889DN", ship_number: "3889", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N376NW", ship_number: "3276", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N331DN", ship_number: "3031", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N312US", ship_number: "3212", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N667DN", ship_number: "667", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N504DN", ship_number: "3504", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N330NW", ship_number: "3230", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N339NW", ship_number: "3239", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N822DX", ship_number: "6822", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N821DX", ship_number: "6821", type_subtype: "757-200EM", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N335DN", ship_number: "3035", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N364NW", ship_number: "3264", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N660DL", ship_number: "660", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N350NA", ship_number: "3250", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N333DX", ship_number: "3033", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N334DN", ship_number: "3034", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N890DN", ship_number: "3890", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N360NW", ship_number: "3260", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N345NW", ship_number: "3245", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N332DN", ship_number: "3032", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N335NW", ship_number: "3235", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N332NW", ship_number: "3232", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N366NB", ship_number: "3166", type_subtype: "319-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N333NW", ship_number: "3233", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N891DN", ship_number: "3891", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N337DN", ship_number: "3037", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N331NW", ship_number: "3231", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N365NW", ship_number: "3265", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N892DN", ship_number: "3892", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N338DN", ship_number: "3038", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N309US", ship_number: "3209", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N507DN", ship_number: "3507", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N341DN", ship_number: "3041", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N340DN", ship_number: "3040", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N363NW", ship_number: "3263", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N508DN", ship_number: "3508", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N342DN", ship_number: "3042", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N361NW", ship_number: "3261", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N894DN", ship_number: "3894", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N336DX", ship_number: "3036", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N339DN", ship_number: "3039", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N511DN", ship_number: "3511", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N893DN", ship_number: "3893", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N895DN", ship_number: "3895", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N319US", ship_number: "3219", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N366NW", ship_number: "3266", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N345DN", ship_number: "3045", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N346DN", ship_number: "3046", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N320US", ship_number: "3220", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N344DN", ship_number: "3044", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N898DN", ship_number: "3898", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N897DN", ship_number: "3897", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N351DN", ship_number: "3051", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N367NW", ship_number: "3267", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N368NW", ship_number: "3268", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N362NW", ship_number: "3262", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N775DE", ship_number: "3775", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N896DN", ship_number: "3896", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N356DN", ship_number: "3056", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N899DN", ship_number: "3899", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N348DN", ship_number: "3048", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N352DN", ship_number: "3052", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N343DN", ship_number: "3043", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N321US", ship_number: "3221", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N369NW", ship_number: "3269", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N337NW", ship_number: "3237", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N510DN", ship_number: "3510", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N358DN", ship_number: "3058", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N349DX", ship_number: "3049", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N900DU", ship_number: "3900", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N347DN", ship_number: "3047", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N901DN", ship_number: "3901", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N336NW", ship_number: "3236", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N350DN", ship_number: "3050", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N902DN", ship_number: "3902", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N359DN", ship_number: "3059", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N370NW", ship_number: "3270", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N353DN", ship_number: "3053", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N355DN", ship_number: "3055", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N357DN", ship_number: "3057", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N354DN", ship_number: "3054", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N360DN", ship_number: "3060", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N322US", ship_number: "3222", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N371NW", ship_number: "3271", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N903DN", ship_number: "3903", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N509DN", ship_number: "3509", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N362DN", ship_number: "3062", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N323US", ship_number: "3223", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N361DN", ship_number: "3061", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N363DN", ship_number: "3063", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N904DN", ship_number: "3904", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N324US", ship_number: "3224", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N377NW", ship_number: "3277", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N364DX", ship_number: "3064", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N365DN", ship_number: "3065", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N906DN", ship_number: "3906", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N341NW", ship_number: "3241", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N776DE", ship_number: "3776", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N907DN", ship_number: "3907", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N374NW", ship_number: "3274", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N909DN", ship_number: "3909", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N905DN", ship_number: "3905", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N351NW", ship_number: "3251", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N372NW", ship_number: "3272", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N338NW", ship_number: "3238", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N377DE", ship_number: "3777", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N328NW", ship_number: "3228", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N908DN", ship_number: "3908", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N913DU", ship_number: "3913", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N914DU", ship_number: "3914", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N911DQ", ship_number: "3911", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N912DU", ship_number: "3912", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N915DU", ship_number: "3915", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N373NW", ship_number: "3273", type_subtype: "320-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N910DU", ship_number: "3910", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N916DU", ship_number: "3916", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N366DX", ship_number: "3066", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N918DU", ship_number: "3918", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N774DE", ship_number: "3774", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N368DN", ship_number: "3068", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N919DU", ship_number: "3919", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N327NW", ship_number: "3227", type_subtype: "320-200", system_assigned: "eX1,eX2", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N512DN", ship_number: "3512", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N920DU", ship_number: "3920", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N921DU", ship_number: "3921", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N922DZ", ship_number: "3922", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N369DN", ship_number: "3069", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N372DN", ship_number: "3072", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N371DN", ship_number: "3071", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N370DN", ship_number: "3070", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N923DZ", ship_number: "3923", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N925DZ", ship_number: "3925", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N374DX", ship_number: "3074", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N367DN", ship_number: "3067", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N373DX", ship_number: "3073", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N375DN", ship_number: "3075", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N377DN", ship_number: "3077", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N926DZ", ship_number: "3926", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N380DN", ship_number: "3080", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N383DZ", ship_number: "3083", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N376DN", ship_number: "3076", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N927DZ", ship_number: "3927", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N924DZ", ship_number: "3924", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N381DZ", ship_number: "3081", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N929DZ", ship_number: "3929", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N384DN", ship_number: "3084", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N379DN", ship_number: "3079", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N930DZ", ship_number: "3930", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N385DZ", ship_number: "3085", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N387DN", ship_number: "3087", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N513DZ", ship_number: "3513", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N917DU", ship_number: "3917", type_subtype: "300-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N390DN", ship_number: "3090", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N395DZ", ship_number: "3095", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N392DN", ship_number: "3092", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N396DN", ship_number: "3096", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N393DN", ship_number: "3093", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N928DU", ship_number: "3928", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N397DN", ship_number: "3097", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N394DX", ship_number: "3094", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N378DN", ship_number: "3078", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N388DN", ship_number: "3088", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N386DN", ship_number: "3086", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N398DN", ship_number: "3098", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "F-WZGA", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "F-WZMI", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N129DU", ship_number: "", type_subtype: "CS100-", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N101DQ", ship_number: "1001", type_subtype: "321-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N391DN", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N389DN", ship_number: "", type_subtype: "321-100", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N103DY", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "N102DN", ship_number: "1002", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "F-WZMV", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DAL", registration_name: "F-WZMX", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DER", registration_name: "2-DEER", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1203" },
{ operator_icao_code: "DKH", registration_name: "B-1115", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "92465" },
{ operator_icao_code: "DKH", registration_name: "B-207N", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "92466" },
{ operator_icao_code: "DKH", registration_name: "B-208A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "92467" },
{ operator_icao_code: "DKH", registration_name: "B-209R", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "92468" },
{ operator_icao_code: "DKH", registration_name: "B-20D1", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "92469" },
{ operator_icao_code: "DKH", registration_name: "B-20DT", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "92470" },
{ operator_icao_code: "DKH", registration_name: "N", ship_number: "", type_subtype: "220-100", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-APBB", ship_number: "", type_subtype: "737-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-APBC", ship_number: "", type_subtype: "737-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-APBD", ship_number: "", type_subtype: "737-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-AIKF", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2146" },
{ operator_icao_code: "DLH", registration_name: "D-AIGX", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2050" },
{ operator_icao_code: "DLH", registration_name: "D-AIKH", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "2051" },
{ operator_icao_code: "DLH", registration_name: "D-AIMA", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51279" },
{ operator_icao_code: "DLH", registration_name: "D-AIKI", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2058" },
{ operator_icao_code: "DLH", registration_name: "D-AIMB", ship_number: "", type_subtype: "380-800", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51280" },
{ operator_icao_code: "DLH", registration_name: "D-AIMC", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51281" },
{ operator_icao_code: "DLH", registration_name: "D-AIMD", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51282" },
{ operator_icao_code: "DLH", registration_name: "D-AIME", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2185" },
{ operator_icao_code: "DLH", registration_name: "D-AIGP", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2072" },
{ operator_icao_code: "DLH", registration_name: "D-AIGM", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2069" },
{ operator_icao_code: "DLH", registration_name: "D-AIKJ", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-AIGV", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2123" },
{ operator_icao_code: "DLH", registration_name: "D-AIGW", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2124" },
{ operator_icao_code: "DLH", registration_name: "D-AIKB", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2053" },
{ operator_icao_code: "DLH", registration_name: "D-AIGL", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2068" },
{ operator_icao_code: "DLH", registration_name: "D-AIFC", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2125" },
{ operator_icao_code: "DLH", registration_name: "D-AIKA", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-AIHT", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2127" },
{ operator_icao_code: "DLH", registration_name: "D-AIFD", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2126" },
{ operator_icao_code: "DLH", registration_name: "D-AIHN", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-AIMF", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51284" },
{ operator_icao_code: "DLH", registration_name: "D-AIGS", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2073" },
{ operator_icao_code: "DLH", registration_name: "D-AIFE", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2079" },
{ operator_icao_code: "DLH", registration_name: "D-AIMG", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51285" },
{ operator_icao_code: "DLH", registration_name: "D-AIFF", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2080" },
{ operator_icao_code: "DLH", registration_name: "D-AIHL", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2090" },
{ operator_icao_code: "DLH", registration_name: "D-AIMH", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51286" },
{ operator_icao_code: "DLH", registration_name: "D-AIGN", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2070" },
{ operator_icao_code: "DLH", registration_name: "D-AIKD", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2055" },
{ operator_icao_code: "DLH", registration_name: "D-ABVO", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2110" },
{ operator_icao_code: "DLH", registration_name: "D-AIGO", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2071" },
{ operator_icao_code: "DLH", registration_name: "D-AIGT", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2074" },
{ operator_icao_code: "DLH", registration_name: "D-ABVR", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2112" },
{ operator_icao_code: "DLH", registration_name: "D-AIHO", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-ABVS", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2113" },
{ operator_icao_code: "DLH", registration_name: "D-AIGU", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2075" },
{ operator_icao_code: "DLH", registration_name: "D-AIKE", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2056" },
{ operator_icao_code: "DLH", registration_name: "D-AIKM", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "21719" },
{ operator_icao_code: "DLH", registration_name: "D-AIHV", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2129" },
{ operator_icao_code: "DLH", registration_name: "D-AIHP", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2094" },
{ operator_icao_code: "DLH", registration_name: "D-AIHU", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2128" },
{ operator_icao_code: "DLH", registration_name: "D-AIGZ", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2077" },
{ operator_icao_code: "DLH", registration_name: "D-AIHR", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-AIHX", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2131" },
{ operator_icao_code: "DLH", registration_name: "D-AIHW", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2130" },
{ operator_icao_code: "DLH", registration_name: "D-AIKN", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "21720" },
{ operator_icao_code: "DLH", registration_name: "D-AIHZ", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2133" },
{ operator_icao_code: "DLH", registration_name: "D-AIKO", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "21721" },
{ operator_icao_code: "DLH", registration_name: "D-AIKP", ship_number: "", type_subtype: "330-340", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2177" },
{ operator_icao_code: "DLH", registration_name: "D-AIKQ", ship_number: "", type_subtype: "330-340", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2178" },
{ operator_icao_code: "DLH", registration_name: "D-AIKR", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2179" },
{ operator_icao_code: "DLH", registration_name: "D-AIHQ", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-AIHC", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2083" },
{ operator_icao_code: "DLH", registration_name: "D-AIHY", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2132" },
{ operator_icao_code: "DLH", registration_name: "D-AIHS", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-ABVM", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2141" },
{ operator_icao_code: "DLH", registration_name: "D-ABTA", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-ABTB", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-ABTC", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-ABTD", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-ABTE", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-ABTK", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2139" },
{ operator_icao_code: "DLH", registration_name: "D-ABTL", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2138" },
{ operator_icao_code: "DLH", registration_name: "D-ABVP", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2111" },
{ operator_icao_code: "DLH", registration_name: "D-ABVT", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2114" },
{ operator_icao_code: "DLH", registration_name: "D-ABVU", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2115" },
{ operator_icao_code: "DLH", registration_name: "D-ABVW", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2116" },
{ operator_icao_code: "DLH", registration_name: "D-ABVX", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2117" },
{ operator_icao_code: "DLH", registration_name: "D-ABVY", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2142" },
{ operator_icao_code: "DLH", registration_name: "D-ABVZ", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2143" },
{ operator_icao_code: "DLH", registration_name: "D-AIHB", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2082" },
{ operator_icao_code: "DLH", registration_name: "D-ABYA", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2154" },
{ operator_icao_code: "DLH", registration_name: "D-AIHD", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2084" },
{ operator_icao_code: "DLH", registration_name: "D-AIHE", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2085" },
{ operator_icao_code: "DLH", registration_name: "D-AIHF", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2086" },
{ operator_icao_code: "DLH", registration_name: "D-AIMI", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51287" },
{ operator_icao_code: "DLH", registration_name: "D-AIMJ", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51288" },
{ operator_icao_code: "DLH", registration_name: "D-ABYC", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2155" },
{ operator_icao_code: "DLH", registration_name: "D-ABYD", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2156" },
{ operator_icao_code: "DLH", registration_name: "D-ABYF", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2158" },
{ operator_icao_code: "DLH", registration_name: "D-AIHK", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2089" },
{ operator_icao_code: "DLH", registration_name: "D-AIKK", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "21717" },
{ operator_icao_code: "DLH", registration_name: "D-AIKL", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "21718" },
{ operator_icao_code: "DLH", registration_name: "D-ABYU", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2173" },
{ operator_icao_code: "DLH", registration_name: "D-ABYL", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2164" },
{ operator_icao_code: "DLH", registration_name: "D-ABYQ", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2169" },
{ operator_icao_code: "DLH", registration_name: "D-ABYH", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2160" },
{ operator_icao_code: "DLH", registration_name: "D-ABYM", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2165" },
{ operator_icao_code: "DLH", registration_name: "D-ABYR", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2170" },
{ operator_icao_code: "DLH", registration_name: "D-ABYI", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2161" },
{ operator_icao_code: "DLH", registration_name: "D-ABYN", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2166" },
{ operator_icao_code: "DLH", registration_name: "D-ABYS", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2171" },
{ operator_icao_code: "DLH", registration_name: "D-ABYJ", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2162" },
{ operator_icao_code: "DLH", registration_name: "D-ABYO", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2167" },
{ operator_icao_code: "DLH", registration_name: "D-ABYT", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2172" },
{ operator_icao_code: "DLH", registration_name: "D-ABYK", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2163" },
{ operator_icao_code: "DLH", registration_name: "D-ABYP", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2168" },
{ operator_icao_code: "DLH", registration_name: "D-ABYG", ship_number: "", type_subtype: "747-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2159" },
{ operator_icao_code: "DLH", registration_name: "D-AIHI", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2088" },
{ operator_icao_code: "DLH", registration_name: "D-AIHH", ship_number: "", type_subtype: "340-640", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2087" },
{ operator_icao_code: "DLH", registration_name: "D-AIML", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51290" },
{ operator_icao_code: "DLH", registration_name: "D-AIKS", ship_number: "", type_subtype: "330-340", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2176" },
{ operator_icao_code: "DLH", registration_name: "D-AIMK", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51289" },
{ operator_icao_code: "DLH", registration_name: "D-AIMM", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51291" },
{ operator_icao_code: "DLH", registration_name: "D-AIMN", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "51292" },
{ operator_icao_code: "DLH", registration_name: "D-AWBB", ship_number: "", type_subtype: "737-700", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-AIXA", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51120" },
{ operator_icao_code: "DLH", registration_name: "D-AIXC", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51122" },
{ operator_icao_code: "DLH", registration_name: "D-AIXB", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51128" },
{ operator_icao_code: "DLH", registration_name: "D-AIXD", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51123" },
{ operator_icao_code: "DLH", registration_name: "D-AIXE", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51124" },
{ operator_icao_code: "DLH", registration_name: "D-AIXF", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51125" },
{ operator_icao_code: "DLH", registration_name: "D-AIXG", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51126" },
{ operator_icao_code: "DLH", registration_name: "D-AIXH", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51127" },
{ operator_icao_code: "DLH", registration_name: "D-AIXI", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51117" },
{ operator_icao_code: "DLH", registration_name: "D-AIXJ", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51118" },
{ operator_icao_code: "DLH", registration_name: "D-AIXK", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51119" },
{ operator_icao_code: "DLH", registration_name: "D-AIXL", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51129" },
{ operator_icao_code: "DLH", registration_name: "D-AIXM", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51130" },
{ operator_icao_code: "DLH", registration_name: "D-AIXN", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51131" },
{ operator_icao_code: "DLH", registration_name: "D-AIXO", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51132" },
{ operator_icao_code: "DLH", registration_name: "F-WZFNI", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "GEN1",xid: "2273" },
{ operator_icao_code: "DLH", registration_name: "FWZN-N", ship_number: "212", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DLH", registration_name: "D-AIGY", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "2076" },
{ operator_icao_code: "DLH", registration_name: "D-AIXP", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51121" },
{ operator_icao_code: "DTH", registration_name: "7T-VCE", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DTH", registration_name: "7T-VCT", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DTH", registration_name: "7T-VCF", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DUB", registration_name: "A6-COM", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "DUB", registration_name: "A6-MMM", ship_number: "", type_subtype: "747-400", system_assigned: "eX1,eXConnect", system_reported: "eX1,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50495" },
{ operator_icao_code: "DUB", registration_name: "A6-HRM", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50480" },
{ operator_icao_code: "DUB", registration_name: "A6-HEH", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50484" },
{ operator_icao_code: "DUB", registration_name: "A6-MRS", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN3",xid: "50482" },
{ operator_icao_code: "DUB", registration_name: "A6-MRM", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50481" },
{ operator_icao_code: "DUB", registration_name: "A6-FZZ", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50483" },
{ operator_icao_code: "DUB", registration_name: "A6-HRS", ship_number: "", type_subtype: "737-700", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50477" },
{ operator_icao_code: "DUB", registration_name: "A6-HMM", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50485" },
{ operator_icao_code: "EDW", registration_name: "HB-JHQ", ship_number: "1193", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-IHY", ship_number: "947", type_subtype: "320-214", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-IJW", ship_number: "2134", type_subtype: "320-214", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-IJU", ship_number: "1951", type_subtype: "320-214", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-IJV", ship_number: "2024", type_subtype: "320-214", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-IHZ", ship_number: "1026", type_subtype: "320-214", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-IHX", ship_number: "942", type_subtype: "320-214", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-JHR", ship_number: "1711", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-JMG", ship_number: "562", type_subtype: "340-310", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-JMF", ship_number: "561", type_subtype: "340-310", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-JME", ship_number: "559", type_subtype: "340-310", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-JJK", ship_number: "1692", type_subtype: "320-214", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-JMD", ship_number: "556", type_subtype: "340-310", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-JJL", ship_number: "2606", type_subtype: "320-214", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-JJM", ship_number: "2627", type_subtype: "320-214", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EDW", registration_name: "HB-JJN", ship_number: "4187", type_subtype: "320-214", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EGR", registration_name: "CS-TQX", ship_number: "", type_subtype: "777-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EIN", registration_name: "EI-DUO", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56531" },
{ operator_icao_code: "EIN", registration_name: "EI-DUZ", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "56532" },
{ operator_icao_code: "EIN", registration_name: "EI-EAV", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "56533" },
{ operator_icao_code: "EIN", registration_name: "EI-DAA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "56529" },
{ operator_icao_code: "EIN", registration_name: "EI-LAX", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56530" },
{ operator_icao_code: "EIN", registration_name: "EI-EDY", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "56534" },
{ operator_icao_code: "EIN", registration_name: "EI-ELA", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56535" },
{ operator_icao_code: "EIN", registration_name: "EI-FNG", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56525" },
{ operator_icao_code: "EIN", registration_name: "EI-FNH", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "56528" },
{ operator_icao_code: "EIN", registration_name: "EI-GAJ", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56527" },
{ operator_icao_code: "EIN", registration_name: "EI-GCF", ship_number: "", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56526" },
{ operator_icao_code: "EIN", registration_name: "EI-GEY", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56542" },
{ operator_icao_code: "EIN", registration_name: "EI-LRA", ship_number: "", type_subtype: "321-200", system_assigned: "eX1-eXConnect", system_reported: "eX1,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56537" },
{ operator_icao_code: "EIN", registration_name: "EI-LRB", ship_number: "", type_subtype: "321-200", system_assigned: "eX1-eXConnect", system_reported: "eX1,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56538" },
{ operator_icao_code: "EIN", registration_name: "EI-LRC", ship_number: "", type_subtype: "321-200", system_assigned: "eX1-eXConnect", system_reported: "eX1,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56539" },
{ operator_icao_code: "EIN", registration_name: "EI-EIM", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56540" },
{ operator_icao_code: "EIN", registration_name: "EI-EIN", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56541" },
{ operator_icao_code: "EIN", registration_name: "EI-LRD", ship_number: "", type_subtype: "321-200", system_assigned: "eX1-eXConnect", system_reported: "eX1,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56543" },
{ operator_icao_code: "ELY", registration_name: "4X-ECA", ship_number: "WC101", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ECB", ship_number: "WC102", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ECC", ship_number: "WC103", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ECD", ship_number: "WC104", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ELA", ship_number: "RU081", type_subtype: "747-400", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ELB", ship_number: "RU082", type_subtype: "747-400", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ELC", ship_number: "RU083", type_subtype: "747-400", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ELD", ship_number: "RM091", type_subtype: "747-400", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ECE", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ECF", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EHA", ship_number: "", type_subtype: "737-900", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EHB", ship_number: "", type_subtype: "737-900", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EHC", ship_number: "", type_subtype: "737-900", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EHD", ship_number: "", type_subtype: "737-900", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EHE", ship_number: "", type_subtype: "737-900", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EHF", ship_number: "", type_subtype: "737-900", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EHH", ship_number: "", type_subtype: "737-900", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EHI", ship_number: "", type_subtype: "737-900", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDB", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDC", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDD", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDE", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDH", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDI", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDL", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDK", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-EDM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ERA", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ERC", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ELY", registration_name: "4X-ERB", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EMB", registration_name: "FAB2590", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EMB", registration_name: "FAB2591", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EMB", registration_name: "PR-ZIQ", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EMB", registration_name: "PR-ZGQ", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EMB", registration_name: "EMBRIG", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eXConnect", system_reported: "", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "54525" },
{ operator_icao_code: "EMB", registration_name: "ERJ190-400", ship_number: "", type_subtype: "220-100", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-AFA", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-AFB", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-AFD", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-ETG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6611" },
{ operator_icao_code: "ETD", registration_name: "A6-ETH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6617" },
{ operator_icao_code: "ETD", registration_name: "A6-EYN", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6578" },
{ operator_icao_code: "ETD", registration_name: "A6-EYO", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6577" },
{ operator_icao_code: "ETD", registration_name: "A6-EYK", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6574" },
{ operator_icao_code: "ETD", registration_name: "A6-EYP", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6562" },
{ operator_icao_code: "ETD", registration_name: "A6-ETI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6610" },
{ operator_icao_code: "ETD", registration_name: "A6-ETJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6618" },
{ operator_icao_code: "ETD", registration_name: "A6-ETK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6615" },
{ operator_icao_code: "ETD", registration_name: "A6-ETA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2-eXConnect", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6608" },
{ operator_icao_code: "ETD", registration_name: "A6-ETB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2-eXConnect", system_reported: "eX2L-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6616" },
{ operator_icao_code: "ETD", registration_name: "A6-ETC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6614" },
{ operator_icao_code: "ETD", registration_name: "A6-ETD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55028" },
{ operator_icao_code: "ETD", registration_name: "A6-ETE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6607" },
{ operator_icao_code: "ETD", registration_name: "A6-ETF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6612" },
{ operator_icao_code: "ETD", registration_name: "A6-EYR", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6566" },
{ operator_icao_code: "ETD", registration_name: "A6-EYS", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6572" },
{ operator_icao_code: "ETD", registration_name: "A6-EYQ", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6579" },
{ operator_icao_code: "ETD", registration_name: "A6-EYF", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6564" },
{ operator_icao_code: "ETD", registration_name: "A6-EYG", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6569" },
{ operator_icao_code: "ETD", registration_name: "A6-EYH", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6570" },
{ operator_icao_code: "ETD", registration_name: "A6-EYJ", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55059" },
{ operator_icao_code: "ETD", registration_name: "A6-ETM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6623" },
{ operator_icao_code: "ETD", registration_name: "A6-ETN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6624" },
{ operator_icao_code: "ETD", registration_name: "A6-ETO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6622" },
{ operator_icao_code: "ETD", registration_name: "A6-ETQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6620" },
{ operator_icao_code: "ETD", registration_name: "A6-ETR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6619" },
{ operator_icao_code: "ETD", registration_name: "A6-ETP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6621" },
{ operator_icao_code: "ETD", registration_name: "A6-ETL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6606" },
{ operator_icao_code: "ETD", registration_name: "A6-AEA", ship_number: "", type_subtype: "321-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EY7", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55273" },
{ operator_icao_code: "ETD", registration_name: "A6-EYT", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6568" },
{ operator_icao_code: "ETD", registration_name: "A6-EYU", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "55068" },
{ operator_icao_code: "ETD", registration_name: "A6-APA", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6600" },
{ operator_icao_code: "ETD", registration_name: "A6-APB", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6594" },
{ operator_icao_code: "ETD", registration_name: "A6-BLA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55008" },
{ operator_icao_code: "ETD", registration_name: "A6-AEB", ship_number: "", type_subtype: "321-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-AEC", ship_number: "", type_subtype: "321-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EIX", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-ETS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6609" },
{ operator_icao_code: "ETD", registration_name: "A6-BLB", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55004" },
{ operator_icao_code: "ETD", registration_name: "A6-EIY", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-AED", ship_number: "", type_subtype: "321-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-BLC", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55006" },
{ operator_icao_code: "ETD", registration_name: "A6-BLD", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55009" },
{ operator_icao_code: "ETD", registration_name: "A6-BLE", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55010" },
{ operator_icao_code: "ETD", registration_name: "A6-BLF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55011" },
{ operator_icao_code: "ETD", registration_name: "A6-APC", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6599" },
{ operator_icao_code: "ETD", registration_name: "A6-APE", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6596" },
{ operator_icao_code: "ETD", registration_name: "A6-APD", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6598" },
{ operator_icao_code: "ETD", registration_name: "A6-AEE", ship_number: "", type_subtype: "321-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EJA", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-AEF", ship_number: "", type_subtype: "321-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-APH", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6597" },
{ operator_icao_code: "ETD", registration_name: "A6-APF", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6595" },
{ operator_icao_code: "ETD", registration_name: "A6-APG", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6593" },
{ operator_icao_code: "ETD", registration_name: "A6-AEG", ship_number: "", type_subtype: "321-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-AEH", ship_number: "", type_subtype: "321-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-BLG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55007" },
{ operator_icao_code: "ETD", registration_name: "A6-BLH", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55012" },
{ operator_icao_code: "ETD", registration_name: "A6-BLI", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55013" },
{ operator_icao_code: "ETD", registration_name: "A6-BLJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55005" },
{ operator_icao_code: "ETD", registration_name: "A6-AEJ", ship_number: "", type_subtype: "321-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-AEI", ship_number: "", type_subtype: "321-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-BLK", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55014" },
{ operator_icao_code: "ETD", registration_name: "A6-BLL", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55015" },
{ operator_icao_code: "ETD", registration_name: "A6-API", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6637" },
{ operator_icao_code: "ETD", registration_name: "A6-APJ", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6638" },
{ operator_icao_code: "ETD", registration_name: "A6-BLM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55016" },
{ operator_icao_code: "ETD", registration_name: "A6-BLN", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55017" },
{ operator_icao_code: "ETD", registration_name: "A6-BLO", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55018" },
{ operator_icao_code: "ETD", registration_name: "A6-BLP", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55019" },
{ operator_icao_code: "ETD", registration_name: "A6-BLQ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55020" },
{ operator_icao_code: "ETD", registration_name: "A6-BLR", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55021" },
{ operator_icao_code: "ETD", registration_name: "A6-BLS", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55022" },
{ operator_icao_code: "ETD", registration_name: "A6-BLT", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55023" },
{ operator_icao_code: "ETD", registration_name: "A6-BLU", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55024" },
{ operator_icao_code: "ETD", registration_name: "A6-BMA", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54989" },
{ operator_icao_code: "ETD", registration_name: "A6-BLV", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54993" },
{ operator_icao_code: "ETD", registration_name: "A6-BMB", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54992" },
{ operator_icao_code: "ETD", registration_name: "A6-BMC", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54994" },
{ operator_icao_code: "ETD", registration_name: "A6-BMD", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54995" },
{ operator_icao_code: "ETD", registration_name: "A6-BLW", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54990" },
{ operator_icao_code: "ETD", registration_name: "A6-BME", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54991" },
{ operator_icao_code: "ETD", registration_name: "A6-BLX", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54996" },
{ operator_icao_code: "ETD", registration_name: "A6-BLY", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54997" },
{ operator_icao_code: "ETD", registration_name: "A6-BLZ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54999" },
{ operator_icao_code: "ETD", registration_name: "A6-BNB", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55001" },
{ operator_icao_code: "ETD", registration_name: "A6-BMF", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54998" },
{ operator_icao_code: "ETD", registration_name: "A6-BNC", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55002" },
{ operator_icao_code: "ETD", registration_name: "A6-BND", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55003" },
{ operator_icao_code: "ETD", registration_name: "A6-BNA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55000" },
{ operator_icao_code: "ETD", registration_name: "A6-EIN", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EIT", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EIP", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EIQ", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EIU", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EIR", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EIS", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EIW", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EIO", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-EIV", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "NOT-N394DX", ship_number: "", type_subtype: "321-200N", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ETD", registration_name: "A6-BMH", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55069" },
{ operator_icao_code: "ETD", registration_name: "A6-BMG", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55044" },
{ operator_icao_code: "ETD", registration_name: "A6-EIM", ship_number: "", type_subtype: "320-230", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16301", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16307", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16305", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16303", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16302", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16306", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16411", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16412", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16410", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16308", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16309", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16310", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16311", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16312", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16701", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16702", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16703", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51932" },
{ operator_icao_code: "EVA", registration_name: "B-16705", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51933" },
{ operator_icao_code: "EVA", registration_name: "B-16706", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51934" },
{ operator_icao_code: "EVA", registration_name: "B-16707", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51927" },
{ operator_icao_code: "EVA", registration_name: "B-16708", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51937" },
{ operator_icao_code: "EVA", registration_name: "B-16709", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51938" },
{ operator_icao_code: "EVA", registration_name: "B-16710", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51939" },
{ operator_icao_code: "EVA", registration_name: "B-16711", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51940" },
{ operator_icao_code: "EVA", registration_name: "B-16712", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51941" },
{ operator_icao_code: "EVA", registration_name: "B-16713", ship_number: "", type_subtype: "777-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51942" },
{ operator_icao_code: "EVA", registration_name: "B-16715", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51943" },
{ operator_icao_code: "EVA", registration_name: "B-16716", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51944" },
{ operator_icao_code: "EVA", registration_name: "B-16717", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51945" },
{ operator_icao_code: "EVA", registration_name: "B-16331", ship_number: "", type_subtype: "330-300", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51956" },
{ operator_icao_code: "EVA", registration_name: "B-16332", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51957" },
{ operator_icao_code: "EVA", registration_name: "B-16333", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51958" },
{ operator_icao_code: "EVA", registration_name: "B-16201", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16202", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16203", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16205", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16718", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51929" },
{ operator_icao_code: "EVA", registration_name: "B-16719", ship_number: "WE436", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51946" },
{ operator_icao_code: "EVA", registration_name: "B-16720", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51935" },
{ operator_icao_code: "EVA", registration_name: "B-16721", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51947" },
{ operator_icao_code: "EVA", registration_name: "B-16722", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51948" },
{ operator_icao_code: "EVA", registration_name: "B-16723", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51925" },
{ operator_icao_code: "EVA", registration_name: "B-16725", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51949" },
{ operator_icao_code: "EVA", registration_name: "B-16206", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16207", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16208", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16209", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16210", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16211", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16212", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16213", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16215", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16216", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16217", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16218", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16219", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16220", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16726", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51950" },
{ operator_icao_code: "EVA", registration_name: "B-16727", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51951" },
{ operator_icao_code: "EVA", registration_name: "B-16728", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51952" },
{ operator_icao_code: "EVA", registration_name: "B-16729", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51953" },
{ operator_icao_code: "EVA", registration_name: "B16335", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "B-16335", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51959" },
{ operator_icao_code: "EVA", registration_name: "B-16336", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51960" },
{ operator_icao_code: "EVA", registration_name: "B-16337", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51961" },
{ operator_icao_code: "EVA", registration_name: "B-16338", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51962" },
{ operator_icao_code: "EVA", registration_name: "B-16339", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51963" },
{ operator_icao_code: "EVA", registration_name: "B-16340", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51964" },
{ operator_icao_code: "EVA", registration_name: "B-16730", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51922" },
{ operator_icao_code: "EVA", registration_name: "B-16731", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51926" },
{ operator_icao_code: "EVA", registration_name: "B-16732", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51930" },
{ operator_icao_code: "EVA", registration_name: "B-16736", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51936" },
{ operator_icao_code: "EVA", registration_name: "B-16738", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51923" },
{ operator_icao_code: "EVA", registration_name: "B-16733", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51928" },
{ operator_icao_code: "EVA", registration_name: "B-16735", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51954" },
{ operator_icao_code: "EVA", registration_name: "B-16737", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51931" },
{ operator_icao_code: "EVA", registration_name: "B-16739", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51924" },
{ operator_icao_code: "EVA", registration_name: "B-16740", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51955" },
{ operator_icao_code: "EVA", registration_name: "F-WWYA", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EVA", registration_name: "BR-G78901A", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "C: Commercial", antenna_gen: "GEN1",xid: "1883" },
{ operator_icao_code: "EVA", registration_name: "B-17881", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51970" },
{ operator_icao_code: "EVA", registration_name: "B-17882", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51971" },
{ operator_icao_code: "EVA", registration_name: "B-17883", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51972" },
{ operator_icao_code: "EVA", registration_name: "B-17885", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "51973" },
{ operator_icao_code: "EVA", registration_name: "B-17801", ship_number: "", type_subtype: "787-10", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51918" },
{ operator_icao_code: "EVA", registration_name: "B-17803", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51920" },
{ operator_icao_code: "EVA", registration_name: "B-17805", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51917" },
{ operator_icao_code: "EVA", registration_name: "B-17802", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51919" },
{ operator_icao_code: "EVA", registration_name: "B-17806", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51921" },
{ operator_icao_code: "EVE", registration_name: "EC-MII", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "EWG", registration_name: "D-AXGG", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXL", system_reported: "eXL-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3425" },
{ operator_icao_code: "EWG", registration_name: "D-AXGC", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXL", system_reported: "eXL-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3427" },
{ operator_icao_code: "EWG", registration_name: "D-AXGD", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXL", system_reported: "eXL-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3428" },
{ operator_icao_code: "EWG", registration_name: "D-AXGE", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXL", system_reported: "eXL", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54602" },
{ operator_icao_code: "EWG", registration_name: "D-AXGF", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXL", system_reported: "eXL-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54603" },
{ operator_icao_code: "EWG", registration_name: "D-AXGB", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXConnect,eXL", system_reported: "eXL", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54599" },
{ operator_icao_code: "EWG", registration_name: "D-AXGA", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect,eXL", system_reported: "eXL", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54598" },
{ operator_icao_code: "EWG", registration_name: "OO-SFJ", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "3434" },
{ operator_icao_code: "EWG", registration_name: "OO-SFK", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "3435" },
{ operator_icao_code: "EWG", registration_name: "OO-SFL", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "3436" },
{ operator_icao_code: "EWG", registration_name: "OO-SFP", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "M: Melco", antenna_gen: "GEN1",xid: "3437" },
{ operator_icao_code: "FJI", registration_name: "DQ-FJW", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "FJI", registration_name: "DQ-FJT", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "FJI", registration_name: "DQ-FJU", ship_number: "1416", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "FJI", registration_name: "DQ-FJV", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "FJI", registration_name: "DQ-FJO", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "FJI", registration_name: "DQ-FAB", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54430" },
{ operator_icao_code: "FJI", registration_name: "DQ-FAD", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54429" },
{ operator_icao_code: "FJI", registration_name: "DQ-FAF", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "FWI", registration_name: "F-OFDF", ship_number: "", type_subtype: "330-220", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "FWI", registration_name: "F-ORLY", ship_number: "", type_subtype: "330-320", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "FWI", registration_name: "F-OONE", ship_number: "", type_subtype: "330-320", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "FWI", registration_name: "F-GOTO", ship_number: "", type_subtype: "330-320", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "FWI", registration_name: "F-HPTP", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GAF", registration_name: "10-03", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "52333" },
{ operator_icao_code: "GFA", registration_name: "A9C-AM", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AL", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AJ", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AO", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AP", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AK", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AN", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-KF", ship_number: "", type_subtype: "330-240", system_assigned: "2000E,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-KE", ship_number: "", type_subtype: "330-240", system_assigned: "2000E", system_reported: "2000E", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AQ", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AB", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AC", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AD", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AE", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AF", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AG", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AH", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-AI", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-CB", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-CA", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-CC", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-CD", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-CF", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GFA", registration_name: "A9C-CE", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMA", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMC", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMD", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GME", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMF", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPF", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMG", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMH", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMK", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GML", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPD", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPG", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPC", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMN", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMJ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMI", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMM", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMO", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMP", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMQ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFT", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFE", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFL", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPA", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFA", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFC", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFD", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPE", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFF", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFG", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFH", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFI", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFJ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFK", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMR", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFM", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPM", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFN", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFS", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFO", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFP", ship_number: "YL780", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFQ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPP", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFR", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPN", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMS", ship_number: "YR441", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPO", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMU", ship_number: "YR442", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMV", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMX", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMW", ship_number: "YR445", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GIA", ship_number: "PK-GIA", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3041" },
{ operator_icao_code: "GIA", registration_name: "PK-GIC", ship_number: "PK-GIC", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3042" },
{ operator_icao_code: "GIA", registration_name: "PK-GID", ship_number: "PK-GID", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3043" },
{ operator_icao_code: "GIA", registration_name: "PK-GIE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3044" },
{ operator_icao_code: "GIA", registration_name: "PK-GIF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3045" },
{ operator_icao_code: "GIA", registration_name: "PK-GIG", ship_number: "PK-GIG", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3046" },
{ operator_icao_code: "GIA", registration_name: "PK-GII", ship_number: "PK-GII", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3049" },
{ operator_icao_code: "GIA", registration_name: "PK-GIJ", ship_number: "PK-GIJ", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3048" },
{ operator_icao_code: "GIA", registration_name: "PK-GIK", ship_number: "PK-GIK", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3050" },
{ operator_icao_code: "GIA", registration_name: "PK-GIH", ship_number: "PK-GIH", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3047" },
{ operator_icao_code: "GIA", registration_name: "PK-GPQ", ship_number: "1410", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMY", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFU", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFX", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFV", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFW", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GMZ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFY", ship_number: "YR547", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GFZ", ship_number: "PK-GFZ", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPR", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNA", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNC", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPS", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GND", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNE", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNG", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNF", ship_number: "YR449", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNI", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNH", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNJ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNK", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNM", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPT", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNL", ship_number: "YR550", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNN", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPU", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNO", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPW", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPV", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNP", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNQ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNR", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNS", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNT", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPX", ship_number: "1654", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPY", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNU", ship_number: "YT416", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GNV", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GPZ", ship_number: "1698", type_subtype: "330-300", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GHA", ship_number: "MSN1709", type_subtype: "330-300", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GHC", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GHD", ship_number: "", type_subtype: "330-300", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GDA", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GVF", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "PK-GHE", ship_number: "", type_subtype: "330-900", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56110" },
{ operator_icao_code: "GIA", registration_name: "PK-GHF", ship_number: "", type_subtype: "330-900", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56109" },
{ operator_icao_code: "GIA", registration_name: "PK-GHG", ship_number: "", type_subtype: "330-900", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56111" },
{ operator_icao_code: "GIA", registration_name: "F-WWKV", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GIA", registration_name: "F-WWYP", ship_number: "", type_subtype: "330-900", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56112" },
{ operator_icao_code: "GIA", registration_name: "F-WWCD", ship_number: "", type_subtype: "330-900", system_assigned: "eXConnect", system_reported: "eFX", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56113" },
{ operator_icao_code: "GIA", registration_name: "F-WWYE", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56114" },
{ operator_icao_code: "GMI", registration_name: "D-AHIL", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "GMI", registration_name: "D-AHIM", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N380HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N381HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N382HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N383HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N384HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N385HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N386HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N389HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N388HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N390HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N392HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N393HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N391HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N395HA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N399HA", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N396HA", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N373HA", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N370HA", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N374HA", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N378HA", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N375HA", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N379HA", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N360HA", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N361HA", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N202HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N204HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N212HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N205HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N214HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N215HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N209HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N213HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N216HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N217HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N208HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N218HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N220HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N226HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N223HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N227HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N228HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "F-WZMS", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HAL", registration_name: "N230HA", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HLK", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HLJ", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HTG", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HTF", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HLE", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HTE", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HTD", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-LAB", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HTH", ship_number: "MSN1984", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-LAA", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HTI", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HWM", ship_number: "1457", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HLG", ship_number: "MSN118", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HLL", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HLI", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HLB", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HYI", ship_number: "MSN479", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HTJ", ship_number: "MSN3699", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HYF", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HYG", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HYJ", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HYB", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HYQ", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HTK", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSG", ship_number: "", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSD", ship_number: "MSN756", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSI", ship_number: "MSN930", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSJ", ship_number: "MSN1253", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSK", ship_number: "", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSL", ship_number: "MSN2229", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSM", ship_number: "MSN2238", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSN", ship_number: "MSN2428", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSO", ship_number: "MSN4023", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSP", ship_number: "MSN4247", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-LBD", ship_number: "1503", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSQ", ship_number: "", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSR", ship_number: "", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-LBE", ship_number: "1523", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-LBF", ship_number: "MS1545", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HSU", ship_number: "", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-LBI", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HST", ship_number: "", type_subtype: "320-230", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-LBG", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-LBH", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-LBK", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HLM", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HLT", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HLN", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HDA", registration_name: "B-HLO", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HFY", registration_name: "AT-HFY1", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HFY", registration_name: "9H-SOL", ship_number: "", type_subtype: "340-310", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HLF", registration_name: "D-ATUA", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HLF", registration_name: "D-ATUB", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HLF", registration_name: "D-ATUI", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HLF", registration_name: "D-ATUK", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HLF", registration_name: "D-ATUL", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HLF", registration_name: "D-ATUJ", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HLF", registration_name: "D-ATYG", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A379", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A147", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A151", ship_number: "", type_subtype: "767-300ER", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A381", ship_number: "", type_subtype: "330-240", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A344", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A345", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A347", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A348", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A349", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A350", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A351", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A378", ship_number: "", type_subtype: "330-220", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A352", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A353", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A377", ship_number: "", type_subtype: "330-220", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A354", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A356", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A376", ship_number: "", type_subtype: "330-220", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A357", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A375", ship_number: "", type_subtype: "330-240", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A358", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A359", ship_number: "", type_subtype: "321-230", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A360", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A374", ship_number: "", type_subtype: "330-220", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A361", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A362", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A372", ship_number: "", type_subtype: "330-220", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A363", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A365", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A371", ship_number: "", type_subtype: "330-220", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A366", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A367", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A322", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A323", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A324", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A325", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A326", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A327", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A329", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A339", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A331", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A332", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A338", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A336", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A335", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A334", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A392", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A390", ship_number: "", type_subtype: "321-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A886", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A887", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A888", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A889", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A617", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A618", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A619", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A624", ship_number: "", type_subtype: "321-200N", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A616", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A622", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A503", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "D-AZAI", ship_number: "", type_subtype: "321-200", system_assigned: "eXO", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A506", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A508", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A511", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A510", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "HVN", registration_name: "VN-A509", ship_number: "", type_subtype: "321-200", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASF", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASE", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASG", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASH", ship_number: "YS853", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASJ", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASI", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASK", ship_number: "YS856", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASQ", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASS", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASR", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-AST", ship_number: "YS860", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASU", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-AQZ", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASV", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IAW", registration_name: "YI-ASW", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-IOB", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-INO", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-IQR", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-DNR", ship_number: "", type_subtype: "300-100", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-IZX", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-IZY", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-JBA", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-JCY", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-JCZ", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-JFX", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-JLE", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-JNQ", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-JPU", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-LEU", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-LCZ", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-KZI", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-LEV", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-LFS", ship_number: "", type_subtype: "340-600", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-LUB", ship_number: "1377", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-LUK", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-LUX", ship_number: "1405", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-LXK", ship_number: "1426", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-LZJ", ship_number: "1490", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-LYF", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-MAA", ship_number: "1515", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-LZX", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-MIL", ship_number: "1694", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54633" },
{ operator_icao_code: "IBE", registration_name: "EC-MJA", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54623" },
{ operator_icao_code: "IBE", registration_name: "EC-MKI", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54630" },
{ operator_icao_code: "IBE", registration_name: "EC-MKJ", ship_number: "MSN1728", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54621" },
{ operator_icao_code: "IBE", registration_name: "EC-MJT", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54635" },
{ operator_icao_code: "IBE", registration_name: "EC-MLB", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54634" },
{ operator_icao_code: "IBE", registration_name: "EC-MLP", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54622" },
{ operator_icao_code: "IBE", registration_name: "EC-MMG", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54629" },
{ operator_icao_code: "IBE", registration_name: "EC-MNK", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54624" },
{ operator_icao_code: "IBE", registration_name: "EC-MNL", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54631" },
{ operator_icao_code: "IBE", registration_name: "F-WWCRNO", ship_number: "", type_subtype: "320-200", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "F-WWYY", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-MSY", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54632" },
{ operator_icao_code: "IBE", registration_name: "F-WWKH", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-MUD", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54625" },
{ operator_icao_code: "IBE", registration_name: "EC-MXV", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56237" },
{ operator_icao_code: "IBE", registration_name: "EC-MYX", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56238" },
{ operator_icao_code: "IBE", registration_name: "EC-NBE", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56239" },
{ operator_icao_code: "IBE", registration_name: "EC-NCX", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56240" },
{ operator_icao_code: "IBE", registration_name: "EC-NDR", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56241" },
{ operator_icao_code: "IBE", registration_name: "EC-NGT", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56243" },
{ operator_icao_code: "IBE", registration_name: "F-WZNC", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IBE", registration_name: "EC-NIG", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56244" },
{ operator_icao_code: "IBE", registration_name: "EC-NIS", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56245" },
{ operator_icao_code: "IFC", registration_name: "VT-ALV", ship_number: "", type_subtype: "777-300", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50489" },
{ operator_icao_code: "IRA", registration_name: "EP-IFA", ship_number: "", type_subtype: "321-100", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IRA", registration_name: "EP-IJA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IRA", registration_name: "EP-IJB", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ISS", registration_name: "I-EEZB", ship_number: "", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ISS", registration_name: "I-EEZA", ship_number: "", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ISS", registration_name: "I-EEZJ", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ISS", registration_name: "I-EEZL", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ISS", registration_name: "EI-GFX", ship_number: "", type_subtype: "330-200", system_assigned: "eX2-eXConnect", system_reported: "eX2-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ISS", registration_name: "EI-GGP", ship_number: "EI-GGP", type_subtype: "330-200", system_assigned: "eX2-eXConnect", system_reported: "eX2-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ISS", registration_name: "EI0GGO", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ISS", registration_name: "EI-GGO", ship_number: "", type_subtype: "330-200", system_assigned: "eX2-eXConnect", system_reported: "eX2-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ISS", registration_name: "EI-GGN", ship_number: "", type_subtype: "330-200", system_assigned: "eX2-eXConnect", system_reported: "eX2-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ISS", registration_name: "EI-GGR", ship_number: "", type_subtype: "330-200", system_assigned: "eX2-eXConnect", system_reported: "eX2-eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IWD", registration_name: "EC-LEQ", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IWD", registration_name: "EC-JHP", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IWD", registration_name: "CS-TRH", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IYE", registration_name: "7O-ADP", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IYE", registration_name: "7O-ADT", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IYE", registration_name: "7O-AFA", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "IYE", registration_name: "7O-AFB", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "J2G", registration_name: "80-1111", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "217" },
{ operator_icao_code: "J2G", registration_name: "80-1112", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "218" },
{ operator_icao_code: "JAF", registration_name: "OO-JAH", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAF", registration_name: "OO-JAX", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAF", registration_name: "OO-JAD", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAF", registration_name: "OO-JAU", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAF", registration_name: "OO-JAV", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAF", registration_name: "OO-JAY", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAF", registration_name: "OO-JEF", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JGQ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JGR", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JGS", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JGT", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JGU", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JGV", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JGW", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JEH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBB", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBD", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JGP", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JEK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JWV", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JWP", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JWQ", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBE", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBG", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBF", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBH", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBJ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBK", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBQ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBX", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBS", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JEM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBW", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JBR", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JFA", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JFB", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JFC", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JWS", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JWR", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JWT", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JWU", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JEQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JES", ship_number: "35159", type_subtype: "777-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JFS", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JFW", ship_number: "", type_subtype: "300-100", system_assigned: "Other", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JFT", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JFX", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JFY", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JFZ", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JTB", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JTA", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JWW", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JET", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JEU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JEV", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JEW", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAI", registration_name: "VT-JEX", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA701J", ship_number: "", type_subtype: "777-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "56422" },
{ operator_icao_code: "JAL", registration_name: "JA702J", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56423" },
{ operator_icao_code: "JAL", registration_name: "JA703J", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56424" },
{ operator_icao_code: "JAL", registration_name: "JA704J", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56425" },
{ operator_icao_code: "JAL", registration_name: "JA706J", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56504" },
{ operator_icao_code: "JAL", registration_name: "JA601J", ship_number: "", type_subtype: "767-300EREM", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA602J", ship_number: "", type_subtype: "767-300EREM", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA603J", ship_number: "", type_subtype: "767-300EREM", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA604J", ship_number: "", type_subtype: "767-300EREM", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA605J", ship_number: "", type_subtype: "767-300EREM", system_assigned: "3000,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA607J", ship_number: "", type_subtype: "767-300EREM", system_assigned: "3000,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56414" },
{ operator_icao_code: "JAL", registration_name: "JA705J", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56503" },
{ operator_icao_code: "JAL", registration_name: "JA606J", ship_number: "", type_subtype: "767-300ER", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56413" },
{ operator_icao_code: "JAL", registration_name: "JA752J", ship_number: "", type_subtype: "777-300", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA771J", ship_number: "", type_subtype: "777-240", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA731J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56490" },
{ operator_icao_code: "JAL", registration_name: "JA608J", ship_number: "", type_subtype: "767-300EREM", system_assigned: "3000,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56415" },
{ operator_icao_code: "JAL", registration_name: "JA609J", ship_number: "", type_subtype: "767-300EREM", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA708J", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56506" },
{ operator_icao_code: "JAL", registration_name: "JA709J", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56507" },
{ operator_icao_code: "JAL", registration_name: "JA707J", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56505" },
{ operator_icao_code: "JAL", registration_name: "JA610J", ship_number: "", type_subtype: "767-300ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA611J", ship_number: "", type_subtype: "767-300ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA612J", ship_number: "", type_subtype: "767-300ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA613J", ship_number: "", type_subtype: "767-300ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA710J", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56508" },
{ operator_icao_code: "JAL", registration_name: "JA711J", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56426" },
{ operator_icao_code: "JAL", registration_name: "JA732J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56491" },
{ operator_icao_code: "JAL", registration_name: "JA733J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56492" },
{ operator_icao_code: "JAL", registration_name: "JA734J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56493" },
{ operator_icao_code: "JAL", registration_name: "JA614J", ship_number: "", type_subtype: "767-300EREM", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA615J", ship_number: "", type_subtype: "767-300ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA616J", ship_number: "", type_subtype: "767-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56416" },
{ operator_icao_code: "JAL", registration_name: "JA735J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56488" },
{ operator_icao_code: "JAL", registration_name: "JA736J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56494" },
{ operator_icao_code: "JAL", registration_name: "JA737J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56495" },
{ operator_icao_code: "JAL", registration_name: "JA738J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56496" },
{ operator_icao_code: "JAL", registration_name: "JA739J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56497" },
{ operator_icao_code: "JAL", registration_name: "JA740J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56498" },
{ operator_icao_code: "JAL", registration_name: "JA617J", ship_number: "", type_subtype: "767-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56417" },
{ operator_icao_code: "JAL", registration_name: "JA304J", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA303J", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA305J", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA310J", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA312J", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA315J", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA317J", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA618J", ship_number: "", type_subtype: "767-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56418" },
{ operator_icao_code: "JAL", registration_name: "JA619J", ship_number: "", type_subtype: "767-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56419" },
{ operator_icao_code: "JAL", registration_name: "JA620J", ship_number: "", type_subtype: "767-300ER", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56420" },
{ operator_icao_code: "JAL", registration_name: "JA621J", ship_number: "", type_subtype: "767-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56421" },
{ operator_icao_code: "JAL", registration_name: "JA622J", ship_number: "", type_subtype: "767-300ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA623J", ship_number: "", type_subtype: "767-300ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA320J", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA321J", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA741J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56499" },
{ operator_icao_code: "JAL", registration_name: "JA742J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56500" },
{ operator_icao_code: "JAL", registration_name: "JA743J", ship_number: "", type_subtype: "777-300ER", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56501" },
{ operator_icao_code: "JAL", registration_name: "JA840J", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56461" },
{ operator_icao_code: "JAL", registration_name: "JA842J", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56475" },
{ operator_icao_code: "JAL", registration_name: "JA841J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56463" },
{ operator_icao_code: "JAL", registration_name: "JA839J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56445" },
{ operator_icao_code: "JAL", registration_name: "JA821J", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56446" },
{ operator_icao_code: "JAL", registration_name: "JA823J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56448" },
{ operator_icao_code: "JAL", registration_name: "JA822J-OLD", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eX3,eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA824J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56449" },
{ operator_icao_code: "JAL", registration_name: "JA826J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56451" },
{ operator_icao_code: "JAL", registration_name: "JA827J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "2735" },
{ operator_icao_code: "JAL", registration_name: "JA828J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56453" },
{ operator_icao_code: "JAL", registration_name: "JA829J", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56454" },
{ operator_icao_code: "JAL", registration_name: "JA830J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56455" },
{ operator_icao_code: "JAL", registration_name: "JA834J", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56473" },
{ operator_icao_code: "JAL", registration_name: "JA832J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "56457" },
{ operator_icao_code: "JAL", registration_name: "JA833J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56458" },
{ operator_icao_code: "JAL", registration_name: "JA831J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56456" },
{ operator_icao_code: "JAL", registration_name: "JA835J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56459" },
{ operator_icao_code: "JAL", registration_name: "JA837J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56460" },
{ operator_icao_code: "JAL", registration_name: "JA838J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56462" },
{ operator_icao_code: "JAL", registration_name: "JA836J", ship_number: "", type_subtype: "787-8", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56472" },
{ operator_icao_code: "JAL", registration_name: "JA861J", ship_number: "", type_subtype: "787-9", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56466" },
{ operator_icao_code: "JAL", registration_name: "JA862J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56467" },
{ operator_icao_code: "JAL", registration_name: "JA843J", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56476" },
{ operator_icao_code: "JAL", registration_name: "JA844J", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56464" },
{ operator_icao_code: "JAL", registration_name: "JA845J", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56465" },
{ operator_icao_code: "JAL", registration_name: "JA863J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56468" },
{ operator_icao_code: "JAL", registration_name: "JA864J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56469" },
{ operator_icao_code: "JAL", registration_name: "JA865J", ship_number: "458", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56470" },
{ operator_icao_code: "JAL", registration_name: "JA866J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56471" },
{ operator_icao_code: "JAL", registration_name: "JA867J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56502" },
{ operator_icao_code: "JAL", registration_name: "JA868J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56474" },
{ operator_icao_code: "JAL", registration_name: "JA869J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56477" },
{ operator_icao_code: "JAL", registration_name: "JA870J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56478" },
{ operator_icao_code: "JAL", registration_name: "JA871J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56479" },
{ operator_icao_code: "JAL", registration_name: "JA872J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56480" },
{ operator_icao_code: "JAL", registration_name: "JA873J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56481" },
{ operator_icao_code: "JAL", registration_name: "JA874J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56482" },
{ operator_icao_code: "JAL", registration_name: "JA875J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56483" },
{ operator_icao_code: "JAL", registration_name: "JA876J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56484" },
{ operator_icao_code: "JAL", registration_name: "JA877J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56485" },
{ operator_icao_code: "JAL", registration_name: "JA01XJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56365" },
{ operator_icao_code: "JAL", registration_name: "JA56435", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA02XJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56367" },
{ operator_icao_code: "JAL", registration_name: "JA03XJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56368" },
{ operator_icao_code: "JAL", registration_name: "JA04XJ", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56369" },
{ operator_icao_code: "JAL", registration_name: "JA05XJ", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56370" },
{ operator_icao_code: "JAL", registration_name: "JA06XJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56371" },
{ operator_icao_code: "JAL", registration_name: "JA846J", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56374" },
{ operator_icao_code: "JAL", registration_name: "JA847J", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56377" },
{ operator_icao_code: "JAL", registration_name: "JA848J", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56378" },
{ operator_icao_code: "JAL", registration_name: "JA849J", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56379" },
{ operator_icao_code: "JAL", registration_name: "JA07XJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA08XJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA09XJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA10XJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA11XJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA878J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56486" },
{ operator_icao_code: "JAL", registration_name: "JA880J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56489" },
{ operator_icao_code: "JAL", registration_name: "JA880", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JAL", registration_name: "JA879J", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56487" },
{ operator_icao_code: "JNA", registration_name: "HL7733", ship_number: "", type_subtype: "777-200", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL8012", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL8013", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL8015", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL7743", ship_number: "", type_subtype: "777-200", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL7734", ship_number: "", type_subtype: "777-200", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL8014", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL8016", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL7750", ship_number: "", type_subtype: "777-200", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL8017", ship_number: "YT396", type_subtype: "737-800", system_assigned: "eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL8224", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL8225", ship_number: "YR362", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL8242", ship_number: "YR365", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL8243", ship_number: "YR366", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JNA", registration_name: "HL8244", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VGP", ship_number: "", type_subtype: "320-200", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VKA", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VKB", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VKD", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VKE", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VKF", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VKH", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VKG", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VKI", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VKJ", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VKK", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JST", registration_name: "VH-VKL", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JTA", registration_name: "JA01RK", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JTA", registration_name: "JA02RK", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JTA", registration_name: "JA03RK", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JTA", registration_name: "JA04RK", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JTA", registration_name: "JA05RK", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "JTA", registration_name: "JA06RK", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAC", registration_name: "9K-AKE", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAC", registration_name: "9K-AKF", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAC", registration_name: "9K-AKG", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAC", registration_name: "9K-AKH", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAC", registration_name: "9K-AKI", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAC", registration_name: "9K-AKJ", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAC", registration_name: "9K-AKK", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAC", registration_name: "9K-AOC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6530" },
{ operator_icao_code: "KAC", registration_name: "9K-AOD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6531" },
{ operator_icao_code: "KAC", registration_name: "9K-AOE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6532" },
{ operator_icao_code: "KAC", registration_name: "9K-AOF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6533" },
{ operator_icao_code: "KAC", registration_name: "9K-AOH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6534" },
{ operator_icao_code: "KAC", registration_name: "9K-AOI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6535" },
{ operator_icao_code: "KAC", registration_name: "9K-AOJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6536" },
{ operator_icao_code: "KAC", registration_name: "9K-AOK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6537" },
{ operator_icao_code: "KAC", registration_name: "9K-AOL", ship_number: "WE784", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6538" },
{ operator_icao_code: "KAC", registration_name: "9K-AOM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6539" },
{ operator_icao_code: "KAC", registration_name: "F-WWCL", ship_number: "", type_subtype: "330-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAC", registration_name: "F-WWYU", ship_number: "", type_subtype: "330-", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7402", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7461", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7465", ship_number: "", type_subtype: "747-400", system_assigned: "3000i,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57470" },
{ operator_icao_code: "KAL", registration_name: "HL8223", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7751", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7752", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7764", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7765", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7766", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7611", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8221", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7612", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7613", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7614", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8240", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7615", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8241", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8245", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8246", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8247", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8248", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8249", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7619", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8272", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8273", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7621", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7622", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7627", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7628", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8010", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8011", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8006", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8007", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7630", ship_number: "", type_subtype: "747-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7631", ship_number: "", type_subtype: "747-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8008", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7632", ship_number: "", type_subtype: "747-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7633", ship_number: "", type_subtype: "747-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8009", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7636", ship_number: "", type_subtype: "747-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7638", ship_number: "", type_subtype: "747-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8041", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7637", ship_number: "", type_subtype: "747-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8042", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7642", ship_number: "", type_subtype: "747-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7643", ship_number: "", type_subtype: "747-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7644", ship_number: "RC060", type_subtype: "747-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8092", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eX1,eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8093", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eX1,eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7202", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7200", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7203", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7201", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7204", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL7205", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8313", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8311", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8312", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8314", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eXW", system_reported: "CMS", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8315", ship_number: "", type_subtype: "220-300", system_assigned: "CMS,eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8091", ship_number: "55049", type_subtype: "220-300", system_assigned: "CMS,eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "N507BJ", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8346", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "KE-B747L", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "GEN3",xid: "57482" },
{ operator_icao_code: "KAL", registration_name: "HL8347", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KAL", registration_name: "HL8350", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57469" },
{ operator_icao_code: "KAL", registration_name: "HL8351", ship_number: "", type_subtype: "737-800", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQA", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQC", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQB", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQD", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFG", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFH", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFI", ship_number: "", type_subtype: "747-400PC", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFK", ship_number: "", type_subtype: "747-400PC", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFL", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFN", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFS", ship_number: "", type_subtype: "747-400PC", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFU", ship_number: "", type_subtype: "747-400PC", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFT", ship_number: "", type_subtype: "747-400PC", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFV", ship_number: "", type_subtype: "747-400PC", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFW", ship_number: "", type_subtype: "747-400PC", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BFY", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQE", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQF", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQG", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQH", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQK", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQI", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AOA", ship_number: "", type_subtype: "330-200", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AOB", ship_number: "", type_subtype: "330-200", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AOC", ship_number: "", type_subtype: "330-200", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AOD", ship_number: "", type_subtype: "330-200", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AOE", ship_number: "", type_subtype: "330-200", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AOF", ship_number: "", type_subtype: "330-200", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQL", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQM", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQN", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQO", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BQP", ship_number: "", type_subtype: "777-200ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AOM", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6049" },
{ operator_icao_code: "KLM", registration_name: "PH-BVG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AKA", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AKB", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AKD", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AKE", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AON", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-AKF", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BHA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56590" },
{ operator_icao_code: "KLM", registration_name: "PH-BHC", ship_number: "ZB235", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56591" },
{ operator_icao_code: "KLM", registration_name: "PH-BHD", ship_number: "ZB236", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56604" },
{ operator_icao_code: "KLM", registration_name: "PH-BHE", ship_number: "ZB237", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56598" },
{ operator_icao_code: "KLM", registration_name: "PH-BHG", ship_number: "ZB302", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56600" },
{ operator_icao_code: "KLM", registration_name: "PH-BHH", ship_number: "ZB238", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "56592" },
{ operator_icao_code: "KLM", registration_name: "PH-BHI", ship_number: "ZB240", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56593" },
{ operator_icao_code: "KLM", registration_name: "PH-BHK", ship_number: "ZB241", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BHL", ship_number: "ZB342", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56597" },
{ operator_icao_code: "KLM", registration_name: "PH-BHF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56601" },
{ operator_icao_code: "KLM", registration_name: "PH-BHM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56603" },
{ operator_icao_code: "KLM", registration_name: "PH-BVP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVR", ship_number: "1406", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-TKL", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BVU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KLM", registration_name: "PH-BHN", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56599" },
{ operator_icao_code: "KLM", registration_name: "PH-BHO", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56595" },
{ operator_icao_code: "KLM", registration_name: "PH-BHP", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56602" },
{ operator_icao_code: "KLM", registration_name: "PH-BKA", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56589" },
{ operator_icao_code: "KLM", registration_name: "PH-BKC", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56596" },
{ operator_icao_code: "KLM", registration_name: "PH-BKD", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56594" },
{ operator_icao_code: "KLM", registration_name: "PH-BKF", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56605" },
{ operator_icao_code: "KLM", registration_name: "PH-BKG", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56606" },
{ operator_icao_code: "KQA", registration_name: "5Y-FFA", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-FFB", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-FFC", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-FFD", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-FFE", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-FFF", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-FFG", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-FFH", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-FFJ", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-FFK", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-KZA", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-KZB", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-KZC", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-KZD", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-KZE", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-CYA", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-KZF", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-CYB", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-CYC", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-CYD", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-KZG", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-CYE", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-KZH", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KQA", registration_name: "5Y-KZJ", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KZR", registration_name: "P4-KDA", ship_number: "", type_subtype: "321-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KZR", registration_name: "P4-VAS", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KZR", registration_name: "P4-KDB", ship_number: "", type_subtype: "321-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KZR", registration_name: "P4-KBA", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KZR", registration_name: "P4-KBB", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KZR", registration_name: "P4-KBC", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KZR", registration_name: "P4-KBD", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "KZR", registration_name: "P4-KBE", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "L5L", registration_name: "EC-MOY", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3154" },
{ operator_icao_code: "L5L", registration_name: "EC-MOU", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "3153" },
{ operator_icao_code: "L5L", registration_name: "F-HLVL", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "L5L", registration_name: "F-HLVM", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "L5L", registration_name: "EC-MYA", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "L5L", registration_name: "EC-NEN", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "L5L", registration_name: "F-HLVN", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CPJ", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CPL", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CPM", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CPO", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CPQ", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CQK", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CQL", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CQM", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CQN", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "HC-CPR", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "HC-CPJ", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CQO", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "LV-GUS", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "HC-CQU", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CYJ", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-CYL", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAA", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAB", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAC", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAD", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAE", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAG", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAH", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "LV-FUX", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAF", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAK", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAL", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAM", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "HC-CPZ", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "HC-CPY", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAQ", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BCD", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAJ", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAR", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAT", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAS", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAU", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAV", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAX", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BBA", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BBB", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BBC", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFB", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFD", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFC", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFE", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFF", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFH", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFG", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFI", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFJ", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BBE", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BBD", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFU", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BBF", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BBG", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BBH", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BBI", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BBJ", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGB", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGC", ship_number: "35321", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGD", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGE", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGH", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEA", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEB", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEC", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BED", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEE", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEF", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEG", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEH", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEI", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGI", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGK", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGL", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BCE", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BCF", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAW", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAY", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BAZ", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFK", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFL", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFN", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFM", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFO", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BJE", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BJF", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BJD", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFP", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFQ", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFR", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFS", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFT", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFV", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BFX", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEJ", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEK", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEL", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEM", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BEN", ship_number: "", type_subtype: "321-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGN", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGO", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "CC-BGP", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LAN", registration_name: "PT-MOF", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2-eXConnect", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LDI", registration_name: "I-LIVM", ship_number: "", type_subtype: "330-200", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LDI", registration_name: "I-LIVN", ship_number: "", type_subtype: "330-200", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LDI", registration_name: "I-LIVL", ship_number: "", type_subtype: "330-200", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LNI", registration_name: "PK-LKZ", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LNI", registration_name: "PK-LLF", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "LTU", registration_name: "D-AIPI", ship_number: "", type_subtype: "330-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXR", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXB", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXC", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTA", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTC", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTB", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTD", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXD", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTE", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXF", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXE", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTF", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXG", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXH", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MLM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MLN", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTG", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTH", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTJ", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MLO", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXI", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTI", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXJ", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MLP", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXK", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MLQ", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXL", ship_number: "9M-MXL", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MLR", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXN", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXO", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTK", ship_number: "1388", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTL", ship_number: "1395", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MSB", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MSA", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTM", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MSE", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MSF", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MSG", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MLS", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MLT", ship_number: "YR098", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXQ", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTN", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXS", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXT", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MLU", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXU", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MLV", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXA", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXV", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXX", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MXW", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MTO", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55836" },
{ operator_icao_code: "MAS", registration_name: "9M-MXY", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAS", registration_name: "9M-MAB", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55824" },
{ operator_icao_code: "MAS", registration_name: "9M-MAC", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55825" },
{ operator_icao_code: "MAS", registration_name: "9M-MAD", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55826" },
{ operator_icao_code: "MAS", registration_name: "9M-MAE", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55821" },
{ operator_icao_code: "MAS", registration_name: "9M-MAF", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55822" },
{ operator_icao_code: "MAS", registration_name: "9M-MAG", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55823" },
{ operator_icao_code: "MAS", registration_name: "9M-MTU", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55827" },
{ operator_icao_code: "MAS", registration_name: "9M-MTV", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55828" },
{ operator_icao_code: "MAS", registration_name: "9M-MTW", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55829" },
{ operator_icao_code: "MAS", registration_name: "9M-MTY", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55830" },
{ operator_icao_code: "MAS", registration_name: "9M-MTZ", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55831" },
{ operator_icao_code: "MAU", registration_name: "3B-NAU", ship_number: "", type_subtype: "340-300", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAU", registration_name: "3B-NBD", ship_number: "", type_subtype: "340-300", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MAU", registration_name: "3B-NBE", ship_number: "", type_subtype: "340-300", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MED", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MRT", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MEA", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MRS", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MEB", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MRR", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MEC", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MRO", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MRN", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MRM", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MRL", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "T7-MRA", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "T7-MRB", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "T7-MRC", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "T7-MRD", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "T7-MRE", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "T7-MRF", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "OD-MEE", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MEA", registration_name: "D-AVXK", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MGL", registration_name: "JU-1021", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MGL", registration_name: "EI-MNG", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MGL", registration_name: "1J602", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MJN", registration_name: "554", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MJN", registration_name: "555", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MJN", registration_name: "556", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEZ", ship_number: "", type_subtype: "220-300", system_assigned: "eXW-eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57077" },
{ operator_icao_code: "MSR", registration_name: "SU-GCH", ship_number: "", type_subtype: "330-240", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GCI", ship_number: "", type_subtype: "330-240", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GCK", ship_number: "", type_subtype: "330-240", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GCJ", ship_number: "", type_subtype: "330-240", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDS", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GCM", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GCN", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GCO", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GCP", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GCS", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GCZ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GCR", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDA", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDB", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDC", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDE", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDX", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDD", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDY", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDZ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEA", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDT", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEB", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDU", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GDV", ship_number: "", type_subtype: "330-340", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEC", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GED", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEE", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEF", ship_number: "6190", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEG", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEH", ship_number: "YW275", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEI", ship_number: "YW276", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEJ", ship_number: "YW277", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEK", ship_number: "YW278", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEL", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GEN", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GER", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57069" },
{ operator_icao_code: "MSR", registration_name: "SU-GES", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57070" },
{ operator_icao_code: "MSR", registration_name: "SU-GEU", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57071" },
{ operator_icao_code: "MSR", registration_name: "SU-GET", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57072" },
{ operator_icao_code: "MSR", registration_name: "SU-GEV", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "57073" },
{ operator_icao_code: "MSR", registration_name: "SU-GEW", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57074" },
{ operator_icao_code: "MSR", registration_name: "SU-GEX", ship_number: "", type_subtype: "220-300", system_assigned: "eXW-eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57075" },
{ operator_icao_code: "MSR", registration_name: "SU-GEY", ship_number: "", type_subtype: "220-300", system_assigned: "eXW-eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57076" },
{ operator_icao_code: "MSR", registration_name: "SU-GFB", ship_number: "", type_subtype: "220-300", system_assigned: "eXW-eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57080" },
{ operator_icao_code: "MSR", registration_name: "SU-GFC", ship_number: "", type_subtype: "220-300", system_assigned: "eXW-eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57081" },
{ operator_icao_code: "MSR", registration_name: "F-WWBBNOT", ship_number: "", type_subtype: "320-200", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GFE", ship_number: "", type_subtype: "220-300", system_assigned: "eXW-eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57082" },
{ operator_icao_code: "MSR", registration_name: "SU-GFA", ship_number: "", type_subtype: "220-300", system_assigned: "eXW-eXConnect", system_reported: "eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GFD", ship_number: "", type_subtype: "220-300", system_assigned: "eXW-eXConnect", system_reported: "eXConnect", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MSR", registration_name: "SU-GFF", ship_number: "", type_subtype: "220-300", system_assigned: "eXW-eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57084" },
{ operator_icao_code: "MSR", registration_name: "SU-GFJ", ship_number: "", type_subtype: "320-200", system_assigned: "eX1,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57083" },
{ operator_icao_code: "MSR", registration_name: "SU-GFK", ship_number: "", type_subtype: "320-200", system_assigned: "eX1-eXConnect", system_reported: "eX1", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57085" },
{ operator_icao_code: "MSR", registration_name: "SU-GFL", ship_number: "", type_subtype: "320-200", system_assigned: "eX1-eXConnect", system_reported: "eX1", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57086" },
{ operator_icao_code: "MSR", registration_name: "SU-GFM", ship_number: "", type_subtype: "320-200", system_assigned: "eX1,eXConnect", system_reported: "eX1", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57087" },
{ operator_icao_code: "MXA", registration_name: "N429MX", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "XA-MXQ", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "XA-MXP", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "F-OHMK", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "F-OHMM", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "N213MX", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "N292MX", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "N304ML", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "N361DA", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "N368MX", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "N369MX", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "N405MX", ship_number: "", type_subtype: "320-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "N428MX", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "N706MX", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "XA-MXK", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "XA-MXL", ship_number: "", type_subtype: "320-210", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "XA-UBW", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "XA-UBX", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "XA-UBY", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXA", registration_name: "XA-UBZ", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXD", registration_name: "9M-LNG", ship_number: "", type_subtype: "737-900", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2978" },
{ operator_icao_code: "MXD", registration_name: "9M-LNF", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "MXD", registration_name: "9M-LNH", ship_number: "", type_subtype: "737-900", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2979" },
{ operator_icao_code: "MXD", registration_name: "9M-LNJ", ship_number: "", type_subtype: "737-900", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2980" },
{ operator_icao_code: "MXD", registration_name: "9M-LNL", ship_number: "", type_subtype: "737-900", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "2982" },
{ operator_icao_code: "MXD", registration_name: "9M-LNK", ship_number: "", type_subtype: "737-900", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "2981" },
{ operator_icao_code: "MXD", registration_name: "9M-LNY", ship_number: "", type_subtype: "737-800", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAB", registration_name: "NOT-LNLNB", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNA", ship_number: "ZA576", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNB", ship_number: "ZA577", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNC", ship_number: "ZA650", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LND", ship_number: "ZA578", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNE", ship_number: "ZA651", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNF", ship_number: "ZA579", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNG", ship_number: "ZA580", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNI", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNH", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNW", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNK", ship_number: "ZB648", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNL", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNO", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNN", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNR", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNV", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNS", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKMU", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "SE-RXZ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKOF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKOG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKWB", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKWA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKWC", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKWD", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKWE", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKWF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNP", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKWN", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNT", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKWS", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNU", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKWP", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "LN-LNX", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKWT", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CKWU", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "SE-RXM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "G-CLJN", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NAX", registration_name: "SE-RXA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEA", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEB", ship_number: "", type_subtype: "320-214", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEC", ship_number: "", type_subtype: "320-214", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEE", ship_number: "", type_subtype: "320-214", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEF", ship_number: "", type_subtype: "320-214", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEG", ship_number: "", type_subtype: "320-214", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEH", ship_number: "", type_subtype: "320-214", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LES", ship_number: "", type_subtype: "321-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LET", ship_number: "", type_subtype: "321-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEU", ship_number: "", type_subtype: "320-214", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEW", ship_number: "", type_subtype: "321-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEX", ship_number: "", type_subtype: "320-214", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEY", ship_number: "", type_subtype: "320-214", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LEZ", ship_number: "", type_subtype: "321-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LCI", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LCJ", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LCM", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NLY", registration_name: "OE-LCL", ship_number: "", type_subtype: "321-100", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NOS", registration_name: "EI-NEO", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NOS", registration_name: "EI-NEW", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NOS", registration_name: "EI-NEU", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NOS", registration_name: "EI-NUA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NTC", registration_name: "HS-XBA", ship_number: "", type_subtype: "777-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NTC", registration_name: "HS-XBB", ship_number: "", type_subtype: "777-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "NTC", registration_name: "HS-XBC", ship_number: "", type_subtype: "777-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OAL", registration_name: "SX-DFA", ship_number: "235", type_subtype: "340-300", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OAL", registration_name: "SX-DFB", ship_number: "239", type_subtype: "340-300", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OAL", registration_name: "SX-DFC", ship_number: "280", type_subtype: "340-300", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OAL", registration_name: "SX-DFD", ship_number: "292", type_subtype: "340-300", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OBT", registration_name: "N744VG", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57325" },
{ operator_icao_code: "OMA", registration_name: "A4O-SY", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OMA", registration_name: "A4O-SZ", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OMA", registration_name: "A40-DZ", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OMS", registration_name: "A40-OVE", ship_number: "", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OMS", registration_name: "A40-OVI", ship_number: "", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OMS", registration_name: "A40-OVF", ship_number: "", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OMS", registration_name: "A40-OVG", ship_number: "", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "OMS", registration_name: "A40-OVH", ship_number: "", type_subtype: "320-200", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ORB", registration_name: "VQ-ORB", ship_number: "", type_subtype: "300-100", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ORB", registration_name: "VQ-BVV", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ORB", registration_name: "VQ-BBU", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ORB", registration_name: "VQ-BWG", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ORB", registration_name: "VQ-BWH", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "ORB", registration_name: "VQ-BWJ", ship_number: "YT131", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-02666", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-02679", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-04940", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05062", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05063", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05065", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05066", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05067", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05068", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05069", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05070", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05072", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-04930", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05176", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05241", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-05473", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "P4N", registration_name: "PA-00652", ship_number: "", type_subtype: "777-300", system_assigned: "2000E,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8600", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8601", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8602", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8603", ship_number: "", type_subtype: "319-110", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8604", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8605", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8606", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8607", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8609", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8610", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8611", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8612", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8613", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8614", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8615", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C7777", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C7776", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8616", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C7775", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8618", ship_number: "", type_subtype: "320-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8619", ship_number: "", type_subtype: "320-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C7774", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C8620", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C7773", ship_number: "38718", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C7772", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C7778", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C7779", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C7782", ship_number: "", type_subtype: "777-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PAL", registration_name: "RP-C7781", ship_number: "", type_subtype: "777-300", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PIA", registration_name: "AP-BGJ", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PIA", registration_name: "AP-BGK", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PIA", registration_name: "AP-BGL", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PIA", registration_name: "AP-BGY", ship_number: "", type_subtype: "777-200LR", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PIA", registration_name: "AP-BGZ", ship_number: "", type_subtype: "777-200LR", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PIA", registration_name: "AP-BHV", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PIA", registration_name: "AP-BHW", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PIA", registration_name: "AP-BHX", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PIA", registration_name: "AP-BID", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PIA", registration_name: "AP-BMS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PLM", registration_name: "EC-KQC", ship_number: "RT525", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PLM", registration_name: "EC-KSM", ship_number: "RT523", type_subtype: "747-400", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PLM", registration_name: "EC-KXN", ship_number: "RT749", type_subtype: "747-400", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PLM", registration_name: "EC-MTT", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "PLM", registration_name: "EC-MTU", ship_number: "", type_subtype: "330-200", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QAF", registration_name: "A7-HHE", ship_number: "", type_subtype: "747-800", system_assigned: "eX2", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1202" },
{ operator_icao_code: "QAF", registration_name: "A7-HJA", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QAF", registration_name: "A7-HHF", ship_number: "", type_subtype: "747-800", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50494" },
{ operator_icao_code: "QDA", registration_name: "B-9956", ship_number: "", type_subtype: "300-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBV", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXW", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQA", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQB", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQC", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQD", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "ZK-ZQA", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "ZK-ZQB", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "ZK-ZQC", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBM", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXW", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQE", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQF", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBN", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXW", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQG", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQH", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQI", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBO", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXW", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBP", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXW", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQJ", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "ZK-ZQD", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "ZK-ZQE", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "ZK-ZQF", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZL", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZM", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZO", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZP", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "ZK-ZQG", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "ZK-ZQH", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQL", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZR", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZS", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OEG", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZT", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OQK", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXW", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZU", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZV", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OEI", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZW", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZX", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OEH", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZY", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OEJ", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OEE", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZZ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-OEF", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZA", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZB", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZC", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZD", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBS", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXW", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZE", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZF", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZG", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZH", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZI", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZJ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-YQS", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-YQT", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZK", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-YQU", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-YQV", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBQ", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXW", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBR", ship_number: "", type_subtype: "330-200", system_assigned: "eX2,eXW", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-YQW", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBB", ship_number: "", type_subtype: "330-200", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZL", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZM", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZN", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBC", ship_number: "", type_subtype: "330-200", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBA", ship_number: "", type_subtype: "330-200", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZO", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBD", ship_number: "", type_subtype: "330-200", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-XZP", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-QPA", ship_number: "", type_subtype: "330-300", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-QPC", ship_number: "", type_subtype: "330-300", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBJ", ship_number: "", type_subtype: "330-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-QPB", ship_number: "", type_subtype: "330-300", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-QPD", ship_number: "", type_subtype: "330-300", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-QPI", ship_number: "", type_subtype: "330-300", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBK", ship_number: "", type_subtype: "330-200", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXR", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBF", ship_number: "", type_subtype: "330-200", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-QPE", ship_number: "", type_subtype: "330-300", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXQ", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZB", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXA", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXB", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXC", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXD", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXE", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXF", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXG", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXH", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXI", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXJ", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXK", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXL", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXM", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXN", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXO", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXP", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXS", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXT", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VXU", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYA", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYB", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYC", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYD", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYE", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYF", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYG", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYH", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYI", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYJ", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYK", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VYL", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZA", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZC", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZD", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZE", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBE", ship_number: "", type_subtype: "330-200", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-QPF", ship_number: "", type_subtype: "330-300", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-QPH", ship_number: "", type_subtype: "330-300", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-YQY", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-YQX", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-QPG", ship_number: "", type_subtype: "330-300", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-NXJ", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-QPJ", ship_number: "", type_subtype: "330-300", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-NXI", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-NXR", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-ZNB", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-ZNA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-NXQ", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-ZNC", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-NXE", ship_number: "", type_subtype: "717-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-ZND", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-ZNE", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-ZNF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-ZNH", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-ZNG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBL", ship_number: "", type_subtype: "330-200", system_assigned: "eX1,eXW", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZI", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZK", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-VZJ", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-EBG", ship_number: "", type_subtype: "330-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-ZNI", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-ZNJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QFA", registration_name: "VH-ZNK", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QQE", registration_name: "A7-HBJ", ship_number: "", type_subtype: "747-800", system_assigned: "eXConnect", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN1",xid: "1204" },
{ operator_icao_code: "QTR", registration_name: "A7-AEB", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-AEA", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-AEC", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-AED", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-AEE", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-AEF", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-AEG", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-ACG", ship_number: "", type_subtype: "330-200", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-ACI", ship_number: "", type_subtype: "330-200", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-ACJ", ship_number: "", type_subtype: "330-200", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-ACK", ship_number: "", type_subtype: "330-200", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-ACL", ship_number: "", type_subtype: "330-200", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-ACM", ship_number: "", type_subtype: "330-200", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-AEI", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-AEJ", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-AEM", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-AEN", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-AEO", ship_number: "", type_subtype: "330-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BBA", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BBB", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BBC", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BBD", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BBE", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BBF", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BBG", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BBH", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BBI", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAX", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAW", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAY", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAZ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAT", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BAV", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BED", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BER", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BES", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEV", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEW", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BET", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "QTR", registration_name: "A7-BEX", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "R2M", registration_name: "CNMB-H", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "R2M", registration_name: "CN-MBH", ship_number: "", type_subtype: "747-800", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1223" },
{ operator_icao_code: "RAM", registration_name: "CN-ROW", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RAM", registration_name: "CN-RGE", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RAM", registration_name: "CN-RGF", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RAM", registration_name: "CN-RGG", ship_number: "", type_subtype: "737-800", system_assigned: "MPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RAM", registration_name: "CN-RGH", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RAM", registration_name: "CN-RGI", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RAS", registration_name: "DONTUSEEC-NCY", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RBA", registration_name: "V8-RBU", ship_number: "V8-RBU", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RBA", registration_name: "V8-DLA", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RBA", registration_name: "V8-DLB", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RBA", registration_name: "V8-DLC", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RBA", registration_name: "V8-DLD", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RBA", registration_name: "V8-DLE", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "REU", registration_name: "F-ONOU", ship_number: "NOU", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "REU", registration_name: "F-ONGA", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "REU", registration_name: "F-OREU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "REU", registration_name: "F-ONGB", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "REU", registration_name: "F-OLRA", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "REU", registration_name: "F-OLRB", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "REU", registration_name: "F-OLRD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1969" },
{ operator_icao_code: "REU", registration_name: "F-OLRC", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "REU", registration_name: "F-OLRE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "1970" },
{ operator_icao_code: "REU", registration_name: "REU-222", ship_number: "", type_subtype: "737-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RJA", registration_name: "JY-AIF", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RJA", registration_name: "JY-AIG", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RLH", registration_name: "YW493", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RLH", registration_name: "YW491", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RLH", registration_name: "YW492", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RWD", registration_name: "9XR-WG", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RWD", registration_name: "9XR-WQ", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "eFX-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4289" },
{ operator_icao_code: "RWD", registration_name: "9XR-WR", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "eFX-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4290" },
{ operator_icao_code: "RWD", registration_name: "F-WWCJ", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "58173" },
{ operator_icao_code: "RWD", registration_name: "F-WWKQ", ship_number: "", type_subtype: "330-900", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "58174" },
{ operator_icao_code: "RZO", registration_name: "CS-TSF", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RZO", registration_name: "CS-TSG", ship_number: "", type_subtype: "321-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "RZO", registration_name: "CS-TSH", ship_number: "", type_subtype: "321-100", system_assigned: "eXW-eXConnect", system_reported: "eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56142" },
{ operator_icao_code: "S2A", registration_name: "N801XA", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "S2A", registration_name: "N802XA", ship_number: "", type_subtype: "737-800", system_assigned: "eX3,eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "S2A", registration_name: "N803XA", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "S2A", registration_name: "N804XA", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "S2A", registration_name: "N805XA", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "S2A", registration_name: "N806XA", ship_number: "", type_subtype: "737-800", system_assigned: "eXO,eXW", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SNA", ship_number: "", type_subtype: "340-600", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SNG", ship_number: "", type_subtype: "340-600", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SNE", ship_number: "", type_subtype: "340-600", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SNB", ship_number: "", type_subtype: "340-600", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SNC", ship_number: "", type_subtype: "340-600", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SNF", ship_number: "", type_subtype: "340-600", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SND", ship_number: "", type_subtype: "340-600", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SNH", ship_number: "", type_subtype: "340-640", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SNI", ship_number: "", type_subtype: "340-640", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SXA", ship_number: "", type_subtype: "340-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SXB", ship_number: "", type_subtype: "340-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SXC", ship_number: "", type_subtype: "340-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SXD", ship_number: "", type_subtype: "340-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SXE", ship_number: "", type_subtype: "340-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SXF", ship_number: "", type_subtype: "340-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SDC", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAA", registration_name: "ZS-SDD", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RCN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RPL", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RZC", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RCX", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RCY", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RPO", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RPR", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RCZ", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RRS", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RRL", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RRT", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RRW", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RPM", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RPN", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RRK", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RRU", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SAS", registration_name: "LN-RKH", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57166" },
{ operator_icao_code: "SAS", registration_name: "LN-RKM", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57167" },
{ operator_icao_code: "SAS", registration_name: "LN-RKN", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57168" },
{ operator_icao_code: "SAS", registration_name: "LN-RKO", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57172" },
{ operator_icao_code: "SAS", registration_name: "OY-KBM", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57174" },
{ operator_icao_code: "SAS", registration_name: "OY-KBA", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57169" },
{ operator_icao_code: "SAS", registration_name: "LN-RKF", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57170" },
{ operator_icao_code: "SAS", registration_name: "LN-RKR", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "2484" },
{ operator_icao_code: "SAS", registration_name: "LN-RKS", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "2485" },
{ operator_icao_code: "SAS", registration_name: "LN-RKT", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "2486" },
{ operator_icao_code: "SAS", registration_name: "LN-RKG", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57165" },
{ operator_icao_code: "SAS", registration_name: "OY-KBC", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57180" },
{ operator_icao_code: "SAS", registration_name: "OY-KBI", ship_number: "430", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57171" },
{ operator_icao_code: "SAS", registration_name: "OY-KBD", ship_number: "", type_subtype: "340-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57173" },
{ operator_icao_code: "SAS", registration_name: "LN-RKU", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "2492" },
{ operator_icao_code: "SCO", registration_name: "9V-OJA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6017" },
{ operator_icao_code: "SCO", registration_name: "9V-OJB", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6018" },
{ operator_icao_code: "SCO", registration_name: "9V-OJC", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6019" },
{ operator_icao_code: "SCO", registration_name: "9V-OJD", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6020" },
{ operator_icao_code: "SCO", registration_name: "9V-OJE", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6021" },
{ operator_icao_code: "SCO", registration_name: "9V-OJF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6022" },
{ operator_icao_code: "SCO", registration_name: "9V-OJG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6023" },
{ operator_icao_code: "SCO", registration_name: "9V-OJH", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6024" },
{ operator_icao_code: "SCO", registration_name: "9V-OJI", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6025" },
{ operator_icao_code: "SCO", registration_name: "9V-OJJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6026" },
{ operator_icao_code: "SCO", registration_name: "9V-OFA", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6033" },
{ operator_icao_code: "SCO", registration_name: "9V-OFB", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6034" },
{ operator_icao_code: "SCO", registration_name: "9V-OFC", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6035" },
{ operator_icao_code: "SCO", registration_name: "9V-OFD", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6036" },
{ operator_icao_code: "SCO", registration_name: "9V-OFE", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6041" },
{ operator_icao_code: "SCO", registration_name: "9V-OFG", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6042" },
{ operator_icao_code: "SCO", registration_name: "9V-OFH", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "G: G/T", antenna_gen: "GEN1",xid: "6037" },
{ operator_icao_code: "SCO", registration_name: "9V-OFI", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6038" },
{ operator_icao_code: "SCO", registration_name: "9V-OFJ", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6039" },
{ operator_icao_code: "SCO", registration_name: "9V-OFK", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6040" },
{ operator_icao_code: "SCO", registration_name: "9V-OFL", ship_number: "", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "58013" },
{ operator_icao_code: "SDM", registration_name: "VP-BGR", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SDM", registration_name: "VP-BOB", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SDM", registration_name: "VP-BOD", ship_number: "", type_subtype: "737-800", system_assigned: "eX1,eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SDM", registration_name: "VP-BOH", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SDM", registration_name: "VP-BOI", ship_number: "", type_subtype: "737-800", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFB", registration_name: "V8-OAS", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50479" },
{ operator_icao_code: "SFJ", registration_name: "NOTJA02MC", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA06MC", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA07MC", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA08MC", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA09MC", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA20MC", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA21MC", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA23MC", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA22MC", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA05MC", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA24MC", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA25MC", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA26MC", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SFJ", registration_name: "JA27MC", ship_number: "", type_subtype: "320-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SGQ", registration_name: "HZ-CGB", ship_number: "", type_subtype: "320-200", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57341" },
{ operator_icao_code: "SGQ", registration_name: "D-AUBC", ship_number: "", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SMW", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55875" },
{ operator_icao_code: "SIA", registration_name: "9V-SGA", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55853" },
{ operator_icao_code: "SIA", registration_name: "9V-SGD", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55856" },
{ operator_icao_code: "SIA", registration_name: "9V-SGE", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55857" },
{ operator_icao_code: "SIA", registration_name: "9V-SMM", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55894" },
{ operator_icao_code: "SIA", registration_name: "9V-SMP", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55897" },
{ operator_icao_code: "SIA", registration_name: "9V-SMR", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55895" },
{ operator_icao_code: "SIA", registration_name: "9V-SMS", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55890" },
{ operator_icao_code: "SIA", registration_name: "9V-SMU", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55896" },
{ operator_icao_code: "SIA", registration_name: "9V-SMV", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55864" },
{ operator_icao_code: "SIA", registration_name: "9V-SMY", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55876" },
{ operator_icao_code: "SIA", registration_name: "9V-SMZ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55878" },
{ operator_icao_code: "SIA", registration_name: "9V-SQL", ship_number: "", type_subtype: "777-200", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SRM", ship_number: "", type_subtype: "777-200", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SRO", ship_number: "", type_subtype: "777-200", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SRP", ship_number: "", type_subtype: "777-200", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SRQ", ship_number: "", type_subtype: "777-200", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SVC", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SVE", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SVJ", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SVM", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SVN", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SYH", ship_number: "", type_subtype: "777-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SYF", ship_number: "", type_subtype: "777-300", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SMJ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55893" },
{ operator_icao_code: "SIA", registration_name: "9V-SMN", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55881" },
{ operator_icao_code: "SIA", registration_name: "9V-SMO", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55891" },
{ operator_icao_code: "SIA", registration_name: "9V-SMQ", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55887" },
{ operator_icao_code: "SIA", registration_name: "9V-SYI", ship_number: "", type_subtype: "777-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SMA", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55885" },
{ operator_icao_code: "SIA", registration_name: "9V-SMC", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55883" },
{ operator_icao_code: "SIA", registration_name: "9V-SME", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55882" },
{ operator_icao_code: "SIA", registration_name: "9V-SMF", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55888" },
{ operator_icao_code: "SIA", registration_name: "9V-SMH", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55889" },
{ operator_icao_code: "SIA", registration_name: "9V-SMK", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55880" },
{ operator_icao_code: "SIA", registration_name: "9V-SML", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55892" },
{ operator_icao_code: "SIA", registration_name: "9V-SMT", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55898" },
{ operator_icao_code: "SIA", registration_name: "9V-STZ", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SYJ", ship_number: "", type_subtype: "777-300", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SQN", ship_number: "", type_subtype: "777-200", system_assigned: "eXK", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SQM", ship_number: "", type_subtype: "777-200", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SMD", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55900" },
{ operator_icao_code: "SIA", registration_name: "9V-SMB", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55884" },
{ operator_icao_code: "SIA", registration_name: "9V-SYL", ship_number: "", type_subtype: "777-300", system_assigned: "3000", system_reported: "3000", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKF", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWT", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKI", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-STC", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKH", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKG", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKJ", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-STO", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKK", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-STQ", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-STR", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKL", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKN", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKM", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKP", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKQ", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKR", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SVB", ship_number: "", type_subtype: "777-200ER", system_assigned: "eXK", system_reported: "eXK", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKS", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKT", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-STT", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SWU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55866" },
{ operator_icao_code: "SIA", registration_name: "9V-SWV", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55867" },
{ operator_icao_code: "SIA", registration_name: "9V-SWW", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55868" },
{ operator_icao_code: "SIA", registration_name: "9V-SWY", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55869" },
{ operator_icao_code: "SIA", registration_name: "9V-SWZ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55870" },
{ operator_icao_code: "SIA", registration_name: "9V-SNA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55871" },
{ operator_icao_code: "SIA", registration_name: "9V-SNB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55872" },
{ operator_icao_code: "SIA", registration_name: "9V-SNC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55873" },
{ operator_icao_code: "SIA", registration_name: "9V-STU", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-STW", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-STY", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SSA", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SSB", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SSC", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SSD", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SSE", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SSF", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SSG", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SSH", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SSI", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SMG", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55899" },
{ operator_icao_code: "SIA", registration_name: "9V-SMI", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55886" },
{ operator_icao_code: "SIA", registration_name: "9V-SKV", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SCA", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1285" },
{ operator_icao_code: "SIA", registration_name: "9V-SKU", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SCC", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1287" },
{ operator_icao_code: "SIA", registration_name: "9V-SKW", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SCD", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1288" },
{ operator_icao_code: "SIA", registration_name: "9V-SCE", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1289" },
{ operator_icao_code: "SIA", registration_name: "9V-SCF", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1290" },
{ operator_icao_code: "SIA", registration_name: "F-WWAM", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKY", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SKZ", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SCG", ship_number: "565", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1291" },
{ operator_icao_code: "SIA", registration_name: "9V-SCH", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "1292" },
{ operator_icao_code: "SIA", registration_name: "9V-SGB", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55854" },
{ operator_icao_code: "SIA", registration_name: "9V-SGC", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55855" },
{ operator_icao_code: "SIA", registration_name: "9V-SGF", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55858" },
{ operator_icao_code: "SIA", registration_name: "9V-SGG", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55859" },
{ operator_icao_code: "SIA", registration_name: "9V-SCI", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55860" },
{ operator_icao_code: "SIA", registration_name: "9V-SCK", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55862" },
{ operator_icao_code: "SIA", registration_name: "9V-SCL", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55863" },
{ operator_icao_code: "SIA", registration_name: "F-WZFGX", ship_number: "", type_subtype: "350-900", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SIA", registration_name: "9V-SCJ", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55861" },
{ operator_icao_code: "SIA", registration_name: "9V-SCM", ship_number: "", type_subtype: "787-10", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55865" },
{ operator_icao_code: "SIA", registration_name: "9V-SCN", ship_number: "", type_subtype: "787-10", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55874" },
{ operator_icao_code: "SIA", registration_name: "9V-SJA", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55877" },
{ operator_icao_code: "SIA", registration_name: "9V-SCO", ship_number: "", type_subtype: "787-10", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "55879" },
{ operator_icao_code: "SKY", registration_name: "MSN1483", ship_number: "", type_subtype: "330-", system_assigned: "", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "MSN1491", ship_number: "", type_subtype: "330-", system_assigned: "", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "JA330D", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "MSN1554", ship_number: "", type_subtype: "330-", system_assigned: "", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "MSN1574", ship_number: "", type_subtype: "330-", system_assigned: "", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "MSN1607", ship_number: "", type_subtype: "330-", system_assigned: "", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "MSN162", ship_number: "", type_subtype: "380-800", system_assigned: "", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "MSN167", ship_number: "", type_subtype: "380-800", system_assigned: "", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "MSN185", ship_number: "", type_subtype: "380-800", system_assigned: "", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "NOTT2F-WWYF", ship_number: "1554", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "JA330E", ship_number: "", type_subtype: "330-340", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "JA330F", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "JA330G", ship_number: "", type_subtype: "330-", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "JA330K", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "JA330L", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SKY", registration_name: "NOTF-WWCE", ship_number: "", type_subtype: "330-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGA", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGB", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGC", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-SLM", ship_number: "", type_subtype: "320-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGD", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-SLL", ship_number: "", type_subtype: "320-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-SLO", ship_number: "", type_subtype: "320-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-SLQ", ship_number: "", type_subtype: "320-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-SLP", ship_number: "", type_subtype: "320-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-SLR", ship_number: "", type_subtype: "320-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGE", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-SLS", ship_number: "", type_subtype: "320-200", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGF", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-SBH", ship_number: "", type_subtype: "319-100", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGG", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-SBG", ship_number: "", type_subtype: "319-100", system_assigned: "eXW", system_reported: "eXW", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGQ", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGH", ship_number: "YT468", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGM", ship_number: "YT473", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGI", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGJ", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGK", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGN", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGO", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGL", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MGP", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBA", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBB", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBC", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBD", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBE", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBF", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBG", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXO,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBH", ship_number: "", type_subtype: "737-800", system_assigned: "eXO-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBJ", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBL", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBM", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SLK", registration_name: "9V-MBN", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SOR", registration_name: "D2-EVW", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SOR", registration_name: "D2-EWS", ship_number: "", type_subtype: "737-800", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SOR", registration_name: "N322SG", ship_number: "322", type_subtype: "747-481", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SOR", registration_name: "N263SG", ship_number: "263", type_subtype: "747-481", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS51", ship_number: "7383", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS52", ship_number: "7432", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS53", ship_number: "7444", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS54", ship_number: "7482", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS55", ship_number: "7550", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS56", ship_number: "7608", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS57", ship_number: "7666", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS58", ship_number: "7718", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS59", ship_number: "7789", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS60", ship_number: "7817", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS65", ship_number: "8064", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS68", ship_number: "8081", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS63", ship_number: "8009", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS62", ship_number: "8007", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS61", ship_number: "7927", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS72", ship_number: "8161", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS64", ship_number: "8037", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS66", ship_number: "8070", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS73", ship_number: "8177", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS67", ship_number: "8072", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS69", ship_number: "8124", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS74", ship_number: "8189", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS77", ship_number: "8346", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS75", ship_number: "8298", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS76", ship_number: "8315", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS83", ship_number: "8427", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS79", ship_number: "8428", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS78", ship_number: "8394", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS84", ship_number: "8438", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AS82", ship_number: "8442", type_subtype: "320-200", system_assigned: "eXO", system_reported: "eXO", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-ASB", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-ASA", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-ASC", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-ASE", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-ASD", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-ASF", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-ASG", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AR24", ship_number: "", type_subtype: "787-10", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AR26", ship_number: "", type_subtype: "787-10", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AR25", ship_number: "", type_subtype: "787-10", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SVA", registration_name: "HZ-AR27", ship_number: "", type_subtype: "787-10", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "R737TRP", ship_number: "", type_subtype: "737-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8725L", ship_number: "8725", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8721J", ship_number: "8721", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8726H", ship_number: "8726", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8727M", ship_number: "8727", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8722L", ship_number: "8722", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8723Q", ship_number: "8723", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8724J", ship_number: "8724", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8728Q", ship_number: "8728", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8730Q", ship_number: "8730", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8731J", ship_number: "8731", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8732S", ship_number: "8732", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWA", registration_name: "N8729H", ship_number: "8729", type_subtype: "737-MAX8", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JMA", ship_number: "", type_subtype: "340-310", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54142" },
{ operator_icao_code: "SWR", registration_name: "HB-JMB", ship_number: "", type_subtype: "340-310", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54169" },
{ operator_icao_code: "SWR", registration_name: "HB-JMC", ship_number: "546", type_subtype: "340-310", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54134" },
{ operator_icao_code: "SWR", registration_name: "HB-JMH", ship_number: "585", type_subtype: "340-310", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54125" },
{ operator_icao_code: "SWR", registration_name: "HB-JMI", ship_number: "598", type_subtype: "340-310", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54131" },
{ operator_icao_code: "SWR", registration_name: "HB-JHA", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54128" },
{ operator_icao_code: "SWR", registration_name: "HB-JHB", ship_number: "", type_subtype: "330-300", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54129" },
{ operator_icao_code: "SWR", registration_name: "HB-JHC", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54135" },
{ operator_icao_code: "SWR", registration_name: "HB-JHD", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54146" },
{ operator_icao_code: "SWR", registration_name: "HB-JHE", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54126" },
{ operator_icao_code: "SWR", registration_name: "HB-JHF", ship_number: "", type_subtype: "330-300", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54156" },
{ operator_icao_code: "SWR", registration_name: "HB-JHG", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54136" },
{ operator_icao_code: "SWR", registration_name: "HB-JHH", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54139" },
{ operator_icao_code: "SWR", registration_name: "HB-JHI", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54130" },
{ operator_icao_code: "SWR", registration_name: "HB-JHJ", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54145" },
{ operator_icao_code: "SWR", registration_name: "HB-JHK", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54143" },
{ operator_icao_code: "SWR", registration_name: "HB-JHL", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54150" },
{ operator_icao_code: "SWR", registration_name: "HB-JHM", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54127" },
{ operator_icao_code: "SWR", registration_name: "HB-JHN", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54151" },
{ operator_icao_code: "SWR", registration_name: "HB-JNA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "54144" },
{ operator_icao_code: "SWR", registration_name: "HB-JNB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "54170" },
{ operator_icao_code: "SWR", registration_name: "HB-JNC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "54132" },
{ operator_icao_code: "SWR", registration_name: "HB-JND", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "54133" },
{ operator_icao_code: "SWR", registration_name: "HB-JNE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "54137" },
{ operator_icao_code: "SWR", registration_name: "HB-JNF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54149" },
{ operator_icao_code: "SWR", registration_name: "HB-JBA", ship_number: "50010", type_subtype: "220-100", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JBB", ship_number: "50011", type_subtype: "220-100", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JBC", ship_number: "50012", type_subtype: "220-100", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JBD", ship_number: "50013", type_subtype: "220-100", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JBE", ship_number: "50014", type_subtype: "220-100", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JNG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54147" },
{ operator_icao_code: "SWR", registration_name: "HB-JNH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54148" },
{ operator_icao_code: "SWR", registration_name: "HB-JBF", ship_number: "50015", type_subtype: "220-100", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JNI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54141" },
{ operator_icao_code: "SWR", registration_name: "HB-JNJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54155" },
{ operator_icao_code: "SWR", registration_name: "HB-JBG", ship_number: "50016", type_subtype: "220-100", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JBH", ship_number: "50017", type_subtype: "220-100", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCA", ship_number: "55010", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCB", ship_number: "55011", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCC", ship_number: "55012", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCE", ship_number: "55014", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCF", ship_number: "55015", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCD", ship_number: "55013", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCJ", ship_number: "55025", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCL", ship_number: "55029", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCK", ship_number: "55027", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCI", ship_number: "55023", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCH", ship_number: "55021", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCG", ship_number: "55020", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCM", ship_number: "55030", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCN", ship_number: "55032", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCO", ship_number: "55033", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCP", ship_number: "55036", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCQ", ship_number: "55040", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCR", ship_number: "55044", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCS", ship_number: "55045", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JCT", ship_number: "55046", type_subtype: "220-300", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JBI", ship_number: "50018", type_subtype: "220-100", system_assigned: "CMS", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "SWR", registration_name: "HB-JNK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54138" },
{ operator_icao_code: "SWR", registration_name: "HB-JNL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54140" },
{ operator_icao_code: "T5T", registration_name: "ADC01", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "CMS", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50116" },
{ operator_icao_code: "T5T", registration_name: "SWE-002", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50073" },
{ operator_icao_code: "T5T", registration_name: "DTE014", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50337" },
{ operator_icao_code: "T5T", registration_name: "DTE018", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "50341" },
{ operator_icao_code: "T5T", registration_name: "12345", ship_number: "", type_subtype: "300-100", system_assigned: "eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "50296" },
{ operator_icao_code: "TAI", registration_name: "N570TA", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAI", registration_name: "N683TA", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAI", registration_name: "N684TA", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAI", registration_name: "N685TA", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAI", registration_name: "N522TA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAI", registration_name: "N524TA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAI", registration_name: "N703AV", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAI", registration_name: "N692AV", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAI", registration_name: "N690AV", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAI", registration_name: "N693AV", ship_number: "", type_subtype: "321-210", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MHK", ship_number: "HK", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MBO", ship_number: "BO", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MHF", ship_number: "HF", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MHM", ship_number: "HM", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXA", ship_number: "XA", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXB", ship_number: "XB", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MHP", ship_number: "HP", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MHQ", ship_number: "HQ", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXC", ship_number: "XC", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MHR", ship_number: "HR", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MOB", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MUA", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MHU", ship_number: "HU", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MUB", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MUC", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MUD", ship_number: "", type_subtype: "777-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MBU", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MHW", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYA", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYB", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYC", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXD", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MHZ", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MHX", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MBW", ship_number: "BW", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXE", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-TMA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TMB", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TMC", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TMD", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXF", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXG", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TME", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYJ", ship_number: "YJ", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYK", ship_number: "YK", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXH", ship_number: "XH", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXI", ship_number: "XI", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYH", ship_number: "YH", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYI", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYL", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYM", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TMG", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MUE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MUF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MUG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MUH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MSY", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MUI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MSX", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MSW", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MUJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYV", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MSV", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MSS", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MSO", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MSZ", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXN", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXL", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MOC", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MOA", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MOE", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYQ", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYR", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYP", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYT", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYX", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYY", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MYZ", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYA", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYD", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYF", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYG", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYH", ship_number: "", type_subtype: "320-200", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXJ", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXM", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXO", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXP", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MXQ", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPA", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPB", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPC", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPD", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPE", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPF", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPH", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPI", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPG", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPJ", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPL", ship_number: "6895", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTA", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTB", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPM", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-TML", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPN", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTC", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPQ", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-XPO", ship_number: "", type_subtype: "321-230", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTD", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTF", ship_number: "64", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTE", ship_number: "", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTG", ship_number: "79", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-MOD", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-TMO", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTH", ship_number: "200", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTI", ship_number: "250", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "CC-CXC", ship_number: "", type_subtype: "767-300", system_assigned: "eX1,eX2", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-MBV", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PT-TMI", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYI", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYJ", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYK", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYL", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYM", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYN", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYO", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYP", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYQ", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYR", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "CC-CXF", ship_number: "", type_subtype: "767-300", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYS", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYT", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYU", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-TYV", ship_number: "", type_subtype: "320-214", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "CC-CWV", ship_number: "", type_subtype: "767-300", system_assigned: "eX1,eX2", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "CC-CXH", ship_number: "", type_subtype: "767-300", system_assigned: "eX1,eX2", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "CC-CWF", ship_number: "", type_subtype: "767-300", system_assigned: "eX1,eX2", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTM", ship_number: "363", type_subtype: "350-900", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "CC-CXG", ship_number: "", type_subtype: "767-300", system_assigned: "eX1,eX2", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "CC-CXJ", ship_number: "", type_subtype: "767-300", system_assigned: "eX1,eX2", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "CC-CWY", ship_number: "", type_subtype: "767-300", system_assigned: "eX1,eX2", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "CC-CXI", ship_number: "", type_subtype: "767-300", system_assigned: "eX1,eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "CC-CXD", ship_number: "", type_subtype: "767-300", system_assigned: "eX1,eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "CC-CXE", ship_number: "", type_subtype: "767-300", system_assigned: "eX1,eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTJ", ship_number: "265", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTK", ship_number: "282", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAM", registration_name: "PR-XTL", ship_number: "313", type_subtype: "350-900", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOA", ship_number: "", type_subtype: "340-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOB", ship_number: "", type_subtype: "340-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOD", ship_number: "", type_subtype: "340-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOC", ship_number: "", type_subtype: "340-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOE", ship_number: "", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOF", ship_number: "", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOG", ship_number: "", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOH", ship_number: "", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOI", ship_number: "", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOJ", ship_number: "", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOK", ship_number: "", type_subtype: "330-200", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOL", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOM", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TON", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOO", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOP", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TNQ", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TNR", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TNS", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TNT", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TNU", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TNV", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOQ", ship_number: "MSN477", type_subtype: "330-200", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOR", ship_number: "", type_subtype: "330-200", system_assigned: "eXL", system_reported: "eXL", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOU", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOV", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TOW", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TUA", ship_number: "1819", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54685" },
{ operator_icao_code: "TAP", registration_name: "CS-TOX", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "F-WWYT", ship_number: "1897", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TUB", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54688" },
{ operator_icao_code: "TAP", registration_name: "F-WWKE", ship_number: "", type_subtype: "330-900", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "F-WWKJ", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "F-WWYZ", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "F-WWYQ", ship_number: "1918", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "F-WWCQ", ship_number: "", type_subtype: "330-900", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TUC", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54687" },
{ operator_icao_code: "TAP", registration_name: "CS-TUD", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54686" },
{ operator_icao_code: "TAP", registration_name: "F-WWCT", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TUG", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54689" },
{ operator_icao_code: "TAP", registration_name: "CS-TUF", ship_number: "", type_subtype: "330-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54691" },
{ operator_icao_code: "TAP", registration_name: "CS-TXA", ship_number: "", type_subtype: "321-200", system_assigned: "eX1-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54692" },
{ operator_icao_code: "TAP", registration_name: "CS-TXB", ship_number: "", type_subtype: "321-200", system_assigned: "eX1-eXConnect", system_reported: "eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54690" },
{ operator_icao_code: "TAP", registration_name: "CS-TUI", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54694" },
{ operator_icao_code: "TAP", registration_name: "CS-TUE", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54669" },
{ operator_icao_code: "TAP", registration_name: "CS-TUH", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54693" },
{ operator_icao_code: "TAP", registration_name: "CS-TUL", ship_number: "", type_subtype: "330-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54697" },
{ operator_icao_code: "TAP", registration_name: "CS-TUJ", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54695" },
{ operator_icao_code: "TAP", registration_name: "CS-TUK", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54670" },
{ operator_icao_code: "TAP", registration_name: "F-WWCC", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TUM", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54671" },
{ operator_icao_code: "TAP", registration_name: "CS-TUO", ship_number: "", type_subtype: "330-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54672" },
{ operator_icao_code: "TAP", registration_name: "CS-TXC", ship_number: "", type_subtype: "321-200", system_assigned: "eX1-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54702" },
{ operator_icao_code: "TAP", registration_name: "CS-TUN", ship_number: "", type_subtype: "330-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54696" },
{ operator_icao_code: "TAP", registration_name: "CS-TXD", ship_number: "", type_subtype: "321-200", system_assigned: "eX3-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54703" },
{ operator_icao_code: "TAP", registration_name: "CS-TUR", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54673" },
{ operator_icao_code: "TAP", registration_name: "CS-TUS", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54699" },
{ operator_icao_code: "TAP", registration_name: "CS-TXG", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TXH", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAP", registration_name: "CS-TUP", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54698" },
{ operator_icao_code: "TAP", registration_name: "CS-TUQ", ship_number: "", type_subtype: "330-900", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54674" },
{ operator_icao_code: "TAP", registration_name: "D-AVZM", ship_number: "", type_subtype: "321-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAR", registration_name: "TS-IFM", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TAR", registration_name: "TS-IFN", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TBA", registration_name: "B-8420", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TBA", registration_name: "B-8950", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TBA", registration_name: "B-8951", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TBA", registration_name: "B-1047", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TBA", registration_name: "B-1046", ship_number: "", type_subtype: "330-200", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-244", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-245", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-246", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "SHELF-2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "F-WWSA", ship_number: "", type_subtype: "380-800", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "SHELF-3", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-233", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "UPLOADER-1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "F-WWSC", ship_number: "", type_subtype: "380-800", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-231", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "F-WWSE", ship_number: "", type_subtype: "380-800", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "HAMBURG", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-239", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-240", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DATA3-383F3A", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-232", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-124", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-092", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-115", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-164", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-183", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "25", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "29", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-234", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DATA3-8851C6", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CELL-242", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DATA3-0A9420", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DATA3-750030", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DATA3-06A036", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DATA3-E4818C", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DATA3-06A066", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-3K1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-3K2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-3K3", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-3K4", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-3K5", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-3K6", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X27", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X28", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X29", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X3", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X36", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X37", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X38", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X39", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X4", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X40", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X41", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X5", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X6", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X7", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X8", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X9", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ALPHA1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ALPHA2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ALST1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ALST2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ALST787", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ALST7871", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "BELL", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "BELL1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "BELL2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "BRAVO1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "BRAVO2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CATHAYLAB1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CATHAYLAB2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CATHAYLAB3", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CHARLIE1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CHARLIE2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CONTINENTAL1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CONTINENTAL1B", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CONTINENTAL2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CONTINENTAL2B", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DELTA2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ECHO1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ECHO2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "EDISON", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "EDISON2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "EMIRATESLAB1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "EMIRATESLAB2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "EMIRATESLAB3", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "EMIRATESLAB4", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ETIHAD", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ETIHAD2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "EX2LAB", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "FOXTROT1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "FOXTROT2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "GOLF1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "GOLF1B", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "GOLF2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "GOLF2B", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "HOTEL1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "HOTEL1B", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "HOTEL2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "HOTEL2B", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "INDIA1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "INDIA1B", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "INDIA2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "INDIA2B", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "KILO1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "KILO2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "KIRBY1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "KIRBY2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "LIMA1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "LIMA2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "MARCONI", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "MARCONI2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "MAXWELL", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "MAXWELL2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "MIKE1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "MIKE2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "NOVEMBER1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "NOVEMBER2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "NOVEMBER3", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "OSCAR1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "OSCAR2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "PAPA", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "PSELAB", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "QANTASLAB1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "QANTASLAB2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "QUEBEC", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "QUEBEC1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "QUEBEC2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ROMEO", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ROMEO2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "SHANNON", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "SHANNONB", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "SIERRA", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "SIERRA1B", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "SIERRA2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "TANGO1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "TANGO2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "TANGO3", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "TESLA", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "TESLA2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "UNIFORM1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "UNIFORM2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "VAUS01", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "VICTOR1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "VICTOR2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "WHISKEY1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "WHISKEY2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "2000E", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "XRAY", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "YANKEE2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "YANKEE3", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "YANKEE4", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ZULU1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ZULU2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ZULU3", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "LUFTHANSA3", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DTE-X11", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "CARSONCITY", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "ATLANTA2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "AUSTIN1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "LITTLEROCK", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "SAINTPAUL3", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "HELENA1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "LN-PAC", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "LN-GX4", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "SPRINGFIELD2", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "DENVER1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "9M-VIC", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "SAINTPAUL2", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "AG-DCA", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "Other", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "SACRAMENTO1", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "TALLAHASSEE4", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "MSN1431", ship_number: "", type_subtype: "330-", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "D-B747", ship_number: "", type_subtype: "300-100", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TEST", registration_name: "NOTLUFT1", ship_number: "", type_subtype: "380-800", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TFL", registration_name: "PH-OYI", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TFL", registration_name: "OO-JNL", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TFL", registration_name: "PH-TFD", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TFL", registration_name: "D-ATYE", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TFL", registration_name: "D-ATYF", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TGW", registration_name: "AT-TGW2", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TGW", registration_name: "AT-TGW4", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "55618" },
{ operator_icao_code: "THA", registration_name: "HS-TGT", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TGW", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TGX", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJA", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TGO", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TGP", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TGR", ship_number: "", type_subtype: "747-400", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJB", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJC", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJD", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJE", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJF", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJG", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJH", ship_number: "", type_subtype: "777-200", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJR", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJS", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJT", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJU", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJV", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TJW", ship_number: "", type_subtype: "777-200ER", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-THE", ship_number: "", type_subtype: "350-900", system_assigned: "3000i,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56048" },
{ operator_icao_code: "THA", registration_name: "HS-TKA", ship_number: "", type_subtype: "777-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKB", ship_number: "", type_subtype: "777-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKC", ship_number: "", type_subtype: "777-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKD", ship_number: "", type_subtype: "777-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKE", ship_number: "", type_subtype: "777-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKF", ship_number: "", type_subtype: "777-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TEN", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TEO", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TEP", ship_number: "", type_subtype: "330-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TBA", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TBB", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TBC", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TBD", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TUB", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TUC", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TUA", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TBE", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TEQ", ship_number: "", type_subtype: "330-340", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TER", ship_number: "", type_subtype: "330-340", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TES", ship_number: "", type_subtype: "330-340", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TET", ship_number: "", type_subtype: "330-340", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TEU", ship_number: "", type_subtype: "330-340", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TXA", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TXB", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TXC", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TXD", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TXE", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TXF", ship_number: "", type_subtype: "320-230", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TBF", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TUD", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TBG", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TUE", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TUF", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKV", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKW", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TQA", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6479" },
{ operator_icao_code: "THA", registration_name: "HS-TKX", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TKY", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-TQF", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6484" },
{ operator_icao_code: "THA", registration_name: "HS-TKZ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THA", registration_name: "HS-THB", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56045" },
{ operator_icao_code: "THA", registration_name: "HS-THC", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56046" },
{ operator_icao_code: "THA", registration_name: "HS-TQB", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6480" },
{ operator_icao_code: "THA", registration_name: "HS-TQC", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6481" },
{ operator_icao_code: "THA", registration_name: "HS-TQD", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6482" },
{ operator_icao_code: "THA", registration_name: "HS-TQE", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56057" },
{ operator_icao_code: "THA", registration_name: "HS-THD", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "G: G/T", antenna_gen: "GEN3",xid: "56047" },
{ operator_icao_code: "THA", registration_name: "HS-TWA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56058" },
{ operator_icao_code: "THA", registration_name: "HS-THF", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56049" },
{ operator_icao_code: "THA", registration_name: "HS-THG", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56050" },
{ operator_icao_code: "THA", registration_name: "HS-TWB", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "6478" },
{ operator_icao_code: "THA", registration_name: "HS-THH", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56051" },
{ operator_icao_code: "THA", registration_name: "HS-THJ", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56052" },
{ operator_icao_code: "THA", registration_name: "HS-THK", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56053" },
{ operator_icao_code: "THA", registration_name: "HS-THM", ship_number: "", type_subtype: "350-900", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56055" },
{ operator_icao_code: "THA", registration_name: "HS-THL", ship_number: "", type_subtype: "350-900", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56054" },
{ operator_icao_code: "THA", registration_name: "HS-THN", ship_number: "", type_subtype: "350-900", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56056" },
{ operator_icao_code: "THT", registration_name: "F-OMUA", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "52015" },
{ operator_icao_code: "THT", registration_name: "F-ONUI", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "52013" },
{ operator_icao_code: "THT", registration_name: "F-OVAA", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "52016" },
{ operator_icao_code: "THT", registration_name: "F-OTOA", ship_number: "", type_subtype: "787-9", system_assigned: "eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "52014" },
{ operator_icao_code: "THY", registration_name: "TC-JII", ship_number: "", type_subtype: "340-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JDM", ship_number: "", type_subtype: "340-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JIH", ship_number: "", type_subtype: "340-310", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JDN", ship_number: "", type_subtype: "340-300", system_assigned: "3000i", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JNA", ship_number: "", type_subtype: "330-200", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53885" },
{ operator_icao_code: "THY", registration_name: "TC-JNB", ship_number: "", type_subtype: "330-200", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53886" },
{ operator_icao_code: "THY", registration_name: "TC-JNC", ship_number: "", type_subtype: "330-200", system_assigned: "eXL", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53887" },
{ operator_icao_code: "THY", registration_name: "TC-JND", ship_number: "", type_subtype: "330-200", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53888" },
{ operator_icao_code: "THY", registration_name: "TC-JNE", ship_number: "", type_subtype: "330-200", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53889" },
{ operator_icao_code: "THY", registration_name: "TC-JJE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53956" },
{ operator_icao_code: "THY", registration_name: "TC-JJF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53986" },
{ operator_icao_code: "THY", registration_name: "TC-JJG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53965" },
{ operator_icao_code: "THY", registration_name: "TC-JJH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E1: EMS (Prototype)", antenna_gen: "GEN3",xid: "53962" },
{ operator_icao_code: "THY", registration_name: "TC-JNH", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53890" },
{ operator_icao_code: "THY", registration_name: "TC-JNI", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53891" },
{ operator_icao_code: "THY", registration_name: "TC-JJI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54012" },
{ operator_icao_code: "THY", registration_name: "TC-JNJ", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53892" },
{ operator_icao_code: "THY", registration_name: "TC-JNK", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53893" },
{ operator_icao_code: "THY", registration_name: "TC-JNN", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53896" },
{ operator_icao_code: "THY", registration_name: "TC-JJJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54013" },
{ operator_icao_code: "THY", registration_name: "TC-JJK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54014" },
{ operator_icao_code: "THY", registration_name: "TC-JJL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53960" },
{ operator_icao_code: "THY", registration_name: "TC-JRP", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JRO", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JJM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53943" },
{ operator_icao_code: "THY", registration_name: "TC-JRM", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JLS", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JNL", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53894" },
{ operator_icao_code: "THY", registration_name: "TC-JNM", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53895" },
{ operator_icao_code: "THY", registration_name: "TC-JRN", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JRR", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JLT", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JRT", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JJN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53948" },
{ operator_icao_code: "THY", registration_name: "TC-JJO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53958" },
{ operator_icao_code: "THY", registration_name: "TC-JLU", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JLV", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JLY", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JLZ", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JRU", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYA", ship_number: "N973TK", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JJP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53968" },
{ operator_icao_code: "THY", registration_name: "TC-JRS", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JHK", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYB", ship_number: "N974TK", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JHL", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JNO", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53897" },
{ operator_icao_code: "THY", registration_name: "TC-JYC", ship_number: "N977TK", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYD", ship_number: "N978TK", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JRZ", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYF", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSA", ship_number: "TC-JSA", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JNP", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53933" },
{ operator_icao_code: "THY", registration_name: "TC-JNR", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53934" },
{ operator_icao_code: "THY", registration_name: "TC-JHN", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYG", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JHM", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYH", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYE", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JRV", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYI", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JAI", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JBI", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JLR", ship_number: "", type_subtype: "319-100", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSB", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSC", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JRY", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSD", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYJ", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JHO", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JHP", ship_number: "TC-JHP", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSE", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSF", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSG", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JHR", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JHT", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JHS", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JHU", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSH", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSI", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSJ", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSK", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSL", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JSM", ship_number: "", type_subtype: "321-230", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JNT", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53918" },
{ operator_icao_code: "THY", registration_name: "TC-JNZ", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53936" },
{ operator_icao_code: "THY", registration_name: "TC-JNS", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53935" },
{ operator_icao_code: "THY", registration_name: "TC-JHV", ship_number: "YR961", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JOA", ship_number: "", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53950" },
{ operator_icao_code: "THY", registration_name: "TC-JHY", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JHZ", ship_number: "YR963", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JVA", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JVB", ship_number: "YR956", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JVC", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JJR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53966" },
{ operator_icao_code: "THY", registration_name: "TC-JJS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53967" },
{ operator_icao_code: "THY", registration_name: "TC-JJT", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53964" },
{ operator_icao_code: "THY", registration_name: "TC-JOB", ship_number: "1514", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53937" },
{ operator_icao_code: "THY", registration_name: "TC-JJU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53963" },
{ operator_icao_code: "THY", registration_name: "TC-TUR", ship_number: "", type_subtype: "330-240", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56335" },
{ operator_icao_code: "THY", registration_name: "TC-JVF", ship_number: "YR69", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JVD", ship_number: "YR967", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JVG", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JVE", ship_number: "", type_subtype: "737-800", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JOD", ship_number: "1529", type_subtype: "330-300", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53929" },
{ operator_icao_code: "THY", registration_name: "TC-JIL", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JIM", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JIN", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JJY", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53907" },
{ operator_icao_code: "THY", registration_name: "TC-JJZ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53884" },
{ operator_icao_code: "THY", registration_name: "TC-JJV", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53900" },
{ operator_icao_code: "THY", registration_name: "TC-JOE", ship_number: "", type_subtype: "330-300", system_assigned: "eX2-eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53931" },
{ operator_icao_code: "THY", registration_name: "TC-JOF", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53914" },
{ operator_icao_code: "THY", registration_name: "TC-JOG", ship_number: "1620", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53872" },
{ operator_icao_code: "THY", registration_name: "TC-JOH", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53917" },
{ operator_icao_code: "THY", registration_name: "TC-JOI", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53923" },
{ operator_icao_code: "THY", registration_name: "TC-JOK", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53879" },
{ operator_icao_code: "THY", registration_name: "TC-JOL", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53921" },
{ operator_icao_code: "THY", registration_name: "TC-JOJ", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53922" },
{ operator_icao_code: "THY", registration_name: "TC-JYL", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LJD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53903" },
{ operator_icao_code: "THY", registration_name: "TC-LJC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53909" },
{ operator_icao_code: "THY", registration_name: "TC-LJA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53908" },
{ operator_icao_code: "THY", registration_name: "TC-LJB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53906" },
{ operator_icao_code: "THY", registration_name: "TC-JYM", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYN", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYP", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JYO", ship_number: "", type_subtype: "737-900", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LNC", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53904" },
{ operator_icao_code: "THY", registration_name: "TC-LJE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53915" },
{ operator_icao_code: "THY", registration_name: "TC-LND", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53877" },
{ operator_icao_code: "THY", registration_name: "TC-LNF", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53899" },
{ operator_icao_code: "THY", registration_name: "FWWKR", ship_number: "1704", type_subtype: "330-300", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LKA", ship_number: "", type_subtype: "777-300", system_assigned: "eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "2361" },
{ operator_icao_code: "THY", registration_name: "TC-LKB", ship_number: "", type_subtype: "777-300", system_assigned: "eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "2370" },
{ operator_icao_code: "THY", registration_name: "TC-LKC", ship_number: "", type_subtype: "777-300", system_assigned: "eXConnect", system_reported: "eX2L-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "2371" },
{ operator_icao_code: "THY", registration_name: "TC-LJF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53911" },
{ operator_icao_code: "THY", registration_name: "TC-LJG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53919" },
{ operator_icao_code: "THY", registration_name: "TC-LNE", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53882" },
{ operator_icao_code: "THY", registration_name: "2340", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LJH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53912" },
{ operator_icao_code: "THY", registration_name: "TC-LJI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53913" },
{ operator_icao_code: "THY", registration_name: "TC-LJJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53916" },
{ operator_icao_code: "THY", registration_name: "TC-LOB", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53875" },
{ operator_icao_code: "THY", registration_name: "TC-LOF", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53881" },
{ operator_icao_code: "THY", registration_name: "TC-LOC", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53876" },
{ operator_icao_code: "THY", registration_name: "TC-LOG", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53883" },
{ operator_icao_code: "THY", registration_name: "TC-LOD", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53878" },
{ operator_icao_code: "THY", registration_name: "TC-LOA", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53874" },
{ operator_icao_code: "THY", registration_name: "TC-LOE", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53880" },
{ operator_icao_code: "THY", registration_name: "TC-LNG", ship_number: "", type_subtype: "330-300", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53898" },
{ operator_icao_code: "THY", registration_name: "TC-LJK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53910" },
{ operator_icao_code: "THY", registration_name: "TC-CAN", ship_number: "", type_subtype: "340-500", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56333" },
{ operator_icao_code: "THY", registration_name: "TC-IST", ship_number: "", type_subtype: "319-100", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56334" },
{ operator_icao_code: "THY", registration_name: "TC-JUG", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JUI", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JML", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JUE", ship_number: "", type_subtype: "320-200", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LSA", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53954" },
{ operator_icao_code: "THY", registration_name: "TC-LSB", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53952" },
{ operator_icao_code: "THY", registration_name: "TC-LCD", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53870" },
{ operator_icao_code: "THY", registration_name: "TC-LCF", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53871" },
{ operator_icao_code: "THY", registration_name: "TC-LCA", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53869" },
{ operator_icao_code: "THY", registration_name: "DTE19", ship_number: "", type_subtype: "RACK-TEST", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56354" },
{ operator_icao_code: "THY", registration_name: "TC-LCB", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53901" },
{ operator_icao_code: "THY", registration_name: "TC-TRK", ship_number: "", type_subtype: "747-800", system_assigned: "eX2-eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56336" },
{ operator_icao_code: "THY", registration_name: "TC-JIO", ship_number: "", type_subtype: "330-220", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JIP", ship_number: "", type_subtype: "330-220", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JIR", ship_number: "", type_subtype: "330-220", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JIS", ship_number: "", type_subtype: "330-220", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LOI", ship_number: "", type_subtype: "330-220", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LOH", ship_number: "", type_subtype: "330-220", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JIZ", ship_number: "", type_subtype: "330-220", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-JIT", ship_number: "", type_subtype: "330-220", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LCC", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53873" },
{ operator_icao_code: "THY", registration_name: "TC-LSC", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53955" },
{ operator_icao_code: "THY", registration_name: "TC-LCE", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53902" },
{ operator_icao_code: "THY", registration_name: "TC-LCG", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53905" },
{ operator_icao_code: "THY", registration_name: "TC-LSD", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53924" },
{ operator_icao_code: "THY", registration_name: "D-AVYH", ship_number: "", type_subtype: "300-200", system_assigned: "eX1,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LCJ", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53920" },
{ operator_icao_code: "THY", registration_name: "TC-LCK", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53925" },
{ operator_icao_code: "THY", registration_name: "TC-LCM", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53926" },
{ operator_icao_code: "THY", registration_name: "TC-LCL", ship_number: "", type_subtype: "737-MAX8", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53927" },
{ operator_icao_code: "THY", registration_name: "TC-LYA", ship_number: "", type_subtype: "737-MAX9", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53928" },
{ operator_icao_code: "THY", registration_name: "TC-LSG", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53938" },
{ operator_icao_code: "THY", registration_name: "TC-LSE", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53932" },
{ operator_icao_code: "THY", registration_name: "TC-LLB", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53941" },
{ operator_icao_code: "THY", registration_name: "TC-LYC", ship_number: "", type_subtype: "737-MAX9", system_assigned: "eX1-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LLA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53939" },
{ operator_icao_code: "THY", registration_name: "TC-LSF", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53940" },
{ operator_icao_code: "THY", registration_name: "TC-LLC", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53942" },
{ operator_icao_code: "THY", registration_name: "TC-LLD", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53944" },
{ operator_icao_code: "THY", registration_name: "D-AVYA", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LSH", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53945" },
{ operator_icao_code: "THY", registration_name: "TC-LLF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53946" },
{ operator_icao_code: "THY", registration_name: "TC-LLE", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53947" },
{ operator_icao_code: "THY", registration_name: "TC-LSL", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect", system_reported: "eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53982" },
{ operator_icao_code: "THY", registration_name: "TC-LSK", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect,eXO", system_reported: "eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53949" },
{ operator_icao_code: "THY", registration_name: "TC-LLG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53981" },
{ operator_icao_code: "THY", registration_name: "TC-LSJ", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect,eXO", system_reported: "eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53951" },
{ operator_icao_code: "THY", registration_name: "D-AVYU", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LSM", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect", system_reported: "eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53953" },
{ operator_icao_code: "THY", registration_name: "TC-LSP", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1,eXConnect", system_reported: "eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53957" },
{ operator_icao_code: "THY", registration_name: "TC-LSO", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1,eXConnect", system_reported: "eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53959" },
{ operator_icao_code: "THY", registration_name: "TC-LSN", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1-eXConnect", system_reported: "eXConnect,eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53961" },
{ operator_icao_code: "THY", registration_name: "TC-LLH", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53983" },
{ operator_icao_code: "THY", registration_name: "TC-LLI", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53984" },
{ operator_icao_code: "THY", registration_name: "TC-LLM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "D-AVZI", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXW-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "THY", registration_name: "TC-LLJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53987" },
{ operator_icao_code: "THY", registration_name: "TC-LLK", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53969" },
{ operator_icao_code: "THY", registration_name: "TC-LSR", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1,eXConnect", system_reported: "eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53971" },
{ operator_icao_code: "THY", registration_name: "TC-LSU", ship_number: "", type_subtype: "321-NEO", system_assigned: "eX1", system_reported: "eXO", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53970" },
{ operator_icao_code: "THY", registration_name: "TC-LSS", ship_number: "", type_subtype: "321-NEO", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TNA", registration_name: "NOTF-WWYH", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TNA", registration_name: "B-22101", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TNA", registration_name: "B-22102", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TNA", registration_name: "B-22103", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TNA", registration_name: "B-22105", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-FDZT", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-FDZU", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-FDZW", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-FDZX", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-FDZY", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-FDZZ", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWA", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWB", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWC", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWD", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWF", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWG", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWH", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWI", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWJ", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWK", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWL", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWM", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWN", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWO", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWP", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWR", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIA", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIB", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIC", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUID", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TAWU", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIF", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIE", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "OO-LOE", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-OBYH", ship_number: "", type_subtype: "767-300", system_assigned: "eFX", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIH", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUII", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "PH-TFM", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "PH-TFL", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "PH-TFK", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "OO-JDL", ship_number: "", type_subtype: "787-8", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIJ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIK", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIL", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIN", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TOM", registration_name: "G-TUIO", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TSC", registration_name: "C-GTSR", ship_number: "201", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TSC", registration_name: "C-GTSZ", ship_number: "202", type_subtype: "330-200", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "TZP", registration_name: "JA822J", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "52206" },
{ operator_icao_code: "TZP", registration_name: "AZ-TZP7", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "52227" },
{ operator_icao_code: "TZP", registration_name: "JA825J", ship_number: "", type_subtype: "787-8", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "52205" },
{ operator_icao_code: "UAE", registration_name: "A6-EDN", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EBB", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56780" },
{ operator_icao_code: "UAE", registration_name: "A6-EBC", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "3000,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56705" },
{ operator_icao_code: "UAE", registration_name: "A6-EBE", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "3000,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56706" },
{ operator_icao_code: "UAE", registration_name: "A6-EBG", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "3000,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56726" },
{ operator_icao_code: "UAE", registration_name: "A6-EBI", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56727" },
{ operator_icao_code: "UAE", registration_name: "A6-EBJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56728" },
{ operator_icao_code: "UAE", registration_name: "A6-EBK", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56729" },
{ operator_icao_code: "UAE", registration_name: "A6-EBM", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56730" },
{ operator_icao_code: "UAE", registration_name: "A6-EBN", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "3000,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56731" },
{ operator_icao_code: "UAE", registration_name: "A6-EBO", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "3000,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56732" },
{ operator_icao_code: "UAE", registration_name: "A6-EBQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56733" },
{ operator_icao_code: "UAE", registration_name: "A6-EBR", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "3000,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56734" },
{ operator_icao_code: "UAE", registration_name: "A6-EBU", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56735" },
{ operator_icao_code: "UAE", registration_name: "A6-EBW", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "3000,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56736" },
{ operator_icao_code: "UAE", registration_name: "A6-EBY", ship_number: "", type_subtype: "777-300ER", system_assigned: "3000i,eXConnect", system_reported: "3000,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56738" },
{ operator_icao_code: "UAE", registration_name: "A6-ECA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56809" },
{ operator_icao_code: "UAE", registration_name: "A6-ECC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56810" },
{ operator_icao_code: "UAE", registration_name: "A6-ECD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56811" },
{ operator_icao_code: "UAE", registration_name: "A6-EDA", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDB", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDC", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDL", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDE", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDF", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDG", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDH", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDI", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDJ", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDK", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EWA", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4394" },
{ operator_icao_code: "UAE", registration_name: "A6-EWB", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4395" },
{ operator_icao_code: "UAE", registration_name: "A6-EWC", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4386" },
{ operator_icao_code: "UAE", registration_name: "A6-EWD", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4387" },
{ operator_icao_code: "UAE", registration_name: "A6-ECE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56812" },
{ operator_icao_code: "UAE", registration_name: "A6-ECF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56813" },
{ operator_icao_code: "UAE", registration_name: "A6-ECG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56814" },
{ operator_icao_code: "UAE", registration_name: "A6-ECH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56815" },
{ operator_icao_code: "UAE", registration_name: "A6-EWE", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4388" },
{ operator_icao_code: "UAE", registration_name: "A6-ECI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56816" },
{ operator_icao_code: "UAE", registration_name: "A6-ECJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56817" },
{ operator_icao_code: "UAE", registration_name: "A6-ECK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56818" },
{ operator_icao_code: "UAE", registration_name: "A6-EWF", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4389" },
{ operator_icao_code: "UAE", registration_name: "A6-EWG", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2-eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4390" },
{ operator_icao_code: "UAE", registration_name: "A6-EWH", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4391" },
{ operator_icao_code: "UAE", registration_name: "A6-ECL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56819" },
{ operator_icao_code: "UAE", registration_name: "A6-ECM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56820" },
{ operator_icao_code: "UAE", registration_name: "A6-EWI", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4392" },
{ operator_icao_code: "UAE", registration_name: "A6-ECN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56821" },
{ operator_icao_code: "UAE", registration_name: "A6-ECO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56822" },
{ operator_icao_code: "UAE", registration_name: "A6-ECP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56823" },
{ operator_icao_code: "UAE", registration_name: "A6-EWJ", ship_number: "", type_subtype: "777-200LR", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4372" },
{ operator_icao_code: "UAE", registration_name: "A6-ECQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56824" },
{ operator_icao_code: "UAE", registration_name: "A6-ECR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56825" },
{ operator_icao_code: "UAE", registration_name: "A6-ECS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56826" },
{ operator_icao_code: "UAE", registration_name: "A6-ECT", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56827" },
{ operator_icao_code: "UAE", registration_name: "A6-ECU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56828" },
{ operator_icao_code: "UAE", registration_name: "A6-ECV", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56829" },
{ operator_icao_code: "UAE", registration_name: "A6-ECX", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56831" },
{ operator_icao_code: "UAE", registration_name: "A6-ECW", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56830" },
{ operator_icao_code: "UAE", registration_name: "A6-ECY", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56832" },
{ operator_icao_code: "UAE", registration_name: "A6-ECZ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56833" },
{ operator_icao_code: "UAE", registration_name: "A6-EGA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56785" },
{ operator_icao_code: "UAE", registration_name: "A6-EDD", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDM", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDO", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EGB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56786" },
{ operator_icao_code: "UAE", registration_name: "A6-EGC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56787" },
{ operator_icao_code: "UAE", registration_name: "A6-EGD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56788" },
{ operator_icao_code: "UAE", registration_name: "A6-EGE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56789" },
{ operator_icao_code: "UAE", registration_name: "A6-EGF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56790" },
{ operator_icao_code: "UAE", registration_name: "A6-EGG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56791" },
{ operator_icao_code: "UAE", registration_name: "A6-EGH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56792" },
{ operator_icao_code: "UAE", registration_name: "A6-EDP", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EGI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56793" },
{ operator_icao_code: "UAE", registration_name: "A6-EDQ", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDR", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDT", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EGJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56794" },
{ operator_icao_code: "UAE", registration_name: "A6-EGK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56795" },
{ operator_icao_code: "UAE", registration_name: "A6-EGL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56796" },
{ operator_icao_code: "UAE", registration_name: "A6-EDS", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EGM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56797" },
{ operator_icao_code: "UAE", registration_name: "A6-EGN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56799" },
{ operator_icao_code: "UAE", registration_name: "A6-EDU", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EGO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56808" },
{ operator_icao_code: "UAE", registration_name: "A6-EGP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56741" },
{ operator_icao_code: "UAE", registration_name: "A6-EGQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56742" },
{ operator_icao_code: "UAE", registration_name: "A6-EGR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56743" },
{ operator_icao_code: "UAE", registration_name: "A6-EGS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56744" },
{ operator_icao_code: "UAE", registration_name: "A6-EGT", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56745" },
{ operator_icao_code: "UAE", registration_name: "A6-EGU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56746" },
{ operator_icao_code: "UAE", registration_name: "A6-EGV", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56747" },
{ operator_icao_code: "UAE", registration_name: "A6-EGW", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56748" },
{ operator_icao_code: "UAE", registration_name: "A6-EDV", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EGX", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56749" },
{ operator_icao_code: "UAE", registration_name: "A6-EGY", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56750" },
{ operator_icao_code: "UAE", registration_name: "A6-EGZ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56751" },
{ operator_icao_code: "UAE", registration_name: "A6-EDW", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-CJE", ship_number: "", type_subtype: "319-110", system_assigned: "eXConnect", system_reported: "eX1-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4321" },
{ operator_icao_code: "UAE", registration_name: "A6-EDY", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EDZ", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-ENB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56753" },
{ operator_icao_code: "UAE", registration_name: "A6-EDX", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-ENA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56752" },
{ operator_icao_code: "UAE", registration_name: "A6-ENC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56754" },
{ operator_icao_code: "UAE", registration_name: "A6-EEA", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEB", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEC", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EED", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEE", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEF", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEG", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEH", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEI", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEJ", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEK", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEL", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56978" },
{ operator_icao_code: "UAE", registration_name: "A6-EEM", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56944" },
{ operator_icao_code: "UAE", registration_name: "A6-EEN", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEO", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEP", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56973" },
{ operator_icao_code: "UAE", registration_name: "A6-EEQ", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56974" },
{ operator_icao_code: "UAE", registration_name: "A6-EER", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56959" },
{ operator_icao_code: "UAE", registration_name: "A6-EES", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56975" },
{ operator_icao_code: "UAE", registration_name: "A6-EET", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEU", ship_number: "", type_subtype: "380-800", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57004" },
{ operator_icao_code: "UAE", registration_name: "A6-EEV", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEW", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEX", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEY", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EEZ", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-END", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4429" },
{ operator_icao_code: "UAE", registration_name: "A6-ENE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56756" },
{ operator_icao_code: "UAE", registration_name: "A6-ENF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56757" },
{ operator_icao_code: "UAE", registration_name: "A6-ENG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56758" },
{ operator_icao_code: "UAE", registration_name: "A6-ENH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56759" },
{ operator_icao_code: "UAE", registration_name: "A6-ENI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56760" },
{ operator_icao_code: "UAE", registration_name: "A6-ENJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56761" },
{ operator_icao_code: "UAE", registration_name: "A6-ENK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56762" },
{ operator_icao_code: "UAE", registration_name: "A6-ENL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56763" },
{ operator_icao_code: "UAE", registration_name: "A6-ENN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56765" },
{ operator_icao_code: "UAE", registration_name: "A6-ENM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56764" },
{ operator_icao_code: "UAE", registration_name: "A6-ENO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56766" },
{ operator_icao_code: "UAE", registration_name: "A6-ENP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56767" },
{ operator_icao_code: "UAE", registration_name: "A6-ENQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56768" },
{ operator_icao_code: "UAE", registration_name: "A6-ENR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56769" },
{ operator_icao_code: "UAE", registration_name: "A6-ENS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56770" },
{ operator_icao_code: "UAE", registration_name: "A6-ENT", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56774" },
{ operator_icao_code: "UAE", registration_name: "A6-ENU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56771" },
{ operator_icao_code: "UAE", registration_name: "A6-ENV", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56773" },
{ operator_icao_code: "UAE", registration_name: "A6-ENW", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56775" },
{ operator_icao_code: "UAE", registration_name: "A6-EOA", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOB", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOC", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOD", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOE", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-ENX", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN1",xid: "4508" },
{ operator_icao_code: "UAE", registration_name: "A6-EOF", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOG", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-ENY", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2-eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56777" },
{ operator_icao_code: "UAE", registration_name: "A6-ENZ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2,eXConnect", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56778" },
{ operator_icao_code: "UAE", registration_name: "A6-EOH", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOI", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOJ", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EPA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56724" },
{ operator_icao_code: "UAE", registration_name: "A6-EOK", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOL", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EPB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56657" },
{ operator_icao_code: "UAE", registration_name: "A6-EOM", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EON", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOO", ship_number: "", type_subtype: "380-800", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EPC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56723" },
{ operator_icao_code: "UAE", registration_name: "A6-EPD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56722" },
{ operator_icao_code: "UAE", registration_name: "A6-EOP", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOQ", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EPF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56720" },
{ operator_icao_code: "UAE", registration_name: "A6-EPE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56707" },
{ operator_icao_code: "UAE", registration_name: "A6-EOR", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOS", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EPG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56719" },
{ operator_icao_code: "UAE", registration_name: "A6-EPH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56718" },
{ operator_icao_code: "UAE", registration_name: "A6-EOT", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EPI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56717" },
{ operator_icao_code: "UAE", registration_name: "A6-EOU", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EPJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56716" },
{ operator_icao_code: "UAE", registration_name: "A6-EOV", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EPK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56715" },
{ operator_icao_code: "UAE", registration_name: "A6-EOW", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EPL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56701" },
{ operator_icao_code: "UAE", registration_name: "A6-EOX", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EOY", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EUB", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56920" },
{ operator_icao_code: "UAE", registration_name: "A6-EUC", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56921" },
{ operator_icao_code: "UAE", registration_name: "A6-EUD", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56922" },
{ operator_icao_code: "UAE", registration_name: "A6-EOZ", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EPM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56714" },
{ operator_icao_code: "UAE", registration_name: "A6-EUF", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56924" },
{ operator_icao_code: "UAE", registration_name: "A6-EUG", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56925" },
{ operator_icao_code: "UAE", registration_name: "A6-EUH", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56926" },
{ operator_icao_code: "UAE", registration_name: "A6-EUI", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56927" },
{ operator_icao_code: "UAE", registration_name: "A6-EUN", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56932" },
{ operator_icao_code: "UAE", registration_name: "A6-EUM", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56931" },
{ operator_icao_code: "UAE", registration_name: "A6-EUL", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56930" },
{ operator_icao_code: "UAE", registration_name: "A6-EUK", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56929" },
{ operator_icao_code: "UAE", registration_name: "A6-EUJ", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56928" },
{ operator_icao_code: "UAE", registration_name: "A6-EUE", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56923" },
{ operator_icao_code: "UAE", registration_name: "A6-EUA", ship_number: "", type_subtype: "380-800", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EPN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56713" },
{ operator_icao_code: "UAE", registration_name: "A6-EPO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56702" },
{ operator_icao_code: "UAE", registration_name: "A6-EPP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56703" },
{ operator_icao_code: "UAE", registration_name: "A6-EPQ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56712" },
{ operator_icao_code: "UAE", registration_name: "A6-EPR", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56711" },
{ operator_icao_code: "UAE", registration_name: "A6-EPS", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56710" },
{ operator_icao_code: "UAE", registration_name: "A6-EPT", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56709" },
{ operator_icao_code: "UAE", registration_name: "A6-EUO", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56933" },
{ operator_icao_code: "UAE", registration_name: "A6-EUP", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56917" },
{ operator_icao_code: "UAE", registration_name: "A6-EUQ", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56934" },
{ operator_icao_code: "UAE", registration_name: "F-WWAN", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EUT", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56937" },
{ operator_icao_code: "UAE", registration_name: "A6-EUU", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56918" },
{ operator_icao_code: "UAE", registration_name: "A6-EUV", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56938" },
{ operator_icao_code: "UAE", registration_name: "A6-EUW", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56939" },
{ operator_icao_code: "UAE", registration_name: "A6-EPU", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56708" },
{ operator_icao_code: "UAE", registration_name: "A6-EPV", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56845" },
{ operator_icao_code: "UAE", registration_name: "A6-EPW", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56846" },
{ operator_icao_code: "UAE", registration_name: "A6-EPX", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56847" },
{ operator_icao_code: "UAE", registration_name: "A6-EPY", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56848" },
{ operator_icao_code: "UAE", registration_name: "A6-EPZ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56849" },
{ operator_icao_code: "UAE", registration_name: "A6-EQA", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56851" },
{ operator_icao_code: "UAE", registration_name: "A6-EQB", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56852" },
{ operator_icao_code: "UAE", registration_name: "A6-EQC", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56853" },
{ operator_icao_code: "UAE", registration_name: "A6-EQD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56854" },
{ operator_icao_code: "UAE", registration_name: "A6-EUY", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56941" },
{ operator_icao_code: "UAE", registration_name: "A6-EUX", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56940" },
{ operator_icao_code: "UAE", registration_name: "A6-EQH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "4543" },
{ operator_icao_code: "UAE", registration_name: "A6-EUZ", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56942" },
{ operator_icao_code: "UAE", registration_name: "A6-EQE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56855" },
{ operator_icao_code: "UAE", registration_name: "A6-EQF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56856" },
{ operator_icao_code: "UAE", registration_name: "A6-EQI", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "4544" },
{ operator_icao_code: "UAE", registration_name: "A6-EQG", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56857" },
{ operator_icao_code: "UAE", registration_name: "A6-EQJ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "4545" },
{ operator_icao_code: "UAE", registration_name: "A6-EUR", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56935" },
{ operator_icao_code: "UAE", registration_name: "A6-EUS", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56936" },
{ operator_icao_code: "UAE", registration_name: "A6-EVA", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56943" },
{ operator_icao_code: "UAE", registration_name: "A6-EQK", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3-eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN1",xid: "4546" },
{ operator_icao_code: "UAE", registration_name: "A6-EVD", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56910" },
{ operator_icao_code: "UAE", registration_name: "A6-EQL", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56653" },
{ operator_icao_code: "UAE", registration_name: "A6-EKZ", ship_number: "", type_subtype: "757-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EQM", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56654" },
{ operator_icao_code: "UAE", registration_name: "A6-EVC", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56909" },
{ operator_icao_code: "UAE", registration_name: "A6-EQN", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56655" },
{ operator_icao_code: "UAE", registration_name: "A6-EVB", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56970" },
{ operator_icao_code: "UAE", registration_name: "F-WWAY", ship_number: "", type_subtype: "380-800", system_assigned: "eX3-eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EVE", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56911" },
{ operator_icao_code: "UAE", registration_name: "A6-EQO", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56658" },
{ operator_icao_code: "UAE", registration_name: "A6-EVF", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56912" },
{ operator_icao_code: "UAE", registration_name: "A6-EVH", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56914" },
{ operator_icao_code: "UAE", registration_name: "A6-EQP", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "56659" },
{ operator_icao_code: "UAE", registration_name: "A6-EVG", ship_number: "", type_subtype: "380-800", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56913" },
{ operator_icao_code: "UAE", registration_name: "A6-EVJ", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56915" },
{ operator_icao_code: "UAE", registration_name: "A6-EVI", ship_number: "", type_subtype: "380-800", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56916" },
{ operator_icao_code: "UAE", registration_name: "A6-EVK", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56919" },
{ operator_icao_code: "UAE", registration_name: "A6-EVL", ship_number: "", type_subtype: "380-800", system_assigned: "eX3-eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "56963" },
{ operator_icao_code: "UAE", registration_name: "A6-ZAD", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EVN", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57007" },
{ operator_icao_code: "UAE", registration_name: "A6-EVO", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57008" },
{ operator_icao_code: "UAE", registration_name: "A6-EVP", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EVQ", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EVR", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAE", registration_name: "A6-EVS", ship_number: "", type_subtype: "380-800", system_assigned: "eX3,eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N78001", ship_number: "1", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53354" },
{ operator_icao_code: "UAL", registration_name: "N78002", ship_number: "2", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53355" },
{ operator_icao_code: "UAL", registration_name: "N78003", ship_number: "3", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53356" },
{ operator_icao_code: "UAL", registration_name: "N78004", ship_number: "4", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53357" },
{ operator_icao_code: "UAL", registration_name: "N78005", ship_number: "5", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53358" },
{ operator_icao_code: "UAL", registration_name: "N77006", ship_number: "6", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53349" },
{ operator_icao_code: "UAL", registration_name: "N74007", ship_number: "7", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53346" },
{ operator_icao_code: "UAL", registration_name: "N78008", ship_number: "8", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53359" },
{ operator_icao_code: "UAL", registration_name: "N78009", ship_number: "9", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53360" },
{ operator_icao_code: "UAL", registration_name: "N76010", ship_number: "10", type_subtype: "777-220", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53347" },
{ operator_icao_code: "UAL", registration_name: "N79011", ship_number: "11", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53363" },
{ operator_icao_code: "UAL", registration_name: "N77012", ship_number: "12", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53350" },
{ operator_icao_code: "UAL", registration_name: "N78013", ship_number: "13", type_subtype: "777-220", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53361" },
{ operator_icao_code: "UAL", registration_name: "N77014", ship_number: "14", type_subtype: "777-220", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53351" },
{ operator_icao_code: "UAL", registration_name: "N27015", ship_number: "15", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53342" },
{ operator_icao_code: "UAL", registration_name: "N57016", ship_number: "16", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53344" },
{ operator_icao_code: "UAL", registration_name: "N78017", ship_number: "17", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53362" },
{ operator_icao_code: "UAL", registration_name: "N37018", ship_number: "18", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53343" },
{ operator_icao_code: "UAL", registration_name: "N66051", ship_number: "51", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53327" },
{ operator_icao_code: "UAL", registration_name: "N67052", ship_number: "52", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53330" },
{ operator_icao_code: "UAL", registration_name: "N59053", ship_number: "53", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53326" },
{ operator_icao_code: "UAL", registration_name: "N76054", ship_number: "54", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53335" },
{ operator_icao_code: "UAL", registration_name: "N76055", ship_number: "55", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53336" },
{ operator_icao_code: "UAL", registration_name: "N66056", ship_number: "56", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53328" },
{ operator_icao_code: "UAL", registration_name: "N66057", ship_number: "57", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53329" },
{ operator_icao_code: "UAL", registration_name: "N69059", ship_number: "59", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53333" },
{ operator_icao_code: "UAL", registration_name: "N78060", ship_number: "60", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53341" },
{ operator_icao_code: "UAL", registration_name: "N68061", ship_number: "61", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53332" },
{ operator_icao_code: "UAL", registration_name: "N76062", ship_number: "62", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53337" },
{ operator_icao_code: "UAL", registration_name: "N69063", ship_number: "63", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53334" },
{ operator_icao_code: "UAL", registration_name: "N76064", ship_number: "64", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53338" },
{ operator_icao_code: "UAL", registration_name: "N76065", ship_number: "65", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53339" },
{ operator_icao_code: "UAL", registration_name: "N77066", ship_number: "66", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53340" },
{ operator_icao_code: "UAL", registration_name: "N58101", ship_number: "101", type_subtype: "757-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eX1,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53289" },
{ operator_icao_code: "UAL", registration_name: "N14102", ship_number: "102", type_subtype: "757-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eX1,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53257" },
{ operator_icao_code: "UAL", registration_name: "N33103", ship_number: "103", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53281" },
{ operator_icao_code: "UAL", registration_name: "N17104", ship_number: "104", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53264" },
{ operator_icao_code: "UAL", registration_name: "N17105", ship_number: "105", type_subtype: "757-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53265" },
{ operator_icao_code: "UAL", registration_name: "N14106", ship_number: "106", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eX1,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53258" },
{ operator_icao_code: "UAL", registration_name: "N14107", ship_number: "107", type_subtype: "757-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eX1,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53259" },
{ operator_icao_code: "UAL", registration_name: "N21108", ship_number: "108", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53277" },
{ operator_icao_code: "UAL", registration_name: "N12109", ship_number: "109", type_subtype: "757-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53250" },
{ operator_icao_code: "UAL", registration_name: "N13110", ship_number: "110", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53254" },
{ operator_icao_code: "UAL", registration_name: "N57111", ship_number: "111", type_subtype: "757-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eX1,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53288" },
{ operator_icao_code: "UAL", registration_name: "N18112", ship_number: "112", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53271" },
{ operator_icao_code: "UAL", registration_name: "N13113", ship_number: "113", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53255" },
{ operator_icao_code: "UAL", registration_name: "N12114", ship_number: "114", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53251" },
{ operator_icao_code: "UAL", registration_name: "N14115", ship_number: "115", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53260" },
{ operator_icao_code: "UAL", registration_name: "N12116", ship_number: "116", type_subtype: "757-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eX1,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53252" },
{ operator_icao_code: "UAL", registration_name: "N19117", ship_number: "117", type_subtype: "757-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53273" },
{ operator_icao_code: "UAL", registration_name: "N14118", ship_number: "118", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53261" },
{ operator_icao_code: "UAL", registration_name: "N18119", ship_number: "119", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53272" },
{ operator_icao_code: "UAL", registration_name: "N14120", ship_number: "120", type_subtype: "757-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eX1,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53262" },
{ operator_icao_code: "UAL", registration_name: "N14121", ship_number: "121", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53263" },
{ operator_icao_code: "UAL", registration_name: "N17122", ship_number: "122", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53266" },
{ operator_icao_code: "UAL", registration_name: "N29124", ship_number: "124", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53279" },
{ operator_icao_code: "UAL", registration_name: "N17126", ship_number: "126", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53267" },
{ operator_icao_code: "UAL", registration_name: "N17128", ship_number: "128", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53268" },
{ operator_icao_code: "UAL", registration_name: "N26123", ship_number: "123", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N12125", ship_number: "125", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53253" },
{ operator_icao_code: "UAL", registration_name: "N48127", ship_number: "127", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53287" },
{ operator_icao_code: "UAL", registration_name: "N29129", ship_number: "129", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53280" },
{ operator_icao_code: "UAL", registration_name: "N19130", ship_number: "130", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53274" },
{ operator_icao_code: "UAL", registration_name: "N34131", ship_number: "131", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53283" },
{ operator_icao_code: "UAL", registration_name: "N33132", ship_number: "132", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53282" },
{ operator_icao_code: "UAL", registration_name: "N17133", ship_number: "133", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53269" },
{ operator_icao_code: "UAL", registration_name: "N67134", ship_number: "134", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53290" },
{ operator_icao_code: "UAL", registration_name: "N41135", ship_number: "135", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53285" },
{ operator_icao_code: "UAL", registration_name: "N19136", ship_number: "136", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53275" },
{ operator_icao_code: "UAL", registration_name: "N34137", ship_number: "137", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53284" },
{ operator_icao_code: "UAL", registration_name: "N13138", ship_number: "138", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53256" },
{ operator_icao_code: "UAL", registration_name: "N17139", ship_number: "139", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53270" },
{ operator_icao_code: "UAL", registration_name: "N41140", ship_number: "140", type_subtype: "757-200", system_assigned: "eFX,eXConnect,eXW", system_reported: "eFX,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53286" },
{ operator_icao_code: "UAL", registration_name: "N19141", ship_number: "141", type_subtype: "757-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eX1,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53276" },
{ operator_icao_code: "UAL", registration_name: "N57855", ship_number: "", type_subtype: "757-320", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N69020", ship_number: "20", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53345" },
{ operator_icao_code: "UAL", registration_name: "N77019", ship_number: "19", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53352" },
{ operator_icao_code: "UAL", registration_name: "N641UA", ship_number: "6441", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53291" },
{ operator_icao_code: "UAL", registration_name: "N642UA", ship_number: "6442", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53292" },
{ operator_icao_code: "UAL", registration_name: "N406UA", ship_number: "4106", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53140" },
{ operator_icao_code: "UAL", registration_name: "N647UA", ship_number: "6447", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53296" },
{ operator_icao_code: "UAL", registration_name: "N653UA", ship_number: "6453", type_subtype: "767-300ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53301" },
{ operator_icao_code: "UAL", registration_name: "N656UA", ship_number: "6456", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53304" },
{ operator_icao_code: "UAL", registration_name: "N652UA", ship_number: "6452", type_subtype: "767-300ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53300" },
{ operator_icao_code: "UAL", registration_name: "N651UA", ship_number: "6451", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53299" },
{ operator_icao_code: "UAL", registration_name: "N657UA", ship_number: "6457", type_subtype: "767-300ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53305" },
{ operator_icao_code: "UAL", registration_name: "N643UA", ship_number: "6443", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53293" },
{ operator_icao_code: "UAL", registration_name: "N659UA", ship_number: "6459", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53307" },
{ operator_icao_code: "UAL", registration_name: "N646UA", ship_number: "6446", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53295" },
{ operator_icao_code: "UAL", registration_name: "N644UA", ship_number: "6444", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53294" },
{ operator_icao_code: "UAL", registration_name: "N658UA", ship_number: "6458", type_subtype: "767-300ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53306" },
{ operator_icao_code: "UAL", registration_name: "N660UA", ship_number: "6460", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53308" },
{ operator_icao_code: "UAL", registration_name: "N648UA", ship_number: "6448", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53297" },
{ operator_icao_code: "UAL", registration_name: "N649UA", ship_number: "6449", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53298" },
{ operator_icao_code: "UAL", registration_name: "N654UA", ship_number: "6454", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53302" },
{ operator_icao_code: "UAL", registration_name: "N655UA", ship_number: "6455", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53303" },
{ operator_icao_code: "UAL", registration_name: "N661UA", ship_number: "6461", type_subtype: "767-300ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53309" },
{ operator_icao_code: "UAL", registration_name: "N662UA", ship_number: "6462", type_subtype: "767-300ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53310" },
{ operator_icao_code: "UAL", registration_name: "N663UA", ship_number: "6463", type_subtype: "767-300ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53311" },
{ operator_icao_code: "UAL", registration_name: "N199UA", ship_number: "8499", type_subtype: "747-400", system_assigned: "eFX,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N794UA", ship_number: "2894", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53410" },
{ operator_icao_code: "UAL", registration_name: "N782UA", ship_number: "2882", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53400" },
{ operator_icao_code: "UAL", registration_name: "N76021", ship_number: "21", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53348" },
{ operator_icao_code: "UAL", registration_name: "N77022", ship_number: "22", type_subtype: "777-220", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53353" },
{ operator_icao_code: "UAL", registration_name: "N784UA", ship_number: "2884", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53402" },
{ operator_icao_code: "UAL", registration_name: "N783UA", ship_number: "2883", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53401" },
{ operator_icao_code: "UAL", registration_name: "N785UA", ship_number: "2885", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53403" },
{ operator_icao_code: "UAL", registration_name: "N787UA", ship_number: "2887", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53405" },
{ operator_icao_code: "UAL", registration_name: "N788UA", ship_number: "2888", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53406" },
{ operator_icao_code: "UAL", registration_name: "N797UA", ship_number: "2897", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53413" },
{ operator_icao_code: "UAL", registration_name: "N226UA", ship_number: "2826", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53386" },
{ operator_icao_code: "UAL", registration_name: "N209UA", ship_number: "2809", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53375" },
{ operator_icao_code: "UAL", registration_name: "N773UA", ship_number: "2473", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53392" },
{ operator_icao_code: "UAL", registration_name: "N216UA", ship_number: "2816", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53376" },
{ operator_icao_code: "UAL", registration_name: "N217UA", ship_number: "2817", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53377" },
{ operator_icao_code: "UAL", registration_name: "N222UA", ship_number: "2822", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53382" },
{ operator_icao_code: "UAL", registration_name: "N223UA", ship_number: "2823", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53383" },
{ operator_icao_code: "UAL", registration_name: "N780UA", ship_number: "2480", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53398" },
{ operator_icao_code: "UAL", registration_name: "N224UA", ship_number: "2824", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53384" },
{ operator_icao_code: "UAL", registration_name: "N228UA", ship_number: "2828", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53388" },
{ operator_icao_code: "UAL", registration_name: "N781UA", ship_number: "2481", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53399" },
{ operator_icao_code: "UAL", registration_name: "N227UA", ship_number: "2827", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53387" },
{ operator_icao_code: "UAL", registration_name: "N777UA", ship_number: "2477", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53396" },
{ operator_icao_code: "UAL", registration_name: "N229UA", ship_number: "2029", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53389" },
{ operator_icao_code: "UAL", registration_name: "N225UA", ship_number: "2025", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53385" },
{ operator_icao_code: "UAL", registration_name: "N218UA", ship_number: "2018", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53378" },
{ operator_icao_code: "UAL", registration_name: "N775UA", ship_number: "2475", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53394" },
{ operator_icao_code: "UAL", registration_name: "N219UA", ship_number: "2019", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53379" },
{ operator_icao_code: "UAL", registration_name: "N774UA", ship_number: "2474", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53247" },
{ operator_icao_code: "UAL", registration_name: "N769UA", ship_number: "2469", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53390" },
{ operator_icao_code: "UAL", registration_name: "N776UA", ship_number: "2476", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53395" },
{ operator_icao_code: "UAL", registration_name: "N220UA", ship_number: "2020", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53380" },
{ operator_icao_code: "UAL", registration_name: "N778UA", ship_number: "2478", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53397" },
{ operator_icao_code: "UAL", registration_name: "N221UA", ship_number: "2021", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53381" },
{ operator_icao_code: "UAL", registration_name: "N401UA", ship_number: "4101", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53135" },
{ operator_icao_code: "UAL", registration_name: "N402UA", ship_number: "4102", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53136" },
{ operator_icao_code: "UAL", registration_name: "N403UA", ship_number: "4703", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53137" },
{ operator_icao_code: "UAL", registration_name: "N404UA", ship_number: "4704", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53138" },
{ operator_icao_code: "UAL", registration_name: "N405UA", ship_number: "4705", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53139" },
{ operator_icao_code: "UAL", registration_name: "N407UA", ship_number: "4107", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53141" },
{ operator_icao_code: "UAL", registration_name: "N408UA", ship_number: "4108", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53142" },
{ operator_icao_code: "UAL", registration_name: "N409UA", ship_number: "4709", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53143" },
{ operator_icao_code: "UAL", registration_name: "N411UA", ship_number: "4711", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53145" },
{ operator_icao_code: "UAL", registration_name: "N412UA", ship_number: "4712", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53146" },
{ operator_icao_code: "UAL", registration_name: "N413UA", ship_number: "4713", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53147" },
{ operator_icao_code: "UAL", registration_name: "N414UA", ship_number: "4214", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53148" },
{ operator_icao_code: "UAL", registration_name: "N415UA", ship_number: "4615", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53149" },
{ operator_icao_code: "UAL", registration_name: "N416UA", ship_number: "4616", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53150" },
{ operator_icao_code: "UAL", registration_name: "N417UA", ship_number: "4617", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53151" },
{ operator_icao_code: "UAL", registration_name: "N418UA", ship_number: "4618", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53152" },
{ operator_icao_code: "UAL", registration_name: "N419UA", ship_number: "4619", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53153" },
{ operator_icao_code: "UAL", registration_name: "N420UA", ship_number: "4620", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53154" },
{ operator_icao_code: "UAL", registration_name: "N421UA", ship_number: "4621", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53155" },
{ operator_icao_code: "UAL", registration_name: "N422UA", ship_number: "4622", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53156" },
{ operator_icao_code: "UAL", registration_name: "N423UA", ship_number: "4623", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53157" },
{ operator_icao_code: "UAL", registration_name: "N424UA", ship_number: "4624", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53158" },
{ operator_icao_code: "UAL", registration_name: "N425UA", ship_number: "4625", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53159" },
{ operator_icao_code: "UAL", registration_name: "N426UA", ship_number: "4626", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53160" },
{ operator_icao_code: "UAL", registration_name: "N427UA", ship_number: "4627", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53161" },
{ operator_icao_code: "UAL", registration_name: "N428UA", ship_number: "4628", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53162" },
{ operator_icao_code: "UAL", registration_name: "N429UA", ship_number: "4629", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53163" },
{ operator_icao_code: "UAL", registration_name: "N430UA", ship_number: "4630", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53164" },
{ operator_icao_code: "UAL", registration_name: "N431UA", ship_number: "4631", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53165" },
{ operator_icao_code: "UAL", registration_name: "N432UA", ship_number: "4632", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53166" },
{ operator_icao_code: "UAL", registration_name: "N433UA", ship_number: "4633", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53167" },
{ operator_icao_code: "UAL", registration_name: "N434UA", ship_number: "4634", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53168" },
{ operator_icao_code: "UAL", registration_name: "N435UA", ship_number: "4635", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53169" },
{ operator_icao_code: "UAL", registration_name: "N436UA", ship_number: "4636", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53170" },
{ operator_icao_code: "UAL", registration_name: "N437UA", ship_number: "4637", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53171" },
{ operator_icao_code: "UAL", registration_name: "N438UA", ship_number: "4238", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53172" },
{ operator_icao_code: "UAL", registration_name: "N439UA", ship_number: "4239", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53173" },
{ operator_icao_code: "UAL", registration_name: "N204UA", ship_number: "2704", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53373" },
{ operator_icao_code: "UAL", registration_name: "N206UA", ship_number: "2706", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53374" },
{ operator_icao_code: "UAL", registration_name: "N20904", ship_number: "904", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53430" },
{ operator_icao_code: "UAL", registration_name: "N801UA", ship_number: "4001", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53069" },
{ operator_icao_code: "UAL", registration_name: "N802UA", ship_number: "4002", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53070" },
{ operator_icao_code: "UAL", registration_name: "N440UA", ship_number: "4240", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53174" },
{ operator_icao_code: "UAL", registration_name: "N803UA", ship_number: "4003", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53071" },
{ operator_icao_code: "UAL", registration_name: "N441UA", ship_number: "4241", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53175" },
{ operator_icao_code: "UAL", registration_name: "N804UA", ship_number: "4004", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53072" },
{ operator_icao_code: "UAL", registration_name: "N442UA", ship_number: "4242", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53176" },
{ operator_icao_code: "UAL", registration_name: "N805UA", ship_number: "4005", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53073" },
{ operator_icao_code: "UAL", registration_name: "N806UA", ship_number: "4006", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53074" },
{ operator_icao_code: "UAL", registration_name: "N807UA", ship_number: "4007", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53075" },
{ operator_icao_code: "UAL", registration_name: "N808UA", ship_number: "4008", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53076" },
{ operator_icao_code: "UAL", registration_name: "N809UA", ship_number: "4009", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53077" },
{ operator_icao_code: "UAL", registration_name: "N810UA", ship_number: "4010", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53078" },
{ operator_icao_code: "UAL", registration_name: "N811UA", ship_number: "4011", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53079" },
{ operator_icao_code: "UAL", registration_name: "N812UA", ship_number: "4012", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53080" },
{ operator_icao_code: "UAL", registration_name: "N813UA", ship_number: "4013", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53081" },
{ operator_icao_code: "UAL", registration_name: "N814UA", ship_number: "4014", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53082" },
{ operator_icao_code: "UAL", registration_name: "N815UA", ship_number: "4015", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53083" },
{ operator_icao_code: "UAL", registration_name: "N816UA", ship_number: "4016", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53084" },
{ operator_icao_code: "UAL", registration_name: "N817UA", ship_number: "4017", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53085" },
{ operator_icao_code: "UAL", registration_name: "N818UA", ship_number: "4018", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53086" },
{ operator_icao_code: "UAL", registration_name: "N820UA", ship_number: "4020", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53088" },
{ operator_icao_code: "UAL", registration_name: "N821UA", ship_number: "4021", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53089" },
{ operator_icao_code: "UAL", registration_name: "N822UA", ship_number: "4022", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53090" },
{ operator_icao_code: "UAL", registration_name: "N823UA", ship_number: "4023", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53091" },
{ operator_icao_code: "UAL", registration_name: "N824UA", ship_number: "4024", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53092" },
{ operator_icao_code: "UAL", registration_name: "N825UA", ship_number: "4025", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53093" },
{ operator_icao_code: "UAL", registration_name: "N826UA", ship_number: "4026", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53094" },
{ operator_icao_code: "UAL", registration_name: "N827UA", ship_number: "4027", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53095" },
{ operator_icao_code: "UAL", registration_name: "N828UA", ship_number: "4028", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53096" },
{ operator_icao_code: "UAL", registration_name: "N829UA", ship_number: "4029", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53097" },
{ operator_icao_code: "UAL", registration_name: "N830UA", ship_number: "4030", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53098" },
{ operator_icao_code: "UAL", registration_name: "N831UA", ship_number: "4031", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53099" },
{ operator_icao_code: "UAL", registration_name: "N832UA", ship_number: "4032", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53100" },
{ operator_icao_code: "UAL", registration_name: "N833UA", ship_number: "4033", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53101" },
{ operator_icao_code: "UAL", registration_name: "N835UA", ship_number: "4035", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53103" },
{ operator_icao_code: "UAL", registration_name: "N836UA", ship_number: "4036", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53104" },
{ operator_icao_code: "UAL", registration_name: "N837UA", ship_number: "4037", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53105" },
{ operator_icao_code: "UAL", registration_name: "N838UA", ship_number: "4038", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53106" },
{ operator_icao_code: "UAL", registration_name: "N839UA", ship_number: "4039", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53107" },
{ operator_icao_code: "UAL", registration_name: "N840UA", ship_number: "4040", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53108" },
{ operator_icao_code: "UAL", registration_name: "N841UA", ship_number: "4041", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53109" },
{ operator_icao_code: "UAL", registration_name: "N842UA", ship_number: "4042", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53110" },
{ operator_icao_code: "UAL", registration_name: "N843UA", ship_number: "4043", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53111" },
{ operator_icao_code: "UAL", registration_name: "N844UA", ship_number: "4044", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53112" },
{ operator_icao_code: "UAL", registration_name: "N845UA", ship_number: "4045", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53113" },
{ operator_icao_code: "UAL", registration_name: "N846UA", ship_number: "4046", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53114" },
{ operator_icao_code: "UAL", registration_name: "N847UA", ship_number: "4047", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53115" },
{ operator_icao_code: "UAL", registration_name: "N848UA", ship_number: "4048", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53116" },
{ operator_icao_code: "UAL", registration_name: "N849UA", ship_number: "4049", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53117" },
{ operator_icao_code: "UAL", registration_name: "N850UA", ship_number: "4050", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53118" },
{ operator_icao_code: "UAL", registration_name: "N851UA", ship_number: "4051", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53119" },
{ operator_icao_code: "UAL", registration_name: "N852UA", ship_number: "4052", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53120" },
{ operator_icao_code: "UAL", registration_name: "N853UA", ship_number: "4053", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53121" },
{ operator_icao_code: "UAL", registration_name: "N854UA", ship_number: "4054", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53122" },
{ operator_icao_code: "UAL", registration_name: "N855UA", ship_number: "4055", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53123" },
{ operator_icao_code: "UAL", registration_name: "N443UA", ship_number: "4643", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53177" },
{ operator_icao_code: "UAL", registration_name: "N444UA", ship_number: "4244", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53178" },
{ operator_icao_code: "UAL", registration_name: "N445UA", ship_number: "4245", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53179" },
{ operator_icao_code: "UAL", registration_name: "N446UA", ship_number: "4246", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53180" },
{ operator_icao_code: "UAL", registration_name: "N447UA", ship_number: "4247", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53181" },
{ operator_icao_code: "UAL", registration_name: "N448UA", ship_number: "4248", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53182" },
{ operator_icao_code: "UAL", registration_name: "N449UA", ship_number: "4249", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53183" },
{ operator_icao_code: "UAL", registration_name: "N451UA", ship_number: "4251", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53184" },
{ operator_icao_code: "UAL", registration_name: "N452UA", ship_number: "4252", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53185" },
{ operator_icao_code: "UAL", registration_name: "N453UA", ship_number: "4253", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53186" },
{ operator_icao_code: "UAL", registration_name: "N454UA", ship_number: "4654", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53187" },
{ operator_icao_code: "UAL", registration_name: "N455UA", ship_number: "4655", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53188" },
{ operator_icao_code: "UAL", registration_name: "N456UA", ship_number: "4656", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53189" },
{ operator_icao_code: "UAL", registration_name: "N457UA", ship_number: "4257", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53190" },
{ operator_icao_code: "UAL", registration_name: "N458UA", ship_number: "4258", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53191" },
{ operator_icao_code: "UAL", registration_name: "N459UA", ship_number: "4259", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53192" },
{ operator_icao_code: "UAL", registration_name: "N460UA", ship_number: "4260", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53193" },
{ operator_icao_code: "UAL", registration_name: "N461UA", ship_number: "4661", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53194" },
{ operator_icao_code: "UAL", registration_name: "N462UA", ship_number: "4662", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53195" },
{ operator_icao_code: "UAL", registration_name: "N463UA", ship_number: "4663", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53196" },
{ operator_icao_code: "UAL", registration_name: "N464UA", ship_number: "4664", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53197" },
{ operator_icao_code: "UAL", registration_name: "N465UA", ship_number: "4265", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53198" },
{ operator_icao_code: "UAL", registration_name: "N466UA", ship_number: "4666", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53199" },
{ operator_icao_code: "UAL", registration_name: "N467UA", ship_number: "4267", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53200" },
{ operator_icao_code: "UAL", registration_name: "N468UA", ship_number: "4668", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53201" },
{ operator_icao_code: "UAL", registration_name: "N469UA", ship_number: "4269", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53202" },
{ operator_icao_code: "UAL", registration_name: "N470UA", ship_number: "4270", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53203" },
{ operator_icao_code: "UAL", registration_name: "N471UA", ship_number: "4171", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53204" },
{ operator_icao_code: "UAL", registration_name: "N472UA", ship_number: "4172", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53205" },
{ operator_icao_code: "UAL", registration_name: "N473UA", ship_number: "4173", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53206" },
{ operator_icao_code: "UAL", registration_name: "N474UA", ship_number: "4274", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53207" },
{ operator_icao_code: "UAL", registration_name: "N475UA", ship_number: "4275", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53208" },
{ operator_icao_code: "UAL", registration_name: "N476UA", ship_number: "4276", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53209" },
{ operator_icao_code: "UAL", registration_name: "N477UA", ship_number: "4277", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53210" },
{ operator_icao_code: "UAL", registration_name: "N478UA", ship_number: "4278", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53211" },
{ operator_icao_code: "UAL", registration_name: "N479UA", ship_number: "4279", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53212" },
{ operator_icao_code: "UAL", registration_name: "N480UA", ship_number: "4280", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53213" },
{ operator_icao_code: "UAL", registration_name: "N481UA", ship_number: "4281", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53214" },
{ operator_icao_code: "UAL", registration_name: "N482UA", ship_number: "4282", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53215" },
{ operator_icao_code: "UAL", registration_name: "N483UA", ship_number: "4283", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53216" },
{ operator_icao_code: "UAL", registration_name: "N484UA", ship_number: "4284", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53217" },
{ operator_icao_code: "UAL", registration_name: "N485UA", ship_number: "4285", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53218" },
{ operator_icao_code: "UAL", registration_name: "N486UA", ship_number: "4286", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53219" },
{ operator_icao_code: "UAL", registration_name: "N487UA", ship_number: "4287", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53220" },
{ operator_icao_code: "UAL", registration_name: "N488UA", ship_number: "4288", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53221" },
{ operator_icao_code: "UAL", registration_name: "N489UA", ship_number: "4289", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53222" },
{ operator_icao_code: "UAL", registration_name: "N490UA", ship_number: "4290", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53223" },
{ operator_icao_code: "UAL", registration_name: "N491UA", ship_number: "4291", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53224" },
{ operator_icao_code: "UAL", registration_name: "N492UA", ship_number: "4292", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53225" },
{ operator_icao_code: "UAL", registration_name: "N493UA", ship_number: "4293", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53226" },
{ operator_icao_code: "UAL", registration_name: "N494UA", ship_number: "4294", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53227" },
{ operator_icao_code: "UAL", registration_name: "N495UA", ship_number: "4295", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53228" },
{ operator_icao_code: "UAL", registration_name: "N496UA", ship_number: "4296", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53229" },
{ operator_icao_code: "UAL", registration_name: "N497UA", ship_number: "4297", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53230" },
{ operator_icao_code: "UAL", registration_name: "N498UA", ship_number: "4298", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53231" },
{ operator_icao_code: "UAL", registration_name: "N674UA", ship_number: "6674", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53322" },
{ operator_icao_code: "UAL", registration_name: "N834UA", ship_number: "4034", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53102" },
{ operator_icao_code: "UAL", registration_name: "N173UA", ship_number: "", type_subtype: "747-400", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N664UA", ship_number: "6664", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53312" },
{ operator_icao_code: "UAL", registration_name: "N665UA", ship_number: "6665", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53313" },
{ operator_icao_code: "UAL", registration_name: "N666UA", ship_number: "6666", type_subtype: "767-300ER", system_assigned: "eX3", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53314" },
{ operator_icao_code: "UAL", registration_name: "N667UA", ship_number: "6667", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53315" },
{ operator_icao_code: "UAL", registration_name: "N668UA", ship_number: "6668", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53316" },
{ operator_icao_code: "UAL", registration_name: "N669UA", ship_number: "6669", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53317" },
{ operator_icao_code: "UAL", registration_name: "N670UA", ship_number: "6670", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53318" },
{ operator_icao_code: "UAL", registration_name: "N671UA", ship_number: "6671", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53319" },
{ operator_icao_code: "UAL", registration_name: "N672UA", ship_number: "6672", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53320" },
{ operator_icao_code: "UAL", registration_name: "N673UA", ship_number: "6673", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53321" },
{ operator_icao_code: "UAL", registration_name: "N675UA", ship_number: "6675", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53323" },
{ operator_icao_code: "UAL", registration_name: "N676UA", ship_number: "6676", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53324" },
{ operator_icao_code: "UAL", registration_name: "N677UA", ship_number: "6677", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53325" },
{ operator_icao_code: "UAL", registration_name: "N791UA", ship_number: "2791", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53407" },
{ operator_icao_code: "UAL", registration_name: "N410UA", ship_number: "4710", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53144" },
{ operator_icao_code: "UAL", registration_name: "N27901", ship_number: "901", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53435" },
{ operator_icao_code: "UAL", registration_name: "N26902", ship_number: "902", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53431" },
{ operator_icao_code: "UAL", registration_name: "N27903", ship_number: "903", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53436" },
{ operator_icao_code: "UAL", registration_name: "N45905", ship_number: "905", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53441" },
{ operator_icao_code: "UAL", registration_name: "N26906", ship_number: "906", type_subtype: "787-8", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53432" },
{ operator_icao_code: "UAL", registration_name: "N771UA", ship_number: "2471", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53391" },
{ operator_icao_code: "UAL", registration_name: "N772UA", ship_number: "2372", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53371" },
{ operator_icao_code: "UAL", registration_name: "N779UA", ship_number: "2379", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53372" },
{ operator_icao_code: "UAL", registration_name: "N210UA", ship_number: "2510", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53364" },
{ operator_icao_code: "UAL", registration_name: "N211UA", ship_number: "2511", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53365" },
{ operator_icao_code: "UAL", registration_name: "N212UA", ship_number: "2512", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53248" },
{ operator_icao_code: "UAL", registration_name: "N213UA", ship_number: "2513", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53367" },
{ operator_icao_code: "UAL", registration_name: "N214UA", ship_number: "2514", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53368" },
{ operator_icao_code: "UAL", registration_name: "N215UA", ship_number: "2515", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53369" },
{ operator_icao_code: "UAL", registration_name: "N792UA", ship_number: "2792", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53408" },
{ operator_icao_code: "UAL", registration_name: "N793UA", ship_number: "2793", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53409" },
{ operator_icao_code: "UAL", registration_name: "N795UA", ship_number: "2795", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53411" },
{ operator_icao_code: "UAL", registration_name: "N796UA", ship_number: "2796", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53412" },
{ operator_icao_code: "UAL", registration_name: "N798UA", ship_number: "2798", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53414" },
{ operator_icao_code: "UAL", registration_name: "N799UA", ship_number: "2799", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53415" },
{ operator_icao_code: "UAL", registration_name: "N768UA", ship_number: "2368", type_subtype: "777-200ER", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53370" },
{ operator_icao_code: "UAL", registration_name: "N786UA", ship_number: "2786", type_subtype: "777-200ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53404" },
{ operator_icao_code: "UAL", registration_name: "N596UA", ship_number: "5996", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N595UA", ship_number: "5995", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N590UA", ship_number: "5990", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N518UA", ship_number: "5318", type_subtype: "757-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N589UA", ship_number: "5989", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N505UA", ship_number: "5305", type_subtype: "757-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N568UA", ship_number: "5468", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N512UA", ship_number: "5312", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N587UA", ship_number: "5687", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N510UA", ship_number: "5310", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N554UA", ship_number: "5354", type_subtype: "757-200", system_assigned: "Unknown", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N819UA", ship_number: "4019", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53087" },
{ operator_icao_code: "UAL", registration_name: "N597UA", ship_number: "5997", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N588UA", ship_number: "5688", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N598UA", ship_number: "5598", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N29907", ship_number: "907", type_subtype: "787-8", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53439" },
{ operator_icao_code: "UAL", registration_name: "N546UA", ship_number: "5946", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N73860", ship_number: "860", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53239" },
{ operator_icao_code: "UAL", registration_name: "N27908", ship_number: "908", type_subtype: "787-8", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53437" },
{ operator_icao_code: "UAL", registration_name: "N26909", ship_number: "909", type_subtype: "787-8", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53433" },
{ operator_icao_code: "UAL", registration_name: "N26910", ship_number: "910", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53434" },
{ operator_icao_code: "UAL", registration_name: "N28912", ship_number: "912", type_subtype: "787-8", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53438" },
{ operator_icao_code: "UAL", registration_name: "N30913", ship_number: "913", type_subtype: "787-8", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53440" },
{ operator_icao_code: "UAL", registration_name: "N19951", ship_number: "951", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53445" },
{ operator_icao_code: "UAL", registration_name: "N38950", ship_number: "950", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53461" },
{ operator_icao_code: "UAL", registration_name: "N26952", ship_number: "952", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53446" },
{ operator_icao_code: "UAL", registration_name: "N75861", ship_number: "861", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53240" },
{ operator_icao_code: "UAL", registration_name: "N57862", ship_number: "862", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53233" },
{ operator_icao_code: "UAL", registration_name: "N502UA", ship_number: "5302", type_subtype: "757-200", system_assigned: "eX1", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N57863", ship_number: "863", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53234" },
{ operator_icao_code: "UAL", registration_name: "N57864", ship_number: "864", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53235" },
{ operator_icao_code: "UAL", registration_name: "N77865", ship_number: "865", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53241" },
{ operator_icao_code: "UAL", registration_name: "N78866", ship_number: "866", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53244" },
{ operator_icao_code: "UAL", registration_name: "N77867", ship_number: "867", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53242" },
{ operator_icao_code: "UAL", registration_name: "N57870", ship_number: "870", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53238" },
{ operator_icao_code: "UAL", registration_name: "N57868", ship_number: "868", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53236" },
{ operator_icao_code: "UAL", registration_name: "N57869", ship_number: "869", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53237" },
{ operator_icao_code: "UAL", registration_name: "N35953", ship_number: "953", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53459" },
{ operator_icao_code: "UAL", registration_name: "N13954", ship_number: "954", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53442" },
{ operator_icao_code: "UAL", registration_name: "N77871", ship_number: "871", type_subtype: "757-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53243" },
{ operator_icao_code: "UAL", registration_name: "N525UA", ship_number: "", type_subtype: "757-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N594UA", ship_number: "", type_subtype: "757-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N549UA", ship_number: "", type_subtype: "757-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N555UA", ship_number: "", type_subtype: "757-200", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N38955", ship_number: "955", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53462" },
{ operator_icao_code: "UAL", registration_name: "N45956", ship_number: "956", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53468" },
{ operator_icao_code: "UAL", registration_name: "N27958", ship_number: "958", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53453" },
{ operator_icao_code: "UAL", registration_name: "N27959", ship_number: "959", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53454" },
{ operator_icao_code: "UAL", registration_name: "N27957", ship_number: "957", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53452" },
{ operator_icao_code: "UAL", registration_name: "N26960", ship_number: "960", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53447" },
{ operator_icao_code: "UAL", registration_name: "N29961", ship_number: "961", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53457" },
{ operator_icao_code: "UAL", registration_name: "N36962", ship_number: "962", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53460" },
{ operator_icao_code: "UAL", registration_name: "N17963", ship_number: "963", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53444" },
{ operator_icao_code: "UAL", registration_name: "N27964", ship_number: "964", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53455" },
{ operator_icao_code: "UAL", registration_name: "N27965", ship_number: "965", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53456" },
{ operator_icao_code: "UAL", registration_name: "N885UA", ship_number: "4885", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N886UA", ship_number: "4886", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N887UA", ship_number: "4887", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N889UA", ship_number: "4889", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53124" },
{ operator_icao_code: "UAL", registration_name: "N890UA", ship_number: "4890", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53125" },
{ operator_icao_code: "UAL", registration_name: "N891UA", ship_number: "4891", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53126" },
{ operator_icao_code: "UAL", registration_name: "N892UA", ship_number: "4892", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53127" },
{ operator_icao_code: "UAL", registration_name: "N893UA", ship_number: "4893", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53128" },
{ operator_icao_code: "UAL", registration_name: "N894UA", ship_number: "4894", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53129" },
{ operator_icao_code: "UAL", registration_name: "N895UA", ship_number: "4895", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53130" },
{ operator_icao_code: "UAL", registration_name: "N896UA", ship_number: "4896", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53131" },
{ operator_icao_code: "UAL", registration_name: "N897UA", ship_number: "4897", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53132" },
{ operator_icao_code: "UAL", registration_name: "N898UA", ship_number: "4898", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53133" },
{ operator_icao_code: "UAL", registration_name: "N899UA", ship_number: "4899", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53134" },
{ operator_icao_code: "UAL", registration_name: "N26966", ship_number: "966", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53448" },
{ operator_icao_code: "UAL", registration_name: "N26967", ship_number: "967", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "53449" },
{ operator_icao_code: "UAL", registration_name: "N2331U", ship_number: "2131", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53422" },
{ operator_icao_code: "UAL", registration_name: "N2332U", ship_number: "2132", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53423" },
{ operator_icao_code: "UAL", registration_name: "N59033", ship_number: "", type_subtype: "777-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N59035", ship_number: "", type_subtype: "777-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N59036", ship_number: "", type_subtype: "777-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N59039", ship_number: "", type_subtype: "777-300", system_assigned: "eXConnect", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N29968", ship_number: "968", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53458" },
{ operator_icao_code: "UAL", registration_name: "N2333U", ship_number: "2133", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53424" },
{ operator_icao_code: "UAL", registration_name: "N15969", ship_number: "969", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E1: EMS (Prototype)", antenna_gen: "GEN3",xid: "53443" },
{ operator_icao_code: "UAL", registration_name: "N2135U", ship_number: "2135", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53416" },
{ operator_icao_code: "UAL", registration_name: "N2534U", ship_number: "2134", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53429" },
{ operator_icao_code: "UAL", registration_name: "N2136U", ship_number: "2136", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53417" },
{ operator_icao_code: "UAL", registration_name: "N2737U", ship_number: "2137", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53428" },
{ operator_icao_code: "UAL", registration_name: "N2138U", ship_number: "2138", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53418" },
{ operator_icao_code: "UAL", registration_name: "N2639U", ship_number: "2139", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53426" },
{ operator_icao_code: "UAL", registration_name: "N2140U", ship_number: "2140", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53419" },
{ operator_icao_code: "UAL", registration_name: "N2341U", ship_number: "2141", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53425" },
{ operator_icao_code: "UAL", registration_name: "N2142U", ship_number: "2142", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53420" },
{ operator_icao_code: "UAL", registration_name: "N2243U", ship_number: "2143", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53421" },
{ operator_icao_code: "UAL", registration_name: "N2644U", ship_number: "2144", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53427" },
{ operator_icao_code: "UAL", registration_name: "N26970", ship_number: "970", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53464" },
{ operator_icao_code: "UAL", registration_name: "N24972", ship_number: "972", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53451" },
{ operator_icao_code: "UAL", registration_name: "N29971", ship_number: "971", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53450" },
{ operator_icao_code: "UAL", registration_name: "N4888U", ship_number: "4888", type_subtype: "319-100", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53232" },
{ operator_icao_code: "UAL", registration_name: "N24973", ship_number: "973", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53466" },
{ operator_icao_code: "UAL", registration_name: "N24974", ship_number: "974", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53467" },
{ operator_icao_code: "UAL", registration_name: "N2645U", ship_number: "2145", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53463" },
{ operator_icao_code: "UAL", registration_name: "N2846U", ship_number: "2146", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53469" },
{ operator_icao_code: "UAL", registration_name: "N2747U", ship_number: "2147", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53465" },
{ operator_icao_code: "UAL", registration_name: "N4901U", ship_number: "4901", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53245" },
{ operator_icao_code: "UAL", registration_name: "N1902U", ship_number: "4902", type_subtype: "320-200", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53246" },
{ operator_icao_code: "UAL", registration_name: "N2748U", ship_number: "2148", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53470" },
{ operator_icao_code: "UAL", registration_name: "N91007", ship_number: "1007", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53474" },
{ operator_icao_code: "UAL", registration_name: "ZC-036", ship_number: "", type_subtype: "787-10", system_assigned: "2000", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UAL", registration_name: "N14001", ship_number: "1001", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53249" },
{ operator_icao_code: "UAL", registration_name: "N17002", ship_number: "1002", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53471" },
{ operator_icao_code: "UAL", registration_name: "N12003", ship_number: "1003", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53366" },
{ operator_icao_code: "UAL", registration_name: "N12004", ship_number: "1004", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53393" },
{ operator_icao_code: "UAL", registration_name: "N12005", ship_number: "1005", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53472" },
{ operator_icao_code: "UAL", registration_name: "N12006", ship_number: "1006", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53473" },
{ operator_icao_code: "UAL", registration_name: "N16008", ship_number: "1008", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53475" },
{ operator_icao_code: "UAL", registration_name: "N16009", ship_number: "1009", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53476" },
{ operator_icao_code: "UAL", registration_name: "N67058", ship_number: "58", type_subtype: "767-400", system_assigned: "eX2,eXConnect,eXW", system_reported: "eX2L,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53331" },
{ operator_icao_code: "UAL", registration_name: "N684UA", ship_number: "6684", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53478" },
{ operator_icao_code: "UAL", registration_name: "N686UA", ship_number: "6686", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53479" },
{ operator_icao_code: "UAL", registration_name: "N685UA", ship_number: "6685", type_subtype: "767-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53480" },
{ operator_icao_code: "UAL", registration_name: "N2749U", ship_number: "2149", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53481" },
{ operator_icao_code: "UAL", registration_name: "N2250U", ship_number: "2150", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53483" },
{ operator_icao_code: "UAL", registration_name: "N12012", ship_number: "1012", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53487" },
{ operator_icao_code: "UAL", registration_name: "N12010", ship_number: "1010", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53482" },
{ operator_icao_code: "UAL", registration_name: "N2251U", ship_number: "2151", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53486" },
{ operator_icao_code: "UAL", registration_name: "N14011", ship_number: "1011", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53484" },
{ operator_icao_code: "UAL", registration_name: "N29975", ship_number: "975", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53485" },
{ operator_icao_code: "UAL", registration_name: "N2352U", ship_number: "2152", type_subtype: "777-300ER", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "53490" },
{ operator_icao_code: "UAL", registration_name: "N24976", ship_number: "976", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53488" },
{ operator_icao_code: "UAL", registration_name: "N29977", ship_number: "977", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53489" },
{ operator_icao_code: "UAL", registration_name: "N13013", ship_number: "1013", type_subtype: "787-10", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53491" },
{ operator_icao_code: "UAL", registration_name: "N29978", ship_number: "978", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "53492" },
{ operator_icao_code: "UAL", registration_name: "N24979", ship_number: "979", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "53493" },
{ operator_icao_code: "UBA", registration_name: "XY-ALB", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eFX,eXW", system_reported: "eFX", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UBA", registration_name: "XY-ALC", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eFX,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UBA", registration_name: "XY-ALF", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eFX,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UBA", registration_name: "XY-ALG", ship_number: "", type_subtype: "737-800", system_assigned: "DMPES,eFX,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UZB", registration_name: "UK67003", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UZB", registration_name: "UK67004", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "eX2L", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UZB", registration_name: "UK67005", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UZB", registration_name: "UK67006", ship_number: "", type_subtype: "767-300ER", system_assigned: "eX2", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UZB", registration_name: "UK78701", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UZB", registration_name: "UK78702", ship_number: "", type_subtype: "787-8", system_assigned: "eX3", system_reported: "eX3", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "UZB", registration_name: "UK32021", ship_number: "", type_subtype: "320-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "eX1,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57645" },
{ operator_icao_code: "UZB", registration_name: "UK32022", ship_number: "", type_subtype: "320-200", system_assigned: "eXConnect", system_reported: "eX1,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57647" },
{ operator_icao_code: "UZB", registration_name: "UK78704", ship_number: "", type_subtype: "787-8", system_assigned: "eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57648" },
{ operator_icao_code: "UZB", registration_name: "UK78705", ship_number: "ZA876", type_subtype: "787-8", system_assigned: "eX3-eXConnect", system_reported: "eX3", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57649" },
{ operator_icao_code: "VIR", registration_name: "G-VXLG", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VAST", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VGAL", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VLIP", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VROM", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VLUV", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VSXY", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VKSS", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VINE", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VRAY", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VGBR", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VROS", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VROY", ship_number: "", type_subtype: "747-400", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VNYC", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VWAG", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VGEM", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VUFO", ship_number: "", type_subtype: "330-300", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VIR", registration_name: "G-VNEW", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "G: G/T", antenna_gen: "GEN3",xid: "57237" },
{ operator_icao_code: "VIR", registration_name: "G-VAHH", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57239" },
{ operator_icao_code: "VIR", registration_name: "G-VOOH", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57241" },
{ operator_icao_code: "VIR", registration_name: "G-VWHO", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57247" },
{ operator_icao_code: "VIR", registration_name: "G-VFAN", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57261" },
{ operator_icao_code: "VIR", registration_name: "G-VWOO", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "G: G/T", antenna_gen: "GEN3",xid: "57265" },
{ operator_icao_code: "VIR", registration_name: "G-VBZZ", ship_number: "", type_subtype: "787-9", system_assigned: "eX3-eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57257" },
{ operator_icao_code: "VIR", registration_name: "G-VSPY", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "G: G/T", antenna_gen: "GEN3",xid: "57251" },
{ operator_icao_code: "VIR", registration_name: "G-VNYL", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "G: G/T", antenna_gen: "GEN3",xid: "57230" },
{ operator_icao_code: "VIR", registration_name: "G-VBEL", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57267" },
{ operator_icao_code: "VIR", registration_name: "G-VZIG", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57243" },
{ operator_icao_code: "VIR", registration_name: "G-VYUM", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57245" },
{ operator_icao_code: "VIR", registration_name: "G-VCRU", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "57249" },
{ operator_icao_code: "VIR", registration_name: "G-VDIA", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "G: G/T", antenna_gen: "GEN3",xid: "57255" },
{ operator_icao_code: "VIR", registration_name: "G-VOWS", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "G: G/T", antenna_gen: "GEN3",xid: "57253" },
{ operator_icao_code: "VIR", registration_name: "G-VMAP", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "G: G/T", antenna_gen: "GEN3",xid: "57259" },
{ operator_icao_code: "VIR", registration_name: "G-VBOW", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3", antenna: "G: G/T", antenna_gen: "GEN3",xid: "57263" },
{ operator_icao_code: "VIR", registration_name: "G-VMNK", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57231" },
{ operator_icao_code: "VIR", registration_name: "G-VMIK", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57233" },
{ operator_icao_code: "VIR", registration_name: "G-VWND", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57235" },
{ operator_icao_code: "VIR", registration_name: "G-VLNM", ship_number: "", type_subtype: "330-200", system_assigned: "eXConnect", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "57229" },
{ operator_icao_code: "VOZ", registration_name: "VH-VOZ", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VOZ", registration_name: "VH-VPD", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VOZ", registration_name: "VH-VPE", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VOZ", registration_name: "VH-VPF", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VOZ", registration_name: "VH-VPH", ship_number: "", type_subtype: "777-300ER", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VOZ", registration_name: "VH-XFC", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VOZ", registration_name: "VH-XFD", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VOZ", registration_name: "VH-XFE", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VOZ", registration_name: "VH-XFG", ship_number: "1407", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VOZ", registration_name: "VH-XFH", ship_number: "1452", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VOZ", registration_name: "VH-XFJ", ship_number: "", type_subtype: "330-200", system_assigned: "eX2", system_reported: "eX2", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N621VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N622VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N623VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N624VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N626VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N627VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N629VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX,eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N6VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N631VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N521VA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N522VA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N523VA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N524VA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N525VA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N526VA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N527VA", ship_number: "3417", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N528VA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N529VA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N530VA", ship_number: "", type_subtype: "319-100", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N632VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N633VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N634VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N635VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N636VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N637VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N638VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N639VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N640VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N641VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N642VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N835VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N836VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N837VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N838VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N839VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N840VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N841VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N842VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N843VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N844VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N845VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N846VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N847VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N848VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N849VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N854VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N853VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N852VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N851VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N855VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N361VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N281VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N282VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N283VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N284VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N285VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N286VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N362VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N363VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N364VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N365VA", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "eX1", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N921VA", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N922VA", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N925VA", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N923VA", ship_number: "7861", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "D-AVZJ", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N924VA", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N927VA", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VRD", registration_name: "N926VA", ship_number: "", type_subtype: "321-200", system_assigned: "eX1", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "VTI", registration_name: "VT-TSD", ship_number: "", type_subtype: "787-9", system_assigned: "eX3,eXConnect", system_reported: "eX3,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "52317" },
{ operator_icao_code: "VTI", registration_name: "DONOTUSED-AVXP", ship_number: "", type_subtype: "321-200", system_assigned: "eX1,eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "WCA", registration_name: "N162WC", ship_number: "", type_subtype: "737-700", system_assigned: "eXConnect", system_reported: "eX1,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "58141" },
{ operator_icao_code: "WIF", registration_name: "LN-WEA", ship_number: "", type_subtype: "ERJ190-100IGW", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "WIF", registration_name: "LN-WEC", ship_number: "", type_subtype: "300-100", system_assigned: "eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "WJA", registration_name: "C-GKWA", ship_number: "823", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54780" },
{ operator_icao_code: "WJA", registration_name: "C-GVWA", ship_number: "825", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54766" },
{ operator_icao_code: "WJA", registration_name: "C-FBWI", ship_number: "822", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54804" },
{ operator_icao_code: "WJA", registration_name: "C-FRWA", ship_number: "821", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54811" },
{ operator_icao_code: "WJA", registration_name: "C-FJWS", ship_number: "2", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54876" },
{ operator_icao_code: "WJA", registration_name: "C-FKWS", ship_number: "3", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54824" },
{ operator_icao_code: "WJA", registration_name: "C-GAWS", ship_number: "820", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54826" },
{ operator_icao_code: "WJA", registration_name: "C-FWIJ", ship_number: "819", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54813" },
{ operator_icao_code: "WJA", registration_name: "C-GLWS", ship_number: "5", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54776" },
{ operator_icao_code: "WJA", registration_name: "C-FZWS", ship_number: "6", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54777" },
{ operator_icao_code: "WJA", registration_name: "C-GWRG", ship_number: "818", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54770" },
{ operator_icao_code: "WJA", registration_name: "C-FWVJ", ship_number: "817", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54817" },
{ operator_icao_code: "WJA", registration_name: "C-GRWS", ship_number: "7", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54877" },
{ operator_icao_code: "WJA", registration_name: "C-GTWS", ship_number: "8", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54790" },
{ operator_icao_code: "WJA", registration_name: "C-FKWJ", ship_number: "815", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54818" },
{ operator_icao_code: "WJA", registration_name: "C-FCNW", ship_number: "816", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54809" },
{ operator_icao_code: "WJA", registration_name: "C-GUWS", ship_number: "9", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54879" },
{ operator_icao_code: "WJA", registration_name: "C-GWSX", ship_number: "813", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54798" },
{ operator_icao_code: "WJA", registration_name: "C-GWSZ", ship_number: "812", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54797" },
{ operator_icao_code: "WJA", registration_name: "C-FWSE", ship_number: "811", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54832" },
{ operator_icao_code: "WJA", registration_name: "C-GWSV", ship_number: "810", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54769" },
{ operator_icao_code: "WJA", registration_name: "C-GWSH", ship_number: "11", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54878" },
{ operator_icao_code: "WJA", registration_name: "C-FWCN", ship_number: "212", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54822" },
{ operator_icao_code: "WJA", registration_name: "C-GWSR", ship_number: "809", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54847" },
{ operator_icao_code: "WJA", registration_name: "C-GWWJ", ship_number: "808", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54825" },
{ operator_icao_code: "WJA", registration_name: "C-FAWJ", ship_number: "807", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54765" },
{ operator_icao_code: "WJA", registration_name: "C-GWBL", ship_number: "806", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54821" },
{ operator_icao_code: "WJA", registration_name: "C-GWSA", ship_number: "805", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54823" },
{ operator_icao_code: "WJA", registration_name: "C-GZWS", ship_number: "803", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54802" },
{ operator_icao_code: "WJA", registration_name: "C-GWBF", ship_number: "213", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54835" },
{ operator_icao_code: "WJA", registration_name: "C-GWBJ", ship_number: "215", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54836" },
{ operator_icao_code: "WJA", registration_name: "C-GJWS", ship_number: "802", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54815" },
{ operator_icao_code: "WJA", registration_name: "C-GWBT", ship_number: "216", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54844" },
{ operator_icao_code: "WJA", registration_name: "C-GKWJ", ship_number: "801", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54808" },
{ operator_icao_code: "WJA", registration_name: "C-GWCM", ship_number: "217", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54841" },
{ operator_icao_code: "WJA", registration_name: "C-FWSF", ship_number: "218", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54819" },
{ operator_icao_code: "WJA", registration_name: "C-FWSO", ship_number: "219", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54810" },
{ operator_icao_code: "WJA", registration_name: "C-FWSV", ship_number: "220", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54787" },
{ operator_icao_code: "WJA", registration_name: "C-FWSX", ship_number: "221", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54870" },
{ operator_icao_code: "WJA", registration_name: "C-FWSY", ship_number: "222", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54792" },
{ operator_icao_code: "WJA", registration_name: "C-GWAZ", ship_number: "223", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54795" },
{ operator_icao_code: "WJA", registration_name: "C-GWJO", ship_number: "225", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54855" },
{ operator_icao_code: "WJA", registration_name: "C-FGWJ", ship_number: "226", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54833" },
{ operator_icao_code: "WJA", registration_name: "C-GCWJ", ship_number: "227", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54829" },
{ operator_icao_code: "WJA", registration_name: "C-FUWS", ship_number: "228", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54837" },
{ operator_icao_code: "WJA", registration_name: "C-GWJF", ship_number: "229", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54850" },
{ operator_icao_code: "WJA", registration_name: "C-FBWJ", ship_number: "230", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54779" },
{ operator_icao_code: "WJA", registration_name: "C-FXWJ", ship_number: "231", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54830" },
{ operator_icao_code: "WJA", registration_name: "C-FEWJ", ship_number: "232", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54778" },
{ operator_icao_code: "WJA", registration_name: "C-FMWJ", ship_number: "233", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54874" },
{ operator_icao_code: "WJA", registration_name: "C-GWBN", ship_number: "235", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54812" },
{ operator_icao_code: "WJA", registration_name: "C-GWBX", ship_number: "236", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54783" },
{ operator_icao_code: "WJA", registration_name: "C-GWCN", ship_number: "237", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54772" },
{ operator_icao_code: "WJA", registration_name: "C-GYWJ", ship_number: "238", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54789" },
{ operator_icao_code: "WJA", registration_name: "C-GMWJ", ship_number: "239", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54788" },
{ operator_icao_code: "WJA", registration_name: "C-FTWJ", ship_number: "241", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54786" },
{ operator_icao_code: "WJA", registration_name: "C-GGWJ", ship_number: "242", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54785" },
{ operator_icao_code: "WJA", registration_name: "C-GWJG", ship_number: "243", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54845" },
{ operator_icao_code: "WJA", registration_name: "C-GWJE", ship_number: "245", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54796" },
{ operator_icao_code: "WJA", registration_name: "C-GQWJ", ship_number: "246", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54793" },
{ operator_icao_code: "WJA", registration_name: "C-GVWJ", ship_number: "247", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54803" },
{ operator_icao_code: "WJA", registration_name: "C-GUWJ", ship_number: "248", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54820" },
{ operator_icao_code: "WJA", registration_name: "C-FWSK", ship_number: "251", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54816" },
{ operator_icao_code: "WJA", registration_name: "C-GWSU", ship_number: "252", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54782" },
{ operator_icao_code: "WJA", registration_name: "C-FWSI", ship_number: "253", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54831" },
{ operator_icao_code: "WJA", registration_name: "C-GWSN", ship_number: "256", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54846" },
{ operator_icao_code: "WJA", registration_name: "C-GWSO", ship_number: "257", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54842" },
{ operator_icao_code: "WJA", registration_name: "C-GWSP", ship_number: "258", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54838" },
{ operator_icao_code: "WJA", registration_name: "C-GWSQ", ship_number: "259", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54873" },
{ operator_icao_code: "WJA", registration_name: "C-GSWJ", ship_number: "261", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54828" },
{ operator_icao_code: "WJA", registration_name: "C-FLWJ", ship_number: "262", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54875" },
{ operator_icao_code: "WJA", registration_name: "C-GWJT", ship_number: "263", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54791" },
{ operator_icao_code: "WJA", registration_name: "C-FIBW", ship_number: "266", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54880" },
{ operator_icao_code: "WJA", registration_name: "C-FKIW", ship_number: "265", type_subtype: "737-700", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54881" },
{ operator_icao_code: "WJA", registration_name: "C-GPWS", ship_number: "601", type_subtype: "737-600", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54834" },
{ operator_icao_code: "WJA", registration_name: "C-GWSL", ship_number: "606", type_subtype: "737-600", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "WJA", registration_name: "C-FUSM", ship_number: "826", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54806" },
{ operator_icao_code: "WJA", registration_name: "C-FUMF", ship_number: "835", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54771" },
{ operator_icao_code: "WJA", registration_name: "C-FUCS", ship_number: "836", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54800" },
{ operator_icao_code: "WJA", registration_name: "C-FUJR", ship_number: "837", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54781" },
{ operator_icao_code: "WJA", registration_name: "C-FDMB", ship_number: "833", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54768" },
{ operator_icao_code: "WJA", registration_name: "C-GWBU", ship_number: "828", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54827" },
{ operator_icao_code: "WJA", registration_name: "C-FOGJ", ship_number: "671", type_subtype: "767-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54848" },
{ operator_icao_code: "WJA", registration_name: "C-FWJS", ship_number: "827", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54799" },
{ operator_icao_code: "WJA", registration_name: "C-GWUX", ship_number: "830", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54814" },
{ operator_icao_code: "WJA", registration_name: "C-GJLZ", ship_number: "831", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54773" },
{ operator_icao_code: "WJA", registration_name: "C-FOGT", ship_number: "673", type_subtype: "767-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54887" },
{ operator_icao_code: "WJA", registration_name: "C-FWAD", ship_number: "672", type_subtype: "767-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54840" },
{ operator_icao_code: "WJA", registration_name: "C-GOGN", ship_number: "670", type_subtype: "767-300", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "E2: EMS", antenna_gen: "GEN3",xid: "54801" },
{ operator_icao_code: "WJA", registration_name: "C-FCSX", ship_number: "832", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54767" },
{ operator_icao_code: "WJA", registration_name: "C-FKRF", ship_number: "829", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54807" },
{ operator_icao_code: "WJA", registration_name: "C-GYSD", ship_number: "848", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54856" },
{ operator_icao_code: "WJA", registration_name: "C-FZRM", ship_number: "849", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54794" },
{ operator_icao_code: "WJA", registration_name: "C-GRTB", ship_number: "850", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54784" },
{ operator_icao_code: "WJA", registration_name: "C-FXMS", ship_number: "851", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54805" },
{ operator_icao_code: "WJA", registration_name: "C-FRAX", ship_number: "301", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54839" },
{ operator_icao_code: "WJA", registration_name: "C-GXAX", ship_number: "303", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54851" },
{ operator_icao_code: "WJA", registration_name: "C-FNAX", ship_number: "302", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54849" },
{ operator_icao_code: "WJA", registration_name: "C-GRAX", ship_number: "305", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54868" },
{ operator_icao_code: "WJA", registration_name: "C-FRYV", ship_number: "306", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54867" },
{ operator_icao_code: "WJA", registration_name: "C-GCAM", ship_number: "307", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54852" },
{ operator_icao_code: "WJA", registration_name: "C-GOCD", ship_number: "852", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54774" },
{ operator_icao_code: "WJA", registration_name: "C-GJLS", ship_number: "853", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54775" },
{ operator_icao_code: "WJA", registration_name: "C-GZSG", ship_number: "309", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54866" },
{ operator_icao_code: "WJA", registration_name: "C-FNWD", ship_number: "308", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "eX2", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54865" },
{ operator_icao_code: "WJA", registration_name: "C-GEHF", ship_number: "310", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54843" },
{ operator_icao_code: "WJA", registration_name: "C-FCTK", ship_number: "312", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54853" },
{ operator_icao_code: "WJA", registration_name: "C-GAMQ", ship_number: "311", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "eX2", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54854" },
{ operator_icao_code: "WJA", registration_name: "C-GDDR", ship_number: "313", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "eX2,eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54861" },
{ operator_icao_code: "WJA", registration_name: "C-GUDH", ship_number: "901", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "54860" },
{ operator_icao_code: "WJA", registration_name: "C-GURP", ship_number: "902", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "54864" },
{ operator_icao_code: "WJA", registration_name: "C-FHCM", ship_number: "315", type_subtype: "737-MAX8", system_assigned: "eXConnect,eXW", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "54862" },
{ operator_icao_code: "WJA", registration_name: "C-GUDO", ship_number: "903", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "54863" },
{ operator_icao_code: "WJA", registration_name: "C-GKKN", ship_number: "905", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "54858" },
{ operator_icao_code: "WJA", registration_name: "C-GYRS", ship_number: "906", type_subtype: "787-9", system_assigned: "eX3,eXConnect,eXW", system_reported: "eX3,eXConnect", antenna: "SPA2: SPA", antenna_gen: "GEN3",xid: "54882" },
{ operator_icao_code: "WSW", registration_name: "C-GDMP", ship_number: "838", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51341" },
{ operator_icao_code: "WSW", registration_name: "C-FPLS", ship_number: "839", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51342" },
{ operator_icao_code: "WSW", registration_name: "C-FONK", ship_number: "841", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51343" },
{ operator_icao_code: "WSW", registration_name: "C-GXRW", ship_number: "840", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51344" },
{ operator_icao_code: "WSW", registration_name: "C-FLBV", ship_number: "842", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51345" },
{ operator_icao_code: "WSW", registration_name: "C-FYBK", ship_number: "843", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51346" },
{ operator_icao_code: "WSW", registration_name: "C-FLSF", ship_number: "845", type_subtype: "737-800", system_assigned: "eXConnect,eXW", system_reported: "eXConnect", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51347" },
{ operator_icao_code: "WSW", registration_name: "C-FYPB", ship_number: "846", type_subtype: "737-800", system_assigned: "eXConnect,eXW-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51348" },
{ operator_icao_code: "WSW", registration_name: "C-GNDG", ship_number: "847", type_subtype: "737-800", system_assigned: "eXW-eXConnect", system_reported: "", antenna: "SPA: SPA", antenna_gen: "GEN3",xid: "51349" },
{ operator_icao_code: "YZR", registration_name: "YQ606", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "YZR", registration_name: "YQ610", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "YZR", registration_name: "B-1391", ship_number: "", type_subtype: "737-800", system_assigned: "eXO", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "YZR", registration_name: "B-20AF", ship_number: "", type_subtype: "787-9", system_assigned: "eX3", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
{ operator_icao_code: "", registration_name: "B-9962", ship_number: "", type_subtype: "320-214", system_assigned: "eFX", system_reported: "", antenna: "", antenna_gen: "",xid: "" },
]

import React                from 'react';
import { Auth, API, Logger } from 'aws-amplify';
import { makeStyles }       from '@material-ui/core/styles';
import Tooltip              from '@material-ui/core/Tooltip';
import Hidden               from '@material-ui/core/Hidden';
import IconButton           from '@material-ui/core/IconButton';
import Popover              from '@material-ui/core/Popover';
import Grid                 from '@material-ui/core/Grid';
import Box                  from '@material-ui/core/Box';
import Divider              from '@material-ui/core/Divider';
import CircularProgress     from '@material-ui/core/CircularProgress';

import CardIcon             from '../core/CardIcon';

import AppsIcon             from '@material-ui/icons/Apps';
import UserIcon             from '@material-ui/icons/PersonOutlined';
import NotificationsIcon    from '@material-ui/icons/NotificationsOutlined';
import SettingsIcon         from '@material-ui/icons/SettingsOutlined';
import { getResizedImageUrl }  from '../core/PlatformCloudbar';



const logger = new Logger('MenuApps');

const useStyles = makeStyles(theme => ({
    grid_container: {
        width: 360,
        //border: "solid 1px red",
        overflow: "hidden"
        
    }
}));



export default function MenuApps(props) {
    logger.debug('props', props);
    
    const classes = useStyles();
    
    const menuId = 'popover-apps-menu';
  
    
    const [apps, setApps]   = React.useState(null);
    const [anchorEl, setAnchorEl]       = React.useState(null);
    
    const handleMenuClose = () => {
        setAnchorEl(null);
    }; 

    const handleMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };
    
    
    
    const fetchUserApplications = async () => {
   
        let user = await Auth.currentAuthenticatedUser();
        logger.debug('fetchUserApplications username', user.username)
    
        let path = '/users/' +  user.username + '/apps';
    
        let currentSession = await Auth.currentSession();
        let headers = {
            Authorization: currentSession.idToken.jwtToken
        };
    
    
        let queryStringParameters = Object.assign({}, { offset: 0, limit: 18 });
    
        let options = { headers: headers, queryStringParameters: queryStringParameters };
    
    
         logger.debug('SIDEBAR APPS FETCHING OPTIONS: ', options);
    
        try {
            return await API.get('TenantManagementServiceAPI', path, options);
            
        }
        catch (e) {
             logger.debug('SIDEBAR APPS FETCHING ERROR: ', options);
            logger.error(e);
        }
    
    
    };
    
    const getAppsRow = (items) => {
        logger.debug('getAppsRow', items);
        if (items.length) {
            return (
                <Grid container key={"grid_container_" + items[0].id}>
                    {
                        items.map((item) => {
                            
                            return (
                                <Grid item key={item.id}>
                                    <CardIcon 
                                        text={item.name} 
                                        icon={<embed src={getResizedImageUrl(item)} type="image/png" height="32" />} 
                                        link={item['app-link']}
                                    />
                                </Grid>
                            )
                        })    
                    }
                </Grid>
            )
        }
    }
    
    var apps_jsx;
    
    if (apps) {
        var app_jsx;
        logger.debug("state.apps",apps);
        let items = []
        let contents = []
        logger.debug("apps.Items", apps.Items);            
        apps.Items.map((app, index) => {
            
            items.push(app);
            if (items.length == 3) {
                contents.push(getAppsRow(items));
                items = [];
            }
        })
        
    
        if (items) contents.push(getAppsRow(items));
    
    
       
        apps_jsx = (
             <React.Fragment>
                <Divider/>
                {
                    contents.map((content) => {
                        return content;
                    })
                }
            </React.Fragment>
        )
    }
    else {
        fetchUserApplications().then((result) => {
            setApps(result);
            logger.debug("fetchUserApplications result", result);
        }, reason => {
            logger.debug("error", reason);
            window.location.reload(); // error reloads page, and auth redirect kicks
        });
        apps_jsx = (
            <React.Fragment>
                <Divider/>
                <Grid container alignItems="center" className={classes.grid_container}>
                    <Grid item  className={classes.grid_item}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
    
    return (
        <React.Fragment>
            {/* <Hidden xsUp> */}
                <Tooltip title="Applications"> 
                    <IconButton
                        color="inherit"
                        aria-label="menu"
                        onClick={handleMenuOpen}
                        aria-controls={menuId}
                        aria-haspopup="true"
                    >
                        <AppsIcon />
                    </IconButton>
                </Tooltip>
            {/* </Hidden>*/}
            <Popover
                PaperProps = {{square: true}}
                className={classes.popover}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <Grid container alignItems="center" className={classes.grid_container}>
                    <Grid item>
                        <CardIcon 
                            text="Profile" 
                            icon={<UserIcon fontSize="large" />} 
                            link="https://portal.nextcloud.aero/app/profile"
                        />
                    </Grid>
                    <Grid item>
                        <CardIcon 
                            text="Notifications" 
                            icon={<NotificationsIcon fontSize="large" />} 
                            link="https://portal.nextcloud.aero/app/notifications"
                        />
                    </Grid>                    
                    <Grid item>
                        <CardIcon 
                            text="Settings" 
                            icon={<SettingsIcon fontSize="large" />} 
                            link="https://portal.nextcloud.aero/app/settings"
                        />
                    </Grid>
                </Grid>
               {apps_jsx}
            </Popover>
        </React.Fragment>
    )
}
import { Logger } from 'aws-amplify';
import LogsService    from '../services/LogsService';

const logger = new Logger('DataLogs');
const logs_service = new LogsService();

function setData(date, operator, registration_name, log_type, keys, charts) {
   
    logger.debug('getData', date, log_type, keys);
    
    //var end_timestamp = (new Date(date).getTime())+86400000;
    var data = []
    logs_service.fetchRecords({
        operator: operator, 
        tail: registration_name,
        log_type: log_type,
        log_date: date 
    }).then(result => {
        logger.debug("datalogs.then", result);
        for (var i=0; i < result.data.length; i++) {
            //logger.debug('records', result.data[i]);
            const entries = Object.entries( result.data[i] );
            var timestamp = (new Date(result.data[i]['log_date']).getTime());
            logger.debug("timestamp", result.data[i]['log_date'], timestamp);
            for (const entry of entries) {
                //logger.debug('entry', entry, timestamp);
                if (keys[entry[0]] !== undefined) {
                    if (!data[entry[0]]) data[entry[0]] = []
                    if (!isNaN(entry[1]))
                        data[entry[0]].push( [ timestamp, +entry[1]]);
                }
            }
        }  
        logger.debug('data',data);
        const key_entries = Object.entries(keys);
        for (const entry of key_entries) {
            if (data[entry[0]]) {
                //data[entry[0]].push( [ end_timestamp, 0 ]);
                charts[entry[1]].series[0].setData(data[entry[0]]); 
            }
        }
    })
   
}


export { setData }


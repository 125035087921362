import React    from 'react';
import Button   from '@material-ui/core/Button';

export default function ButtonFilter(props) {

    function buttonAction() {
        var instance = props.gridApi.getFilterInstance(props.field);
        instance.setModel(props.model);
        instance.applyModel();
        props.gridApi.onFilterChanged();
    };

    return (
        <Button variant="outlined" size="small" color="primary" onClick={() => buttonAction()}>
            {props.children}
        </Button>
    )
}
import React, {useState} from 'react';

export default function Clock(props) {
    const [UTCTime, setUTCTime] = useState(); // move to a separate component to not refresh state 
    
    
    function updateUTCTime() {
        
        var date = new Date();
        var utcTime = date.getUTCFullYear() + '-' + (date.getUTCMonth()+1).toString().padStart(2, '0') + '-' + 
                date.getUTCDate().toString().padStart(2, '0') + ' ' + date.getUTCHours().toString().padStart(2, '0') + ':' + 
                date.getUTCMinutes().toString().padStart(2, '0') + ':' + date.getUTCSeconds().toString().padStart(2, '0') + ' UTC';
        setUTCTime(utcTime);
    }
    
    if (!UTCTime) {
        updateUTCTime();
        setInterval(updateUTCTime, 1000);
    }
    
    return UTCTime;
}
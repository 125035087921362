import React, { useEffect, useContext, useState }    from 'react';
import { Logger }           from 'aws-amplify';
import { makeStyles }       from '@material-ui/core/styles';
import Tooltip              from '@material-ui/core/Tooltip';
import Button               from '@material-ui/core/Button';
import TextField            from '@material-ui/core/TextField'
import IconSelect           from '@material-ui/icons/Reorder'
import IconReset            from '@material-ui/icons/ZoomOut'
import Grid                 from '@material-ui/core/Grid';
import Typography           from '@material-ui/core/Typography';
import Highcharts           from 'highcharts';
import HighChart            from 'components/HighCharts/HighChart';
import MenuMultiSelect      from 'components/MenuMultiSelect';
import DatePicker           from 'components/DatePicker';
import SelectOperator       from 'components/SelectOperator';
import SelectAircraft       from 'components/SelectAircraft';

import { setData }          from 'data/DataLogs';

import FormContext      from 'pac-responsive-ui-framework/forms/FormContext';

const logger = new Logger('components/Timeline');

const useStyles = makeStyles(theme => ({
   
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 120
    },
    chart: {
        minHeight: 100,
        height: 100
    }
}));

for (var i = 0; i < Highcharts.charts.length; i = i + 1) {
    Highcharts.charts[i].destroy();
}




export default function Timeline(props) {
    
    const classes = useStyles();
    
    var zIndex = 100;
    
    var date = props.date;

    const [operator, setOperator] = useState(props.operator);
    const [aircraft, setAircraft] = useState(props.aircraft);

    const updateState = (key, event, value, reason) => {
        logger.debug(event, value, reason);
        if (key === 'operator_icao_code') {
            if (value === null) {
                setOperator(null);
            }
            else {
                setOperator(value.operator_icao_code);
            }
            setAircraft(null);
        }
        else if (key === 'registration_name') {
            if (value === null) {
                setAircraft(null);
            }
            else {
                setAircraft(value.registration_name);
            }
        }
    }
    
    
    Highcharts.Pointer.prototype.reset = function () {
        return undefined;
    };
    
    Highcharts.Point.prototype.highlight = function (event) {
        event = this.series.chart.pointer.normalize(event);
        this.onMouseOver(); // Show the hover marker
        this.series.chart.tooltip.refresh(this); // Show the tooltip
        this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
    
   
    
    const setDate = (date) => {
        logger.debug('setDate1', date, Highcharts.charts);
        var log_types =[]
        var keys = {}
        for (var i=0; i < props.chartList.length; i++) {
            if (log_types.indexOf(props.chartList[i].log_type) === -1) {
                log_types.push(props.chartList[i].log_type);
            }
            if (!keys[props.chartList[i].log_type]) keys[props.chartList[i].log_type] = {}
            keys[props.chartList[i].log_type][props.chartList[i].key] = i;
        }
        for (i=0; i < log_types.length; i++) {
            //setData(date, operator, registration_name, log_types[i], keys[log_types[i]], Highcharts.charts);
        }
        resetCharts();
    }
    
    const updateCharts = (e, current_chart) => {
        var chart,
            point,
            i,
            event;

        for (i = 0; i < Highcharts.charts.length; i = i + 1) {
            chart = Highcharts.charts[i];
            if (chart && current_chart !== chart) {
                // Find coordinates within the chart
                event = chart.pointer.normalize(e);
                // Get the hovered point
                point = chart.series[0].searchPoint(event, true);
                if (point) {
                    point.highlight(e);
                    
                }
               
            }        
        }
    };
    const resetCharts = () => {
        logger.debug('resetCharts');
        var chart,
            i;
        for (i = 0; i < Highcharts.charts.length; i = i + 1) {
             chart = Highcharts.charts[i];
             if (chart) chart.xAxis[0].setExtremes();
        }
    }
   
    
    
    
    const syncZoom = (zoom_event) =>  {
       // logger.debug('syncZoom', Highcharts.charts.length);
        var chart, i;
        for (i = 0; i < Highcharts.charts.length; i = i + 1) {
            
            chart = Highcharts.charts[i];
            if (chart && chart !== zoom_event.target.chart) {
                //if ($(chart.container).attr('id') != $(zoom_event.target.chart.container).attr('id')) {

                var extremes = chart.xAxis[0].getExtremes();
                if (zoom_event.min !== extremes.min || zoom_event.max !== extremes.max) {
                    chart.xAxis[0].setExtremes(zoom_event.min, zoom_event.max, true, true, {invokedByDARTSyncManually: true});
                }
            }
        }
    }
    
    var i, chart_list = [];
    for (i = 0; i < props.chartList.length; i++) {
         chart_list.push({
            title: props.chartList[i].log_name + ": " + props.chartList[i].series_label,
            color: props.chartList[i].color,
            checked: props.chartList[i].visible,
            myRef: React.createRef()
        })
    }
    
    const setChartList = (value) => {
        logger.debug("setChartList", value);
        var chart_count = 0;
        for (i = 0; i < value.length; i++) {
            if (value[i].checked) {
                chart_count++;
            }
        }
        logger.debug('setChartList', chart_count);
        for (i = 0; i < value.length; i++) {
            if (value[i].checked) {
                value[i].myRef.current.style.display = "block";
            }
            else {
                value[i].myRef.current.style.display = "none";
            }
        
            if (chart_count === 1) {
                value[i].myRef.current.style.height = "480px";
            }
            else if (chart_count === 2) {
                value[i].myRef.current.style.height = "240px";
            }
            else if (chart_count === 3) {
                value[i].myRef.current.style.height = "160px";
            }
            else if (chart_count === 4) {
                value[i].myRef.current.style.height = "120px";
            }
            else {
                value[i].myRef.current.style.height = "100px";
            }
        }
        //refresh all the charts so they rezize
        for (i = 0; i < Highcharts.charts.length; i = i + 1) {
            Highcharts.charts[i].reflow();
        }
        
        chart_list = value;
    }
    
    var charts = [];
    logger.debug("defining charts", operator, aircraft);
    if (operator && aircraft) {
    
    for (i = 0; i < props.chartList.length; i++) {

        charts.push(
            <div className={classes.chart} ref={chart_list[i].myRef} key={props.chartList[i].log_type + ':' + props.chartList[i].key}>
                <HighChart 
                    key={props.chartList[i].log_type + ':' + props.chartList[i].key}
                    logType={props.chartList[i].log_type}
                    metricKey={props.chartList[i].key}
                    metricName={props.chartList[i].series_label}
                    metricUnit={props.chartList[i].series_unit}
                    title={props.chartList[i].log_name + ': ' + props.chartList[i].series_label}
                    date={date}
                    updateChartsCallback={updateCharts}  
                    afterSetExtremes={syncZoom} 

                    options={{ 
                    plotOptions: { 
                        series: { 
                            color: props.chartList[i].color,  
                            name: props.chartList[i].series_label
                        }
                     }
                    }} 
                    zIndex = {zIndex}
                />
            </div>
        );
        zIndex--;
    }   
    }

     useEffect(() => {
        setDate(props.date);
      }, [])
      
             
    return (
        <React.Fragment>
            <FormContext.Provider value = {{
                updateState: updateState
            }}>
                <div style={{border: "solid 1px black", paddingLeft:"16px"}}>
                    <Typography>Controls</Typography>
                    <DatePicker label="Date" onChange={setDate} date={props.date}/>            
                <MenuMultiSelect 
                    variant="contained" 
                    tooltip="Select charts to view" 
                    items={chart_list}
                    onChange={setChartList}
                >
                    <IconSelect/>
                </MenuMultiSelect>
                <Tooltip title="Reset Zoom">
                    <Button variant="contained" onClick={resetCharts}>
                        <IconReset />
                    </Button>
                </Tooltip>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                        <Grid item xs={12}>
                            <SelectOperator value={operator} />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectAircraft operator={operator} value={aircraft}/>
                        </Grid>
                    </Grid>
                </div>
            </FormContext.Provider>
           
            
            <div style={{height: "400px", overflow: "scroll"}}>
            {charts}
            </div>
           
        </React.Fragment>
    );
}
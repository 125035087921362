import React, { useEffect, useContext } from 'react';
import { Logger }                   from 'aws-amplify';

import Tooltip from '@material-ui/core/Tooltip';
import FilterIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';

/*
import Grid from '@material-ui/core/Grid';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker
   
} from '@material-ui/pickers';
*/

import OperatorSelect   from '../../components/FormElements/Select/Operator';

import AppContext       from 'pac-responsive-ui-framework/core/AppContext';

const logger = new Logger('FormFilter/Operator');

/**
 *  Props:
 *      start_date
 *      end_date
 *      operator JSON object containing operator_name, operator_icao
 *      aircraft JSON object containing registration_name, ship_number, system, type, etc. 
 */ 
export default function Operator(props) {
    logger.debug("props", props);
    const app_context = useContext(AppContext);
    var props_operator;
    var operator_set = false;
    if (props.state.operator_icao_code) {
      
        if (app_context.state.cache && app_context.state.cache.operators) {
            logger.debug("operator_cache", app_context.state.cache.operators);
            app_context.state.cache.operators.data.forEach(function(value, key) {
                if (value.operator_icao_code == props.state.operator_icao_code) {
                    props_operator = value;
                }
            });
        }
        
        if (!props_operator)
            props_operator = {operator_icao_code: props.state.operator_icao_code};
        
        logger.debug("setting operator1", props_operator);
    }
    /*
    else if (app_context.state.default_operator_icao_code && app_context.state.default_operator_icao_code != "---") {
         props_operator = {operator_icao_code: app_context.state.default_operator_icao_code};
         operator_set = true;
    }
    */
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [operator, setOperator] = React.useState(props_operator);
    const [wasClosed, setWasClosed] = React.useState(false);
    const [operatorSet, setOperatorSet] = React.useState(operator_set);
    
    

    const operatorLabel = (option) => {
        if (option.operator_icao_code && option.operator_name) {
             return option.operator_icao_code + ' - ' + option.operator_name;
        }
        else if (option.operator_icao_code) {
             return option.operator_icao_code;
        }
        else {
            return option.operator_name;
        }
    };
   
  
    
    var invalidState = false;
    if (!operator) invalidState = true;
    
   
    
        var found = false;
    var operator_text;
    if (props.state.operator) {  
        operator_text = "Operator: " + operatorLabel(props.state.operator);
        found = true;
    }
   
   
    
    var header_text= <React.Fragment>&larr; Select your operator here</React.Fragment>;
    if (found) {
         header_text= <React.Fragment>{operator_text}</React.Fragment>;
    }
   
    const handleOpen = (event) => {
        logger.debug('handleOpen', event);
        setAnchorEl(event.currentTarget);
    };

    const handleCancel = () => {
        logger.debug('handleCancel');
        //setStartDate(props.state.start_date);
        //setEndDate(props.state.end_date);
        //setOperator({operator_icao_code: props.state.operator});
        //setAircraft(props.state.aircraft);
        setWasClosed(true);
        setAnchorEl(null);
    };
    
    const handleSave = () => {
        props.onChange({operator: operator});
        setWasClosed(true);
        setAnchorEl(null);
    };
    

     const handleOperatorChange = (value) => {
        logger.debug("handleOperatorChange", value);
        setOperator(value.target.value);
        setOperatorSet(true);
    };
    

    var anchorRef = React.createRef();
    
    useEffect(() => {
        if (wasClosed == false)
            setAnchorEl(anchorRef.current);
    });
    
    useEffect(() => {
        console.log(operator, '- Has changed')
    },[operator]) 
    
    
    var open = Boolean(anchorEl);
    logger.debug("anchorEl", anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    return (
   
        <React.Fragment>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <Tooltip title="Data Filters">
                                <IconButton onClick={handleOpen}>
                                    <FilterIcon ref={anchorRef}/>
                                </IconButton>
                            </Tooltip>
                        </td>
                        <td>
                            {header_text}
                        </td>
                    </tr>
                </tbody>
            </table>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCancel}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                
            >
            <div style={{padding: "16px", width: "400px"}}>
                <OperatorSelect 
                    handleChange={handleOperatorChange} 
                    defaultValue={operatorSet? operator : props_operator} 
                    options={operator ? [operator] : null}
                    required
                />
            </div>
            <Grid container justifyContent="flex-end">
                <div style={{padding: "8px"}}>
                <Button variant="contained" color="primary" onClick={handleSave} disabled={invalidState}>Submit</Button>
                </div>
            </Grid>
            <div style={{paddingLeft: 8, paddingBottom: 8}} > * required</div>
      </Popover>

    </React.Fragment>
    
  );
}
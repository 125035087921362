import React from 'react';
import { Logger }                   from 'aws-amplify';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  //KeyboardDateTimePicker
   
} from '@material-ui/pickers';


const logger = new Logger('FormElements/DateTime');

export default function DateAndTimePickers(props) {
  logger.debug('props', props)
  
    const [date, setDate] = React.useState(props.defaultValue);  //note that both values can take a date time string
    const [time, setTime] = React.useState(props.defaultValue);
  
    const handleDateChange = (event) => {
      logger.debug("handleDateChange", event);
      event = String(event);
      logger.debug("handleDateChange", event);
      if (event === "Invalid Date" || event === "null") return;
      var d = new Date(event.substr(0, 25) + " GMT-0000"); // have to convert time to UTC
      
      var strTime = "00:00:00";
      if(time) {
        strTime = time.substr(11, 8);
      }
      var new_date = d.toISOString().substr(0, 10) + ' ' + strTime;
      logger.debug("handleDateChange", new_date);
      setDate(new_date);
      props.onChange(new_date);
    };
  
    const handleTimeChange = (event) => {
      logger.debug("handleDateChange", event);
      event = String(event);
      logger.debug("handleDateChange", event);
      if (event === "Invalid Date" || event === "null") return;
      var d = new Date(event.substr(0, 25) + " GMT-0000"); // have to convert time to UTC
      
      var new_date = date.substr(0, 10) + ' ' + d.toISOString().substr(11, 8);
      logger.debug("handleDateChange", new_date);
      setTime(new_date);
      props.onChange(new_date);
      
    };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{display: "inline-block", width: "170px"}}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="yyyy-MM-dd"
          margin="normal"
          id={"date_" + props.id}
          label={props.dateLabel}
          value={props.defaultValue}
          onChange={handleDateChange}
          maxDate={props.maxDate}
          minDate={props.minDate}
          maxDateMessage={props.maxDateMessage}
          minDateMessage={props.minDateMessage}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          error={props.error}
          helperText={props.helperText} 
          required={props.required}
          //autoOk={true}
          /*
          popoverProps={{ // this does not work
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            }
          }}
          */
        />
      </div>
      <div style={{display: "inline-block", width: "155px"}}>
        <KeyboardTimePicker
          disableToolbar
          variant="inline"
         // format="yyyy-MM-dd"
          margin="normal"
          id={"time_" + props.id}
          label={props.timeLabel}
          value={props.defaultValue}
          onChange={handleTimeChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          ampm={false}
          autoOk={true}
          required={props.required}
          disabled={!date}
        />
        </div>
    </MuiPickersUtilsProvider>
  );
}
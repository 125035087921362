import React, {useContext}  from 'react';
import { constants } from 'assets/Config.js';
import { Link }             from 'react-router-dom';
import { makeStyles }       from '@material-ui/core/styles';
import Popover              from '@material-ui/core/Popover';
import MenuItem             from '@material-ui/core/MenuItem';
import ListItemIcon         from '@material-ui/core/ListItemIcon';
import Divider              from '@material-ui/core/Divider';
import Tooltip              from '@material-ui/core/Tooltip';
import Hidden               from '@material-ui/core/Hidden';
import Button               from '@material-ui/core/Button';
import Box                  from '@material-ui/core/Box';

import AccountIcon          from '@material-ui/icons/Person';
import SignoutIcon          from '@material-ui/icons/ExitToApp';
import ArrowDropDownIcon    from '@material-ui/icons/ArrowDropDown';
import HomeIcon             from '@material-ui/icons/Home';
import HelpIcon             from '@material-ui/icons/Help';
import FeedbackIcon         from '@material-ui/icons/Feedback';
import InfoIcon             from '@material-ui/icons/Info';



import MenuOperators        from './MenuOperators';
import MenuDateRange        from './MenuDateRange';


import AppContext           from 'pac-responsive-ui-framework/core/AppContext';
import { Logger }           from 'aws-amplify';


const logger = new Logger('MenuUser');

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary[800]
  }
  
}));

export default function MenuUser(props) {
   var app_context = useContext(AppContext);
   const classes = useStyles();
  
  const userMenuId = 'popover-user-menu';
  
    
  const [userAnchorEl, setUserAnchorEl] = React.useState(null);
    
  const isUserMenuOpen = Boolean(userAnchorEl);
    
  const handleUserMenuClose = () => {
    setUserAnchorEl(null);
  }; 

  const handleUserMenuOpen = event => {
    setUserAnchorEl(event.currentTarget);
  };
  
  const handleOperatorChange = (value) => {
    app_context.updateOperator(value);
  };
  
  const handleDateRangeChange = (value) => {
    logger.debug("handleDateRangeChange", value);
    app_context.updateDateRange(value);
  };
  
  var hidden1 = { 'mdUp': true};
  var hidden2 = { 'smDown': true};
  if (props.context_menu) {
    hidden1 = { 'lgUp': true};
    hidden2 = { 'mdDown': true};
  }
  
  var profile;
  if (constants.profile_page) {
    profile = 
      <React.Fragment>
        <MenuItem component={Link} to="/profile" onClick={handleUserMenuClose}>
          <ListItemIcon>
            <AccountIcon />
          </ListItemIcon>
          Profile
        </MenuItem>
      </React.Fragment>
  }
  
  var about=(
          <React.Fragment>
            <Divider />
            <MenuItem component={Link} to="/about" onClick={handleUserMenuClose}>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                About {constants.title}
              </MenuItem>
          </React.Fragment>
        )

  var faq;
  if (constants.faq_url) {
        faq=(
          <React.Fragment>
            <Divider />
            <MenuItem  
                  component={Link} 
                  to={{ pathname: constants.faq_url }} 
                  target="_blank" 
                  onClick={handleUserMenuClose}
            >
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              FAQ
            </MenuItem>
          </React.Fragment>
        )
  }
  
  var feedback;
  if (constants.feedback_url) {
     feedback=(
          <React.Fragment>
                 <Divider />
               <MenuItem component={Link} to={constants.feedback_url} onClick={handleUserMenuClose}>
                <ListItemIcon>
                  <FeedbackIcon />
                </ListItemIcon>
                Feedback
              </MenuItem>
            </React.Fragment>
        );
  }

  var date_range_menu1;
  var date_range_menu2;
  
  
     
  date_range_menu1 =
    <Hidden only="xs">
      <MenuDateRange 
        startDate={app_context.state.start_date}
        endDate={app_context.state.end_date}
        onChange={handleDateRangeChange}
        tooltip="Select your working date range"
        alwaysShowDate={false}
      />
    </Hidden>;
  
  date_range_menu2 = 
    <Hidden smUp>
      <Box pl={2}>
         <MenuDateRange 
          startDate={app_context.state.start_date}
          endDate={app_context.state.end_date}
          onChange={handleDateRangeChange}
          tooltip="Select your working date Range"
          alwaysShowDate={true}
        />
      </Box>
      <Divider />
    </Hidden>;
        
  var operator_menu1;
  var operator_menu2;
  logger.debug("default_operator_icao_code", app_context.state.user, app_context.state.user.payload.pac_tenant_name);
  if (app_context.state.user.payload.pac_tenant_name == "PanaSky") { // match to PAC Users
    logger.debug("default_operator_icao_code", app_context.state.default_operator_icao_code);
    var operator_menu = 
      <MenuOperators 
          operator={app_context.state.default_operator_icao_code} 
          onCloseCallback={handleOperatorChange}
          tooltip="Select your working operator"
      />
      operator_menu1 =
        <Hidden only="xs">
          {operator_menu}
        </Hidden>
      
      operator_menu2 = 
        <Hidden smUp>
          <Box pl={2}>
            {operator_menu}
          </Box>
          <Divider />
        </Hidden>
  }
  
  return (
    
          <React.Fragment>
            
            <div>
            {props.context_menu}
            </div>
            <div> 
            {date_range_menu1}
            {operator_menu1}
              
              <Hidden {...hidden1}>
                <Tooltip  title={app_context.state.user.payload.pac_first_name + ' ' + app_context.state.user.payload.pac_last_name }> 
                  <Button 
                    variant="text"
                    color="inherit"
                    className={classes.button}
                    startIcon={<AccountIcon />}
                    endIcon={<ArrowDropDownIcon/>}
                    onClick={handleUserMenuOpen}
                    aria-haspopup="true"
                  > </Button>
                </Tooltip>
              </Hidden>
              <Hidden {...hidden2}>
                <Button 
                  variant="text"
                  color="inherit"
                  className={classes.button}
                  startIcon={<AccountIcon />}
                  endIcon={<ArrowDropDownIcon/>}
                  onClick={handleUserMenuOpen}
                  aria-haspopup="true"
                >
                   {app_context.state.user.payload.pac_first_name + ' ' + app_context.state.user.payload.pac_last_name } 
                </Button>
              </Hidden>
              
            </div>
            {/*  USER POPUP */}
            
            <Popover
              anchorEl={userAnchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              id={userMenuId}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={isUserMenuOpen}
              onClose={handleUserMenuClose}
            >
            {date_range_menu2}
            {operator_menu2}
            
              <MenuItem component={Link} to="/" onClick={handleUserMenuClose}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                Home
              </MenuItem>
              {profile}
              {about}
              {faq}
              {feedback}
              <Divider />
             
              <MenuItem component={Link} to="/signout" onClick={handleUserMenuClose}>
                <ListItemIcon>
                  <SignoutIcon/>
                </ListItemIcon>
                Sign Out
              </MenuItem>
            </Popover>
            
          </React.Fragment>    
  
    );
}
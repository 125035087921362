import React from 'react';
import { Logger } from 'aws-amplify';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const logger = new Logger('FormElements/MultiSelect');

export default function MultiSelect(props) {
  
  var options_init = props.defaultValue;

  if (props.data) {
    logger.debug("options_init1", props);
    //options_init = props.data[props.field];
    options_init = props.getInitValue(props.data, props.options);
    logger.debug("options_init2", options_init);
    
    
    /*
    var init_value = props.data[props.field];
    if (props.defaultValue) init_value = props.defaultValue;
    
    if (init_value) {
      var top_value = 65536;
      while (top_value >= 1) {
        if (init_value >= top_value) {
          options_init.push(props.options[props.options.findIndex(obj => obj.id === top_value)]);
          init_value = init_value - top_value;
        }
        top_value = top_value /2;
      }
      logger.debug(init_value);
    }
    */
    
  }
  /*
  else {
    logger.debug("options_init2", props.defaultValue);
  
    options_init = [];
    if (props.defaultValue) {
      options_init = props.defaultValue;
      //for (const val of props.defaultValue) {
        //options_init.push(props.options[val]);
      //}
    }
   
  }
  */
  
  logger.debug("props", props);

  const [open, setOpen]             = React.useState(false);
  const [options, setOptions]       = React.useState([]);
  const [value, setValue]           = React.useState(options_init);
  const [inputValue, setInputValue] = React.useState("");
  const [loaded, setLoaded]         = React.useState(false);
  
  const loading = open && !loaded;
  
  
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (active) {
      if (props.options) {
        logger.debug("active1", props.options);
        setOptions(props.options);
        setLoaded(true);
      }
      else {
        logger.debug("active2", options_init);
        props.service.fetchRecords()
          .then((data) => {
            logger.debug("success", data);  
            //var new_options = [];
            //data['data'].forEach(e => {
            //  new_options.push(props.getValue(e));
            //});
            setOptions(data['data']);
            setLoaded(true);
          }).catch((err) => {
            logger.debug("error", err);
            setOptions([]);
          }); 
    
      }
    }

    return () => {
      active = false;
    };
  }, [loading]);
  
  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      multiple
      id="checkboxes-tags-demo"
      ref={props.ref}
      options={options}
      defaultValue={value}
      //inputValue={inputValue}
      onChange={(event, newValue) => {
        logger.debug('onChange', event, newValue);  
        props.handleChange({target: {name: props.name, value: newValue}});
      }}
    
      disableCloseOnSelect
      disabled={props.disabled===true?true:false}
      getOptionLabel={props.getOptionLabel?props.getOptionLabel:(option) => {}}
      renderOption={(option, { selected }) => {
        logger.debug("renderOption", option, selected);
        var rendered_option = props.renderOption(option);
        
        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {rendered_option}
          </React.Fragment>
      )}}
      style={props.style} //{{ display: 'inline-flex', width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} 
            inputProps={props.inputProps}
            InputLabelProps = {props.InputLabelProps}
            size={props.size}
            label={props.label}
            required={props.required}
            variant="outlined"
            //style={props.style}
            error={props.error}
            helperText={props.helperText}
            autoComplete="new-password"
            InputProps={{ ...params.InputProps, autoComplete: "new-password"}}
            placeholder={props.placeholder} />
      )}
    />
  );
}

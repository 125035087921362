import React            from 'react';
import { Logger }       from 'aws-amplify';
import Select           from '../Select';
import OperatorService  from '../../../services/OperatorService';


const logger = new Logger('FormElements/Select/Operator');

export default function Operator(props) {
    logger.debug("construtor", props);
    

    var defaultValue = props.defaultValue;
    var options = props.options;
    var service = new OperatorService();
      
    const renderOption = (option, state) => { 
        var operator_icao_code = '---';
        var src = "https://origins-assets.nextcloud.aero/operator-logos/logo----.png";
            
        if (option.operator_icao_code) {
            operator_icao_code = option.operator_icao_code;
            src = "https://origins-assets.nextcloud.aero/operator-logos/logo-" + option["operator_icao_code"].toLowerCase() + ".png";
        }
        var icon = <img 
                src={src} 
                style={{width: 24, height: 24, marginRight: 16 }} 
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src= "https://origins-assets.nextcloud.aero/operator-logos/logo----.png";
                }} 
            />;
        return <React.Fragment>
                {icon}
                { '[' + operator_icao_code + '] ' + option.operator_name }
            </React.Fragment>;
        
    };
    
    const optionLabel = (option) => { 
       // if (typeof option[0] == "undefined") {
        //    logger.debug("optionLabel returning nothing");
        //    return ""; 
        //}
        logger.debug('optionLabel', option);
        var operator_icao_code = '---';
        
        if (option.operator_icao_code) {
            operator_icao_code = option.operator_icao_code;
        }
        if (option.operator_name) {
            return  operator_icao_code + ' - ' + option.operator_name;
        }
        //else if (app_context.state.cache['operators']) {
            
        //}
        else return operator_icao_code;
    };
    
    return <Select
        key="operator" 
        id="operator" 
        name="operator" 
        field="operator_icao_code" 
        label="Operator"
        variant="standard"
        defaultValue={defaultValue}
        data={props.data}  
        required={props.required} 
        helperText={props.helperText}
        service={service}
        handleChange={props.handleChange}
        error={props.error}
        options={options}
        getOptionLabel={ optionLabel }
        renderOption={renderOption}
        //style={{paddingRight:20, paddingBottom: 10, width: "200px"}}
    />;
}
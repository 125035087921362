import BaseService from './BaseService';
import { Logger } from 'aws-amplify';

const logger = new Logger('MapService');

export default class MapService extends BaseService {

    constructor() {
        let apiName = 'OriginsAPI';
        let collectionPath = '/services/map/flightmap';
        super(apiName, collectionPath);
        
    }
    
    static parseMetadata = (data) => {
        return '' +
            '<table>' +
            '<tr><td style="text-align: right; padding-right: 8px; font-weight: bold">Flight ID: </td><td>' + data['FlightID'] + '</td></tr>' +
            '<tr><td style="text-align: right; padding-right: 8px; font-weight: bold">Operator: </td><td>' + data['Operator'] + '</td></tr>' +
            '<tr><td style="text-align: right; padding-right: 8px; font-weight: bold">Aircraft: </td><td>' + data['Tail'] + ' (' + data['AircraftType'] + '-' + data['AircraftSubType'] + ')</td></tr>' + 
            '<tr><td style="text-align: right; padding-right: 8px; font-weight: bold">Flight Number: </td><td>' + data['FlightNumber'] + '</td></tr>' +
            '<tr><td style="text-align: right; padding-right: 8px; font-weight: bold">Route: </td><td>' + data['Route'] + '</td></tr>' + 
            '<tr><td style="text-align: right; padding-right: 8px; font-weight: bold">Departure: </td><td>' + data['TakeoffTime'].substr(0, 16).replace("T", " ") + '</td></tr>' + 
            '<tr><td style="text-align: right; padding-right: 8px; font-weight: bold">Arrival: </td><td>' + data['LandingTime'].substr(0, 16).replace("T", " ") + '</td></tr>' + 
            '<tr><td style="text-align: right; padding-right: 8px; font-weight: bold">SLA Availability: </td><td>' + (data['SLAAvailability']?(Math.round(data['SLAAvailability'] * 10) / 10) + '%</td></tr>' : "n/a") + 
            '<tr><td style="text-align: right; padding-right: 8px; font-weight: bold">Raw Availability: </td><td>' + (data['RAWAvailability']?(Math.round(data['RAWAvailability'] * 10) / 10) + '%</td></tr>' : "n/a") +
            '</table>' +
            '<div style="border: none; padding: 4px; margin-top: 18px">' +
            '<a href="https://dart.panasonic.aero/dart2/gcs_flight_status.php?operator_icao_code='+ data['Operator'] + '&operator_icao_code_formelementexists=1&registration_name='+ data['Tail'] + '&registration_name_formelementexists=1&flight_sector=' + data['FlightID'] + '&flight_sector_formelementexists=1&gcs_only=1&form=flight-select&opsel_action=Submit" target="_lowperform">[DART] Flight Status</a> ↗' +
            //'<a href="https://origins.nextcloud.aero/dashboards/Low%20Performing%20Flight%20Analysis?Tail='+ data['Tail'] +'" target="_lowperform">Launch Low Performing Flight Analysis Dashboard</a>' +
            '</div>' +
            //'<div style="border: none; padding: 4px; margin-top: 4px">' +
            //'<a onclick="details_callback()" href="#">Flight Details</a>' +
            //'</div>'
            "";
            
    }
    
    static getFlightName = (data) => {
        
        return data['FlightID'] + " | " + 
          data['Operator'] + ": " + data['Tail'] + " | " + 
          data['Route'] + " | " + 
          data['TakeoffTime'].substr(0, 16).replace("T", " ") + " | " +  
          (data['SLAAvailability'] ? (Math.round(data['SLAAvailability'] * 10) / 10) + "%":"");
    }
    
    static getTimestamp = (data) => {
        // get the timestamp
        var start = data.indexOf("timestamp: ") + 11;
        //var end = data.indexOf("<br>BeamNameFrom: ");
        //if (end == -1) 
        //    end = data.indexOf("<br>BeamName: ");
        
        var timestamp = data.substr(start, 10) + ' <b>' + data.substr(start + 10, 6) + '</b>';
        return timestamp;
    };
    
    
    static adjustGeometry = (geometry, min, max, shift_flight) => {
          logger.debug("adjustGeometry", min, max, shift_flight);
      
        if (Array.isArray(geometry)) {
          logger.debug("wrapGeometry double", geometry);
          var result = [];
          for (var i = 0; i < geometry.length; i++) {
            result.push(this.adjustGeometry(geometry[i], min, max, shift_flight));
          } 
          return result;      
        }
        else {
          var beam_left = 1000; //arbitrary location that will be overwritten on the first element
          var beam_right = -1000;
          for (var i = 0; i < geometry.coordinates[0].length; i++) {
            if (geometry.coordinates[0][i][0] < beam_left) beam_left = geometry.coordinates[0][i][0];
            if (geometry.coordinates[0][i][0] > beam_right) beam_right = geometry.coordinates[0][i][0];
          }
          
          for (i = 0; i < geometry.coordinates[0].length; i++) {
            geometry.coordinates[0][i][0] = geometry.coordinates[0][i][0]+shift_flight;
            if (beam_right -180 > max) {
              geometry.coordinates[0][i][0] = geometry.coordinates[0][i][0]-360;
            }
            if (beam_left +180  < min) {
              geometry.coordinates[0][i][0] = geometry.coordinates[0][i][0]+360;
            }
          }
          
          
          return geometry;
       
        }
    }

    static parseData = (data) => {
        logger.debug("parseData", data['data']);  
        const beam_switch_radius = 8;
        const event_radius = 6;
        
        var layers_checked = false;
        if (data['data'].length == 1) layers_checked = true;
        
        var result = [];
        var beam_collection = { 
            "type": "FeatureCollection",
            "properties": {
                "name": "Show/Hide Beam Coverage Areas",
                "flightSet": true,
                "checked": layers_checked
            },
            "features": []
        };
            
        var beam_switch_collection = { 
            "type": "FeatureCollection",
            "properties": {
                "name": "Show/Hide Flight Beam Switches",
                "flightSet": true,
                "checked": layers_checked,
                "key": "beam_switch_collection"
            },
            "features": []
        };
        
        var events_collection = { 
            "type": "FeatureCollection",
            "properties": {
                "name": "Show/Hide Flight Events",
                "flightSet": true,
                "checked": layers_checked,
                "key": "flight_event_collection"

            },
            "features": []
        };
            
        /*
        if (data['data'].length > 1) {
            beam_collection.properties.checked = false;
        }
        */
        
        var flight_collections = [];
        var big_beams = [];
        var small_beams = [];    
        var has_events = false;
        
        for (var i = 0; i < data['data'].length; i++) {
            var flight_id = data['data'][i]["flight_metadata"]['FlightID'];
            flight_collections.push(
                { 
                    "type": "FeatureCollection",
                    "properties": {
                        "name": this.getFlightName(data['data'][i]["flight_metadata"]),
                        "id": flight_id
                    },
                    "features": []
                }
            );
            
            // beam switches
            var beam_list = [];
            
            // Add the beams for the take off and landing points
            var start_beam = data['data'][i]['flight_start_end_markers'][0]['content'];
            logger.debug("beam_list start/end", start_beam);
            start_beam = start_beam.substr(start_beam.indexOf('BeamName: ') + 10);
            start_beam = start_beam.substr(0, start_beam.indexOf('<br>'));
            if (start_beam) beam_list.push(start_beam);
            var end_beam = data['data'][i]['flight_start_end_markers'][1]['content'];
            logger.debug("beam_list start/end", end_beam);
            end_beam = end_beam.substr(end_beam.indexOf('BeamName: ') + 10);
            end_beam = end_beam.substr(0, end_beam.indexOf('<br>'));
            if (end_beam && end_beam != start_beam) beam_list.push(end_beam);
            
   

            // shift any wrapping flights around
            var shift_flight = 0; //true if the flight goes over the 180'
            /*
            if (data['data'][i]['flight_metadata']['DepartureAirportLatLng'][1]) {
              logger.debug("landing marker", data['data'][i]['flight_start_end_markers'][1]);
              if (data['data'][i]['flight_start_end_markers'][1]['longitude'] < -180) shift_flight = 360;
            }
            */
            //if (Number(data['data'][i]['flight_metadata']['DepartureAirportLatLng'][1]) + Number(data['data'][i]['flight_metadata']['ArrivalAirportLatLng'][1]) > 100) shift_flight = -360;
            
            //if (data['data'][i]['flight_metadata']['FlightID'] == 23094455 )  shift_flight = -360;
            logger.debug("shift_flight", data['data'][i]['flight_metadata']['FlightID'], data['data'][i]['flight_metadata']['DepartureAirportLatLng'][1], data['data'][i]['flight_metadata']['ArrivalAirportLatLng'][1], shift_flight);
            
            // flight path
            var left_edge = 1000; //arbitrary location that will be overwritten on the first element
            var right_edge = -1000;
            if (data['data'][i]['flight_path']) {
                var new_flight_path = [];
                var last_long2 = null;
                for (var j = 0; j < data['data'][i]['flight_path'].length; j++) {
                    var properties = {"center": true,  "z-layer": 1 };
                    if (data['data'][i]['flight_path'][j]['connectivity_status'] == "offline") {
                        properties["stroke"] = "#cc0000";
                        
                    }
                    else if (data['data'][i]['flight_path'][j]['connectivity_status'] == "online") {
                        properties["stroke"] = "#00cc00";
                        
                    }
                    var collection = [];
                    collection.push({properties: properties, coordinates: []});
                    var collection_count = 0;
                    var last_long = null;
                  
                  
                    for (var k = 0; k < data['data'][i]['flight_path'][j]['coordinates'].length; k++) {
                      logger.debug("flight_path1", data['data'][i]['flight_path'][j]['coordinates'][k][1], last_long2,
                            data['data'][i]['flight_path'][j]['coordinates'][k][1] - last_long2);
                      
                      if (last_long2) {
                        if (data['data'][i]['flight_path'][j]['coordinates'][k][1] - last_long2 < -180) {
                          data['data'][i]['flight_path'][j]['coordinates'][k][1] += 360;
                        }
                        else if (data['data'][i]['flight_path'][j]['coordinates'][k][1] + last_long > 360) {
                         // data['data'][i]['flight_path'][j]['coordinates'][k][1] -= 360;
                        }
                      }
                      last_long2 = data['data'][i]['flight_path'][j]['coordinates'][k][1];
                      
                        // set the left and right edges of the flight
                        if (data['data'][i]['flight_path'][j]['coordinates'][k][1] < left_edge)
                          left_edge = data['data'][i]['flight_path'][j]['coordinates'][k][1];
                        if (data['data'][i]['flight_path'][j]['coordinates'][k][1] > right_edge)
                          right_edge = data['data'][i]['flight_path'][j]['coordinates'][k][1];
                      
                        if (last_long !== null && Math.abs(last_long - (data['data'][i]['flight_path'][j]['coordinates'][k][1])) > 180) {
                            logger.debug("flight path", "big diff", k, data['data'][i]['flight_path'][j]['coordinates'].length);
                            
                            collection_count++;
                            collection.push({properties: properties, coordinates: [
                                [data['data'][i]['flight_path'][j]['coordinates'][k-1][1] + shift_flight, data['data'][i]['flight_path'][j]['coordinates'][k-1][0]],
                                [data['data'][i]['flight_path'][j]['coordinates'][k][1] + shift_flight, data['data'][i]['flight_path'][j]['coordinates'][k][0]]
                              ]});
                            
                            collection_count++;
                            //[collection_count]['coordinates'].push([ data['data'][i]['flight_path'][j]['coordinates'][k][1] + shift_flight, data['data'][i]['flight_path'][j]['coordinates'][k][0]])
                        
                            collection.push({properties: properties, coordinates: []});
                        }
                       
                        collection[collection_count]['coordinates'].push([ data['data'][i]['flight_path'][j]['coordinates'][k][1] + shift_flight, data['data'][i]['flight_path'][j]['coordinates'][k][0]]);
                        //logger.debug("flight path", data['data'][i]['flight_path'][j]['coordinates'][k][1] + shift_flight, data['data'][i]['flight_path'][j]['coordinates'][k][0]);
                        
                        last_long = data['data'][i]['flight_path'][j]['coordinates'][k][1] + shift_flight;
                    }
                    logger.debug("flight path collection", collection);
                    for (k = 0; k < collection.length; k++) {
                     
                    
                      feature = {
                        "type": "Feature",
                        "properties": collection[k]['properties'],
                        "geometry": {  
                             "type": "LineString",  
                             "coordinates": collection[k]['coordinates']
                        },
                        
                      };
                       logger.debug("linestring", feature);
                      new_flight_path.push(feature);
                      flight_collections[i]["features"].push(feature);
                    }
                }
                logger.debug("flight path 2", data['data'][i]['flight_path'], new_flight_path);
            }
            
               if (data['data'][i]['beam_switch_markers']) {
                for (var j = 0; j < data['data'][i]['beam_switch_markers'].length; j++) {
                   
                  if (data['data'][i]['beam_switch_markers'][j]['longitude'] > right_edge + 100) 
                    data['data'][i]['beam_switch_markers'][j]['longitude'] -= 360;
                  else if (data['data'][i]['beam_switch_markers'][j]['longitude'] < left_edge - 100)
                    data['data'][i]['beam_switch_markers'][j]['longitude'] += 360;
                   
                    // get beam names to include them in the beam_list
                    // from beam
                    var start = data['data'][i]['beam_switch_markers'][j]['content'].indexOf("<br>BeamNameFrom: ") + 18;
                    var end = data['data'][i]['beam_switch_markers'][j]['content'].indexOf("<br>BeamNameTo: ");
                    var from_beam = data['data'][i]['beam_switch_markers'][j]['content'].substr(start, end-start);
                    // to beam
                    start = data['data'][i]['beam_switch_markers'][j]['content'].indexOf("<br>BeamNameTo: ") + 16;
                    end = data['data'][i]['beam_switch_markers'][j]['content'].indexOf("<br>beam_from: ");
                    var to_beam = data['data'][i]['beam_switch_markers'][j]['content'].substr(start, end-start);
                    //logger.debug("to_beam", start, end, end-start, to_beam, data['data'][i]['beam_switch_markers'][j]['content'],);
                    if (from_beam && !beam_list.includes(from_beam)) beam_list.push(from_beam);
                    if (to_beam && !beam_list.includes(to_beam)) beam_list.push(to_beam);
                    
                    feature = {
                        "type": "Feature",
                        "properties": {
                            "name": this.getTimestamp(data['data'][i]['beam_switch_markers'][j]['content']) + ' Beam Switch<br>' + from_beam + ' &rarr; ' + to_beam,
                            "details": "<p><b>Beam Switch</b></p><p>" + data['data'][i]['beam_switch_markers'][j]['content'] + "</p>",
                            "stroke": "#0044aa",
                            "stroke-width": 1,
                            "stroke-opacity": 1,
                            "fill": "#0066ff",
                            "fill-opacity": 1,
                            "ref-id": flight_id,
                            "z-layer": 3
                        },
                        "geometry": {"type": "CircleMarker", "coordinates": [data['data'][i]['beam_switch_markers'][j]["longitude"] + shift_flight, data['data'][i]['beam_switch_markers'][j]["latitude"]], "radius": beam_switch_radius}
                    };
                    beam_switch_collection["features"].push(feature);
                    //beam_switch_markers.push(feature);
                }
            }
            
             // start marker
            if (data['data'][i]['flight_start_end_markers'][0]) {
             
              if (data['data'][i]["flight_metadata"]["DepartureAirportLatLng"]) { // airport link
                var add = 0.005;
                if (data['data'][i]['flight_metadata']['Arrival'] == data['data'][i]['flight_metadata']['Departure']) {
                  add = 0
                }
                var airport_lat = add + parseFloat(data['data'][i]["flight_metadata"]["DepartureAirportLatLng"][0]);
                var airport_lon = add + parseFloat(data['data'][i]["flight_metadata"]["DepartureAirportLatLng"][1]);
                
                // Adjust the airport position to be in the same region as the last point
                logger.debug("start_marker", airport_lat, airport_lon, last_long, data['data'][i]['flight_start_end_markers'][0]["longitude"]);
                
                
                if (data['data'][i]['flight_start_end_markers'][0]["longitude"] > right_edge + 100) {
                  data['data'][i]['flight_start_end_markers'][0]["longitude"] +=360;
                  airport_lon +=  360;
                }
                else if (data['data'][i]['flight_start_end_markers'][0]["longitude"] < left_edge -100) {
                  data['data'][i]['flight_start_end_markers'][0]["longitude"] -=360;
                  airport_lon -=  360;
                }
                feature = {
                  "type": "Feature",
                  "properties": {
                        "stroke": "#000000",
                        "stroke-width": 3,
                        "stroke-opacity": 0.2,
                        "center": true
                  },
                  "geometry": {  
                       "type": "LineString",  
                       "coordinates": [[airport_lon + shift_flight, airport_lat],[data['data'][i]['flight_start_end_markers'][0]["longitude"] + shift_flight, data['data'][i]['flight_start_end_markers'][0]["latitude"]]]
                  }
                };
                
                flight_collections[i]["features"].push(feature);
                
                 var feature = {
                    "type": "Feature",
                    "properties": {
                        "name": this.getTimestamp(data['data'][i]['flight_start_end_markers'][0]['content']) + ' ' + "Begin Data",
                        "details": "<p><b>Begin Data</b></p><p>" +  data['data'][i]['flight_start_end_markers'][0]['content'] + "</p>",
                        "stroke": "#909090",
                        "stroke-width": 4,
                        "stroke-opacity": 1,
                        "fill": "#ffffff",
                        "fill-opacity": 1,
                        "ref-id": flight_id,
                        "z-layer": 2 
                    },
                    "geometry": {"type": "CircleMarker", "coordinates": [data['data'][i]['flight_start_end_markers'][0]["longitude"] + shift_flight, data['data'][i]['flight_start_end_markers'][0]["latitude"]], "radius": event_radius}
                           
                };
                events_collection["features"].push(feature);
                
              
                
              }
              if (data['data'][i]['flight_metadata']['Arrival'] != data['data'][i]['flight_metadata']['Departure']) {
                feature = {
                    "type": "Feature",
                    "properties": {
                        "name": this.getTimestamp("timestamp: " + data['data'][i]['flight_metadata']['TakeoffTime'].replace("T", " ")) + " Departure<br />" +
                                data['data'][i]["flight_metadata"]['FlightID'] + ' ' + 
                                data['data'][i]["flight_metadata"]['Route'] ,
                                
                        "details": "<p><b>Departure</b></p><p>" + this.parseMetadata(data['data'][i]["flight_metadata"]) + "</p>",
                        "icon": "departure"
                    },
                    //"geometry": {"type": "Point", "coordinates": [data['data'][i]['flight_start_end_markers'][0]["longitude"] + shift_flight, data['data'][i]['flight_start_end_markers'][0]["latitude"]]}
                    "geometry": {"type": "Point", "coordinates": [airport_lon + shift_flight, airport_lat]}
                };
                flight_collections[i]["features"].push(feature);
              }
            }
            
            // end marker
            if (data['data'][i]['flight_start_end_markers'][1]) {
              //var airport_lon = data['data'][i]['flight_start_end_markers'][1]["longitude"] - 0.08;
              //var airport_lat = data['data'][i]['flight_start_end_markers'][1]["latitude"] + 0.08;
                
              if (data['data'][i]["flight_metadata"]["ArrivalAirportLatLng"]) { // airport link
                airport_lat = data['data'][i]["flight_metadata"]["ArrivalAirportLatLng"][0];
                airport_lon = parseFloat(data['data'][i]["flight_metadata"]["ArrivalAirportLatLng"][1]);
                
                
                
                logger.debug("end_marker", airport_lat, airport_lon, last_long, data['data'][i]['flight_start_end_markers'][1]["longitude"], left_edge, right_edge);
                
                // Adjust the airport position to be in the same region as the last point
                /*
                if (data['data'][i]['flight_start_end_markers'][1]["longitude"] > right_edge) {
                  data['data'][i]['flight_start_end_markers'][1]["longitude"] -=  360;
                }
                else if (data['data'][i]['flight_start_end_markers'][1]["longitude"] < left_edge) {
                  data['data'][i]['flight_start_end_markers'][1]["longitude"] +=  360;
                }
                */
                if (airport_lon > right_edge + 100) {
                  airport_lon -= 360;
                }
                else if (airport_lon < left_edge-100) { 
                  airport_lon += 360;
                }
                
                feature = {
                    "type": "Feature",
                    "properties": {
                        //"name": data['data'][i]["flight_metadata"]["Arrival"],
                        "name": this.getTimestamp(data['data'][i]['flight_start_end_markers'][1]['content']) + " " + "End Data",
                        "details": "<p><b>End Data</b></p><p>" +  data['data'][i]['flight_start_end_markers'][1]['content'] + "</p>",
                        "stroke": "#000000",
                        "stroke-width": 3,
                        "stroke-opacity": 1,
                        "fill": "#000000",
                        "fill-opacity": 1,
                        "icon": "departure",
                        "ref-id": flight_id,
                        "z-layer": 2 
                        
                    },
                    "geometry": {"type": "CircleMarker", "coordinates": [data['data'][i]['flight_start_end_markers'][1]["longitude"] + shift_flight, data['data'][i]['flight_start_end_markers'][1]["latitude"]], "radius": event_radius}
                };
                events_collection["features"].push(feature);
                
                feature = {
                  "type": "Feature",
                  "properties": {
                        "stroke": "#000000",
                        "stroke-width": 3,
                        "stroke-opacity": .2,
                        "center": true,
                        "dashArray": '50, 50', 
                        "dashOffset": '50'
                  },
                  "geometry": {  
                       "type": "LineString",  
                       "coordinates": [[airport_lon + shift_flight, airport_lat],[data['data'][i]['flight_start_end_markers'][1]["longitude"]  + shift_flight, data['data'][i]['flight_start_end_markers'][1]["latitude"]]]
                  }
                };
                
                flight_collections[i]["features"].push(feature);
                
              }
              //landing marker
              logger.debug("landing marker", data['data'][i]);
              var icon = "arrival";
              var depart_timestamp = "";
              var title = "Arrival";
              if (data['data'][i]['flight_metadata']['Arrival'] == data['data'][i]['flight_metadata']['Departure']) {
                icon = "departure_arrival";
                title = "Departure/Arrival";
              } 
              
              feature = {
                  "type": "Feature",
                  "properties": {
                      "name": this.getTimestamp("timestamp: " + data['data'][i]['flight_metadata']['LandingTime'].replace("T", " ")) + " Arrival<br />" +
                                data['data'][i]["flight_metadata"]['FlightID'] + ' ' + 
                                data['data'][i]["flight_metadata"]['Route'] ,
       
                      "details": "<p><b>Arrival</b></p><p>" + this.parseMetadata(data['data'][i]["flight_metadata"]) + "</p>",
                      "stroke": "#0066ff",
                      "stroke-width": 3,
                      "stroke-opacity": 1,
                      "fill": "#cccccc",
                      "fill-opacity": 1,
                      "icon": icon
                  },
                  //"geometry": {"type": "Point", "coordinates": [data['data'][i]['flight_start_end_markers'][1]["longitude"] + shift_flight, data['data'][i]['flight_start_end_markers'][1]["latitude"]]}
                  "geometry": {"type": "Point", "coordinates": [airport_lon + shift_flight, airport_lat]}
              };
              flight_collections[i]["features"].push(feature);
                
            }
            
          
            
            // online markers
            if (data['data'][i]['connectivity_online_markers']) {
                       
                has_events = true;
                for (var j = 0; j < data['data'][i]['connectivity_online_markers'].length; j++) {
                  if (data['data'][i]['connectivity_online_markers'][j]['longitude'] > right_edge) 
                    data['data'][i]['connectivity_online_markers'][j]['longitude'] -= 360;
                  else if (data['data'][i]['connectivity_online_markers'][j]['longitude'] < left_edge)
                    data['data'][i]['connectivity_online_markers'][j]['longitude'] += 360;
                  
                     feature = {
                        "type": "Feature",
                        "properties": {
                            "name": this.getTimestamp(data['data'][i]['connectivity_online_markers'][j]['content']) + " Online",
                            "details": "<p><b>Online</b></p><p>" + data['data'][i]['connectivity_online_markers'][j]['content'] + "</p>",
                            "stroke": "#cc0000",
                            "stroke-width": 0,
                            "stroke-opacity": 1,
                            "fill": "#66ff66",
                            "fill-opacity": 1,
                            "ref-id": flight_id,
                            "z-layer": 4
                        },
                        "geometry": {"type": "CircleMarker", "coordinates": [data['data'][i]['connectivity_online_markers'][j]["longitude"] + shift_flight, data['data'][i]['connectivity_online_markers'][j]["latitude"]], "radius": event_radius}
                    };
                    events_collection["features"].push(feature);
                }
            }
            
            // offline markers
            if (data['data'][i]['connectivity_offline_markers']) {
                has_events = true;
                for (var j = 0; j < data['data'][i]['connectivity_offline_markers'].length; j++) {
                  if (data['data'][i]['connectivity_online_markers'][j]['longitude'] > right_edge) 
                    data['data'][i]['connectivity_online_markers'][j]['longitude'] -= 360;
                  else if (data['data'][i]['connectivity_online_markers'][j]['longitude'] < left_edge)
                    data['data'][i]['connectivity_online_markers'][j]['longitude'] += 360;
                  
                     feature = {
                        "type": "Feature",
                        "properties": {
                            "name": this.getTimestamp(data['data'][i]['connectivity_offline_markers'][j]['content']) + " Offline",
                            "details": "<p><b>Offline</b></p><p>" + data['data'][i]['connectivity_offline_markers'][j]['content'] + "</p>",
                            "stroke": "#cc0000",
                            "stroke-width": 0,
                            "stroke-opacity": 1,
                            "fill": "#ff6666",
                            "fill-opacity": 1,
                            "ref-id": flight_id,
                            "z-layer": 4 
                        },
                        "geometry": {"type": "CircleMarker", "coordinates": [data['data'][i]['connectivity_offline_markers'][j]["longitude"] + shift_flight, data['data'][i]['connectivity_offline_markers'][j]["latitude"]], "radius": event_radius}
                    };
                    events_collection["features"].push(feature);
                }
            }
            
            //logger.debug("beam_switch_markers", beam_switch_markers);
            //flight_collections[i]["features"] = flight_collections[i]["features"].concat(beam_switch_markers);
             
            logger.debug("beam_list", beam_list);
            
            
            // beam overlay
            
            if (data['data'][i]['beam_overlay']) {
                logger.debug('beam_list overlay',data['data'][i]['beam_overlay']);
                var added_beams = [];
                for (var j = 0; j < data['data'][i]['beam_overlay'].length; j++) {
                    if (beam_list.includes(data['data'][i]['beam_overlay'][j]['beam_name']) 
                            && !added_beams.includes(data['data'][i]['beam_overlay'][j]['beam_name'])) {
                        logger.debug('beam_list found',data['data'][i]['beam_overlay'][j]['beam_name']);
                        added_beams.push(data['data'][i]['beam_overlay'][j]['beam_name']);
                        /*
                        var coordinates = []
                        for (var k = 0; k < data['data'][i]['beam_overlay'][j]['geometry']['coordinates'].length; k++) {
                            coordinates.push([]);
                            for (var l = 0; l < data['data'][i]['beam_overlay'][j]['geometry']['coordinates'][k].length; l++) {
                                logger.debug("beam coordinate", data['data'][i]['beam_overlay'][j]['geometry']['coordinates'][k][l]);
                                coordinates[k].push([ data['data'][i]['beam_overlay'][j]['geometry']['coordinates'][k][l][1], data['data'][i]['beam_overlay'][j]['geometry']['coordinates'][k][l][0]])
                            }
                        }
                        */
                        
                        data['data'][i]['beam_overlay'][j]['geometry'] = this.adjustGeometry(data['data'][i]['beam_overlay'][j]['geometry'], left_edge, right_edge, shift_flight);
                        //var geometry = data['data'][i]['beam_overlay'][j]['geometry']['coordinates'];
                        feature = {
                            "type": "Feature",
                            "properties": {
                                //"name": data['data'][i]['beam_overlay'][j]['beam_name'],
                                "details": data['data'][i]['beam_overlay'][j]['beam_name'],
                                "stroke": "#0066ff",
                                "stroke-width": 2,
                                "stroke-opacity": 0.3,
                                "fill": "#0066ff",
                                "fill-opacity": 0.05,
                                "layer_to_back": true,
                                "mouseover": {
                                    "stroke": "#ffffff",
                                    "stroke-width": 4,
                                    "fill": "#42ffca"
                                },
                                "ref-id": flight_id,
                                "z-layer": 0 
                            },
                            "geometry": data['data'][i]['beam_overlay'][j]['geometry'] //{"type": "Polygon", "coordinates": data['data'][i]['beam_overlay'][j]['geometry']['coordinates']}
                        };
                        logger.debug("adding beam", data['data'][i]['beam_overlay'][j]['beam_name'], feature);
                        logger.debug("beams", data['data'][i]['beam_overlay'][j]['beam_name'], data['data'][i]['beam_overlay'][j]['beam_name'].indexOf("_W0"));
                        if (data['data'][i]['beam_overlay'][j]['beam_name'].indexOf("_W0") != -1) {
                            big_beams.push(feature);
                        }
                        else {
                            small_beams.push(feature);
                        }
                    }
                }
                
                logger.debug("Beams", small_beams, big_beams, beam_collection['features']);

            }
        }
        
        beam_collection['features'].push(...big_beams, ...small_beams);
       
        
        result = result.concat(flight_collections, beam_collection, beam_switch_collection, events_collection);
        logger.debug("Results", result);
        return result;
    }
}

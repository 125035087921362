import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';



export default function Delete(props) {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    
     const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        setOpen(false);
        props.onSubmit();
    };
  
   
    return (
        <div>
            <Tooltip title="Delete Selected items">
                <IconButton aria-label="delete entries" onClick={handleOpen}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
       
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete Items</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete these items?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
  );
}

import React            from 'react';


import { Logger }       from 'aws-amplify';

import Select           from '../Select';
import AircraftService  from '../../../services/AircraftService';

const logger = new Logger('FormElements/Select/Aircraft');

export default function Aircraft(props) {
    logger.debug("construtor", props);
    
    var aircraft_service = aircraft_service = new AircraftService();;
    
    const renderOption = (option, state) => { 
        logger.debug('renderOption', option, state);
        return  aircraft_service.formatLabel(option);
    };
    
    const optionLabel = (option) => { 
        logger.debug("optionLabel", option, typeof option);
        return  aircraft_service.formatLabel(option);
    };
    
  
    
    return <Select
        key="aircraft" 
        id="aircraft" 
        name="aircraft" 
        field="registration_name" 
        label="Aircraft"
        variant="standard"
        defaultValue={props.defaultValue} 
        data={props.data}  
        required={props.required} 
        helperText={props.helperText}
        service={props.operator_icao_code? aircraft_service : null}
        queryStringParameters={{
            operator_icao_code: props.operator_icao_code,
            live_only: true
        }}
        handleChange={props.handleChange}
        error={props.error}
        options={props.options}
        getOptionLabel={ optionLabel }
        renderOption={renderOption}
        noOptionsText={props.operator_icao_code ? "No aircraft found" : "Please select an operator"}
        //style={{paddingRight:20, paddingBottom: 10, width: "200px"}}
    />;
}


/**
 *  This is a loading screen, containing a spinning icon and the product logo
 */ 
 import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/styles';
import { baseTheme } from 'pac-responsive-ui-framework/themes/Main';
import { constants } from 'assets/Config.js';

import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * props.embedded = true - simplifies the screen, sets the bg to white, and no logo
 */
export default function Loading(props) {
  var background_color = constants.loading.background_color;// "#1565c0";
  var color = "secondary";
  if (props.embedded) {
    background_color = "#ffffff";
    color= "primary";
  }  
    
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      backgroundColor: background_color
    },
   
  }));
  const classes = useStyles();

 var logo = (<div>
                <img src={require("../assets/appbarlogo.png")} alt="logo" className={classes.logo} />
                </div>);
  if (props.embedded) {
    logo = "";
    
  }  

  return (
    <ThemeProvider theme={baseTheme}>
      <div className={classes.root}>
      
        <Grid 
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >

          <Grid item xs={3} >
            {logo}
            <div align="center">
           <CircularProgress color={color} size={80} thickness={1} />
           </div>
          </Grid>   
        
        </Grid> 
      </div>
      </ThemeProvider>
  );
    
}
import React, { useContext } from 'react';

import TablePagination from '@material-ui/core/TablePagination';

import { Logger }           from 'aws-amplify';

const logger = new Logger('Pagination');



export default function Pagination(props) {
    
    // settings={metaData} visibleRows={visibleRowCount} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage}
    logger.debug(props);
    var content = "";
    if (props.settings) {
        
        if (props.settings.page_size == props.settings.total_records) { // single page
            var visibleRows = props.visibleRows?props.visibleRows:props.settings.total_records;
            content = <div style={{ 
                paddingLeft: "16px",
                paddingRight: "4px",
                paddingBottom: "4px", 
                paddingTop: "16px"
                 
            }}>
                {"Showing " + visibleRows + ' of ' + props.settings.total_records}
            </div>;
        }
        else {
        
            content = <TablePagination
              component="div"
              count={props.settings.total_records}
              page={props.settings.page_number - 1}
              onPageChange={props.onPageChange}
              rowsPerPage={props.settings.page_size}
              rowsPerPageOptions={[10,25,50,100, 250]}
              onChangeRowsPerPage={props.onChangeRowsPerPage}
              style={{padding: "0px"}}
              labelDisplayedRows = {
                    function defaultLabelDisplayedRows({ from, to, count }) { 
                        var showing = '';
                        if (props.visibleRows && props.visibleRows < to - from) {
                            showing = '(showing ' + props.visibleRows + ' of ' + (to - from + 1) + ')'; 
                        }
                        return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`} ${showing}`; 
                    }}
            />;
        }
    }
    return <div style={
            { 
                paddingTop: "0px",
                paddingLeft: "16px",
                paddingRight: "0px",
                paddingBottom: "4px", 
                 
            } 
        }>{content}</div>
    ;
}
import ReactDOMServer from 'react-dom/server';
import { getIcon } from '../../data/Operators';



export default function OperatorIcaoCodeCellRenderer() {
}

OperatorIcaoCodeCellRenderer.prototype.init = function (params) {
    //console.log('operatorIcaoCodeCellRenderer.prototype.init', params);
    this.eGui = document.createElement('span');
   // if (params.value === "null") params.value = null;
    if (params.value !== "" &&  params.value !== undefined && params.value !== null) {
        console.log('operatorIcaoCodeCellRenderer value', params.value);
        
        //var icon = ReactDOMServer.renderToStaticMarkup(getIcon({operator_icao_code: params.value}));
        var src;
        if (params.value) {
            src = "https://origins-assets.nextcloud.aero/operator-logos/logo-" + params.value.toLowerCase() + ".png";
            /*
            try {
                var http = new XMLHttpRequest();
                http.open('GET', src, false);
                http.send();
                console.log('operatorIcaoCodeCellRenderer status', http.status);
            }
            catch(err) {
            }
            */
            /* global Image */
            /*
            var icon = new Image();
            icon.src = src;
            icon.width = 24;
            icon.height = 24;
            icon.style = "margin-right: 49";
            icon.onerror = () => {
                this.onerror=null; 
                console.log('operatorIcaoCodeCellRenderer error');
                this.src="https://origins-assets.nextcloud.aero/operator-logos/logo----.png";
            }
            icon = icon.outerHTML;
            */
            
            var icon = '<img width=24 height=24' +  
                ' src=' + src + 
                ' style="margin-right: 16 "' +  
                ' onerror=" ' + 
               // '    console.log("operatorIcaoCodeCellRenderer err");' +
                '    this.onerror = null; ' + 
                "    this.src='https://origins-assets.nextcloud.aero/operator-logos/logo----.png';" + 
                '"' + 
            '/>';
            
        
            this.eGui.innerHTML = icon + ' ' + params.value;
        }
    }
};

OperatorIcaoCodeCellRenderer.prototype.getGui = function () {
    return this.eGui;
};


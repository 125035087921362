import React from 'react';
import Select from 'pac-responsive-ui-framework/forms/Select'
import AircraftService      from '../services/AircraftService';
import { Logger }           from 'aws-amplify';

const logger = new Logger("SelectAircraft");

export default function SelectAircraft(props) {
    logger.debug(props);
    
    var load = false;
    if (props.operator) {
        load = true;
    }
    
    return (
        <Select 
            label="Aircraft" 
            valueKey = 'registration_name'
            value={props.value} 
            service={AircraftService}
            filter={{operator_icao_code: props.operator}}
            getOptionLabel={(option) => option.registration_name?option.registration_name:""}
            noOptionsText="Please select an operator"
            load ={load}
            />
      )
    
}
import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
//import ReactDOMServer from 'react-dom/server';
import { Logger }           from 'aws-amplify';
import { AgGridReact } from 'ag-grid-react';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
//import AgGridContext      from '../contexts/AgGrid';

//import '../3rd_party/ag-grid/dist/ag-grid-community.min.js';
import '../3rd_party/ag-grid/dist/styles/ag-grid.css';
import '../3rd_party/ag-grid/dist/styles/ag-theme-balham.min.css';
import '../3rd_party/ag-grid/dist/styles/agGridBalhamFont.min.css';
import "../3rd_party/ag-grid/dist/styles/webfont/agGridBalhamFont.scss";


//import 'ag-grid-community/dist/styles/ag-grid.css';
//import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import url from "../util/URL";

//import Typography from '@material-ui/core/Typography';
import Tooltip      from '@material-ui/core/Tooltip';
import Hidden       from '@material-ui/core/Hidden';
import Typography   from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton   from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/GetApp';
//import UndoIcon from '@material-ui/icons/Undo';
//import RedoIcon from '@material-ui/icons/Redo';
import EditIcon     from '@material-ui/icons/Edit';
import AddIcon      from '@material-ui/icons/Add';
import CloneIcon    from '@material-ui/icons/LibraryAddRounded';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

//import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
//import FilterIcon from '@material-ui/icons/FilterList';


//import Select from '@material-ui/core/Select';

import FormDialog from './FormDialog';
//import TextFilter from './TextFilter';
import Pagination from './Pagination';
import Error from './Error';
import DeleteButton from '../components/AGGrid/DeleteButton';

import AppContext               from 'pac-responsive-ui-framework/core/AppContext';


//import PopupCellRenderer from '../components/AGGrid/PopupCellRenderer';
//import EditIcon from '@material-ui/icons/Edit'

import { getIcon } from '../data/Operators';

const logger = new Logger('AGGrid');

function percentColumnFilter() {

}

percentColumnFilter.prototype.doesFilterPass = function(params) {
    console.log('percentColumnFilter.prototype.doesFilterPass',params);
    // make sure each word passes separately, ie search for firstname, lastname
    var passed = true;
    var valueGetter = this.valueGetter;
    this.filterText.toLowerCase().split(" ").forEach(function(filterWord) {
        var value = valueGetter(params);
        if (value.toString().toLowerCase().indexOf(filterWord) < 0) {
            passed = false;
        }
    });

    return passed;
};

export default function AGGrid(props) {


    logger.debug("DataGrid props",props);

    var app_context = useContext(AppContext);

    const gridRef = useRef();

    const [loaded, setLoaded] = useState(null);

    const [rowData, setRowData] = useState(null);
    const [metaData, setMetaData] = useState(null);

    const [query, setQuery] = useState(props.query);


    const [open, setOpen] = useState(false);
    const [grid, setGrid] = useState(null);
    const [isSelected, setIsSelected] = useState(false); // boolean to see if it is selected.
    const [selectedData, setSelectedData] = useState(null); // null or data set
    const [cloneData, setCloneData] = useState(null); // null or data set

    const [selectedGroupHeaderName, setSelectedGroupHeaderName] = useState(null); // null or data set

    const [editType, setEditType] = useState("new"); // "new", "clone" or "edit"

    const [footerMessage, setFooterMessage] = useState(props.footer); // null or data set

    const [visibleRowCount, setVisibleRowCount] = useState(null);

    const [error, setError] = useState(null);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const buttonMenuOpen = Boolean(anchorEl);

    const handleButtonMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleButtonMenuClose = () => {
        logger.debug("handleClickOpen handleButtonMenuClose");
        setAnchorEl(null);
    };

    window.addEventListener("resize", handleButtonMenuClose);

    var columnDefs = props.columnDefs;
    const service = props.service;
    service.setPermissions(app_context.state.user.session.acl_role);


    // This triggers when the parameters change coming into the function
    useEffect(() => {
        logger.debug("useEffect props.recordID", props.recordID);
        setLoaded(false);
        if (props.refCallback)
            props.refCallback({handleRefresh: handleRefresh});
    }, [props.recordID]);

    useEffect(() => {
        logger.debug("useEffect props.query", props.query);
        setLoaded(false);
        setQuery(props.query);
        if (grid)
            grid.showLoadingOverlay();
    }, [props.query]);



    if (loaded === false && grid) {
        logger.debug("AGGrid service loading", loaded);

        setLoaded(true);
         if (props.recordID) {
             logger.debug("AGGrid service fetch", props.recordID);
             service.fetch(props.recordID)
             .then((data) => {
                 setMetaData(service.getMetaData(data));
                 logger.debug("AGGrid service success a1", props.recordID, data);
                 data = service.formatRowData(data);
                 logger.debug("AGGrid service success a2", data);
                 setRowData(data);

                 grid.setRowData(data);
             })
             .catch((err) => {

                logger.debug('AGGrid service failed 1', err);
                //setError(err);
                setRowData([]);
                grid.setRowData([]);
             });
         }
         else {
            logger.debug("AGGrid service fetchRecords", query, props);
            service.fetchRecords(query)
            .then((data) => {
                setMetaData(service.getMetaData(data));
                logger.debug("AGGrid service success b1", query, data);
                data = service.formatRowData(data);
                logger.debug("AGGrid service success b2", query, data);

                setRowData(data);

                grid.setRowData(data);
            })
            .catch((err) => {

                logger.debug('AGGrid service failed', err);
                //setError(err);
                setRowData([]);
                grid.setRowData([]);
            });
        }
    }

    var gridApi;

    function handleClose() {
        setOpen(false);
    }

    /* moved inline
    const onGridReady = params => {
        logger.debug('onGridReady', params.api);
        gridApi = params.api;
        gridApi.sizeColumnsToFit();
        setGrid(gridApi);
    };
    */

    const cellClickedListener = useCallback( event => {
        console.log('cellClickedListener cellClicked', event);
        setIsSelected(true);
        var focusedCell = gridRef.current.api.getFocusedCell();
        var data= null;
        var clone_data = null;
        if (focusedCell) {
            console.log('cellClickedListener focusedCell', focusedCell);
            var row = gridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
            setSelectedGroupHeaderName(focusedCell.column.originalParent.colGroupDef.headerName);
            if (row) {
                data= row.data;
                if (service.getCloneRecord) {
                    clone_data  = Object.assign({}, data);
                    clone_data = service.getCloneRecord(clone_data);
                    setCloneData(clone_data);
                }
            }
        }
        logger.debug("cellClickedListener", data);
        setSelectedData(data);
    }, []);

     const cellDoubleClickedListener = useCallback( event => {
        setEditType("edit");
        setOpen(true);

    }, []);

   function onBtnExport() {
        // see https://ag-grid.com/react-data-grid/csv-export/
        setAnchorEl(null);
        var filename = props.filename;
        filename += ".csv";
        grid.exportDataAsCsv({
            fileName: filename,
            skipHeader: false,
            allColumns: true,
            skipColumnGroupHeaders: false,
            processHeaderCallback: (params) => {
                //logger.debug("processHeaderCallback", params);
                return params.column.originalParent.colGroupDef.headerName + ": \n" + params.column.colDef.headerName;
            }
        });
    }

    function handleRefresh() {
        logger.debug("handleRefresh", grid);
        setAnchorEl(null);
        grid.showLoadingOverlay();
        setSelectedData(null);
        setIsSelected(false);
        setLoaded(false);
    }

    function onBtnEdit() {
        setAnchorEl(null);
        setEditType("edit");
        setOpen(true);
    }

    function onBtnClone() {
        setAnchorEl(null);
        setEditType("clone");
        setOpen(true);
    }
    function onBtnDelete() {
        setAnchorEl(null);
        service.delete(selectedData, props.recordID);
        handleRefresh();
    }

    function onBtnAdd() {
        setAnchorEl(null);
        setEditType("new");
        setOpen(true);
    }

    function handleChangePage(event, newPage) {
        var q = query;
        q["page[number]"] = newPage + 1;
        setQuery(q);
        handleRefresh();
    }

    function handleChangeRowsPerPage(event) {
        var size = parseInt(event.target.value, 10);
        var q = query;
        q["page[size]"] = size;
        setQuery(q);
        handleChangePage(event, 0);
    }

    if (error) {
        return <Error err={error}/>;
    }

    var pagination;
    if (metaData) {
        if (metaData.pagination) {
            pagination =   <Pagination settings={metaData} visibleRows={visibleRowCount} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage}/>;
        }
        else {
            var rowCount = visibleRowCount;
            if (rowCount == null) rowCount = metaData.total_records;
            pagination =
                <div style={{padding:"16px"}}>
                    <Typography>Displaying {rowCount} of {metaData.total_records} total records.</Typography>
                </div>;
        }
    }

    var form_data = selectedData;
    if (editType == "clone") form_data = cloneData;

    var add_record_button;
    var add_record_menuitem;
    var clone_button;
    var clone_menuitem;
    var edit_record_button;
    var edit_record_menuitem;
     var delete_button;
    var delete_menuitem;
    if (service.save && service.permissions.includes('c')) {
         add_record_button =
            <Tooltip title="Add Record">
                <IconButton onClick={onBtnAdd}>
                    <AddIcon/>
                </IconButton>
            </Tooltip>;
        add_record_menuitem =
            <MenuItem key="addrecord" onClick={onBtnAdd} >
                <ListItemIcon>
                    <AddIcon  />
                </ListItemIcon>
                <Typography variant="inherit">Add Record</Typography>
            </MenuItem>;
        if (service.getCloneRecord) {

             clone_button =
                <Tooltip title="Clone Record">
                    <IconButton onClick={onBtnClone} disabled={!isSelected} >
                        <CloneIcon/>
                    </IconButton>
                </Tooltip>;
            clone_menuitem =
                <MenuItem key="clonerecord" onClick={onBtnClone} disabled={!isSelected}>
                    <ListItemIcon>
                        <CloneIcon  />
                    </ListItemIcon>
                    <Typography variant="inherit">Clone Record</Typography>
                </MenuItem>;
        }
    }
    if (service.delete && service.permissions.includes('u')) {
        edit_record_button =
            <Tooltip title="Edit Record">
                <IconButton onClick={onBtnEdit} disabled={!isSelected} >
                    <EditIcon/>
                </IconButton>
            </Tooltip>;
         edit_record_menuitem =
            <MenuItem key="editrecord" onClick={onBtnEdit} disabled={!isSelected}>
                <ListItemIcon>
                    <EditIcon  />
                </ListItemIcon>
                <Typography variant="inherit">Edit Record</Typography>
            </MenuItem>;

    }
    else {
         edit_record_button =
            <Tooltip title="View Record">
                <IconButton onClick={onBtnEdit} disabled={!isSelected} >
                    <EditIcon/>
                </IconButton>
            </Tooltip>;
        edit_record_menuitem =
            <MenuItem key="editrecord" onClick={onBtnEdit} disabled={!isSelected}>
                <ListItemIcon>
                    <EditIcon  />
                </ListItemIcon>
                <Typography variant="inherit">View Record</Typography>
            </MenuItem>;
    }
    if (service.delete && service.permissions.includes('d')) {
        delete_button = <DeleteButton callback={onBtnDelete} disabled={!isSelected} data={selectedData} columnDefs={columnDefs} asMenuItem={false} />;
        delete_menuitem = <DeleteButton onClick={handleButtonMenuClose} callback={onBtnDelete} disabled={!isSelected} data={selectedData} columnDefs={columnDefs} asMenuItem={true} />;
    }

    var buttons =
        <React.Fragment>
            <Tooltip title="Export to CSV">
                <IconButton onClick={onBtnExport} disabled={!(metaData && metaData.total_records > 0)}>
                    <DownloadIcon/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Refresh the Data">
                <IconButton onClick={handleRefresh}>
                    <RefreshIcon/>
                </IconButton>
            </Tooltip>
            {add_record_button}
            {edit_record_button}
            {clone_button}
            {delete_button}
    </React.Fragment>

    var buttons_menu =
        <React.Fragment>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleButtonMenuClick}
              >
                <MoreVertIcon />
             </IconButton>
             <Menu
                id="button-menu"
                anchorEl={anchorEl}
                keepMounted
                open={buttonMenuOpen}
                onClose={handleButtonMenuClose}
                PaperProps={{
                  style: {
                    width: '24ch',
                  },
                }}
            >
                <MenuItem key="download" onClick={onBtnExport} disabled={!(metaData && metaData.total_records > 0)}>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Export to CVS</Typography>
                 </MenuItem>
                 <MenuItem key="refresh" onClick={handleRefresh}>
                    <ListItemIcon>
                        <RefreshIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit">Refresh Data</Typography>
                </MenuItem>
                {add_record_menuitem}
                {edit_record_menuitem}
                {clone_menuitem}
                {delete_menuitem}
              </Menu>
        </React.Fragment>;
    return (
        <React.Fragment>
            <div style={{ paddingRight: 16 }}>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td style={{paddingLeft: "24px", whiteSpace: "nowrap"}}>
                                {props.header}
                            </td>
                            <td style={{textAlign: "right", whiteSpace: "nowrap"}}>
                                <Hidden xsDown={true}>
                                    {buttons}
                                </Hidden>
                                <Hidden smUp={true}>
                                    {buttons_menu}
                                </Hidden>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="ag-theme-balham" style={ {
                    paddingLeft: "16px",
                    paddingBottom: "4px",
                    height: 'calc(100% - 120px)',
                    width: 'calc(100%)',
                    overflowY: "hidden",
                    overflowX: "hidden"
            } }>
                <AgGridReact
                    ref={gridRef}
                    onGridReady= {(params) => {
                        logger.debug('onGridReady', params.api);
                        gridApi = params.api;
                        gridApi.sizeColumnsToFit();
                        setGrid(gridApi);
                    }}
                    //{onGridReady}
                    defaultColDef={ {
                        suppressMenu: true,
                        suppressSizeToFit: true,
                        floatingFilter: true,
                        editable: false,
                        sortable: true
                    }}
                    components= { {
                      //  'operatorIcaoCodeCellRenderer': operatorIcaoCodeCellRenderer,
                        //'percentColumnFilter': percentColumnFilter,
                        // handleOpen: handleOpen
                    }}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    undoRedoCellEditing="true"
                    undoRedoCellEditingLimit="20"
                    rowSelection="single"
                    onCellClicked={cellClickedListener}
                    onCellDoubleClicked={cellDoubleClickedListener}
                    onFirstDataRendered = {(event) => {
                        let search = window.location.search;
                        let params = new URLSearchParams(search);
                        logger.debug("onFirstDataRendered", event, params.get("grid_filters"));
                        event.api.setFilterModel(JSON.parse(params.get("grid_filters")));
                    }}
                    onGridSizeChanged={(event) => {
                        logger.debug("gridSizeChanged", event);
                    }}
                    onViewportChanged={(event) => {
                        logger.debug("viewportChanged", event);
                    }}
                    onStateUpdated={(event) => {
                        logger.debug("stateUpdated", event);
                    }}
                    onModelUpdated={(event) => {
                        let search = window.location.search;
                        let params = new URLSearchParams(search);
                        logger.debug("modelUpdated", event,params.get("grid_filters"));
                         //event.api.setFilterModel(JSON.parse(params.get("grid_filters")));
                    }}
                    enableBrowserTooltips="true"
                    onFilterChanged= {(event) => {
                        let search = window.location.search;
                        let params = new URLSearchParams(search);
                        let param_array = [];
                        for (const p of params) {
                            if (p[0] !== 'grid_filters') {
                                var p2 = p[0];
                                if (p[1] !== "") {
                                    p2 += "=" + p[1];
                                }
                                param_array.push(p2);
                            }
                        }
                        logger.debug("onFilterChanged1", params, param_array, params.get("grid_filters"), event);
                        setVisibleRowCount(gridRef.current.api.getDisplayedRowCount());
                        url.pushState("", null, "?grid_filters=" + JSON.stringify(event.api.getFilterModel()) + "&" + param_array.join('&'), app_context.state.page_title);
                    }}
                    /*
                    onFilterModified= {(event) => {
                        let search = window.location.search;
                        let params = new URLSearchParams(search);
                        logger.debug("onFilterModified", event,params.get("grid_filters"));
                        //event.api.setFilterModel(JSON.parse(params.get("grid_filters")));
                    }}
                    */
                    overlayLoadingTemplate=
                        '<span class="ag-overlay-loading-center">Loading data ...</span>'

                    >
                </AgGridReact>
            </div>
             <div style={ {
                    paddingLeft: "16px",
                    paddingBottom: "4px",
                    width: '100%',
                    overflowY: "hidden",
                    overflowX: "hidden"
            } }>
            <table width="100%"><tbody><tr><td>
                <p style={{mergin:0, paddingLeft: 20}}>{footerMessage}</p>
            </td><td style={{textAlign: "right"}}>
                {pagination}
            </td></tr></tbody></table>
            </div>
            <FormDialog
                handleRefresh={handleRefresh}
                handleClose={handleClose}
                open={open}
                data={form_data}
                editType={editType}
                columnDefs={columnDefs}
                service={service}
                groupHeaderName={selectedGroupHeaderName}
                metaData={{recordID: props.recordID, app_state: app_context.state}}
                permissions={service.permissions}
            />
        </React.Fragment>
    );
}


import React, { useContext, useState, useEffect, useRef }          from 'react';
import { flushSync } from 'react-dom';
import { Amplify, Logger, Storage } from 'aws-amplify';
//import AppContext      from 'pac-responsive-ui-framework/core/AppContext';

import { withStyles } from '@material-ui/core/styles';

import url from "../util/URL";

import Paper from '@material-ui/core/Paper';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppContext               from 'pac-responsive-ui-framework/core/AppContext';
import Grid from '@material-ui/core/Grid';

import { AgGridReact } from 'ag-grid-react';

import TeleportBeamMappingService   from '../services/TeleportBeamMappingService';
import LCMReplayService   from '../services/LCMReplayService';

import TailStatusService            from '../services/TailStatusService';

import View           from 'pac-responsive-ui-framework/core/View';

import MultiSelectSimple  from 'components/FormElements/MultiSelectSimple';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';

import PlayIcon from '@material-ui/icons/PlayArrow';

import PauseIcon from '@material-ui/icons/PauseRounded';
import LoadIcon from '@material-ui/icons/VerticalAlignBottom';
import FastForwardIcon from '@material-ui/icons/FastForward';
import StepForwardIcon from '@material-ui/icons/DoubleArrow';


import StepIcon from '@material-ui/icons/Eject';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos';
import StartIcon from '@material-ui/icons/LastPage';

//import Highcharts from 'highcharts/highstock';
//import HighchartsReact from 'highcharts-react-official';

import Clock      from 'components/Clock';

import Tooltip from '@material-ui/core/Tooltip';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';

import ReconnectingWebSocket from 'reconnecting-websocket';

var bucket;
if (window.location.origin.indexOf("amazonaws") > -1) { // cloud9
    bucket = "pac-origins-lcm-replay-requests-592706360732-us-west-2";
}
else if (window.location.origin.indexOf("-dev.nextcloud.aero") > -1) { // dev
    bucket = "pac-origins-lcm-replay-requests-592706360732-us-west-2";
}
else if (window.location.origin.indexOf("-test.nextcloud.aero") > -1) { // test
    bucket = "pac-origins-lcm-replay-requests-737555935625-us-west-2";
}
else { //prod
    bucket = "pac-origins-lcm-replay-requests-328840581762-us-west-2";
}

const logger = new Logger('LiveBeamStatus');
Amplify.configure({
            Storage: {
                AWSS3: {
                    bucket: bucket,
                    region: 'us-west-2',
                    //isObjectLockEnabled: true //OPTIONAl - Object Lock parameter
                }
            }
        });


const theme = createTheme({
    // fixed a bug where the background of the textfield changes color on autocomplete
    // see https://stackoverflow.com/questions/54706919/material-ui-remove-the-yellow-background-on-textfield-autofill
    overrides: {
      MuiOutlinedInput: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px #fff inset'
          }
        }
      }
    }
});

const CustomTextField = withStyles({
    root: {
        '& fieldset': {
            borderRadius: '2px',
        },
        /*
        '& label': {
            color: '#000000',
        },
         '&.Mui-focused label': {
            color: '#000000',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white',
          },
          '&:hover fieldset': {
            borderColor: 'white',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'yellow',
          },
        },
        */

    }
})(TextField);

const CustomSlider = withStyles({
  root: {
    color: "green",
    height: 8,
  },
  thumb: {
    height: 21,
    width: 21,
    backgroundColor: '#fff',
    border: '4px solid grey',
    marginTop: -6,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },

  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: "green",
    opacity: 1
  },
  rail: {
    height: 8,
    borderRadius: 4,
    background: "gray",
    opacity: 1
  },
})(Slider);

const CustomSlider2 = withStyles({
  root: {
    color: "green",
    height: 8,
  },
  thumb: {
    height: 21,
    width: 21,
    backgroundColor: '#fff',
    border: '4px solid grey',
    marginTop: -6,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },

  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: "gray",
    opacity: 1
  },
  rail: {
    height: 8,
    borderRadius: 4,
    background: "green",
    opacity: 1
  },
})(Slider);

const StyledAccordionSummary = withStyles({
root: {
    minHeight: 40,
    maxHeight: 40,
    backgroundColor: '#e0e0e0',
    '&.Mui-expanded': {
      minHeight: 40,
      maxHeight: 40,
      backgroundColor: '#e0e0e0',
    }
}
})(AccordionSummary);

/*  Class for AGGrid
class AircraftStateCellRenderer {
    init(params) {
        console.log("OOOIChosenEventRenderer", params);
        this.eGui = document.createElement('span');
        var value = params.value|| "";
        if (value == "NULL") value = "";
        this.eGui.innerHTML = value;
         this.eGui.style.cssText = 'color: #008000;';
    }
    getGui() {
        return this.eGui;
    }
}
*/

const max_bandwidth = 300;
//let socket = new WebSocket('wss://pacfleetmonitoringws.origins-dev.nextcloud.aero');
let socket = new ReconnectingWebSocket('wss://pacfleetmonitoringws.origins.nextcloud.aero');

export default function LiveBeamStatus(props) {

    var app_context = useContext(AppContext);
    //var app_context = React.useContext(AppContext);
    //var s3 = new S3(app_context.state.user);
    //var data = s3.getData();
    //logger.debug("s3data", data);



    const gridBeamRef = useRef();
    const gridBeamFiltersRef = useRef();
    const gridTailFiltersRef = useRef();
    const gridBeamSelectedRow = useRef();
    const gridTailSelectedRow = useRef();
    const gridTailRef = useRef();
    const loadingCount = useRef();
    let teleport_list_ref = useRef([]);
    let teleport_list_replay_ref = useRef([]);

    /**
     * structure:
     * { timestamp => { event_type => [{event}, ... {event}]}}
     *
     * example:
     * {
     *   "2024-04-01 18:00:00": {
     *     "statera_terminal_state": [
     *       {
     *           "record_type": "statera/terminal/state",
     *           "data.sessionid": "623d9d27-5b48-438e-aac1-9e3e72eade32",
     *           "record_timestamp": "2024-04-01 18:00:00.152",
     *           "states": "NetworkRegained",
     *           "xid": 53207,
     *           "previous_beam_name": "",
     *           "beam_name": ""
     *       },
     *      ...
     *
     */
    let timeline_data_ref = useRef({});

    let tail_list_ref = useRef([]);
    let tail_list_replay_ref = useRef([]);
    let lcm_request_id = useRef('e44d5305-4549-45fe-99ab-0c04aead6a49');
    //let lcm_request_date = useRef();

    //logger.debug("LiveBeamStatus function");

    var color_good                  = "#059700";
    var color_good_light            = "#EDFFED";
    var color_good_dark             = "#026B00";

    var color_nonoperational        = "#808080";
    var color_nonoperational_light  = "#e0e0e0";
    var color_inactive_beam         = "#BF8500";
    var color_inactive_beam_light   = "#FFEDC2";
    var color_bad                   = "#E10000";
    var color_bad_light             = "#FFE0E0";
    var color_switching             = "#002BBD";
    var color_switching_light       = "#D8E3FF";
    var color_inactive              = "#000000";
    var color_inactive_light        = "#d0d0d0";

    var color_initializing          = "dodgerblue";

    let params = new URLSearchParams(window.location.search);

    /**
     * Define the BEAM checkbox states
     */
    const beam_states = [
        {key: 0, name: "Empty",         label: "Empty Beams",           color: color_nonoperational,    bgcolor: color_nonoperational_light,    state: true,    count: 0},
        {key: 1, name: "NonEmpty",      label: "Non-empty Beams",       color: color_good,              bgcolor: color_good_light,              state: true,    count: 0},
        {key: 2, name: "Inactive",      label: "Inactive Beams",        color: color_inactive_beam,     bgcolor: color_inactive_beam_light,     state: true,    count: 0},
        {key: 3, name: "NeverActive",   label: "Never Active Beams",    color: color_bad,               bgcolor: color_bad_light,               state: true,    count: 0}

    ];

    var default_beam_checkbox_state = {};
    beam_states.forEach((state)=> {
        if (params.get(state.name) === null) {
            default_beam_checkbox_state[state.name] = state.state;
        }
        else default_beam_checkbox_state[state.name] = params.get(state.name) === "1";
    });
    const [beam_checkbox_state, setBeamCheckboxState] = React.useState(default_beam_checkbox_state);
       //{Empty: false, NonEmpty: true, Inactive: true });


    /**
     * Define the AIRCRAFT checkbox states
     */
    const aircraft_states = [
        {key: 0, group: "Operational Aircraft States",      name: "Login",             label: "Login",             header_class: "color_good",              color: color_good,           bgcolor:  color_good_light,             operational: true,  active: true,    count: 0},
        {key: 1, group: "Operational Aircraft States",      name: "NetworkRegained",   label: "Network Regained",  header_class: "color_good",              color: color_good,           bgcolor:  color_good_light,             operational: true,  active: true,    count: 0},
        {key: 2, group: "Operational Aircraft States",      name: "Operational",       label: "Operational",       header_class: "color_good",              color: color_good,           bgcolor:  color_good_light,             operational: true,  active: true,    count: 0},
        {key: 3, group: "Operational Aircraft States",      name: "Switched",          label: "Switched",          header_class: "color_good",              color: color_good,           bgcolor:  color_good_light,             operational: true,  active: true,    count: 0},
        {key: 4, group: "Non-Operational Aircraft States",  name: "Switching",         label: "Switching",         header_class: "color_switching",         color: color_switching,      bgcolor: color_switching_light,         operational: false, active: true,    count: 0},
        {key: 5, group: "Non-Operational Aircraft States",  name: "NetworkLost",       label: "Network Lost",      header_class: "color_bad",               color: color_bad,            bgcolor: color_bad_light,               operational: false, active: true,    count: 0},
        {key: 6, group: "Non-Operational Aircraft States",  name: "NonOperational",    label: "Non-Operational",   header_class: "color_nonoperational",    color: color_nonoperational, bgcolor: color_nonoperational_light,    operational: false, active: true,    count: 0},
        {key: 7, group: "Inactive Aircraft States",         name: "Logout",            label: "Logout",            header_class: "color_inactive",          color: color_inactive,       bgcolor: color_inactive_light,          operational: false, active: false,   count: 0},
        {key: 8, group: "Inactive Aircraft States",         name: "Unknown",           label: "Unknown",           header_class: "color_inactive",          color: color_inactive,       bgcolor: color_inactive_light,          operational: false, active: false,   count: 0}
    ];

    var default_aircraft_checkbox_state = {};
    aircraft_states.forEach((state)=> {
        if (params.get(state.name) === null) {
            default_aircraft_checkbox_state[state.name] = state.active;
        }
        else default_aircraft_checkbox_state[state.name] = params.get(state.name) === "1";
    });

    const [aircraft_checkbox_state, setAircraftCheckboxState] = React.useState(default_aircraft_checkbox_state);

    //    {Login: true, NetworkRegained: true, Operational: true, Switched: true,
      //      Switching: true, NetworkLost: true, NonOperational: true, Logout: false, Unknown: false});

    const aircraft_groups = [
        {key:0, name: "Operational Aircraft States", count: 0, show_percent: true},
        {key:1, name: "Non-Operational Aircraft States", count: 0, show_percent: true},
        {key:2, name: "Inactive Aircraft States", count: 0, show_percent: false}
    ];

    const [refresh, setRefresh] = useState(0);
    const [operator_list, setOperatorList] = useState([]);
    const [aircraft_list, setAircraftList] = useState([]);
    const [route_list, setRouteList] = useState([]);


    //const [teleport_beam_mapping, setTeleportBeamMapping] = useState([]);
    //const [tail_list, setTailList] = useState([]);

    var default_search_beam_name = "";
    if (params.get('beam')) default_search_beam_name = params.get('beam');
    const [search_beam_name, setSearchBeamName] = useState(default_search_beam_name);
    const [search_operators, setSearchOperators] = useState(params.get('operator_icao_code')?params.get('operator_icao_code').split(','):[]);
    const [search_aircraft, setSearchAircraft] = useState(params.get('aircraft')?params.get('aircraft').split(','):[]);
    //const [search_route, setSearchRoute] = useState(params.get('route')?params.get('route').split(','):[]);

    const [search_exclude_operators, setSearchExcludeOperators] = useState(params.get('excluded_operator_icao_code')?params.get('excluded_operator_icao_code').split(','):[]);
    const [search_teleports, setSearchTeleports] = useState(params.get('teleport')?params.get('teleport').split(','):[]);
    const [search_beams, setSearchBeams] = useState(params.get('beams')?params.get('beams').split(','):[]);
    const [search_routes, setSearchRoutes] = useState(params.get('routes')?params.get('routes').split(','):[]);


    const [hide_usage, setHideUsage] = useState(params.get('hide_usage')?true:false);
    const [bandwidth_slider_value, setBandwidthSliderValue] = useState(params.get('max_bandwidth')?params.get('max_bandwidth'):max_bandwidth);
    const [fwd_slider_value, setFWDSliderValue] = useState(params.get('fwd_utilized')?params.get('fwd_utilized'):0);
    const [rtn_slider_value, setRTNSliderValue] = useState(params.get('rtn_utilized')?params.get('rtn_utilized'):0);

    const [hide_utilization, setHideUtilization] = useState(params.get('hide_utilization')?true:false);


    const [visible_row_count, setVisibleRowCount] = useState();


    const [teleport_data_begun, setTeleportDataBegun] = useState(false);

    //const [timeline_data, setTimelineData] = useState([]);

    //var init_time =  new Date().toISOString().replace('T', ' ').substr(0, 19);
     //logger.debug("timeline_datetime", init_time);

    var default_timeline_start_date = new Date();
    default_timeline_start_date.setHours(0,0,0,0);
    const [timeline_timestamp, setTimelineTimestamp] = useState(null); // the running clock which is updated by the controls
    const [timeline_min_timestamp, setMinTimelineTimestamp] = useState(null); // the minimum time stamp allowed. (max will be cacluated)
    const [timeline_start_date, setTimelineStartDate] = useState(default_timeline_start_date.valueOf()); // the initial date to load data from
    const [timeline_start_hour, setTimelineStartHour] = useState(new Date().getUTCHours() *3600); // the initial time to load data on (in seconds from midnight)
    //const [timeline_data, setTimelineData] = useState(null); // the loaded data, null means data is not loaded
    const [timeline_state, setTimelineState] = useState(null); // the state of the controls selected (ff, rewind, play, pause, etc.)
    const [timeline_state_interupted, setTimelineStateInterupted] = useState();
    const timeline_timestamp_delta = 7200000; // 7200 seconnds = 2 hours
    const timeline_timestamp_jump = 60000; // one minute 300000; // 300 seconds = 5 minutes
    const timeline_timestamp_ff = 60000; // 60 seconds = one minute

   //  const [cooldown_tail_list, setCooldownTailList] = React.useState([]);
   // const [UTCTime, setUTCTime] = useState(); // move to a separate component to not refresh state

    const [tab, setTab] = React.useState(0);

    const handleBeamStateChange = (event) => {
        setBeamCheckboxState({ ...beam_checkbox_state, [event.target.name]: event.target.checked });
    };

    const handleAircraftStateChange = (event) => {
        setAircraftCheckboxState({ ...aircraft_checkbox_state, [event.target.name]: event.target.checked });
    };

    const handleHideUsageChange = (event) => {
        setHideUsage(event.target.checked);
    };

    const handleHideUtilizationChange = (event) => {
        setHideUtilization(event.target.checked);
    };

    const handleOperatorStateChange= (event, value, reason) => {
        setSearchOperators(value);
        var new_search_aircraft = [];
        search_aircraft.forEach((element) => {
            if(value.indexOf(element.substring(0, element.indexOf(":"))) !== -1) new_search_aircraft.push(element);
        });
        setSearchAircraft(new_search_aircraft);
    };

    const handleExcludeOperatorStateChange= (event, value, reason) => {
        setSearchExcludeOperators(value);
        var new_search_aircraft = [];
        search_aircraft.forEach((element) => {
            if(value.indexOf(element.substring(0, element.indexOf(":"))) === -1) new_search_aircraft.push(element);
        });
        setSearchAircraft(new_search_aircraft);

    };

    const handleAircraftSearchChange= (event, value, reason) => {
        setSearchAircraft(value);
    };

    const handleTeleportStateChange= (event, value, reason) => {
        setSearchTeleports(value);
    };

    const handleBeamsStateChange= (event, value, reason) => {
        setSearchBeams(value);
    };

     const handleRoutesStateChange= (event, value, reason) => {
        setSearchRoutes(value);
    };

    const handleBandwidthSliderChange = (event, newValue) => {
        setBandwidthSliderValue(newValue);
    };

    const handleBandwidthInputChange = (event) => {
        setBandwidthSliderValue(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleFWDSliderChange = (event, newValue) => {
        setFWDSliderValue(newValue);
    };

    const handleFWDInputChange = (event) => {
        setFWDSliderValue(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleRTNSliderChange = (event, newValue) => {
        setRTNSliderValue(newValue);
    };

    const handleRTNInputChange = (event) => {
        setRTNSliderValue(event.target.value === '' ? '' : Number(event.target.value));
    };


    const handleBlur = () => {
        if (!bandwidth_slider_value || bandwidth_slider_value < 0) {
            setBandwidthSliderValue(0);
        } else if (bandwidth_slider_value > max_bandwidth) {
            setBandwidthSliderValue(max_bandwidth);
        }

        if (!fwd_slider_value || fwd_slider_value < 0) {
            setFWDSliderValue(0);
        } else if (fwd_slider_value > 100) {
            setFWDSliderValue(100);
        }

        if (!rtn_slider_value || rtn_slider_value < 0) {
            setRTNSliderValue(0);
        } else if (rtn_slider_value > 100) {
            setRTNSliderValue(100);
        }

    };

    /*
    let beamTailsMap = new Map();
    let beamTailsMap2 = new Map();
    let previousCircleElement = NaN;
    let previousCircleUsageElement = NaN;
    let cooldownTailList = [];
    */
    const sleep = (seconds) => {
        const milliseconds = seconds * 1000;
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    };

    const [socket_state, setSocketState] = React.useState("Initializing");

    /**
     *
     */
    function updateLastUpdatedBeam(beam_name, record_timestamp) {

        if (!beam_name) {
            beam_name = "BEAM_Unknown";
        }
        teleport_list_ref.current.forEach((beam, index) => {
            if (beam.beam_name === beam_name) {
                logger.debug("updateLastUpdatedBeam " + beam_name + " " + record_timestamp + ' > ' + teleport_list_ref.current[index]['last_tail_seen']);

                if (teleport_list_ref.current[index]['last_tail_seen'] < record_timestamp || teleport_list_ref.current[index]['last_tail_seen'] === undefined ) {
                    logger.debug("updateLastUpdatedBeam " + beam_name + " " + record_timestamp + ' > ' + teleport_list_ref.current[index]['last_tail_seen'] + " update");

                    teleport_list_ref.current[index]['last_tail_seen'] = record_timestamp;
                }
            }
        });
    }

    /**
     * WHen an event comes in, either live or from playback.
     */
    function processMessageEvent(data, tail_list, beam_list, live = true) {
        var record_types = [

            'statera/terminal/state',
            'sandvine_active_tails',
            'fds_oooi_event',
            'lcm_replay_request',
            'statera/terminal/statusupdate',
            'statera_beam_report'
        ];

        if (!live && data.tail === 'TC-JNL') logger.debug("processMessageEvent playback0", data.record_type, data.tail, data.record_timestamp, data);
        //logger.debug("processMessageEvent", data.record_type, data.tail, data.record_timestamp, data);
        if (!record_types.includes(data.record_type)) return;


        //if (!live) logger.debug("processMessageEvent playback1", data);
        var socket_state_message;

        if (!data['tail']) {
            element_id = tail_list.findIndex(e => e.xid === data.xid);

            if (element_id === -1) { // tail doesn't exist
                data['operator'] = "---";
                data['tail'] = "XID:" + data['xid'];
            }
            else {
                if (tail_list[element_id]['operator']) data['operator'] = tail_list[element_id]['operator'];
                data['tail'] = tail_list[element_id]['tail'];
            }
        }
        else {
            element_id = tail_list.findIndex(e => e.xid === data.xid);
            if (element_id !== -1) {
                if (data['operator']) tail_list[element_id]['operator'] = data['operator'];
                tail_list[element_id]['tail'] = data['tail'];
            }
        }


        if (!data.beam) data.beam = "BEAM_Unknown";
        //if (!live) logger.debug("processMessageEvent playback2", data.record_type, data.tail, data);
        //else logger.debug("processMessageEvent live", data.record_type, data.tail);

        var element_id;

        //if (!live && data['tail'] === 'TC-JNL') {
        //    logger.debug("processMessageEvent tc-jnl global", data);
    //    }

         // update the beam using Ref
        var beam_id = beam_list.findIndex((beam) => {
            return beam.beam_name === data.beam;
        });
        if (beam_id === -1) { // new beam
            var new_teleport_beam= {
                teleport: data.teleport?data.teleport:"UNKNOWN",
                beam_name: data.beam,
                last_tail_seen: data['record_timestamp']
            };
            beam_list.push(new_teleport_beam);

        }
        else {
            if (beam_list[beam_id].teleport === "UNKNOWN") {
                beam_list[beam_id].teleport = data.teleport?data.teleport:"UNKNOWN";
            }
            if (live) updateLastUpdatedBeam(data.beam, data['record_timestamp']);

        }



        if(data.record_type === 'statera/terminal/statusupdate') {
            socket_state_message = "Active";

            //logger.debug("statera/terminal/statusupdate", data);

            /*
            {
                "record_type": "statera/terminal/statusupdate",
                "window_start": "2024-01-22 18:11:00",
                "window_end": "2024-01-22 18:11:15",
                "record_timestamp": "2024-01-22 18:10:51",
                "beam": "BEAM_IS46e-S48_S01_0648",
                "operator": "UAL",
                "tail": "N12004",
                "xid": "53393"
            }
            */

            //if (!live) logger.debug("processMessageEvent status: statusupdate1", data.tail, data.beam, data['record_timestamp']);
            /*
             // update the beam using Ref
            var beam_id = beam_list.findIndex((beam) => {
                return beam.beam_name === data.beam;
            });
            if (beam_id === -1) { // new beam
                if (!live) logger.debug("processMessageEvent statera/terminal/statusupdate new beam", data);
                var new_teleport_beam= {
                    teleport: data.teleport?data.teleport:"UNKNOWN",
                    beam_name: data.beam,
                    last_tail_seen: data['record_timestamp']
                };
                beam_list.push(new_teleport_beam);

            }
            else {
                if (beam_list[beam_id].teleport === "UNKNOWN") {
                    beam_list[beam_id].teleport = data.teleport?data.teleport:"UNKNOWN";
                }
                if (live) updateLastUpdatedBeam(data.beam, data['record_timestamp']);
            }
            */
            // update the tail


            element_id = tail_list.findIndex(e => e.tail === data.tail);
            //if (!live && data['tail'] === 'TC-JNL') {
            //    logger.debug("processMessageEvent tc-jnl statusupdate", element_id, data);
            //}
            //if (!live && data.tail === 'TC-JNL') logger.debug("processMessageEvent playback1", element_id, data.record_timestamp);

            if (element_id === -1) { // tail doesn't exist
                element_id = tail_list.findIndex(e => e.xid === data.xid);
            }
            if (element_id === -1) { // tail doesn't exist
                        tail_list.push(data);
            }
            else {
               // tail_list.forEach((tail, index) => {
                //    if (tail.tail == data.tail) {
                        //if (!live && data.tail === 'TC-JNL')  logger.debug("processMessageEvent playback statusupdate2", data.tail, data.beam, data['record_timestamp'], tail_list[element_id]['record_timestamp']);
                        if (data['record_timestamp'] >=  tail_list[element_id]['record_timestamp']) {
                            //if (!live) logger.debug("processMessageEvent status: statusupdate3", data.tail, data.beam);
                            tail_list[element_id]['record_timestamp'] = data['record_timestamp'];
                            //if (tail['beam'] != data['beam'] && data['beam'] != "BEAM_Unknown") {
                                //logger.debug("status: statusupdate4", data.tail, data.beam);

                                //tail_list[index]['previous_beam_name'] = tail['beam'];
                                //if (tail_list[index] !== "Switching") {
                                    tail_list[element_id]['beam'] = data['beam'];
                                    tail_list[element_id]['state'] = "Operational";

                                //}
                            //}
                        }
                  //  }
            //    });

            }
        }

        else if(data.record_type === 'statera/terminal/state') {
            socket_state_message = "Active";
            if (!data.state && data.states) data.state = data.states;
            /*
            beam_id = beam_list.findIndex((beam) => {
                return beam.beam_name === data.beam;
            });
            if (beam_id === -1) { // new beam
                new_teleport_beam= {
                    teleport: data.teleport?data.teleport:"UNKNOWN",
                    beam_name: data.beam,
                    last_tail_seen: data['record_timestamp']
                };
                beam_list.push(new_teleport_beam);

            }
            else {
                if (beam_list[beam_id].teleport === "UNKNOWN") {
                    beam_list[beam_id].teleport = data.teleport?data.teleport:"UNKNOWN";
                }
                if (live) updateLastUpdatedBeam(data.beam, data['record_timestamp']);
            }
            */
            //logger.debug("statera/terminal/state", data.state, data);
            if (data.tail) {
                element_id = tail_list.findIndex(e => e.tail === data.tail);
            }
            else {
                element_id = tail_list.findIndex(e => e.xid === data.xid);
            }
            if (!live && data.tail === 'TC-JNL') logger.debug("processMessageEvent playback2", element_id, data, tail_list);
            //if (!live && data['tail'] === 'TC-JNL') {
            //    logger.debug("processMessageEvent tc-jnl state", data['state'], data['tail'], element_id);
            //}


            if (element_id === -1) { // tail doesn't exist
                element_id = tail_list.push(data);
            }
            else {
                if (data['record_timestamp'] > tail_list[element_id]['record_timestamp']) {
                    tail_list[element_id]['state'] = data.state;
                    tail_list[element_id]['record_timestamp'] = data['record_timestamp'];
                    logger.debug("status: state", data.state, element_id, tail_list[element_id].tail, data.state, data.tail, data);
                    if (data['tail']) tail_list[element_id]['tail'] = data.tail;
                    if (data['operator']) tail_list[element_id]['operator'] = data.operator;

                    if (data.state === "Switching" || data.state === "Switched") {
                        if (data['previous_beam_name'] === null || data['previous_beam_name'] === undefined) {
                            data['previous_beam_name'] = "BEAM_Unknown";
                        }
                        if (data['beam_name'] === null || data['previous_beam_name'] === undefined) {
                            data['beam_name'] = "BEAM_Unknown";
                        }
                        if (data.state === "Switching") {
                            /*
                            {
                                "record_type": "statera/terminal/state",
                                "xid": "54680",
                                "window_start": "2024-02-02 21:02:36",
                                "window_end": "2024-02-02 21:02:37",
                                "record_timestamp": "2024-02-02 21:02:12",
                                "state": "Switched",
                                "previous_beam_name": "BEAM_SES14MO10_S01_0070",
                                "beam_name": "BEAM_E10B-H68_X01_0568"
                            }
                            */


                            tail_list[element_id]['next_beam_name'] = data['beam_name'];
                            //if (tail_list[element_id]['beam'] != data['previous_beam_name']) {
                            tail_list[element_id]['beam'] = data['previous_beam_name'];
                            tail_list[element_id]['previous_beam_name'] = null;
                            tail_list[element_id]['usage_total_mb'] = null;

                        }
                        else if (data.state === "Switched") {

                            tail_list[element_id]['next_beam_name'] = null;
                            tail_list[element_id]['beam'] = data['beam_name'];
                            if (tail_list[element_id]['previous_beam_name'] != data['previous_beam_name']) {
                                tail_list[element_id]['previous_beam_name'] = data['previous_beam_name'];
                            }

                           logger.debug("eventListener switched",  data, element_id, tail_list[element_id]);
                            // setTailList(new_tail_list);


                        }
                        // update the beam
                        //logger.debug("testing beam",  tail_list_ref.current[element_id]['beam']);
                        beam_id = beam_list.findIndex((beam) => {
                            return beam.beam_name === tail_list[element_id]['beam'];
                        });
                        if (beam_id === -1) { // new beam

                            new_teleport_beam= {
                                teleport: "UNKNOWN",
                                beam_name: data.beam,
                                last_tail_seen: data['record_timestamp']
                            };
                            beam_list.push(new_teleport_beam);
                        }
                        else {
                            if (live) updateLastUpdatedBeam(data['beam'], data['record_timestamp']);
                        }
                    }
                    else if (data.state === "Operational" || data.state === "NetworkRegained") {
                        if (tail_list[element_id]['next_beam_name']) {
                            tail_list[element_id]['beam'] = tail_list[element_id]['next_beam_name'];
                            tail_list[element_id]['next_beam_name'] = null;
                        }
                        /*
                        {
                            "record_type": "statera/terminal/state",
                            "xid": "95461",
                            "window_start": "2024-02-02 20:42:16",
                            "window_end": "2024-02-02 20:42:17",
                            "record_timestamp": "2024-02-02 20:41:22",
                            "state": "Operational",
                            "previous_beam_name": null,
                            "beam_name": null
                        }
                        */
                        if (live) updateLastUpdatedBeam(data['beam'], data['record_timestamp']);

                    }
                    else if (data.state === "NetworkLost" || data.state === "Logout") {
                        /*
                        {
                            "record_type": "statera/terminal/state",
                            "xid": "53422",
                            "window_start": "2024-02-02 20:46:47",
                            "window_end": "2024-02-02 20:46:48",
                            "record_timestamp": "2024-02-02 20:46:27",
                            "state": "NetworkLost",
                            "previous_beam_name": null,
                            "beam_name": null
                        }
                        */
                         tail_list[element_id]['usage_total_mb'] = null;
                    }
                }
                    //logger.debug("statera/terminal/state END", data.state, data);
            }
        }
        else if(data.record_type == 'sandvine_active_tails') {
            socket_state_message = "Active";
            //if (!live) logger.debug("status: sandvine", data.tail, data.record_timestamp);

            //logger.debug("eventListener sandvine_active_tails",  data);
            /*
            {
                "record_type": "sandvine_active_tails",
                "tail": "HB-JHB",
                "service_id": 100,
                "window_start": "2024-01-22 20:08:00",
                "window_end": "2024-01-22 20:09:00",
                "record_timestamp": "2024-01-22 20:08:00",
                "tx_bytes": 193750,
                "rx_bytes": 806459,
                "total_bytes": 1000209
            }
            */
            element_id = tail_list.findIndex(e => e.tail === data.tail);
            if (element_id === -1) {
                element_id = tail_list.findIndex(e => e.xid === data.xid);
            }
            if (element_id === -1) {
                 data['usage_total_mb'] = (data['total_bytes'] / 1024 / 1024).toFixed(2);
                 element_id = tail_list.push(data);
                 //logger.debug("eventListener sandvine_active_tails adding tail", data.tail, data);
            }
            else {
               // logger.debug("eventListener sandvine_active_tails found",  data);
                tail_list.forEach((element, index) => {
                    if (element.tail === data.tail) {
                        if (element.state == "Login" || element.state === "Operational" || element.state === "NetworkRegained"
                                || element.state === "Switched") {
                            tail_list[index]['usage_total_mb'] = (data['total_bytes'] / 1024 / 1024).toFixed(2);
                            if (live) updateLastUpdatedBeam(element['beam'], data['record_timestamp']);
                        }
                    }
                });
            }
              //let {tail, service_id, record_timestamp, tx_bytes, rx_bytes, total_bytes} = data;

              //logger.debug("eventListener sandvine_active_tails",  data);
              //updateTailUsage(tail, service_id, record_timestamp, tx_bytes, rx_bytes, total_bytes, true);

        }
        else if(data.record_type === 'fds_oooi_event') {
            socket_state_message = "Active";

           // logger.debug("eventListener fds_oooi_event found",  data);
            /*
            {
                "record_type": "fds_oooi_event",
                "window_start": "2024-02-22 20:06:00",
                "window_end": "2024-02-22 20:07:00",
                "operator": "WJA",
                "tail": "C-FKWJ",
                "airport_iata_departure_code": "LAS",
                "airport_iata_arrival_code": "YEG",
                "airport_icao_departure_code": "KLAS",
                "airport_icao_arrival_code": "CYEG",
                "flight_number": "1447",
                "depart_gate_timestamp": "2024-02-22 19:52:00",
                "takeoff_timestamp": "2024-02-22 20:06:00",
                "landing_timestamp": null,
                "arrive_gate_timestamp": null,
                "flight_state_code": "OPEN",
                "flight_uuid": "fc2659e4-6d2c-4fa7-b2b4-460e7966292c",
                "record_created_timestamp": "2024-02-22 19:54:12",
                "record_modified_timestamp": "2024-02-22 20:06:16"
            }
            */

            // find element by name and then by xid if name isn't found
            element_id = tail_list.findIndex(e => e.tail === data.tail);
            if (element_id === -1) {
                element_id = tail_list.findIndex(e => e.xid === data.xid);

                 //logger.debug("eventListener fds_oooi_event adding tail", data.tail, data);
            }

            if (element_id === -1) {
                 element_id = tail_list.push(data);
            }
            else {
                logger.debug("fds_oooi_event found", element_id, tail_list[element_id], data);
                if (data.arrive_gate_timestamp
                    && tail_list[element_id].record_timestamp <= data.arrive_gate_timestamp) {
                   // logger.debug("eventListener fds_oooi_event time",  tail_list[element_id].tail, data.tail, data);
                    tail_list.splice(element_id);
                }
                else if (data.flight_state_code !== "CLOSED" && !data.arrive_gate_timestamp) {
                    //logger.debug("eventListener fds_oooi_event active",  tail_list[element_id].tail, data.tail, data);
                    tail_list[element_id].airport_iata_departure_code = data.airport_iata_departure_code;
                    tail_list[element_id].airport_iata_arrival_code = data.airport_iata_arrival_code;
                    tail_list[element_id].airport_icao_departure_code = data.airport_icao_departure_code;
                    tail_list[element_id].airport_icao_arrival_code = data.airport_icao_arrival_code;
                }
                /*
                else if (data.flight_state_code === "CLOSED") {
                    logger.debug("eventListener fds_oooi_event CLOSED",
                            data.tail,
                            tail_list[element_id].record_timestamp,
                            data.flight_state_code,
                            data.airport_iata_departure_code,
                            data.airport_iata_arrival_code,
                            data.depart_gate_timestamp,
                            data.takeoff_timestamp,
                            data.landing_timestamp,
                            data.arrive_gate_timestamp
                    );
                }
                */
                if (live) updateLastUpdatedBeam(tail_list[element_id]['beam'], data['record_timestamp']);

            }
        }
        else if(data.record_type == 'lcm_replay_request') {
            //logger.debug("processMessageEvent lcm_replay_request LCMReplayService", data.record_type);

            /*
                {
                    'record_type': 'lcm_replay_request',
                    'record_data': {
                        'request_id': '0ba0e3fd-29d4-449b-99da-1d46b999e757',
                        'status': 'COMPLETE'
                    }
                }
            */

            if (lcm_request_id.current === data['record_data']['request_id'] && data['record_data']['status'] === 'COMPLETE') {
                setTimelineState("pause");
            }
            else {
                setTimelineState("error");
            }
        }
        else if (data.record_type === 'statera_beam_report') {
            //logger.debug("processMessageEvent statera_beam_report", data);
            /*
            {
                "record_type": "statera_beam_report",
                "window_start": "2024-05-13 21:29:30",
                "window_end": "2024-05-13 21:29:45",
                "record_timestamp": "2024-05-13 21:28:05",
                "beam": "BEAM_SES14CA08_S01_0467",
                "dmm_state_accessible": true,
                "fwd_link_utalized": 25.795248,
                "fwd_link_delay": 0,s
                "rtn_link_utalized": 0,
                "terminals_list": "",
                "terminals_count": 0,
                "terminals_limit": 24,
                "metrics_lastupdate": null,
                "teleport": "HTL"
            }
            */
            beam_id = beam_list.findIndex((beam) => {
                return beam.beam_name === data.beam;
            });
            if (beam_id === -1) { // new beam
                // do nothing
                 logger.debug("processMessageEvent statera_beam_report found beam not", data);
            }
            else {
                logger.debug("processMessageEvent statera_beam_report found beam", data);
               // beam_list['beam_id']['dmm_state_accessible'] = data['dmm_state_accessible'];
                beam_list[beam_id]['fwd_link_utalized'] = data['fwd_link_utalized'].toFixed(2);
                beam_list[beam_id]['rtn_link_utalized'] = data['rtn_link_utalized'].toFixed(2);
                beam_list[beam_id]['fwd_link_delay'] = data['fwd_link_delay'];
                beam_list[beam_id]['terminals_count'] = data['terminals_count'];
                beam_list[beam_id]['terminals_limit'] = data['terminals_limit'];

            }
        }
        else {
            //logger.debug("processMessageEvent other LCMReplayService", data.record_type);
        }


        return socket_state_message;
    }

    const connect_to_ws = () => {
        // Get a reference to the status indicator element in your HTML
        //const statusIndicator = document.getElementById('ws-status-indicator');
        // Create a WebSocket connection


        logger.debug("connect_to_ws", socket);
        // Event listener for WebSocket messages
        socket.addEventListener('message', (event) => {
            logger.debug("connect_to_ws1", event);
            var event_data = JSON.parse(event.data);

            if(event_data.record_type == 'lcm_replay_request') {
                logger.debug("connect_to_ws2 lcm_replay_request", event_data.record_type, event_data);
            }
            var socket_state_message = processMessageEvent(JSON.parse(event.data), tail_list_ref.current, teleport_list_ref.current);

            if (!socket_state_message) socket_state_message = "Open";
            setSocketState(socket_state_message);

        });

        // Add event listeners to handle WebSocket events
        socket.addEventListener('open', () => {
            logger.debug("connect_to_ws3 open");
            setSocketState("Open");
        });

        socket.addEventListener('close', () => {
             logger.debug("connect_to_ws3 closed");
            // Connection has been closed
            setSocketState("Closed");
            sleep(5).then(() => {
                connect_to_ws();
            });
        });

         socket.addEventListener('offline', () => {
            // Connection has gone offline
            logger.debug("connect_to_ws3 offline");
            setSocketState("Closed");
            sleep(5).then(() => {
                connect_to_ws();
            });
        });

        socket.addEventListener('error', (error) => {
             logger.debug("connect_to_ws3 error");
            setSocketState("Error");
            sleep(5).then(() => {
        });
                connect_to_ws();
            });
    };

    function getTabJSX(title, value) {
        return (
            <Grid item>
                <div style={{borderRight: "solid 4px #133859", borderLeft: "solid 4px #133859", borderBottom: "solid 4px #133859"}}>
                <Button variant="contained" style={{ color: "#ffffff", backgroundColor: "#133859",
                    borderBottom: tab===value?"solid 4px #FFD709":"solid 4px #133859" }} size="small" onClick={() => {setTab(value)}}>
                    {title}
                </Button>
                </div>
            </Grid>
        );
    }



    function fetch_teleport_beam_mapping() {

        var service = new TeleportBeamMappingService();
        service.fetchRecords()
        .then((data) => {
            logger.debug("TeleportBeamMappingService success", data);


                /* Data Structure
                {
                    teleport: 'GNA',
                    beam_name: 'BEAM_APS6D-B84B_S01_0430',
                    last_tail_seen: '2024-01-11 15:59:31.925'
                }
                */
                teleport_list_ref.current = data.data;
                //setTeleportBeamMapping(data.data);
                fetch_beam_tail_status_update();
               // prime_dashboard_teleport_beams(data.data);
        })
        .catch((err) => {
          logger.debug('TeleportBeamMappingService error', err);
        });
    }


    /**
     * Load the initial snapshot for the aircraft
     */
    function fetch_beam_tail_status_update() {
        var service = new TailStatusService();
        service.fetchRecords()
         .then((data) => {

            logger.debug('fetch_beam_tail_status_update', data.data);

            data.data.forEach((tail) => {
                /*
                {
                    "record_timestamp": "2024-02-09 19:52:11.500",
                    "xid": "51922",
                    "operator": "EVA",
                    "tail": "B-16730",
                    "beam": "BEAM_E172BS09_S01_0065",
                    "state": "Switching",
                    "visible": true
                }
               */
                var teleport_index = teleport_list_ref.current.findIndex(e => e.beam_name === tail.beam);
                if (teleport_index === -1) {
                    logger.debug("beam not found", tail);
                }
                else {
                    //logger.debug("fetch_beam_tail_status_update updating beam last seen2", teleport_list_ref.current[teleport_index]['beam_name'], teleport_list_ref.current[teleport_index]['last_tail_seen'], tail['tail'], tail['record_timestamp']);
                    /*
                    teleport_list_ref.current.forEach((beam, index) => {
                        if (beam.beam_name === tail.beam) {
                            if (data['record_timestamp'] > beam['last_tail_seen'] || beam['last_tail_seen'] === undefined ) {
                                logger.debug("fetch_beam_tail_status_update updating beam last seen1", teleport_list_ref.current[teleport_index]['beam_name'], teleport_list_ref.current[teleport_index]['last_tail_seen'], tail['tail'], tail['record_timestamp']);

                                teleport_list_ref.current[index]['last_tail_seen'] = tail['record_timestamp'];
                            }
                        }
                    });
                    */

                    if (tail['record_timestamp'] > teleport_list_ref.current[teleport_index]['last_tail_seen'] || teleport_list_ref.current[teleport_index]['last_tail_seen'] === undefined ) {
                        logger.debug("fetch_beam_tail_status_update updating beam last seen2", teleport_list_ref.current[teleport_index]['beam_name'], teleport_list_ref.current[teleport_index]['last_tail_seen'], tail['tail'], tail['record_timestamp']);

                        teleport_list_ref.current[teleport_index]['last_tail_seen'] = tail['record_timestamp'];
                    }

                }

            });

            tail_list_ref.current = data.data;
            connect_to_ws();
        })
        .catch((err) => {
          logger.debug('TailStatusService error', err);
        });
    }


    var teleport_list = [];

    /**
     * bd
     */
    function summaryGridItem(title, color, value, total, show_percent, tooltip = null, onclick = null) {
        var percent;
        if (total !== 0 && show_percent) percent =<div style={{float: "right", fontSize: "24px"}}>{((value/total)*100).toFixed(1)}% </div>;
        if (onclick) {
            title = <a style={{cursor: "pointer"}} title={tooltip} onClick={onclick}>
                {title}
            </a>;
        }


        return <Grid item>
                    <Paper elevation={0} style={{backgroundColor: "#e0e0e0", border: "solid 0px black", padding: "4px 24px 4px 4px"}}>
                        <div id="kpi-Operational" style={{width: "180px", height: "80px", borderLeft: "10px solid " + color, paddingTop: "5px", paddingLeft: "8px"}}>

                            <div style={{fontWeight: "bold"}} >
                                {title}
                            </div>
                            {percent}
                            <div style={{fontSize: "24px"}}>{value}</div>
                            <div>of {total}</div>

                        </div>
                    </Paper>
                </Grid>;
    }

    /**
     * get the data for the Beam Summary Table/Grid
     */
    function getBeamSummaryData() {

        var table_data = [];
        beam_keys.forEach((key) => {
            if (beams[key]['visible']) {
                 var data = {
                    beam: beams[key].beam_name,
                    last_tail_seen: beams[key].last_tail_seen.substring(0, 19),
                    teleport: beams[key]['teleport'],
                    tail_count: 0,
                    Operational_total: 0,
                    Inactive_total: 0,
                    Neveractive_total: 0,
                    NonOperational_total: 0,
                    type: beams[key]['type']
                };
                aircraft_states.forEach((ac_state) => {
                    data[ac_state.name] = 0;
                });
                table_data.push(data);
            }

        });
        tail_list_ref.current.forEach((tail) => {
            if (tail.visible) {
                if (!tail.state) tail.state = "Unknown";
                if (beams[tail['beam']] && beams[tail['beam']]['visible']) {
                    var index = table_data.findIndex((element) => {
                        return element.beam === tail.beam;
                    });
                    if (index === -1) { // add the row
                        var data = {
                            beam: tail.beam,
                            tail_count: 1,
                            teleport: beams[tail.beam]['teleport']
                        };
                        aircraft_states.forEach((ac_state) => {
                            data[ac_state.name] = 0;
                            if (ac_state.name === tail.state && aircraft_checkbox_state[tail.state]) {
                                data[ac_state.name] = 1;
                                if (ac_state.operational) {
                                    table_data[index]['Operational_total'] = 1;
                                }
                                else if (ac_state.active) {
                                    table_data[index]['NonOperational_total'] = 1;
                                }
                                else if (!ac_state.active) {
                                    table_data[index]['Inactive_total'] = 1;
                                }
                            }

                        });
                        table_data.push(data);
                    }
                    else {

                        aircraft_states.forEach((ac_state) => {
                            if (ac_state.name === tail.state  && aircraft_checkbox_state[tail.state]) {
                                table_data[index]['tail_count']++;
                                table_data[index][tail.state] ++;
                                if (ac_state.operational) {
                                    table_data[index]['Operational_total']++;
                                }
                                else if (ac_state.active) {
                                    table_data[index]['NonOperational_total']++;
                                }
                                else if (!ac_state.active) {
                                    table_data[index]['Inactive_total']++;
                                }
                            }

                        });
                    }
                }
            }
        });
        return table_data;

    }

    useEffect(() => {
        if (!teleport_data_begun) {
            fetch_teleport_beam_mapping();
            setTeleportDataBegun(true);
        }
    }, [teleport_data_begun]);

    useEffect(() => {
        return () => { // triggers when the component dismounts
          socket.close();
        };
    }, []);

    /**
     * Because we are keeping the data in a non-state object. This needs to run to refresh those changes on the second.
     */
    useEffect(() => {
        var now = Date.now();
        const interval = setInterval(() => setRefresh(now), 1000);

        /*
        var snapshot_timestamp = Math.round((now + 60000)/60000)*60000;
        if (!timeline_data_ref[snapshot_timestamp]) {
            timeline_data_ref[snapshot_timestamp] = teleport_list_ref;
            logger.debug("useEffect", now,  Math.round((now + 1000)/1000)*1000, Math.round((now + 60000)/60000)*60000);
        }
        */
        logger.debug("loadTimelineData useEffect refresh", timeline_state, loadingCount.current);

        if (timeline_state === "forward") {

            setTimelineState("forward_loading");
            loadTimelineData(timeline_timestamp, () => {
                var tmp = Math.round((timeline_timestamp + timeline_timestamp_ff)/timeline_timestamp_ff)*timeline_timestamp_ff;
                var new_time;
                for (var i=timeline_timestamp; i < tmp; i+=1000) {
                    if (i < timeline_min_timestamp + timeline_timestamp_delta) {
                        applyTimelineData(i);
                        new_time = i;
                    }
                }
                if (new_time < timeline_min_timestamp + timeline_timestamp_delta) {
                    setTimelineTimestamp(new_time+ 1000);
                    setTimelineState("forward");
                }
                else {
                     setTimelineState("pause");
                }
            });

        }
        else if (timeline_state === "step") {
            setTimelineState("step_loading");
            loadTimelineData(timeline_timestamp, () => {
                applyTimelineData(timeline_timestamp);
                var tmp = timeline_timestamp + 1000;
                setTimelineTimestamp(tmp);
                setTimelineState("pause");
            });
        }

        else  if (timeline_state === "stepforward") {

            setTimelineState("stepforward_loading");
            loadTimelineData(timeline_timestamp, () => {
                var tmp = Math.round((timeline_timestamp + timeline_timestamp_ff)/timeline_timestamp_ff)*timeline_timestamp_ff;
                var new_time;
                for (var i=timeline_timestamp; i < tmp; i+=1000) {
                    if (i < timeline_min_timestamp + timeline_timestamp_delta) {
                        applyTimelineData(i);
                        new_time = i;
                    }
                }
                setTimelineTimestamp(new_time+ 1000);
                setTimelineState("pause");

            });


        }
        else if (timeline_state === "play") {
            setTimelineState("play_loading");
            logger.debug("play_loading1");

            loadTimelineData(timeline_timestamp, () => {
                logger.debug("play_loading2");
                applyTimelineData(timeline_timestamp);
                setTimelineState("play_loaded");
                logger.debug("play_loading3");

            });
        }
        else if (timeline_state === "play_loaded") {
            if (timeline_state_interupted) {
                setTimelineState("pause");
            }
            else {
                var tmp = timeline_timestamp + 1000;
                if (tmp > timeline_min_timestamp + timeline_timestamp_delta) {
                    tmp = timeline_min_timestamp + timeline_timestamp_delta;
                    setTimelineState("pause");
                }
                else {
                    setTimelineState("play");
                }
                setTimelineTimestamp(tmp);
            }
        }
        else if (timeline_state === "catchup") {
            setTimelineState("catchup_loading"); // just so it doesn't re-trigger until done
            tail_list_replay_ref.current = [];
            teleport_list_replay_ref.current = [];
            var dateObj = new Date(timeline_min_timestamp);
            var dateObj2 = new Date(timeline_timestamp);

            logger.debug("Catchup1", timeline_min_timestamp, dateObj.toString(), timeline_timestamp, dateObj2.toString());
            loadTimeRange(timeline_min_timestamp, timeline_timestamp);
            /*
             var tmp = Math.round((timeline_timestamp + timeline_timestamp_jump)/timeline_timestamp_jump)*timeline_timestamp_jump;
                                                    if (tmp > timeline_min_timestamp + timeline_timestamp_delta) {
                                                        tmp = timeline_min_timestamp + timeline_timestamp_delta;
                                                    }
            loadTimelineData(timeline_timestamp, () => {

                logger.debug("loadTimelineData play", tmp);

                applyTimelineData(timeline_timestamp);
              */
            //setTimelineState("pause");




        }
        else if (timeline_state === "loading") {
            loadingCount.current++;
            if (loadingCount.current === 10) {
                 logger.debug("loadTimelineData useEffect refresh", loadingCount.current);
                 setTimelineState("load");
            }
        }

        /*
        else if (timeline_state == "jumpforward") {
            tmp = Math.round((timeline_timestamp + 60000)/60000)*60000;

            if (tmp < now) {
                setTimelineTimestamp(tmp);
            }
            else {
                 setTimelineTimestamp(now);
                 setTimelineState("live");
            }
        }
        else if (timeline_state == "jumprewind") {
            tmp = Math.round((timeline_timestamp - 60000)/60000)*60000;
            setTimelineTimestamp(tmp);
        }
        else if (timeline_state == "steprewind") {
            setTimelineTimestamp(timeline_timestamp - 1000);
            setTimelineState("pause");
        }
        else if (timeline_state == "stepforward") {
            setTimelineTimestamp(timeline_timestamp + 1000);
            setTimelineState("pause");
        }
        */

        /*
        var new_record = {y: Math.floor(Math.random() * (1 - 5) + 5), x: Date.now()};
        timeline_data.push(new_record);
        setTimelineData(timeline_data);
        */
        //logger.debug("useEffect refresh");
    //     if (gridBeamRef.current && gridBeamRef.current.api ) {
            //logger.debug("Refresh GridBeamData2", getBeamSummaryData());
            //gridBeamRef.current.api.setGridOption('rowData', getBeamSummaryData());
            //gridBeamRef.current.api.setDatasource(getBeamSummaryData());
            //gridBeamRef.current.api.setRowData(getBeamSummaryData());
            //gridBeamRef.current.api.setFilterModel(gridBeamFiltersRef.current);
            //gridBeamRef.current.api.setNodesSelected(gridSelectedRows);
            // this does not work. :(
            //gridBeamRef.current.api.forEachNode(node=> node.rowIndex === gridSelectedRows.current ? 0 : node.setSelected(true))
        //}
        return () => {

            clearInterval(interval);
        };

    }, [refresh]);

    useEffect(() => {
        if (timeline_state === 'load') {
            loadingCount.current = 0;
            logger.debug("LCMReplayService load");
            tail_list_replay_ref.current = [];
            teleport_list_replay_ref.current = [];
            setTimelineState("loading");
            setTimelineTimestamp(timeline_start_date + (timeline_start_hour * 1000));
            setMinTimelineTimestamp(timeline_start_date + (timeline_start_hour * 1000));


            var d = new Date(timeline_start_date + (timeline_start_hour * 1000));

            var new_d = new Date(d.getTime() - d.getTimezoneOffset()*60000);
            timeline_datestring = new_d.toISOString().substr(0, 10);

            var timeline_start_hour_formatted =  (timeline_start_hour/60/60).toString().padStart(2, '0') + ":00:00";

            var query = {
                    startdate: timeline_datestring,
                    starttime: timeline_start_hour_formatted
            };
            if (lcm_request_id.current) {
                query['request_id'] = lcm_request_id.current;
            }
            logger.debug("LCMReplayService query", query);
            var service = new LCMReplayService();

            service.fetchRecords(query)
            .then((response) => {
                logger.debug("LCMReplayService success", response);
                if (response['data'][0]['request_id']) {
                    lcm_request_id.current = response['data'][0]['request_id'];
                    // check cache flag.
                    if (response['data'][0]['cached'] === true) { // VALUE TBD
                        setTimelineState("pause");
                    }
                }
                else {
                    setTimelineState("error");
                }
            })
            .catch((err) => {
                setTimelineState("error");
                logger.debug('LCMReplayService error', err);
            });


            /*
            setTimelineState("pause");LCMReplayService

            */
        }
    }, [timeline_state]);

    var content = "Loading ...";

    var tail_list;
    var beam_list;
    if (timeline_state === null) {
        tail_list = tail_list_ref.current;
        beam_list = teleport_list_ref.current;
    }
    else {
        tail_list = tail_list_replay_ref.current;
        beam_list = teleport_list_replay_ref.current;
    }

    var beams = {};
    var beam_keys = [];
    var total_aircraft = 0; // excluding inactive aircraft

    // Run this when we have teleport data and tail list data.
    if (beam_list.length) {
        beam_list.forEach((teleport_beam, index) => {
            beam_list[index]["aircraft_count"] = 0;
            if (!teleport_beam.beam_name) teleport_beam.beam_name = "BEAM_Unknown";
            // set the teleport_list for the dropdown
            if (!teleport_list.includes(teleport_beam.teleport)) {
                teleport_list.push(teleport_beam.teleport);
            }
            beam_list[index]["visible"] = true;
            beam_list[index]["included"] = true;
            beam_list[index]["type"] = "Empty";
            if (search_teleports.length && !search_teleports.includes(teleport_beam.teleport)) {
                beam_list[index]["visible"] = false;
                beam_list[index]["included"] = false;
            }
            if (search_beams.length && !search_beams.includes(teleport_beam.beam_name)) {
                beam_list[index]["visible"] = false;
                beam_list[index]["included"] = false;
            }
            // beam search name hides non-matching
            if (search_beam_name) {
                if (teleport_beam['beam_name'] && !teleport_beam['beam_name'].toUpperCase().includes(search_beam_name.toUpperCase())) {
                    beam_list[index]['visible'] = false;
                    beam_list[index]['included'] = false;
                }
                else {
                    beam_list[index]['visible'] = true;
                    beam_list[index]['included'] = true;
                }
                if (teleport_beam.teleport && search_teleports.length && search_teleports.includes(teleport_beam.teleport)) {
                    beam_list[index]['visible'] = true;
                    beam_list[index]['included'] = true;
                }
            }
            if (beam_list[index]['fwd_link_utalized'] === undefined && beam_list[index]['rtn_link_utalized'] === undefined) {
                if (hide_utilization) {
                    beam_list[index]['visible'] = false;
                    beam_list[index]['included'] = false;
                }
            }
            else {
                // check sliders
                if (beam_list[index]['fwd_link_utalized'] <= fwd_slider_value) {
                    beam_list[index]['visible'] = false;
                    beam_list[index]['included'] = false;

                }
                if (beam_list[index]['rtn_link_utalized'] <= rtn_slider_value) {
                    beam_list[index]['visible'] = false;
                    beam_list[index]['included'] = false;

              }
            }

            beams[teleport_beam.beam_name] = beam_list[index];
            if (!beam_keys.includes(teleport_beam.beam_name)) {
                beam_keys.push(teleport_beam.beam_name);
            }
            else {
                //logger.debug("duplicate beam", teleport_beam);
            }
        });
      //  logger.debug("duplicate beam count", teleport_beam_mapping.length, beam_keys.length);

        teleport_list.sort();
        beam_keys.sort();

        var new_operator_list = [];
        var new_aircraft_list = [];
        var new_route_list = [];
        tail_list.forEach((element, index) => {
            // add all the route to the drop down
            if (element.airport_iata_departure_code) {
                new_route_list.push(element.airport_iata_departure_code + ' (' + element.airport_icao_departure_code +  ') → ' +  element.airport_iata_arrival_code + ' (' + element.airport_icao_arrival_code + ")");
            }

            try {
                tail_list[index]['visible'] = beams[element['beam']]['visible'];
            }
            catch(e) {
                logger.debug("Missing beam!", beams, element);
            }
            if (search_operators.length && !search_operators.includes(element.operator)) {
                tail_list[index]['visible'] = false;
            }

             if (search_exclude_operators.length && search_exclude_operators.includes(element.operator)) {
                tail_list[index]['visible'] = false;
            }
            if (tail_list[index]['visible']) {
                new_aircraft_list.push(element.operator + ": " + element.tail);

            }



            if (search_aircraft.length && !search_aircraft.includes(element.operator + ": " + element.tail)) {
                tail_list[index]['visible'] = false;
            }
            if (search_routes.length) {

                var route_visible = false;
                search_routes.forEach((route) => {
                    logger.debug("search_routes", route, route.substr(5, 4), route.substr(18, 4));

                    if (element['airport_icao_departure_code'] === route.substr(5, 4) &&
                        element['airport_icao_arrival_code'] === route.substr(18, 4)) {
                            route_visible = true;
                        }
                })
                tail_list[index]['visible'] = route_visible;
            }


            if (tail_list[index]['visible']) {
                if (!element.state) tail_list[index].state = "Unknown";
                aircraft_states.forEach((state) => {
                    if (state.name == element.state) {
                        state['count']++;
                        aircraft_groups.forEach((group)=> {
                            if (state.group === group.name) {
                                group.count++;
                            }
                        });
                        if (state.active) total_aircraft++;
                    }

                });

            }

            if (aircraft_checkbox_state[element.state] === false) {
                tail_list[index]['visible'] = false;
            }
            if (element['usage_total_mb'] === undefined || element['usage_total_mb'] === null) {
                if (hide_usage) {
                    tail_list[index]['visible'] = false;
                }
            }
            else {
                if (bandwidth_slider_value !== max_bandwidth && element['usage_total_mb'] > bandwidth_slider_value) {
                    tail_list[index]['visible'] = false;
                }
            }

            if (beam_keys.includes(element.beam)) {
                beams[element.beam]["aircraft_count"]++;
            }

            if (element['visible']) {

                if (beam_keys.includes(element.beam)) {
                    if (beams[element.beam]['type'] === "Empty") {
                        beams[element.beam]['type'] = "NonEmpty";
                    }
                }
                else {
                    // unknown beam (should never happen if your updates are working)
                }


            }
            if (!new_operator_list.includes(element.operator))
                new_operator_list.push(element.operator);

        });
        new_operator_list.sort();
        new_aircraft_list.sort();
        new_route_list.sort();

        if (JSON.stringify(operator_list) !== JSON.stringify(new_operator_list)) {
           setOperatorList(new_operator_list);
        }

        if (JSON.stringify(aircraft_list) !== JSON.stringify(new_aircraft_list)) {
           setAircraftList(new_aircraft_list);
        }

        if (JSON.stringify(route_list) !== JSON.stringify(new_route_list)) {
           setRouteList(new_route_list);
        }


        beam_keys.forEach((element) => {
            if (search_operators.length && beams[element]['type'] === "Empty") {
                beams[element]['visible'] = false;
                beams[element]['included'] = false;
            }
            if (search_aircraft.length && beams[element]['type'] === "Empty") {
                beams[element]['visible'] = false;
                beams[element]['included'] = false;
            }
            if (search_routes.length && beams[element]['type'] === "Empty") {
                beams[element]['visible'] = false;
                beams[element]['included'] = false;
            }
        });

        beam_keys.forEach((element) => {
            if (beams[element]['included']) {
                if (timeline_state === null) {
                    if (beams[element]['last_tail_seen'] ) {
                        let utcTimestamp = Date.parse(beams[element]['last_tail_seen'].substring(0, 19));

                        // Get the current UTC time as a Date object
                        let currentUtcTime = Date.now();

                        // Calculate the time difference in seconds
                        let timeDifferenceInSeconds = (currentUtcTime - utcTimestamp) / 1000;
                        if (timeDifferenceInSeconds > 3600) {
                            beams[element]['type'] = 'Inactive';
                        }
                    }
                    else {
                        beams[element]['type'] = 'NeverActive';
                    }
                }
                beam_states.forEach((beam_state) => {
                    if (beam_state.name === beams[element]['type']) {
                        beam_state.count++;
                        //beams_total++;
                    }
                });
            }
            // hide the unchecked beam types
            if (beam_checkbox_state[beams[element]['type']] === false) beams[element]['visible'] = false;
        });


    }

    // Set the URL to the filters
    var query_string_array = [];
    if (search_beam_name) query_string_array.push("beam=" + search_beam_name);
    if (search_operators.length) query_string_array.push("operator_icao_code=" + search_operators.join(','));
    if (search_exclude_operators.length) query_string_array.push("excluded_operator_icao_code=" + search_exclude_operators.join(','));
    if (search_aircraft.length) query_string_array.push("aircraft=" + search_aircraft.join(','));
    //if (search_route.length) query_string_array.push("route=" + search_aircraft.join(','));
    if (search_teleports.length) query_string_array.push("teleport=" + search_teleports.join(','));
    if (search_beams.length) query_string_array.push("beams=" + search_beams.join(','));
    if (search_routes.length) query_string_array.push("routes=" + search_routes.join(','));


    if (hide_usage) query_string_array.push("hide_usage=1");
    if (hide_utilization) query_string_array.push("hide_utilization=1");

    if (bandwidth_slider_value !== max_bandwidth) query_string_array.push("max_bandwidth=" + bandwidth_slider_value);
    if (fwd_slider_value !== 0) query_string_array.push("fwd_utilized=" + fwd_slider_value);
    if (rtn_slider_value !== 0) query_string_array.push("rtn_utilized=" + rtn_slider_value);

    for (const [key, value] of Object.entries(beam_checkbox_state)) {
        if (value === true) {
            query_string_array.push(key + "=1");
        }
        else {
            query_string_array.push(key + "=0");
        }
    }

    for (const [key, value] of Object.entries(aircraft_checkbox_state)) {
        if (value === true) {
            query_string_array.push(key + "=1");
        }
        else {
            query_string_array.push(key + "=0");
        }
    }
    var query_string = "?";
    if (query_string_array.length) {
        query_string += query_string_array.join('&');
    }
    url.pushState("", null, query_string, app_context.state.page_title);


    var visible_beam_count = 0;
    var beam_count = beam_keys.length;
   // var beams_total = 0;
    var visible_tail_count = 0;
    var tail_count = tail_list.length;
    var operational_tail_count = 0;
    var active_tail_count = 0;
    var switching_tail_count = 0;
    var network_regained_tail_count = 0;
    var network_lost_tail_count = 0;

    beam_states.forEach((beam_state) => {
        visible_beam_count+=beam_state.count;
    });

    aircraft_states.forEach((aircraft_state) => {

        visible_tail_count+=aircraft_state.count;
        if (aircraft_state.operational)
            operational_tail_count+=aircraft_state.count;
        if (aircraft_state.active)
            active_tail_count+=aircraft_state.count;
        if (aircraft_state.name === "Switching")
            switching_tail_count+=aircraft_state.count;
         if (aircraft_state.name === "NetworkRegained")
            network_regained_tail_count+=aircraft_state.count;
         if (aircraft_state.name === "NetworkLost")
            network_lost_tail_count+=aircraft_state.count;
    });

    /**
     * start_timestamp timestamp not in UTC
     * end_timestamp timestamp not in UTC
     */
    function loadTimeRange(start_timestamp, end_timestamp) {
        logger.debug("loadTimeRange", start_timestamp, end_timestamp);
        var i = start_timestamp;
        var process = function() {
            for ( ; i < end_timestamp; i+=1000) {
                loadTimelineData(i, () => {
                    logger.debug("loadTimeRange i", i);
                    applyTimelineData(i);
                    if (i === end_timestamp - 1000) {
                        setTimelineState("pause");
                    }
                });
                if (i + 1 < end_timestamp && i % 50000 === 0) {
                    setTimeout(process, 5);
                }
            }

        };
        process();
    }

    /**
     * Run the timeline data through processMessageEvent
     */
    function applyTimelineData(timestamp) {
        var date = new Date(timestamp);
        var date_time = date.getFullYear() + "-" + (date.getMonth()+1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0') +
                " " + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":" + date.getSeconds().toString().padStart(2, '0') ;
        //logger.debug("applyTimelineData date_time", date_time, timeline_data_ref.current[date_time]);
        if (timeline_data_ref.current[date_time] !== undefined) {
            for (const [key, value] of Object.entries(timeline_data_ref.current[date_time])) {
                //logger.debug("applyTimelineData value", key, value);
                value.forEach((element, index)=> {
                    //if (element.tail === "TC-JNL")
                    //    logger.debug("applyTimelineData element1", key, index, element);
                    //logger.debug("applyTimelineData element2", element.tail,  element);
                    processMessageEvent(element, tail_list_replay_ref.current, teleport_list_replay_ref.current, false);
                });
            }
        }
        return null;
    }

    async function loadTimelineData(timestamp, callback) {
        var date = new Date(timestamp);
        var date_time = date.getFullYear() + "-" + (date.getMonth()+1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0') +
                " " + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":00";
       // logger.debug("loadTimelineData2 date_time", date_time);
        //e.g.: 0b655851-3477-4d54-b575-ec3989180c88/public/dt=20240306/hour=4/minute=1/
        if (timeline_data_ref.current[date_time] !== undefined) {
            //logger.debug("loadTimelineData cached", key);
            callback();
        }
        else {
            timeline_data_ref.current = {};
        }

        const event_types = [
            'fds_oooi_event',
            'sandvine_active_tails',
            'statera_terminal_state',
            'statera_terminal_statusupdate',
            'statera_beam'
        ];
        //const results = async _ => {
            for (let index1 = 0; index1 < event_types.length; index1++) {
                var key = lcm_request_id.current + '/type=' + event_types[index1] + '/dt=' + date.getFullYear() + (date.getMonth()+1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0') + "/hour=" + date.getHours() + "/minute=" + date.getMinutes() +  "/";
                logger.debug("loadTimelineData2 key", key);
                try{
                    var files = await Storage.list(key, { level: 'public' });
                    logger.debug("loadTimelineData2 files", event_types[index1], files);
                }
                catch(err) {
                    logger.debug("loadTimelineData2 error", err);
                }
                //const results = async _ => {
                    for (let index = 0; index < files.length; index++) {
                        logger.debug("loadTimelineData2 file", event_types[index1], files[index]);
                        var file_content;
                        try{

                            file_content = await Storage.get(files[index]['key'], { download: true, level: 'public' });
                            var tmp = new TextDecoder().decode(file_content.Body).split("\n");
                            //logger.debug("loadTimelineData2 file_content1", tmp.length);

                            tmp.forEach((element) => {
                                /* sample element
                                {
                                    "record_type": "statera/terminal/statusupdate",
                                    "record_timestamp": "2024-03-06 04:01:44.416",
                                    "beam": "BEAM_SES15-S12_S01_0087",
                                    "operator": "DLH",
                                    "tail": "D-AIMN",
                                    "xid": 51292
                                }
                                */
                                if (element) {

                                    //logger.debug("loadTimelineData2 file_content2", element);
                                    try {
                                        var element_obj = JSON.parse(element);
                                         if (element_obj.tail === 'TC-JNL') {
                                            //logger.debug('loadTimelineData2 element',element);
                                        }
                                         element_obj['record_timestamp'] = element_obj['record_timestamp'].substring(0, 19);
                                        var timestamp = element_obj['record_timestamp'];

                                        //logger.debug('loadTimelineData2 file_content4 timestamp', timestamp, element_obj);
                                        if (timeline_data_ref.current[timestamp] === undefined) {
                                            timeline_data_ref.current[timestamp] = {};
                                        }
                                        if (timeline_data_ref.current[timestamp][event_types[index1]]  === undefined) {
                                            timeline_data_ref.current[timestamp][event_types[index1]] = [];
                                        }
                                        var found = timeline_data_ref.current[timestamp][event_types[index1]].find((e) => {
                                            return JSON.stringify(e) === JSON.stringify(element_obj);
                                        });
                                        if (!found)
                                            timeline_data_ref.current[timestamp][event_types[index1]].push(element_obj);
                                        else {
                                            //logger.debug("loadTimelineData2 duplicate", element_obj);
                                        }
                                    }
                                    catch (e) {
                                       logger.debug('loadTimelineData Error parsing JSON', e);
                                    }
                                }
                            });
                        }
                        catch(err) {
                            logger.debug("loadTimelineData2 err", err);
                        }

                    }
                //};
                //logger.debug("loadTimelineData2 compete1");
            }
            //logger.debug("loadTimelineData2 compete2", timeline_data_ref.current);
            callback();
        //};
    }


    /**
     * Tab0
     *
     * format for tail_list:
     *   {
     *      "record_timestamp": "2024-03-19 16:51:05.946",
     *      "xid": "53491",
     *      "operator": "UAL",
     *      "tail": "N13013",
     *      "beam": "BEAM_IS46e-S48_S01_0648",
     *      "state": "Logout",
     *      "visible": false
     *   }
     *
     * beam_keys is an array of keys to find the proper beam
     */
    function tileContent(tail_list, beam_keys, live=true) {
        //logger.debug("tileContent", tail_list, beam_keys);
        var  content = [];
        beam_keys.forEach((key) => {
            //logger.debug("tileContent2 ", key, beams[key]);
            if (beams[key]['visible']) {
                var beam_name = key.substring(5);
                var teleport;
                var last_tail_seen;

                let tails = [];
                tail_list.forEach((element) => {
                    if (element['beam'] === key && element['visible']) {
                        tails.push(element);
                    }
                });
                //logger.debug("tails", tails);
                let header_color = "#454545"; //"#212529";
                teleport = beams[key].teleport;
                last_tail_seen =  beams[key].last_tail_seen?beams[key].last_tail_seen.substring(0, 19):"";
                teleport = beams[key].teleport;
                if (beams[key]['type'] === "Inactive") {
                    beam_states.forEach((beam_state) => {
                    if (beam_state.name === "Inactive") {
                        header_color = beam_state.color;
                    }
                });
            }
            else if (beams[key]['type'] === "NeverActive") {
                beam_states.forEach((beam_state) => {
                    if (beam_state.name === "NeverActive") {
                        header_color = beam_state.color;
                    }
                });
            }
            if (!beams[key].last_tail_seen) last_tail_seen = "n/a";

            var teleport_url_title = "Click here to set the Teleports filter to " + teleport;

            var utilized;
            if (beams[key]['fwd_link_utalized'] !== undefined && beams[key]['rtn_link_utalized'] !== undefined) {
                var bad_style = {color: "white", backgroundColor: "#e10000", padding: "2px", borderRadius: 2};
                var fwd_style = {};

                if (beams[key]['fwd_link_utalized'] > 90) fwd_style = bad_style;
                var rtn_style = {};
                if (beams[key]['rtn_link_utalized'] > 90) rtn_style = bad_style;
                var fwd_link_delay_style = {};
                if (beams[key]['fwd_link_delay'] >= 10) fwd_link_delay_style = bad_style;
                var terminal_limit_style = {};
                if (beams[key]['aircraft_count'] > beams[key]['terminals_limit']) terminal_limit_style = bad_style;

                utilized = <div style={{ fontSize: "10px"}}>
                    <span title="Forward Link Utilized (%)" style={fwd_style}>→ {beams[key]['fwd_link_utalized']}</span>&nbsp;
                    <span title="Return Link Utilized (%)" style={rtn_style}>↩ {beams[key]['rtn_link_utalized']}</span>&nbsp;
                    <span title="Link Delay (s)" style={fwd_link_delay_style}>🕑 {beams[key]['fwd_link_delay']}s</span>&nbsp;
                    <span title="Aircraft per Terminal Limit" style={terminal_limit_style}>&#9992;&nbsp;{beams[key]['aircraft_count']}/{beams[key]['terminals_limit']}</span>
                </div>;
            }
            var beam_jsx =

                <div key={key}
                    style={{
                        backgroundColor: "#f0f0f0",
                        color: "black",
                        display: "inline-block",
                        width: "197px",
                        border: "solid 2px #d0d0d0",
                        margin: "2px",
                        padding: "8px 8px 8px 8px"
                }}>
                    <div style={{
                        padding: "4px",
                        backgroundColor: header_color,
                        color: "white",
                        borderRadius: "5px"
                    }}>
                        <div style={{
                            fontSize: "10px",
                        }}>
                            <a style={{cursor: "pointer"}} title={teleport_url_title} onClick={() => {
                                    setSearchTeleports([teleport]);
                                }}
                            >
                                {teleport}
                            </a>
                        </div>
                        <div style={{
                            fontWeight: "bold",
                        }}><a style={{cursor: "pointer"}} title="View only this beam" onClick={() => {setSearchBeamName(beam_name)}}>{beam_name}</a></div>
                        {utilized}
                        <div style={{
                            fontSize: "10px",
                        }}>{live?"Latest:" + last_tail_seen:""}</div>
                    </div>
                    {
                        tails.map((element, index) => {
                            // /logger.debug("element", element);
                            //if (!unique_tails.includes(element.tail)) {
                            //    unique_tails.push(element.tail);
                            //}

                            if (element.visible === false) return null;
                            /*
                            if (aircraft_checkbox_state[element.state] === false) {
                                return null;
                            }
                            if (search_operators.length && !search_operators.includes(element.operator)) {
                                return null;
                            }
                            */
                            var state_bgcolor = color_inactive;
                            var bg_color;
                            var bg_class;

                            aircraft_states.forEach((ac_state) => {
                                if (element.state === ac_state.name) {
                                    state_bgcolor = ac_state.color;

                                    bg_color = ac_state.bgcolor;
                                    if (ac_state.class)  bg_class = ac_state.class;
                                }
                            });

                            if (element.usage_total_mb && element.usage_total_mb == 0) {
                                bg_color = color_bad_light;
                                //bg_class = "bgflashbad";

                            }
                            var state = <span style={{fontSize: "11px", backgroundColor: state_bgcolor, color: "white",  padding: "3px", borderRadius: "5px"}}>{element.state}</span>;
                            var usage;
                            if (element.usage_total_mb) usage = <span style={{fontSize: "11px", marginLeft: "4px", backgroundColor: element.usage_total_mb== 0?color_bad:color_good, color: "white",  padding: "3px", borderRadius: "5px"}}>{element.usage_total_mb}&nbsp;MB</span>;

                            var previous_beam;
                            if (element.previous_beam_name) previous_beam = <div style={{fontSize: "smaller"}}>
                                (from: <a style={{cursor: "pointer"}} title="View only this beam" onClick={() => {setSearchBeamName(element.previous_beam_name.substring(5))}}>
                                    {element.previous_beam_name.substring(5)}</a>)
                                </div>;
                            var next_beam;
                            if (element.next_beam_name) next_beam = <div style={{fontSize: "smaller"}}>
                                (to: <a style={{cursor: "pointer"}} title="View only this beam" onClick={() => {setSearchBeamName(element.next_beam_name.substring(5))}}>
                                    {element.next_beam_name.substring(5)}</a>)
                                </div>;
                            var route;

                            if (element.airport_iata_departure_code && element.airport_iata_arrival_code) {
                                logger.debug("route fds_oooi_event", element.tail, element);

                                var route_title = element.airport_iata_departure_code + ' (' + element.airport_icao_departure_code + ') → ' + element.airport_iata_arrival_code + ' (' + element.airport_icao_arrival_code + ')';

                                route = <div style={{fontSize: "smaller"}}>
                                    <a
                                        style={{cursor: "pointer"}}
                                        title="Click here to set the Operators filter to this route."
                                        onClick={() => { // reset all the beams to true
                                                setSearchRoutes([route_title]);
                                        }}
                                    >
                                        {route_title}
                                    </a>
                                </div>;


                            }

                            var title1 = "Click here to set the Operators filter to " + element.operator;
                            var title2 = "Click here to set the Aircraft filter to " + element.tail;

                            return (
                                <div key={element.tail}  className={bg_class} style={{fontWeight: "normal", background: bg_color, marginTop: "2px", borderRadius: "5px", paddingLeft: "4px", paddingBottom: "3px"}}>
                                    <div>
                                        <a style={{cursor: "pointer"}} title={title1} onClick={() => { // reset all the beams to true
                                                setSearchOperators([element.operator]);
                                            }}
                                        >
                                            {element.operator}
                                        </a>: <b>
                                        <a style={{cursor: "pointer"}} title={title2} onClick={() => { // reset all the beams to true
                                                setSearchAircraft([element.operator + ": " + element.tail]);
                                            }}
                                                >{element.tail}
                                        </a></b>
                                    </div>
                                    <div>{element.record_timestamp.substring(0, 19)}</div>
                                    {previous_beam}
                                    {next_beam}
                                    {route}
                                    {state}
                                    {usage}
                                </div>
                            );
                        })
                    }
                </div>;
                content.push(beam_jsx);
            }
        });
        content = <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(190px, 1fr))", gap: "4px", clear: "both"}}>
            {content}
        </div>;


        return content;
    }

    if (tab === 0) {
        if (timeline_state !== null && beam_keys.length) {
            content = tileContent(tail_list_replay_ref.current, beam_keys, false);
        }
        else if (tail_list_ref.current.length && beam_keys.length) {
            content = tileContent(tail_list_ref.current, beam_keys, true); // taken out for testing only do not check in!!!
        }
        else {
            if (timeline_state === null)
                content = "Loading ...";
            else
                content = "Replay an event to see the data.";
        }

    }
    else if (tab === 1) {
        var table_data = getBeamSummaryData();
        logger.debug("table_data", table_data);
        var col_defs =
            [
                { headerName: "Summary",
                    children: [
                        { headerName: "Teleport", headerTooltip: "Teleport", field: "teleport", sortable: true, filter: true, width: 100, pinned: 'left' },
                        { headerName: "Beam Name", headerTooltip: "Beam Name", field: "beam", sortable: true, filter: true, width: 190, pinned: 'left',
                             cellStyle: params => {
                                var result = null;
                                beam_states.forEach((element) => {
                                     if (params.data.type === element.name) {
                                         result = {'background-color': element.bgcolor};
                                     }
                                });
                                return result;
                            }
                        },
                        { headerName: "Last Tail Seen", headerTooltip: "Last Tail Seen", field: "last_tail_seen", sortable: true, filter: true, width: 150, pinned: 'left'},
                    ]
                },
                { headerName: "Aircraft",
                    children: [
                        { headerName: "Count", headerTooltip: "count", field: "tail_count", sortable: true, filter: 'agNumberColumnFilter', width: 100 }
                    ]
                }
            ];



        aircraft_states.forEach((state)=> {
            logger.debug("tab1", state, aircraft_checkbox_state);
            if (aircraft_checkbox_state[state.name]) {
                if (col_defs.findIndex((element)=> element.headerName == state.group) === -1) {
                    col_defs.push({headerName: state.group, children: []});
                }

                var index = col_defs.findIndex((element)=> element.headerName == state.group);
                col_defs[index].children.push( { headerName: state.label, field: state.name, sortable: true, filter: 'agNumberColumnFilter', width: 100,
                        cellStyle: params => {
                            if (params.data[state.name] > 0) {
                                var color;
                                aircraft_states.forEach((ac_state) => {
                                    if (ac_state.name === state.name) {
                                        color = ac_state.bgcolor;
                                    }
                                });
                                return {'background-color': color};
                            }
                            else return null;
                        },
                        headerClass: params => {
                            var header_class;
                            aircraft_states.forEach((ac_state) => {
                                if (ac_state.name === state.name) {
                                    header_class = ac_state.header_class;
                                }
                            });
                            return header_class;

                        },
                        icons: {
                            sortAscending: '<span class="ag-icon ag-icon-asc" unselectable="on" style="color: white"></span>',
                            sortDescending: '<span class="ag-icon ag-icon-desc" unselectable="on" style="color: white"></span>'
                        },
                        headerTooltip: state.label
                });
            }

        });

        content =
            <div className="ag-theme-balham" style={{height: "462px", marginBottom: "8px"}}>
            <AgGridReact
                    ref={gridBeamRef}
                    defaultColDef={ {
                        suppressMenu: true,
                        suppressSizeToFit: true,
                        floatingFilter: true,
                        editable: false,
                        readOnlyEdit: true,
                        sortable: true
                    }}
                    components= { {
                      //  'operatorIcaoCodeCellRenderer': operatorIcaoCodeCellRenderer,
                        //'percentColumnFilter': percentColumnFilter,
                        // handleOpen: handleOpen
                    }}
                    columnDefs= {col_defs}
                    rowData={table_data}
                    enableCellTextSelection={true}
                    //undoRedoCellEditing="true"
                    //undoRedoCellEditingLimit="20"
                    rowSelection="single"
                   // onCellClicked={cellClickedListener}

                    onCellDoubleClicked={(event) => {
                        if (event.colDef.field === "beam") {
                            setSearchBeamName(event.value.substr(5));
                        }
                        else if (event.colDef.field === "teleport") {
                             setSearchTeleports([event.value]);
                        }
                    }}

                    enableBrowserTooltips="true"

                    onGridReady= {(params) => {

                    }}
                    onRowDataChanged={(params) => { // see https://stackoverflow.com/questions/50533098/keep-filter-after-ag-grid-update
                            // refresh filters
                            gridBeamRef.current.api.setFilterModel(gridBeamFiltersRef.current);
                            // refresh selected row
                            if (gridBeamSelectedRow.current) {
                                gridBeamRef.current.api.forEachNode(node=> {
                                    if (node.data.beam  === gridBeamSelectedRow.current) {
                                        node.setSelected(true);
                                    }
                                });
                            }
                        }
                    }
                    onFilterChanged= {(event) =>  {
                        logger.debug("onFilterChanged",event, gridBeamRef.current.api.getFilterModel());
                        if (gridBeamFiltersRef.current !== gridBeamRef.current.api.getFilterModel()) {
                            gridBeamFiltersRef.current = gridBeamRef.current.api.getFilterModel();
                        }
                        setVisibleRowCount(gridBeamRef.current.api.getDisplayedRowCount());

                    }}
                    onRowSelected  = {(event) => {
                        gridBeamSelectedRow.current = event.data.beam;
                    }}
                    overlayLoadingTemplate=
                        '<span class="ag-overlay-loading-center">Loading data ...</span>'
                    //deltaRowDataMode={true}
                    >
        </AgGridReact>
        Showing {visible_row_count===undefined?table_data.length:visible_row_count} of {table_data.length} rows
        </div>;

    }
    else if (tab === 2) {
        table_data = [];
        var tab2_tail_list_ref = [];
        if (timeline_state !== null) {
            tab2_tail_list_ref = tail_list_replay_ref.current;
        }
        else if (tail_list_ref.current.length && beam_keys.length) {
            tab2_tail_list_ref = tail_list_ref.current;
        }

        tab2_tail_list_ref.forEach((tail) => {

            if (tail['visible']) {
                logger.debug("tab2", tail, beams);
                var record = {};
                record['operator'] = tail['operator'];
                record['tail'] = tail['tail'];
                record['xid'] = tail['xid'];
                record['beam'] = tail['beam'];
                record['teleport'] = beams[tail['beam']] && beams[tail['beam']]['teleport']?beams[tail['beam']]['teleport']:"Unknown";
                record['usage_total_mb'] = tail['usage_total_mb'];
                record['record_timestamp'] =  tail['record_timestamp'].substring(0, 19);
                record['previous_beam_name'] = tail['previous_beam_name'];
                record['next_beam_name'] = tail['next_beam_name'];
                record['state'] = tail['state'];
                if (tail['airport_iata_departure_code']) {
                    record['route'] = tail['airport_iata_departure_code'] + " ( " + tail['airport_icao_departure_code'] + ') → ' + tail['airport_iata_arrival_code'] + ' (' + tail['airport_icao_arrival_code'] + ')';
                }
                table_data.push(record);
            }
        });
        content =
            <div className="ag-theme-balham" style={{height: "462px"}}>
                <AgGridReact
                    ref={gridTailRef}
                    defaultColDef={ {
                        suppressMenu: true,
                        suppressSizeToFit: true,
                        floatingFilter: true,
                        editable: false,
                        sortable: true,
                    }}
                    components= { {
                      //  'operatorIcaoCodeCellRenderer': operatorIcaoCodeCellRenderer,
                        //'percentColumnFilter': percentColumnFilter,
                        // handleOpen: handleOpen
                    }}
                    columnDefs= {[
                      // { headerName: "Summary",
                        //    children: [
                                { headerName: "Operator", field: "operator", sortable: true, filter: true, width: 100},
                                { headerName: "Aircraft", field: "tail", sortable: true, filter: true, width: 100, sort: 'asc'},
                                { headerName: "XID", field: "xid", sortable: true, filter: true, width: 80},
                                { headerName: "Teleport", field: "teleport", sortable: true, filter: true, width: 100 },
                                { headerName: "Beam Name", field: "beam", sortable: true, filter: true, width: 190},
                                { headerName: "State", field: "state", sortable: true, filter: true, width: 150,
                                    cellStyle: params => {
                                        var style = null;
                                        aircraft_states.forEach((state) => {

                                            logger.debug("header summary", params, state);
                                            if (params.value === state.name) {
                                                logger.debug("header summary match", params, state);
                                                style = {backgroundColor: state.bgcolor};
                                            }
                                            else {
                                                logger.debug("header summary no match", params, state);
                                            }
                                        });
                                        return style;
                                    },
                                    valueFormatter: params => {
                                        var value = params.value;
                                        aircraft_states.forEach((state) => {
                                            if (state.name === params.value)
                                                value = state.label;

                                        });
                                        return value;
                                    }
                                },
                                { headerName: "Last Updated", field: "record_timestamp", sortable: true, filter: true, width: 150},
                                { headerName: "Previous Beam", field: "previous_beam_name", sortable: true, filter: true, width: 190},
                                { headerName: "Next Beam", field: "next_beam_name", sortable: true, filter: true, width: 190},
                                { headerName: "Route", field: "route", sortable: true, filter: true, width: 190},

                                {
                                    headerName: "Usage (MB)",
                                    field: "usage_total_mb",
                                    sortable: true,
                                    width: 100,
                                    filter: 'agNumberColumnFilter',
                                    cellStyle: params => {
                                        if (params.value == 0) {
                                            return {backgroundColor: color_bad_light};
                                        }
                                        return null;
                                    }
                                },
                        //    ]
                      //  }
                    ]}
                    rowData={table_data}
                    //undoRedoCellEditing="true"
                    //undoRedoCellEditingLimit="20"
                    rowSelection="single"
                    // onCellClicked={cellClickedListener}
                    onCellDoubleClicked={(event) => {
                        logger.debug('onCellDoubleClicked', event);
                        if (event.colDef.field === "beam"  || event.colDef.field === "previous_beam_name" || event.colDef.field === "next_beam_name") {
                            if (event.value) {
                                setSearchBeamName(event.value.substr(5));
                            }
                        }
                        else if (event.colDef.field === "teleport") {
                             setSearchTeleports([event.value]);
                        }
                        else if (event.colDef.field === "operator") {
                             setSearchOperators([event.value]);
                        }
                        else if (event.colDef.field === "tail") {
                             setSearchAircraft([event.data['operator'] + ': ' + event.value]);
                        }
                    }}
                    enableBrowserTooltips="true"
                    onRowDataChanged={(params) => { // see https://stackoverflow.com/questions/50533098/keep-filter-after-ag-grid-update
                            // refresh filters
                            gridTailRef.current.api.setFilterModel(gridTailFiltersRef.current);
                            // refresh selected row
                            if (gridTailSelectedRow.current) {
                                gridTailRef.current.api.forEachNode(node=> {
                                    if (node.data.tail  === gridTailSelectedRow.current) {
                                        node.setSelected(true);
                                    }
                                });
                            }
                        }
                    }

                    onFilterChanged= {(event) =>  {
                        if (gridTailFiltersRef.current !== gridTailRef.current.api.getFilterModel()) {
                            gridTailFiltersRef.current = gridTailRef.current.api.getFilterModel();
                        }
                        setVisibleRowCount(gridTailRef.current.api.getDisplayedRowCount());

                    }}
                    onRowSelected  = {(event) => {
                        gridTailSelectedRow.current = event.data.tail;
                    }}
                    overlayLoadingTemplate=
                        '<span class="ag-overlay-loading-center">Loading data ...</span>'

                    >
        </AgGridReact>
        Showing {visible_row_count===undefined?table_data.length:visible_row_count} of {table_data.length} rows
        </div>;
    }


    if (socket_state ==="Initializing") {
        socket_state_title = socket_state;
        socket_state_color = color_initializing;
    }
    else if (socket_state ==="Open") {
        socket_state_title = socket_state;
        socket_state_color = color_good_dark;
    }
    else if (socket_state ==="Active") {
        socket_state_title = socket_state;
        socket_state_color = color_good;
    }
    else if (socket_state ==="Closed") {
        socket_state_title = socket_state;
        socket_state_color = color_inactive;
    }
    else if (socket_state ==="Error") {
        socket_state_title = socket_state;
        socket_state_color = color_bad;
    }
    else {
        var socket_state_color = color_inactive;
        var socket_state_title = "Unknown State";
    }

    var socket_state_element = <div
        style={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            display: "inline-block",
            marginLeft: "4px",
            marginRight: "2px",
            marginTop: "2px",
            backgroundColor: socket_state_color}}
        title={socket_state_title}>
    </div>;
    /*
    if (timeline_data.length === 0) {
        var chart_data = [];
        for(var i = 0; i < 200; i++) {
            chart_data.push({y: Math.floor(Math.random() * (1 - 5) + 5), x: Date.now() + i*10000 - (10000 * 200)});
        }
        setTimelineData(chart_data);
    }
    */

    var timeline_datestring = "";
    if (timeline_timestamp) {
        var d = new Date(timeline_timestamp);
        var new_d = new Date(d.getTime() - d.getTimezoneOffset()*60000);
        timeline_datestring = new_d.toISOString().replace('T', ' ').substr(0, 19);
    }
    var timeline_start_hour_formatted =  (timeline_start_hour/60/60).toString().padStart(2, '0') + ":00:00";

    return (
        <View title="Live Connectivity Monitoring (LCM)" fixedContent={true}>
            <div style={{padding: "16px", height: "100%", overflow: "scroll"}} >
                <div>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{marginTop: "8px"}}>
                        <Grid item>
                            <MultiSelectSimple
                                options={operator_list}
                                id="operators"
                                label=<React.Fragment><span style={{fontWeight: "bold", color: "green"}}>&#10004;</span> Operators</React.Fragment>
                                placeholder=""
                                onChange={handleOperatorStateChange}
                                value={search_operators}
                            />
                        </Grid>
                        <Grid item>
                            <MultiSelectSimple
                                options={operator_list}
                                id="exclude_operators"
                                label=<React.Fragment><span style={{fontWeight: "bold", color: "red"}}>&#10006;</span> Exclude Operators</React.Fragment>
                                placeholder=""
                                onChange={handleExcludeOperatorStateChange}
                                value={search_exclude_operators}
                            />
                        </Grid>
                        <Grid item>
                            <MultiSelectSimple
                                options={aircraft_list}
                                id="operators"
                                label=<React.Fragment><span style={{fontWeight: "bold", color: "black"}}>&#9992;</span> Aircraft</React.Fragment>
                                placeholder=""
                                onChange={handleAircraftSearchChange}
                                value={search_aircraft}
                            />
                        </Grid>
                        <Grid item>
                            <MultiSelectSimple
                                options={route_list}
                                id="routes"
                                label=<React.Fragment><span style={{fontWeight: "bold", color: "black"}}>&#8644;</span> Routes</React.Fragment>
                                placeholder=""
                                onChange={handleRoutesStateChange}
                                value={search_routes}
                            />
                        </Grid>
                        <Grid item>
                            <MultiSelectSimple
                                options={teleport_list}
                                id="teleports"
                                label="&#128225; Teleports"
                                placeholder=""
                                onChange={handleTeleportStateChange}
                                value={search_teleports}
                            />
                        </Grid>
                        <Grid item>
                            <MultiSelectSimple
                                options={beam_keys}
                                id="beams"
                                label="&#128498; Beams"
                                placeholder=""
                                onChange={handleBeamsStateChange}
                                value={search_beams}
                            />
                        </Grid>
                        <Grid item >
                          <ThemeProvider theme={theme}>
                            <CustomTextField id="beam_name" label="&#128498; Beam Name (exclusive)" variant="outlined" size="small"
                                //see: https://stackoverflow.com/questions/70544205/material-ui-inputadornment-not-showing-anything
                                style={{width:"250px"}}
                                InputProps={{
                                    endAdornment: search_beam_name?<IconButton title="clear" size="small" onClick={() => {setSearchBeamName("")}}>
                                        <ClearIcon />
                                    </IconButton>:""
                                }}
                                value={search_beam_name}
                                onChange= {(e) => {setSearchBeamName(e.target.value)}}
                                InputLabelProps={{ shrink: true }}
                            />
                          </ThemeProvider>
                        </Grid>
                    </Grid>
                </div>
                <div style={{paddingBottom: "10px"}}>
                    <Grid container justifyContent="space-evenly" spacing={1} style={{marginTop: "8px"}}>
                        {
                            summaryGridItem("Beams", color_nonoperational, visible_beam_count, beam_count, false, "Click here to reset the Beam Filters",
                                () => { // reset all the beams to true
                                        var new_state = {};
                                        beam_states.forEach((element) => {
                                            new_state[element.name] = true;
                                        });
                                        setBeamCheckboxState(new_state);
                                    }
                            )
                        }
                        {
                            summaryGridItem("Total Aircraft", color_nonoperational, visible_tail_count, tail_count, false, "Click here to reset the Aircraft Filters",
                               () => { // reset all the aircraft to true
                                    var new_state = {};
                                    aircraft_states.forEach((element) => {
                                        new_state[element.name] = true;
                                    });
                                    setAircraftCheckboxState(new_state);
                                }
                            )
                        }
                          {
                            summaryGridItem("Active Aircraft", color_nonoperational, active_tail_count, visible_tail_count, false, "Click here to reset the Aircraft Filters",
                               () => { // reset aircraft checkboxes to active
                                    var new_state = {};
                                    aircraft_states.forEach((element) => {
                                        new_state[element.name] = element.active;
                                    });
                                    setAircraftCheckboxState(new_state);
                                }
                            )
                        }
                        {
                            summaryGridItem("Operational Aircraft", color_good, operational_tail_count, active_tail_count, true, "Click here to set Aircraft Filters to operational states",
                              () => { // reset aircraft checkboxes to operational
                                    var new_state = {};

                                    aircraft_states.forEach((element) => {
                                        new_state[element.name] = element.operational;
                                    });
                                    setAircraftCheckboxState(new_state);
                                }
                            )
                        }
                        {
                            summaryGridItem("Switching", color_switching, switching_tail_count, active_tail_count, true, "Click here to set Aircraft Filters to Switching",
                              () => { // reset aircraft checkboxes to switching only
                                    var new_state = {};
                                    logger.debug("beam state click", beam_checkbox_state);
                                    aircraft_states.forEach((element) => {
                                        new_state[element.name] = element.name === "Switching";
                                    });
                                    setAircraftCheckboxState(new_state);
                                }
                            )
                        }
                        {
                            summaryGridItem("Network Regained", color_good, network_regained_tail_count, active_tail_count, true, "Click here to set Aircraft Filters to Network Regained",
                                () => { // reset aircraft checkboxes to network regained  only
                                    var new_state = {};
                                    logger.debug("beam state click", beam_checkbox_state);
                                    aircraft_states.forEach((element) => {
                                        new_state[element.name] = element.name === "NetworkRegained";
                                    });
                                    setAircraftCheckboxState(new_state);
                                }
                            )
                        }
                        {
                            summaryGridItem("Network Lost", color_bad, network_lost_tail_count, active_tail_count, true, "Click here to set Aircraft Filters to Network Lost",
                                () => { //reset aircraft checkboxes to network lost only
                                    var new_state = {};
                                    logger.debug("beam state click", beam_checkbox_state);
                                    aircraft_states.forEach((element) => {
                                        new_state[element.name] = element.name === "NetworkLost";
                                    });
                                    setAircraftCheckboxState(new_state);
                                }
                            )
                        }
                    </Grid>
                </div>
                <Accordion defaultExpanded={true} elevation={0} style={{border: "solid 1px grey", paddingTop: 0, marginTop: 0}}>
                    <StyledAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel-content"
                      id="panel-header"
                    >
                        <Typography>Display Filters</Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{marginTop: "8px"}}>

                                <Grid item>
                                    <div style={{position: "relative", display: "inline-block", border: "solid 1px #c0c0c0", paddingLeft: "10px", paddingTop: "0px"}}>
                                        <div style={{fontSize: "12px", paddingLeft:"4px",  paddingRight:"4px", backgroundColor: "white", position: "absolute", top: "-9px", left: "7px"}}>Beam Filters</div>
                                        {/*<span style={{fontWeight: "bold", paddingTop: "5px",paddingRight: "8px"}}>({beams_total})</span> */}
                                        {
                                            beam_states.map((element, index) => {
                                                return <FormControlLabel
                                                    key={element.name}
                                                    control={
                                                        <Checkbox
                                                            checked={beam_checkbox_state[element.name]}
                                                            onChange={handleBeamStateChange}
                                                            name={element.name}
                                                            style={{color: element.color}}
                                                            size="small"
                                                          />
                                                    }
                                                    label={element.label + " (" + element.count + ")"}
                                                />;
                                            })
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{marginTop: "8px"}}>

                                {
                                aircraft_groups.map((group) => {
                                var total_jsx;
                                if (0 && group.show_percent && total_aircraft > 0) {
                                    total_jsx = <span style={{paddingTop: "5px",paddingRight: "8px"}}>Total <b>{(group.count/total_aircraft*100).toFixed(2)}%</b> ({group.count} of {total_aircraft})</span>;
                                }
                                else {
                                    total_jsx = <span style={{paddingTop: "5px",paddingRight: "8px"}}>Total ({group.count})</span>;
                                }
                                return (
                                <Grid item key={group.key}>
                                    <div style={{position: "relative", display: "inline-block", border: "solid 1px #c0c0c0", paddingLeft: "10px", paddingTop: "0px"}}>
                                        <div style={{fontSize: "12px", paddingLeft:"4px",  paddingRight:"4px", backgroundColor: "white", position: "absolute", top: "-9px", left: "7px"}}>{group.name}</div>
                                        {total_jsx}
                                        {
                                            aircraft_states.map((element) => {
                                                var label_jsx;
                                                if (0 && element.active && total_aircraft > 0) {
                                                    label_jsx = <span>{element.label + " "}<b>{(element.count/total_aircraft*100).toFixed(2) + "%"}</b>{" (" + element.count + ") "}</span>;
                                                }
                                                else {
                                                   label_jsx = element.label + " (" + element.count + ")";
                                                }
                                                return (element.group === group.name?
                                                    <FormControlLabel
                                                        key={element.key}
                                                        control={
                                                            <Checkbox
                                                                key={element.name}
                                                                checked={aircraft_checkbox_state[element.name]}
                                                                onChange={handleAircraftStateChange}
                                                                name={element.name}
                                                                style={{color: element.color}}
                                                              />
                                                        }
                                                        label={label_jsx}
                                                    />
                                                :null);
                                            })

                                        }
                                    </div>
                                </Grid>
                                );
                                })
                            }
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}  style={{marginTop: "8px"}}>
                                <Grid item>
                                     <div style={{position: "relative", display: "inline-block", border: "solid 1px #c0c0c0", paddingLeft: "10px", paddingTop: "0px", }}>
                                        <div style={{fontSize: "12px", paddingLeft:"4px",  paddingRight:"4px", backgroundColor: "white", position: "absolute", top: "-9px", left: "7px"}}>Bandwidth Usage</div>
                                        <div style={{verticalAlign: "top", display: "inline-block", paddingTop: 0, paddingRight: "46px"}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={hide_usage}
                                                        onChange={handleHideUsageChange}
                                                        name="hide_usage"
                                                        style={{color: "black"}}
                                                      />
                                                }
                                                label="Hide aircraft not reporting usage"
                                            />
                                        </div>
                                        <div style={{verticalAlign: "top", display: "inline-block"}}>
                                            <div style={{paddingTop: "12px", paddingBottom: "8px"}}>Hide aircraft with bandwidth usage above this value.</div>
                                            <div style={{verticalAlign: "top", display: "inline-block", paddingTop: "4px", paddingRight: "36px"}}>
                                                <CustomSlider
                                                    style={{width: "270px"}}
                                                    value={bandwidth_slider_value}
                                                    onChange={handleBandwidthSliderChange}
                                                    aria-labelledby="continuous-slider"
                                                    //marks={[{value:0, label: "0 MB"}, {value:200, label: "200 MB"}]}
                                                    min={0}
                                                    max={max_bandwidth}
                                                    valueLabelDisplay="auto"
                                                    //valueLabelFormat={(x) => x + " MB"}

                                                />

                                            </div>

                                            <div style={{verticalAlign: "top", display: "inline-block", paddingTop: "4px", paddingRight: "36px"}}>
                                                <Input
                                                    value={bandwidth_slider_value}
                                                    margin="dense"
                                                    onChange={handleBandwidthInputChange}
                                                    onBlur={handleBlur}
                                                    inputProps={{
                                                      min: 0,
                                                      max: max_bandwidth,
                                                      type: 'number',
                                                    }}
                                                    style={{width: "50px"}}
                                                /> MB

                                            </div>

                                        </div>
                                    </div>
                                </Grid>
                                 <Grid item>
                                     <div style={{position: "relative", display: "inline-block", border: "solid 1px #c0c0c0", paddingLeft: "10px", paddingTop: "0px", }}>
                                        <div style={{fontSize: "12px", paddingLeft:"4px",  paddingRight:"4px", backgroundColor: "white", position: "absolute", top: "-9px", left: "7px"}}>Beam Link Utilization</div>
                                        <div style={{verticalAlign: "top", display: "inline-block", paddingTop: 0, paddingRight: "46px"}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={hide_utilization}
                                                        onChange={handleHideUtilizationChange}
                                                        name="hide_utilization"
                                                        style={{color: "black"}}
                                                      />
                                                }
                                                label="Hide beams not reporting utilization"
                                            />
                                        </div>
                                        <div style={{verticalAlign: "top", display: "inline-block"}}>
                                            <div style={{paddingTop: "12px", paddingBottom: "8px"}}>Hide beams with forward utilization below these values.</div>
                                            <div style={{verticalAlign: "top", display: "inline-block", paddingTop: "4px", paddingRight: "36px"}}>
                                                <CustomSlider2
                                                    style={{width: "250px"}}
                                                    value={fwd_slider_value}
                                                    onChange={handleFWDSliderChange}
                                                    aria-labelledby="continuous-slider"
                                                    //marks={[{value:0, label: "0 MB"}, {value:200, label: "200 MB"}]}
                                                    min={0}
                                                    max={100}
                                                    valueLabelDisplay="auto"
                                                    //valueLabelFormat={(x) => x + " MB"}

                                                />

                                            </div>
                                            <div style={{verticalAlign: "top", display: "inline-block", paddingTop: "4px", paddingRight: "36px"}}>
                                                <Input
                                                    value={fwd_slider_value}
                                                    margin="dense"
                                                    onChange={handleFWDInputChange}
                                                    onBlur={handleBlur}
                                                    inputProps={{
                                                      min: 0,
                                                      max: 100,
                                                      type: 'number',
                                                    }}
                                                    style={{width: "50px"}}
                                                /> %

                                            </div>
                                            <div style={{paddingTop: "12px", paddingBottom: "8px"}}>Hide beams with return utilization below these values.</div>
                                            <div style={{verticalAlign: "top", display: "inline-block", paddingTop: "4px", paddingRight: "36px"}}>
                                                <CustomSlider2
                                                    style={{width: "250px"}}
                                                    value={rtn_slider_value}
                                                    onChange={handleRTNSliderChange}
                                                    aria-labelledby="continuous-slider"
                                                    //marks={[{value:0, label: "0 MB"}, {value:200, label: "200 MB"}]}
                                                    min={0}
                                                    max={100}
                                                    valueLabelDisplay="auto"
                                                    //valueLabelFormat={(x) => x + " MB"}

                                                />

                                            </div>
                                            <div style={{verticalAlign: "top", display: "inline-block", paddingTop: "4px", paddingRight: "36px"}}>
                                                <Input
                                                    value={rtn_slider_value}
                                                    margin="dense"
                                                    onChange={handleRTNInputChange}
                                                    onBlur={handleBlur}
                                                    inputProps={{
                                                      min: 0,
                                                      max: 100,
                                                      type: 'number',
                                                    }}
                                                    style={{width: "50px"}}
                                                /> %

                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false} elevation={0} style={{border: "solid 1px grey", paddingTop: 0, marginTop: 0}}>
                    <StyledAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel-content"
                      id="panel-header"
                    >
                        <Typography>Event Replay</Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                        <div style={{width: "100%"}}>
                            <span style={{ display: "inline-block", verticalAlign: "top", paddingTop: "12px"}}>
                                <div>
                                    <span style={{backgroundColor: "black", color: "white", padding: "4px"}}>1</span> Load a two hour window of data.
                                </div>
                                <div style={{paddingLeft: "8px"}}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                     <KeyboardDatePicker

                                        disableToolbar
                                        variant="inline"
                                        format="yyyy-MM-dd"
                                        margin="normal"
                                        id="timeline-start"
                                        //label="Start Date"
                                        value={timeline_start_date}
                                        onChange={(value) => {
                                            var d = new Date(Date.parse(value));
                                            d.setHours(0,0,0,0);
                                            var ts = d.valueOf();
                                            setTimelineStartDate(ts);
                                            logger.debug("onChange", value, d.valueOf(), ts);

                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{width:"160px", paddingRight: "8px"}}
                                    />
                                </MuiPickersUtilsProvider>
                                <span style={{ display: "inline-block", marginTop: "12px"}}>
                                <Tooltip title="Back one hour">
                                    <IconButton

                                        onClick={() => {
                                            var time = timeline_start_hour;
                                            time -= 3600;
                                            if (time < 0) time += 86400;
                                            setTimelineStartHour(time);
                                        }}
                                        style={timeline_state==="back"?{backgroundColor: "#d0d0d0", color: "#000000"}:{}}
                                    >
                                        <BackIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <span style={{fontSize: "16px"}}>
                                    {timeline_start_hour_formatted}
                                </span>
                                <Tooltip title="Forward one hour">
                                    <IconButton

                                        onClick={() => {     var time = timeline_start_hour;
                                            time += 3600;
                                            if (time >= 86400) time -= 86400;
                                            setTimelineStartHour(time);
                                         }}
                                         style={timeline_state==="back"?{backgroundColor: "#d0d0d0", color: "#000000"}:{}}
                                    >
                                        <ForwardIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>

                                <Button disabled = {timeline_state === "loading"}
                                    onClick={() => {
                                        setTimelineState('load');
                                    }}
                                    variant="contained" color="primary" style={{marginLeft: "8px", marginRight: "32px"}}
                                >
                                    Load Replay Data
                                </Button>
                                </span>
                                </div>
                            </span>
                            <span style={{ display: "inline-block", verticalAlign: "top", paddingTop: "12px"}}>
                                <div>
                                    <span style={{backgroundColor: "black", color: "white", padding: "4px"}}>2</span> Select the starting time for the stream.
                                </div>
                                <div>
                                    <Tooltip title="Start">
                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error" || timeline_timestamp <= timeline_min_timestamp}
                                                onClick={() => {
                                                    setTimelineState('pause');
                                                    setTimelineStateInterupted(true);
                                                    setTimelineTimestamp(timeline_min_timestamp);
                                                }}
                                        >
                                            <StartIcon style={{rotate: "180deg"}}/>

                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Back One Minutes">
                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error" || timeline_timestamp <= timeline_min_timestamp}
                                                onClick={() => {
                                                    setTimelineState('pause');
                                                    setTimelineStateInterupted(true);
                                                    var tmp = Math.round((timeline_timestamp - timeline_timestamp_jump)/timeline_timestamp_jump)*timeline_timestamp_jump;
                                                    if (tmp > timeline_min_timestamp + timeline_timestamp_delta) {
                                                        tmp = timeline_min_timestamp + timeline_timestamp_delta;
                                                    }
                                                    setTimelineTimestamp(tmp);
                                                }}
                                        >
                                            {/* <RewindIcon/> */}
                                            <span style={{ fontSize: "32px", width: "20px", height: "20px", position:"relative", top: "-16px" }}>&#171;</span>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Back One Second">
                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error" || timeline_timestamp <= timeline_min_timestamp}
                                                onClick={() => {
                                                    setTimelineState('pause');
                                                    setTimelineStateInterupted(true);
                                                    setTimelineTimestamp(timeline_timestamp - 1000);

                                                }}
                                        >
                                            {/*<StepRewindIcon style={{rotate: "-90deg"}}/> */}
                                            <span style={{ fontSize: "32px", width: "20px", height: "20px", position:"relative", top: "-16px" }}>&#8249;</span>
                                            {/*<PlayRewindIcon style={{rotate: "180deg" }}/>*/}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Forward One Second">
                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error" || timeline_timestamp >= timeline_min_timestamp + timeline_timestamp_delta}
                                                onClick={() => {
                                                    setTimelineState('pause');
                                                    setTimelineStateInterupted(true);
                                                    setTimelineTimestamp(timeline_timestamp + 1000);

                                                }}
                                        >
                                            {/*<StepRewindIcon style={{rotate: "-90deg"}}/> */}

                                             <span style={{ fontSize: "32px", width: "20px", height: "20px", position:"relative", top: "-16px" }}>&#8250;</span>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Forward One Minute">
                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error" || timeline_timestamp >= timeline_min_timestamp + timeline_timestamp_delta}
                                                onClick={() => {
                                                    setTimelineState('pause');
                                                    setTimelineStateInterupted(true);
                                                    var tmp = Math.round((timeline_timestamp + timeline_timestamp_jump)/timeline_timestamp_jump)*timeline_timestamp_jump;
                                                    if (tmp > timeline_min_timestamp + timeline_timestamp_delta) {
                                                        tmp = timeline_min_timestamp + timeline_timestamp_delta;
                                                    }
                                                    setTimelineTimestamp(tmp);
                                                }}
                                                //style={timeline_state==="jumpforward"?{backgroundColor: "#d0d0d0", color: "#000000"}:{}}
                                        >
                                            <span style={{ fontSize: "32px", width: "20px", height: "20px", position:"relative", top: "-16px" }}>&#187;</span>

                                            {/* <span style={{fontSize: "19px", letterSpacing: "-10px"}}>&#11208;&#11208;&#11208;</span> */}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="End">
                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error" || timeline_timestamp >= timeline_min_timestamp + timeline_timestamp_delta}
                                                onClick={() => {
                                                    setTimelineState('pause');
                                                    setTimelineStateInterupted(true);
                                                    setTimelineTimestamp(timeline_min_timestamp + timeline_timestamp_delta)
                                                }}
                                               // style={timeline_state==="pause"?{backgroundColor: "#d0d0d0", color: "#000000"}:{}}
                                        >
                                            <StartIcon  style={{rotate: "0deg"}}/>
                                        </IconButton>
                                    </Tooltip>

                                    <span style={{paddingLeft: "24px", paddingRight: "24px", width: "224px", display: "inline-block", userSelect: "none", fontWeight: "bold", fontSize:"18px"}}>
                                        {timeline_state === 'loading'?"Loading ...":timeline_state === "error"?"An Error Occurred":timeline_datestring}
                                    </span>
                                </div>
                                <div style={{paddingLeft: "16px", paddingRight: "16px"}}>
                                        <Slider
                                            disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading"}
                                            min={0}
                                            max={timeline_timestamp_delta/1000}
                                            value={(timeline_timestamp - timeline_min_timestamp)/1000}
                                            onChange={(event, value) => {
                                                setTimelineState('pause');
                                                setTimelineStateInterupted(true);
                                                setTimelineTimestamp(timeline_min_timestamp + (value * 1000));
                                            }}
                                            aria-labelledby="timeline-slider"
                                        />
                                    </div>
                            </span>
                            <span style={{ display: "inline-block", verticalAlign: "top", paddingTop: "12px", paddingLeft: "32px"}}>

                                <div>
                                    <span style={{backgroundColor: "black", color: "white", padding: "4px"}}>3</span> Play the events through the system.
                                </div>
                                <div>
                                    <Tooltip title="Clear All Events">
                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error"}
                                                onClick={() => {
                                                    setTimelineState('pause');
                                                    setTimelineStateInterupted(true);
                                                    tail_list_replay_ref.current = [];
                                                    teleport_list_replay_ref.current = [];

                                                }}
                                        >
                                            <ClearIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    {/*
                                    <Tooltip title="Run Events From Begining Time to Selected Time">
                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error"}
                                                onClick={() => {
                                                    setTimelineState('catchup');
                                                }}
                                                style={timeline_state==="catchup"||timeline_state==="catchup_loading"?{backgroundColor: "#d0d0d0", color: "#000000"}:{}}
                                        >
                                            <LoadIcon style={{rotate: "-90deg"}} />
                                        </IconButton>
                                    </Tooltip>
                                    */}
                                    <Tooltip title="Step forward one minute">
                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error" || timeline_timestamp >= timeline_min_timestamp + timeline_timestamp_delta}
                                                onClick={() => {
                                                    setTimelineState('stepforward');
                                                     setTimelineStateInterupted(true);
                                                }}
                                                style={timeline_state==="stepforward"||timeline_state==="stepforward_loading"?{backgroundColor: "#d0d0d0", color: "#000000"}:{}}
                                        >
                                            <StepForwardIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Step forward one second">
                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error" || timeline_timestamp >= timeline_min_timestamp + timeline_timestamp_delta}
                                                onClick={() => {
                                                    if (timeline_state === "pause") {
                                                        //flushSync(() => { //https://www.robinwieruch.de/react-batching/
                                                            setTimelineState('step');
                                                            setTimelineStateInterupted(true);
                                                        //});
                                                    }
                                                }}
                                                style={timeline_state==="step"||timeline_state==="step_loading"?{backgroundColor: "#d0d0d0", color: "#000000"}:{}}
                                        >
                                            <StepIcon  style={{rotate: "90deg"}}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Pause">
                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error"}
                                                onClick={() => {
                                                    logger.debug("Pause button pressed");
                                                    setTimelineState('pause');
                                                    setTimelineStateInterupted(true);
                                                }}
                                                style={timeline_state==="pause"?{backgroundColor: "#d0d0d0", color: "#000000"}:{}}
                                        >
                                            <PauseIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Play">

                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error" || timeline_timestamp >= timeline_min_timestamp + timeline_timestamp_delta}
                                                onClick={() => {
                                                    setTimelineState('play')
                                                    setTimelineStateInterupted(false);
                                                }}
                                                style={timeline_state==="play"|| timeline_state==="play_loading"||timeline_state==="play_loaded"?{backgroundColor: "#d0d0d0", color: "#000000"}:{}}
                                        >
                                            <PlayIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    {/*}
                                    <Tooltip title="Fast Forward">

                                        <IconButton
                                                disabled={timeline_state === null || timeline_state === "load" || timeline_state === "loading" || timeline_state === "error" || timeline_timestamp >= timeline_min_timestamp + timeline_timestamp_delta}
                                                onClick={() => { setTimelineState('forward') }}
                                                style={timeline_state==="forward"||timeline_state==="forward_loading"?{backgroundColor: "#d0d0d0", color: "#000000"}:{}}
                                        >
                                            <FastForwardIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    */}
                                </div>

                            </span>
                            <span style={{ display: "inline-block", verticalAlign: "top", paddingTop: "12px", paddingLeft: "32px"}}>
                                <Button
                                    onClick={() => {
                                        setTimelineState(null);
                                        setTimelineTimestamp(null);
                                        setTimelineStateInterupted(true);
                                        //setTimelineData(null);
                                        tail_list_replay_ref.current = [];
                                    }}
                                    variant="contained"
                                    color="primary"
                                    style={{marginLeft: "0px", marginRight: "16px"}}
                                    disabled={ timeline_state === null}
                                >
                                    Live Stream
                                </Button>
                            </span>

                            {/*
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={{

                                    series: [
                                        {
                                        data: timeline_data
                                        }
                                    ]
                                }}
                                containerProps={{ style: { height: "300px", width: "100%" } }}
                            />
                            */}

                        </div>
                    </AccordionDetails>
                </Accordion>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}  style={{marginTop: "24px"}}>
                    {getTabJSX(<React.Fragment><span style={{fontSize: "16px", paddingRight: "10px"}}>&#9647;</span> Tile View</React.Fragment>, 0)}
                    {getTabJSX(<React.Fragment><span style={{fontSize: "16px", paddingRight: "10px"}}>&#128498;</span> Beam Summary Table</React.Fragment>, 1)}
                    {getTabJSX(<React.Fragment><span style={{fontSize: "16px", paddingRight: "10px"}}>&#9992;</span> Aircraft Details Table</React.Fragment>, 2)}
                    {/*
                    <Grid item>
                        <Button variant="contained" color={tab===3?"secondary":"primary"} size="small" startIcon={<ChartIcon />} onClick={() => {setTab(3)}}>Chart View</Button>
                    </Grid>
                    */}
                    <Grid item>
                        <div style={{marginTop: "11px"}}>{socket_state_element} <Clock /></div>
                    </Grid>
                </Grid>

                <div style={{marginTop: "16px"}}>
                    {content}
                </div>
            </div>
        </View>
    );

}
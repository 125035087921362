
/**
 * @experimental
 * 
 * This tests the functionality of the grid component
 */

import React                from 'react';
import { makeStyles }       from '@material-ui/core/styles';

import View                 from 'pac-responsive-ui-framework/core/View';

import DataGrid from '../components/DataGrid';

import rowData from '../data/FlightData';

export default function GridTest() {
  
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    div: {
      paddingTop: "78px", 
      height: "100%", 
      position: "fixed", 
      top: 0,
      [theme.breakpoints.only('xs')]: {
        left: 0,
      },
      [theme.breakpoints.up('sm')]: {
        left: 64,
      },
      right: 0,
      overflowScrolling: "touch",
      WebkitOverflowScrolling: "touch",
      overflowY: "scroll",
      overflowX: "scroll",
    }
   
  }));
  const classes = useStyles();

  return (
      <View title="Grid Test 1" fixedContent={true}>
          <DataGrid rowData={rowData} />
      </View>
      
  );
  
}
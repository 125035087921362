import BaseService from './BaseService';
import { Logger } from 'aws-amplify';


import OperatorService      from './OperatorService';

const logger = new Logger('AircraftService');

export default class AircraftService extends BaseService {

    constructor(order_by_registration = true) {

        let apiName = 'OriginsAPI';
        let collectionPath = '/aircraft';
        super(apiName, collectionPath);
        this.order_by_registration = order_by_registration;
        
    }
    
    getMetaData(data) {
        logger.debug("getMetaData", data);
        var meta_data = data.meta;
        meta_data.count = data.data.length;
        meta_data.total_records = data.data.length;
        meta_data.page_size = data.data.length;
        meta_data.page_number = 1;
        meta_data.rowsPerPage = data.data.length;
        return meta_data;
    }

    /**
     * data in the format
     * [{registration_name: "", ...}, {{registration_name: "",...}]
     */
    formatRowData(data) {
        logger.debug(data);
        data.sort(function(a, b) {
          var keyA = a.registration_name,
            keyB = b.registration_name;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        return data;
    }
    
    formatLabel(option, show_operator=false, show_type=true, show_system=true, show_programatic_system= false, show_percent=false) {

        // if (is_null($order_by_registration)) {
         //   $order_by_registration = PAC_User::getCurrentUser()->getOrderByAircraft();
        //}

        var label = '';
        if (show_operator && option['operator_icao_code'])
            label += '[' + option['operator_icao_code'] + '] ';
        if (this.order_by_registration) {
            label += option['registration_name'];
            if (option['ship_number'])
                label += ' (' + option['ship_number'] + ')';
        } else {
            if (option['ship_number']) {
                label += option['ship_number'] + ' (' + option['registration_name'] + ')';
            } else {
                label += option['registration_name'];
            }
        }
        if (show_type || show_system) {
            var system_display_name = "";
            if (option['system']) system_display_name = option['system'];
            else if (option['system_type']) system_display_name = option['system_type'];
            else if (option['system_type_name']) system_display_name = option['system_type_name'];
            if (option['system_programmatic_name']
                && system_display_name != option['system_programmatic_name']) {
                if (show_programatic_system && option['system_programmatic_name']) system_display_name += '/';
                system_display_name += option['system_programmatic_name'];
            }

            var type_label = '';
            if (show_type && (option['type'] || option['subtype']))
                type_label += option['type'] + '-' + option['subtype'];
            if (show_type && show_system && system_display_name)
                type_label += ' ';
            if (show_type && system_display_name)
                type_label += system_display_name;
            if (show_type && option['xid']) {
                type_label += ' ';
                if (option['xid']<50000) type_label += 'GEN1';
                else type_label += 'GEN3';
            }
            if (type_label) label += ' (' + type_label + ')';
        }

        if (option['aircraft_category'] && option['aircraft_category'] != "LIVE")
            label += ' (' + option['aircraft_category'] + ')';
        if (show_percent && option['FlightPercentComplete']) {
            label += ' (Flying: ' + option['FlightPercentComplete'] + '%)';
        }
        if (show_percent && option['GCSSLAPercent']) {
            label += ' SLA (' + option['flight_count'] + ' flights):  ' + option['GCSSLAPercent'] + '%';
        }


        if (option['aircraft_status'] === 0) {
            label += ' (inactive)';
        }
        if (option['fmr_description']) {  //FMR aircraft with no dart_ops linkage (add non-empty label) or multiple linkages (differentiate equivalent labels)
            label += option['fmr_description'];
        }

        return label;
    }
    
     async getColumnDefs() {
        var column_width=100;
        
        // add metrics
        var col_defs = [
            {
                headerName: 'Aircraft',
                openByDefault: false, // column: header expanded
                defaultExpanded: false, 
                children: [
                    {
                        field: "id_aircraft",
                        headerName: "Aircraft ID", 
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        formType: "readonly"
                    },
                ]
            },
            {
                headerName: 'Operator',
                openByDefault: true, // column: header expanded
                defaultExpanded: true, 
                children: [
                    {
                        field: "id_operator",
                        headerName: "Operator", 
                        headerTooltip: "Region",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        hide: true, 
                        
                        required: true,
                        formType: "select",
                        service: new OperatorService(),
                        getOptionLabel: (option) => { return (option.operator_icao_code?option.operator_icao_code:"---") + ": " + option.operator_name },
                        renderOption: (option) =>  { return (option.operator_icao_code?option.operator_icao_code:"---") + ": " + option.operator_name },
                        getValue: (data) => { return {"id_operator": data.id_operator,"operator_name":data.operator_name}},
                        columnGroupShow: 'open'
                    },
                    {
                        field: "operator_name",
                        headerName: "Operator Name", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        formType: "readonly"
                    },
                    {
                        field: "operator_icao_code",
                        headerName: "ICAO Code", 
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        formType: "readonly"
                    },
                ]
            },
            {
                headerName: 'Aircraft type',
                openByDefault: false, // column: header expanded
                defaultExpanded: false, 
                children: [
                    {
                        field: "type_subtype",
                        headerName: "Aircraft Type-Subtple", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "oem",
                        headerName: "Manufacturer", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "type",
                        headerName: "Aircraft Type", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "subtype",
                        headerName: "Aircraft Subtype", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "registration_name",
                        headerName: "Registration Name", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "ship_number",
                        headerName: "Ship/Nose Number", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "manufacture_serial_number",
                        headerName: "OEM Serial Number", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "oem_tail_number",
                        headerName: "OEM Tail Number", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "line_number",
                        headerName: "Line Number", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "system_type",
                        headerName: "System", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "flight_type",
                        headerName: "Flight Type", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "aircraft_category",
                        headerName: "A/C Category", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "aircraft_status",
                        headerName: "A/C Status", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "rack_name",
                        headerName: "Rack Name", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "cellmodem_ip",
                        headerName: "Cell Modem IP Address", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "secondary_cellmodem_ip",
                        headerName: "Cell Modem IP (Secondary)", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "sdu_tray_id",
                        headerName: "SDU Tray ID", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "gcs_enabled",
                        headerName: "GCS Enabled", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "xid",
                        headerName: "XID", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "aircraft_bc_gen",
                        headerName: "BC Gen", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "gcs_commissioning_deleted",
                        headerName: "Commissioning Deleted", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "mtn_commissioning_state",
                        headerName: "MTN State", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "mtn_disabled",
                        headerName: "MTN Disabled", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "gcs_antenna_type_name",
                        headerName: "Antenna Type", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "gcs_concentration_point",
                        headerName: "Concentration Point", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "airbus_msn",
                        headerName: "Airbus MSN", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "airbus_msn",
                        headerName: "Airbus MSN", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "date_in",
                        headerName: "Date In", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "date_out",
                        headerName: "Date Out", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "entry_into_service",
                        headerName: "Entry Into Service", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "id_gcs_concentration_point",
                        headerName: "ID Concentration Point", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "management_vlan_337_cidr",
                        headerName: "management_vlan_337_cidr", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "management_vlan_337_ip",
                        headerName: "management_vlan_337_ip", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "shiptracker_id",
                        headerName: "Shiptracker ID", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "system_overwrite_flag",
                        headerName: "System Overwrite", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "system_display_name",
                        headerName: "System Display Name", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "system_programmatic_name",
                        headerName: "System Programmatic Name", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    },
                    {
                        field: "last_flight_date",
                        headerName: "Last Knwon Flight", 
                        filter: 'agTextColumnFilter',
                        width: column_width
                    }
                ]
        }];
        return col_defs;
         
     }
}

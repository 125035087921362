/**
 * @experimental
 * 
 * This tests the functionality of the grid component
 */

import React from 'react';

import View from 'pac-responsive-ui-framework/core/View';

import AGGrid from '../components/AGGrid';
import Typography from '@material-ui/core/Typography';
import Service from '../services/AlertConfigService';

export default function OpsAlertConfig(props) {
    const service = new Service();
   var header = <Typography variant="h5">Origins Alerts (MARS Alerts)</Typography>;

    return (
        <View title={props.title} fixedContent={true}>
           <AGGrid header={header} service={service} columnDefs={service.getColumnDefs()} filename="operations_alert_config"/>
       </View>
    );
}

import React, { Component }         from 'react';
import Button from '@material-ui/core/Button';

export default function ButtonSetExpanded(props) {

    function action() {
        props.gridApi.forEachNodeAfterFilter(node => node.setExpanded(props.expand))
        
    };

    var label = "Collapse All";
    if (props.expand) {
        label = "Expand All"
    }

    return (
        <Button variant="outlined" size="small" color="primary" onClick={() => action()}>
            {label}
        </Button>
    )
}
/**
 *  Generic map with no view components
 */ 

import React, {useState}  from 'react';
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
 
import MapService      from '../services/MapService';

import { makeStyles } from '@material-ui/core/styles';

import Map    from 'components/Map';

import Button from '@material-ui/core/Button';

export default function LinkBox(props) {
  const { search } = useLocation();
  const values = queryString.parse(search);
  
  var url;
 if (typeof(values.generate_link) != "string" || values.generate_link.toLowerCase() != "no") {
  url = values.url + "?";
  for (const v in values) {
    if (v != "url" && v != "button") {
      url = url + v + '=' + values[v] + "&";
    }
    console.log(`${v}: ${values[v]}`);
  }
  url = url.substr(0, url.length - 1);
 }

  const useStyles = makeStyles(theme => ({
      root: {
          //border: "solid 1px black",
          //backgroundColor: "#ffffff",
          margin: "8px",
          padding: "2px",
          //width: 'calc(100% - 64px)'
        }       
  }));
  

  const classes = useStyles();
  
  if (url) {
     if (typeof(values.button) == "string" && values.button == "1") {
       return (<Button variant="contained" onClick={() => {  window.parent.location.href = url; }}>Refresh page</Button>) 
     }
     else {
      return (
        <React.Fragment>
          <div className={classes.root}>
            <a href={url} target="_blank">{url}</a>
          </div>
        </React.Fragment>      
      );
     }
  }
  else {
    return (
          <div>&nbsp;&nbsp;To show the Report Link, set Generate Link to "yes".</div>
    
      );
  }
}
let Storage = {
  account: process.env,
  bucket: 'pac-origins-lcm-replay-requests-' + process.env.REACT_APP_ACCOUNT + '-' + process.env.REACT_APP_COGNITO_REGION,
  region: process.env.REACT_APP_COGNITO_REGION,

  // OPTIONAL - Amazon Cognito User Pool ID
  identityPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  //identityPoolId: process.env.REACT_APP_identityPoolId
  
};

export default Storage;

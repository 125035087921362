/**
 * This view is reached when the user fails authentication or authorization.
 */ 
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { ThemeProvider } from '@material-ui/styles';
import { baseTheme } from 'pac-responsive-ui-framework/themes/Main';
import { Logger } from 'aws-amplify';

//import { ButtonPrimary } from "pac-ux-components";

const logger = new Logger('views/Error');

export default function Error(props) {
    
    logger.debug("props", props);
    
    const useStyles = makeStyles(theme => ({
  
    root: {
      display: 'flex',
      backgroundColor: "#1565c0"
    },
    button: {
      marginTop: 16
    },
    message: {
      marginLeft: 4
    }
    }));
    const classes = useStyles();

    var message;
    if (!props.isAuthenticated) {
        message = "You are not logged in."
    }
    else if (props.isFailed) {
       message = (
          <React.Fragment>
            An unexpected error occurred.
          </React.Fragment>);
    }
    else if (!props.isPermitted) {
        message = (
          <React.Fragment><p>You do not have permission to access Origins.</p>
            <p>Please contact your administrator to request access.</p>
          </React.Fragment>);
    }
    else {
        message = "An unknown error occurred";
    }
    
    // do not return to the sign out page
    var returnto = '';
    if (!window.location.href.includes('/signout')) {
       returnto=  "?returnTo=" + window.location.href;
    }
    

    return (
    <ThemeProvider theme={baseTheme}>
      <div className={classes.root}>
      
        <Grid 
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >

          <Grid item xs={6} >
            <div>
            <img src={require("../assets/appbarlogo.png")} alt="applogo" className={classes.logo} />
            </div>
            <Typography className={classes.message} variant="body1" color="secondary">
              {message}
            </Typography>
            <Box className={classes.button}> 
            
              <Button  
                  variant="contained" 
                  color="primary" 
                  className={classes.button}
                  href={"https://login.nextcloud.aero/" + returnto}
              >
                Return to NextCloud
              </Button>
              {/*
              <ButtonPrimary
                disabled={false}
                onClick={function noRefCheck() {
                  window.location.href = "https://login.nextcloud.aero/?returnTo=" + window.location.href
                  
                }}
                size="default"
              >
                Return to NextCloud
              </ButtonPrimary>
            */}
            </Box>
          </Grid>   
        
        </Grid> 
      </div>
      </ThemeProvider>
  );
    
}
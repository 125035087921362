import React                from 'react';
import { Logger }           from 'aws-amplify';
import { makeStyles }       from '@material-ui/core/styles';
import Tooltip              from '@material-ui/core/Tooltip';
import Card                 from '@material-ui/core/Card';
import CardActionArea       from '@material-ui/core/CardActionArea';
//import CardActions          from '@material-ui/core/CardActions';
import CardContent          from '@material-ui/core/CardContent';
//import CardMedia            from '@material-ui/core/CardMedia';
import Typography           from '@material-ui/core/Typography';

const logger = new Logger('CardIcon');

const useStyles = makeStyles(theme => ({
    card: {
        textAlign: 'center'
    },
    card_action_area: {
        width: 120,
        height: 80
    },
   
    typography: {
        fontSize: 10
    }
}));

export default function CardIcon(props) {
    const classes = useStyles();
    
    return (
        <Card className={classes.card} elevation={0}>
            <Tooltip title={props.text}>
                <a 
                    href={props.link} 
                    target="_self"
                    color="inherit" 
                    style={{textDecoration: 'none' }}
                    component="button" 
                    aria-label="openinnew" 
                    onClick={(event) => {event.stopPropagation()}}
                >
                    <CardActionArea className={classes.card_action_area}>
                        <CardContent>
                            {props.icon}
                            <Typography className={classes.typography} variant="button" display="block" noWrap>
                                {props.text}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </a>
            </Tooltip>
        </Card>
    )
}
import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField            from '@material-ui/core/TextField';
import IconPrevious         from '@material-ui/icons/ChevronLeft';
import IconNext             from '@material-ui/icons/ChevronRight';
import Tooltip              from '@material-ui/core/Tooltip';
import Button               from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({

    button: {
        maxWidth: '36px', maxHeight: '36px', minWidth: '36px', minHeight: '36px',
    }
}));

function formatDate(d) {
    var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

 

export default function News(props) {
    const classes = useStyles();
    
    const [date, setStateDate] = useState(props.date);
    
    const handleDateChange = (e) => {
        if (e.target.value != date) {
            setDate(e.target.value);
            
        }
    }
    
    const setDate = (date) => {
        props.onChange(date);
        setStateDate(date);
    }
    
    return (
        <React.Fragment>
            <Tooltip title="Previous Day">
             <Button 
                aria-label="Previous"
                onClick={() => {
                    var parts =date.split('-');
                    var d = new Date(parts[0], parts[1] - 1, parts[2]); 
                    d.setDate(d.getDate()-1);
                    setDate(formatDate(d));
                    
                }}
                className={classes.button} 
                size="small">
                <IconPrevious />
              </Button>
            </Tooltip>
            <TextField
                id="date"
                //label="Date"
                type="date"
                format="YYYY-MM-DD" // doesnt work
                //defaultValue={date}
                value={date}
                className={classes.textField}
                onChange={handleDateChange.bind(this)}
            />
            
            <Tooltip title="Previous Day">
             <Button 
                aria-label="Next"
                onClick={() => {
                    var parts =date.split('-');
                    var d = new Date(parts[0], parts[1] - 1, parts[2]); 
                    d.setDate(d.getDate()+1);
                    setDate(formatDate(d));
                    
                }}
                className={classes.button} 
                size="small">
                <IconNext />
              </Button>
            </Tooltip>
        </React.Fragment>
    );
}

import React from 'react';
import { Logger }                   from 'aws-amplify';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  //KeyboardDateTimePicker
   
} from '@material-ui/pickers';


const logger = new Logger('FormElements/DateTime');

export default function DateAndTimePickers(props) {
  logger.debug('props', props)
  
  const [date, setDate] = React.useState(props.defaultValue);  //note that both values can take a date time string
  
  const handleDateChange = (event) => {
    logger.debug("handleDateChange", event);
    event = String(event);
    logger.debug("handleDateChange", event);
    if (event === "Invalid Date" || event === "null") {
      props.onChange(event);
      return;
    }
    var d = new Date(event.substr(0, 25) + " GMT-0000"); // have to convert time to UTC
    
    var new_date = d.toISOString().substr(0, 10);
    logger.debug("handleDateChange", new_date);
    setDate(new_date);
    props.onChange(new_date);
    
  };
  
  const handleDateBlur = (event) => {
    logger.debug("handleDateBlur", event, date);
    return;
    setDate(event.target.value);
    props.onChange(event.target.value);
  };
  
  const handleDateAccept = (event) => {
    logger.debug("handleDateAccept", event, date);
    return;
    event = String(event);
    var d = new Date(event.substr(0, 25) + " GMT-0000"); // have to convert time to UTC
    var new_date = d.toISOString().substr(0, 10);
    setDate(new_date);
    props.onChange(new_date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{display: "inline-block", width: "160px"}}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="yyyy-MM-dd"
          margin="normal"
          id={"date_" + props.id}
          label={props.dateLabel}
          value={props.defaultValue}
          onChange={handleDateChange}
          onBlur={handleDateBlur}
          onAccept={handleDateAccept}
          maxDate={props.maxDate}
          minDate={props.minDate}
          maxDateMessage={props.maxDateMessage}
          minDateMessage={props.minDateMessage}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          error={props.error}
          helperText={props.helperText} 
          required={props.required}
        />
      </div>
   </MuiPickersUtilsProvider>
  );
}
import React        from 'react';
import { Link }     from 'react-router-dom';
import IconButton   from '@material-ui/core/IconButton';
import Button       from '@material-ui/core/Button';
import Hidden       from '@material-ui/core/Hidden';
import Tooltip      from '@material-ui/core/Tooltip';
import Typography   from '@material-ui/core/Typography';

export default function IconButtonHome(props) {
    
    var path = '/';
    if (props.path) path = props.path;
    
    var mobile_button = (
        <Tooltip title={props.title + ' Home'}>
            <IconButton
                edge={props.edge}
                color="inherit"
                aria-label={props.title}
                component={Link} to={path}
            >
                {props.icon}
            </IconButton>
        </Tooltip>);
    
    var desktop_button;
    if (props.logo) {
         desktop_button =  (
            <Tooltip title={props.title + ' Home'}>
                <IconButton
                    edge={props.edge}
                    color="inherit"
                    aria-label={props.title}
                    component={Link} to="/"
                >
                    {props.logo}
                </IconButton>
            </Tooltip>);
    }
    else {
        desktop_button = (
            <Tooltip title={props.title + ' Home'}>
                <div>
                <Button
                    edge={props.edge}
                    color="inherit"
                    aria-label={props.title}
                    component={Link} to="/"
                    startIcon={props.icon}
                >
                    <Typography variant="h6">
                        {props.title}
                    </Typography>
                 </Button>
                </div>
            </Tooltip>);
    }
    
    return (
        <React.Fragment>
            <Hidden mdUp={true}>
                {mobile_button}
            </Hidden>
            <Hidden smDown={true}>
                {desktop_button}
            </Hidden>
        </React.Fragment>

    )
}
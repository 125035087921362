import React from 'react';
import Home from '../views/Home';
import Search from '../views/Search';

import { 
 // BrowserRouter as Router, 
//  Link,  
//  useParams,

  Switch, 
  Route, 
  useRouteMatch,
  useLocation
} from 'react-router-dom';

/*
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
*/

export default function RouterSearch(props) {
  let match = useRouteMatch();
  return (
      <Switch>
        <Route path={`${match.path}/:search`}>
          <Search />
        </Route>
        <Route path={match.path}>
          <Home />
        </Route>
      </Switch>
   
   
  );
}
import React, { useState }  from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { baseTheme } from 'pac-responsive-ui-framework/themes/Main';
import { constants } from 'assets/Config.js';


import { Auth, Hub, Logger } from 'aws-amplify';

import TokenHelper from './config/TokenHelper';

import './config/amplify-configure.js';

import {
    Redirect,
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from 'react-router-dom';



import CssBaseline      from '@material-ui/core/CssBaseline';
import 'assets/App.css';

import AppContext      from 'pac-responsive-ui-framework/core/AppContext';

import NavigationMenu           from './components/NavigationMenu';
import Header                   from './components/Header';

import ScrollToTop      from 'pac-responsive-ui-framework/core/ScrollToTop';

import ReportFavoriteService    from './services/ReportFavoriteService';

import ErrorView    from 'views/Error';
import LoadingView  from 'views/Loading';
import Home         from 'views/Home';
import About        from 'views/About';
import Profile      from 'views/Profile';
import Preferences  from 'views/Preferences';
import RouterReport from 'views/RouterReport';
//import Help         from 'views/Help';
import RouterSearch from 'views/RouterSearch';
import Signout      from 'views/Signout';
//import Users        from 'views/OpsUsers';
//import Operators    from 'views/Operators';
import GridTest     from 'views/GridTest';
//import GridTest2     from 'views/GridTest2';
import GridTest3    from 'views/GridTest3';
import GridTest4    from 'views/GridTest4';
//import DashboardEditor    from 'views/DashboardEditor';
import RouterOperations   from 'views/RouterOperations';
//import FlightList   from 'views/FlightList';
import LogView      from 'views/LogView';
import Timeline     from 'views/Timeline';


import FlightMapView from 'views/FlightMap';
import LinkBoxView from 'views/LinkBox';


import Map1View from 'views/Map1';
//import Map2View from 'views/Map2'; // replaced with FlightMap
import Map3View from 'views/Map3';



import Feedback     from 'views/Feedback';

import UserService  from './services/UserService';
import ReportsService  from './services/ReportsService';
//import {    UserProfilePage } from 'pac-platform-ui-components';



const logger = new Logger('src/App');

if (process.env.REACT_APP_STAGE == "prd") {
    //console.log = function () {};
}

class App extends React.Component {

    constructor(props) {
        super(props);

        //var start_date = new Date();
        //start_date.setDate(start_date.getDate()-2);

        //var end_date = new Date();

        this.state = {
            env: process.env.REACT_APP_STAGE,
            title: constants.title,
            header: <Header />,
            navigationMenu: <NavigationMenu/>,
            user: {},
            isAuthenticated: false,
            isLoaded: false,
            isPermitted: false,
            isFailed: 0,
            default_operator_icao_code: null,
            start_date: null, // start_date.toISOString().substr(0, 10) + ' 12:00:00',
            end_date: null, // end_date.toISOString().substr(0, 10) + ' 13:00:00',
            favorite_reports: [],
            nav_open: true,
            menus_open: {Dashboards: true},
            cache: {},
            quicksight_parameters: []
        };

        Hub.listen('auth', (data) => {
            console.log("Hub.listen(auth}", data);
            const { payload } = data;
            this.onAuthEvent(payload);
                
            //console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);
        });
    }

    updateOperator = (val) => {
        logger.debug("updateOperator", val);
        this.setState({'default_operator_icao_code': val});
    }

    updateAircraft = (val) => {
       this.setState({'default_registration_name': val});
    }

    updateDateRange = (val) => {
        logger.debug("updateDateRange", val);
        this.setState({'start_date': val.start_date});
        this.setState({'end_date': val.end_date});

    }

    updateQuickSightParameter = (parameters, force=false) => {
        logger.debug("quicksight_parameters1 updateQuickSightParamter", parameters,this.state.quicksight_parameters);
        var params = this.state.quicksight_parameters;
        if (force) params = [];
        parameters.forEach((n) => {
            var found = false;
            params.forEach((p) => {
                if (n.Name == p.Name) {
                    p.Values = n.Values;
                    found = true;
                }
            });
            if (!found) params.push(n);
        });
        logger.debug("quicksight_parameters2", params);

        this.setState({'quicksight_parameters': params});
    }

    setNavOpen = (val) => {
        this.setState({'nav_open': val});
        const user_service = new UserService();
        const key = this.state.user.session.email;
        var new_val = 1;
        if (!val) new_val = 2;
        user_service.update(key, {default_nav_menu_state: new_val})
            .then((data) => {
                logger.debug("UserService setNavOpen success", data, val)
            }).catch((err) => {
                logger.debug("UserService setNavOpen error", err)
            });
    }

    MenuHandleClick = (menu, val) => {
        logger.debug('onClickMenu', menu, val);
        const user_service = new UserService();
        var new_state = this.state.menus_open;
        const key = this.state.user.session.email;
        var new_val = 1;
        if (!val) new_val = 2;
        new_state[menu] = val;
        this.setState({menus_open: new_state});
        //this.setState({menus_open: new_state});
        //user_service.setMenuOpen(menu, val);
        /*
        logger.debug("setMenuOpen1",new_state);
        user_service.update(key, {menus_open: new_state})
            .then((data) => {
                logger.debug("UserService setMenuOpen success", data, val)
            }).catch((err) => {
                logger.debug("UserService setMenuOpen error", err)
            });
            */
    }

    setPageTitle = (title) => {
        if (title != this.state.page_title)
            this.setState({'page_title': title});
    }

    setCache(key, value) {
        logger.debug('cache', key, value, this.state.default_operator_icao_code);
        var cache = this.state.cache;
        cache[key] = value;
        this.setState({cache: cache});
    }

    searchCallback = (search_text) => {

    }


    toggleFavoriteReport = (report_id) => {
        logger.debug("toggleFavoriteReport", report_id);
        const favorite_service = new ReportFavoriteService();

        var new_favorites = [];
        var found = false;
        this.state.favorite_reports.forEach(existing_report_id => {
            if (report_id != existing_report_id) new_favorites.push(existing_report_id);
            else {
                found = true;
                favorite_service.deleteRecord(report_id)
            }
        });

        if (!found) {
            new_favorites.push(report_id);
            favorite_service.update(report_id);
        }

        this.setState({
            favorite_reports: new_favorites
        });

    }

    async componentDidMount() {

        logger.debug("componentDidMount");
      
        // unused?
        window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
            console.log("myErrorHandler", errorMsg, url, lineNumber);
            return true;
        };
        
        // unused?  
        window.addEventListener('error', (event) => {
          console.log("addEventListener error", event);
          return true;
        });

        // this is meant to suppress the Failed to execute 'replaceState' on 'History' error
        window.addEventListener('unhandledrejection', (event) => {
          console.log('unhandledrejection', event);
          event.preventDefault();
          return false;
        });

        var intervalId = setInterval(() => {
            logger.debug('auth interval');

            Auth.currentAuthenticatedUser()
                .then((user) => {
                    clearInterval(intervalId);
                    
                    const helper2 = new TokenHelper();
                    helper2.setAccessTokenToStore(user.signInUserSession.accessToken.jwtToken);

                    logger.debug("service Auth.currentAuthenticatedUser success", user, user.signInUserSession.accessToken, user.signInUserSession.idToken, user.signInUserSession.idToken.payload);
                    //logger.debug("USER IN ROUTE: ", user);
                    //logger.debug('USER SESSION ID TOKEN', user.signInUserSession.idToken)
                    //logger.debug('USER TOKEN PAYLOAD', user.signInUserSession.idToken.payload)

                    const reports_service = new ReportsService();

                    var username = user.attributes.email;
                    username = username.toLowerCase();

                    var user_object = user.signInUserSession.idToken;

                    const user_service = new UserService();
                    user_service.fetch(username)
                        .then((data) => {
                          
                            logger.debug("UserService success", data);
                             reports_service.fetch(constants.app)
                                .then((reports_data) => {
                                    
                                    logger.debug("reports_service success1", reports_data);
                                    reports_data.config = reports_service.formatRowData(reports_data);
                                    logger.debug("reports_service success2", reports_data);

                                    var default_operator_icao_code = data.preferred_operator;

                                    let search = window.location.search;

                                    let params = new URLSearchParams(search);
                                    let query_operator = params.get('operator');
                                    if (query_operator) default_operator_icao_code = query_operator;
                                    let start = params.get('start');
                                    let end = params.get('end');



                                    user_object['session'] = data;
                                    var new_favorites = []
                                    if (data.report_favorites) {
                                        for (let [key, value] of Object.entries(data.report_favorites)) {
                                          new_favorites.push(key);
                                        }
                                    }
                                    var new_nav_state = true
                                    if (data.default_nav_menu_state == 2) new_nav_state = false

                                    this.setState({
                                        isLoaded: true,
                                        isAuthenticated: true,
                                        isPermitted: true,
                                        user: user_object,
                                        default_operator_icao_code: default_operator_icao_code,
                                        start_date: start,
                                        end_date: end,
                                        favorite_reports: new_favorites,
                                        reports_list: reports_data,
                                        nav_open: new_nav_state
                                    });
                                })
                                .catch((err) => {

                                    logger.debug('ReportsService add failed', err);
                                    this.setState({
                                        isLoaded: true,
                                        isAuthenticated: true,
                                        isPermitted: false,
                                        isFailed: 1
                                    });
                                    //clearInterval(intervalId);
                                });
                        }).catch((err) => {
                            logger.debug('UserService fetech error', err, user_object);
                            user_service.add(user_object.payload)
                                .then((new_user_data) => {
                                    logger.debug('UserService add success', new_user_data);
                                    reports_service.fetch(constants.app)
                                    .then((reports_data) => {
                                        logger.debug("reports_service success1", reports_data);
                                        reports_data = user_service.formatRowData(reports_data);
                                        logger.debug("reports_service success2", reports_data);
                                        //   var report_list = require('../data/' + constants.report_list);
                                        // user_object['report_list'] = report_list;
                                        user_object['session'] = new_user_data;
                                        this.setState({
                                            isLoaded: true,
                                            isAuthenticated: true,
                                            isPermitted: true,
                                            user: user_object,
                                            reports_list: reports_data,
                                            //default_operator_icao_code: new_user_data.preferred_operator.preferred_operator
                                            });
                                        })
                                    .catch((err) => {

                                        logger.debug('ReportsService add failed', err);
                                        this.setState({
                                            isLoaded: true,
                                            isAuthenticated: true,
                                            isPermitted: false,
                                            isFailed: 2
                                        });
                                    //clearInterval(intervalId);
                                });
                                 }).catch((err) => {
                                    logger.debug('UserService add failed', err);
                                    this.setState({
                                        isLoaded: true,
                                        isAuthenticated: false,
                                        isPermitted: false,
                                        user: user_object
                                    });
                                    //clearInterval(intervalId);
                                })
                            });

                }).catch((err) => {
                    logger.debug("service Auth.currentAuthenticatedUser error");
                    logger.error(err);
                    this.setState({
                        isLoaded: true,
                        isAuthenticated: false,
                        isPermitted: false,
                        user: {}
                    });
                    clearInterval(intervalId);
                });
                    /*
                    logger.debug("UserService auth success");
                    var username = user.attributes.email;
                    username = username.toLowerCase();

                    logger.debug("USER IN ROUTE: ", user);
                    logger.debug('USER SESSION ID TOKEN', user.signInUserSession.idToken)
                    logger.debug('USER TOKEN PAYLOAD', user.signInUserSession.idToken.payload)

                    var user_object = user.signInUserSession.idToken;

                    const user_service = new UserService();
                    user_service.fetch(username)
                        .then((data) => {

                            var default_operator_icao_code = data.preferred_operator;

                            let search = window.location.search;

                            let params = new URLSearchParams(search);
                            let query_operator = params.get('Operator');
                            if (query_operator) default_operator_icao_code = query_operator;

                            user_object['session'] = data;
                            var new_favorites = []
                            if (data.report_favorites) {
                                for (let [key, value] of Object.entries(data.report_favorites)) {
                                  new_favorites.push(key);
                                }
                            }
                            var new_nav_state = true
                            if (data.default_nav_menu_state == 2) new_nav_state = false
                            logger.debug("UserService success", data, new_nav_state);
                            this.setState({
                                isLoaded: true,
                                isAuthenticated: true,
                                isPermitted: true,
                                user: user_object,
                                default_operator_icao_code: default_operator_icao_code,
                                favorite_reports: new_favorites,
                                nav_open: new_nav_state
                            });
                        }).catch((err) => {
                            logger.debug('UserService fetech error', err, user_object);
                            user_service.add(user_object.payload)
                                .then((new_user_data) => {
                                    logger.debug('UserService add success', new_user_data);

                                 //   var report_list = require('../data/' + constants.report_list);
                                   // user_object['report_list'] = report_list;
                                    user_object['session'] = new_user_data;
                                    this.setState({
                                        isLoaded: true,
                                        isAuthenticated: true,
                                        isPermitted: true,
                                        user: user_object
                                        //default_operator_icao_code: new_user_data.preferred_operator.preferred_operator
                                    });
                                 }).catch((err) => {
                                    logger.debug('UserService add failed', err);
                                    this.setState({
                                        isLoaded: true,
                                        isAuthenticated: true,
                                        isPermitted: false,
                                        user: user_object
                                    });
                                })
                        });
                    clearInterval(intervalId);
                }).catch((err) => {
                    logger.debug("UserService auth fail");
                    logger.error(err);
                    this.setState({
                        isLoaded: true,
                        isAuthenticated: false,
                        isPermitted: false,
                        user: {}
                    });
                clearInterval(intervalId);
                */
                /* UNUSED
                  const cognitoUser = await Auth.currentAuthenticatedUser();
                  const currentSession = await Auth.currentSession();
                  cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
                    console.log('session', err, session);
                    const { idToken, refreshToken, accessToken } = session;
                    // do whatever you want to do now :)
                  });
                } catch (e) {
                  console.log('Unable to refresh Token', e);
                }
                */





        }, 500);


        var tokenRefreshIntervalId = setInterval(() => {
            
            logger.debug('refresh token interval');
            logger.debug('fetching current authenticated user');
            Auth.currentAuthenticatedUser()
            .then((cognitoUser) => {
                logger.debug('cognitoUser-->',cognitoUser);
                logger.debug('fetching currentSession');
                Auth.currentSession()
                .then((currentSession) => {
                    logger.debug('currentSession-->',currentSession);
                    try{
                        logger.debug('check if refreshSession required');


                        var idTokenExpire = currentSession.getIdToken().getExpiration();
                        var refreshToken = currentSession.getRefreshToken();
                        var currentTimeSeconds = Math.round(+new Date() / 1000);
                        logger.debug('idTokenExpire-->',idTokenExpire, refreshToken, currentTimeSeconds);
                        if ((idTokenExpire - 5 * 60 * 1000) <= currentTimeSeconds) {
                            const helper = new TokenHelper();
                            if (!refreshToken.getToken()) {
                                refreshToken.token = helper.getRefreshToken();
                            } 
                            logger.debug('attempting refresh session', refreshToken);
                            cognitoUser.refreshSession(refreshToken, (err, session) => {
                                if (session) {
                                    logger.debug('session refreshed....');
                                    logger.debug('session refresh1 ', err, session);
                                    const { idToken, refreshToken, accessToken } = session;
                                    logger.debug('session refresh2', idToken, refreshToken, accessToken);
                                    //copy the token to session
                                   
                                    //helper.copyIDTokenToLocalSessionStorage(cognitoUser);
                                    helper.copyIDTokenToCookieStorage(cognitoUser);
                                    helper.copyRefreshTokenToLocalSessionStorage(cognitoUser);
                                    helper.copyAccessTokenToLocalSessionStorage(cognitoUser);
                                }
                                else if (err) {
                                    logger.debug('session refresh  error', err);
                                    
                                }
                            });
                        }


                    }
                    catch (e){
                         console.log('Unable to refresh Token', e);
                    }
                }).catch((err) => {
                    logger.debug("fetching current authenticated session fail");
                    logger.error(err);
                    this.setState({
                        isLoaded: true,
                        isAuthenticated: false,
                        isPermitted: false,
                        user: {}
                    });
                clearInterval(intervalId);

            })


            }).catch((err) => {
                    logger.debug("fetching current authenticated user fail", err);
                    this.setState({
                        isLoaded: true,
                        isAuthenticated: false,
                        isPermitted: false,
                        user: {}
                    });
                clearInterval(intervalId);

            })





        }, 60000);


    }

    onAuthEvent(payload) {
        console.log('onAuthEvent PAYLOAD: ', payload);
        const { event, data } = payload;
        switch (event) {
            case "signIn":
                this.setState({ user: data });
                this.runAfterSignIn();
                break;
            //case "signOut":
            //    this.setState({ user: null });
            //    break;
            case "customOAuthState":
                this.setState({ customState: data });
              case "tokenRefresh":
                   logger.debug("onAuthEvent tokenRefresh");
        }
    }

    runAfterSignIn() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                const helper = new TokenHelper();
                try{
                    helper.copyRefreshTokenToLocalSessionStorage(user);
                }
                catch (e){
                     console.log('Unable to set refresh Token in local storage', e);
                }
                helper.copyIDTokenToCookieStorage(user);
            })
            .catch(err => console.log('>>>>currentAuthenticatedUser error', err));

    }


    render() {
        // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
        // Logger.debug("prefersDarkMode", prefersDarkMode);

        logger.debug('render', this.state);

        console.log('PATH--->',window.location.pathname);

        if (this.state.isLoaded === false) {
            var embedded = false;
            if (window.location.pathname == "/flightmap") embedded = true;
            return <LoadingView embedded={embedded} />;
        }
        if (this.state.isAuthenticated === false) {
            return (
                <ErrorView {...this.state} />
            );
        }
        if (this.state.isPermitted === false) {
            return (
                <ErrorView {...this.state} />
            );
        }
        else {
            // todo: re-check login status here. Refresh the token
            return (
                <ThemeProvider theme={baseTheme}>
                    <AppContext.Provider value = {{
                        state: this.state,
                        updateOperator: this.updateOperator,
                        updateAircraft: this.updateAircraft,
                        updateDateRange: this.updateDateRange,
                        toggleFavoriteReport: this.toggleFavoriteReport,
                        setNavOpen: this.setNavOpen,
                        MenuHandleClick: this.MenuHandleClick,
                        setPageTitle: this.setPageTitle,
                        setCache: this.setCache,
                        updateQuickSightParameter: this.updateQuickSightParameter
                    }}>
                            <CssBaseline />
                            <Router>
                                <ScrollToTop />
                                <Switch>
                                    <Route path="/logview">
                                        <LogView />
                                    </Route>
                                    <Route path="/timeline">
                                        <Timeline />
                                    </Route>
                                    <Route path="/gridtest">
                                        <GridTest />
                                    </Route>
                                     <Route path="/gridtest3">
                                        <GridTest3 />
                                    </Route>
                                    <Route path="/gridtest4">
                                        <GridTest4 />
                                    </Route>
                                    {/*
                                    <Route path="/home2">
                                        <DashboardEditor />
                                    </Route>
                                    */}
                                    <Route path="/operations">
                                        <RouterOperations/>
                                    </Route>
                                    <Route path="/flightmap">
                                        <FlightMapView />
                                    </Route>
                                    <Route path="/linkbox">
                                        <LinkBoxView />
                                    </Route>
                                    <Route path="/map1">
                                        <Map1View />
                                    </Route>
                                    <Route path="/map2">
                                        <FlightMapView />
                                    </Route>

                                    <Route path="/map3">
                                        <Map3View />
                                    </Route>
                                    <Route path="/about">
                                        <About />
                                    </Route>
                                    <Route path="/profile">
                                        <Profile />
                                    </Route>
                                    <Route path="/preferences">
                                        <Preferences />
                                    </Route>
                                    {/*
                                    <Route path="/Users">
                                        <Users />
                                    </Route>
                                    */}
                                    {/*
                                    <Route path="/QuickSight">
                                         <QuickSight />
                                    </Route>
                                    */ }
                                    {/*
                                   <Route path="/oooi_diagnostic_tool">
                                        <FlightList />
                                    </Route>
                                    *.}
                                    {/*
                                    <Route path="/reports" component={() => {
                                        let location = useLocation();
                                        let new_path = "/dashboards/" + location.pathname.substring(9);
                                        return <Redirect to={new_path} />
                                    }}/>
                                    */}
                                    <Route path="/dashboards">
                                        <RouterReport default_operator_icao_code={this.state.default_operator_icao_code} />
                                    </Route>
                                     <Route path="/feedback">
                                        <Feedback />
                                    </Route>
                                    <Route path="/search">
                                        <RouterSearch />
                                    </Route>
                                    <Route path="/signout">
                                        <Signout />
                                    </Route>
                                    <Route exact path='/app/profile' component={() => {
                                        window.location.href = 'https://portal.nextcloud.aero/app/profile';
                                        return null;
                                    }}/>
                                    <Route exact path='/app/notifications' component={() => {
                                        window.location.href = 'https://portal.nextcloud.aero/app/notifications';
                                        return null;
                                    }}/>
                                    <Route exact path='/app/settings' component={() => {
                                        window.location.href = 'https://portal.nextcloud.aero/app/settings';
                                        return null;
                                    }}/>
                                    <Route path="/">
                                        <Home />
                                    </Route>
                                </Switch>
                            </Router>
                    </AppContext.Provider>
                </ThemeProvider>
            );
        }
    }
}

export default App;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

import { Logger }                   from 'aws-amplify';

const logger = new Logger('Checkbox');


export default function Boolean(props) {
  logger.debug("props", props);
  var init_val = false;
  if (props.value) init_val = true;
  const [value, setValue] = React.useState(init_val);

  const handleChange = (event) => {
    var val = 0;
    if (event.target.checked) val = 1;
    logger.debug("handleChange", event.target.checked);
    setValue(event.target.checked);
    props.handleChange({target: {name: props.name, value: val}});
  };
  
  var label = props.headerName;
  if (props.label) label = props.label;
  

  return (
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={handleChange}
            name={props.name}
            color="primary"
            disabled={props.disabled}
          />
        }
        label={label}
      />
      
   
  );
}
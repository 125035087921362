import BaseService from './BaseService';

export default class LogsService extends BaseService {

    constructor() {

        let apiName = 'OriginsAPI';
        let collectionPath = '/logviewer';
        super(apiName, collectionPath);

    }

    
}

/**
 *  Generic map with no view components
 */ 

import React, {useState}  from 'react';
import MapService      from '../services/MapService';
import View   from 'pac-responsive-ui-framework/core/View';
import { makeStyles } from '@material-ui/core/styles';

import Map    from 'components/Map';

export default function Map2(props) {
  const useStyles = makeStyles(theme => ({
      root: {
          flexGrow: 1,
          paddingTop: "32px",
          paddingBottom: "32px",
          paddingLeft: "32px",
          height: "400px", 
          width: "600px",
         
        }       
  }));
  
   const [geoData, setGeoData] = useState(null);
 
  const service = new MapService();
  service.fetchRecords({flightid: 8183021})
    .then((data) => {
        if (data['meta'].result_count > 0) 
          if (!geoData)
            setGeoData(MapService.parseData(data));
    }).catch((err) => {
      console.log("fetchRecords:error", err);  
      // do something here.
    });
  
  
  
  const classes = useStyles();
  return (
       <View fixedContent={true} title="Map Example">
         <div className={classes.root}>
            <Map data={geoData}/>
        </div>
        <div className={classes.root}>
        <iframe 
            frameBorder="0" 
            width="100%" height= "100%"
           
            id="feedback" 
            title="feedback form" 
            frameborder= "0" 
            marginwidth= "0" marginheight= "0" 
            scrolling="no"
            src="/map2" >
	   
        </iframe>
        </div>
    </View>
          
  );
}
/**
 * @obsolete - unused test page
 */ 
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import View                     from 'pac-responsive-ui-framework/core/View';
import Container from '@material-ui/core/Container';
import News from '../components/News';

export default function Profile(props) {
    
const useStyles = makeStyles({
  container: {
    paddingLeft: 96,
  },
  root: {
    flexGrow: 1,
  },
 
});
   const classes = useStyles();


  return (
     <View title="Preferences" >
          <News message="Preferences" />
    </View>
  );
    
}
import React, { useContext, useState }          from 'react';
import { Logger } from 'aws-amplify';
import View           from 'pac-responsive-ui-framework/core/View';
import AGGridOOOIFlights         from '../components/AGGridOOOIFlights';
//import Service        from '../services/FlightDataService';
import Service        from '../services/OOOI';

//import FlightFormFilter     from '../components/FormFilters/Flight';
import AppContext      from 'pac-responsive-ui-framework/core/AppContext';

const logger = new Logger('views/FlightList');

export default function GridTest() {
  const service = new Service();
  var isoStr1 = new Date();
  var isoStr2 = new Date();
  isoStr1.setDate(isoStr1.getDate() - 1);
  logger.debug('date', isoStr1.toISOString().substr(0, 19).replace('T', ' '), isoStr2.toISOString());
  const app_context = useContext(AppContext);  
  logger.debug(app_context);

  var start_date = isoStr1.toISOString().substr(0, 17).replace('T', ' ') + "00";
  var end_date = isoStr2.toISOString().substr(0, 17).replace('T', ' ') + "59";
  
  start_date = app_context.state.start_date;
  end_date = app_context.state.end_date;
  
  var init_state = {
      operator_icao_code:app_context.state.default_operator_icao_code === '---'?null:app_context.state.default_operator_icao_code, 
      start_date: start_date,
      end_date: end_date, 
      'page[size]': "25",
      'page[number]': 1,
      include_oooi: "True"
  }
  /*
  const [state, setState] = useState(init_state);
  
  
  const handleFilterChange = (state) => {
    logger.debug("handleFilterChange", state);
    setState({
      operator_icao_code: state.operator_icao_code, 
      start_date: state.start_date,
      end_date: state.end_date, 
      'page[size]': "25",
      'page[number]': 1,
      include_oooi: "True"
    });
  };
  
  var GridHeader = 
    <FlightFormFilter state={state} onChange={handleFilterChange}/>
  */
  return (
      <View title="OOOI Diagnostic Tool" fixedContent={true}>
          <AGGridOOOIFlights 
            service={service} 
            columnDefs={service.getColumnDefs()}
            query={init_state}
           />
      </View>
  );
  
}
import React, { useContext }  from 'react';
import { Logger }             from 'aws-amplify';
import PropTypes              from 'prop-types';

import Downshift            from 'downshift';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import TextField            from '@material-ui/core/TextField';
import Popper               from '@material-ui/core/Popper';
import Paper                from '@material-ui/core/Paper';
import MenuItem             from '@material-ui/core/MenuItem';
import { Link }             from 'react-router-dom';
import Button               from '@material-ui/core/Button';
import InputAdornment       from '@material-ui/core/InputAdornment';
import SearchIcon           from '@material-ui/icons/Search';
import Box                  from '@material-ui/core/Box';
import Tooltip              from '@material-ui/core/Tooltip';

import AppContext           from '../core/AppContext';
import { getReportDetails } from '../../data/ReportList';

import IconButtonNewWindow     from '../core/IconButtonNewWindow';
import { useHistory } from "react-router-dom";
import { parseHTMLtoJSX }   from '../core/HelperHTML';
import { getURLParams }         from '../../data/ReportList';

const logger = new Logger('InputSearch');
const HtmlTooltip = withStyles(theme => ({
  
  
  tooltip: {
    backgroundColor: theme.palette.primary1Color ,
    color: theme.palette.contrastText,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  root: {
     flexGrow: 1,
  },
  container: {
    whiteSpace: "nowrap",
    flexGrow: 1,
    position: 'relative',
     borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
        backgroundColor: theme.palette.primary.hover,
    },
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  //  paddingRight: theme.spacing(0),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'nowrap',
    color: 'inherit',
  },
  inputInput: {
    //width: 'auto',
    flexGrow: 1,
    padding: theme.spacing(1, 1, 1, 1),
    color: theme.palette.primary.contrastText
  },
  searchIcon: {
    color: theme.palette.primary.contrastText
  }
  
}));

function renderInput(inputProps) {
  logger.debug('renderInput', inputProps);
  

  
  const { InputProps, classes, ref, history, ...other } = inputProps;
  return (
    <TextField
      InputProps={{
        
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        endAdornment: 
          <InputAdornment position="end" >
            <Button
             onClick =  {(event) => {
                        history.push('/search/' + inputProps.selection)
                      }}
            >
              <SearchIcon className={classes.searchIcon} />
            </Button>
          </InputAdornment>,
        disableUnderline : true,
        ...InputProps,
      }}
     
      {...other}
    />
      
  );
}

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};


function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.title) > -1;
  var component = Link;
  logger.debug('renderSuggestion', suggestionProps);
  
  
  var tooltip_title;
  if (suggestion.subtitle) tooltip_title= parseHTMLtoJSX('<p><b>' + suggestion.title + '</b></p>' + suggestion.subtitle);
  else tooltip_title= parseHTMLtoJSX('<p><b>' + suggestion.title + '</b>');
  
  return (
    <MenuItem
      {...itemProps}
      key={suggestion.title}
      target={suggestion.target}
      label={suggestion.title}
      selected={isHighlighted}
      component={component}
      to={suggestion.href}
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
          
    >
        <AppContext.Consumer>
            {content => (
                <React.Fragment>
                  {
                     suggestion.target == "_blank" && <div title="External link.  This will open in a new tab or window" style={{paddingLeft: "20px", paddingRight:"18px"}}>↗</div>
                     
                  }
                  {
                    suggestion.target != "_blank" && <IconButtonNewWindow href={suggestion.href + getURLParams(content)}  />
                  }
                </React.Fragment>
            )}
        </AppContext.Consumer>
        <HtmlTooltip
            placement="bottom-start"
            title = {tooltip_title} 
              
        >
          <Box paddingLeft={1}>
            {suggestion.title} 
          </Box> 
        </HtmlTooltip>
    </MenuItem>
  );

}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};




let popperNode;

export default function InputSearch(props) {
  const classes = useStyles();
  const app_context   = useContext(AppContext);  
  
  let history = useHistory();


  return (
    <div className={classes.root}>
      
      <Downshift id="downshift-popper" selectedItem={props.defaultValue} initialSelectedItem={props.defaultValue}
       
        onChange={selection => {
          if (selection) {
            var report_details = getReportDetails(selection,  app_context.state.user.session.acl_role, app_context.state.user.session.pac_icao_code);
            if (report_details.target != '_blank') {
              history.push(report_details.href)
            }
          } 
        }}
      >
        {({
          clearSelection,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          openMenu,
          selectedItem,
        
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            onKeyDown: (event) => { 
              if (event.keyCode === 13) {
                event.preventDefault();
                history.push('/search/' + event.target.value);
              }
            },
            onFocus: (event) => { 
              event.preventDefault();
              event.target.select();
              openMenu();
            },
            placeholder: props.placeholder,
          });

          return (
            <div className={classes.container}>
              {renderInput({
              selection: inputValue,
                fullWidth: true,
                classes,
                //label: 'Country',
                InputProps: { onBlur, onFocus },
                InputLabelProps: getLabelProps({ shrink: true }),
                inputProps,
                ref: node => {
                  popperNode = node
                },
                history: history
              })}

              <Popper open={isOpen} anchorEl={popperNode}>
                <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
                  <Paper
                    square
                    style={{ maxHeight: 300, overflow: 'auto', marginTop: 20, minWidth: 300, width: popperNode ? popperNode.clientWidth : undefined }}
                  >
                 
                   {
                      props.searchFunction(inputValue, app_context.state.user.session.acl_role, app_context.state.user.session.pac_icao_code).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.title }),
                        highlightedIndex,
                        selectedItem,
                      }),
                    )}
                   
                  </Paper>
                </div>
              </Popper>
              
            </div>
            
          );
        }}
      </Downshift>
      
    </div>
  );
}

import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/icons/Info';

export default function IconButtonConfluence(props) {
    
     return (
        <Tooltip title="Click here for more information in Confluence" placement="bottom-start">
           <a 
            href={props.href} 
            target="_blank"
            rel="noopener noreferrer"
            color="inherit" 
            style={{textDecoration: 'none' }}
            component="button" 
            aria-label="more info" 
            onClick={(event) => {event.stopPropagation()}}
          
          >
          <IconButton aria-label="more info"> 
              <Icon fontSize="inherit" />
          </IconButton>
          </a>
      </Tooltip>
      );
}
import React    from 'react';
import Button   from '@material-ui/core/Button';

export default function ButtonSaveFilters(props) {

    return (
        <Button variant="outlined" size="small" color="primary" onClick={() => props.callback()}>
           Save Filters 
        </Button>
    )
}
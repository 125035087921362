import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ErrorIcon     from '@material-ui/icons/Error';
import { Logger } from 'aws-amplify';
const logger = new Logger('Error');
export default function Error(props) {
    logger.debug(props);
    /*
    return <div style={
        {
            display: "flex",
            flexdDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "100vh"  
        
        }
    }>
    */
    return (
        <div style={
            {
                display: "flex",
                flexdDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                minHeight: "100vh"  
            
            }
        }>
        <table>
            <tbody>
            <tr><td align="center" style={{paddingBottom: "20px"}}>
            <ErrorIcon style={{ fontSize: '8rem' }} />
            </td></tr>
            <tr><td align="center" style={{paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px"}}>
            <Typography variant="h5" display="block">The server encountered an internal error and was unable to complete your request.</Typography>
            </td></tr>
            <tr><td align="center" style={{paddingBottom: "30px", paddingLeft: "20px", paddingRight: "20px"}}>
            <Typography variant="h6" display="block">Please try refreshing the page or contact your administrator to report the issue.</Typography>
            </td></tr>
            </tbody>
        </table>
        </div>
    );
}
import React          from 'react';
//import { makeStyles } from '@material-ui/styles';
import { Link }       from 'react-router-dom';
import MenuItem       from '@material-ui/core/MenuItem';
import ListItemIcon   from '@material-ui/core/ListItemIcon';
import ListItemText   from '@material-ui/core/ListItemText';
import Tooltip        from '@material-ui/core/Tooltip';
import Typography     from '@material-ui/core/Typography';



export default function NavigationMenuItem(props) {
 
 //const classes = useStyles();
 
 var list_item_icon = '';
 if (props.icon) {
   list_item_icon = <ListItemIcon>{props.icon}</ListItemIcon>
 }
 
  var content = 
    <MenuItem component={Link} to={props.path} target={props.target}>
      {list_item_icon}
      <ListItemText primary={props.text} style={{whiteSpace: "normal"}} />
    </MenuItem>;

  if (props.description) {

    return (
      <Tooltip title={props.description} enterDelay={700}>
        {content}
      </Tooltip>
    );
  }
  else {
    return content;
  }

}
import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReportsService from '../../services/ReportsService';
import { Logger } from 'aws-amplify';
const logger = new Logger('DeployEnvironmentButton');

export default function DeployEnvironmentButton(props) {
  logger.debug("props", props);
  
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  
  // This triggers when the parameters change coming into the function
    useEffect(() => {
        logger.debug("useEffect",props.gridRef);
    }, [props.gridRef]);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleDeploy= () => {
    setSaving(true);
    
  };

  if (saving) {
    const service_prod = new ReportsService("OriginsAPIProd", false);
    service_prod.fetch(props.recordID) 
      .then((data) => {
        data['reportid'] = props.recordID;
        logger.debug("data", data);
        
        var service_env_test;
        service_env_test = new ReportsService("OriginsAPITest", false);
        var service_env_dev;
        service_env_dev = new ReportsService("OriginsAPIDev", false);
        
        service_env_test.update(props.recordID, data)
          .then((results) => {
            logger.debug("results", results);
              service_env_dev.update(props.recordID, data)
                .then((results) => {
                  logger.debug("results", results);
                  setSaving(false);
                  setOpen(false);
                  
                  props.handleRefresh();
                })
                .catch((err) => {
                  setSaving(false);
                });
          })
          .catch((err) => {
            setSaving(false);
          });
      })
      .catch((err) => {
        setSaving(false);
      });
  }
  
                            
  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Copy Data from Production to Test and Dev
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Copy Data from Production"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p><b>Warning:</b> This action will replace the report data on the Test and Dev
              environments, and replace it with what is on Production.</p>
            <p>
              Are you sure you want to copy Production data to Test and Dev?
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeploy} color="primary" autoFocus disabled={saving > 0}>
            Deploy
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
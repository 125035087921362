import React, { useContext }    from 'react';
import { Logger }             from 'aws-amplify';

import AppContext               from 'pac-responsive-ui-framework/core/AppContext';
import IconButtonHome           from 'pac-responsive-ui-framework/header/IconButtonHome';
import PageTitle                from 'pac-responsive-ui-framework/header/PageTitle';
import InputSearch              from 'pac-responsive-ui-framework/header/InputSearch';
import Spacer                   from 'pac-responsive-ui-framework/header/Spacer';
import MenuUser                 from './MenuUser';
import MenuApps                 from 'pac-responsive-ui-framework/header/MenuApps';


import { setReportList, getRecentReportList, searchReports } from '../data/ReportList';

const logger = new Logger('Header');

var recents;
var state;

function getSuggestions(inputValue) {
    logger.debug('getSuggestions', inputValue, recents);
    if (inputValue.length === 0) 
        return recents;

    else 
        return searchReports(inputValue, 20, state);
}



export default function Header(props) {

    const app_context = useContext(AppContext);  
    logger.debug("app_context", app_context);
    setReportList(app_context.state.reports_list);
    state = app_context.state;
    recents = getRecentReportList(app_context);

    var defaultValue = app_context.state.page_title;
    if (defaultValue == 'Home') defaultValue = '';



    return (
        <React.Fragment>
            <IconButtonHome 
                title={app_context.state.title}
                logo=<img src={require("../assets/appbarlogo.png")} alt="logo" />
                icon=<img src={require("../assets/appbarlogomobile.png")} alt="logo" />
            />
            <InputSearch 
                placeholder='Search'
                defaultValue={defaultValue}
                searchFunction={getSuggestions}
            />
            <Spacer/>
            <MenuUser/>
            <MenuApps/>
        </React.Fragment>
    );
}
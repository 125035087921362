/* eslint-disable no-use-before-define */
import React from 'react';
import { Logger } from 'aws-amplify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import ClearIcon from "@material-ui/icons/Clear";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from "@material-ui/core/Checkbox";
const logger = new Logger('FormElements/MultiSelectSimple');
var width= 340;
const useStyles = makeStyles((theme) => ({
  root: {
    width: width,
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  MuiTextFieldRoot: {
    height: "40px"
  }
}));

const StyledChip = withStyles({
root: {
    "&.MuiChip-root": {
    borderRadius: "4px"
  },
  ".MuiChip-deleteIcon": {
    color: "black"
  }
}
})(Chip);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelect2(props) {
  const classes = useStyles();



  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        size="small" 
        limitTags={3}
        id="multiple-limit-tags"
        disableCloseOnSelect={true}
        options={props.options}
        getOptionLabel={(option) => option?option:""}
        defaultValue={[]}
        value={props.value}
        onChange={props.onChange}
        renderInput={(params) => (
            <TextField {...params} 
                style = {{width: width + "px"}}
                InputLabelProps={{shrink: true}} 
                variant="outlined" 
                label={props.label} 
                placeholder={props.placeholder} 
            />
        )}
        renderOption={(option, { selected }) => {
    
        
        
        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 0 }}
              checked={selected}
            />
            <span>{option}</span>
          </React.Fragment>
      )}}
     
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
           
              <StyledChip
                {...getTagProps({ index })}
                size="small"
                label={`${option}`}
                deleteIcon={<ClearIcon />}
              />
            
          ))
        }
      />
    </div>
  );
}
import React from 'react';
import { Logger } from 'aws-amplify';

const logger = new Logger('ThirdPartycookieSupport');

var current_object;

const states = {
        loading: 0,
        linked: 1,
        ok: 2,
        bad: 3,
        timeout: 4
    };

window._3rd_party_test_step1_loaded = function(){
  current_object.state.state = states.linked;
  logger.debug("_3rd_party_test_step1_loaded");
  // At this point, a third-party domain has now attempted to set a cookie (if all went to plan!)
  var step2Url = 'https://dart.panasonic.aero/dart2/ajax/tptest.php?test2=gkfdiuerd',
    resultsEl = document.getElementById('3rd_party_cookie_test_results'),
    step2El = document.createElement('script');
  logger.debug("step2El", step2El, resultsEl);
  // Update loading / results message
  // resultsEl.innerHTML = 'Stage one complete, loading stage 2&hellip;';
  // And load the second part of the test (reading the cookie)
  step2El.setAttribute('src', step2Url);
  resultsEl.appendChild(step2El);
};

window._3rd_party_test_step2_loaded = function(cookieSuccess){
    logger.debug('_3rd_party_test_step2_loaded', cookieSuccess);
  //var resultsEl = document.getElementById('3rd_party_cookie_test_results'),
//    errorEl = document.getElementById('3rd_party_cookie_test_error');
  // Show message
  if (cookieSuccess == true) current_object.setState({state: states.ok});
  else if (cookieSuccess == false) current_object.setState({state: states.bad});

  // Done, so remove loading class
  //resultsEl.className = resultsEl.className.replace(/\bloading\b/,' ');
  // And remove error message
 // errorEl.className = 'hidden';
}

/*
window.addEventListener('error', function(e) {
    logger.debug(e);
}, true);
*/

class ThirdPartycookieSupport extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
           state: states.loading, 
           children: props.children
        };
    }
    
    async componentDidMount() {
        current_object = this;
        var step1Url = 'https://dart.panasonic.aero/dart2/ajax/tptest.php?test1=erslde',
        resultsEl = document.getElementById('3rd_party_cookie_test_results'),
        step1El = document.createElement('script');
        step1El.setAttribute('src', step1Url);
        resultsEl.appendChild(step1El);
        
        window.setTimeout(function() {
            logger.debug('timeout', current_object.state);
            if(current_object.state.state == states.loading || current_object.state.state == states.linked) {
                current_object.setState({state: states.timeout});
            }
          }, 7*1000); // 7 sec timeout
    }
    

    
   
    render() {
        logger.debug("render", this.state.state);
        if (this.state.state == states.bad) {
            return (
                <React.Fragment>
                    {this.state.children}
               </React.Fragment>
               );
        }
        else return (<p id="3rd_party_cookie_test_results"></p>);
        
    }
}

export default ThirdPartycookieSupport;

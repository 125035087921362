import React, { useContext, useState }      from 'react';
import { Logger }   from 'aws-amplify';
import AppContext      from 'pac-responsive-ui-framework/core/AppContext';
import { View }             from 'pac-responsive-ui-framework/core';

const logger = new Logger('LogView');

export default function LogView(props) {
    const app_context   = useContext(AppContext); 
    logger.debug(app_context);
     logger.debug(app_context.state);
    logger.debug(app_context.state.default_operator_icao_code);
    var qs = '';
    if (app_context.state.default_operator_icao_code) {
        qs = "?operator=" + app_context.state.default_operator_icao_code;
        if (app_context.state.default_registration_name) {
            qs = qs + "&tail=" + app_context.state.default_registration_name;
        }
    }
    
    logger.debug("qs", qs);
    var embed_url = '/timeline' + qs;
    logger.debug("enbeded_url, ")
    return (
        <View title="Log Viewer" fixedContent={true}>
            <iframe style={{padding: "16px"}} overflow-y="auto" frameBorder="0" height="100%" width="100%" id="reports" title="dashboard" src={embed_url} ></iframe> 
        </View>
    );
}
/**
 * This is the about page, a brief introduction to Origins.
 */ 
import React                from 'react';
import { Link }             from 'react-router-dom';
import Box                  from '@material-ui/core/Box';
import Typography           from '@material-ui/core/Typography';
import List                 from '@material-ui/core/List';
import ListItem             from '@material-ui/core/ListItem';
import ListItemIcon         from '@material-ui/core/ListItemIcon';
import ListItemText         from '@material-ui/core/ListItemText';

import CustomerSupportIcon  from '@material-ui/icons/SupervisorAccount';
import TroubleShootingIcon  from '@material-ui/icons/FindInPage';
import TrendingUpIcon       from '@material-ui/icons/TrendingUp';
import FeedbackIcon         from '@material-ui/icons/Feedback';
import BulletIcon           from '@material-ui/icons/Label';

import View                 from 'pac-responsive-ui-framework/core/View';
import { constants } from 'assets/Config.js';

var feedback;
if (constants.feedback_url) {
  feedback = (
    <Box p={1}>
        <Typography variant="body1">  
      
         Please use this <Link to={constants.feedback_url}><FeedbackIcon fontSize="small"/> feedback page</Link > to submit your questions, feedback and requests.
         {/*Please use this <a target="_blank" rel="noopener noreferrer" href="https://confluence.panasonic.aero/display/AC/Origins+-+User+Feedback">Confluence page</a> to give us feedback.*/}
          
        </Typography>
    </Box>
  )
}

var release_text;
if (constants.release_month) {
  release_text = (
      <Box p={1}>
        <Typography variant="body1">  
      {constants.title} was released in {constants.release_month}.
        </Typography>
      </Box>
  )
}

export default function About() {
  return (
    <View title="About">
      <Typography variant="h4">Welcome to {constants.title}.</Typography>
      <Box p={1}>
        <Typography variant="body1">{constants.tagline}<br/><br/>{constants.title} provides enhanced dashboards for:</Typography>
        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText
              primary="Trends and data analysis."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <TroubleShootingIcon />
            </ListItemIcon>
            <ListItemText
              primary="Troubleshooting and investigations"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CustomerSupportIcon />
            </ListItemIcon>
            <ListItemText
              primary="Day-to-day operational support"
            />
          </ListItem>
        </List>
      </Box>    
      {release_text}
      <br/>
      <Box p={1}>
        <Typography variant="h5">Site Navigation</Typography>
        <Typography variant="body1">  
          {constants.title} navigation is intended to be simple and intuitive.<br/><br/>
          You can open a dashboard by: </Typography>
        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <BulletIcon />
            </ListItemIcon>
            <ListItemText primary="Clicking directly on dashboard tiles" />
          </ListItem>
            <ListItem>
            <ListItemIcon>
              <BulletIcon />
            </ListItemIcon>
            <ListItemText primary="Using the Search bar to filter the list of dashboards" />
          </ListItem>
            <ListItem>
            <ListItemIcon>
              <BulletIcon />
            </ListItemIcon>
            <ListItemText primary={"Using the menu, accessible by clicking on the arrow left of the " + constants.title + " logo"} />
          </ListItem>
        </List>
        <Typography variant="body1">  
          You can <b>navigate back to the home page</b> by clicking on the {constants.title} logo (top left).<br/><br/>
          The <b>Recently Viewed</b> list automatically updates as you open dashboards.<br/><br/> 
          Click on the heart icon to mark a report as a <b>Favorite</b>: it will then be shown at the top of your dashboards list.<br/><br/> 
          You also have the option to open dashboards in separate browser tabs by clicking on the boxed arrow icon [&#8599;]
        </Typography>
      </Box>
      {feedback}
    </View>
  );
  
}
import React    from 'react';
import Button   from '@material-ui/core/Button';

export default function ButtonFilterSet(props) {

    function buttonAction() {
        props.gridApi.setFilterModel(props.model);
        props.gridApi.onFilterChanged();
    };

    return (
        <Button variant="outlined" size="small" color="primary" onClick={() => buttonAction()}>
            {props.children}
        </Button>
    )
}
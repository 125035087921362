import React, { useRef, useState, useContext }    from 'react';
import { Logger }           from 'aws-amplify';
import { useLocation } from 'react-router-dom';

import { makeStyles }       from '@material-ui/core/styles';

import { View }             from 'pac-responsive-ui-framework/core';
import Timeline            from 'components/Timeline';

const logger = new Logger('LogView');

const useStyles = makeStyles(theme => ({
    
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function LogView(props) {
    //const classes = useStyles();
     let query = useQuery();
    const operator = query.get("operator");
    const registration_name = query.get("tail");
    logger.debug(operator);
    
    var i, colors = ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572',   
             '#FF9655', '#FFF263', '#6AF9C4','#e6194b', '#3cb44b', '#ffe119', '#4363d8', 
             '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', 
             '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', 
             '#000075', '#808080', '#ffffff', '#000000'];
  
    var chart_list = [
        {
            log_name: "FCC Log",
            series_label: "Altitude (ft)",
            unit: "ft",
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "altitude"
        },
        {
            log_name: "FCC Log",
            series_label: "Longitude",
            series_unit: '°',
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "satlong"
        },
        {
            log_name: "FCC Log",
            series_label: "Rx Frequency",
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "rxfreq"
        },
        {
            log_name: "FCC Log",
            series_label: "In Network",
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "in_network"
        },
        {
            log_name: "FCC Log",
            series_label: "rxsnr",
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "rxsnr"
        },
        {
            log_name: "FCC Log",
            series_label: "TX Power",
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "tx_power"
        },
        {
            log_name: "FCC Log",
            series_label: "TX Frequency",
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "txfreq"
        },
        {
            log_name: "FCC Log",
            series_label: "Elevation",
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "elevation"
        },
        {
            log_name: "FCC Log",
            series_label: "azimuth",
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "azimuth"
        },
        {
            log_name: "FCC Log",
            series_label: "Polarization",
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "polarization"
        },
        {
            log_name: "FCC Log",
            series_label: "modemtx",
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "modemtx"
        },
        {
            log_name: "FCC Log",
            series_label: "txdatarate",
            color: colors[0],
            visible: true,
            log_type: "fcc",
            key: "txdatarate"
        },
        {
            log_name: "BC Manager",
            series_label: "Connection Timeout Count",
            color: colors[5],
            visible: true,
            log_type: "bcmgr",
            key: "conn_timeout_count"
        }
    ];
             

    return (
            <Timeline 
                chartList={chart_list} 
                date="2020-09-01"
                operator={operator}
                registrationName={registration_name}
            />

    );
}
import React            from 'react';

function createMarkup(text) {
  return {__html: text};
}

/**
 *  This fumction takes plainHTML and converts it to JSX
 *  If the text is already an object, it just returns the object unchanged 
 * 
 */
export function parseHTMLtoJSX(text) {
    if (typeof  text === 'object') return text; 
    else return <span dangerouslySetInnerHTML={createMarkup(text)} />
}
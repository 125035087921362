/**
 * This module contains the data as well as abstracted calls to retreive the data.
 * 
 * Eventually the data will be retreived from a data source.
 * 
 */
import _                    from 'lodash';
import React                from 'react';
import { Logger }           from 'aws-amplify';
//import SvgIcon              from '@material-ui/core/SvgIcon';
import GenericOperatorIcon  from '@material-ui/icons/Flight';

const logger = new Logger('data/Operators');

/**
 * @obsolete
 */ 
const operators = [
  { icao_code: 'AAL', name: 'AMERICAN AIRLINES'},
  { icao_code: 'AAR', name: 'ASIANA AIRLINES'}, 
  { icao_code: 'ABB', name: 'AIR BELGIUM'},
  { icao_code: 'ABY', name: 'AIR ARABIA'},
  { icao_code: 'ACA', name: 'AIR CANADA'},
  { icao_code: 'ACI', name: 'AIRCALIN'},
  { icao_code: 'ADO', name: 'Air Do'},
  { icao_code: 'AEA', name: 'AIR EUROPA'},
  { icao_code: 'AFL', name: 'AEROFLOT RUSSIAN AIRLINES'},
  { icao_code: 'AFR', name: 'AIR FRANCE'},
  { icao_code: 'AHY', name: 'AZERBAIJAN'},
  { icao_code: 'AIB', name: 'AIRBUS INDUSTRIE AIRLINE'},
  { icao_code: 'AIC', name: 'AIR INDIA'},
  { icao_code: 'AIJ', name: 'INTERJET AIRLINES LIMITED'},
  { icao_code: 'AIZ', name: 'ARKIA ISRAELI LTD'},
  { icao_code: 'ALK', name: 'SRILANKAN AIRLINES LIMITED'},
  { icao_code: 'AMC', name: 'AIR MALTA CO LTD'},
  { icao_code: 'AMX', name: 'AEROVIAS DE MEXICO'},
  { icao_code: 'ANA', name: 'ALL NIPPON AIRWAYS CO LTD'},
  { icao_code: 'ANZ', name: 'AIR NEW ZEALAND LTD'},
  { icao_code: 'ARA', name: 'ARIK AIR LIMITED'},
  { icao_code: 'ARG', name: 'AEROLINEAS ARGENTINAS'},
  { icao_code: 'ASL', name: 'AIR SERBIA'},
  { icao_code: 'ASV', name: 'AIR SEOUL'},
  { icao_code: 'ATC', name: 'AIR TANZANIA'},
  { icao_code: 'AUA', name: 'AUSTRIAN AIRLINES'},
  { icao_code: 'AUH', name: 'PRESIDENTIAL FLIGHT'},
  { icao_code: 'AUI', name: 'UKRAINE INTERNATIONAL AIRLINES'},
  { icao_code: 'AUT', name: 'AUSTRAL LINEAS AEREAS'},
  { icao_code: 'AVA', name: 'AVIANCA'},
  { icao_code: 'AVN', name: 'Air Vanuatu'},
  { icao_code: 'AXB', name: 'AIR INDIA EXPRESS'},
  { icao_code: 'AZA', name: 'ALITALIA COMP. AEREA ITALIANA SPA'},
  { icao_code: 'AZU', name: 'AZUL BRAZILIAN AIRLINES'},
  { icao_code: 'BAH', name: 'BAHRAIN ROYAL FLIGHT'},
  { icao_code: 'BAV', name: 'BAMBOO AIRWAYS'},
  { icao_code: 'BAW', name: 'BRITISH AIRWAYS CORP'},
  { icao_code: 'BBA', name: 'BOMBARDIER INC'},
  { icao_code: 'BBC', name: 'BIMAN BANGLADESH AIRLINES'},
  { icao_code: 'BEJ', name: 'AIR CHINA GROUP IMPORT & EXPORT TRADING CO'},
  { icao_code: 'BEL', name: 'BRUSSELS  AIRLINES'},
  { icao_code: 'BER', name: 'AIR BERLIN PLC & CO LUFTVERKEHRS KG'},
  { icao_code: 'BLX', name: 'TUIFLY NORDIC AB'},
  { icao_code: 'BMA', name: 'BRITISH MIDLAND INTERNATIONAL'},
  { icao_code: 'BOE', name: 'BOEING COMMERCIAL AIRPLANE GROUP'},
  { icao_code: 'BOV', name: 'BOLIVIANA DE AVIACION'},
  { icao_code: 'BRU', name: 'BELAVIA'},
  { icao_code: 'BTI', name: 'AIR BALTIC'},
  { icao_code: 'BTK', name: 'BATIK AIR'},
  { icao_code: 'C2A', name: 'CRYSTAL AIRCRUISES'},
  { icao_code: 'CAL', name: 'CHINA AIRLINES LTD'},
  { icao_code: 'CCA', name: 'AIR CHINA IMPORT & EXPORT CO. LTD'},
  { icao_code: 'CDG', name: 'SHANDONG AIRLINES'},
  { icao_code: 'CES', name: 'CHINA EASTERN AV IMP & EXP CORP'},
  { icao_code: 'CFG', name: 'CONDOR FLUGDIENST GMBH'},
  { icao_code: 'CHH', name: 'HAINAN AIRLINES CO LTD'},
  { icao_code: 'CMP', name: 'COPA AIRLINES'},
  { icao_code: 'CPA', name: 'CATHAY PACIFIC'},
  { icao_code: 'CQN', name: 'CHONGQING AIRLINES'},
  { icao_code: 'CRK', name: 'HONG KONG AIRLINES LIMITED'},
  { icao_code: 'CRL', name: 'CORSAIRFLY'},
  { icao_code: 'CSC', name: 'SICHUAN AIRLINES'},
  { icao_code: 'CSN', name: 'CHINA SOUTHERN AIRLINES CO LTD'},
  { icao_code: 'CSZ', name: 'SHENZHEN AIRLINES'},
  { icao_code: 'CXA', name: 'XIAMEN AIRLINES CO LTD'},
  { icao_code: 'CYP', name: 'CYPRUS AIRWAYS'},
  { icao_code: 'DAH', name: 'AIR ALGERIE SPA'},
  { icao_code: 'DAL', name: 'DELTA AIR LINES'},
  { icao_code: 'DER', name: 'DEER JET'},
  { icao_code: 'DKH', name: 'JUNEYAO AIRLINES'},
  { icao_code: 'DLH', name: 'LUFTHANSA'},
  { icao_code: 'DTH', name: 'TASSILI AIRLINES'},
  { icao_code: 'DUB', name: 'DUBAI AIR WING'},
  { icao_code: 'EDW', name: 'EDELWEISS AIR AG'},
  { icao_code: 'EGR', name: 'EQUATORIAL GUINEA'},
  { icao_code: 'EIN', name: 'AER LINGUS'},
  { icao_code: 'ELY', name: 'EL AL ISRAEL AIRLINES LTD'},
  { icao_code: 'EMB', name: 'EMBRAER'},
  { icao_code: 'ETD', name: 'ETIHAD AIRWAYS'},
  { icao_code: 'EVA', name: 'EVA AIRWAYS'},
  { icao_code: 'EVE', name: 'EVELOP AIRLINES'},
  { icao_code: 'EWG', name: 'EUROWINGS AG'},
  { icao_code: 'FIN', name: 'FINNAIR'},
  { icao_code: 'FJI', name: 'FIJI AIRWAYS'},
  { icao_code: 'FWI', name: 'AIR CARAIBE ATLANTIQUE'},
  { icao_code: 'GFA', name: 'GULF AIR COMPANY'},
  { icao_code: 'GIA', name: 'PT GARUDA INDONESIA'},
  { icao_code: 'GMI', name: 'GERMANIA FLUGGESELLSCHAFT MBH'},
  { icao_code: 'HAL', name: 'HAWAIIAN AIRLINES'},
  { icao_code: 'HDA', name: 'DRAGON AIR'},
  { icao_code: 'HFY', name: 'HIFLY'},
  { icao_code: 'HLF', name: 'TUIFLY GMBH'},
  { icao_code: 'HVN', name: 'VIETNAM AIRLINES'},
  { icao_code: 'IAW', name: 'IRAQI AIRWAYS'},
  { icao_code: 'IBE', name: 'IBERIA'},
  { icao_code: 'IFC', name: 'INDIAN AIR FORCE'},
  { icao_code: 'IRA', name: 'IRAN AIR'},
  { icao_code: 'ISS', name: 'AIR ITALY SPA'},
  { icao_code: 'IWD', name: 'IBERWORLD AIRLINES'},
  { icao_code: 'IYE', name: 'YEMEN AIRWAYS'},
  { icao_code: 'J2G', name: 'ITOCHU AIRLEASE BV'},
  { icao_code: 'JAF', name: 'TUI AIRLINES BELGIUM (JETAIRFLY)'},
  { icao_code: 'JAI', name: 'JET AIRWAYS INDIA LIMITED'},
  { icao_code: 'JAL', name: 'JAPAN AIRLINES CO LTD'},
  { icao_code: 'JNA', name: 'JIN AIR CO LTD'},
  { icao_code: 'JST', name: 'JETSTAR AIRWAYS'},
  { icao_code: 'JTA', name: 'JAPAN TRANSOCEAN AIR CO LTD'},
  { icao_code: 'KAC', name: 'KUWAIT AIRWAYS CORP'},
  { icao_code: 'KAL', name: 'KOREAN AIR'},
  { icao_code: 'KLM', name: 'KLM ROYAL DUTCH AIRLINES'},
  { icao_code: 'KQA', name: 'KENYA AIRWAYS'},
  { icao_code: 'KZR', name: 'AIR ASTANA'},
  { icao_code: 'LAN', name: 'LATAM AIRLINES GROUP SA'},
  { icao_code: 'LDI', name: 'LIVINGSTON SPA'},
  { icao_code: 'LNI', name: 'LION AIR'},
  { icao_code: 'LTU', name: 'AIRBERLIN TECHNIK GMBH'},
  { icao_code: 'MAS', name: 'MALAYSIA AIRLINES'},
  { icao_code: 'MAU', name: 'AIR MAURITIUS LTD'},
  { icao_code: 'MEA', name: 'MIDDLE EAST AIRLINES'},
  { icao_code: 'MGL', name: 'MONGOLIAN AIRLINES'},
  { icao_code: 'MJN', name: 'ROYAL AIR FORCE OF OMAN'},
  { icao_code: 'MSR', name: 'EGYPT AIR'},
  { icao_code: 'MXA', name: 'COMPANIA MEXICANA DE AVIACION S.A.'},
  { icao_code: 'MXD', name: 'MALINDO AIR'},
  { icao_code: 'NAB', name: 'NATIONAL AIRLINES'},
  { icao_code: 'NAX', name: 'NORWEGIAN AIR SHUTTLE'},
  { icao_code: 'NLY', name: 'NIKI LUFTFAHRT GMBH'},
  { icao_code: 'NOS', name: 'NEOS SPA'},
  { icao_code: 'NTC', name: 'NOKSCOOT AIRLINES'},
  { icao_code: 'OAL', name: 'OLYMPIC AIRWAYS'},
  { icao_code: 'OBT', name: 'VIRGIN ORBIT'},
  { icao_code: 'OMA', name: 'OMAN AIR'},
  { icao_code: 'OMS', name: 'SALAM AIR'},
  { icao_code: 'ORB', name: 'ORENAIR'},
  { icao_code: 'P4N', name: 'Pana Sky'},
  { icao_code: 'PAL', name: 'PHILIPPINE AIRLINES'},
  { icao_code: 'PIA', name: 'PAKISTAN INTERNATIONAL AIRLINES'},
  { icao_code: 'PLM', name: 'Wamos Air'},
  { icao_code: 'QAF', name: 'QATAR AMIRI FLIGHT'},
  { icao_code: 'QDA', name: 'QINGDAO AIRLINES CO LTD'},
  { icao_code: 'QFA', name: 'QANTAS AIRWAYS LIMITED'},
  { icao_code: 'QQE', name: 'QATAR EXECUTIVE'},
  { icao_code: 'QTR', name: 'QATAR AIRWAYS'},
  { icao_code: 'R2M', name: 'ROYAL MOROCCAN AIR FORCE'},
  { icao_code: 'RAM', name: 'ROYAL AIR MAROC'},
  { icao_code: 'RAS', name: 'ARAMCO SERVICES COMPANY'},
  { icao_code: 'RBA', name: 'ROYAL BRUNEI AIRLINES'},
  { icao_code: 'REU', name: 'AIR AUSTRAL'},
  { icao_code: 'RJA', name: 'ROYAL JORDANIAN AIRLINES'},
  { icao_code: 'RLH', name: 'RUILI AIRLINES CO., LTD'},
  { icao_code: 'RWD', name: 'RWANDAIR'},
  { icao_code: 'RZO', name: 'AZORES AIRLINES'},
  { icao_code: 'S2A', name: 'SAUDI ARAMCO'},
  { icao_code: 'SAA', name: 'SOUTH AFRICAN AIRWAYS'},
  { icao_code: 'SAS', name: 'SCANDINAVIAN AIRLINES SYSTEM'},
  { icao_code: 'SCO', name: 'SCOOT PTE LTD'},
  { icao_code: 'SDM', name: 'ROSSIYA'},
  { icao_code: 'SFB', name: 'GOVERNMENT OF BRUNEI'},
  { icao_code: 'SFJ', name: 'STAR FLYER INC'},
  { icao_code: 'SIA', name: 'SINGAPORE AIRLINES LTD'},
  { icao_code: 'SKY', name: 'SKYMARK AIRLINES'},
  { icao_code: 'SLK', name: 'SILK AIR'},
  { icao_code: 'SOR', name: 'SONAIR'},
  { icao_code: 'SVA', name: 'SAUDI ARABIAN AIRLINES'},
  { icao_code: 'SWA', name: 'SOUTHWEST AIRLINES'},
  { icao_code: 'SWR', name: 'SWISS INTERNATIONAL AIRLINES'},
  { icao_code: 'TAI', name: 'TACA INTERNATIONAL AIRLINES'},
  { icao_code: 'TAM', name: 'TAM LINHAS AEREA S/A'},
  { icao_code: 'TAP', name: 'TAP AIR PORTUGAL'},
  { icao_code: 'TAR', name: 'TUNISAIR'},
  { icao_code: 'TBA', name: 'TIBET AIRLINES CO LTD'},
  { icao_code: 'TFL', name: 'ARKEFLY'},
  { icao_code: 'TGW', name: 'SCOOT TIGERAIR PTE LTD'},
  { icao_code: 'THA', name: 'THAI AIRWAYS INTL PUBLIC CO  LTD'},
  { icao_code: 'THT', name: 'AIR TAHITI NUI'},
  { icao_code: 'THY', name: 'TURKISH AIRLINES TECHNIC INC.'},
  { icao_code: 'TNA', name: 'TRANSASIA AIRWAYS CORP'},
  { icao_code: 'TOM', name: 'THOMSON AIRWAYS LTD'},
  { icao_code: 'TSC', name: 'AIR TRANSAT'},
  { icao_code: 'TZP', name: 'ZIPAIR TOKYO INC'},
  { icao_code: 'UAE', name: 'EMIRATES AIRLINE'},
  { icao_code: 'UAL', name: 'UNITED AIRLINES'},
  { icao_code: 'UBA', name: 'MYANMAR NATIONAL AIRLINES'},
  { icao_code: 'UZB', name: 'UZBEKISTAN AIRWAYS'},
  { icao_code: 'VIR', name: 'VIRGIN ATLANTIC AIRWAYS LTD'},
  { icao_code: 'VOZ', name: 'VIRGIN AUSTRALIA AIRLINES PTY LTD'},
  { icao_code: 'VRD', name: 'VIRGIN AMERICA'},
  { icao_code: 'WCA', name: 'WCA HOLDINGS III LLC'},
  { icao_code: 'WIF', name: 'WIDEROE AS'},
  { icao_code: 'WJA', name: 'WESTJET'},
  { icao_code: 'WSW', name: 'SWOOP AIRLINES'},
  { icao_code: 'YZR', name: 'SUPARNA AIRLINES COMPANY LIMITED'}
];

/**
 * @obsolete
 */ 
operators.sort(function(a, b){
        if(a.icao_code < b.icao_code) { return -1; }
        if(a.icao_code > b.icao_code) { return 1; }
        return 0;
    })

/**
 * @obsolete not exported
 * 
 * This is a helper function for the data set to get an operator record from an icao_code
 */ 
function findOperator(operator_icao_code) {
  const result =  operators.find(( {icao_code}) => icao_code === operator_icao_code);
  logger.debug("findOperator", result);
  return result;
}

/**
 * @obsolete  not exported
 */ 
function getOperators()
{
  return operators;  
}

function imageExists(image_url){

    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status !== 404;

}


/** This takes an operator JSON object and will return an icon
 *  First returns the icon defined in the JSON
 *  Then looks for a file based on the icao_code
 *  Finally returns a generic icon.
 */ 
function getIcon(operator, props) {
  //logger.debug("getIcon", operator, props);
    var height = _.get(props, 'height', 24);
    //logger.debug("getIcon", props, height);


    const default_icon = <GenericOperatorIcon className={"rotate-90"}/>
    //return default_icon; // returns the same for all operators
    if (operator && operator.icon) return operator.icon;
    if (operator && operator.operator_icao_code) {
      if (operator.operator_icao_code !== "---") {
        try {
            return <img src={"https://origins-assets.nextcloud.aero/operator-logos/logo-" + operator.operator_icao_code.toLowerCase() + ".png"} height={height}
            onError={(e)=>{e.target.onerror = null; e.target.src="https://origins-assets.nextcloud.aero/operator-logos/logo----.png"}}
            />
        }
        catch (e) {
          //logger.debug("getIcon", e);
          // file not found, revert to default_operator icon.
        }
      }
    }
    return default_icon; 
}

export { getIcon }



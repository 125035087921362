import React, { Component }         from 'react';
import Button from '@material-ui/core/Button';

export default function ButtonClearFilters(props) {

    function clearFilters() {
        props.columnApi.getAllColumns().forEach( col => {
            var instance = props.gridApi.getFilterInstance(col.colId);
            instance.setModel(null);
            instance.applyModel();
            props.gridApi.onFilterChanged();
        });
    };

    return (
        <Button variant="outlined" size="small" color="primary" onClick={() => clearFilters()}>
            Clear FIlters
        </Button>
    )
}
import React, { useContext, useEffect } from 'react';
import clsx                 from 'clsx';
import { Logger }           from 'aws-amplify';

//import { useLocation, useHistory } from 'react-router-dom';

import { makeStyles }       from '@material-ui/core/styles';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery        from '@material-ui/core/useMediaQuery';

import Hidden               from '@material-ui/core/Hidden';
import Container            from '@material-ui/core/Container';
import AppBar               from '@material-ui/core/AppBar';
import Toolbar              from '@material-ui/core/Toolbar';



//import { PlatformCloudbar } from 'pac-platform-ui-components';
//import PlatformCloudbar from './PlatformCloudbar';

import DrawerNavMenu        from './DrawerNavMenu';


import IconButtonNavMenu    from './IconButtonNavMenu';
import AppContext           from './AppContext';

const logger = new Logger('View');

/*
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
*/


export default function View(props) {
    
    const app_context = useContext(AppContext);  
    
    logger.debug(app_context);
    
    // This resets the sidebar z index when the page is shrunk and stretched.  See DARTNEW-1678
    const theme = useTheme();
    if (useMediaQuery(theme.breakpoints.up('sm'))) {
        var loadInterval = setInterval(function () {
            if (document.getElementsByClassName("simplebar-wrapper").length) {
                var x = document.getElementsByClassName("simplebar-wrapper");
                x[0].parentNode.parentNode.style.zIndex = "1300";
                clearInterval(loadInterval);
            }
            
        }, 100);
    }
    
   /*
    let query = useQuery();
   
    var clear_url = false;
    const operator = query.get("operator");
    logger.debug("checking operator", operator, app_context);
    if (operator) {
        clear_url = true;
        if (operator != app_context.state.default_operator_icao_code) {
            app_context.updateOperator(operator);
        }
    }
    
    const aircraft = query.get("tail");
    
    if (aircraft && aircraft != app_context.state.default_registration_name) {
        app_context.updateAircraft(aircraft);
        clear_url = true;
    }
    let history = useHistory();
    const path = useLocation().pathname;
    logger.debug("clearing path", clear_url, path);
    if (clear_url) {
        
        history.push(path);
    }
    */
    app_context.setPageTitle(props.title);   
   
   
    var navigationMenu = props.navigationMenu;
    if (!navigationMenu) navigationMenu = app_context.state.navigationMenu;
    
    var header = props.header;
    if (!header) header = app_context.state.header;
    
   
    
    // The state for the navbutton and side menu    
    const [nav_open, setNavOpen] = React.useState(app_context.state.nav_open);
    
    const handleDrawerOpen = () => {
        logger.debug('handleDrawerOpen');
        setNavOpen(true);
        setTimeout(function(){ app_context.setNavOpen(true) }, 1000);
        
    };
    
    const handleDrawerClose = () => {
        logger.debug('handleDrawerClose');
        setNavOpen(false);
        setTimeout(function(){ app_context.setNavOpen(false) }, 1000);
    };
    
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        container: {
            paddingTop: 16,
           
        },
         appbar_root: {
            color: theme.palette.primary.contrastText, 
            backgroundColor: theme.palette.primary.dark,
            left: 0,
           
            flexGrow: 1,
            paddingRight: 0,
            
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.up('sm')]: {
              marginLeft: theme.custom.platformCloudbar.width,
              width: `calc(100% - ${theme.custom.platformCloudbar.width}px)`,
            }
          },
          appbar_rootShift: {
            color: theme.palette.primary.contrastText, 
            backgroundColor: theme.palette.primary.dark,
            left: 0,
            flexGrow: 1,
            paddingRight: 0,
           
            [theme.breakpoints.up('lg')]: {
                 width: `calc(100% - ${theme.custom.DrawerNavMenu.width + theme.custom.platformCloudbar.width}px)`,
                marginLeft: theme.custom.DrawerNavMenu.width + theme.custom.platformCloudbar.width,
                transition: theme.transitions.create(['margin', 'width'], {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            }
          },
          toolBar: {
            margin: "auto",
            width: "100%",
            whiteSpace: "nowrap",
          },
        
       
        
        content: {
             paddingTop:64,
             //paddingLeft: platformCloudbarWidth ,
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.custom.platformCloudbar.width,
            },
            //marginLeft: platformCloudbarWidth //-theme.custom.DrawerNavMenu.drawerWidth,
          },
          contentShift: {
              [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.custom.platformCloudbar.width,
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: theme.custom.DrawerNavMenu.width,
             }
          },
          fixed_content: {
              left: 0,
              marginTop: "64px", // todo: move to styles
              height: `calc(100% - 64px)`,
             
              position: "fixed", 
              top: 0,
              [theme.breakpoints.only('xs')]: {
                left: 0,
              },
              [theme.breakpoints.up('sm')]: {
                left: theme.custom.platformCloudbar.width,
              },
              right: 0,
              overflowScrolling: "touch",
              WebkitOverflowScrolling: "touch",
              overflowY: "hidden",
              overflowX: "hidden",
              paddingRight: "8px"
            },
            fixed_contentShift: {
              marginTop: "64px", 
              height: `calc(100% - 64px)`, 
           
              position: "fixed", 
              top: 0,
              [theme.breakpoints.down('md')]: {
                left: 0,
              },
              [theme.breakpoints.up('lg')]: {
                left: theme.custom.DrawerNavMenu.width + theme.custom.platformCloudbar.width,
              },
              right: 0,
              overflowScrolling: "touch",
              WebkitOverflowScrolling: "touch",
              overflowY: "hidden",
              overflowX: "hidden",
            }
                
    }));
  
    const classes = useStyles();
    const app_title = app_context.state.title;
    const page_title = app_context.state.page_title;
    window.document.title = page_title?page_title + ' - ' + app_title:app_title;
    var nav_button = '';
    if (!nav_open) {
        nav_button = (
            <IconButtonNavMenu edge="start" onClick={handleDrawerOpen}/>
        )
    }
  
    function getContent()
    {
      if (props.fixedContent) {
        return (
            <div   className={clsx(classes.fixed_content, {
                  [classes.fixed_contentShift]: nav_open,
                })}>
               {props.children}
            </div>
        )
      }
      else {
        return props.children;
      }
    }

    return (
       <div className={classes.root}>
        <Hidden only="xs">
        </Hidden>
        <DrawerNavMenu open={nav_open} handleDrawerClose={handleDrawerClose}>
           {navigationMenu}
        </DrawerNavMenu>
        
        <AppBar elevation={0} className={clsx(classes.appbar_root, {
          [classes.appbar_rootShift]: nav_open,
        })} position="fixed">

            <Toolbar  className={classes.toolBar} >
                {nav_button}
                {header}
             </Toolbar>
        </AppBar>
       
         <main
        className={clsx(classes.content, {
          [classes.contentShift]: nav_open,
        })}
      >
            <Container className={classes.container}>
                {getContent()}
            </Container>
        </main>
    </div>
    )
}
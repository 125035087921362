
export default function BooleanCellRenderer() {
}

BooleanCellRenderer.prototype.init = function (params) {
    //console.log('operatorIcaoCodeCellRenderer.prototype.init', params);
    this.eGui = document.createElement('span');
   // if (params.value === "null") params.value = null;
    //if (params.value !== "" &&  params.value !== undefined && params.value !== null) {
        if (params.value) this.eGui.innerHTML = '1 &#9989;';
        else this.eGui.innerHTML = '0 &#10060;';
    //}
};

BooleanCellRenderer.prototype.getGui = function () {
    return this.eGui;
};


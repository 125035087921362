import OauthService from './OauthService';
import { Logger } from 'aws-amplify';
import BooleanCellRenderer from '../components/AGGrid/BooleanCellRenderer';

const logger = new Logger('AlertConfigService');

const checkboxes = [
    "alarm_enabled",
    "teams_enabled",
    "pac_service_status_enabled",
    "alarm_corrective_actions_enabled",
    "pagerduty_escalation_enabled",
    "email_enabled",
    "text_enabled"
];


export default class AlertConfigService extends OauthService {

    constructor() {
        let apiName = 'OriginsAPI';
        let collectionPath = '/services/alertconfig';
        super(apiName, collectionPath);
    }


    formatRowData(data) {
        logger.debug("formatRowData", data);

        data.forEach((element) => {
            if (!element['last_updated_date']) element['last_updated_date'] = element['created_date'];
            if (element['created_date']) element['created_date'] = element['created_date'].substring(0, 19).replace('T', ' ');
            if (element['last_updated_date']) element['last_updated_date'] = element['last_updated_date'].substring(0, 19).replace('T', ' ');

            checkboxes.forEach((checkbox)=> {
                if (element[checkbox] === undefined) element[checkbox] = false;
            });
        });
        return data;
    }

    getMetaData(data) {
        return {
            pagination: false,
            total_records: data.length
        };
    }

    getCloneRecord(record, ) {
        record['alarm_code'] = record['alarm_code'] + '_CLONE';
        record['alarm_name'] = record['alarm_name'] + ' (clone)';
        record['original_title'] = "";
        return record;
    }


    async save(data, metaData) {
        logger.debug("save1", data);

        data['alarm_code'] = data['alarm_code'].toUpperCase();

        //check the PK and set bool to true/false
        if (!data['GSI PK']) data['GSI PK'] = 'ALERTCONFIG';
        data['PK_NEW'] = data['GSI PK'] + "|" + data['alarm_code'];


        checkboxes.forEach((checkbox)=> {
            if (data[checkbox])
                data[checkbox] = true;
            else
                data[checkbox] = false;
        });
        data['last_updated_by'] = metaData.app_state.user.session.login_name;

        logger.debug("save2", data, metaData);
        var response = null;
        if (data['PK'] && data['PK'] == data['PK_NEW']) { //update
            logger.debug("save update", data);
            data['revision'] = 0; // data['SK']; // which is always "v0"
            try {
                var results = await this.update(data['alarm_code'], data);

            //.then((results) => {
                logger.debug("save: update record", results);
                 response = {
                    success: true,
                    updated: true // todo: need to check result to see if updated
                 };
            //})
            }
            catch(err) {
            //.catch((err) => {
                logger.debug('save: update record failed', err, data);
                 response = {
                    success: false,
                    updated: false,
                    response: {
                        data: {
                            error: "An error occurred"
                        }
                    }
                };

            //});
            }
        }
        else { //insert
            logger.debug("save insert", data);
            try {
                var records = await this.fetch(data['alarm_code']);
                logger.debug("save service fetch for duplicate", records);
                if (records) {
                    response = {
                        success: false,
                        updated: false,
                        response: {
                            data: {
                                error: "Duplicate Alarm Code",
                                columns: [
                                    {
                                        key: 'alarm_code',
                                        message: "Alarm code is already in use"
                                    }
                                ]

                            }
                        }
                    };
                }
                else {
                    logger.debug("save insert 2", data);
                    await this.add(data)
                    .then((results) => {
                        logger.debug("save: insert record", results);
                        if (results.success == false) {
                            response =  {
                                success: false,
                                updated: false,
                                response: {
                                    data: {
                                        error: results.error.message
                                    }
                                }
                            };
                        }
                        else {
                            response = {
                                success: true,
                                updated: true
                            };
                        }
                    })
                    .catch((err) => {
                        logger.debug("save: insert record error1", err);
                        response = {
                            success: false,
                            updated: false,
                            response: {
                                data: {
                                    error: err.response.data.error.message
                                }
                            }
                        };
                    });

                }

            }
            catch (err) {
                logger.debug("save service fetch records failed", err, metaData.recordID, records);
                var error;
                if (err.response) error =err.response.data.error.message;
                else error = err.message;
                response = {
                    success: false,
                    updated: false,
                    response: {
                        data: {
                            error: error
                        }
                    }
                };
            }
        }
        return response;
    }


    getColumnDefs() {
        var column_width = 100;

        return [
        {
            headerName: 'History',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                {
                    field: "created_date",
                    headerName: "Creation Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                },
                {
                    field: "created_by",
                    headerName: "Created By",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                },
                {
                    field: "last_updated_date",
                    headerName: "Last Updated Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    newline: true,
                    sort: "desc"
                },
                {
                    field: "last_updated_by",
                    headerName: "Last Updated By",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "text",
                    formType: "readonly",
                    columnGroupShow: "open"
                },
            ]
        },
        {
            headerName: 'Alert Details',
            defaultExpanded: true,
            children: [
                {
                    field: "alarm_enabled",
                    headerName: "Enabled",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: 1,


                },
                {
                    field: "alarm_name",
                    headerName: "Alarm Name",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "text",
                    required: true
                },
                {
                    field: "alarm_code",
                    headerName: "Alarm Code",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "text",
                    newline: true,
                    required: true,
                    inputProps: { style: { textTransform: "uppercase" } }
                },
                {
                    field: "alarm_teams_description",
                    headerName: "Description/Impact",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "multiline",
                    required: true
                },
                {
                    field: "alarm_teams_description_ok_state",
                    headerName: "OK State Description",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "multiline",
                    required: true
                },
                /*
                {
                    field: "GSI PK",
                    headerName: "GSI Primary Key",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    resizable: true,
                    formType: "readonly",
                    newline:true,
                    defaultValue: "ALERTCONFIG"
                },
                */
                /*
                {
                    field: "PK",
                    headerName: "Primary Key",
                    filter: 'agTextColumnFilter',
                    width: column_width * 3,
                    resizable: true,
                    formType: "text"
                },
                */
                /*
                {
                    field: "PKSUBSTRING",
                    headerName: "Primary Key",
                    filter: 'agTextColumnFilter',
                    width: column_width * 3,
                    resizable: true,
                    formType: "text",
                    required: true
                },
                */
                /*
                {
                    field: "SK",
                    headerName: "Secondary Key",
                    filter: 'agTextColumnFilter',
                    width: column_width ,
                    resizable: true,
                    formType: "text"
                },
                */
                /*
                {
                    field: "GSI SK",
                    headerName: "GSI Secondary Key",
                    filter: 'agTextColumnFilter',
                    width: column_width ,
                    resizable: true,
                    formType: "text"
                },
                */
                {
                    field: "alarm_corrective_actions_enabled",
                    headerName: "Corrective Action Enabled",
                    headerTooltip: "Corrective Action Enabled",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: 1,
                    newline: true
                },
                {
                    field: "alarm_cooloff_ttl_seconds",
                    headerName: "Cool Off TTL (s)",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    resizable: true,
                    formType: "text",
                    newline: true,
                    defaultValue: 3600
                },
                {
                    field: "alarm_cloudwatch_dashboard",
                    headerName: "Dashboard",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "text",
                    newline: true
                },
                {
                    field: "alarm_confluence_page",
                    headerName: "Confluence Page",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "text",
                    newline: true
                }
            ]
        },
        {
            headerName: 'Teams Integration',
            defaultExpanded: false,
            children: [
                  {
                    field: "teams_enabled",
                    headerName: "Enabled",
                    headerTooltip: "PAC Service Status Enabled",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: false
                },
                {
                    field: "alarm_teams_endpoint",
                    headerName: "Teams Endpoint",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "text",
                    helperText: "Defaults to main teams channel."
                }

            ]
        },
        {
            headerName: 'PAC Service Status',
            children: [
                {
                    field: "pac_service_status_enabled",
                    headerName: "Enabled",
                    headerTooltip: "PAC Service Status Enabled",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: false
                },
                {
                    field: "pac_service_status_name",
                    headerName: "Service Status Name",
                    filter: 'agTextColumnFilter',
                    width: column_width * 1.4,
                    resizable: true,
                    formType: "text",
                    newline: true,
                    defaultValue: "FDS"
                },
                {
                    field: "pac_service_status_group",
                    headerName: "Status Group",
                    filter: 'agTextColumnFilter',
                    width: column_width * 1.3,
                    resizable: true,
                    formType: "text",
                    defaultValue: "Flight Data Services"
                },
                {
                    field: "pac_service_status_severity",
                    headerName: "Service Severity",
                    filter: 'agTextColumnFilter',
                    width: column_width * 1.3,
                    resizable: true,
                    formType: "text",
                    defaultValue: "Major Outage"
                },
                {
                    field: "pac_service_status_description",
                    headerName: "Status Description",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "multiline",
                    newline: true
                }

            ]
        },
        {
            headerName: 'Pager Duty',
            children: [
                {
                    field: "pagerduty_escalation_enabled",
                    headerName: "Enabled",
                    headerTooltip: "Pager Duty Escalation Enabled",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: false
                },
                {
                    field: "pagerduty_integration_key",
                    headerName: "Integration Key",
                    headerTooltip: "Pager Duty Integration Key",
                    filter: 'agTextColumnFilter',
                    width: column_width * 3,
                    resizable: true,
                    formType: "text",
                    newline: true
                },
                {
                    field: "pagerduty_severity",
                    headerName: "Severity",
                    headerTooltip: "Pager Duty Severity",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    resizable: true,
                    formType: "text"
                }
            ]
        },
        {
            headerName: 'Email Notifications',
            defaultExpanded: false,
            children: [
                  {
                    field: "email_enabled",
                    headerName: "Enabled",
                    headerTooltip: "Email Notifications Enabled",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: false
                },
                {
                    field: "email_recipients",
                    headerName: "Email Recipients",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "multiline",
                    required: false,
                    helperText: "Comma separated list of email addresses.  E.g.: joe@address.com, mary@address.com"
                }

            ]
        },
        {
            headerName: 'Text Messages Integration',
            defaultExpanded: false,
            children: [
                  {
                    field: "text_enabled",
                    headerName: "Enabled",
                    headerTooltip: "Text Messages Enabled",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: false
                },
                {
                    field: "text_recipients",
                    headerName: "Text Recipients",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "multiline",
                    required: false,
                    helperText: "Comma separated list of phone numbers.  E.g.: 555-555-5555, 555-555-1234, (555) 555-0000"
                }

            ]
        },
        ];
    }
}

import BaseService from './BaseService';

export default class UserService extends BaseService {

    constructor() {
        let apiName = 'OriginsAPI';
        let collectionPath = '/user';
        super(apiName, collectionPath);
    }
}

import React from 'react';
import { Logger }               from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormContext      from 'pac-responsive-ui-framework/forms/FormContext';
import SelectOperator from './SelectOperator';
import SelectAircraft from './SelectAircraft';

const logger = new Logger('FormNotification');

var xmlHttp;
function srvTime(){
    try {
        //FF, Opera, Safari, Chrome
        xmlHttp = new XMLHttpRequest();
    }
    catch (err1) {
        //AJAX not supported, use CPU time.
        alert("AJAX not supported", err1);
    }
    xmlHttp.open('HEAD',window.location.href.toString(),false);
    xmlHttp.setRequestHeader("Content-Type", "text/html");
    xmlHttp.send('');
    return xmlHttp.getResponseHeader("Date");
    
}

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
var day_count = 6;
var days = [];
var date = new Date(srvTime());

for(var i=0; i < day_count; i++) {
    var date_string = date.toISOString().substring(0, 10);
    days.push( <MenuItem value={date_string}>{date_string}</MenuItem>);
    date = date.addDays(1);
}
logger.debug("servertime", date, days);


const NotificationType = props => {
    
    
    return (
        <DialogContent>
            <FormControlLabel  label={props.label} control={
            <Select
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                displayEmpty
                // className={classes.selectEmpty}
                //  inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={0}>
                <em>None</em>
              </MenuItem>
              {days}
            </Select>} />
        </DialogContent>
    );
}



export default function FormNotification(props) {
    logger.debug('props', props);
    
    
    const [open, setOpen] = React.useState(props.open);
    
    var saved_disabled = true;


    const init_operator = props.operator?props.operator.toUpperCase():null;
    const init_aircraft = props.aircraft?props.aircraft.toUpperCase():null;
    
    /** 
     * This is a list of all the default types, and their default values.
     * You will want to update this list as new types are defined.
     */
    const init_types = [
        {name: "Build Tracker",      value: 0},
        {name: "Flight Performance", value: 0},
        {name: "Test Type 1",        value: 0}
    ];
   
    if (init_operator && init_aircraft) {
        saved_disabled = false;
        
        if (props.data) {
            for(let j=0; j < init_types.length; j++) {
                for(let i=0; i < props.data.length; i++) {
                    if (props.data[i]['operator'] === init_operator 
                            && props.data[i]['aircraft'] === init_aircraft
                            && props.data[i]['type'] === init_types[j]['name']) {
                        logger.debug("found");    
                        init_types[j]['value'] = props.data[i]['date'];

                    }
                }
            }
        }
    }

    logger.debug(init_types);

    const [operator, setOperator] = React.useState(init_operator);
    const [aircraft, setAircraft] = React.useState(init_aircraft);
    const [types, setTypes] = React.useState(init_types);

    

    if (operator && aircraft) {
        saved_disabled = false;
    }

   
    const handleOpen = () => {
        setOpen(true);
        
    };
    
     const handleClose = () => {
        setOpen(false);
        props.onClose()
    };

    const handleSave = () => {
        setOpen(false);
        // save the data here.
        props.onClose({operator, aircraft, types})
    };
    
    const handleChange = (event) => {
        logger.debug("handleChange", event, event.target.name, event.target.value);
        let new_types = [];
        for(let i = 0 ; i < types.length; i++) {
            if (types[i]['name'] == event.target.name) {
                new_types.push({name: types[i]['name'], value: event.target.value, label: types[i]['value']})
            }
            else {
                new_types.push(types[i]);
            }
        }
        setTypes(new_types);
    }
    
    //set the notification componets based on state
    var types_obj = [];
    logger.debug("types_obj", types);
    for(let i = 0 ; i < types.length; i++) {
        types_obj.push(<NotificationType key={types[i]['name']} value={types[i]['value']} name={types[i]['name']} label={types[i]['name']} onChange={handleChange}/>);
    }
    
  
    const updateState = (key, event, value, reason) => {
        if (key === 'operator_icao_code') {
            if (value === null) {
                setOperator(null);
            }
            else {
                setOperator(value.operator_icao_code);
            }
            setAircraft(null);
        }
        else if (key === 'registration_name') {
            if (value === null) {
                setAircraft(null);
            }
            else {
                setAircraft(value.registration_name);
            }
        }
    }
    
   
    var icon_button = (
         <IconButton aria-label="add entry" onClick={handleOpen}>
            <AddIcon />
        </IconButton>
    )
    if (props.edit) {
        icon_button = (
            <IconButton aria-label="edit entry" size="small" onClick={handleOpen}>
                <EditIcon />
            </IconButton>
         )
          
          
    }
    
    return (
        <div>
            <Tooltip title="Add Entry">
                {icon_button}
            </Tooltip>
        <FormContext.Provider value = {{
          updateState: updateState
        }}>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Notification</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add a notification by selecting which operator and aircraft you wish to receive notifications for.
                    </DialogContentText>
                
                    <SelectOperator value={operator} />
                    <SelectAircraft operator={operator} value={aircraft} />
                    <DialogContentText>
                        
                    </DialogContentText>
                    {types_obj}    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={saved_disabled} onClick={handleSave} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </FormContext.Provider>
    </div>
  );
}

import React, { useState, useRef, useCallback, useContext } from 'react';
//import ReactDOMServer from 'react-dom/server';
import { Logger }           from 'aws-amplify';
import { AgGridReact } from 'ag-grid-react';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
//import AgGridContext      from '../contexts/AgGrid';

//import '../3rd_party/ag-grid/dist/ag-grid-community.min.js';
import '../3rd_party/ag-grid/dist/styles/ag-grid.css';
import '../3rd_party/ag-grid/dist/styles/ag-theme-balham.min.css';
import '../3rd_party/ag-grid/dist/styles/agGridBalhamFont.min.css';
import "../3rd_party/ag-grid/dist/styles/webfont/agGridBalhamFont.scss";


//import 'ag-grid-community/dist/styles/ag-grid.css';
//import 'ag-grid-community/dist/styles/ag-theme-balham.css';

//import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import Grid from '@material-ui/core/Grid';

import FlightFormFilter     from '../components/FormFilters/Flight';

import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/GetApp';
//import UndoIcon from '@material-ui/icons/Undo';
//import RedoIcon from '@material-ui/icons/Redo';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
//import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
//import FilterIcon from '@material-ui/icons/FilterList';
import InfoIcon from '@material-ui/icons/Info';


import Hidden from '@material-ui/core/Hidden';

//import Select from '@material-ui/core/Select';

import FormDialog from './FormDialog';
//import TextFilter from './TextFilter';
import Pagination from './Pagination';


//import PopupCellRenderer from '../components/AGGrid/PopupCellRenderer';
//import EditIcon from '@material-ui/icons/Edit'

//import { getIcon } from '../data/Operators';

import url from "../util/URL";
import AppContext               from 'pac-responsive-ui-framework/core/AppContext';

const logger = new Logger('AGGridOOOIFlights');

function percentColumnFilter() {

}

percentColumnFilter.prototype.doesFilterPass = function(params) {
    console.log('percentColumnFilter.prototype.doesFilterPass',params);
    // make sure each word passes separately, ie search for firstname, lastname
    var passed = true;
    var valueGetter = this.valueGetter;
    this.filterText.toLowerCase().split(" ").forEach(function(filterWord) {
        var value = valueGetter(params);
        if (value.toString().toLowerCase().indexOf(filterWord) < 0) {
            passed = false;
        }
    });

    return passed;
};

export default function DataGrid(props) {
    logger.debug("DataGrid props",props.refresh , props, props.query.operator_icao_code);

    var app_context = useContext(AppContext);

    let default_state = props.query;
    let search = window.location.search;
    let params = new URLSearchParams(search);

    if (params.get("query")) {
        try { // protect against crap in the URL
            default_state = JSON.parse(params.get("query"));
        }
        catch(e) {

        }
    }
    if (!default_state.start_date && !default_state.end_date) {
        var d = new Date();
        var new_date = d.toISOString().substr(0, 19).replace("T", " ");
        logger.debug("DataGrid default start date", new_date);

        default_state.end_date = new_date;

        var day = d.setTime(d.getTime() - 86400000);
        new_date = d.toISOString().substr(0, 19).replace("T", " ");
        logger.debug("DataGrid default start date", new_date);
        default_state.start_date = new_date;
    }
    logger.debug("DataGrid props",props, props.query.operator_icao_code, default_state);
    const aircraft_limit = 30; //days
    const operator_limit = 2;// days

    const gridRef = useRef();
    const [loaded, setLoaded] = useState(props.refresh === undefined?false:!props.refresh);


    //const [rowData, setRowData] = useState([]);
    const [metaData, setMetaData] = useState(null);

    const [state, setState] = useState(default_state);
    const [last_query, setLastQuery] = useState();


    const [open, setOpen] = useState(false);
    const [grid, setGrid] = useState(null);
    const [isSelected, setIsSelected] = useState(false); // boolean to see if it is selected.  May change to actual index
    const [selectedData, setSelectedData] = useState(null); // null or data set

    const [newRecord, setNewRecord] = useState(true); // boolean to see if it is selected.  May change to actual index

    const [refreshFlag, setRefreshFlag] = useState(null);

    const [footerMessage, setFooterMessage] = useState(null); // null or data set

    const [visibleRowCount, setVisibleRowCount] = useState(null);

    var columnDefs = props.columnDefs;


    //logger.debug("AGGrid service",grid, loaded, props.query.operator_icao_code, props);
    const service = props.service;
    if (grid) {
        logger.debug("AGGrid service loading1", loaded, open, props.refresh, state, last_query);
        if (!loaded && state.operator) {
            logger.debug("AGGrid service loading2", loaded, open, props.refresh, state, last_query);
            grid.showLoadingOverlay();
            setLoaded(true);
            var query = {};
            query['start_date'] = state.start_date;
            query['end_date'] = state.end_date;
            query['operator_icao_code'] = state.operator.operator_icao_code;
            query['aircraft'] = state.aircraft ? state.aircraft.registration_name : null;
            var page_number =  state['page[number]'];
            var page_size =  state['page[size]']?state['page[size]']:25;
            logger.debug("AGGrid service Query1", query);
            var start = new Date(query['start_date']);
            var end = new Date(query['end_date']);
            if (query['aircraft']) {

                end.setDate(end.getDate() - aircraft_limit);
                if (start < end) {
                    query['start_date'] = end.toISOString().substr(0, 10) + ' ' + query['end_date'].substr(11, 8);
                    setFooterMessage(["* indicates an estimated time.", "Data is limited to " + aircraft_limit + ' days prior to the end date.']);

                }
                else setFooterMessage(['* indicates an estimated time']);
            }
            else {
                end.setDate(end.getDate() - operator_limit);
                if (start < end) {
                    query['start_date'] = end.toISOString().substr(0, 10) + ' ' + query['end_date'].substr(11, 8);
                    setFooterMessage(['* indicates an estimated time', "Data is limited to " + operator_limit + ' days prior to the end date.']);
                }
                else setFooterMessage(['* indicates an estimated time']);
            }
            logger.debug("AGGrid service Query2", query);
            if (last_query) {
                if (query['start_date'] != last_query['start_date']
                    || query['end_date'] != last_query['end_date']
                    || query['operator_icao_code'] != last_query['operator_icao_code']
                    || query['aircraft'] != last_query['aircraft']) {
                    page_number = 1;
                }
            }
            query['page[number]'] = page_number;
            query['page[size]'] = page_size;


            setLastQuery(query);
            service.fetchRecords(query)
            .then((data) => {
                setMetaData(service.getMetaData(data));
                logger.debug("AGGrid service success 1", query, data);
                data = service.formatRowData(data);
                logger.debug("test1 AGGrid service success 2", query, data);
                grid.setRowData(data);
            })
            .catch((err) => {

                logger.debug('AGGrid service failed', err);
                grid.setRowData(null);
                grid.showNoRowsOverlay();
            });
        }
        else if (!state.operator) {
            //logger.debug("AGGrid service not loading",grid, loaded, state.operator, props);
            grid.setRowData([]);

        }
    }

    var gridApi;

    function handleClose() {
        setOpen(false);
    }

    const onGridReady = params => {

      logger.debug('onGridReady', params.api);
      gridApi = params.api;
      gridApi.sizeColumnsToFit();
      setGrid(gridApi);
    };

    const cellClickedListener = useCallback( event => {
        console.log('cellClicked', event);
        setIsSelected(true);
        var focusedCell = gridRef.current.api.getFocusedCell();
        var data= null
        if (focusedCell) {
            var row = gridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
            if (row) {
              data= row.data;

            }
          }
        setSelectedData(data);
    }, []);

     const cellDoubleClickedListener = useCallback( event => {
        console.log('cellDoubleClicked', event);
        setNewRecord(false);
        setOpen(true);

    }, []);



    function onBtnExport() {
        // see https://ag-grid.com/react-data-grid/csv-export/
        logger.debug("onBtnExport", state, last_query);
        var filename = 'origins_oooi_diagnostic_tool';
        if (last_query && last_query.operator_icao_code) {
            filename += ' (' + last_query.operator_icao_code;
            if (last_query.aircraft) {
                filename += ', ' + last_query.aircraft;
            }
            filename += ", " + last_query.start_date + " to " + last_query.end_date + ", records ";
            filename += ((metaData.page_number -1) * metaData.page_size) + 1;
            filename += "-";
            if (metaData.has_next_page) {
                filename += metaData.page_number * metaData.page_size;
            }
            else filename += metaData.total_records;
            filename += ')';
        }
        filename += ".csv";
        grid.exportDataAsCsv({
            fileName: filename,
            skipHeader: false,
            allColumns: true,
            skipColumnGroupHeaders: false,
            processHeaderCallback: (params) => {
                //logger.debug("processHeaderCallback", params);
                return params.column.originalParent.colGroupDef.headerName + ": \n" + params.column.colDef.headerName;
            }
        });
    }

    function handleRefresh() {
        logger.debug("handleRefresh", loaded);
        setLoaded(false);
        setRefreshFlag(Date.now());
    }
    /*
    function onBtnUndo() {
        gridRef.current.api.undoCellEditing();
    }

    function onBtnRedo() {
        gridRef.current.api.redoCellEditing();
    }
    */

    function onBtnEdit() {
        setNewRecord(false);
        setOpen(true);
         /*
        var focusedCell = gridRef.current.api.getFocusedCell();
        console.log("AGGrid onBtnEdit", focusedCell);
        if (focusedCell) {
            gridRef.current.api.setFocusedCell(focusedCell.rowIndex, focusedCell.column.coiId);
            gridRef.current.api.startEditingCell({rowIndex: focusedCell.rowIndex, colKey: focusedCell.column.coiId} );
        }
        */
    }

    function onBtnAdd() {
        //gridRef.current.api.setFocusedCell(
        setNewRecord(true);
        setOpen(true);
    }

    function handleChangePage(event, newPage) {
        var s = state;
        s["page[number]"] = newPage + 1;
        setState(s);
        setVisibleRowCount(null);
        handleRefresh();
    }

    function handleChangeRowsPerPage(event) {
        var size = parseInt(event.target.value, 10);
        var s = state;
        s["page[size]"] = size;
        setState(s);
        handleChangePage(event, 0);
    }

    const handleFilterChange = (new_state) => {
        logger.debug("handleFilterChange1", state, new_state);
        var s = state;
        var refresh = false;
        if (new_state.operator != state.operator) {
            s['operator'] = new_state.operator;

            refresh = true;
        }
        if (new_state.aircraft != state.aircraft) {
            s['aircraft'] = new_state.aircraft;
            refresh = true;
        }
        if (new_state.start_date != state.start_date) {
            s['start_date'] = new_state.start_date;
            refresh = true;
        }
        if (new_state.end_date != state.end_date) {
            s['end_date'] = new_state.end_date;
            refresh = true;
        }

         var url_obj = {
            start_date:  new_state.start_date,
            end_date:  new_state.end_date,
            include_oooi: true,
            operator: {
                operator_icao_code: new_state.operator.operator_icao_code,
                operator_name: new_state.operator.operator_name
            },
            //aircraft: new_state.aircraft

         };

         if (new_state.aircraft) {
              url_obj.aircraft = {
                registration_name: new_state.aircraft.registration_name,
                ship_number: new_state.aircraft.ship_number,
                system_type: new_state.aircraft.system_type,
                //type_subtype: new_state.aircraft.type_subtype
                type: new_state.aircraft.type,
                subtype: new_state.aircraft.subtype
            };
         }


        //q['operator_icao_code'] = state.operator.operator_icao_code;
        logger.debug("handleFilterChange2", s, refresh);

        setState(s);

        /**
         * Query object looks like this
         * {
         *    "start_date": null,
         *    "end_date": null,
         *    "page[size]": "25",
         *    "page[number]": 1,
         *    "include_oooi": "True"
         * }
         */
        url.append("query", JSON.stringify(url_obj), app_context.state.page_title);
        /*
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let param_array = [];
        for (const p of params) {
            if (p[0] !== 'query') {
                var p2 = p[0];
                if (p[1] !== "") {
                    p2 += "=" + p[1];
                }
                param_array.push(p2);
            }
        }
        url.pushState("", null, "?query=" + JSON.stringify(query) + "&" + param_array.join('&'), , app_context.state.page_title);
        */

        if (refresh) handleRefresh();
    };

    var footer_content = [];
    var footer_object;
    if (footerMessage) {
        footerMessage.forEach((msg) => {
            footer_content.push(<div key={msg} style={{mergin:0}}>{msg}</div>);
        });

        footer_object =
            <React.Fragment>
                <Hidden smDown={true}>
                    {footer_content}
                </Hidden>
                <Hidden mdUp={true}>
                    <Tooltip title={footer_content}>
                        <span>
                            <IconButton aria-label="info" disableRipple={true} disableFocusRipple={true}>
                                <InfoIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Hidden>
            </React.Fragment>;
    }

    return (
        <React.Fragment>
            <div style={{ paddingRight: 16 }}>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <FlightFormFilter state={state} onChange={handleFilterChange} />
                            </td>
                            <td style={{textAlign: "right", whiteSpace: "nowrap"}}>
                                <Tooltip title="Export to CSV">
                                    <span>
                                        <IconButton onClick={onBtnExport} disabled={!(metaData && metaData.total_records > 0)}>
                                            <DownloadIcon/>
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Refresh the Data">
                                    <span>
                                        <IconButton onClick={handleRefresh}>
                                            <RefreshIcon/>
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="ag-theme-balham" style={ {
                    paddingLeft: "16px",
                    paddingBottom: "4px",
                    height: 'calc(100% - 100px)',
                    width: 'calc(100% - 8px)',
                    overflowY: "hidden",
                    overflowX: "hidden"
            } }>
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    defaultColDef={ {
                        suppressMenu: true,
                        suppressSizeToFit: true,
                        floatingFilter: true,
                        editable: false,
                        sortable: true,
                    }}
                    components= { {
                      //  'operatorIcaoCodeCellRenderer': operatorIcaoCodeCellRenderer,
                        //'percentColumnFilter': percentColumnFilter,
                        // handleOpen: handleOpen
                    }}
                    columnDefs={columnDefs}
                    //rowData={rowData}
                    undoRedoCellEditing="true"
                    undoRedoCellEditingLimit="20"
                    rowSelection="single"
                    onCellClicked={cellClickedListener}
                    onCellDoubleClicked={cellDoubleClickedListener}
                    enableBrowserTooltips="true"
                    overlayNoRowsTemplate={"Please select an operator and verify your date range using the upper left Data Filters."}
                    enableCellTextSelection="true"
                    onFilterChanged= {(event) => {
                        let search = window.location.search;
                        let params = new URLSearchParams(search);
                        let param_array = [];
                        for (const p of params) {
                            if (p[0] !== 'grid_filters') {
                                var p2 = p[0];
                                if (p[1] !== "") {
                                    p2 += "=" + p[1];
                                }
                                param_array.push(p2);
                            }
                        }
                        logger.debug("onFilterChanged1", params, param_array, params.get("grid_filters"), event);
                        setVisibleRowCount(gridRef.current.api.getDisplayedRowCount());
                        url.pushState("", null, "?grid_filters=" + JSON.stringify(event.api.getFilterModel()) + "&" + param_array.join('&'), app_context.state.page_title);
                    }}
                    onFirstDataRendered = {(event) => {
                        let search = window.location.search;
                        let params = new URLSearchParams(search);
                        logger.debug("onFirstDataRendered", event, params.get("grid_filters"));
                        event.api.setFilterModel(JSON.parse(params.get("grid_filters")));
                    }}
                    >
                </AgGridReact>
            </div>
            <div style={ {
                    paddingLeft: "0px",
                    paddingBottom: "4px",
                    width: '100%',
                    overflowY: "hidden",
                    overflowX: "hidden",
                    textOverflow: "ellipsis"
            } }>
            <table width="100%" height="50px"><tr><td style={{alignItems: "center", paddingLeft: "20px"}}>
                {footer_object}
            </td><td style={{textAlign: "right"}}>
                <Pagination settings={metaData} visibleRows={visibleRowCount} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}/>
            </td></tr></table>
            </div>
            <FormDialog readOnly={true} handleRefresh={handleRefresh} handleClose={handleClose} open={open} data={selectedData} newRecord={newRecord} columnDefs={columnDefs} service={service}/>
        </React.Fragment>
    );
}


import React, { useRef, useState } from 'react';
import { makeStyles }               from '@material-ui/core/styles';
//import Typography                   from '@material-ui/core/Typography';
//import IconButton                   from '@material-ui/core/IconButton';
//import Tooltip                      from '@material-ui/core/Tooltip';
//import SaveIcon                     from '@material-ui/icons/SaveAlt';

import { Logger }                   from 'aws-amplify';
import Highcharts       from 'highcharts';
import HC_exporting     from 'highcharts/modules/exporting'


import HighchartsReact  from 'highcharts-react-official';
require("highcharts/modules/export-data")(Highcharts);
HC_exporting(Highcharts);


const logger = new Logger('HighChart');

const useStyles = makeStyles(theme => ({
    root: {
       
        height: "100%",
        paddingTop: "16px"
    },
}));

export default function HighChart(props) {
    logger.debug(props)
    const classes = useStyles();
     
    var charts = useRef(null);
    
    var data = []
     
    const options = {
       // time: {
        //    timezoneOffset: 0
        //},
        chart: {
            zoomType: 'x',
            resetZoomButton: {
                position: {
                    // align: 'right', // by default
                    // verticalAlign: 'top', // by default
                    x: 100,
                    y: -100
                }
            },
            margin: [30, 30, 32, 50],
            style: {
                overflow: 'visible',
                zIndex: props.zIndex
             },
             backgroundColor: "#fafafa"
        },
        title: {
            text: props.title,
            align: "left",
            style: { fontSize: "10px", fontWeight: "Bold" }
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                '' : ''
        },
        xAxis: {
            type: 'datetime',
            crosshair: true,
            events: {
                afterSetExtremes: function(event) {
                    if (props.afterSetExtremes) props.afterSetExtremes(event);
                }
            },
            dateTimeLabelFormats: {
                day: '%Y-%m-%d'
            }
        },
        yAxis: {
            title: {
                text: props.metricName,
                x: -100
            },
        },
        legend: {
            enabled: false
        },
        tooltip: {
           // enabled: false
           style: {
               fontSize: "10px",
               
           },
           padding: 4,
           formatter: function() {
      	        var tooltip = '<b>' + props.title + '</b><br/>';
      	        //tooltip += Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', new Date(this.x));
      	        tooltip += new Date(this.x).toUTCString();
      	        tooltip += '<br/>' + props.metricName + ': ' + this.y;
      	        if (props.metricUnit) tooltip += ' ' + props.metricUnit;
      	        return tooltip;
           }
        },
        series: [{
            type: 'line',
            data: data,
            //pointStart: start_timestamp
        }],
        exporting: {
            enabled: true,
            csv: {
                itemDelimiter: ',',
                lineDelimiter: '\n',
                decimalPoint: '.',
                /*
                columnHeaderFormatter: function (item, key, keyLength){
                    logger.debug("columnHeaderFormatter", item, key, keyLength);
                    if (key == 'y') return props.metricName;
                    return "Timestamp";
                }
                */
            },
            
            menuItemDefinitions: {
                /*
                "viewFullscreen": { text: "View Full Screen" }, 
                "printChart": { text: "Print Chart foo" }, 
                "separator": {}, 
                "downloadPNG": { text: "Print Chart foo"}, 
                "downloadJPEG": {}, 
                "downloadPDF": {}, 
                "downloadSVG": {}
                  
              
            
              viewFullscreen: {
                text: "fullscreen"
              }*/
            },
            buttons: {
              contextButton: {
                menuItems: [
                  "viewFullscreen",
                  'separator',
                  'printChart',
                  'viewData',
                  'separator',
                  'downloadJPEG', 
                  'downloadPNG',
                  'downloadPDF',
                  'downloadSVG', 
                  'separator',
                  {
                    text: "Download Excel File",
                    onclick() {
                      this.downloadXLS();
                    }
                  },
                  {
                    text: "Download CSV File",
                    onclick() {
                        this.downloadCSV();
                        /*
                        logger.debug(this);
                      let csv = this.getCSV();
        
                      console.log(csv);
                      */
                    }
                  }
                ]
              }
            }
      }
    };
    
    var new_options = {...options, ...props.options};
    
   
    
    const chartMove = (e) => {
        props.updateChartsCallback(e)
        //logger.debug('chartMove', charts); 
        var chart,
            point,
            i,
            event;

        for (i = 0; i < Highcharts.charts.length; i = i + 1) {
            chart = Highcharts.charts[i];
            // Find coordinates within the chart
            event = chart.pointer.normalize(e);
            // Get the hovered point
            point = chart.series[0].searchPoint(event, true);
            if (point) {
                //chart.xAxis[0].drawCrosshair(e, point);
            }
        }
    };
    
    return (
        <div className={classes.root} onMouseMove={chartMove.bind(this)} >
            <HighchartsReact 
                highcharts={Highcharts} 
                options={new_options}
                containerProps={{ style: { height: "100%" } }}
                ref={charts}
            />
        </div>
        
    );
}
import React, { Component }         from 'react';
import { render }                   from 'react-dom';
import { AgGridReact }              from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MasterDetailModule }       from '@ag-grid-enterprise/master-detail';
import { MenuModule }               from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule }   from '@ag-grid-enterprise/column-tool-panel';
import { RowGroupingModule }        from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule }          from '@ag-grid-enterprise/set-filter';
import { FiltersToolPanelModule }   from '@ag-grid-enterprise/filter-tool-panel';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { Logger }                   from 'aws-amplify';

import operatorIcaoCodeCellRenderer from './DataGrid/RendererOperatorIcaoCode';
import lineChartRenderer            from './DataGrid/RendererLineChart';

import ColumnPercent        from './DataGrid/ColumnPercent';


import ToolPanelFleet from './DataGrid/ToolPanelFleet';

import flightData from '../data/FlightData';
import rowData from '../data/FlightData';


import ButtonClearFilters from './DataGrid/ButtonClearFilters';
import ButtonSaveFilters from './DataGrid/ButtonSaveFilters';
import ButtonFilterSet from './DataGrid/ButtonFilterSet';
import ButtonFilter from './DataGrid/ButtonFilter';
import ButtonSetExpanded from './DataGrid/ButtonSetExpanded';



const logger = new Logger('DataGrid3');
export default class DataGrid3 extends Component {
  constructor(props) {
    super(props);
    
    
    this.ac_data = props.rowData;


    this.state = {
      saved_filters: [],
      modules: [
        ClientSideRowModelModule,
        MasterDetailModule,
        MenuModule,
        ColumnsToolPanelModule,
        FiltersToolPanelModule,
        SetFilterModule
      ],
      columnDefs: [
         
        {
            field: 'registration_name',
            headerName: "A/C Registration", 
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              checkbox: true
            },
            filter: 'agTextColumnFilter',
            filterParams: {
              buttons: ["clear"]
            },
            width: 150,
            //pinned: true,
            suppressMenu: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
           
        },
        {   field: 'ship_number',
            headerName: "Ship/Nose", 
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
             width: 100,
          
        },
        {
          field: 'operator_icao_code',
          headerName: "Operator", 
           filter: 'agSetColumnFilter',
            filterParams: {
              //values: this.getFilterValuesData('type_subtype'),
              //buttons: ["apply"],
              excelMode: 'windows',
            },
          //filter: 'agTextColumnFilter',
          //filterParams: {
            //buttons: ["clear"]
          //},
          //pinned: true,
          suppressMenu: true,
          sortable: true,
          floatingFilter: true,
          resizable: true,
          // rowGroup: true,
          // hide: true,
          width: 110,
          enableRowGroup: true,
          cellRenderer: operatorIcaoCodeCellRenderer,
        },
       
        { 
          field: 'type_subtype', 
            headerName: "A/C Type", 
            filter: 'agSetColumnFilter',
            filterParams: {
              //values: this.getFilterValuesData('type_subtype'),
              //buttons: ["apply"],
              excelMode: 'windows',
            },
            suppressMenu: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
             width: 100,
              enableRowGroup: true,
        },
        {   field: 'system_assigned',
            headerName: "Assigned System", 
             filter: 'agSetColumnFilter',
            filterParams: {
              //values: this.getFilterValuesData('system_assigned'),
              //buttons: ["apply"]
              excelMode: 'windows',
               enableRowGroup: true,
            },
            suppressMenu: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
          //valueFormatter: "x.toLocaleString() + 'm'",
          
        },
        {   field: 'system_reported',
            headerName: "Reported System", 
             filter: 'agSetColumnFilter',
            filterParams: {
               excelMode: 'windows',
              //values: this.getFilterValuesData('system_reported'),
              //buttons: ["apply"]
            },
            suppressMenu: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
             enableRowGroup: true,
          //valueFormatter: "x.toLocaleString() + 'm'",
          
        },
        {   field: 'antenna',
            headerName: "Antenna Type", 
             filter: 'agSetColumnFilter',
            filterParams: {
               excelMode: 'windows',
              //values: this.getFilterValuesData('antenna'),
              //buttons: ["apply"]
            },
            suppressMenu: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
            enableRowGroup: true,
        },
        {   field: 'antenna_gen',
            headerName: "Antenna Gen", 
              filter: 'agSetColumnFilter',
            filterParams: {
              //values: this.getFilterValuesData('antenna_gen'),
              //buttons: ["apply"]
               excelMode: 'windows',
            },
            suppressMenu: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
             enableRowGroup: true,
        },
        {   field: 'xid',
            headerName: "XID", 
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
            
        },
        {   field: 'reboots',
            headerName: "Reboot Count", 
            filter: 'agNumberColumnFilter',
            suppressMenu: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
            enableValue: true
            
        },
        {   
          field: 'sla',
            headerName: "SLA (%)", 
            filter: 'agNumberColumnFilter',
            suppressMenu: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
            enableValue: true,
            cellRenderer: ColumnPercent.renderer,
            valueGetter: ColumnPercent.valueGetter
        },
        
        /*{
            headerName: 'SLA Trend',
            field: 'CloseTrends',
            width: 130,
            resizable: false,
            suppressSizeToFit: true,
            cellRenderer: lineChartRenderer
        },*/
      ],
      //autoGroupColumnDef: { field: 'operator_icao_code' },
      //defaultColDef: { flex: 1 },
      detailCellRendererParams: {
        detailGridOptions: {
          masterDetail: true,
          
          detailCellRendererParams: {
            detailGridOptions: {
              columnDefs: [
                {
                  field: '',
                  headerName: "Log File", 
                },
                {
                  field: '',
                  headerName: "Timestamp", 
                },
                                {
                  field: '',
                  headerName: "Data", 
                }

                
              ],
              sideBar: {
                toolPanels: [
                 
                  {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                  },
                  {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                  },
                  {
                    id: 'export',
                    labelDefault: 'Export Data',
                    labelKey: 'export',
                    iconKey: 'save',
                    toolPanel: 'customStatsToolPanel',
                  },
                ],
              }
            }
          },
          columnDefs: [
            { 
              field: 'id_file_set',
              headerName: "Fileset ID", 
              cellRenderer: 'agGroupCellRenderer',
              filter: 'agTextColumnFilter',
              suppressMenu: true,
              sortable: true,
              floatingFilter: true,
              resizable: true,
              pinned: "left"
            },
            { 
              field: 'system',
              headerName: "System", 
              filter: 'agSetColumnFilter',
              filterParams: {
                excelMode: 'windows',
              //values: this.getFilterValuesData('system_reported'),
              //buttons: ["apply"]
              },
              suppressMenu: true,
              sortable: true,
              floatingFilter: true,
              resizable: true,
              enableRowGroup: true,
            },
            {
              field: 'flight_number',
              headerName: "Flight Number", 
               filter: 'agTextColumnFilter',
              suppressMenu: true,
              sortable: true,
              floatingFilter: true,
              resizable: true,
            },
            { 
              field: 'flight_route',
              headerName: "Route", 
               filter: 'agTextColumnFilter',
              suppressMenu: true,
              sortable: true,
              floatingFilter: true,
              resizable: true,
            },
            { 
              field: 'departure_time',
              headerName: "Dep. Time (s)", 
            },
            { 
              field: 'arrival_time',
              headerName: "Arr. Time (s)", 
            },
            { 
              field: 'flight_duration_secs',
              headerName: "Flight Time (s)", 
              filter: 'agNumberColumnFilter',
              suppressMenu: true,
              sortable: true,
              floatingFilter: true,
              resizable: true,
               enableValue: true
            },
            { 
              field: 'reboots_total',
              headerName: "Reboots", 
               filter: 'agNumberColumnFilter',
              suppressMenu: true,
              sortable: true,
              floatingFilter: true,
              resizable: true,
               enableValue: true
            },
          ],
          
              sideBar: {
                toolPanels: [
                  {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                  },
                  {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                  },
                  {
                    id: 'export',
                    labelDefault: 'Export Data',
                    labelKey: 'export',
                    iconKey: 'save',
                    toolPanel: 'customStatsToolPanel',
                  },
                ],
              }
        },
        getDetailRowData: function(params) {
          
          logger.debug('getDetailRowData', params);
          //setTimeout(function() {
            params.successCallback(flightData);
          //}, 1000);
        },
      },
      rowSelection: 'multiple',
      rowData: [],
      components:{
        boldRenderer: function(params) {
            return '<b>foobar</b>';
        }
      },
      
       sideBar: {
        toolPanels: [
           {
            id: 'dashboard',
            labelDefault: 'Dashboard',
            labelKey: 'dashboard',
            iconKey: 'chart',
            toolPanel: 'ToolPanelFleet',
            toolPanelParams: {
              width:800
            }
          },
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
          {
            id: 'export',
            labelDefault: 'Export Data',
            labelKey: 'export',
            iconKey: 'save',
            toolPanel: 'customStatsToolPanel',
          },
        ],
       
        defaultToolPanel: 'dashboard',
      },
      frameworkComponents: { ToolPanelFleet: ToolPanelFleet },
    };
  }
  
  /*
  getFilterValuesData = column => {
    return [...new Set(this.ac_data.map(row => row[column]))]
  }
  */

  onGridReady = params => {
    logger.debug('onGridReady', params);
    this.gridApi = params.api;
    
    this.gridColumnApi = params.columnApi;
    const updateData = data => {
      this.setState({ rowData: data });
    };
    logger.debug('onGridReady', this.ac_data)
    updateData(this.ac_data);
    
  };  
  
  saveFilters = params => {
    var filterset= {};
    this.gridColumnApi.getAllColumns().forEach( col => {
      var instance = this.gridApi.getFilterInstance(col.colId);
      if (instance.getModel()) {
        filterset[col.colId] = instance.getModel();  
      }
      
    });
    
    var saved_filters= this.state.saved_filters;
    saved_filters.push({name: "Filter Set ", model: filterset});
    this.setState({ saved_filters: saved_filters });
  }

  /*
  onGridReady = params => {
    logger.debug('onGridReady', params);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const httpRequest = new XMLHttpRequest();
    const updateData = data => {
      this.setState({ rowData: data });
    };

    httpRequest.open(
      'GET',
      'https://raw.githubusercontent.com/ag-grid/ag-grid-docs/latest/src/javascript-grid-master-detail/lazy-load-rows/data/data.json'
    );
    httpRequest.send();
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState === 4 && httpRequest.status === 200) {
        logger.debug('onGridReady', httpRequest.responseText);
        updateData(JSON.parse(httpRequest.responseText));
      }
    };
  };
  */
  

  render() {
    logger.debug("render", this.state);
    
  
    var filters = [];   
    for(let i = 0 ; i < this.state.saved_filters.length; i++) {
      filters.push (
        <React.Fragment  key={"filteset" + i}>
        <ButtonFilterSet
            columnApi={this.gridColumnApi} 
            gridApi={this.gridApi} 
            model={this.state.saved_filters[i].model}>
          {this.state.saved_filters[i].name + i}
        </ButtonFilterSet>&nbsp;
        </React.Fragment>
      )
    }

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', height: '100%', flexDirection: "column"}}>
          <div style={{ paddingBottom: "4px", paddingLeft: "4px" }}>
           <ButtonSetExpanded expand={true} columnApi={this.gridColumnApi} gridApi={this.gridApi} />&nbsp;
           <ButtonSetExpanded expand={false} columnApi={this.gridColumnApi} gridApi={this.gridApi} />&nbsp;
          
           <ButtonClearFilters columnApi={this.gridColumnApi} gridApi={this.gridApi} />&nbsp;
           <ButtonSaveFilters callback={this.saveFilters} />&nbsp;
            <ButtonFilter columnApi={this.gridColumnApi} gridApi={this.gridApi} field="system_reported" 
                model={{values: [
                    '3000,eXConnect', 
                    'eFX,eXConnect', 
                    'eFX-eXConnect',
                    'eX1,eXConnect', 
                    'eX1-eXConnect',
                    'eX2,eXConnect', 
                    'eX2-eXConnect',
                    'eX2L,eXConnect', 
                    'eX2L-eXConnect',
                    'eX3,eXConnect', 
                    'eX3-eXConnect',
                    'eXConnect',
                    'eXConnect,eXO',
                    'eXO-eXConnect',
                    'eXL,eXConnect', 
                    'eXL-eXConnect'
                    
                    ]}}>
                System: eXConnect
            </ButtonFilter>&nbsp;
            <ButtonFilter columnApi={this.gridColumnApi} gridApi={this.gridApi} field="reboots" 
                model={{type: "greaterThan", filter: '38'}}>
                Reboots &gt; 38
            </ButtonFilter>&nbsp;
           
            <ButtonFilter columnApi={this.gridColumnApi} gridApi={this.gridApi} field="reboots" model={{type: "greaterThan", filter: '38'}}>
                Reboots &gt; 38
            </ButtonFilter>&nbsp;
             <ButtonFilter columnApi={this.gridColumnApi} gridApi={this.gridApi} field="sla" model={{type: "lessThan", filter: '95'}}>
                SLA &lt; 95 %
            </ButtonFilter>&nbsp;
             
            {filters}
          </div>
          <div style={{ height: "20px", flex: 1 }}>
            <div
              id="myGrid"
              style={{
                height: '100%',
                width: '100%',
              }}
              className="ag-theme-alpine"
            >
              <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
             sideBar={this.state.sideBar}
            masterDetail={true}
            detailRowHeight={500}
            detailCellRendererParams={this.state.detailCellRendererParams}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            frameworkComponents={this.state.frameworkComponents}
          />
            </div>
          </div>
        </div>
     
      {/*
      <div style={{ width: '100%', height: '100%' }}>
        <div
          id="myGrid"
          style={{
            height: '100%',
            width: '100%',
          }}
          className="ag-theme-balham"
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
             sideBar={this.state.sideBar}
            masterDetail={true}
            detailRowHeight={500}
            detailCellRendererParams={this.state.detailCellRendererParams}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            frameworkComponents={this.state.frameworkComponents}
          />
        </div>
      </div>
      */}
       </div>
    );
  }
}
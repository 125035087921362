import React, { useContext } from 'react';
import { Logger }               from 'aws-amplify';
import { useHistory }           from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Switch from '@material-ui/core/Switch';
import FilterListIcon from '@material-ui/icons/FilterList';
import EditIcon from '@material-ui/icons/Edit';
import TableMenuLabel from './TableMenuLabel';

import FormNotification from './FormNotification';
import FormDelete from './FormDelete';
import FormRefresh from './FormRefresh';

import AppContext      from 'pac-responsive-ui-framework/core/AppContext';
import AlertSubscriptionsService from 'services/AlertSubscriptionsService';
import UserService  from 'services/UserService';
const logger = new Logger('EnhancedTable');

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}



function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
         <TableCell padding="checkbox">
          
        </TableCell>
        {props.headers.map((headCell, index) => (
          <TableCell
            key={headCell.key}
            align={headCell.type === "number" ? 'right' : 'left'}
            //padding={index !== 0 ? 'none' : 'default'}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={order}
              onClick={createSortHandler(headCell.key)}
            ><b>
              {headCell.label} 
              </b>
           
              
        
              {orderBy === headCell.key ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  logger.debug('EnhancedTableToolbar', props);
  
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let operator = params.get('operator');
  let aircraft = params.get('tail');
  let type = params.get('type');  
  let open = props.openNotification;
  if (aircraft && operator) {
    logger.debug("opening form");
    open =true;
  }
  else {
    aircraft=null;
    operator=null;
    type=null;
  }
  var types = [];
 
  
  const classes = useToolbarStyles();
  const { numSelected } = props;

  /**
   * this is the headerbar version
   * 
   * This is called by FormNotification
   * 
   * Clears and calls the parent.
   */
  const handleFormClose = (data) => {
      
      logger.debug("EnhancedTableToolbar::handleFormClose", data);
      operator = null;
      aircraft = null;
      type = null;
      open = false;
      props.onClose(data);
  };

  logger.debug("EnhancedTableToolbar - return", open);
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {props.title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <React.Fragment>
          <FormDelete onSubmit={props.onDelete} />
          <FormRefresh loading={props.loading} onClick={props.onRefresh}/>
         </React.Fragment>
      ) : (
        <React.Fragment>
          {/*
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          */}
          <FormNotification data={props.data} open={open} operator={operator} aircraft={aircraft} types={types} onClose={handleFormClose}/>
          <FormRefresh loading={props.loading} onClick={props.onRefresh}/>
        </React.Fragment>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {

  var app_context = useContext(AppContext);
  
  let history = useHistory();
  const classes = useStyles();
  
  const [refreshData, setRefreshData] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(true);
  const dense = true;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [notificationOpen, setNotificationOpen] = React.useState(false);

  if (refreshData) {
    setSelected([]);
    setRefreshData(false);
   
    logger.debug('app_context', app_context.state.user.session.login_name);
    const user_service = new UserService();
    user_service.fetch(app_context.state.user.session.login_name).then((data) => {
      
      logger.debug('user_data', data);
      var user_rows = [];
      if (data.alert_subscriptions) {
        for (const [key, value] of Object.entries(data.alert_subscriptions)) {
          logger.debug(value);
          
          user_rows.push({
            key:      value['subscription_id'],
            date:     value['subscription_expiry_date'],
            operator: value['subscription_operator_icao'],
            aircraft: value['subscription_aircraft_registration'],
            type:     value['subscription_type']
          });
        }
      }
      setLoading(false);
      setRows(user_rows);
      
    }).catch((e) => {
      
    });
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.key);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, key) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  
  const handleOpenNotification = (event, key) => {
    logger.debug("handleOpenNotification", event,key);
    setNotificationOpen(true);
    event.stopPropagation();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleDelete() {
      logger.debug("deleting", rows, selected);
      setLoading(true);
      const alert_subscriptions_service = new AlertSubscriptionsService();
      var calls = [];
      for (let i=0; i < selected.length; i++) {
        calls.push(alert_subscriptions_service.deleteRecord(selected[i]));
      }
      Promise.all(calls).then(values => {
          logger.debug('EnhancedTable::handleFormClose promise.all', values);
          // refresh the table data here
          if (values.length) {
            setRefreshData(true);
            setLoading(true);
          }

        }).catch(e => {
          logger.debug("EnhancedTable::handleFormClose error calling notification update:", e);
        });
  }

  const handleRefresh = () => {
    setRefreshData(true);
    setLoading(true);
  }

  /*
  const handleChangeDense = event => {
    setDense(event.target.checked);
  };
  */
  
  /**
   * this is the EnhancedTable version
   * 
   * This is called from the headerbar
   * 
   */ 
  const handleFormClose = (data) => {
      logger.debug("EnhancedTable::handleFormClose", data, rows);
      
      
      if (data) {
        setLoading(true);
        const alert_subscriptions_service = new AlertSubscriptionsService();
        var calls = [];
        for (let i=0; i < data['types'].length; i++) { // loop through new records
          var found = false;
          for (let j=0; j < rows.length; j++) { // loop through existing records
            let row =rows[j];
            if (row['operator'] === data['operator'] 
                && row['aircraft'] === data['aircraft']
                && row['type'] === data['types'][i]['name']) { // matches existing record
              found = true;
              logger.debug("EnhancedTable::handleFormClose found", row);
              if (data['types'][i]['value'] === 0) { // delete record
                logger.debug("EnhancedTable::handleFormClose delete record", row);
                calls.push(alert_subscriptions_service.deleteRecord(row['key']));
              }
              else if (row['date'] !== data['types'][i]['value']) { // update record
                logger.debug("EnhancedTable::handleFormClose update record", row, data['types'][i]);
                var payload = {
                  subscription_type: data['types'][i]['name'],
                  subscription_operator_icao: data['operator'],
                  subscription_aircraft_registration: data['aircraft'],
                  subscription_expiry_date: data['types'][i]['value']
                }
                calls.push(alert_subscriptions_service.update(row['key'], payload));
              }
              else { // do nothing, no change in record.
                logger.debug("EnhancedTable::handleFormClose no change", row, data['types'][i]);
              }
            }
            
          }
        
          if (!found && data['types'][i]['value'] !== 0) { // insert new record
            logger.debug("EnhancedTable::handleFormClose insert record", data['types'][i]);
              var payload = {
                subscription_type: data['types'][i]['name'],
                subscription_operator_icao: data['operator'],
                subscription_aircraft_registration: data['aircraft'],
                subscription_expiry_date: data['types'][i]['value']
              }
              logger.debug("EnhancedTable::handleFormClose payload", payload);
              calls.push(alert_subscriptions_service.add(payload));
            
          }
        }
        Promise.all(calls).then(values => {
          logger.debug('EnhancedTable::handleFormClose promise.all', values);
          if (values.length) {
            
            setRefreshData(true);
          }
        }).catch(e => {
          logger.debug("EnhancedTable::handleFormClose error calling notification update:", e);
        });
    }
    logger.debug("EnhancedTable::handleFormClose", "history.push");
   
    history.push('/Profile/');
  };
  


  const isSelected = key => selected.indexOf(key) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar 
            headers={props.headers} 
            numSelected={selected.length} 
            title={props.title} 
            onClose={handleFormClose}
            onDelete={handleDelete}
            data={rows}
            openNotification={notificationOpen}
            onRefresh={handleRefresh}
            loading={loading}

        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headers={props.headers}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.key);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => handleRowClick(event, row.key)}
                     // onDoubleClick={event => handleOpenNotification(event, row.key)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.key}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormNotification key={row.key} edit={true} data={rows} open={false} operator={row['operator']} aircraft={row['aircraft']} onClose={handleFormClose}/>
                      </TableCell>
                      {
                        props.headers.map((cell, index) => {
                        
                          // this section formats the cell based on cell type.
                          var cell_value= row[cell.key];
                          var align="left";
                          if (cell.type === 'boolean') {
                              if (row[cell.key] === true) {
                                cell_value = "True";
                              }
                              else cell_value = "False";
                          }
                          else if (cell.type === 'number') {
                              align = 'right';
                          }
                          
                          return (<TableCell align={align} key={row[cell.key]}>{cell_value}</TableCell>)
                        })
                      }
                     
                      
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/*
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      */}
    </div>
  );
}

import React, { Component } from 'react';
import { Logger }                   from 'aws-amplify';

import * as agCharts from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';

import ButtonClearFilters from './ButtonClearFilters';
import ButtonFilter from './ButtonFilter';

import { ModuleRegistry, AllModules } from '@ag-grid-enterprise/all-modules';

ModuleRegistry.registerModules(AllModules);



const logger = new Logger('ToolPanelFleet');

export default class ToolPanelFleet extends Component {
  constructor(props) {
    super(props);
    
    logger.debug('constructor', props);
    
    this.state = { 
      bottom_ac: [], 
      reboot_count: 0,
      aircraft_count: 0,
      options: {
        title: { text: 'Reboots per Operator' },
        subtitle: { text: '(click a column for details)' },
        data: [],
        series: [
          {
            type: 'column',
            xKey: 'operator_icao_code',
            yKeys: ['reboots'],
            grouped: true,
            listeners: {
              nodeClick: function(event) {
                logger.debug("nodeClick", event);
                var instance = props.api.getFilterInstance('operator_icao_code');
                instance.setModel({values: [event.datum.operator_icao_code]});
                instance.applyModel();
                props.api.onFilterChanged();
              },
            },
          },
        ],
        axes: [
          {
            type: 'category',
            position: 'bottom',
          },
          {
            type: 'number',
            position: 'left',
          },
        ],
        legend: { enabled: false },
      }
    };

    // calculate stats when new rows loaded, i.e. onModelUpdated
    
    this.props.api.addEventListener(
      'modelUpdated',
      this.updateTotals.bind(this)
    );
    
    this.props.api.addEventListener(
      'firstDataRendered',
      this.updateTotals.bind(this)
    );
    
  } 
  
  
   updateTotals() {
     //logger.debug("updateTotals1");
     /*
     var createRangeChartParams = {
            cellRange: {
                columns: ['registration_name', 'sla']
            },
            chartType: 'groupedColumn',
            chartContainer: document.querySelector('#myChart'),
            suppressChartRanges: true,
            aggFunc: 'avg',
            click: function (e) {
              logger.debug(e);
            },
            processChartOptions: function(params) {
              let opts = params.options;
              opts.title.enabled = true;
              opts.title.text= "Aircraft (Tail) by SLA" ;
              opts.xAxis.labelRotation = 30;
              opts.seriesDefaults.tooltipRenderer = function(params) {
                let titleStyle = params.color ? ' style="color: white; background-color:' + params.color + '"' : "";
                let title = params.title ? '<div class="title"' + titleStyle + ">" + params.title + "</div>" : "";
                let value = params.datum[params.yField].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                return title + '<div class="content" style="text-align: center">' + value + "</div>";
              };
              return opts;
            }
        };
        
    if (this.chartref) this.chartref.destroyChart();
     this.chartref = this.props.api.createRangeChart(createRangeChartParams);
     //logger.debug("chartref", this.chartref);
     */
     var reboot_count = 0;
     var aircraft_count = 0;
     var filtered_data=[];
     var operator_data = {};
     this.props.api.forEachNodeAfterFilter(function(node, index) {
        filtered_data.push(node.data);
        if (index == 0)
        console.log('updateTotals2', node, index);
        if (node.data && node.data.reboots && node.data.operator_icao_code) {
          if (!operator_data[node.data.operator_icao_code]) operator_data[node.data.operator_icao_code] = 0;
          operator_data[node.data.operator_icao_code] += node.data.reboots;
        }
        
        aircraft_count++;
      }); 
      Object.keys(operator_data).forEach((key, index) => {
          var row = {'operator_icao_code': key, 'reboots': operator_data[key]}
          filtered_data.push(row);
      });
      
      
       console.log('updateTotals3', operator_data);
      var options = {...this.state.options}
      options.data = filtered_data;

     
     this.setState({
      reboot_count: reboot_count,
      aircraft_count: aircraft_count,
      options: options
     })
   }
  
  
  

  render() {
    return (
      <div style={{paddingLeft: 8}}>
        <span>
          <h1>
            Dashboard
          </h1>
          <span>
            <ButtonClearFilters columnApi={this.props.columnApi} gridApi={this.props.api} />
             <ButtonFilter columnApi={this.props.gridColumnApi} gridApi={this.props.api} field="reboots" 
                model={{type: "greaterThan", filter: '38'}}>
                Reboots &gt; 38
            </ButtonFilter>&nbsp;
          </span>
          <p>Total Aircraft: {this.state.aircraft_count}</p>
          <p>Total Reboots: {this.state.reboot_count}</p>
          
        </span>
        <div className="wrapper">
            <AgChartsReact options={this.state.options} />
          </div>
        {/* <div id="myChart" className="ag-theme-alpine-dark my-chart"></div> */ }
        
      </div>
      
    );
  }

 
}

import BaseService from './BaseService';

import OperatorIcaoCodeCellRenderer     from '../components/AGGrid/OperatorIcaoCellRenderer';
import BooleanCellRenderer              from '../components/AGGrid/BooleanCellRenderer';
import GCSExclusionPaddingRenderer      from '../components/AGGrid/GCSExclusionPaddingRenderer';
//import SLAMetricSettingsCellRenderer    from '../components/AGGrid/SLAMetricSettingsCellRenderer';
//import ReportVisibilityCellRenderer     from '../components/AGGrid/ReportVisibilityCellRenderer';

import CustomerCategoryService      from './CustomerCategoryService';
import CountryService               from './CountryService';
import RegionService                from './RegionService';
import WISPSourceService            from './WISPSourceService';

import SLAExclusionParamService from "./SLAExclusionParamService";
import SLAMetric from "./SLAMetric";


import { Logger }                   from 'aws-amplify';

const logger = new Logger('CustomerService');


export default class CustomerService extends BaseService {

    constructor() {
        let apiName = 'OriginsAPI';
        let collectionPath = '/customer';
        super(apiName, collectionPath);
        this.metrics = [];
    }

    async fetchRecords(query) {
        
        const service = new SLAMetric();
        this.metrics = await service.fetchRecords();
        this.metrics = service.formatRowData(this.metrics);
        logger.debug("fetchRecords", this.metrics);
        
        return super.fetchRecords(query);
    }
    

    formatRowData(data) {
        logger.debug("formatRowData", data, this.metrics);
      
        
        data.data.forEach((element) => {
            
            // set the flag if sla_exclusion_params are available (only for GCS operators)
            if (element['OperatorKey']) {
                element['gcs_enabled'] = true;
            }
            else {
                element['gcs_enabled'] = false;
            }
            
            //set the metrics and the metric flag
            element['metrics_enabled'] = false;
            this.metrics.forEach((opt) => {
                
                // set the string value for options
                if (opt['has_active'] && element[opt["code"] + "_enabled"]) {
                    element['metrics_enabled'] = true;
                }
                var value = [];
                if (opt['options_string']) {
                    var init_value = parseInt(element[opt["code"]], 10);
                    logger.debug('formatRowData init_value', init_value, opt["code"]);
                    if (init_value) {
                        var options = {};
                        var options_parts = opt["options_string"].split("|");
                        // get the max value for the metrics
                        var max_value = 2 ** options_parts.length;
                        logger.debug("max_value", max_value);
                        options_parts.forEach((option) => {
                            var option_kvp = option.split("=");
                            options[option_kvp[0]] = option_kvp[1];
                        });
                        logger.debug('formatRowData options', options);
                        var top_value = max_value;
                        while (top_value >= 1) {
                            if (init_value >= top_value) {
                                value.push(options[top_value]);
                            init_value = init_value - top_value;
                            }
                            top_value = top_value /2;
                        }
                        logger.debug('formatRowData value', value);
                    }
                }
                element[opt['code'] + "_text"] = value.join(', '); 
            });
        });
        return data.data;
    }
     
    getMetaData(data) {
        
        logger.debug("getMetaData", data);
        var meta_data = data.meta;
        meta_data.count = data.meta.result_count;
        meta_data.total_records = data.meta.result_count;
        meta_data.page_size = data.meta.result_count;
        meta_data.page_number = 1;
        meta_data.rowsPerPage = data.meta.result_count;
        return meta_data;
    }
     
    async save(data, metaData) {
        logger.debug("save1", data);
        if ( typeof data.id_customer_category === 'object') data.id_customer_category = data.id_customer_category.id_customer_category.toString();
        if ( typeof data.id_country === 'object') data.id_country = data.id_country.id_country.toString();
        if ( typeof data.id_region === 'object') data.id_region = data.id_region.id_region.toString();
        if ( typeof data.WISPSourceKey === 'object') data.WISPSourceKey = data.WISPSourceKey.WISPSourceKey.toString();
        
        for (const key in data) {
            if (Array.isArray(data[key])) {
                var value = 0;
                data[key].forEach((element) => {
                    if (element['id']) value += parseInt(element['id'], 10);    
                });
                data[key] = value;
            }
        }
        logger.debug("save2", data);
        
        
        var response = null;
        if (data['non_revenue_flight']) {
            try {
                data['non_revenue_flight'] = JSON.parse(data['parameter_mapping']);
            }
            catch (err) {
                return { 
                    success: false, 
                    updated: false, 
                    response: {
                        data: {
                            error: "Invalid JSON", 
                            columns: [
                                {
                                    key: 'non_revenue_flight',
                                    message: "Invalid JSON"
                                }
                            ]
                        
                        }
                    } 
                };
            }
        }
        
        if (data['id_operator']) { // update
            await this.update(data['id_operator'], data)
                .then((results) => {
                    response = results;
                    logger.debug("update record", results);
                })
                .catch((err) => {
                    response = err;
                    logger.debug('update record failed', err);
                });
        }
        else {
            // insert
             logger.debug("save insert", data);
            await this.add(data)
                .then((results) => {
                    response = results;
                    logger.debug("insert record", results);
                })
                .catch((err) => {
                    response = err;
                    logger.debug('insert record failed', err);
                });
        }
        return response;
    }
     
    async getColumnDefs() {
        var column_width=100;
        
        // add metrics
        var metrics = [];
        var metrics_header = {
            headerName: 'SLA Metric Settings',
            openByDefault: false, // column: header expanded
            defaultExpanded: false, 
            children: [{
                        field: "metrics_enabled",
                        headerName: "Active", 
                        headerTooltip: 'At least one of the metrics is active for the customer',
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        cellRenderer: BooleanCellRenderer
                    }]
        };
        const service1 = new SLAMetric();
        await service1.fetchRecords()
            .then((data) => {
                this.metrics = service1.formatRowData(data);
                this.metrics.forEach((element, index) => {
                    if (element['active']) {
                        var new_record;
                        if (element['options_string']) {
                            // this is the header in the table
                            new_record = {};
                            new_record['field'] = element["code"] + "_text";
                            new_record['headerName'] = element["name"] + '1';
                            new_record['headerTooltip'] = element["name"] + '1';
                            new_record['width'] = 400;
                            new_record['filter'] = 'agTextColumnFilter';
                            new_record['resizable'] = true;
                            new_record['sortable'] = true;
                            new_record['columnGroupShow'] = 'open';
                            metrics_header.children.push(new_record);
                            
                            // this is the form element
                            new_record = {};
                            new_record['field'] = element["code"];
                            new_record['label'] = element["name"];
                            
                            new_record['hide'] = true;
                            new_record['width'] = 266;
                            new_record['filter'] = 'agTextColumnFilter';
                            new_record['resizable'] = true;
                            new_record['sortable'] = true;
                            new_record['formType'] = "multiselect";
                            new_record['getOptionLabel'] = (option) => { return option['label'] };
                            new_record['renderOption'] = (option) => { return option['label'] };
                            new_record['newline'] = element['newline'];
                            new_record['columnGroupShow'] = 'open';
                            
                            var options = [];
                            var options_parts = element["options_string"].split("|");
                            var max_value = 2 ** options_parts.length;
                            options_parts.forEach((option) => {
                                var option_kvp = option.split("=");
                                options.push({id: option_kvp[0], label: option_kvp[1]});
                            });
                            new_record['options'] = options;
                            
                            logger.debug("getColumnDefs options_string", new_record);
                            
                            new_record['getInitValue'] = (data, options) => {
                                var options_init = [];
                                //var init_value = data[element["code"]];
                                var init_value = parseInt(data[element["code"]], 10);
                                if (init_value) {
                                    var top_value = max_value;
                                    while (top_value >= 1) {
                                        if (init_value >= top_value) {
                                            logger.debug("options_string options_init1", options, top_value);
                                            options.forEach((option) => {
                                                if (option['id'] == top_value) {
                                                    options_init.push(option);
                                                }
                                            });
                                           // options_init.push(options.findIndex((option) => option.id === top_value));
                                            init_value = init_value - top_value;
                                        }
                                        top_value = top_value /2;
                                    }
                                }
                                logger.debug("options_string options_init2", options_init); 
                                return options_init;
                            };
                            
                            metrics_header.children.push(new_record);
                        }
                        else {
                            if (element['has_threshold']) {
                                new_record = {};
                                new_record['field'] = element["code"];
                                new_record['headerName'] = element["name"];
                                new_record['headerTooltip'] = element["name"];
                                new_record['width'] = 133;
                                new_record['resizable'] = true;
                                new_record['sortable'] = true;
                                new_record['filter'] = 'agNumberColumnFilter';
                                new_record['comparator'] = (valueA, valueB, nodeA, nodeB, isDescending) => {
                                    if (valueA === valueB) return 0;
                                    return (valueA > valueB) ? 1 : -1;
                                };
                                new_record['formType'] = "integer";
                                new_record['defaultValue'] = element["default_threshold"];
                                if (element['has_threshold'] && element["default_threshold"] != null) {
                                    new_record['helperText'] = [
                                        "Default value: " + element["default_threshold"]
                                        //element["ExclusionParameterName"],
                                        //element["ExclusionParameterDesc"]
                                    ];
                                }
                                new_record['columnGroupShow'] = 'open';
                                new_record['newline'] = element['newline'];
                                metrics_header.children.push(new_record);
                            }
                        }
                        if (element["has_active"]) {
                            var name = element["name"];
                            if (element['has_threshold'] || element['options_string']) {
                                name += ' Active';
                            }
                            new_record = {};
                            new_record['field'] = element["code"] + "_enabled";
                            new_record['headerName'] = name; 
                            new_record['headerTooltip'] = name;
                            new_record['label'] = name;
                            new_record['resizable'] = true;
                            new_record['sortable'] = true;
                            new_record['filter'] = 'agTextColumnFilter';
                            new_record['cellRenderer'] = BooleanCellRenderer;
                            new_record['columnGroupShow'] = 'open';
                            new_record['width'] = column_width;
                            new_record['formType'] = "boolean";
                            new_record['defaultValue'] = 0;
                            new_record['newline'] = !element['has_threshold'] && !element['options_string'];
                            new_record['columnGroupShow'] = 'open';
                            metrics_header.children.push(new_record);
                        }
                    }
                });
            })
            .catch((err) => {
                logger.debug('getColumnDefs service1 failed', err);
            });
        logger.debug("getColumnDefs returning", exclusion_parameters);
        
        
        // add exclusion parameters
        var exclusion_parameters = [];
        
        var exclusion_header = {
            headerName: "SLA Exclusion Parameters",
            openByDefault: false, // column: header expanded
            defaultExpanded: false, 
            children: [{
                        field: "gcs_enabled",
                        headerName: "Enabled", 
                        headerTooltip: 'These parameters are only available to GCS enabled operators.',
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        cellRenderer: BooleanCellRenderer
                    }]
        };
        
        const service = new SLAExclusionParamService();
        await service.fetchRecords()
            .then((data) => {
                exclusion_parameters = service.formatRowData(data);
                logger.debug("getColumnDefs service success", exclusion_parameters);
                exclusion_parameters.forEach((element, index) => {
                    if (element['RowActiveFlag']) {
                        logger.debug("getColumnDefs exclusion_param", element);
                        var new_record = {};
                        new_record['field'] = element["ExclusionParameterCode"];
                        new_record['headerName'] = element["ExclusionParameterCode"];
                        new_record['headerTooltip'] = element["ExclusionParameterName"];
                        new_record['width'] = 133;
                        new_record['filter'] = 'agNumberColumnFilter';
                        new_record['comparator'] = (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        };
                        new_record['formType'] = "integer";
                        new_record['defaultValue'] = element["DefaultConfigValue"];
                        new_record['helperText'] = [
                            "Default value: " + element["DefaultConfigValue"],
                            element["ExclusionParameterName"],
                            element["ExclusionParameterDesc"]
                        ];
                        new_record['formHide'] = (data) => {return !data['OperatorKey']; };
                        new_record['columnGroupShow'] = 'open';
                        
                        exclusion_header.children.push(new_record);
                    }
                });
                // add a message if there is no GCS entry for the Operator
                exclusion_header.children.push( {
                    hide: true,  
                    formType: "content",
                    formContent: "These parameters are only available to GCS enabled operators.",
                    formHide: (data) => {return data['OperatorKey']; } 
                });
                
            })
            .catch((err) => {
                logger.debug('getColumnDefs service failed', err);
            });
        logger.debug("getColumnDefs returning", exclusion_parameters);
        
        var headers = [
            {
                headerName: 'Customer',
                openByDefault: true, // column: header expanded
                defaultExpanded: true, // Form: initially expanded in form.  Use  expanded: true to make it always open.
                children: [
                    {
                        field: "id_operator",
                        headerName: "Customer ID", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        pinned: 'left',
                        formType: "readonly",
                        columnGroupShow: 'open'
                    },
                    {
                        field: "OperatorKey",
                        headerName: "GCS ID", 
                        headerTooltip: "GCS ID",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        pinned: 'left',
                        formType: "readonly",
                        columnGroupShow: 'open',
                    },
                    {
                        field: "operator_icao_code",
                        headerName: "ICAO", 
                        headerTooltip: "ICAO Code",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        cellRenderer: OperatorIcaoCodeCellRenderer,
                        pinned: 'left',
                        formType: "text",
                        newline: true
                    },
                    {
                        field: "operator_name",
                        headerName: "Customer Name", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 3,
                        pinned: 'left',
        
                        required: true,
                        formType: "text"
                    }
               ] 
            },
            {
                headerName: 'Details',
                defaultExpanded: true, //initially expanded in form.  Use  expanded: true to make it always open.
                
                children: [
                     {
                        field: "category_name",
                        headerName: "Category", 
                        headerTooltip: "Category",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        
                        // no formType so is hidden from the form but visible in the grid
                    },
                    {
                        field: "id_customer_category",
                        headerName: "Category",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        hide: true, //hides it from the grid but not the form.
                        
                        required: true,
                        formType: "select",
                        service: new CustomerCategoryService(),
                        getOptionLabel: (option) => { return option.category_name ? option.category_name : "" },
                        renderOption: (option) =>  { return option.category_name ? option.category_name : "" },
                        getValue: (data) => { return {"id_customer_category": data.id_customer_category,"category_name":data.category_name}},
                        newline: true,
                        
                    },
                    {
                        field: "oracle_id",
                        headerName: "Oracle ID", 
                        headerTooltip: "Oracle ID",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        
                        formType: "integer",
                        required: true,
                        
                        columnGroupShow: 'open',
                    },
                    {
                        field: "oracle_customer_id",
                        headerName: "Oracle Cust. ID", 
                        headerTooltip: "Oracle Customer ID",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        
                        required: true,
                        formType: "integer",
                        columnGroupShow: 'open'
                    },
                    {
                        field: "operator_iata_code",
                        headerName: "IATA", 
                        headerTooltip: "IATA Code",
                        filter: 'agTextColumnFilter',
                        width: column_width,

                        formType: "text",
                        columnGroupShow: 'open'
                        //helperText: "Three letter code for the operator"
                    },
                    {
                        field: "operator_carrier_code",
                        headerName: "Carrier", 
                        headerTooltip: "Carrier Code",
                        filter: 'agTextColumnFilter',
                        width: column_width,

                        formType: "text",
                        columnGroupShow: 'open'
                        //helperText: "Three letter code for the operator"
                    },
                    {
                        field: "id_country",
                        headerName: "Country", 
                        headerTooltip: "Country",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        hide: true,
                        
                        required: true,
                        formType: "select",
                        service: new CountryService(),
                        getOptionLabel: (option) => { return option.country_name ? option.country_name : "" },
                        renderOption: (option) =>  { return option.country_name ? option.country_name : "" },
                        getValue: (data) => { return {"id_country": data.id_country,"country_name":data.country_name}},
                        
                        newline: true,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "country_name",
                        headerName: "Country", 
                        headerTooltip: "Country",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "id_region",
                        headerName: "Region", 
                        headerTooltip: "Region",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        hide: true,
                        
                        required: true,
                        formType: "select",
                        service: new RegionService(),
                        getOptionLabel: (option) => { return option.name ? option.name : (option.region_name ? option.region_name :"" ) },
                        renderOption: (option) =>  { return option.name ? option.name : (option.region_name ? option.region_name :"" ) },
                        getValue: (data) => { return {"id_region": data.id_region,"region_name":data.region_name}},
                        columnGroupShow: 'open'
                    },
                    {
                        field: "region_name",
                        headerName: "Region", 
                        headerTooltip: "Region",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "operator_description",
                        headerName: "Description", 
                        filter: 'agNumberColumnFilter',
                        width: column_width * 3,
                        formType: "multiline",
                        newline: true,
                       
                        columnGroupShow: 'open'
                    },
                    {
                        field: "updated_date",
                        headerName: "Updated Date", 
                        headerTooltip: "Updated Date",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        formType: "readonly",                        
                        
                        newline: true,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "updated_by_name",
                        headerName: "Updated By", 
                       
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        formType: "readonly",
                        columnGroupShow: 'open'
                    },
                ],
            },
            {
                headerName: 'Aircraft',
                children: [
                    {   
                        field: "active_aircraft_count",
                        headerName: "Active A/C", 
                        headerTooltip: "Active Aircraft Count",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "readonly",
                        defaultValue: 0
                    },
                    {   
                        field: "live_aircraft_count",
                        headerName: "Live A/C", 
                        headerTooltip: "Live Aircraft Count",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "readonly",
                        defaultValue: 0,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "gcs_live_aircraft_count",
                        headerName: "GCS A/C", 
                        headerTooltip: "Total aircraft enabled for GCS",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "readonly",
                        defaultValue: 0,
                        columnGroupShow: 'open'
                    },
                    {   
                        field: "aircraft_count",
                        headerName: "Total A/C",
                        HelperText: "Total Aircraft Count, including inactive aircraft",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "readonly",
                        defaultValue: 0,
                        columnGroupShow: 'open'
                    }
                ],
            },
            
            {
                headerName: 'ELog',
                children: [
                     {
                        field: "elog_transfer_enabled",
                        headerName: "ELog Transfer Enabled",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        readonly: true
                    }
                ]
            },
            {
                headerName: 'Load Script Overwrite Settings',
                headerTooltip: 'Load Script Overwrite Settings',
                children: [
                     {
                        field: "LS_Override_Active",
                        headerName: "Active",
                        headerTooltip: "Load Script Overwrite Settings Active",
                        label: "Load Script Overwrite Settings Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        
                        defaultValue: false,
                    },
                    {
                        field: "LS_System_Restart_Count",
                        headerName: "Restart Count", 
                        helperText: "System Restart Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3,
                        
                        newline: true
                    },
                    {
                        field: "LS_Commanded_Reset_Count",
                        headerName: "Command Count", 
                        helperText: "Commanded Reset Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3
                    },
                    {
                        field: "LS_Fault_Count",
                        headerName: "Fault Count", 
                        helperText: "Fault Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3
                    },
                    {
                        field: "LS_Reboot_Count",
                        headerName: "Reboot Count", 
                        helperText: "Reboot Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3,
                        newline: true
                    },
                    {
                        field: "LS_Seat_App_Reset_Count",
                        headerName: "Seat App Reset Count", 
                        helperText: "Seat App Reset Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3
                    },
                    {
                        field: "LS_FMR_Count",
                        headerName: "FMR Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3
                    },
                ]
            }
        ];
        
        headers.push(metrics_header);
        
        headers.push(exclusion_header);
        
        
        var headers2 = 
        [   /*
            {
                headerName: 'SLA Metric Settings (OLD)',
                children: [
                    
                     {
                        headerName: "Active", 
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        cellRenderer: SLAMetricSettingsCellRenderer,
                        columnGroupShow: 'closed',
                    },
                    {
                        field: "flight_time_availability",
                        headerName: "Flight Time Avail.", 
                        headerTooltip: "Flight Time Availability",
                        filter: 'agNumberColumnFilter',
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        width: column_width,
                        formType: "float",
                        defaultValue: 95.00
                    },
                    {
                        field: "flight_time_availability_enabled",
                        headerName: "Active", 
                        headerTooltip: "Flight Time Availability Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        columnGroupShow: 'open',
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0
                    },
                    {
                        field: "minutes_in_month_availability",
                        headerName: "Minutes in Month Avail.", 
                        headerTooltip: "Minutes in Month Availability",
                        filter: 'agNumberColumnFilter',
                        columnGroupShow: 'open',
                        width: column_width,
                        formType: "float",
                        defaultValue: 99.50,
                        newline: true
                    },
                    {
                        field: "minutes_in_month_availability_enabled",
                        headerName: "Active", 
                        headerTooltip: "Minutes in Month Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        columnGroupShow: 'open',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0
                    },
                    {
                        field: "latency",
                        headerName: "Latency", 
                        headerTooltip: "Latency Threshold",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        formType: "float",
                        defaultValue: 800.00,
                        columnGroupShow: 'open',
                        newline: true

                    },
                    {
                        field: "latency_enabled",
                        headerName: "Active", 
                        headerTooltip: "Latency Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        columnGroupShow: 'open',
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0
                    },
                    {
                        field: "packet_loss",
                        headerName: "Packet Loss", 
                        headerTooltip: "Packet Loss Threshold",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        formType: "float",
                        columnGroupShow: 'open',
                        defaultValue: 3.00,
                        newline: true
                    },
                    {
                        field: "packet_loss_enabled",
                        headerName: "Active", 
                        headerTooltip: "Packet Loss Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        columnGroupShow: 'open',
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0
                    },
                    {
                        field: "beam_switch_duration",
                        headerName: "Beam Switch Duration", 
                        headerTooltip: "Beam Switch Duration",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        columnGroupShow: 'open',
                        formType: "float",
                        defaultValue: 300.00,
                        newline: true
                       
                    },
                    {
                        field: "beam_switch_duration_enabled",
                        headerName: "Active", 
                        headerTooltip: "Beam Switch Duration Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        columnGroupShow: 'open',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0
                       
                    },
                ]
            },
            */
            {
                headerName: 'WISP Data',
                children: [/*
                     {
                        headerName: "Active", 
                        
                        width: column_width,
                        cellRenderer: ReportVisibilityCellRenderer,
                        columnGroupShow: 'closed',
                    },
                    */
                    {
                        field: "WISPSourceKey",
                        headerName: "WISP Source", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        hide: true,
                        
                        formType: "select",
                        service: new WISPSourceService(),
                        getOptionLabel: (option) => { return option.WISPSourceKey === 1?"(none)":(option.WISPSourceName ? option.WISPSourceName : "") },
                        renderOption: (option) =>  { return option.WISPSourceKey === 1?"(none)":(option.WISPSourceName ? option.WISPSourceName : "") },
                        getValue: (data) => { return {"WISPSourceKey": data.WISPSourceKey,"WISPSourceName":data.WISPSourceName}},
                        
                        
                        disabled: (data) => {return !data['OperatorKey']; },
                        helperText: "This value is only availalbe to customers who are GCS Operators."
                    },
                    {
                        field: "WISPSourceName",
                        headerName: "Wisp Source", 
                        headerTooltip: "Category",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                    },
                    /*
                    {
                        field: "kpbu_average_enabled",
                        headerName: "KPBU Active", 
                        headerTooltip: "KPBU Active", 
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: 0,
                    },
                    {
                        field: "session_data_enabled",
                        headerName: "Session Data Active", 
                        headerTooltip: "Session Data Active", 
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: 0,
                    },
                    {
                        field: "analytics_values",
                        headerName: "Analytics", 
                        headerTooltip: "Analytics",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        sortable: true,
                        resizable: true,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "analytics",
                        headerName: "Analytics", 
                        headerTooltip: "Analytics", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 3,
                        hide: true,
                        formType: "multiselect",
                        options: [ // see PAC_Data_Operator, tools/convert_gcs_sla_chart_analytics_settings.php
                            {id: 4,     analytics_sources: "BC HB and ISP"},
                            {id: 8,     analytics_sources: "Devices (KB)"},
                            {id: 16,    analytics_sources: "Devices (Kb)"},
                            {id: 64,    analytics_sources: "eXPhone GPRS and Calls"},
                            {id: 128,   analytics_sources: "eXPhone 3G"},
                            {id: 256,   analytics_sources: "eXPhone_3G_Sessions"},
                            {id: 4096,  analytics_sources: "eXTV Channels"},
                            {id: 8192,  analytics_sources: "GCS Drop-off"},
                            {id: 1,     analytics_sources: "Ground Checks"},
                            {id: 32,    analytics_sources: "Modem"},
                            {id: 2048,  analytics_sources: "PAC WISP Signups"},
                            {id: 2,     analytics_sources: "Ping Response"},
                            {id: 512,   analytics_sources: "PMT"},
                            {id: 16384, analytics_sources: "PMT Portal"},
                            {id: 1024,  analytics_sources: "WAP Radio Users"},
                            
                        ],
                        getOptionLabel: (option) => { return option.analytics_sources },
                        renderOption: (option) =>  { return option.analytics_sources },
                        getInitValue: (data, options) => {
                            var options_init = [];
                            var init_value = data.analytics;
                            if (init_value) {
                              var top_value = 65536;
                              while (top_value >= 1) {
                                if (init_value >= top_value) {
                                  options_init.push(options[options.findIndex(obj => obj.id === top_value)]);
                                  init_value = init_value - top_value;
                                }
                                top_value = top_value /2;
                              }
                            }
                            return options_init;
                        }
                    },
                    {
                        field: "analytics_enabled",
                        headerName: "Enabled", 
                        headerTooltip: "Analytics Active",
                        label: "Analytics Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: 0
                    },
                    {
                        field: "use_ground_latency",
                        headerName: "Use Ground latency", 
                        headerTooltip: "Use Ground latency",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: 0,
                        newline: true
                        
                    },
                    {
                        field: "use_flight_avg_in_calcs_enabled",
                        headerName: "Flight Average", 
                        headerTooltip: "Use Flight Average in Calculations",
                        Label: "Use Flight Average in Calculations",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: 0
                    },
                    {
                        field: "cir_overall",
                        headerName: "CIR Overall", 
                        headerTooltip: "CIR Overall",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 0,
                        newline: true,
                    },
                    {
                        field: "cir_forward",
                        headerName: "CIR Forward", 
                        headerTooltip: "CIR Forward",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        columnGroupShow: 'open',
                        defaultValue: 0,
                        formType: "integer"
                    },
                    {
                        field: "cir_return",
                        headerName: "CIR Return", 
                        headerTooltip: "CIR Return",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        columnGroupShow: 'open',
                        defaultValue: 0,
                        formType: "integer"
                    },
                     {
                        field: "cir_enabled",
                        headerName: "CIR Active", 
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: false
                        
                    },
                    {
                        field: "gcs_dashboard_chart_visibility_values",
                        headerName: "GCS Dashboard Chart Visibility", 
                        headerTooltip: "GCS Dashboard Chart Visibility",
                        filter: 'agTextColumnFilter',
                        width: column_width * 3,
                        columnGroupShow: 'open',
                        sortable: true,
                        resizable: true
                    },
                    {
                        field: "gcs_dashboard_chart_visibility",
                        headerName: "GCS Dashboard Chart Visibility", 
                        headerTooltip: "GCS Dashboard Chart Visibility",
                        filter: 'agTextColumnFilter',
                        hide: true,
                        width: column_width * 2,
                        formType: "multiselect",
                        options: [ // see PAC_Data_Operator 
                            {id: 1,     dashboard_visibility: "Availability by Beam"}, 
                            {id: 8,     dashboard_visibility: "eXPhone 3G"},  
                            {id: 16,    dashboard_visibility: "eXPhone 3G Availability"}, 
                            {id: 64,    dashboard_visibility: "eXTV Availability"},
                            {id: 2,     dashboard_visibility: "Raw Availability"}, 
                            {id: 128,   dashboard_visibility: "SLA Bucket"}, 
                            {id: 32,    dashboard_visibility: "Service Outages"}, //eXPHone 3G
                            {id: 4,     dashboard_visibility: "Usage by Beam"}, 
                        ],
                        getOptionLabel: (option) => { return option.dashboard_visibility },
                        renderOption: (option) =>  { return option.dashboard_visibility },
                        getInitValue: (data, options) => {
                            var options_init = [];
                            var init_value = data.gcs_dashboard_chart_visibility;
                            if (init_value) {
                              var top_value = 65536;
                              while (top_value >= 1) {
                                if (init_value >= top_value) {
                                  options_init.push(options[options.findIndex(obj => obj.id === top_value)]);
                                  init_value = init_value - top_value;
                                }
                                top_value = top_value /2;
                              }
                            }
                            return options_init;
                        }
                    },
                    {
                        field: "gcs_dashboard_chart_visibility_enabled",
                        headerName: "Active", 
                        headerTooltip: "GCS Dashboard Chart Visibility Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        columnGroupShow: 'open',
                        formType: "boolean",
                        defaultValue: 0
                    },
                    */
                    
                ]
            },
            {
                headerName: 'Exclusion Settings',
                children: [
                    {
                        field: "excludable_flight_duration",
                        headerName: "Excludable Flight Duration", 
                        filter: 'agNumberColumnFilter',
                        width: column_width * 1.5,
                        formType: "integer",
                        
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        newline: true,
                        
                    },
                    {
                        field: "non_revenue_flight",
                        headerName: "Non-Revenue Flight", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        formType: "multiline",
                        newline: true
                    },
                    {
                        field: "non_revenue_flight",
                        key: "non_revenue_flight2",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        hide: true,
                        formType: "regexTest",
                        newline: true
                    },
                    {
                        field: "test_flight",
                        headerName: "Test Flight", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        formType: "multiline",
                        newline: true
                    },
                ]
            },
            
             {
                headerName: 'SSH Certificate Settings',
                children: [
                    {
                        field: "security_code",
                        headerName: "Security Code", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        formType: "readonly"
                    },
                    {
                        field: "certificate_count",
                        headerName: "Certificate Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "readonly",
                        newline: true
                    },
                    {
                        field: "customer_security_expiration_date",
                        headerName: "Cert. Expiration Date", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        formType: "readonly"
                    },
                    {
                        field: "customer_security_update_date",
                        headerName: "Cert. Update Date", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        formType: "readonly",
                        newline: true
                    },
                    {
                        field: "customer_security_user_name",
                        headerName: "Cert. Updated By", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        formType: "readonly"
                    },
                ]
             }
             
        ];
     
        headers = headers.concat(headers2);
        
        return headers;
        
        
        /**** OLD LIST ****/
        
        /*
        return [
            {
                headerName: 'Customer',
                openByDefault: true, // column: header expanded
                defaultExpanded: true, // Form: initially expanded in form.  Use  expanded: true to make it always open.
                children: [
                    {
                        field: "id_operator",
                        headerName: "Customer ID", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        pinned: 'left',
                        formType: "readonly",
                        columnGroupShow: 'open'
                    },
                    {
                        field: "OperatorKey",
                        headerName: "GCS ID", 
                        headerTooltip: "GCS ID",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        pinned: 'left',
                        formType: "readonly",
                        columnGroupShow: 'open',
                    },
                    {
                        field: "operator_icao_code",
                        headerName: "ICAO", 
                        headerTooltip: "ICAO Code",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        cellRenderer: OperatorIcaoCodeCellRenderer,
                        pinned: 'left',
                        formType: "text",
                        newline: true
                    },
                    {
                        field: "operator_name",
                        headerName: "Customer Name", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 3,
                        pinned: 'left',
        
                        required: true,
                        formType: "text"
                    }
               ] 
            },
            {
                headerName: 'Details',
                defaultExpanded: true, //initially expanded in form.  Use  expanded: true to make it always open.
                
                children: [
                     {
                        field: "category_name",
                        headerName: "Category", 
                        headerTooltip: "Category",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        
                        // no formType so is hidden from the form but visible in the grid
                    },
                    {
                        field: "id_customer_category",
                        headerName: "Category",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        hide: true, //hides it from the grid but not the form.
                        
                        required: true,
                        formType: "select",
                        service: new CustomerCategoryService(),
                        getOptionLabel: (option) => { return option.category_name ? option.category_name : "" },
                        renderOption: (option) =>  { return option.category_name ? option.category_name : "" },
                        getValue: (data) => { return {"id_customer_category": data.id_customer_category,"category_name":data.category_name}},
                        newline: true,
                        
                    },
                    {
                        field: "oracle_id",
                        headerName: "Oracle ID", 
                        headerTooltip: "Oracle ID",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        
                        formType: "integer",
                        required: true,
                        
                        columnGroupShow: 'open',
                    },
                    {
                        field: "oracle_customer_id",
                        headerName: "Oracle Cust. ID", 
                        headerTooltip: "Oracle Customer ID",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        
                        required: true,
                        formType: "integer",
                        columnGroupShow: 'open'
                    },
                    {
                        field: "operator_iata_code",
                        headerName: "IATA", 
                        headerTooltip: "IATA Code",
                        filter: 'agTextColumnFilter',
                        width: column_width,

                        formType: "text",
                        columnGroupShow: 'open'
                        //helperText: "Three letter code for the operator"
                    },
                    {
                        field: "operator_carrier_code",
                        headerName: "Carrier", 
                        headerTooltip: "Carrier Code",
                        filter: 'agTextColumnFilter',
                        width: column_width,

                        formType: "text",
                        columnGroupShow: 'open'
                        //helperText: "Three letter code for the operator"
                    },
                    {
                        field: "id_country",
                        headerName: "Country", 
                        headerTooltip: "Country",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        hide: true,
                        
                        required: true,
                        formType: "select",
                        service: new CountryService(),
                        getOptionLabel: (option) => { return option.country_name ? option.country_name : "" },
                        renderOption: (option) =>  { return option.country_name ? option.country_name : "" },
                        getValue: (data) => { return {"id_country": data.id_country,"country_name":data.country_name}},
                        
                        newline: true,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "country_name",
                        headerName: "Country", 
                        headerTooltip: "Country",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "id_region",
                        headerName: "Region", 
                        headerTooltip: "Region",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        hide: true,
                        
                        required: true,
                        formType: "select",
                        service: new RegionService(),
                        getOptionLabel: (option) => { return option.name ? option.name : (option.region_name ? option.region_name :"" ) },
                        renderOption: (option) =>  { return option.name ? option.name : (option.region_name ? option.region_name :"" ) },
                        getValue: (data) => { return {"id_region": data.id_region,"region_name":data.region_name}},
                        columnGroupShow: 'open'
                    },
                    {
                        field: "region_name",
                        headerName: "Region", 
                        headerTooltip: "Region",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "operator_description",
                        headerName: "Description", 
                        filter: 'agNumberColumnFilter',
                        width: column_width * 3,
                        formType: "multiline",
                        newline: true,
                       
                        columnGroupShow: 'open'
                    },
                    {
                        field: "updated_date",
                        headerName: "Updated Date", 
                        headerTooltip: "Updated Date",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        formType: "readonly",                        
                        
                        newline: true,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "updated_by_name",
                        headerName: "Updated By", 
                       
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        formType: "readonly",
                        columnGroupShow: 'open'
                    },
                ],
            },
            {
                headerName: 'Aircraft',
                children: [
                    {   
                        field: "active_aircraft_count",
                        headerName: "Active A/C", 
                        headerTooltip: "Active Aircraft Count",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "readonly",
                        defaultValue: 0
                    },
                    {   
                        field: "live_aircraft_count",
                        headerName: "Live A/C", 
                        headerTooltip: "Live Aircraft Count",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "readonly",
                        defaultValue: 0,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "gcs_live_aircraft_count",
                        headerName: "GCS A/C", 
                        headerTooltip: "Total aircraft enabled for GCS",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "readonly",
                        defaultValue: 0,
                        columnGroupShow: 'open'
                    },
                    {   
                        field: "aircraft_count",
                        headerName: "Total A/C",
                        HelperText: "Total Aircraft Count, including inactive aircraft",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "readonly",
                        defaultValue: 0,
                        columnGroupShow: 'open'
                    }
                ],
            },
            {
                headerName: 'Load Script Overwrite Settings',
                headerTooltip: 'Load Script Overwrite Settings',
                children: [
                     {
                        field: "LS_Override_Active",
                        headerName: "Active",
                        headerTooltip: "Load Script Overwrite Active",
                        Label: "Load Script Overwrite Active",
                        
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        
                        defaultValue: false,
                    },
                    {
                        field: "LS_System_Restart_Count",
                        headerName: "Restart Count", 
                        helperText: "System Restart Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3,
                        
                        newline: true
                    },
                    {
                        field: "LS_Commanded_Reset_Count",
                        headerName: "Command Count", 
                        helperText: "Commanded Reset Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3
                    },
                    {
                        field: "LS_Fault_Count",
                        headerName: "Fault Count", 
                        helperText: "Fault Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3
                    },
                    {
                        field: "LS_Reboot_Count",
                        headerName: "Reboot Count", 
                        helperText: "Reboot Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3,
                        newline: true
                    },
                    {
                        field: "LS_Seat_App_Reset_Count",
                        headerName: "Seat App Reset Count", 
                        helperText: "Seat App Reset Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3
                    },
                    {
                        field: "LS_FMR_Count",
                        headerName: "FMR Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 3
                    },
                ]
            },
            {
                headerName: 'Exclusion Padding',
                headerTooltip: 'Exclusion Padding',
                children: [
                     {
                        headerName: "DT, DL, IT, IL, FPS", 
                        headerTooltip: "Domestic Takeoff Padding, Domestic Landing Padding, International Takeoff Padding, International Landing Padding, Flexi Pad Skipped",
                        filter: 'agTextColumnFilter',
                        width: column_width * 1.5,
                        cellRenderer: GCSExclusionPaddingRenderer,
                        columnGroupShow: 'closed',
                    },
                    {
                        field: "TAKEOFFPADDING",
                        headerName: "Dom. Takeoff", 
                        headerTooltip: "Domestic Takeoff Padding",
                        label: "Domestic Takeoff Padding", 
                        //helperText: 'Takeoff padding before SLA for domestic routes.',
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "integer",
                        defaultValue: 20,
                        newline: true,
                        columnGroupShow: 'open',
                        formHide: (data) => {return !data['OperatorKey']; },
                        
                    },
                    {
                        field: "LANDINGPADDING",
                        headerName: "Dom. Landing", 
                        headerTooltip: "Domestic Landing Padding",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "integer",
                        defaultValue: 30,
                        columnGroupShow: 'open',
                        formHide: (data) => {return !data['OperatorKey']; } 
                    },
                    {
                        field: "TAKEOFFPADDINGINT",
                        headerName: "Int. Takeoff", 
                        headerTooltip: "International Takeoff Padding",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "integer",
                        defaultValue: 20,
                        columnGroupShow: 'open',
                        formHide: (data) => {return !data['OperatorKey']; } 
                    },
                    {
                        field: "LANDINGPADDINGINT",
                        headerName: "Int. Landing", 
                        headerTooltip: "International Landing Padding",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "integer",
                        defaultValue: 30,
                        columnGroupShow: 'open',
                        formHide: (data) => {return !data['OperatorKey']; } 
                    },
                    {
                        field: "FLEXIPADSKIPPED",
                        headerName: "Flexi Pad Skipped", 
                        headerTooltip: "Flexi Pad Skipped Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0,
                        columnGroupShow: 'open',
                        formHide: (data) => {return !data['OperatorKey']; } 
                    },
                    {
                        hide: true,  
                        formType: "content",
                        formContent: "These parameters are not available.",
                        formHide: (data) => {return data['OperatorKey']; } 
                    },
                ]
            },
            {
                headerName: 'SLA Metric Settings',
                children: [
                     {
                        headerName: "Active", 
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        cellRenderer: SLAMetricSettingsCellRenderer,
                        columnGroupShow: 'closed',
                    },
                    {
                        field: "flight_time_availability",
                        headerName: "Flight Time Avail.", 
                        headerTooltip: "Flight Time Availability",
                        filter: 'agNumberColumnFilter',
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        columnGroupShow: 'open',
                        width: column_width,
                        formType: "float",
                        defaultValue: 95.00
                    },
                    {
                        field: "flight_time_availability_enabled",
                        headerName: "Active", 
                        headerTooltip: "Flight Time Availability Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        columnGroupShow: 'open',
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0
                    },
                    {
                        field: "minutes_in_month_availability",
                        headerName: "Minutes in Month Avail.", 
                        headerTooltip: "Minutes in Month Availability",
                        filter: 'agNumberColumnFilter',
                        columnGroupShow: 'open',
                        width: column_width,
                        formType: "float",
                        defaultValue: 99.50,
                        newline: true
                    },
                    {
                        field: "minutes_in_month_availability_enabled",
                        headerName: "Active", 
                        headerTooltip: "Minutes in Month Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        columnGroupShow: 'open',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0
                    },
                    {
                        field: "latency",
                        headerName: "Latency", 
                        headerTooltip: "Latency Threshold",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        formType: "float",
                        defaultValue: 800.00,
                        columnGroupShow: 'open',
                        newline: true

                    },
                    {
                        field: "latency_enabled",
                        headerName: "Active", 
                        headerTooltip: "Latency Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        columnGroupShow: 'open',
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0
                    },
                    {
                        field: "packet_loss",
                        headerName: "Packet Loss", 
                        headerTooltip: "Packet Loss Threshold",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        formType: "float",
                        columnGroupShow: 'open',
                        defaultValue: 3.00,
                        newline: true
                    },
                    {
                        field: "packet_loss_enabled",
                        headerName: "Active", 
                        headerTooltip: "Packet Loss Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        columnGroupShow: 'open',
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0
                    },
                    {
                        field: "beam_switch_duration",
                        headerName: "Beam Switch Duration", 
                        headerTooltip: "Beam Switch Duration",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        columnGroupShow: 'open',
                        formType: "float",
                        defaultValue: 300.00,
                        newline: true
                       
                    },
                    {
                        field: "beam_switch_duration_enabled",
                        headerName: "Active", 
                        headerTooltip: "Beam Switch Duration Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        columnGroupShow: 'open',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0
                       
                    },
                ]
            },
            
            {
                headerName: 'SLA Report Visibility',
                children: [
                    
                     {
                        headerName: "Active", 
                        
                        width: column_width,
                        cellRenderer: ReportVisibilityCellRenderer,
                        columnGroupShow: 'closed',
                    },
                    
                    {
                        field: "WISPSourceKey",
                        headerName: "WISP Source", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        hide: true,
                        
                        formType: "select",
                        service: new WISPSourceService(),
                        getOptionLabel: (option) => { return option.WISPSourceKey === 1?"(none)":(option.WISPSourceName ? option.WISPSourceName : "") },
                        renderOption: (option) =>  { return option.WISPSourceKey === 1?"(none)":(option.WISPSourceName ? option.WISPSourceName : "") },
                        getValue: (data) => { return {"WISPSourceKey": data.WISPSourceKey,"WISPSourceName":data.WISPSourceName}},
                        
                        
                        disabled: (data) => {return !data['OperatorKey']; },
                        helperText: "This value is only availalbe to customers who are GCS Operators."
                    },
                    {
                        field: "WISPSourceName",
                        headerName: "Wisp Source", 
                        headerTooltip: "Category",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                    },
                    
                    {
                        field: "kpbu_average_enabled",
                        headerName: "KPBU Active", 
                        headerTooltip: "KPBU Active", 
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: 0,
                    },
                    {
                        field: "session_data_enabled",
                        headerName: "Session Data Active", 
                        headerTooltip: "Session Data Active", 
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: 0,
                    },
                    {
                        field: "analytics_values",
                        headerName: "Analytics", 
                        headerTooltip: "Analytics",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        sortable: true,
                        resizable: true,
                        columnGroupShow: 'open'
                    },
                    {
                        field: "analytics",
                        headerName: "Analytics", 
                        headerTooltip: "Analytics", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 3,
                        hide: true,
                        formType: "multiselect",
                        options: [ // see PAC_Data_Operator, tools/convert_gcs_sla_chart_analytics_settings.php
                            {id: 4,     analytics_sources: "BC HB and ISP"},
                            {id: 8,     analytics_sources: "Devices (KB)"},
                            {id: 16,    analytics_sources: "Devices (Kb)"},
                            {id: 64,    analytics_sources: "eXPhone GPRS and Calls"},
                            {id: 128,   analytics_sources: "eXPhone 3G"},
                            {id: 256,   analytics_sources: "eXPhone_3G_Sessions"},
                            {id: 4096,  analytics_sources: "eXTV Channels"},
                            {id: 8192,  analytics_sources: "GCS Drop-off"},
                            {id: 1,     analytics_sources: "Ground Checks"},
                            {id: 32,    analytics_sources: "Modem"},
                            {id: 2048,  analytics_sources: "PAC WISP Signups"},
                            {id: 2,     analytics_sources: "Ping Response"},
                            {id: 512,   analytics_sources: "PMT"},
                            {id: 16384, analytics_sources: "PMT Portal"},
                            {id: 1024,  analytics_sources: "WAP Radio Users"},
                            
                        ],
                        getOptionLabel: (option) => { return option.analytics_sources },
                        renderOption: (option) =>  { return option.analytics_sources },
                        getInitValue: (data, options) => {
                            var options_init = [];
                            var init_value = data.analytics;
                            if (init_value) {
                              var top_value = 65536;
                              while (top_value >= 1) {
                                if (init_value >= top_value) {
                                  options_init.push(options[options.findIndex(obj => obj.id === top_value)]);
                                  init_value = init_value - top_value;
                                }
                                top_value = top_value /2;
                              }
                            }
                            return options_init;
                        }
                    },
                    {
                        field: "analytics_enabled",
                        headerName: "Enabled", 
                        headerTooltip: "Analytics Active",
                        label: "Analytics Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: 0
                    },
                    {
                        field: "use_ground_latency",
                        headerName: "Use Ground latency", 
                        headerTooltip: "Use Ground latency",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: 0,
                        newline: true
                        
                    },
                    {
                        field: "use_flight_avg_in_calcs_enabled",
                        headerName: "Flight Average", 
                        headerTooltip: "Use Flight Average in Calculations",
                        Label: "Use Flight Average in Calculations",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: 0
                    },
                    {
                        field: "cir_overall",
                        headerName: "CIR Overall", 
                        headerTooltip: "CIR Overall",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        columnGroupShow: 'open',
                        formType: "integer",
                        defaultValue: 0,
                        newline: true,
                    },
                    {
                        field: "cir_forward",
                        headerName: "CIR Forward", 
                        headerTooltip: "CIR Forward",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        columnGroupShow: 'open',
                        defaultValue: 0,
                        formType: "integer"
                    },
                    {
                        field: "cir_return",
                        headerName: "CIR Return", 
                        headerTooltip: "CIR Return",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        columnGroupShow: 'open',
                        defaultValue: 0,
                        formType: "integer"
                    },
                     {
                        field: "cir_enabled",
                        headerName: "CIR Active", 
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        columnGroupShow: 'open',
                        defaultValue: false
                        
                    },
                    {
                        field: "gcs_dashboard_chart_visibility_values",
                        headerName: "GCS Dashboard Chart Visibility", 
                        headerTooltip: "GCS Dashboard Chart Visibility",
                        filter: 'agTextColumnFilter',
                        width: column_width * 3,
                        columnGroupShow: 'open',
                        sortable: true,
                        resizable: true
                    },
                    {
                        field: "gcs_dashboard_chart_visibility",
                        headerName: "GCS Dashboard Chart Visibility", 
                        headerTooltip: "GCS Dashboard Chart Visibility",
                        filter: 'agTextColumnFilter',
                        hide: true,
                        width: column_width * 2,
                        formType: "multiselect",
                        options: [ // see PAC_Data_Operator 
                            {id: 1,     dashboard_visibility: "Availability by Beam"}, 
                            {id: 8,     dashboard_visibility: "eXPhone 3G"},  
                            {id: 16,    dashboard_visibility: "eXPhone 3G Availability"}, 
                            {id: 64,    dashboard_visibility: "eXTV Availability"},
                            {id: 2,     dashboard_visibility: "Raw Availability"}, 
                            {id: 128,   dashboard_visibility: "SLA Bucket"}, 
                            {id: 32,    dashboard_visibility: "Service Outages"}, //eXPHone 3G
                            {id: 4,     dashboard_visibility: "Usage by Beam"}, 
                        ],
                        getOptionLabel: (option) => { return option.dashboard_visibility },
                        renderOption: (option) =>  { return option.dashboard_visibility },
                        getInitValue: (data, options) => {
                            var options_init = [];
                            var init_value = data.gcs_dashboard_chart_visibility;
                            if (init_value) {
                              var top_value = 65536;
                              while (top_value >= 1) {
                                if (init_value >= top_value) {
                                  options_init.push(options[options.findIndex(obj => obj.id === top_value)]);
                                  init_value = init_value - top_value;
                                }
                                top_value = top_value /2;
                              }
                            }
                            return options_init;
                        }
                    },
                    {
                        field: "gcs_dashboard_chart_visibility_enabled",
                        headerName: "Active", 
                        headerTooltip: "GCS Dashboard Chart Visibility Active",
                        label: "Active",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        columnGroupShow: 'open',
                        formType: "boolean",
                        defaultValue: 0
                    },
                    
                    
                ]
            },
            
            {
                headerName: 'Exclusion Settings',
                children: [
                    {
                        field: "excludable_flight_duration",
                        headerName: "Excludable Flight Duration", 
                        filter: 'agNumberColumnFilter',
                        width: column_width * 1.5,
                        formType: "integer",
                        
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        newline: true,
                        
                    },
                    {
                        field: "non_revenue_flight",
                        headerName: "Non-Revenue Flight", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        formType: "multiline",
                        newline: true
                    },
                    {
                        field: "non_revenue_flight",
                        key: "non_revenue_flight2",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        hide: true,
                        formType: "regexTest",
                        newline: true
                    },
                    {
                        field: "test_flight",
                        headerName: "Test Flight", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        formType: "multiline",
                        newline: true
                    },
                ]
            },
            
             {
                headerName: 'SSH Certificate Settings',
                children: [
                    {
                        field: "security_code",
                        headerName: "Security Code", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        formType: "readonly"
                    },
                    {
                        field: "certificate_count",
                        headerName: "Certificate Count", 
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                            if (valueA === valueB) return 0;
                            return (valueA > valueB) ? 1 : -1;
                        },
                        formType: "readonly",
                        newline: true
                    },
                    {
                        field: "customer_security_expiration_date",
                        headerName: "Cert. Expiration Date", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        formType: "readonly"
                    },
                    {
                        field: "customer_security_update_date",
                        headerName: "Cert. Update Date", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        formType: "readonly",
                        newline: true
                    },
                    {
                        field: "customer_security_user_name",
                        headerName: "Cert. Updated By", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        formType: "readonly"
                    },
                ]
             }
        ];
        */
     }
}


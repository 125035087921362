import React, { useState, useContext }  from 'react';
import _                    from 'lodash';
import Tooltip              from '@material-ui/core/Tooltip';
import IconButton           from '@material-ui/core/IconButton';
import IconFavorite         from '@material-ui/icons/Favorite';
import IconNotFavorite      from '@material-ui/icons/FavoriteBorder';

import AppContext           from 'pac-responsive-ui-framework/core/AppContext';


export default function IconButtonFavorite(props) {
    
    var app_context = useContext(AppContext);
    console.log(app_context);
    let icon_favorite = <IconNotFavorite fontSize="inherit" />
    let tooltip = "Set as a favorite report";
    if (props.favorite) {
        icon_favorite = <IconFavorite fontSize="inherit" />
        tooltip = "Unset as a favorite report";
    }
    
    return (
        <Tooltip title={tooltip} placement="bottom-start">
          <IconButton aria-label="favorite" onClick={(e) => app_context.toggleFavoriteReport(props.title)}> 
              {icon_favorite}
          </IconButton>
      </Tooltip>
    );
}

/*
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
*/

import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import '@elastic/eui/dist/eui_theme_light.css';
import './assets/index.css';

import { runKeepAlive } from './config/CognitoKeepAlive';
//import AmpConfig from './config/amplify-configure.js';
import App from './App';

//import './config/amplify-configure.js';

//import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router } from 'react-router-dom';


/* Not using Redux at this time
import { Provider } from "react-redux";

import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware, compose} from 'redux';

import reducer from './reducers';

console.log("index.js");

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    composeEnhancers(
        applyMiddleware(
            thunkMiddleware,
            // loggerMiddleware,
        )));
*/


runKeepAlive();

if (process.env.NODE_ENV !== 'development') {
    window.LOG_LEVEL = 'DEBUG';
}
// see https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
const root = createRoot(document.getElementById('root')); 
root.render(<Router><App /></Router>);

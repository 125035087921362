import BaseService from './BaseService';
import { Logger } from 'aws-amplify';
import config from '../config/config.js';

const logger = new Logger('TeleportBeamMappingService');
var ClientOAuth2 = require('client-oauth2');

export default class TeleportBeamMappingService extends BaseService {

    constructor() {

        let apiName = 'OriginsAPIService';
        let collectionPath = '/services/teleportbeammapping/v1';
        super(apiName, collectionPath);
    }

    async getAuthorizationHeader() {
        logger.debug("getAuthorizationHeader fetchRecords", this.getEndPoint().OAuth2);
        var githubAuth = new ClientOAuth2(this.getEndPoint().OAuth2);
       
        
        var token_data = await githubAuth.credentials.getToken()
         
          .catch(error => {
              logger.debug("getAuthorizationHeader fetchRecords error", error);
          });
          
        return token_data.accessToken;
    }
    
    getEndPoint() {
        var endpoint;
        config.API.endpoints.forEach(ep => {
            logger.debug("fetchRecords", ep, this.apiName);
            if (ep.name === this.apiName) {
                logger.debug("endpoint found", ep);
                endpoint = ep;
            }
        });
        logger.debug("endpoint", endpoint);
        return endpoint;
    }
}

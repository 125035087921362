//import FilterDateTime from '../components/DataGrid/FilterDateTime';
import { Logger } from 'aws-amplify';
const logger = new Logger('DataColumns');

var sec2hmsFormatter = (params) => {
    logger.debug('sec2hmsFormatter', params);
    var newval = '';
    if (params.value < 0) {
        params.value = -params.value;
        newval ='-';
    }
    var seconds = (params.value % 60).toString().padStart(2, "0");
    var minutes = (Math.floor(params.value / 60) % 60).toString().padStart(2, "0");
    var hours = Math.floor(params.value / 3600).toString();
    return newval + hours + ':' + minutes + ':' + seconds;
}

const data_columns = {
     operator_icao_code: {
        headerName: "ICAO Code", 
        filter: 'agTextColumnFilter',
        width: "110",
        parentHeaderName: "Operator",
        cellRenderer: 'operatorIcaoCodeCellRenderer',
        pinned: "right"
    }, 
    operator_name: {
        headerName: "Name", 
        columnGroupShow: 'open',
        parentHeaderName: "Operator",
        pinned: "right"
    }, 
    registration_name: {
        headerName: "Registration", 
        filter: 'agTextColumnFilter',
        parentHeaderName: "Aircraft",
        width: "110",
        pinned: "right",
       
    }, 
    type_subtype:
    {
        headerName: "Type-subtype", 
        filter: 'agTextColumnFilter',
        parentHeaderName: "Aircraft",
        columnGroupShow: 'open',
        width: "140",
        pinned: "right"
    }, 
    system:
    {
        headerName: "System Type", 
        filter: 'agTextColumnFilter',
        parentHeaderName: "System",
    }, 
    build: {
        headerName: "Build", 
        field: "build",
        columnGroupShow: 'open',
        width: "90",
        filter: 'agTextColumnFilter',
        parentHeaderName: "System",
       
    }, 
    build_series:
    {
        headerName: "Build Series", 
        columnGroupShow: 'open',
        width: "90",
        filter: 'agTextColumnFilter',
        parentHeaderName: "System",
       
    },
    gcs_antenna_type_name: {
        headerName: "Antenna", 
        columnGroupShow: 'open',
        width: "90",
        filter: 'agTextColumnFilter',
        parentHeaderName: "System",
        valueFormatter: nullFormatter,
       
    },
    aircraft_bc_gen: {
        headerName: "BC", 
        valueFormatter: nullFormatter,
        columnGroupShow: 'open',
        width: "90",
        parentHeaderName: "System"
    },
    flight_number: {
        headerName: "Flight Number", 
        filter: 'agTextColumnFilter',
        parentHeaderName: "Flight Details",
         width: "90",
    },
    departure_city: {
        headerName: "Departure Airport", 
        filter: 'agTextColumnFilter',
        parentHeaderName: "Flight Details",
        width: "80",
       
    },
    arrival_city: {
        headerName: "Arrival Airport", 
        filter: 'agTextColumnFilter',
        parentHeaderName: "Flight Details",
        
        width: "80"
    },
    departure_time: {
        headerName: "Departure Time", 
        filter: 'FilterDateTime',
        parentHeaderName: "Flight Details",
        width: "140",
        pinned: true,
        
    },
    arrival_time: {
        headerName: "Arrival Time", 
        filter: 'agDateColumnFilter',
        parentHeaderName: "Flight Details",
        width: "140",
       
    },
    id_file_set: {
        headerName: "File Set ID", 
        cellRenderer: 'agGroupCellRenderer',
        filter: 'agNumberColumnFilter',
        parentHeaderName: "Flight Details",
        width: "100",
        pinned: true,
        type: "rightAligned",
        suppressMenu: true,
    },
    flight_duration_secs: {
        headerName: "Flight Time (s)", 
        filter: 'agTextColumnFilter',
        parentHeaderName: "Flight Details",
        width: "130",
        type: "numericColumn",
        columnGroupShow: 'open'
    },
    flight_duration_secs2: {
        headerName: "Flight Time (h:m:s)", 
        filter: 'agNumberColumnFilter',
        parentHeaderName: "Flight Details",
        valueFormatter: sec2hmsFormatter,
        width: "150",
        type: "numericColumn",
        columnGroupShow: 'open',
        key: 'flight_duration_secs'
    },
    total_seat_count: {
        headerName: "Seat Count", 
            filter: 'agNumberColumnFilter',
            parentHeaderName: "Flight Details",
            width: "80",
            type: "numericColumn",
            columnGroupShow: 'open',
    },
    id_archive: {
        headerName: "Archive ID", 
            filter: 'agNumberColumnFilter',
            parentHeaderName: "Flight Details",
            width: "100",
            type: "numericColumn",
            columnGroupShow: 'open',
    },
    impacted_seat_count_seat_health_seat_percent: {
        headerName: "Seat Impact", 
        filter: 'percentColumnFilter',
        parentHeaderName: "Seats",
        width: "110",
        type: "numericColumn"
    },
    impacted_seat_count_seat_health_seat_percent: {
        headerName: "Impact (%)", 
        filter: 'agNumberColumnFilter',
        parentHeaderName: "Seats",
        width: "110",
        type: "numericColumn",
        valueFormatter: percentFormatter
    },
    system_restart: {
        headerName: "Raw Count", 
        filter: 'agNumberColumnFilter',
        parentHeaderName: "Restarts",
        width: "110",
        type: "numericColumn",
        columnGroupShow: 'open',
    },
    restarts_system_health: {
        headerName: "Count", 
        filter: 'agNumberColumnFilter',
        parentHeaderName: "Restarts",
        width: "110",
        type: "numericColumn"
    },
     reboots_total: {
        headerName: "Raw Count", 
        filter: 'agNumberColumnFilter',
        parentHeaderName: "Reboots",
        width: "110",
        type: "numericColumn",
        columnGroupShow: 'open',
    },
    reboots_system_health: {
        headerName: "Count", 
        filter: 'agNumberColumnFilter',
        parentHeaderName: "Reboots",
        width: "110",
        type: "numericColumn"
    }
}

function getColumns(column_list) {
    if (column_list) {
        
    }
    else return data_columns;
}

function nullFormatter(params) {
    if (!params.value) params.value = "...";
    return params.value;
}



function percentFormatter(params) {
    if (!params.value) {
        return '...';
    }
  return ((params.value * 100));
}

const formatData = (data, columns) => {
    var column_keys = {}
    var found = false;
    for (let [key, value] of Object.entries(columns)) {
        if (value['key']) {
            column_keys[key] = {
                'key': value['key'],
                'valueFormatter': value['valueFormatter']
            }
            found = true;
        }
    };
    if (found) {
        logger.debug(column_keys);
        for(let i = 0 ; i < data.length; i++) {
            for (let [key, value] of Object.entries(column_keys)) {
                logger.debug('looping', key, value);
                var new_value = data[i][value.key]
                if (typeof value.valueFormatter === "function") {
                    logger.debug('is a function');
                    new_value = value.valueFormatter({value:  data[i][value.key]});
                }
                data[i][key] = new_value;
            }
        };
    }
    return data;
}

export { getColumns, formatData }
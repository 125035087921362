import Auth from './auth.js';
import API from './api.js';
import Analytics from './analytics.js';
import Storage from './storage.js'
let config = {
  Auth: Auth,
  API: API,
  Analytics: Analytics,
  Storage: Storage
};

export default config;

import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/icons/OpenInNew';

export default function IconButtonNewWindow(props) {
    
     return (
        <Tooltip title="Click here to open the report in a new window" placement="bottom-start">
           <a 
            href={props.href} 
            target="_blank"
            rel="noopener noreferrer"
            color="inherit" 
            style={{textDecoration: 'none' }}
            component="button" 
            aria-label="open in new window" 
            onClick={(event) => {event.stopPropagation()}}
          
          >
          <IconButton aria-label="open in new window"> 
              <Icon fontSize="inherit" />
          </IconButton>
          </a>
      </Tooltip>
      );
}
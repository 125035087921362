import _ from 'lodash';
import BaseService from './BaseService';
import { Logger } from 'aws-amplify';
import BooleanCellRenderer from '../components/AGGrid/BooleanCellRenderer';
import UserService            from './UserService';
const logger = new Logger('SLAExclusionParamService');

export default class SLAExclusionParameter extends BaseService {

    constructor(state) {
        logger.debug("constructor", state);
        
        let apiName = 'OriginsAPI';
        let collectionPath = '/slaexclusionparameter';
        
        super(apiName, collectionPath);
        this.state = state;
    }
    
    formatRowData(response) {
         var data = response.data;
         //todo: look up username and set the name in the data
         return data;
    }
     
    getMetaData(data) {
        return data.meta;
    }
    
    async save(data, metaData) {
       
        //todo: look up userID and set the value for RowModifiedByKey
        data['UpdatedByEmail'] = _.get(this.state, 'user.session.email', null);
        logger.debug("save", data, metaData);
        var response = null;
        if (data['ExclusionParameterKey']) { //update
            await this.update(data['ExclusionParameterKey'], data)
                .then((results) => {
                    response = results;
                    logger.debug("save: update record", results);
                })
                .catch((err) => {
                    response = err;
                    logger.debug('save: update record failed', err);
                });
        }
        else { //insert
            await this.add(data)
                .then((results) => {
                    response = results;
                    logger.debug("save: insert record", results);
                })
                .catch((err) => {
                    response = err;
                    logger.debug('save: insert record failed', err);
                });
        }
        return response;
    }
    
    getColumnDefs() {
        var column_width = 100;

        return [{
            headerName: 'Details',
            defaultExpanded: true,
            children: [
                  {
                    field: "RowActiveFlag",
                    headerName: "Enabled",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: 1
                },
                {
                    field: "ExclusionParameterKey",
                    headerName: "Key",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    formType: "readonly",
                    newline: true
                },
                {
                    field: "ExclusionParameterCode",
                    headerName: "Code",
                    filter: 'agTextColumnFilter',
                    width: column_width*2,
                    formType: "text",
                    required: true,
                },
                {
                    field: "ExclusionParameterName",
                    headerName: "Name",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    formType: "text",
                    required: true,
                },
                {
                    field: "ExclusionParameterDesc",
                    headerName: "Description",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    formType: "multiline"
                },
                {
                    field: "DefaultConfigValue",
                    headerName: "Default Value",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    formType: "text",
                    required: true,
                    defaultValue: 0
                }
            ]
        },
        {
            headerName: 'Update Details',
            children: [
                {
                    field: "RowCreatedDate",
                    headerName: "Creation Date",
                    filter: 'agTextColumnFilter',
                    width: column_width*2,
                    formType: "readonly",
                    newline: true
                },
                {
                    field: "RowModifiedDate",
                    headerName: "Last Modified Date",
                    filter: 'agTextColumnFilter',
                    width: column_width*2,
                    formType: "readonly",
                    newline: true
                },
                {
                    field: "RowModifiedByEmail",
                    headerName: "Modified By",
                    filter: 'agTextColumnFilter',
                    width: column_width*2,
                    disabled: true,
                    formType: "text"
                },
            ]
        }]
    }
}

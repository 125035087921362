import BaseService from './BaseService';

export default class AlertSubscriptionService extends BaseService {

    constructor() {

        let apiName = 'OriginsAPI';
        let collectionPath = '/alertsubscription';
        super(apiName, collectionPath);
    }
}

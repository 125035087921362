
/* global URLSearchParams */
import React, {useContext} from 'react';
import { Logger } from 'aws-amplify';
import Home from '../views/Home';
import Report from '../views/Report';


//import AppContext from 'pac-responsive-ui-framework/core/AppContext';

import { 
 // BrowserRouter as Router, 
//  Link,  
//  useParams,

  Switch, 
  Route, 
  useRouteMatch,
  useLocation
} from 'react-router-dom';


const logger = new Logger('RouterReport');


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export default function RouterReport(props) {
  let match = useRouteMatch();
  let query = useQuery();
  
  /* this was moved to View
  var app_context = useContext(AppContext);
  const operator = query.get("operator");
  logger.debug(operator, app_context);
  if (operator && operator != app_context.state.default_operator_icao_code) {
    app_context.updateOperator(operator);
  }
  */

  return (
      <Switch>
        <Route path={`${match.path}/:reportId`}>
          <Report edit={query.get("edit")} operator={props.default_operator_icao_code} />
        </Route>
        <Route path={match.path}>
          <Home />
        </Route>
      </Switch>


  );
}

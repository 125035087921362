import ReactDOMServer from 'react-dom/server';
import { getIcon } from '../../data/Operators';

export default class operatorIcaoCodeCellRenderer {
  
    init(params) {
        if (params.value) {
            console.log('operatorIcaoCodeCellRenderer.init', params);
            this.eGui = document.createElement('span');
            if (params.value !== "" || params.value !== undefined || params.value !== null) {
                var icon = ReactDOMServer.renderToStaticMarkup(getIcon(params.value));
            
                this.eGui.innerHTML = icon + ' ' + params.value;
            }
        }
    };   

    getGui() {
        return this.eGui;
    };
}
import React, { useContext }    from 'react';
import { Logger }           from 'aws-amplify';
import { makeStyles }       from '@material-ui/core/styles';
import Box                  from '@material-ui/core/Box';
import Typography           from '@material-ui/core/Typography';
import Grid                 from '@material-ui/core/Grid';
import CardReport           from './CardReport';

//import AppContext           from 'pac-responsive-ui-framework/core/AppContext';

const logger = new Logger('ReportGrid');

const useStyles = makeStyles(theme => ({
  link: {
    margin: theme.spacing(2),
    
  },
  root: {
    flexGrow: 1,
  },

  
}));


export default function ReportGrid(props) {
    
    const classes = useStyles();

    var show_subtitles = true;
    if (typeof props.showSubtitles !== 'undefined') show_subtitles = props.showSubtitles;    

   

    if (props.report_list.length) {
        return (
            <div className={classes.root} >
                <Box mt={1} mb={1} >
                    <Typography variant="h5" component="h5">
                        {props.title}
                    </Typography>
                </Box>
                <Grid 
                    container 
                    spacing={2}
                    direction="row"
                    //justify="space-between"
                    alignItems="stretch"
                >
                    {props.report_list.map(function(report_details, index) {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index} >
                                <CardReport 
                                    title={report_details.title} 
                                    subtitle={report_details.subtitle} 
                                    href={report_details.href}
                                    docs_href={report_details.docs_href}
                                    target={report_details.target}
                                    image={report_details.image}
                                    customer_icon={report_details.customer_icon}
                                    tags={report_details.tags}
                                    report_environments={report_details.report_environments}
                                    report_type={report_details.report_type}
                                    showSubtitle={show_subtitles}
                                    favorite={report_details.favorite}
                                    default_operator={report_details.default_operator}
                                    size= {props.size}
                                />
                            </Grid>);
                        })
                    }
                </Grid>
            </div>
        );
    }
    else {
        return null;
    }
}

import React, { useState, useContext }  from 'react';
import { Logger }                   from 'aws-amplify';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import FormContext      from './FormContext';
const logger = new Logger('forms/Select');

export default function Select(props) {
    
    logger.debug('Props', props);
    
     var form_context = useContext(FormContext);
    
    const [loading, setLoading] = useState(false);
    const [dataArray, setDataArray] = useState([]);
    const [value, setValue] = useState(props.value);
    
    React.useEffect(() => {
        if (props.load) {
            setLoading(true);
            const service = new props.service();
            logger.debug("useEffect", props.label);  
            service.fetchRecords(props.filter)
                .then((data) => {
                    logger.debug("fetchRecords", props.label, data, props.value);  
                    setDataArray(data['data']);
                    setLoading(false);
                    var value_found = false;
                    for(let i = 0 ; i < data['data'].length; i++) {
                        //logger.debug(data['data'][i][props.valueKey], props.value);  
                        if (data['data'][i][props.valueKey] === props.value) {
                            setValue(data['data'][i]);
                            value_found= true;
                        }
                    } 
                    if (!value_found) setValue(null);
                }).catch((err) => {
                   //setValue({operator_icao_code: '---', name: 'Operator Error'})
            });
        }
        else {
            setDataArray([]);
        }
   }, [props]);
    
     
   // logger.debug('operator', operator);
   
    const updateValue = (event, value, reason) => {
       setValue(value);
       form_context.updateState(props.valueKey, event, value, reason)
   }
   
    return (
        <Autocomplete
        autoComplete={true}
        options={dataArray}
        getOptionLabel={props.getOptionLabel}
        id="debug"
        loading={loading}
        noOptionsText={props.noOptionsText?props.noOptionsText:"No Options"}
        style={{ width: 400 }}
        value = {value}
        renderInput={(params) => <TextField {...params} label={props.label} margin="normal" />}
        onChange={updateValue}
      />
      )
    
}
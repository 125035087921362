import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    overrides: {
        
        MuiListItemIcon: {
            root: {
                color: "rgb(24, 74, 117)",
                maxWidth: "34px",
                minWidth: "34px"
            }
        },
        MuiListItem: {
            root: {
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                color: "rgb(24, 74, 117)",
                
            }
        }
    },
    custom: {
        DrawerNavMenu: {
            width: 400
        }
    }
});


export default function Drawer(props) {
    return (  
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )
}
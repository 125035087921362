 /**
  * @experimental
  * 
  * This tests the functionality of the grid component
  */

import React, { useState } from 'react';
import { Logger } from 'aws-amplify';
import View from 'pac-responsive-ui-framework/core/View';
import AGGrid from '../components/AGGrid';
import Service from '../services/AircraftService';
import OperatorFilter from '../components/FormFilters/Operator';
//import AppContext       from 'pac-responsive-ui-framework/core/AppContext';
 
const logger = new Logger('OpsAircraft');


export default function OpsAircraft(props) {
    //const app_context = useContext(AppContext);
    //logger.debug("app_context", app_context);
     const service = new Service();
    const [columnDefs, setColumnDefs] = useState(false);
    var init_state = {};
    //if (app_context.state && app_context.state.default_operator_icao_code !== "---") {
    //    init_state = {operator: {operator_icao_code: app_context.state.default_operator_icao_code}};
    //}
    
    const [state, setState]= useState(init_state);
   
    
    const headerCallback = (state) => {
        logger.debug("headerCallback", state, typeof state.operator === "object");
        setState(state);
    };
     
    logger.debug("columnDefs", columnDefs);
    if (columnDefs === false)
        service.getColumnDefs().then((data) => {
            setColumnDefs(data);
        });
    if (columnDefs) {
        var query = null;
        if (state.operator && state.operator.operator_icao_code && state.operator.operator_icao_code !== "---") {
            query = {operator_icao_code: state.operator.operator_icao_code};
        }
        
        return (
            <View title={props.title} fixedContent={true}>
                <AGGrid 
                    service={service} 
                    columnDefs={columnDefs} 
                    filename="aircraft_list" 
                    header=<OperatorFilter state={state} onChange={headerCallback}/> 
                    query={query} 
                    overlayNoRowsTemplate="Please select an operator using the upper left Data Filter."
                    runQuery={query?true:false} //typeof state.operator === "object"}
                />
            </View>
        );
    }
    else 
        return (
            <View title={props.title} fixedContent={true}>
            &nbsp;
            </View>
        );
 }
 
import BaseService from './BaseService';
import { Logger } from 'aws-amplify';

const logger = new Logger('service/OOOI');

class OOOISelectedEventRenderer {
    init(params) {
        console.log("OOOISelectedEventRenderer init", params, params.value, params.getValue());
        this.eGui = document.createElement('span');
        this.eGui.innerHTML = params.value || "";
        var column_parts  = params.colDef.field.split('_');
        if (params.data[column_parts[0] + '_' + column_parts[1] + '_selected_flight_event'] == true) {
            this.eGui.style.cssText = 'color: #008000;';
            //this.eGui.style.fontWeight = 'bold';
        
        }
    }
    getGui() {
        return this.eGui;
    }
}

class OOOIChosenEventRenderer {
    init(params) {
        console.log("OOOIChosenEventRenderer", params);
        this.eGui = document.createElement('span');
        var value = params.value|| "";
        if (value == "NULL") value = "";
        this.eGui.innerHTML = value;
         this.eGui.style.cssText = 'color: #008000;';
    }
    getGui() {
        return this.eGui;
    }
}


export default class OOOIService extends BaseService {

    constructor() {
        let apiName = 'OriginsAPI';
        let collectionPath = '/oooi';
        super(apiName, collectionPath);
    }
    
    getMetaData(data) {
        logger.debug("getMetaData", data);
        return data.meta;
    }
    
    formatRowData(data) {
        logger.debug('formatRowData1', data);
        var new_data = [];
       
        data.data.forEach(element => {
            var new_row = element.attributes;
            new_row['depart_airport'] = new_row['depart_airport_icao'] + ' (' + new_row['depart_airport_iata'] + ')';
            new_row['arrive_airport'] = new_row['arrive_airport_icao'] + ' (' + new_row['arrive_airport_iata'] + ')';
            if (element.attributes.oooi_events) {
                element.attributes.oooi_events.forEach(oooi => {
                    

                    //logger.debug('formatRowData', typeof oooi['event_date']);
                    new_row[oooi['event_source'] + '_' + oooi['event_type'] + '_' + 'event_date'] = oooi['event_date'] || "";
                   
                    new_row[oooi['event_source'] + '_' + oooi['event_type'] + '_' + 'flight_number'] = oooi['flight_number'];
                    new_row[oooi['event_source'] + '_' + oooi['event_type'] + '_' + 'item_created_date'] = oooi['item_created_date'];
                    
                    new_row[oooi['event_source'] + '_' + oooi['event_type'] + '_' + 'depart_airport'] = oooi['depart_airport_icao'] + ' (' + oooi['depart_airport_iata'] + ')';
                    new_row[oooi['event_source'] + '_' + oooi['event_type'] + '_' + 'arrive_airport'] = oooi['arrive_airport_icao'] + ' (' + oooi['arrive_airport_iata'] + ')';
                    if (oooi['selected_flight_event'] == true) {
                        new_row[oooi['event_source'] + '_' + oooi['event_type'] + '_' + 'selected_flight_event'] = oooi['selected_flight_event'];
                        new_row[oooi['event_type'] + "_source"] = "Source: " + oooi['event_source'];
                        
                        if (oooi['event_source'] == 'PAC') { // estimated time
                            new_row[oooi['event_source'] + '_' + oooi['event_type'] + '_' + 'event_date']  += "*";
                            if (oooi['event_type'] == "OUT") new_row["out_gate_date"] += "*";
                            if (oooi['event_type'] == "OFF") new_row["takeoff_date"] += "*";
                            if (oooi['event_type'] == "ON") new_row["landing_date"] += "*";
                            if (oooi['event_type'] == "IN") new_row["in_gate_date"] += "*";
                        }
                        
                    }
                });
            }
            
            
            new_data.push(new_row);
            
        });
        logger.debug('test1 formatRowData2', new_data);
        return new_data;
     }

     getColumnDefs() {
        var column_width=104;
        var col_defs =  
        [ 
            {
                headerName: 'Customer Details',
                //expanded: true,
                children: [
                    {
                        field: "operator_icao_code",
                        headerName: "ICAO", 
                        headerTooltip: "ICAO Code",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        pinned: 'left',
                        formType: "readonly",
                    },
                    {
                        field: "aircraft_registration_number",
                        headerName: "Aircraft", 
                        headerTooltip: "Aircraft Registration Number",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        pinned: 'left',
                        formType: "readonly",
                    },
                ]
            },
            {
                headerName: 'Flight Details',
                //expanded: true,
                children: [
                     {
                        field: "op_ee_id_flight",
                        headerName: "Flight ID", 
                        headerTooltip: "Flight ID",
                        filter: 'agNumberColumnFilter',
                        width: column_width,
                        formType: "readonly"
                    },
                    {
                        field: "flight_number",
                        headerName: "Flight No.", 
                        headerTooltip: "Flight Number",
                        filter: 'agTextColumnFilter',
                        width: column_width * 0.8,
                        formType: "readonly"
                    },
                    {
                        field: "depart_airport",
                        headerName: "Departure Airport", 
                        headerTooltip: "Departure Airport",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        formType: "readonly"
                    },
                    {
                        field: "arrive_airport",
                        headerName: "Arrival Airport",
                        headerTooltip: "Arrival Airport",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        formType: "readonly",
                    },
                    {
                        field: "out_gate_date",
                        headerName: "OUT Gate Time", 
                        filter: 'agTextColumnFilter',
                        width: column_width *1.5,
                        cellRenderer: OOOIChosenEventRenderer,
                        headerClass: "highlight_header",
                        formType: "readonly",
                        tooltipField: 'OUT_source',
                        newline: true,
                        icons: {
                            sortAscending: '<span class="ag-icon ag-icon-asc" unselectable="on" style="color: white"></span>',
                            sortDescending: '<span class="ag-icon ag-icon-desc" unselectable="on" style="color: white"></span>'
                        }
                    },
                    {
                        field: "takeoff_date",
                        headerName: "OFF Takeoff Time", 
                        filter: 'agTextColumnFilter',
                        sort: 'desc',
                        width: column_width *1.5,
                        cellRenderer: OOOIChosenEventRenderer,
                        headerClass: "highlight_header",
                        formType: "readonly",
                        tooltipField: 'OFF_source',
                        icons: {
                            sortAscending: '<span class="ag-icon ag-icon-asc" unselectable="on" style="color: white"></span>',
                            sortDescending: '<span class="ag-icon ag-icon-desc" unselectable="on" style="color: white"></span>'
                        }
                    },
                    {
                        field: "landing_date",
                        headerName: "ON Landing Time", 
                        filter: 'agTextColumnFilter',
                        width: column_width *1.5,
                        cellRenderer: OOOIChosenEventRenderer,
                        headerClass: "highlight_header",
                        formType: "readonly",
                        tooltipField: 'ON_source',
                        newline: true,
                        icons: {
                            sortAscending: '<span class="ag-icon ag-icon-asc" unselectable="on" style="color: white"></span>',
                            sortDescending: '<span class="ag-icon ag-icon-desc" unselectable="on" style="color: white"></span>'
                        }
                    },
                    {
                        field: "in_gate_date",
                        headerName: "IN Gate Time", 
                        filter: 'agTextColumnFilter',
                        width: column_width *1.5,
                        cellRenderer: OOOIChosenEventRenderer,
                        headerClass: "highlight_header",
                        formType: "readonly",
                        tooltipField: 'IN_source',
                        icons: {
                            sortAscending: '<span class="ag-icon ag-icon-asc" unselectable="on" style="color: white"></span>',
                            sortDescending: '<span class="ag-icon ag-icon-desc" unselectable="on" style="color: white"></span>'
                        }
                    }
                ]
            },
           
        ];
        var sources = ["ARINC", "ARINC_MQ", "EMAIL", "FLIGHTAWARE", "PAC", "FLIFO", "MQ", "UAL_MQ"];
        var types = ['OUT', 'OFF', 'ON', "IN"];
        var fields = {
            'event_date':           'Event Time', 
            'flight_number':        'Flight', 
            'depart_airport':       'Departure Airport', 
            'arrive_airport':       'Arrival Airport', 
            'item_created_date':    'Creation Date'
        };
            
        sources.forEach(source => {
           
            types.forEach(type => {
                var children = [];
                for (const [field, value] of Object.entries(fields)) {
                    var child = {
                        filter: 'agTextColumnFilter',
                        width: column_width *1.5,
                        formType: "readonly"
                    };
                    child['field'] = source + "_" + type + '_' + field;
                    child['headerName'] = value;
                    child['headerTooltip'] = value;
                    //child['headerClass']= source + "_header";
                    if (field == 'event_date') {
                        child['cellRenderer'] = OOOISelectedEventRenderer;
                    }
                    else {
                        child['columnGroupShow'] = 'open';
                    }
                    
                    children.push(child);
                }
                var columns = {
                    headerName: source + ' ' + type,
                    headerClass: source + "_header",
                    children: children
                };
                col_defs.push(columns);
            });
           
        
        });
        
        col_defs.push(
             {
                headerName: 'Record Details',
                //expanded: true,
                children: [
                    {
                        field: "id",
                        headerName: "FDS ID", 
                        filter: 'agTextColumnFilter',
                        width: column_width * 2.5,
                        formType: "readonly",
                        resizable: false
                    },
                    {
                        field: "flight_created_date",
                        headerName: "Creation Time", 
                        filter: 'agTextColumnFilter',
                        width: column_width *1.5,
                        formType: "readonly",
                        newline: true
                    },
                    {
                        field: "flight_modified_date",
                        headerName: "Modification Time", 
                        filter: 'agTextColumnFilter',
                        width: column_width *1.5,
                        formType: "readonly",
                    },
                ]
            }
        );
        return col_defs;
        
     }
     /*
     async getAuthorizationHeader() {
        logger.debug("getAuthorizationHeader fetchRecords", this.getEndPoint().OAuth2);
        var githubAuth = new ClientOAuth2(this.getEndPoint().OAuth2);
        
        var token_data = await githubAuth.credentials.getToken()
          .catch(error => {
              logger.debug("getAuthorizationHeader fetchRecords error", error);
          });
          
        return token_data.accessToken;
    }
    */
    
    /*
    getEndPoint() {
        
        var endpoint;
        var ep;
        config.API.endpoints.forEach(ep => {
            logger.debug("fetchRecords", ep, this.apiName);
            if (ep.name == this.apiName) {
                endpoint = ep;
            }
        });
        logger.debug("getEndPoint", endpoint)
        return endpoint;
    }
    */
    
    jsonToQueryString(json) {
        return '?' + 
            Object.keys(json).map(function(key) {
                return encodeURIComponent(key) + '=' +
                    encodeURIComponent(json[key]);
            }).join('&');
    }

}

 /**
  * @experimental
  * 
  * This tests the functionality of the grid component
  */

import React, { useState } from 'react';
import { Logger } from 'aws-amplify';
import View from 'pac-responsive-ui-framework/core/View';
import AGGrid from '../components/AGGrid';
import Service from '../services/CustomerService';
 
const logger = new Logger('OpsCustomer');


 export default function OpsCustomer(props) {
   
    const [columnDefs, setColumnDefs] = useState(false);
    const service = new Service();
     
    logger.debug("columnDefs", columnDefs);
    if (columnDefs === false)
        service.getColumnDefs().then((data) => {
            setColumnDefs(data);
        });   
     
   
    
    if (columnDefs) {
        return (
            <View title={props.title} fixedContent={true}>
                <AGGrid service={service} columnDefs={columnDefs} filename="operations_customers_list"/>
            </View>
        );
    }
    else 
        return (
            <View title={props.title} fixedContent={true}>
            &nbsp;
            </View>
        );
 }
 
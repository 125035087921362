import React                        from 'react';
import { Logger }                   from 'aws-amplify';
import { makeStyles }       from '@material-ui/core/styles';
import Tooltip                      from '@material-ui/core/Tooltip';
import Button                       from '@material-ui/core/Button';
import ArrowDropDownIcon            from '@material-ui/icons/ArrowDropDown';
import Hidden                       from '@material-ui/core/Hidden';
import DateIcon             from '@material-ui/icons/DateRange';
import Popover              from '@material-ui/core/Popover';
import Grid                 from '@material-ui/core/Grid';

import DateFilter from '../components/FormElements/Date';




const logger = new Logger('MenuDateRange');


const useStyles = makeStyles(theme => ({
    grid_container: {
        width: 360,
        //border: "solid 1px red",
        overflow: "hidden"
        
    }
}));

/**
 * Required props:
 * operator - ICAO Code
 * onCloseCallback - function to save or pass the data around after the selection is made
 * 
 * Optional props:
 * hasFullName - bool to show the full name of the operator or just the ICAO Code
 * 
 */ 
export default function MenuDateRange(props) {
      const classes = useStyles();
    logger.debug("props default_operator", props);
  
    const [startDate, setStartDate]   = React.useState(props.startDate);
    const [endDate, setEndDate]   = React.useState(props.endDate);
   
    const maxDate = new Date().toISOString().substr(0, 10) + ' 23:59:59';
    
    var invalidState = false;
    var startDateError = false;
    var startDateMessage;
    var endDateError = false;
    var endDateMessage;
    
    logger.debug("dates", startDate, endDate);
    

    if (!startDate || !endDate) {
      invalidState = true;
    }
    if (!startDate && !endDate) {
      invalidState = false;
    }
    
    if (startDate && !startDate.startsWith("null") && startDate > maxDate) {
      logger.debug("invalid date");
      invalidState = true;
      startDateError = true;
      startDateMessage = "Cannot have a future date";
    }
    if (endDate && !endDate.startsWith("null") && endDate > maxDate) {
      logger.debug("invalid date");
      invalidState = true;
      endDateError = true;
      endDateMessage = "Cannot have a future date";
    }
    if (startDate > endDate) {
      logger.debug("invalid date");
      invalidState = true;
      endDateError = true;
      endDateMessage = "End Date must be later than Start Date";
    }
    
    if (startDate && startDate.startsWith("Invalid Date")) {
      invalidState = true;
      startDateError = false;
      startDateMessage = "";
    }
    if (endDate && endDate.startsWith("Invalid Date")) {
      invalidState = true;
      endDateError = false;
      endDateMessage = "";
    }

    var display_text = "____-__-__  → ____-__-__";
    if (props.startDate && props.endDate) {
      display_text = props.startDate.substr(0, 10) + ' → ' + props.endDate.substr(0, 10);
    }
    if (!props.alwaysShowDate) {
      display_text = (
      <Hidden mdDown> 
         {display_text}
         </Hidden>);
  }
  
  logger.debug("dates bad2", startDate, endDate, invalidState);
    
  const menuId = 'popover-operators-menu';
    
  const [anchorEl, setAnchorEl] = React.useState(null);
    
  const handleMenuClose = (value) => {
    logger.debug("handleMenuClose", value);
    setAnchorEl(null);
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }; 
  
  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleStartDateChange = (date) => {
    if (date) date += ' 00:00:00';
    setStartDate(date);
  };
  
  const handleEndDateChange = (date) => {
    if (date) date += ' 23:59:59';
    setEndDate(date);
  };

  const handleSave = () => {
    
    var s = startDate;
    var e = endDate;
    logger.debug("handleSave", s, e);
    if (s && s.includes('null')) s = null;
    if (e && e.includes('null')) e = null;
    
    props.onChange({start_date: s, end_date: e});
    setAnchorEl(null);
  };
  
  logger.debug("invalidState", invalidState);
  
  /**
   * Output the component
   */ 
  return (
    <React.Fragment>
     <Tooltip title={props.tooltip}>
      <Button 
        variant="text"
        color="inherit"
        startIcon={<DateIcon/>}
        endIcon={<ArrowDropDownIcon/>}
        onClick={handleMenuOpen}
        aria-haspopup="true"
        text-transform="none"
      >
        {display_text}
      </Button>
      </Tooltip>
      <Popover
            PaperProps = {{square: true}}
            className={classes.popover}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <div style={{padding: "16px"}}>
              <table><tbody>
                <tr><td colSpan="3">
                  <table><tbody><tr><td>
                    <Button onClick={() => { 
                      const end_date = new Date().toISOString().substr(0, 10);
                      var start_date = new Date();
                      start_date.setDate(start_date.getDate()-2);
                      start_date = start_date.toISOString().substr(0, 10);
                      handleStartDateChange(start_date);
                      handleEndDateChange(end_date);
                    }}>Last 3 days</Button>
                  </td><td>
                    <Button onClick={() => { 
                      const end_date = new Date().toISOString().substr(0, 10);
                      var start_date = end_date.substr(0, 8) + '01';
                      handleStartDateChange(start_date);
                      handleEndDateChange(end_date);
                    }}>Current month</Button>
                  </td></tr><tr><td>
                    <Button onClick={() => { 
                      const end_date = new Date().toISOString().substr(0, 10);
                      var start_date = new Date();
                      start_date.setDate(start_date.getDate()-6);
                      start_date = start_date.toISOString().substr(0, 10);
                      handleStartDateChange(start_date);
                      handleEndDateChange(end_date);
                    }}>Last 7 days</Button>
                  </td><td>
                    <Button onClick={() => { 
                      var end_date = new Date();
                      end_date = new Date(end_date.getFullYear(), end_date.getMonth(), 0);
                      end_date = end_date.toISOString().substr(0, 10);
                      var start_date = end_date.substr(0,8)+ '01';
                      handleStartDateChange(start_date);
                      handleEndDateChange(end_date);
                    }}>Last month</Button>
                  </td></tr></tbody></table>
                </td></tr>
                <tr><td>
                    <Button onClick={() => { 
                      handleStartDateChange(null);
                      handleEndDateChange(null);
                    }}>Clear Dates</Button>
                  </td>
                  <td>
                </td></tr>
                <tr><td>
                <DateFilter 
                        id="start" 
                        dateLabel="Start Date" 
                        timeLabel="Start Time" 
                        defaultValue={startDate}  
                        onChange={handleStartDateChange}
                        maxDate={maxDate}
                        error={startDateError}
                        helperText={startDateMessage}
                        required
                />
                </td>
                <td style={{verticalAlign: "top"}}><br/><br/>&nbsp;→&nbsp;&nbsp;&nbsp;</td>
                <td>
                <DateFilter 
                        id="end" 
                        dateLabel="End Date" 
                        timeLabel="End Time" 
                        defaultValue={endDate} 
                        onChange={handleEndDateChange}
                       // minDate={startDate} 
                        maxDate={maxDate}
                        error={endDateError}
                        helperText={endDateMessage}
                        required
                        //minDateMessage="End Date cannot precede Start Date"
                        //maxDateMessage={"End Date must be within " + days_diff + " days of the Start Date"}
                />
                </td></tr></tbody></table>
              </div>
              <Grid container justifyContent="flex-end">
                <div style={{padding: "8px"}}>
                <Button variant="contained" color="primary" onClick={handleSave} disabled={invalidState}>Submit</Button>
                </div>
            </Grid>
        </Popover>
        {/*
      <Menu
        id={menuId}
        anchorEl={anchorEl}
       // keepMounted  // This will keep state (position) even after the menu is closed
        open={Boolean(anchorEl)}
        onClose={handleMenuClose.bind(this, null)}
      >
     
            <MenuItem key="---" value="---" onClick={handleMenuClose.bind(this, "---")}>
              <ListItemIcon>
                  { getIcon({}) }
              </ListItemIcon>
                --- - {no_operator_label}
              </MenuItem>
          <Divider />
          {
            operator_array.map(function(item, index) {
              return  ( 
                <MenuItem 
                    key={item.operator_icao_code} 
                    value={item.operator_icao_code} 
                    onClick={handleMenuClose.bind(this, item.operator_icao_code)}
                >
                  <ListItemIcon>{ getIcon(item) }</ListItemIcon>
                  {item.operator_icao_code} - {item.operator_name}
                </MenuItem>
              )
            })
          }
     </Menu>
     */}
    </React.Fragment>
  );
}
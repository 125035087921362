import BaseService from './BaseService';
import BooleanCellRenderer from '../components/AGGrid/BooleanCellRenderer';
import { Logger } from 'aws-amplify';

const logger = new Logger('SLAMetricService');

export default class SLAMetric extends BaseService {

    constructor() {
        let apiName = 'OriginsAPI';
        let collectionPath = '/slametric';
        super(apiName, collectionPath);
    }
    
    formatRowData(response) {
         var data = response.data;
         return data;
    }
     
    getMetaData(data) {
        return data.meta;
    }
    
    async save(data, metaData) {
        logger.debug("save", data, metaData);
        if (!data['options_string']) data['options_string'] = "";
        if (data['has_threshold']) data['has_threshold'] = 1;
        else data['has_threshold'] = 0;
        if (data['newline']) data['newline'] = 1;
        else data['newline'] = 0;
        if (data['has_active']) data['has_active'] = 1;
        else data['has_active'] = 0;
        if (data['active']) data['active'] = 1;
        else data['active'] = 0;
        
        if (data['has_threshold'] == 1 && !data['default_threshold']) {
           return { 
                    success: false, 
                    updated: false, 
                    response: {
                        data: {
                            error: "Threshold must be set if enabled.", 
                            columns: [
                                {
                                    key: 'default_threshold',
                                    message: "Threshold value is missing."
                                }
                            ]
                        
                        }
                    } 
                };
        }
        //todo: look up userID and set the value for RowModifiedByKey
        var response = null;
        if (data['id_gcs_sla_metric']) { //update
            await this.update(data['id_gcs_sla_metric'], data)
                .then((results) => {
                    response = results;
                    logger.debug("save: update record", results);
                })
                .catch((err) => {
                    response = err;
                    logger.debug('save: update record failed', err);
                });
        }
        else { //insert
            await this.add(data)
                .then((results) => {
                    response = results;
                    logger.debug("save: insert record", results);
                })
                .catch((err) => {
                    response = err;
                    logger.debug('save: insert record failed', err);
                });
        }
        return response;
    }
    
    
    getColumnDefs() {
        var column_width = 100;

        return [{
            headerName: 'Details',
            defaultExpanded: true,
            children: [
                {
                    field: "active",
                    headerName: "Active",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: 1 
                },
                {
                    field: "id_gcs_sla_metric",
                    headerName: "ID",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    formType: "readonly",
                    newline: true
                },
                {
                    field: "code",
                    headerName: "Code",
                    filter: 'agTextColumnFilter',
                    width: column_width *2,
                    resizable: true,
                    readonly: true,
                    formType: "text",
                    newline: true,
                    required: true
                },
                {
                    field: "name",
                    headerName: "Name",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                     resizable: true,
                    readonly: true,
                    formType: "text",
                    required: true
                },
                {
                    field: "display_order",
                    headerName: "Display Order",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    readonly: true,
                    formType: "text",
                    required: true,
                    newline: true
                }
            ],
        },
        {
            headerName: 'Threshold',
            defaultExpanded: true,
            children: [
                {
                    field: "has_threshold",
                    headerName: "Threshold Enabled",
                    cellRenderer: BooleanCellRenderer,
                    width: column_width * 1.5,
                    formType: "boolean",
                    defaultValue: 1
                    
                },
                {
                    field: "default_threshold",
                    headerName: "Default Threshold",
                    filter: 'agTextColumnFilter',
                    width: column_width  * 1.5,
                    readonly: true,
                    formType: "text",
                    newline: true
                }
            ]
        },
        {
            headerName: 'Options',
            defaultExpanded: true,
            children: [      
                {
                    field: "options_string",
                    headerName: "Options",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    readonly: true,
                    formType: "text",
                    helperText: "Example: 1=Option One|2=Option Two|4=Option Three|8=Option Four|16=Option Five"
                }
            ]
        },
        {
            headerName: 'Interface Flags',
            defaultExpanded: true,
            children: [
                {
                    field: "has_active",
                    headerName: "Active Flag Enabled",
                    cellRenderer: BooleanCellRenderer,
                    width: column_width * 1.5,
                    formType: "boolean",
                    defaultValue: 1
                    
                },
                {
                    field: "newline",
                    headerName: "New Line Enabled",
                    cellRenderer: BooleanCellRenderer,
                    width: column_width * 1.5,
                    formType: "boolean",
                    defaultValue: 1
                    
                }
            ],
        }
       ]
                
    }
}

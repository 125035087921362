import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
const useStyles = makeStyles((theme) => ({
 
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  }
}));

export default function FormRefresh(props) {
    
    var output = ( <Tooltip title="Refresh the data">
                    <IconButton aria-label="refresh entries" onClick={props.onClick}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>);
    
    
    if (props.loading) {
        return (
            <Box position="relative" display="inline-flex">
                <CircularProgress />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Tooltip title="Refresh the data">
                        <IconButton aria-label="refresh entries" onClick={props.onClick}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>);
    }
    else {
   
        return (
            <Tooltip title="Refresh the data">
                <IconButton aria-label="refresh entries" onClick={props.onClick}>
                    <RefreshIcon />
                </IconButton>
            </Tooltip>
        );
    }
}

/**
 * Profile settings page for the user
 */ 
import React, { useState, useContext }    from 'react';
import PropTypes from 'prop-types';
import { Logger }               from 'aws-amplify';
import { makeStyles }           from '@material-ui/core/styles';
import CssBaseline              from '@material-ui/core/CssBaseline';
import Typography               from '@material-ui/core/Typography';
import Grid                     from '@material-ui/core/Grid';
import Avatar                   from '@material-ui/core/Avatar';
import Paper                    from '@material-ui/core/Paper';
import Link                     from '@material-ui/core/Link';

import EnhancedTable            from '../components/EnhancedTable'

import AppContext               from 'pac-responsive-ui-framework/core/AppContext';

import MenuOperators            from '../components/MenuOperators';
import UserService              from '../services/UserService';
import Box                      from '@material-ui/core/Box'
import CircularProgress         from '@material-ui/core/CircularProgress'
import PersonIcon               from '@material-ui/icons/Person'
import { View, Snackbar }       from 'pac-responsive-ui-framework/core';


const logger = new Logger('Profile');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    margin: 'auto',
    maxWidth: 800,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

  
                      
/*
const demo_data = [
    {key: 1, date: '2020-11-17', operator: "AAL", aircraft: "N906AN", type: "Build Tracker" },
    {key: 2, date: '2020-11-19', operator: "AAL", aircraft: "N906AN", type: "Flight Performance" },
    {key: 3, date: '2020-11-19', operator: "AFR", aircraft: "F-GZNH", type: "Build Tracker" },
    {key: 4, date: '2020-11-22', operator: "AFR", aircraft: "F-GZNH", type: "Test Type 1" },
];
*/



export default function Profile(props) {
 
    // map from key to label
   
    
    const app_context = useContext(AppContext);
    const user_service = new UserService();
    logger.debug("app_context", app_context);

    const [operator, setOperator] = useState(app_context.state.user.session.preferred_operator);

    const [message, setMessageBase] = React.useState({});
    const setMessage = msg => {  setMessageBase({ msg, date: new Date() });};
    
    const [loading, setloading] = React.useState(false);
    
    
    const handleOperatorMenuClose = (value) => {
        logger.debug('handleMenuCloseCallback', value);
        const key = app_context.state.user.session.email;
        setloading(true);
        user_service.update(key, {preferred_operator: value})
            .then((data) => {
                logger.debug("UserService success", data);
                setOperator(value);
                app_context.updateOperator(value);
                setMessage("Your preferred operator is now " + value + ".");
                setloading(false);
            }).catch((err) => {
                logger.debug('UserService error', err);
                if (err == "No current user") {
                   // window.location.reload(false);
                }
                else {
                    setMessage("Unable to update operator at this time.");
                }
                setloading(false);
            });
        
    }; 
    
    var notification_table = '';
    if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGE == 'dev') {

         notification_table = ( 
            <EnhancedTable 
                    title="Notifications" 
                    headers= {[
                      { key: 'date',            type: "string",             label: 'Expiry Date'},
                      { key: 'operator',        type: "string",             label: 'Operator'   },
                      { key: 'aircraft',        type: "string",             label: 'Aircraft'   },
                      { key: 'type',            type: "string",             label: 'Type'       },
                      
                    ]}
            />
        )
    }

    const classes = useStyles();
  
    return (
        <View title="Profile">
            {Object.keys(message).length > 0 ? <Snackbar key={message.date} message={message.msg} /> : null}
            <Paper className={classes.paper}>
                
                <Typography variant="h5">
                    <PersonIcon />
                        <Box display="inline" pl={2}>
                        {app_context.state.user.payload.pac_first_name + ' ' +  app_context.state.user.payload.pac_last_name}
                        </Box>
                </Typography>
                
                 <Paper className={classes.paper}>
                    <Typography variant="h6">Preferred Operator</Typography>
                    <Typography variant="subtitle1">
                        <MenuOperators hasFullName={true} operator={operator} onCloseCallback={handleOperatorMenuClose} />
                            {loading && <CircularProgress className={classes.spinner} size={16} />}
                    </Typography>    

                </Paper>

                {notification_table}
               
          </Paper>
         </View>
      
  );
    
}
import React, {useContext}  from 'react';
import { Link }             from 'react-router-dom';
import { makeStyles }       from '@material-ui/core/styles';
import Popover              from '@material-ui/core/Popover';
import MenuItem             from '@material-ui/core/MenuItem';
import ListItemIcon         from '@material-ui/core/ListItemIcon';
import Divider              from '@material-ui/core/Divider';
import Tooltip              from '@material-ui/core/Tooltip';
import FormControlLabel     from '@material-ui/core/FormControlLabel';
import Checkbox             from '@material-ui/core/Checkbox';
import Button               from '@material-ui/core/Button';
import Box                  from '@material-ui/core/Box';

import IconArrowDropDown            from '@material-ui/icons/ArrowDropDown';

import { Logger }           from 'aws-amplify';


const logger = new Logger('MenuMultiSelect');

const useStyles = makeStyles(theme => ({
    button: {
   
    }
  
}));

export default function MenuMultiSelect(props) {
    const classes = useStyles();
  
      
      
        
    const [userAnchorEl, setUserAnchorEl] = React.useState(null);
    const [items, setItems] = React.useState(props.items);
    const [all_checked, setAllChecked] = React.useState(true);
    
    const isUserMenuOpen = Boolean(userAnchorEl);
        
    const handleUserMenuClose = () => {
        setUserAnchorEl(null);
    }; 
    
    const handleUserMenuOpen = event => {
        setUserAnchorEl(event.currentTarget);
    };
    
    const handleCheckAll = (event) => {
        var new_items = Array.from(items);
        for(let i = 0 ; i < new_items.length; i++) {
            new_items[i].checked = event.target.checked
        }
        logger.debug(new_items);
        setItems(new_items);
        setAllChecked(event.target.checked);
        props.onChange(new_items);
    }
    
    const handleCheckItem = (event) => {
        let new_items = Array.from(items);
        for(let i = 0 ; i < new_items.length; i++) {
           if ("checked" + i === event.target.name) {
                logger.debug("handleCheckItem", event, event.target.name,  event.target.checked)
                new_items[i].checked =  event.target.checked
           }
        }
        logger.debug("handleCheckItem", new_items);
        setItems(new_items);
        props.onChange(new_items);
    }
      
    return (
        <React.Fragment>
            <Tooltip  title={props.tooltip}> 
                <Button 
                    variant={props.variant}
                    color="inherit"
                    className={classes.button}
                   
                    endIcon={<IconArrowDropDown/>}
                    onClick={handleUserMenuOpen}
                    aria-haspopup="true"
                  >{props.children}</Button>
            </Tooltip>
              
            <Popover
              anchorEl={userAnchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              id={props.id}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={isUserMenuOpen}
              onClose={handleUserMenuClose}
            >
                <MenuItem value="" key="all" dense={true} disableRipple>
                    <FormControlLabel control={
                        <Checkbox 
                            id="checkbox_all"
                            onChange={handleCheckAll.bind(this)}
                            color="default" 
                            checked={!!all_checked}
                            name="all" 
                        />
                        
                        } 
                        label="All / None" 
                    />
                </MenuItem>
                <Divider />
                 {
                    items.map((value, index) => 
                        <MenuItem value="" key={value.title}  dense={true} disableRipple>
                            <FormControlLabel  control={
                                <Checkbox 
                                    onChange={handleCheckItem.bind(this)} 
                                    checked={!!value.checked} 
                                    style ={{ color: value.color?value.color:"default"}}
                                    name={"checked" + index} />
                                } 
                                label={value.title} 
                            />
                        </MenuItem>
                     )
                 }
            </Popover>
            
          </React.Fragment>    
  
    );
}
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Home from '../views/Home';
import ErrorView from 'views/Error';
import {
  Route,
  useParams,
}
from 'react-router-dom';
import { Auth, API, Analytics, Logger } from 'aws-amplify';

export default function Signout(props) {
  console.log("views/Signout");

  Auth.signOut();
}

import _                            from 'lodash';
import React, { useContext }        from 'react';
import { Logger }                   from 'aws-amplify';
import Tooltip                      from '@material-ui/core/Tooltip';
import Menu                         from '@material-ui/core/Menu';
import MenuItem                     from '@material-ui/core/MenuItem';
import ListItemIcon                 from '@material-ui/core/ListItemIcon';
import Button                       from '@material-ui/core/Button';
import ArrowDropDownIcon            from '@material-ui/icons/ArrowDropDown';
import Divider                      from '@material-ui/core/Divider';

import { getIcon }  from '../data/Operators';

import OperatorService      from '../services/OperatorService';

import AppContext           from 'pac-responsive-ui-framework/core/AppContext';
const logger = new Logger('MenuOperators');

/**
 * Required props:
 * operator - ICAO Code
 * onCloseCallback - function to save or pass the data around after the selection is made
 * 
 * Optional props:
 * hasFullName - bool to show the full name of the operator or just the ICAO Code
 * 
 */ 
export default function MenuOperators(props) {
  
  logger.debug("props default_operator", props);
  const [operator_array, setOperatorArray] = React.useState([]);
  const [default_operator, setDefaultOperator] = React.useState({operator_icao_code: '---', name: 'Loading...'});
  var no_operator_label = 'No operator selected';
  
  var app_context = useContext(AppContext);
  
   const processData = (data) => {
    logger.debug("processData", data);
    setOperatorArray(data['data']);
    var set = false;
    for(let i = 0 ; i < data['data'].length; i++) {
      if (data['data'][i]['operator_icao_code'] === props.operator) {
        setDefaultOperator(data['data'][i]);
        set = true;
        logger.debug("processData setDefaultOperator", props.operator);
      }
    } 
    if (!set) {
      setDefaultOperator({operator_icao_code: '---', name: no_operator_label})
    }
  }
  
  if (app_context.state.cache['operators'] && operator_array.length == 0) {
    logger.debug('cache exists', app_context.state.cache['operators']);
    processData(app_context.state.cache['operators']);
  }
  
  React.useEffect(() => {
    logger.debug("useEffect1 default_operator", default_operator, props.operator);
    if (!app_context.state.cache['operators']) {
    
      logger.debug('cache not exists');
      const service = new OperatorService();
      service.fetchRecords()
        .then((data) => {
          logger.debug("then", data);
          //processData(data);
          app_context.setCache('operators', data);
        }).catch((err) => {
            setDefaultOperator({operator_icao_code: '---', name: 'Operator Error'});
        });
      logger.debug("default_operator1", default_operator, props.operator);
    }
    //*
    else if (props.operator && (typeof(default_operator) === "undefined" || default_operator.operator_icao_code != props.operator) && operator_array) {
      //reset default operator.
      setDefaultOperator(operator_array.find(element => element.operator_icao_code == props.operator));
    }
     // */
  }, [props]);
  
   
  /*
  if (props.operator && default_operator.operator_icao_code != props.operator && operator_array) {
    //reset default operator.
    logger.debug("default operator cache", app_context.state.cache['operators']);
    operator_array.forEach(element => {
      if (element['operator_icao_code'] == props.operator ) setDefaultOperator(element);
    });
  }
  */
  
  
  logger.debug('default_operator2',default_operator, props.operator );
  
  
  var display_operator_text = props.hasFullName?'--- - No operator selected':'---';
  var default_operator_icon = getIcon({operator_icao_code: '---'});
  if (typeof(default_operator) !== "undefined" && default_operator.operator_name) {
    display_operator_text = props.hasFullName?default_operator.operator_icao_code + ' - ' + default_operator.operator_name:default_operator.operator_icao_code;
    default_operator_icon = getIcon(default_operator);
  }
  /*
  if (props.operator && default_operator.operator_icao_code != props.operator && operator_array) {
    //reset default operator.
    logger.debug("default operator cache", app_context.state.cache['operators']);
    operator_array.forEach(element => {
      logger.debug("element", element);
      display_operator_text = props.hasFullName?element.operator_icao_code + ' - ' + element.operator_name:element.operator_icao_code;
      default_operator_icon = getIcon(element);
    });
  }
  */
  
  const menuId = 'popover-operators-menu';
    
  const [anchorEl, setAnchorEl] = React.useState(null);
    
  const handleMenuClose = (value) => {
    logger.debug("handleMenuClose", value);
    setAnchorEl(null);
    if (value) props.onCloseCallback(value);
    
  }; 
  
  
  
  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  
  var tooltip = "Select an operator";
  if (props.tooltip) tooltip = props.tooltip;
  
  logger.debug('display_operator_text', display_operator_text);

  /**
   * Output the component
   */ 
  return (
    <React.Fragment>
     <Tooltip title={tooltip}>
      <Button 
        variant="text"
        color="inherit"
        startIcon={default_operator_icon}
        endIcon={<ArrowDropDownIcon/>}
        onClick={handleMenuOpen}
        aria-haspopup="true"
        text-transform="none"
      >
         {display_operator_text}
      </Button>
      </Tooltip>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
       // keepMounted  // This will keep state (position) even after the menu is closed
        open={Boolean(anchorEl)}
        onClose={handleMenuClose.bind(this, null)}
      >
     
            <MenuItem key="---" value="---" onClick={handleMenuClose.bind(this, "---")}>
              <ListItemIcon>
                  { getIcon({}) }
              </ListItemIcon>
                --- - {no_operator_label}
              </MenuItem>
          <Divider />
          {
            operator_array.map(function(item, index) {
              return  ( 
                <MenuItem 
                    key={item.operator_icao_code} 
                    value={item.operator_icao_code} 
                    onClick={handleMenuClose.bind(this, item.operator_icao_code)}
                >
                  <ListItemIcon>{ getIcon(item) }</ListItemIcon>
                  {item.operator_icao_code} - {item.operator_name}
                </MenuItem>
              )
            })
          }
     </Menu>
    </React.Fragment>
  );
}
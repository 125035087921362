import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Tooltip      from '@material-ui/core/Tooltip';
import IconButton   from '@material-ui/core/IconButton';
import MenuIcon     from '@material-ui/icons/ArrowForwardIos';


const useStyles = makeStyles(theme => ({
  menuButton: {
    //marginRight: theme.spacing(2),
    //[theme.breakpoints.up('sm')]: {
  //    display: 'none',
  //  },
  },
}));

export default function IconButtonNavMenu(props) {
    
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);  
    
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };  
    
  return (
     <Tooltip title="Show Menu" >
      <IconButton
        color="inherit"
        aria-label="open menu"
        edge={props.edge}
        onClick={props.onClick}
        className={classes.menuButton}
      >
        <MenuIcon fontSize="small" />
      </IconButton>
      </Tooltip>
  );
    
     
}
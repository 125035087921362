import React, { useState, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Logger }           from 'aws-amplify';
import { AgGridReact } from 'ag-grid-react';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'

//import '../3rd_party/ag-grid/dist/ag-grid-community.min.js';
import '../3rd_party/ag-grid/dist/styles/ag-grid.css';
import '../3rd_party/ag-grid/dist/styles/ag-theme-balham.min.css';
import '../3rd_party/ag-grid/dist/styles/agGridBalhamFont.min.css';
import "../3rd_party/ag-grid/dist/styles/webfont/agGridBalhamFont.scss";


//import 'ag-grid-community/dist/styles/ag-grid.css';
//import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/GetApp'

import { getIcon } from '../data/Operators';
import { getColumns } from '../data/DataColumns';

const logger = new Logger('DataGrid');

const columns = getColumns();



function percentColumnFilter() {
    
}

percentColumnFilter.prototype.doesFilterPass = function(params) {
    console.log('percentColumnFilter.prototype.doesFilterPass',params);
    // make sure each word passes separately, ie search for firstname, lastname
    var passed = true;
    var valueGetter = this.valueGetter;
    this.filterText.toLowerCase().split(" ").forEach(function(filterWord) {
        var value = valueGetter(params);
        if (value.toString().toLowerCase().indexOf(filterWord) < 0) {
            passed = false;
        }
    });

    return passed;
};

function operatorIcaoCodeCellRenderer() {
}
operatorIcaoCodeCellRenderer.prototype.init = function (params) {
    console.log('operatorIcaoCodeCellRenderer.prototype.init', params);
    this.eGui = document.createElement('span');
    if (params.value !== "" || params.value !== undefined || params.value !== null) {
        var icon = ReactDOMServer.renderToStaticMarkup(getIcon(params.value));
        
        this.eGui.innerHTML = icon + ' ' + params.value;
    }
};
operatorIcaoCodeCellRenderer.prototype.getGui = function () {
    return this.eGui;
};







export function getColumnDefinitions(data) {
    var new_columns = {};
    for (let key in data[0]) {
        let value = data[0][key];
        let column = columns[key];
        if (column) {
            if (!Array.isArray(column)) {
                column = [column]
            }
            column.forEach(val => {
                console.log("getColumnDefinitions", column, val);
                var parent = val.parentHeaderName;
                if (!new_columns[parent]) {
                    new_columns[parent] = {
                        headerName: parent,
                        children: []
                    }
                }
                delete val.parentHeaderName;
                val.field = key;
                val.headerTooltip = val.headerName;
                new_columns[parent].children.push(val);
                
            });
            
        }
    }
    var new_array = [];
    for (let key in new_columns) {
        let value = new_columns[key];
        new_array.push(value);
    }
    return new_array;
}






var gridOptions = {
  defaultColDef: {
    editable: true,
    resizable: true,
    minWidth: 100,
    flex: 1,
  },

  columnDefs: [{ field: 'make' }, { field: 'model' }, { field: 'price' }],

  rowData: [
    { make: 'Toyota', model: 'Celica', price: 35000 },
    { make: 'Ford', model: 'Mondeo', price: 32000 },
    { make: 'Porsche', model: 'Boxter', price: 72000 },
  ],
};

function getValue(inputSelector) {
  var text = document.querySelector(inputSelector).value;
  switch (text) {
    case 'string':
      return (
        'Here is a comma, and a some "quotes". You can see them using the\n' +
        'api.getDataAsCsv() button but they will not be visible when the downloaded\n' +
        'CSV file is opened in Excel because string content passed to\n' +
        'customHeader and customFooter is not escaped.'
      );
    case 'array':
      return [
        [],
        [
          {
            data: {
              value: 'Here is a comma, and a some "quotes".',
              type: 'String',
            },
          },
        ],
        [
          {
            data: {
              value:
                'They are visible when the downloaded CSV file is opened in Excel because custom content is properly escaped (provided that suppressQuotes is not set to true)',
              type: 'String',
            },
          },
        ],
        [
          { data: { value: 'this cell:', type: 'String' }, mergeAcross: 1 },
          {
            data: {
              value: 'is empty because the first cell has mergeAcross=1',
              type: 'String',
            },
          },
        ],
        [],
      ];
    case 'none':
      return;
    case 'tab':
      return '\t';
    case 'true':
      return true;
    case 'none':
      return;
    default:
      return text;
  }
}

function getParams() {
  return {
    suppressQuotes: getValue('#suppressQuotes'),
    columnSeparator: getValue('#columnSeparator'),
    customHeader: getValue('#customHeader'),
    customFooter: getValue('#customFooter'),
  };
}


//const gridApi = useRef();



export default function DataGrid(props) {

    const columnDefs = getColumnDefinitions(props.rowData);

    var gridApi;

    const onGridReady = params => {
    
      logger.debug('onGridReady', params.api);
      gridApi = params.api;
      gridApi.sizeColumnsToFit();
      
    };
    
    
    function onBtnExport() {
      gridApi.exportDataAsCsv();
    }

    return (
        <React.Fragment>
            <Grid
              container
              direction="row-reverse"
              justify="flex-start"
              alignItems="flex-start"
            >
                <IconButton onClick={onBtnExport}>
                    <DownloadIcon/>
                </IconButton>
            </Grid>
            <div className="ag-theme-balham" style={ {paddingLeft: "16px", paddingBottom: "4px", height: '100%', width: '100%'} }>
                <AgGridReact
                    onGridReady= {onGridReady}
                    defaultColDef={ {
                        sortable: true
                    }}
                    components= { {
                        'operatorIcaoCodeCellRenderer': operatorIcaoCodeCellRenderer,
                        'percentColumnFilter': percentColumnFilter
                    }}
                    columnDefs={columnDefs}
                    rowData={props.rowData}>
                </AgGridReact>
            </div>
        </React.Fragment>
    );
}


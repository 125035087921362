import React                from 'react';
import View                 from 'pac-responsive-ui-framework/core/View';
import { makeStyles }       from '@material-ui/core/styles';

import DataGrid from '../components/DataGrid3';


import rowData from '../data/AircraftData';

for(let i = 0; i < rowData.length; i++) {
  rowData[i]['reboots'] = Math.floor((Math.random() * 40));
  rowData[i]['sla'] = Math.floor((Math.random() * 2000) + 8000)/10000;
  
}

const appbarHeight = 78;

export default function GridTest() {
  
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    div: {
      marginTop: appbarHeight, 
      height: `calc(100% - ${appbarHeight}px)`, 
      position: "fixed", 
      top: 0,
      [theme.breakpoints.only('xs')]: {
        left: 0,
      },
      [theme.breakpoints.up('sm')]: {
        left: 64,
      },
      right: 0,
      overflowScrolling: "touch",
      WebkitOverflowScrolling: "touch",
      overflowY: "scroll",
      overflowX: "scroll",
    }
   
  }));
  const classes = useStyles();

  return (
         <View title="Grid Test 3" fixedContent={true}>
            <DataGrid rowData={rowData} />
      </View>
      
  );
  
}
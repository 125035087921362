import React from 'react';
import Select from 'pac-responsive-ui-framework/forms/Select'
import OperatorService      from '../services/OperatorService';

export default function SelectOperator(props) {
    
    return (
        <Select 
            label="Operator" 
            valueKey = 'operator_icao_code'
            value={props.value} 
            service={OperatorService}
            getOptionLabel={(option) => option.operator_icao_code?option.operator_icao_code + ": " + option.operator_name:""}
            load={true} 
            />
      )
    
}
/**
 * https://www.npmjs.com/package/react-awesome-query-builder
 * https://codesandbox.io/p/github/lbrdar/react-awesome-query-builder/master?file=%2Fpackages%2Fexamples%2Fdemo%2Findex.tsx%3A29%2C34-29%2C49
 * https://github.com/ukrbublik/react-awesome-query-builder/issues/190
 * https://jsonlogic.com/
 */

import React, { useState, useCallback } from "react";
import { Logger }           from 'aws-amplify';
//import { Query, Builder, Utils as QbUtils } from "react-awesome-query-builder";

import { QueryBuilder, formatQuery } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
// see https://react-querybuilder.js.org/demo#addRuleToNewGroups=false&autoSelectField=true&autoSelectOperator=true&debugMode=false&disabled=false&enableDragAndDrop=false&independentCombinators=false&justifiedLayout=false&listsAsArrays=false&parseNumbers=false&resetOnFieldChange=true&resetOnOperatorChange=false&showBranches=false&showCloneButtons=false&showCombinatorsBetweenRules=false&showLockButtons=false&showNotToggle=false&showShiftActions=false&validateQuery=false


import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// For AntDesign widgets only:
//import AntdConfig from "react-awesome-query-builder/lib/config/antd";
//import "antd/dist/antd.css"; // or import "react-awesome-query-builder/css/antd.less";
// For MUI 4/5 widgets only:
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
//import MuiConfig from 'react-awesome-query-builder/lib/config/mui';
// For Bootstrap widgets only:
//import BootstrapConfig from "react-awesome-query-builder/lib/config/bootstrap";

import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css"; //optional, for more compact styles

// Choose your skin (ant/material/vanilla):
const InitialConfig = MaterialConfig; // or MaterialConfig or MuiConfig or BootstrapConfig or BasicConfig

//const {queryBuilderFormat, jsonLogicFormat, queryString, mongodbFormat, sqlFormat, getTree, checkTree, loadTree, uuid, loadFromJsonLogic} = QbUtils;

const logger = new Logger('QueryBuilder');

logger.debug("InitialConfig", InitialConfig);
// You need to provide your own config. See below 'Config format'



const attributes = [
  { name: 'ACSEATCLASS', label: 'Aircraft Seat Class', description: 'Seat class for aircraft list.'  },
  { name: 'AFFECTEDFUNCTION', label: 'Affected Function', description: 'FMR Affected Function Area '  },
  { name: 'ALERTDAYS', label: 'Alert Days', description: ''  },
  { name: 'ALERTFREQUENCY', label: 'Alert Frequency', description: 'Frequency of Alert trigger'  },
  { name: 'APPRESETSCHK', label: 'Show App Resets', description: 'Show Seat App Resets which are also recorded in the Fault Log.'  },
  { name: 'ATTRALERTCHURNSHOPRPT', label: 'Repair Rate by Shop Content', description: ''  },
  { name: 'ATTRALERTPARTSHOPRPT', label: 'Alert Parts Usage over Norm by Shop content', description: ''  },
  { name: 'ATTRHWFAILREREPORT', label: 'HW Failure Content', description: ''  },
  { name: 'CHURNPERCENTASINTEGER', label: 'Repair Rate % as integer between 1 and 100', description: ''  },
  { name: 'COMSAPPLICATION', label: 'Application(s)', description: ''  },
  { name: 'CONSECUTIVEFLIGHTS', label: 'Consecutive Flights', description: ''  },
  { name: 'COOLOFFPERIOD', label: 'Cool Off Period', description: 'in hrs'  },
  { name: 'CREATEEMAILALERTEXTV', label: 'eXTV Inflight Outage Email Alert', description: ''  },
  { name: 'CREATEEMAILALERTKU', label: 'KU Inflight Outage Email Alert', description: ''  },
  { name: 'CREATEHPTICKETEXTV', label: 'eXTV Inflight Outage HP Ticket', description: ''  },
  { name: 'CREATEHPTICKETKU', label: 'KU Inflight Outage HP Ticket', description: ''  },
  { name: 'CREATEQS37WORKORDER', label: 'Create QS37 workorder', description: 'This will generate a QS37 workorder when the rule conditions are met. If not checked only email alerts will be sent to the recipients.'  },
  { name: 'DAYSBACK', label: 'Days back', description: 'Date range back from today'  },
  { name: 'DAYSBACKMMID', label: 'Days Back', description: 'How far back to look in FSS for MMID matches'  },
  { name: 'DAYSLIST', label: 'Days Back', description: 'Days back / numeric list'  },
  { name: 'DRMACREPORTDAYS', label: 'Days Since A/C last reported', description: 'the number of days since an A/C has last reported DRM keys that will trigger an alert'  },
  { name: 'DRMKEYTHRESH', label: 'DRM Key Threshold', description: ''  },
  { name: 'DRMKEYTTLAVAILCOUNT', label: 'DRM Key Total Availability Count', description: ''  },
  { name: 'DRMKEYTTLAVAILTHRESH', label: 'DRM Key Total Availability Threshold', description: ''  },
  { name: 'EARLYLOGOFF', label: 'KU Early Logoff', description: ''  },
  { name: 'EARLYLOGOFFEXTV', label: 'eXTV Early Logoff', description: ''  },
  { name: 'EARLYLOGOFFOUTAGEDURATION', label: 'KU Early Logoff Duration', description: 'n/a'  },
  { name: 'ENDDATE', label: 'End Date', description: 'End Date'  },
  { name: 'ENDDOM', label: 'End Day of Month', description: ''  },
  { name: 'ENGINEERINGFAULTSCHK', label: 'Show engineering Faults', description: 'Include faults designated as Engineering/Problem Faults, in addition to Maintenance faults.'  },
  { name: 'EXCHANGENAME', label: 'Exchange Name', description: 'Exchange Name'  },
  { name: 'EXCLDTAIL', label: 'Exclude Tail', description: ''  },
  { name: 'EXCLUDEDEFAULTHEADER', label: 'Exclude Default Header', description: 'Exclude the default SmartAlert header.'  },
  { name: 'EXCLUDEDEFAULTSTYLESHEET', label: 'Exclude Default Style Sheet', description: 'Exclude the default SmartAlert Style Sheet.'  },
  { name: 'EXPHONEEARLYLOGOFF', label: 'eXPhone2G Early Logoff', description: 'n/a'  },
  { name: 'EXPHONEEARLYLOGOFFDURATION', label: 'eXPhone2G Early Logoff Duration', description: 'n/a'  },
  { name: 'EXPHONEEARLYLOGOFFEMAIL', label: 'eXPhone2G Early Logoff Email Alert', description: 'n/a'  },
  { name: 'EXPHONEEARLYLOGOFFTICKET', label: 'eXPhone2G Early Logoff HP Ticket', description: 'n/a'  },
  { name: 'EXPHONEINFLIGHT', label: 'eXPhone2G Inflight Outage', description: 'n/a'  },
  { name: 'EXPHONEINFLIGHTDURATION', label: 'eXPhone2G  Inflight Outage Duration', description: 'n/a'  },
  { name: 'EXPHONEINFLIGHTEMAIL', label: 'eXPhone2G Inflight Outage Email Alert', description: 'n/a'  },
  { name: 'EXPHONEINFLIGHTTICKET', label: 'eXPhone2G Inflight Outage HP Ticket', description: 'n/a'  },
  { name: 'EXPHONENOINITLOGIN', label: 'eXPhone2G No Initial Login', description: 'n/a'  },
  { name: 'EXPHONENOINITLOGINDURATION', label: 'eXPhone2G No Initial Login Duration', description: 'n/a'  },
  { name: 'EXPHONENOINITLOGINEMAIL', label: 'eXPhone2G No Initial Login Email Alert', description: 'n/a'  },
  { name: 'EXPHONENOINITLOGINTICKET', label: 'eXPhone2G No Initial Login HP Ticket', description: 'n/a'  },
  { name: 'EXPHONERESTORED', label: 'eXPhone2G Restored Notice', description: 'n/a'  },
  { name: 'EXPHONERESTOREDDURATION', label: 'eXPhone2G Restored Duration', description: 'n/a'  },
  { name: 'EXPHONERESTOREDEMAIL', label: 'eXPhone2G Restored Email Alert', description: 'n/a'  },
  { name: 'EXPHONERESTOREDTICKET', label: 'eXPhone2G Restored HP Ticket', description: 'n/a'  },
  { name: 'EXTVEARLYLOGOFFDURATION', label: 'eXTV Early Logoff Duration', description: 'n/a'  },
  { name: 'EXTVEARLYLOGOFFEMAIL', label: 'eXTV Early Logoff Email Alert', description: 'n/a'  },
  { name: 'EXTVEARLYLOGOFFTICKET', label: 'eXTV Early Logoff HP Ticket', description: 'n/a'  },
  { name: 'EXTVNOINITLOGINDURATION', label: 'eXTV No Initial Login Duration', description: 'n/a'  },
  { name: 'EXTVNOINITLOGINEMAIL', label: 'eXTV No Initial Login Email Alert', description: 'n/a'  },
  { name: 'EXTVNOINITLOGINTICKET', label: 'eXTV No Initial Login HP Ticket', description: 'n/a'  },
  { name: 'EXTVRESTOREDEMAIL', label: 'eXTV Restored Email Alert', description: 'n/a'  },
  { name: 'EXTVRESTOREDTICKET', label: 'eXTV Restored HP Ticket', description: 'n/a'  },
  { name: 'FAULTLRUTYPE', label: 'Fault LRU Type', description: 'This filters the results based on the LRU Type of the fault.'  },
  { name: 'FAULTSTATUSMULTISELECT', label: 'Fault Status / Activity Code', description: 'This filters the results based on the status of the fault.'  },
  { name: 'FLIGHTTIMELENGTH', label: 'Flight Length', description: 'in minutes'  },
  { name: 'FMROPERATOR', label: 'FMR Operator', description: 'Operator ID for FMR Operators'  },
  { name: 'FMRWRITEUPCOUNT', label: 'FMR Write-up Count', description: 'The count of FMR write-ups for a seat.'  },
  { name: 'FUSSYSTCNT', label: 'Occurrence Count', description: 'Fussy Seat occurrence count'  },
  { name: 'GCSFLIGHTID', label: 'GCS Flight ID', description: ''  },
  { name: 'GROUNDFAULTSCHK', label: 'Show Ground Faults', description: 'This will include faults recorded in the ground leg. Ground faults are excluded from system performance metrics and occur prior to the air leg.'  },
  { name: 'HOSTNAME', label: 'Host Name', description: 'Specified host name'  },
  { name: 'HOURSBACK', label: 'Hours Back', description: ''  },
  { name: 'HOURSBACKMMID', label: 'Hours back', description: 'How far back to look in FSS for MMID matches'  },
  { name: 'INFLIGHTOUTAGE', label: 'KU Inflight Outage', description: ''  },
  { name: 'INFLIGHTOUTAGEEXTV', label: 'eXTV Inflight Outage', description: ''  },
  { name: 'KUEARLYLOGOFFEMAIL', label: 'KU Early Logoff Email Alert', description: 'n/a'  },
  { name: 'KUEARLYLOGOFFTICKET', label: 'KU Early Logoff HP Ticket', description: 'n/a'  },
  { name: 'KUNOINITLOGINEMAIL', label: 'KU No Initial login Email Alert', description: 'n/a'  },
  { name: 'KUNOINITLOGINTICKET', label: 'KU No Initial Login HP Ticket', description: 'n/a'  },
  { name: 'KURESTOREEMAIL', label: 'KU Restored Email Alert', description: 'n/a'  },
  { name: 'KURESTORETICKET', label: 'KU Restored HP Ticket', description: 'n/a'  },
  { name: 'LOGIN', label: 'Login', description: 'Login'  },
  { name: 'MASTCATEGORY', label: 'MAST Catagory', description: 'The unique category of MAST'  },
  { name: 'MASTCODE', label: 'MAST Code', description: 'The unique code of MAST. Used when selecting Code/Category other than PACWISP.'  },
  { name: 'MASTEVALUATIONPERIOD', label: 'MAST Evaluation Period', description: 'Look back x data points - The number of the most recent periods, or data points, to evaluate when determinint alarm state.'  },
  { name: 'MASTOPERATORICAOMULTI', label: 'MAST Operator ICAO', description: 'The unique ICAO code for the operator found in index mast_cli'  },
  { name: 'MASTPERCENTAGEDATAPOINTSTOALARM', label: 'MAST Data Points to Alarm', description: 'Percentage of how many need to fail to trigger. The percentage of data points within the evaluation period that must be in error state to cause the alert to be triggered. The error data points don\'t have to be consecutive, they just must all be within the last number of data points equal to MAST Evaluation Period.'  },
  { name: 'MMIDMULTISELECT', label: 'MMID', description: ''  },
  { name: 'NOINITIALLOGIN', label: 'KU No Initial Login', description: ''  },
  { name: 'NOINITIALLOGINEXTV', label: 'eXTV No Initial Login', description: ''  },
  { name: 'NOINITIALLOGINOUTAGEDURATION', label: 'KU No Initial Login Duration', description: 'n/a'  },
  { name: 'NOUSAGETHRESHOLDMB', label: 'No Usage Threshold Mbit/sec', description: 'Used for comparing each usage sample. If Mega bit per second is less than this threshold, then treat the sample as no usage.'  },
  { name: 'OCCURRENCE', label: 'Occurrence Count', description: 'Fault Occurrence'  },
  { name: 'OPERATORICAO', label: 'Operator ICAO', description: 'The unique ICAO code for the operator'  },
  { name: 'OPERATORICAOMULTIFILTER', label: 'Operator(s)', description: ''  },
  { name: 'OPERATORID', label: 'Operator ID', description: 'The operator ID eg 367 for DLH'  },
  { name: 'OUTAGEDURATION', label: 'KU Inflight Outage Duration', description: ''  },
  { name: 'OUTAGEDURATIONEXTV', label: 'eXTV Inflight Outage Duration', description: ''  },
  { name: 'OUTAGELENGTH', label: 'Channel Outage', description: 'in seconds'  },
  { name: 'OUTAGELENGTHPERCENT', label: 'Channel Outage', description: '% percentage'  },
  { name: 'OUTAGEPERIODMINS', label: 'Outage Period Minutes', description: 'The number of minutes of consecutive no usage, or usage lower than a threshold that is to be tested for.'  },
  { name: 'PCTACCELERATION', label: 'Percentage of Acceleration', description: ''  },
  { name: 'PERIODSTARTYMD', label: 'Avg Days Start', description: ''  },
  { name: 'PIPENAME', label: 'Pipe Name', description: ''  },
  { name: 'PIPENAMEMULTI', label: 'Pipe Name(s)', description: ''  },
  { name: 'PORT', label: 'Port', description: 'Port'  },
  { name: 'PROFITMETRICS', label: 'Profit Metrics', description: 'Deliver profit report.'  },
  { name: 'QUEUENAME', label: 'Queue Name', description: 'Queue Name'  },
  { name: 'RATEOFCHANGE', label: 'Rate of Change', description: '( % )'  },
  { name: 'RAWDATACHK', label: 'Show raw data', description: 'Excluded faults are faults that have been determined to have no impact on performance metrics.'  },
  { name: 'RECONNECTIONNOTICE', label: 'KU Restored Notice', description: ''  },
  { name: 'RECONNECTIONNOTICEEXTV', label: 'eXTV Restored Notice', description: ''  },
  { name: 'REGIONSINGLEFILTER', label: 'Region', description: ''  },
  { name: 'RELIABILITYOPERATOR', label: 'Reliability Operator', description: ''  },
  { name: 'RELIABILITYSHOP', label: 'Reliability Shop', description: ''  },
  { name: 'RESTOREDDURATION', label: 'KU Restored Duration', description: ''  },
  { name: 'RESTOREDDURATIONEXTV', label: 'eXTV Restored Duration', description: ''  },
  { name: 'RESULTSUMMARY', label: 'Results Summary', description: 'Results Summary, use to report when you have a long string or blob of text'  },
  { name: 'RETRIGGERDAYS', label: 'Repair Days (before escalation)', description: '"Cool off" period after a rule is triggered'  },
  { name: 'REVENUEMETRICS', label: 'Revenue Metrics', description: 'Deliver eXConnect performance report.'  },
  { name: 'ROUTINGKEY', label: 'Routing Key', description: 'Routing Key'  },
  { name: 'RULEADVISORY', label: 'Rule Advisory', description: 'The advisory for a rule'  },
  { name: 'RULEDESC', label: 'Rule Description', description: 'The description of a rule'  },
  { name: 'RULENOTES', label: 'Rule Notes', description: 'The notes for a rule'  },
  { name: 'RUNNINGAVGDAYS', label: 'Running Avg Days', description: ''  },
  { name: 'SEATEVENTCOUNT', label: 'Seat Event Count', description: 'The count of events for a seat.'  },
  { name: 'SEATFAULT', label: 'Faults per Seat', description: 'Min reported faults per seat'  },
  { name: 'SEATREBOOT', label: 'Reboots per Seat', description: 'Min reported reboots per seat'  },
  { name: 'SEATRESET', label: 'Resets per Seat', description: 'Min reported resets per seat'  },
  { name: 'SLAAVAIL', label: 'SLA Availability', description: 'this flag references the MCC admin sig event Availability rule parameter'  },
  { name: 'SLAUSAGE', label: 'SLA Usage', description: ' this flag references the MCC admin sig event Usage rule parameter'  },
  { name: 'SRPERCENTASINTEGER', label: 'Repair Rate % as integer between 1 and 100', description: ''  },
  { name: 'STARTDATE', label: 'Start Date', description: 'Start Date'  },
  { name: 'STARTDOM', label: 'Start Day of Month', description: ''  },
  { name: 'SYSTEMTYPE', label: 'System Type', description: 'The IFE System Type on the aircraft'  },
  { name: 'TAIL', label: 'Tail (Registration Name)', description: 'Registration Name'  },
  { name: 'TRIGGERONDAYSOFHISTORY', label: 'Trigger on Days of History', description: 'Number of days of history used to validate trigger thresholds.'  },
  { name: 'USAGEEST', label: 'Usage Estimate', description: ''  },
  { name: 'VIEWHISTORY', label: 'View History', description: 'Number of intervals to display'  },
  { name: 'WEEKSHISTORYTOREPORT', label: 'Weeks of History to Report', description: 'Weeks of history to report for triggered seats.'  },
  { name: 'ZONEFAULT', label: 'Faults per Zone', description: 'Min reported faults per zone'  },
  { name: 'ZONEREBOOT', label: 'Reboots per Zone', description: 'Min reported reboots per zone'  },
  { name: 'ZONERESET', label: 'Reboots per Zone', description: 'Min reported reboots per zone'  },

];

const ruletypes = [
  { code: 'ALERTPARTSUSAGEBYICAO', name: 'Alert Parts Usage Over Norm by ICAO', attributes: [ "RELIABILITYOPERATOR","PCTACCELERATION","ATTRALERTPARTICAORERPT" ]},
  { code: 'ALERTPARTSUSAGEBYSHOP', name: 'Alert Parts Usage Over Norm by Shop', attributes: [ "RELIABILITYSHOP","PCTACCELERATION","ATTRALERTPARTSHOPRPT" ]},
  { code: 'APPLICATIONMONITORING', name: 'Application Monitoring', attributes: [ "OPERATORICAOMULTIFILTER","COMSAPPLICATION","PIPENAMEMULTI","RATEOFCHANGE","HOURSBACK" ]},
  { code: 'BUILDDEPRECATIONPSE', name: 'Build Deprecation Notification', attributes: [ "OPERATORICAOMULTIFILTER" ]},
  { code: 'CHURNBYSHOP', name: 'Repair Rate by Shop', attributes: [ "RELIABILITYSHOP","CHURNPERCENTASINTEGER","SRPERCENTASINTEGER","ATTRALERTCHURNSHOPRPT" ]},
  { code: 'CPCSVCNW5X5', name: 'Service Now - 5 X 5', attributes: [ "OPERATORICAOMULTIFILTER" ]},
  { code: 'DASHBOARD', name: 'Dashboard Email Subscription', attributes: [ "EXCLUDEDEFAULTHEADER","EXCLUDEDEFAULTSTYLESHEET" ]},
  { code: 'DATAUSAGEHISTORY', name: 'Consecutive Flights No Users', attributes: [ "DAYSBACK","OPERATORICAOMULTIFILTER","CONSECUTIVEFLIGHTS" ]},
  { code: 'DATAUSAGESPIKES', name: 'Data Usage Spikes', attributes: [ "RATEOFCHANGE","PIPENAME" ]},
  { code: 'DRMACNOREPORT', name: 'DRM A/C non-reporting', attributes: [ "OPERATORICAO","DRMACREPORTDAYS","RESULTSUMMARY" ]},
  { code: 'DRMKEYS', name: 'DRM Key Usage', attributes: [ "OPERATORID","DRMKEYTHRESH" ]},
  { code: 'DRMKEYSTTLAVAIL', name: 'DRM Key Total Availability', attributes: [ "DRMKEYTTLAVAILTHRESH","DRMKEYTTLAVAILCOUNT" ]},
  { code: 'DRMKEYSUSAGEEST', name: 'DRM Keys Usage Estimate', attributes: [ "PERIODSTARTYMD","RUNNINGAVGDAYS","USAGEEST","ALERTDAYS" ]},
  { code: 'EXCESSIVEHWREMOVAL', name: 'Accelerating Removals by ICAO', attributes: [ "RELIABILITYOPERATOR","PCTACCELERATION","ATTRHWFAILREREPORT" ]},
  { code: 'FARPRT', name: 'Flight Analysis Report', attributes: [ "OPERATORID","SLAAVAIL","SLAUSAGE","EXCLDTAIL","EMAIL_SUBJECT","EMAIL_BODY","GCSFLIGHTID" ]},
  { code: 'FMRDAILY', name: 'FMR Daily Report', attributes: [ "FMROPERATOR","RULEDESC" ]},
  { code: 'FUSSYSEAT', name: 'Repeat Defects Rules', attributes: [ "OPERATORID","SYSTEMTYPE","AFFECTEDFUNCTION","FUSSYSTCNT","DAYSBACK","RETRIGGERDAYS","RULEDESC","RULEADVISORY","RULENOTES" ]},
  { code: 'GCSDAILYREPORT', name: 'GCS Daily Reporting', attributes: [ "OPERATORID","REVENUEMETRICS","PROFITMETRICS","REGIONSINGLEFILTER","RULEDESC" ]},
  { code: 'GCSNOUSAGE', name: 'No Usage Alerts', attributes: [ "OPERATORID","TAIL","OUTAGEPERIODMINS","NOUSAGETHRESHOLDMB" ]},
  { code: 'GCSNOUSAGELIVE', name: 'GCS No Usage LIVE', attributes: [ "OPERATORID","TAIL","OUTAGEPERIODMINS" ]},
  { code: 'LIAAA', name: 'LIAAA', attributes: [ "HOSTNAME","PORT","LOGIN","PASSWORD","QUEUENAME","EXCHANGENAME","ROUTINGKEY" ]},
  { code: 'MASTDATASOURCEALERTS', name: 'MAST Data Source Alerts', attributes: [ "RULENOTES","COOLOFFPERIOD","MASTCATEGORY","MASTEVALUATIONPERIOD","MASTPERCENTAGEDATAPOINTSTOALARM","MASTOPERATORICAOMULTI","VIEWHISTORY","MASTCODE" ]},
  { code: 'MCCCOMMAUTOTRANSITION', name: 'MCC Commissioning Form Auto Transition', attributes: [ "DAYSBACK","EMAIL_BODY" ]},
  { code: 'MCCCOMMDATENOTIFICATION', name: 'MCC 30 Day Commissioning Notification', attributes: [ "DAYSBACK","EMAIL_BODY" ]},
  { code: 'MEDIABITEFAULTS', name: 'Media BITE Faults Report', attributes: [ "OPERATORICAO","MMIDMULTISELECT","STARTDOM","ENDDOM","EMAIL_SUBJECT","RESULTSUMMARY" ]},
  { code: 'MISEXCLMAP', name: 'Missing Exclusion Map', attributes: [ "EMAIL_SUBJECT","EMAIL_BODY" ]},
  { code: 'MMID', name: 'MMID/MMN Occurrence Rules', attributes: [ "OCCURRENCE","MMIDMULTISELECT","OPERATORICAOMULTIFILTER","APPRESETSCHK","CREATEQS37WORKORDER","FAULTSTATUSMULTISELECT","GROUNDFAULTSCHK","SYSTEMTYPEMULTISELECT","FAULTLRUTYPE","ENGINEERINGFAULTSCHK","RAWDATACHK","RETRIGGERDAYS","RULEDESC","RULEADVISORY","RULEADVISORY2","STARTDATE","ENDDATE" ]},
  { code: 'MMIDHEADENDLRUHISTORY', name: 'Headend LRUs MMID Fault History', attributes: [ "OPERATORICAO","MMIDMULTISELECT","HOURSBACKMMID","RULEADVISORY" ]},
  { code: 'MMIDHISTORY', name: 'MMID Fault History', attributes: [ "DAYSBACKMMID","OPERATORICAO","MMIDMULTISELECT" ]},
  { code: 'NOOFFLOAD', name: 'Tails with no recent offloads', attributes: [ "OPERATORID","DAYSLIST","RULEDESC","RULEADVISORY","RULENOTES" ]},
  { code: 'OUTAGENOTICES', name: 'Outage Notices', attributes: [ "OPERATORICAO","OUTAGEDURATION","RESTOREDDURATION","INFLIGHTOUTAGE","NOINITIALLOGIN","EARLYLOGOFF","RECONNECTIONNOTICE","CREATEHPTICKETKU","CREATEEMAILALERTKU","RECONNECTIONNOTICEEXTV","EARLYLOGOFFEXTV","NOINITIALLOGINEXTV","INFLIGHTOUTAGEEXTV","RESTOREDDURATIONEXTV","OUTAGEDURATIONEXTV","CREATEHPTICKETEXTV","CREATEEMAILALERTEXTV","NOINITIALLOGINOUTAGEDURATION","EARLYLOGOFFOUTAGEDURATION","KUEARLYLOGOFFTICKET","KUEARLYLOGOFFEMAIL","KUNOINITLOGINTICKET","KUNOINITLOGINEMAIL","KURESTORETICKET","KURESTOREEMAIL","EXPHONEEARLYLOGOFF","EXPHONEEARLYLOGOFFDURATION","EXPHONEEARLYLOGOFFTICKET","EXPHONEEARLYLOGOFFEMAIL","EXPHONENOINITLOGIN","EXPHONENOINITLOGINDURATION","EXPHONENOINITLOGINTICKET","EXPHONENOINITLOGINEMAIL","EXPHONEINFLIGHT","EXPHONEINFLIGHTDURATION","EXPHONEINFLIGHTTICKET","EXPHONEINFLIGHTEMAIL","EXPHONERESTORED","EXPHONERESTOREDDURATION","EXPHONERESTOREDTICKET","EXPHONERESTOREDEMAIL","EXTVEARLYLOGOFFDURATION","EXTVEARLYLOGOFFTICKET","EXTVEARLYLOGOFFEMAIL","EXTVNOINITLOGINDURATION","EXTVNOINITLOGINTICKET", ]},
  { code: 'PAALERTSSTAGNANT', name: 'PA Alerts - Stagnant PA', attributes: [ "DAYSBACK","EMAIL_BODY" ]},
  { code: 'PACWISPTXNDATA', name: 'PACWISP Transaction Data Alerts', attributes: [ "OPERATORICAO","ALERTFREQUENCY" ]},
  { code: 'PACWISPUSGDATA', name: 'PACWISP Usage Data Alerts', attributes: [ "OPERATORICAO","ALERTFREQUENCY" ]},
  { code: 'SATTVCHANOUT', name: 'SAT TV Channel Outages', attributes: [ "OPERATORICAOMULTIFILTER","OUTAGELENGTH","COOLOFFPERIOD","FLIGHTTIMELENGTH","OUTAGELENGTHPERCENT" ]},
  { code: 'SEATEVENTS', name: 'Seat Events', attributes: [ "OPERATORID","TAIL","ACSEATCLASS","TRIGGERONDAYSOFHISTORY","WEEKSHISTORYTOREPORT","FMRWRITEUPCOUNT","SEATEVENTCOUNT","RULEDESC" ]},
  { code: 'SEATIMPACT', name: 'Impacted Seats', attributes: [ "OPERATORID","SEATREBOOT","ZONEREBOOT","SEATRESET","ZONERESET","SEATFAULT","ZONEFAULT","RULEDESC" ]},
];



var rule_list = [
  <MenuItem key="ALL" value="ALL">All Attributes</MenuItem>,
];

ruletypes.forEach((element) => {
  rule_list.push(  <MenuItem key={element.code} value={element.code}>{element.name}</MenuItem>,
);
});



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function QueryBuilder1(props) {

/*
  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(props.value?props.value:queryValue), config),
    config: config
  });
*/
  const [tab, setTab] = useState(0);
  //const [rule, setRule] = useState("ALL");
  const [error, setError] = useState("");
  //const [json, setJSON] = useState("");
  const [query, setQuery] = useState();
  const [queryType, setQueryType] = useState("ALL");
  
  var fields = [];
  if (queryType === 'ALL') {
    fields = attributes;
  }
  else {
    attributes.forEach((attribute) => {
      query.rules.forEach((rule) => {
        if (rule.field === attribute.name) {
              fields.push(attribute);
        }
      });
      ruletypes.forEach((ruletype) => {
        if (ruletype.code === queryType) {
            logger.debug("ruletypes", ruletype.attributes, attribute.code);
            if (ruletype.attributes.indexOf(attribute.name) !== -1) {
              fields.push(attribute);
            }
        }
      });
    });
  }
  /*
  const onQueryChange = useCallback((immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    logger.debug("onChange", immutableTree, config);
    setState({ ...state, tree: immutableTree, config: config });

    const jsonTree = QbUtils.getTree(immutableTree);
    console.log(jsonTree);
    props.handleChange({target: {name: props.name, value: JSON.stringify(jsonTree)}});
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }, []);
  */
  
  const renderBuilder = useCallback((props) => (
    <div className="query-builder-container" >
      <div className="query-builder qb-lite" >
        <Builder {...props} style={{backgroundColor: "#ffffff"}}/>
         <div style={{color: "#000000"}}>
          Hover your mouse over the query builder to add items.
        </div>
      </div>

    </div>
  ), []);

  const handleTabChange = (event, newValue) => {
    if (newValue === 1) {
      //setJSON(JSON.stringify(logicJSON));

    }
    setError(""); 
    setTab(newValue);
  };
/*
  const handleRuleChange = (event) => {
    logger.debug("handleRuleChange", event);
    setRule(event.target.value);
  };
*/

  const onTextChange = useCallback((event) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    logger.debug("onTextChange", event);
    
    return;
    
    var error = false;
    var value = event.target.value;
    //setJSON(event.target.value);
    if (value.trim() != "") {

      try {
      var json = JSON.parse(event.target.value);
      }
      catch(e) {
        error = "Invalid JSON";
      }
      logger.debug("onTextChange error", error);
    }
    if (error !== false) {
      setError(error);
    }
    else {
      setError("");
      //var treeObj = checkTree(loadFromJsonLogic(json, state.config), state.config); // <- this will work same
     // logger.debug("onTextChange checktree", treeObj);

      /*
      if (treeObj) {
        setState({
          ...state,
          tree: treeObj,
        });
      }
      else if (value.trim() != "") {
          error = "Invalid structure";
      }
      */
    }
    setError(error);
  }, []);

  //var valueJSON = QbUtils.jsonLogicFormat(state.tree, state.config).data;
  //var logicJSON = QbUtils.jsonLogicFormat(state.tree, state.config).logic;
  //var errorsJSON = QbUtils.jsonLogicFormat(state.tree, state.config).errors;
  var displayError = '';
  if (error) displayError = error;
  else {
    // check errorsJSON
  }


  return (
    <div>
      <Tabs value={tab} onChange={handleTabChange} aria-label="simple tabs example">
        <Tab key="builder" label="Builder" {...a11yProps(0)} />
        <Tab key="JSON" label="JSON" {...a11yProps(1)} />
        {/*}
        <Tab label="SQL" {...a11yProps(2)} />
        <Tab label="MongoDB" {...a11yProps(3)} />
        <Tab label="CEL" {...a11yProps(4)} />
        <Tab label="SpEL" {...a11yProps(5)} />
        <Tab label="JsonLogic" {...a11yProps(6)} />
        <Tab label="ElasticSearch" {...a11yProps(7)} />
        <Tab label="JSONata" {...a11yProps(8)} />
        */}
        
      </Tabs>
      <div hidden={tab !== 0} style={{paddingRight: "8px"}}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={queryType}
          onChange={
            (event) => {
              setQueryType(event.target.value);
            }
          }
          style={{margin: "8px 0px 8px 10px"}}
        >
         {rule_list}
        </Select>
        
        <QueryBuilder fields={fields} query={query} onQueryChange={setQuery} />
        
      </div>
      <div hidden={tab !== 1} style={{padding: "28px"}} >
        <TextField
          fullWidth
          id="filled-multiline-flexible"
          label=""
          multiline
          maxRows={20}
          value={formatQuery(query, 'json')}
          //onChange={handleChange}
          variant="outlined"
          inputProps={{style: {fontFamily: "Lucida Console, Courier New, monospace", fontSize: "14px"}}}
          onChange={onTextChange}
        />
        <div style={{color: "#ff0000"}}>
          {displayError}
        </div>
      </div>
      <div hidden={tab !== 2} className="query-builder-result">
       
        <div>
          Query string:{" "}
          <pre>
            {typeof(query) !== "undefined"?formatQuery(query, "json"):""}
          </pre>
        </div>
         <div>
          Query string:{" "}
          <pre>
            {//typeof(query) !== "undefined"?formatQuery(query, "jsonlogic"):""
            }
          </pre>
        </div>
        <div>
          MongoDb query:{" "}
          <pre>
            {//JSON.stringify(QbUtils.mongodbFormat(state.tree, state.config))
            }
          </pre>
        </div>
        <div>
          SQL where:{" "}
          <pre>
             {typeof(query) !== "undefined"?formatQuery(query, "sql"):""}
          </pre>
        </div>
        <div>
          JsonLogic:{" "}
          <pre>
            {//JSON.stringify(QbUtils.jsonLogicFormat(state.tree, state.config))
            }
          </pre>
        </div>
      </div>
    </div>
  );
}
/**
 * Use this to build a view from
 */ 
import React, {useState}  from 'react';
import View   from 'pac-responsive-ui-framework/core/View';
import Map    from 'components/Map';

import MapService      from '../services/MapService';



export default () => {
  
   const [geoData, setGeoData] = useState(null);
 
  const service = new MapService();
  service.fetchRecords({flightid: 8183021})
    .then((data) => {
      if (data['meta'].result_count > 0) 
        if (!geoData)
          setGeoData(MapService.parseData(data));
      
    }).catch((err) => {
      console.log("fetchRecords:error", err);  
      // do something here.
    });
 
 /*
  const [count, setCount] = useState(0);
  
  var test_cases = [
    
    require('../data/GeoData/GeoData.json'),
    
    require('../data/GeoData/GeoData.point.1.json'),
    require('../data/GeoData/GeoData.point.2.json'),
    require('../data/GeoData/GeoData.point.3.json'),
    require('../data/GeoData/GeoData.point.4.json'),
    require('../data/GeoData/GeoData.linestring.json'),
    require('../data/GeoData/GeoData.polygon.1.json'),
    require('../data/GeoData/GeoData.polygon.2.json'),
    require('../data/GeoData/GeoData.multipoint.json'),
    require('../data/GeoData/GeoData.multilinestring.1.json'),
    
    require('../data/GeoData/GeoData.circle.json'),
    require('../data/GeoData/GeoData.circlemarker.json'),
    require('../data/GeoData/GeoData.multipolygon.1.json'),
    require('../data/GeoData/GeoData.multipolygon.2.json'),
    require('../data/GeoData/GeoData.geometrycollection.json'),
    
    //require('../data/GeoData/GeoData.Feature.Point.json'),
    //require('../data/GeoData/GeoData.Feature.Polygon.json'),
    require('../data/GeoData/GeoData.Icons.json'),
    

  ]
  
  setTimeout(function() {
    var c = count;
    c++;
    if (c >= test_cases.length) c = 0;
    setCount(c);
    console.log("Map1", "Timeout");
  }, 500);
  
  var data = test_cases[count];
  data = geoData;
 */
  return (
    <View fixedContent={true} title="Map Example">
        <Map data={geoData} />
    </View>
  );
};
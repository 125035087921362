//import { Logger }                   from 'aws-amplify';
//const logger = new Logger('ColumnPercent');

/**
 * This function changes the value of the data, from 0.XXXX to XX.XX %
 * This changes the actual value so should not be used directly, unless
 * you have custom filters defined as well (see valueGetter below)
 */ 
function valueFormatter(params) {
    if (params.value !== "" || params.value !== undefined || params.value !== null) {
        return (params.value).toFixed(2) + ' %';
    }
    return params.value;
}

/**
 * This formats the value from 0.xxxx to xx.xx
 */ 
function valueGetter(params) {
    if (params.data)
        return params.data[params.colDef.field] * 100;
    else return null;
    
}   

/**
 * This class is used in the rendering of a percent column.  
 * It formats the cell by wrapping the conent in a <span> tag
 * and then uses the valueFormatter to convert it to a XX.XX % format.
 * 
 */
class renderer {
    init(params) {
        if (params.value) {
            console.log('operatorIcaoCodeCellRenderer.init', params);
            this.eGui = document.createElement('span');
            if (params.value !== "" || params.value !== undefined || params.value !== null) {
                
                this.eGui.innerHTML = valueFormatter(params);
            }
        }
    };   

    getGui() {
        return this.eGui;
    };
}

export default { valueFormatter, valueGetter, renderer }


/**
 * Operational view for viewing and editing the reports system and menu
 */

import React, {useContext} from 'react';
import { Logger } from 'aws-amplify';
import AppContext               from 'pac-responsive-ui-framework/core/AppContext';
import View from 'pac-responsive-ui-framework/core/View';
import Typography from '@material-ui/core/Typography';

import AGGrid from '../components/AGGrid';
import ReportsService from '../services/ReportsService';
//import { constants } from 'assets/Config.js';
import Home         from './Home';
import DeployEnvironemtnButton from '../components/AGGrid/DeployEnvironmentButton';


const logger = new Logger('OpsReports');

export default function OpsReports(props) {
    logger.debug(props);
    const [footer, setFooter] = React.useState(null);
    const [key, setKey] = React.useState(0);
    var app_context = useContext(AppContext);
        
    const service = new ReportsService("OriginsAPI", false);
    service.setPermissions(app_context.state.user.session.acl_role);
    
    if (props.recordID !== "origins" && props.recordID !== "dartce") { 
        logger.debug("invalid Record ID", props.recordID);
        return (<Home/>);
    }
    
    const refCallback = (meta) => {
        setFooter(<DeployEnvironemtnButton recordID={props.recordID} handleRefresh={meta.handleRefresh} />);
        setKey(1);
    };
    
    var header = <Typography variant="h5">Menu data for {props.recordID}</Typography>;
    
    return (
        <View title={props.title} fixedContent={true} key={key}>
            <AGGrid 
                service={service} 
                recordID={props.recordID} 
                columnDefs={service.getColumnDefs()} 
                filename="operations_reports_list" 
                header={header} 
                footer={footer}
                refCallback={refCallback}
            />
       </View>
    );
}

import { Auth, Logger } from 'aws-amplify';
 
const logger = new Logger('KeepAlive');
 
let keepAliveInterval = null;
 
let interval = 30 * 60 * 1000; // 30 minutes
 
const doRefresh = () => {
 
    Auth.currentAuthenticatedUser().then(user => {
 
        logger.debug('keep alive user: ', user);
         
        const refreshToken = user.signInUserSession.refreshToken;
 
        user.refreshSession(refreshToken, () => {
            console.log('keep alive refresh called @ ', new Date());
        });
 
 
    }).catch(err => {
        logger.debug('keep alive error @ ', new Date());
        logger.debug(err);
    });
 
};
 
 
export const runKeepAlive = () => {
    if (keepAliveInterval) clearInterval(keepAliveInterval);
 
    console.log('keep alive start @ ', new Date());
 
    keepAliveInterval = setInterval(() => {
        doRefresh();
    }, interval);
 
}
/**
 * Report view page for all reports: Kibana and QuickSight
 *
 */
import _ from 'lodash';
import React, { useState, useContext } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Container from '@material-ui/core/Container';
import View from 'pac-responsive-ui-framework/core/View';
import Home from '../views/Home';
//import DatePickerContextMenu from '../components/DatePickerContextMenu';
import { setReportList, getReportDetails, getQuicksightEmbedURL } from '../data/ReportList';

import LoadingView from '../views/Loading';

import { Auth, API, Logger } from 'aws-amplify';

import axios from 'axios';
//import dateMath from '@elastic/datemath';
import {
  //  BrowserRouter as Router,
  //  Switch,
  Route,
  useHistory,
  //  Link,
  //  useRouteMatch,
  useParams,
  //  useLocation
}
from 'react-router-dom';

import AppContext         from 'pac-responsive-ui-framework/core/AppContext';
import PageVisitService   from '../services/PageVisitService';
import { constants } from 'assets/Config.js';

// custom reports
import FlightList   from 'views/FlightList';
import LiveBeamStatus   from 'views/LiveBeamStatus';

//import LiveFlights   from 'views/LiveFlights';

import QuickSight from '../components/QuickSight';

const logger = new Logger('views/Report');
const page_visit_service = new PageVisitService();

function logReport(app_context, report_details, url) {
    var timestamp = new Date().getTime();
    logger.debug('logReport', timestamp, report_details, app_context, url);
    if (!_.get(app_context, 'state.user.session.recent_reports', false)) _.set(app_context, 'state.user.session.recent_reports', []);
    app_context.state.user.session.recent_reports.unshift({time: timestamp, report: report_details.title});

    page_visit_service.add({
      report_name: report_details.title,
      report_url: url,
      operator_icao_code: app_context.state.user.session.operator_icao_code,
      app_name: constants.app,
      state_change: ""

    }).then((data) => {
      logger.debug("PageVisitService success", data);
    }).catch((err) => {
      logger.debug('PageVisitService error', err);
    });
}

export default function Report(props) {

  logger.debug("constructor", props);
  var app_context = useContext(AppContext);
  //console.log('APP CONTEXT')
  //console.log(app_context);

  //console.log(app_context.state.start_date, app_context.state.end_date)
  setReportList(app_context.state.reports_list);

  let history = useHistory();

  //const [operator, setOperator] = React.useState(app_context.state.user.session.preferred_operator);
  const [operator, setOperator] = React.useState(app_context.state.default_operator_icao_code);
  const [start_date, setStartDate] = React.useState(app_context.state.start_date);
  const [end_date, setEndDate] = React.useState(app_context.state.end_date);

  console.log('report_params', operator, start_date, end_date);

  const [state, setState] = useState({
    isLoading: true,
    start:'',
    end: ''
  });

  let { reportId } = useParams();




  var report_details = getReportDetails(reportId,
      app_context.state.user.session.acl_role,
      app_context.state.user.session.pac_icao_code,
      app_context.state.default_operator_icao_code);
  logger.debug('report_details', report_details);

  logger.debug("report_details", report_details);

  //Check if report is restricted to particular environment
  if (!report_details || (Array.isArray(report_details.report_environments) && report_details.report_environments.includes(process.env.REACT_APP_STAGE)===false)) {
      // redirect user to home page if the report is invalid
      history.push('/');
      return;
  }
   else {
    //continue
    //logReport(report_details, app_context);
  }

  logger.debug('report_details:', report_details);

  if (report_details.report_type === 'quicksight' && state.isLoading){
      logger.debug("quicksight report_details 1", report_details);
      if(!report_details.report_href.includes('getembedurl')){
         logger.debug("quicksight report_details 2", report_details.report_href);
          //check time since last embed - Quicksight dashboard signed URL is only valid for 5 minutes
          var url_params = new URLSearchParams(report_details.report_href);
          var time_param = url_params.get('_');
          var current_time = new Date();
          var user_operator_override = url_params.get('user_operator_override');
          logger.debug("user_operator_override:",user_operator_override);
          logger.debug("app_context.state.default_operator_icao_code:", app_context.state.default_operator_icao_code);



          //[JW] - Removing cache logic due to Operator override now needing to make the embed call for RLS ACL each time when set.
          if (constants.overwrite_roles_by_icao && app_context.state.default_operator_icao_code && user_operator_override!=app_context.state.default_operator_icao_code && app_context.state.default_operator_icao_code!='---') {
            report_details.report_href = getQuicksightEmbedURL();
          }
          else if(user_operator_override && app_context.state.default_operator_icao_code=='---'){

            //reset condition when switching back to No Operator Selected ---
            //app_context.state.default_operator_icao_code = undefined;
            //user_operator_override = app_context.state.default_operator_icao_code;
            report_details.report_href = getQuicksightEmbedURL();
          }
          else{

            var seconds = (current_time - time_param) / 1000;
            if(seconds > 5){
              report_details.report_href = getQuicksightEmbedURL();
            }
          }
      }
      else {
            report_details.dashboard_url = report_details.report_href;
      }
  }

  let currentSession = Auth.currentSession().then(user => {
    logger.debug('axios currentSession1', user);
    let jwt_token = user.idToken;
    logger.debug('currentJWT:',jwt_token);
    logger.debug('report_details:',report_details);
    if (report_details.report_type === 'quicksight'){
      logger.debug('axios quicksight', report_details.report_href);
      if (report_details.report_href.includes('getembedurl')) {
        logger.debug('axios quicksight includes getembedurl');
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': jwt_token
        };
        logger.debug("axios currentSession2", constants, app_context.state, report_details);
        var dashboard_id = report_details.dashboard_id;
        if (process.env.REACT_APP_STAGE == "dev" && report_details.dashboard_id_dev) dashboard_id = report_details.dashboard_id_dev;
        if (process.env.REACT_APP_STAGE == "test" && report_details.dashboard_id_test) dashboard_id = report_details.dashboard_id_test;

        let data = {
          'dashboard_id': dashboard_id,
          'jwt_token': jwt_token
        };
        logger.debug("currentSession3", constants, app_context.state, report_details, data);

        if (constants.overwrite_roles_by_icao && app_context.state.default_operator_icao_code && app_context.state.default_operator_icao_code != '---') {
          data.user_operator_override = app_context.state.default_operator_icao_code;
        }

        logger.debug("currentSession4", constants, app_context.state, report_details, data);
        var cache_bust = '?_='+ new Date().getTime();
        var time_tracker = '&_='+ new Date().getTime();
        var user_operator_override='';
        if(data.user_operator_override){
          user_operator_override = '&user_operator_override=' + data.user_operator_override;
        }
        logger.debug("axios0:", state, operator, start_date, end_date, props, report_details.report_href + cache_bust);
          axios.post(report_details.report_href + cache_bust , data, {
            headers: headers
          })
          .then((response) => {
            logger.debug("axios1:", response);
            logger.debug("axios2:", response.data.response.EmbedUrl);
            report_details.report_href = response.data.response.EmbedUrl+user_operator_override+time_tracker;
            logReport(app_context, report_details, response.data.response.EmbedUrl);
            logger.debug('REPORT---->',report_details.start,report_details.end);

            setState({
              start: (report_details.start) ? report_details.start : start_date,
              end: (report_details.end) ? report_details.end : end_date,
              isLoading: false
            });
          })
          .catch((error) => {
            logger.debug("axios3", error);
          });

      }
    }

  });



  console.log("next section");

  const renderReport = (state, props) => {


    console.log('APP CONTEXT DATES--->',app_context.state.start_date, app_context.state.end_date);
    if(app_context.state.start_date && app_context.state.end_date){
      state.start = app_context.state.start_date + ' GMT+0000';
      state.end = app_context.state.end_date + ' GMT+0000';
    }
    logger.debug("renderReport", state, props);
    //var key = Date.now();
    var embed_url = report_details.report_href;

    //console.log('embed_url', embed_url)

    logger.debug("report_details.parameter_mapping", report_details.parameter_mapping, typeof report_details.parameter_mapping);

    if (typeof report_details.parameter_mapping === 'string' && report_details.parameter_mapping != "") {
      report_details.parameter_mapping =JSON.parse(report_details.parameter_mapping);
    }
    let operator_param_name = 'Operator';
    //handle default operator being called different names in different datasets - mapping defined in report config
    if ('parameter_mapping' in report_details && typeof report_details.parameter_mapping == 'object'){
      if("Operator" in report_details.parameter_mapping){
        operator_param_name = report_details.parameter_mapping['Operator'];
      }
    }

    //Override working operator if the operator is set as a URL param
    const params = new URLSearchParams(window.location.search);
    //console.log(params);




/*
    const operator_param = params.get('Operator');
    if(operator_param !== null  && props.operator !== operator_param){
      app_context.updateOperator(operator_param);
    }
*/

    // Apply url params
    let dashboard_params = [];
    params.forEach(function(value, key) {
      var params_to_ignore = ["Operator", "sheets"];
      if(!params_to_ignore.includes(key)){
        let param_key = key;
        if ('parameter_mapping' in report_details && typeof report_details.parameter_mapping == 'object'){
          if(key in report_details.parameter_mapping){
            param_key = report_details.parameter_mapping[key];
          }
        }
        dashboard_params[param_key]=value;
      }

    });

  if (report_details.target == "_blank") {
      window.location.href = report_details.report_href;
      return;
  }
  if (report_details.report_type === 'kibana') {
      logger.debug("kibana report");

      if (params.get('goto')) {
          embed_url = '/_plugin/kibana/goto/' + params.get('goto');
      }
      else {
        if(state.start !== '' && state.end !== ''){
          state.start = state.start.replace(' GMT+0000', 'Z').replace(' ', 'T');
          state.end = state.end.replace(' GMT+0000', 'Z').replace(' ', 'T');
          embed_url = embed_url + "?embed=true&show-query-input=true&show-time-filter=true&_g=(refreshInterval:(pause:!t,value:0),time:(from:'" + state.start + "',to:'" + state.end + "'))";
        }
        else{
          embed_url = embed_url + "?embed=true&show-query-input=true&show-time-filter=true";
        }
      }
      /*
      if (props.operator || dashboard_params) {

        embed_url = embed_url + '&_a=(filters:!(';

        if (typeof report_details.default_operator !== 'undefined') { // set operator to report default
          if (report_details.default_operator !== null) {
              embed_url+= "(meta:(alias:!n,disabled:!f,index:'"+report_details.report_saved_search_id+"',key:'"+operator_param_name+"',negate:!f,params:(query:'"+ report_details.default_operator + "'),type:phrase,value:'"+ report_details.default_operator + "'),query:(match:('" + operator_param_name + "':(query:'"+ report_details.default_operator + "',type:phrase))))";
          }
        }
        else if(props.operator && props.operator !== '---'){
          //embed_url+= '(meta:(alias:!n,disabled:!f,key:' + operator_param_name + ',negate:!f,params:(query:'+ props.operator + '),type:phrase,value:'+ props.operator + '),query:(match:(' + operator_param_name + ':(query:'+ props.operator + ',type:phrase))))';

          embed_url+= "(meta:(alias:!n,disabled:!f,index:'"+report_details.report_saved_search_id+"',key:'"+operator_param_name+"',negate:!f,params:(query:'"+ props.operator + "'),type:phrase,value:'"+ props.operator + "'),query:(match:('" + operator_param_name + "':(query:'"+ props.operator + "',type:phrase))))";
        }

      // Apply url params
       for (var key in dashboard_params) {
          // check if the property/key is defined in the object itself, not in parent
          if (dashboard_params.hasOwnProperty(key)) {
            embed_url+= ",(meta:(alias:!n,disabled:!f,index:'"+report_details.report_saved_search_id+"',key:'"+key+"',negate:!f,params:(query:'"+dashboard_params[key]+"'),type:phrase,value:'"+dashboard_params[key]+"'),query:(match:('"+key+"':(query:'"+dashboard_params[key]+"',type:phrase))))";
          }
       }

        embed_url+= '))';

      }
      */

      logger.debug(embed_url, state);
      logReport(app_context, report_details, embed_url);

      return (
          <iframe onLoad={_iframeLoaded} overflow-y="auto" frameBorder="0" height="100%" width="100%" id="reports" title="dashboard" src={embed_url} ></iframe>
      );


    }
    else if(report_details.report_type === 'php') {
        // Apply url params
       //console.log('DASH PARAMS--->',dashboard_params, report_details.default_operato, props.operator)

       if (typeof report_details.default_operator !== 'undefined') { // set operator to report default
          if (report_details.default_operator !== null) {
              embed_url = embed_url + '?' + operator_param_name + '=' + report_details.default_operator;
          }
       }
       else if (props.operator && props.operator !== '---') {
          embed_url = embed_url + '?' + operator_param_name + '=' + props.operator;
       }
       else{
          embed_url = embed_url + '?';
       }
       for (var param_key in dashboard_params) {
          // check if the property/key is defined in the object itself, not in parent
          if (dashboard_params.hasOwnProperty(param_key)) {
            embed_url += '&' + param_key + '=' + dashboard_params[param_key];
          }
       }
      return (
          <iframe overflow-y="auto" frameBorder="0" height="100%" width="100%" id="reports" title="dashboard" src={embed_url} ></iframe>
      );
    }

    else if(report_details.report_type === 'quicksight') {
      logger.debug("quicksight report1", dashboard_params);

        if(embed_url.includes('getembedurl')) {
          embed_url = '';
          return (<LoadingView embedded={true}/>);
        }

        //Enable Download / Print Mode on embed iframe
        embed_url = embed_url + '&printEnabled=true';




        //quicksight sheets - open specific sheet within a dashboard
        //sheets/7cbc8c2f-75ea-4ea8-8671-5878fd3cefac_d60ef0b2-369c-4d17-be9b-9a699ecb723d
        const sheets_param = params.get('_sheet');
        var url_parts;
        if(sheets_param !== null){
          url_parts = embed_url.split(/\?(.+)/);
          embed_url = url_parts[0] + '/sheets/' + sheets_param + '?' + url_parts[1];
        }
        else if (report_details.default_sheet){
          url_parts = embed_url.split(/\?(.+)/);
          embed_url = url_parts[0] + '/sheets/' + report_details.default_sheet + '?' + url_parts[1];
        }


        //----------------------------------------------------------------------------------------


        //console.log('DEFAULT---->')
        //console.log(report_details.default_operator,props.operator)
        /*
        if (typeof report_details.default_operator !== 'undefined') { // set operator to report default
          if (report_details.default_operator !== null) {
              embed_url = embed_url + '#p.' + operator_param_name + '=' + report_details.default_operator;
          }
        }
        else if (props.operator && props.operator !== '---') {
            embed_url = embed_url + '#';
            if (typeof props.operator === 'string' || props.operator instanceof String){
              var operator_values_split = props.operator.split(',');
              if(operator_values_split.length > 0){
              	operator_values_split.forEach(operator_value => embed_url += '&p.' + operator_param_name + '=' + operator_value);

              }
            }

          //embed_url = embed_url + '#p.' + operator_param_name + '=' + props.operator;
        }
        else{
          embed_url = embed_url + '#';
        }

        logger.debug("dashboard_paramsA", state.start, state.end)

        //console.log('DATES--->',state.start, state.end)
        if(state.start && state.end){
          var startMoment = dateMath.parse(state.start);
          // dateMath.parse is inconsistent with unparsable strings.
          // Sometimes undefined is returned, other times an invalid moment is returned
          if (!startMoment || !startMoment.isValid()) {
             logger.debug('Unable to parse start string');
          }

          // Pass roundUp when parsing end string
          var endMoment = dateMath.parse(state.end, { roundUp: true });
          if (!endMoment || !endMoment.isValid()) {
             logger.debug('Unable to parse end string');
          }


          if(startMoment.isValid() && endMoment.isValid()){
            const start = startMoment.valueOf();
            const end = endMoment.valueOf();
            logger.debug('TIMESTAMPS--->', start, end);
            var date_defaults = {"start": start, "end": end};


            for (var date_key in date_defaults) {
              if (dashboard_params.hasOwnProperty(date_key)===false) {
                //var dash_param_key;
                if ('parameter_mapping' in report_details && typeof report_details.parameter_mapping == 'object'){
                  if(date_key in report_details.parameter_mapping){
                    param_key = report_details.parameter_mapping[date_key];
                  }
                  else{
                    param_key = date_key;
                  }
                }
                else{
                  param_key = date_key;
                }

                if (!(param_key in dashboard_params)){

                }
              }
            }
          }

        }
       logger.debug("dashboard_paramsA1", dashboard_params)
        if (typeof report_details.embeddomain !== 'undefined'){
          var embeddomain;
            if(report_details.embeddomain=="parent"){
              embeddomain = window.location.hostname;
            }
            else{
              embeddomain = report_details.embeddomain;
            }

            if ('parameter_mapping' in report_details && typeof report_details.parameter_mapping == 'object'){
              if('embeddomain' in report_details.parameter_mapping){
                dashboard_params[report_details.parameter_mapping['embeddomain']] = embeddomain;
              }
            }
            else{
               dashboard_params['embeddomain'] = embeddomain;
            }
          }


        logger.debug("dashboard_paramsB", dashboard_params);
*/
       // Apply url params

       //if(Object.keys(dashboard_params).length > 0 && (typeof report_details.default_operator == 'undefined' || props.operator == '---' || typeof props.operator == 'undefined')){
      //   embed_url += '#';
       //}
       /*
        for (var key in dashboard_params) {
          // check if the property/key is defined in the object itself, not in parent
          if (dashboard_params.hasOwnProperty(key)) {


            var param_values = dashboard_params[key];

            if (typeof param_values === 'string' || param_values instanceof String){
              var param_values_split = param_values.split(',');
              if(param_values_split.length > 0){
              	param_values_split.forEach(param_value => embed_url += '&p.' + key + '=' + param_value) ;

              }
            }
            else{
              //embed_url += '&p.' + key + '=' + dashboard_params[key];
            }
          }
      }
      */
    //  report_details['embed_url'] = embed_url;
      logger.debug("render QuickSight");
      return <QuickSight report_details={report_details} embed_url={embed_url} />;
      /*
      return (
          <iframe overflow-y="auto" frameBorder="0" height="100%" width="100%" id="reports" title="dashboard" src={embed_url} ></iframe>
      );
      */

    }
    else{

      return (<LoadingView embedded={true}/>);

    }

  };

  /**
   * UNUSED
   */
   /*
  const _iframeLoaded2 = () => {
    //alert('hello')
    logger.debug("_iframeLoaded");

    if (window.location.origin.indexOf("amazonaws") >= -1) {
      var frame = document.getElementById("reports");

      frame.contentWindow.KibanaUIMenuHack = function(){
          //Display Filter Bar and Date Picker
          var elements = frame.contentDocument.getElementsByClassName('ng-hide');
          elements[0].classList.remove("ng-hide");
          elements[1].classList.remove("ng-hide");

          //Hide Edit, Clone Links etc
          var links = frame.contentDocument.getElementsByClassName("kuiLocalMenuItem");
          links[0].className += " ng-hide";
          links[2].className += " ng-hide";
          links[3].className += " ng-hide";

          // Remove 2nd Filter bar
          var filter_bar = frame.contentDocument.getElementsByClassName("globalFilterGroup");
          filter_bar[1].className += " ng-hide";

          // Remove unwanted links from share function
          var interval = setInterval(function(){
            var saved_object_link = frame.contentDocument.getElementsByClassName("euiRadioGroup__item");
            if(saved_object_link[1]){
              saved_object_link[1].className += " ng-hide";
            }
            //intervals.add(interval);
            //clearInterval(interval);
          }, 500);
      }


      frame.contentWindow.KibanaUIHack = function() {
        //Need to wait until iframe DOM is available - so keep polling
        if(frame.contentDocument.querySelector('.ng-hide')!=null) {
            frame.contentWindow.KibanaUIMenuHack();
        }
        else {
            setTimeout(function() {
                frame.contentWindow.KibanaUIHack();
            }, 500);
        }


      }


      frame.contentWindow.KibanaUIHack();
    }
  }
  */

  const downloadLinks = (index_pattern_id, title) => {
            // return ''; //temporary to hide the links.
            return '<div class="kbnAggTable__controls ng-scope">' +
                '<small i18n-id="visTypeTable.aggTable.exportLabel" i18n-default-message="Export:" class="ng-isolate-scope">Export:</small>&nbsp;&nbsp;' +
                /*
                '<a class="small" ng-click="dataTable.exportAsCsv(false)">' +
                  '<span i18n-id="visTypeTable.aggTable.rawLabel" i18n-default-message="Raw" class="ng-isolate-scope">Raw</span> ' +
                   '<i aria-hidden="true" class="fa fa-download"></i>' +
                '</a>&nbsp;&nbsp;&nbsp;' +
                '<a class="small" ng-click="dataTable.exportAsCsv(true)">' +
                  '<span i18n-id="visTypeTable.aggTable.formattedLabel" i18n-default-message="Formatted" class="ng-isolate-scope">Formatted</span> ' +
                   '<i aria-hidden="true" class="fa fa-download"></i>' +
                '</a>&nbsp;&nbsp;&nbsp;' +
                */
                '<form method="post" action="_plugin/kibana/downloadcsv" class="inline">' +
                  '<input type="hidden" name="lastUrl" value="' + sessionStorage.getItem('lastUrl:/_plugin/kibana:dashboard') + '">' +
                  '<input type="hidden" name="indexPatternID" value="' + index_pattern_id + '">' +
                  '<input type="hidden" name="title" value="' + title + '">' +
                  '<button type="submit" name="submit_param" value="submit_value" class="link-button" style="color: #006DE4;">' +
                    '<span i18n-id="visTypeTable.dataTable.rawLabel" i18n-default-message="Raw" class="ng-isolate-scope">Raw</span> ' +
                    '<i aria-hidden="true" class="fa fa-download"></i>' +
                  '</button>' +
                '</form>' +
              '</div>';
        };

  const shareLink = () => {
      const div = document.createElement('div');
      div.innerHTML = '<div id="rodHackPopoverForAllFilters" class="euiFlexItem euiFlexItem--flexGrowZero globalFilterGroup__branch">' +
          '<div class="euiPopover euiPopover--anchorRightUp euiPopover--withTitle globalFilterGroup__allFiltersPopover" id="popoverForShare">'+
              '<div class="euiPopover__anchor"><button class="euiButtonIcon euiButtonIcon--primary" type="button" aria-label="Share Link" title="Share Link" data-test-subj="exportReport">'+
                  '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490 490" style="enable-background:new 0 0 490 490;" xml:space="preserve" class="euiIcon euiIcon--medium euiIcon-isLoaded euiButtonIcon__icon" focusable="false">' +
                      '<g><path d="M245,490c135.31,0,245-109.69,245-245C490,109.69,380.31,0,245,0S0,109.69,0,245C0,380.31,109.69,490,245,490z M245,30.625 c118.207,0,214.375,96.168,214.375,214.375S363.207,459.375,245,459.375S30.625,363.207,30.625,245S126.793,30.625,245,30.625z"/>' +
                    '<path d="M137.499,304.69c15.674,0,29.948-6.083,40.611-16l78.128,36.678c-0.583,3.341-0.904,6.771-0.904,10.276 c0,32.913,26.782,59.695,59.695,59.695c32.913,0,59.695-26.782,59.695-59.695c0-32.913-26.782-59.695-59.695-59.695 c-18.454,0-34.978,8.422-45.937,21.621l-74.543-34.995c1.717-5.561,2.646-11.464,2.646-17.581c0-4.942-0.615-9.741-1.753-14.337 l75.93-35.646c10.906,11.704,26.437,19.043,43.657,19.043c32.913,0,59.695-26.782,59.695-59.695 c0-32.913-26.782-59.695-59.695-59.695c-32.913,0-59.695,26.782-59.695,59.695c0,4.681,0.559,9.232,1.583,13.606l-76.295,35.817 c-10.876-11.376-26.181-18.484-43.123-18.484c-32.913,0-59.695,26.782-59.695,59.695S104.586,304.69,137.499,304.69z M315.028,306.574c16.03,0,29.07,13.04,29.07,29.07c0,16.03-13.04,29.07-29.07,29.07c-16.03,0-29.07-13.04-29.07-29.07 C285.958,319.614,298.998,306.574,315.028,306.574z M315.028,125.291c16.03,0,29.07,13.039,29.07,29.07 c0,16.03-13.04,29.07-29.07,29.07c-16.03,0-29.07-13.04-29.07-29.07C285.958,138.331,298.998,125.291,315.028,125.291z M137.499,215.925c16.03,0,29.07,13.04,29.07,29.07s-13.039,29.07-29.07,29.07s-29.07-13.04-29.07-29.07 S121.468,215.925,137.499,215.925z"/>' +
                  '</g></svg></button></div></div></div>';
      return div;
  };

  const _iframeLoaded = () => {

    logger.debug("_iframeLoaded", window.location.origin, window.location.origin.indexOf("amazonaws"));

    if (window.location.origin.indexOf("amazonaws") <= -1) {

      var frame = document.getElementById("reports");
      logger.debug("amazonaws", frame);
      var share_button_event_binded = false;
      setInterval(function () {
          var rodHackKuiBarElements = frame.contentWindow.document.querySelectorAll(".kbnDocTable__bar");
          //logger.debug("rodHackKuiBarElements", rodHackKuiBarElements);
          var i;
            for (i = 0; i < rodHackKuiBarElements.length; i++) {
               var parent_id = rodHackKuiBarElements[i].parentElement.parentElement.parentElement.parentElement.previousSibling.getAttribute("data-test-subj");
                var indexPattern = frame.contentWindow.angular.element(rodHackKuiBarElements[i]).scope().indexPattern.id;
                var rodHackKuiBar = frame.contentWindow.document.getElementById("rodHackKuiBar" + indexPattern + parent_id);
                var title = frame.contentWindow.angular.element(rodHackKuiBarElements[i]).parent().parent().parent().scope().sharedItemTitle;
                logger.debug("rodHackKuiBarElements", "addimg",i, rodHackKuiBarElements, indexPattern, parent_id);
                if (!rodHackKuiBar && rodHackKuiBarElements[i]) {
                   logger.debug("rodHackKuiBarElements NOT FOUND", "addimg",i, rodHackKuiBarElements, indexPattern);
                    //frame.contentWindow.angular.element(rodHackKuiBarElements[i])
                    frame.contentWindow.angular.element(rodHackKuiBarElements[i]).prepend('<div id="rodHackKuiBar'+ indexPattern + parent_id + '" style="padding:10px">' + downloadLinks(indexPattern, title) + '</div>');
                }
            }

            var saveQueryPopover = frame.contentWindow.document.getElementById("savedQueryPopover");
            if (saveQueryPopover)
                saveQueryPopover.innerHTML = '<div style="padding:10px">Search</div>';

            var rodHackPopoverForAllFilters = frame.contentWindow.document.getElementById("rodHackPopoverForAllFilters");
            var popoverForAllFilters = frame.contentWindow.document.getElementById("popoverForAllFilters");
            if (rodHackPopoverForAllFilters == null && popoverForAllFilters != null) {
                popoverForAllFilters.parentElement.parentElement.prepend(shareLink());
            }

            //var shorturlbutton =  frame.contentWindow.document.getElementById("rodHackPopoverForAllFilters");
            if (rodHackPopoverForAllFilters != null && !share_button_event_binded) {  // Binds event to the button inject just above.
                console.log('rodHackPopoverForAllFilters');
                share_button_event_binded = true;
                rodHackPopoverForAllFilters.onclick = function() {
                    var last_url = "/app/dashboards"+sessionStorage.getItem('lastUrl:/_plugin/kibana:dashboard');
                    var request_uri =  window.location + '';
                    // strip out ?goto=
                    var index_uri = request_uri.indexOf('?goto=');
                    if (index_uri >= 0)
                        request_uri = request_uri.substring(0, index_uri);
                    // strip out &goto=
                    index_uri = request_uri.indexOf('&goto=');
                    if (index_uri >= 0)
                        request_uri = request_uri.substring(0, index_uri);
                    // check if a query_string already exists.
                    index_uri = request_uri.indexOf('?');
                    var share_url;
                    if (index_uri >= 0) share_url = request_uri + '&goto=';
                    else share_url = request_uri + '?goto=';

                    // see https://googlechrome.github.io/samples/fetch-api/fetch-post.html
                    fetch('/_plugin/kibana/api/shorten_url', {
                        method: 'post',
                        body: '{"url": "'+last_url+'"}'
                      }).then(function(response) {
                        return response.json();
                      }).then(function(data) {
                        prompt("Share this dashboard.\n\n" +
                            "Copy the URL below by pressing CTRL+C or Command+C, or right clicking on the text and selecting Copy.  " +
                            "The URL can then be emailed or shared. All filters and dates are preserved.\n\n" +
                            "Note that if the recipient doesn't have permission to view the report, they will not be able to access the dashboard.",
                            share_url+data.urlId);
                      });

                };
            }


      }, 100);



      /*
      frame.contentWindow.KibanaUIMenuHack = function(){
          logger.debug("KibanaUIMenuHack");



          //Display Filter Bar and Date Picker
          var elements = frame.contentDocument.getElementsByClassName('ng-hide');
          elements[0].classList.remove("ng-hide");
          elements[1].classList.remove("ng-hide");

          //Hide Edit, Clone Links etc
          var links = frame.contentDocument.getElementsByClassName("kuiLocalMenuItem");
          links[0].className += " ng-hide";
          links[2].className += " ng-hide";
          links[3].className += " ng-hide";

          // Remove 2nd Filter bar
          var filter_bar = frame.contentDocument.getElementsByClassName("globalFilterGroup");
          filter_bar[1].className += " ng-hide";

          // Remove unwanted links from share function
          var interval = setInterval(function(){
            var saved_object_link = frame.contentDocument.getElementsByClassName("euiRadioGroup__item");
            if(saved_object_link[1]){
              saved_object_link[1].className += " ng-hide";
            }
            //intervals.add(interval);
            //clearInterval(interval);

          }, 500);
      }
      */

  /*
      frame.contentWindow.KibanaUIHack = function() {
        //Need to wait until iframe DOM is available - so keep polling
        if(frame.contentDocument.querySelector('.ng-hide')!=null) {
            logger.debug("ng-hide found");
            //frame.contentWindow.KibanaUIMenuHack();
        }
        else {
          logger.debug("KibanaUIHack repeat");
            setTimeout(function() {
                frame.contentWindow.KibanaUIHack();
            }, 500);
        }


      }


      frame.contentWindow.KibanaUIHack();

    }
       */
    }
  };

  /**
   * Unused without the date picker
   */
  const refreshReport = (start, end) => {
    logger.debug("refreshReport", start, end);
    setState({
      start: start,
      end: end,
      isLoading: false
    });


  };

  if (report_details) {
    console.log("report_details section", report_details);
    //if (report_details.report_type === "internal") { // because we don't call renderReport

    /*
    if (report_details.report_type === 'internal') {
      console.log("report_details section internal", report_details.href);
      window.location.href = report_details.href;
      return;
    }
    */

    // add internal reports here.
    if (report_details.report_href === "/oooi_diagnostic_tool") {
      logReport(app_context, report_details, report_details.report_href);
      return  <FlightList />;
    }
    else if (report_details.report_href === "/live_beam_status") {
      logger.debug("live beam status");
      logReport(app_context, report_details, report_details.report_href);
      return  <LiveBeamStatus />;
    }
    /*
    else if (report_details.report_href === "/live_flights") {
      logReport(app_context, report_details, report_details.report_href);
      return  <LiveFlights />;
      //}
    }
    */
    else {
      return (

          <View fixedContent={true} title={reportId}>
            { renderReport(state, props) }
          </View>
      );
    }
  }
  else {
    // this is not working.  todo: replace with history
    return (
      <Route path="\">
          <Home />
        </Route>
    );
  }
}

import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography   from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import { Logger } from 'aws-amplify';
const logger = new Logger('DeleteButton');

export default function DeleteButton(props) {
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    logger.debug("handleClickOpen", props);
    if (props.onClick) 
      props.onClick();
    
    setOpen(true);
  };
  
  const handleDelete = () => {
    props.callback();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const getRecordName = () => {
    var results = null;
    if (props.data) {
      props.columnDefs.forEach(function(column, index) {
        if (column.children) {
          column.children.forEach(function(child, child_index) {
            if (child.recordName) {
              results = props.data[child.field];
            }      
          });
        }
      });
    }
    
    return results;
  };
  
  
  var contentText = "Are you sure you want to delete the selected item?";
  var recordName = getRecordName();
  if (recordName) {
    contentText = 'Are you sure you want to delete the selected item "' + recordName + ' "?';
  }
  var button;
  if (props.asMenuItem) {
    button = 
      <MenuItem key="delete" onClick={handleClickOpen} disabled={props.disabled}>
        <ListItemIcon>
            <DeleteIcon />
        </ListItemIcon>
        <Typography variant="inherit">Delete Record</Typography>
     </MenuItem>
  }
  else {
    button = 
      <Tooltip title="Delete Record">
        <IconButton onClick={handleClickOpen} disabled={props.disabled} >
          <DeleteIcon/>
        </IconButton>
      </Tooltip>
  }
  
  return (
    <React.Fragment>
      {button}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Auth, API } from 'aws-amplify';

import { LinkHelper } from 'pac-platform-ui-auth';

//import { CloudBar } from 'pac-ux-components';


const TENANT_MANAGEMENT_API_NAME = 'TenantManagementServiceAPI';

const linkHelper = new LinkHelper();

let portalAppUrls = {
    prd: "https://portal.nextcloud.aero",
    test: "https://portal-test.nextcloud.aero",
    dev: "https://portal-dev.nextcloud.aero",
    cloud9: "https://portal-dev.nextcloud.aero"
};

let stage = typeof process.env.REACT_APP_STAGE !== 'undefined' ? process.env.REACT_APP_STAGE : 'prd';

console.debug('STAGE: ', stage);

let cloudHomeUrl = portalAppUrls[stage];

let profileUrl = [portalAppUrls[stage], 'app/profile'].join('/');

console.debug('PROFILE URL', profileUrl);

let notificationsUrl = [
    portalAppUrls[stage],
    'app/notifications'
].join('/');

let settingsUrl = [
    portalAppUrls[stage],
    'app/settings'
].join('/');


const PlatformCloudbar = ({ cloudHomeUrl, profileUrl, notificationsUrl, settingsUrl }) => {

    const [userSession, setUserSession] = useState({
        idToken: { jwtToken: '' }
    });

    const [user, setUser] = useState({
        attributes: { username: '', given_name: '', family_name: '' }
    });

    const [applications, setApplications] = useState([]);

    const fetchApplications = async() => {
        try {
            let session = await Auth.currentSession();
            setUserSession(session);

            let user = await Auth.currentAuthenticatedUser();
            setUser(user);

            let applications = await fetchUserApplications(session, user);
            setApplications(applications.Items);
        }
        catch (e) {
            console.debug(e)
        }
    };


    useEffect(() => {
        fetchApplications();
    }, []);


    let urlTokenFragment = linkHelper.getTokenFragment(userSession);

    let cloudBarUser = {
        name: [
            user.attributes.given_name,
            user.attributes.family_name
        ].join(' '),
        profileUrl: profileUrl,
        notificationsUrl: notificationsUrl,
        settingsUrl: settingsUrl
    }


    // console.debug('SESSION: ', userSession)
    // console.debug('USER: ', user)
    // console.debug('APPLICATIONS: ', applications)

    return (<CloudbarComponent cloudHomeUrl={cloudHomeUrl} user={cloudBarUser} applications={applications} urlTokenFragment={urlTokenFragment} />);
}

export default PlatformCloudbar;



PlatformCloudbar.propTypes = {
    cloudHomeUrl: PropTypes.string,
    profileUrl: PropTypes.string,
    notificationsUrl: PropTypes.string,
    settingsUrl: PropTypes.string
}

PlatformCloudbar.defaultProps = {
    cloudHomeUrl: cloudHomeUrl,
    profileUrl: profileUrl,
    notificationsUrl: notificationsUrl,
    settingsUrl: settingsUrl
};


const fetchUserApplications = async(userSession, user) => {

    let path = '/users/' + user.username + '/apps';

    let headers = {
        Authorization: userSession.idToken.jwtToken
    };


    let queryStringParameters = Object.assign({}, { offset: 0, limit: 200 });

    let options = { headers: headers, queryStringParameters: queryStringParameters };

    try {
        return await API.get(TENANT_MANAGEMENT_API_NAME, path, options);
    }
    catch (e) {
        console.error(e);
    }

};



export const CloudbarComponent = ({ cloudHomeUrl, user, applications, urlTokenFragment }) => {


    let prodApplications = filterTools(applications, 'prod');

    let lastUsedTools = prodApplications.map((app) => {
        return {
            name: app.name,
            icon: getResizedImageUrl(app),
            path: app['app-link'] + urlTokenFragment
        };
    });


    let devApplications = filterTools(applications, 'dev');

    let otherTools = devApplications.map((app) => {
        return {
            name: app.name,
            icon: getResizedImageUrl(app),
            path: app['app-link'] + urlTokenFragment
        };
    });


    return (
        <div> CloudBar 
            cloudHomeUrl={cloudHomeUrl}
            user={user} 
            notificationCount={0}
            lastUsedTools={lastUsedTools}
            otherTools={otherTools}  
        </div>
    );


}


const filterTools = (allApplications, appType) => {
    let applications = allApplications.filter(item => item['app-type'] === appType);
    return applications.slice(0, 3);
};



export const getResizedImageUrl = (app) => {

    const logoUrl = app['logo-url'];
    
    // hack to fix the image issue
    if (logoUrl.substr(-11) == 'origins.png') {
        return '/origins.png';
    }
    if (logoUrl.substr(-11) == 'dart-ce.png') {
        return '/dart-ce.png';
    }
    const extension = logoUrl.substr(-3);

    if (extension === 'svg') {
        return logoUrl;
    }

    let request = {
        "bucket": "pac-platform-images-us-west-2",
        "key": logoUrl,
        "edits": {
            "resize": {
                "width": 45,
                "height": 45,
                "fit": "cover"
            }
        }
    }

    const str = JSON.stringify(request);
    const encoded = window.btoa(str);

    return ['https://d2g0vtxljsrm9e.cloudfront.net', encoded].join('/');

}


CloudbarComponent.propTypes = {
    cloudHomeUrl: PropTypes.string,
    user: PropTypes.shape({
        attributes: PropTypes.shape({
            username: PropTypes.string.isRequired,
            given_name: PropTypes.string.isRequired,
            family_name: PropTypes.string.isRequired,
        })
    }),
    applications: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            "app-type": PropTypes.string,
            "app-link": PropTypes.string,
            "logo-url": PropTypes.string,
        })
    ).isRequired,
    urlTokenFragment: PropTypes.string
}

/**
 * This is the feedback form for users to submit feedback.
 */ 
import React from 'react';
import { Logger } from 'aws-amplify';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';
import View           from 'pac-responsive-ui-framework/core/View';

const logger = new Logger('Feedback');

export default function Home() {
   
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
  }));
  
  const classes = useStyles();

  return (
    <View title="Feedback">
      <Paper className={classes.paper}>
        <iframe overflow-y="auto" frameBorder="0" width="100%" height= "1700px" id="feedback" title="feedback form" frameborder= "0" marginwidth= "0" marginheight= "0" src="https://forms.office.com/Pages/ResponsePage.aspx?id=R7K7EN8I00qlXke-dpOx0VsQcgigTDBJoNmAa4TeNnxUOUFQRThURFpKOVhFTlpFMUdOQ0lMNjRDSi4u&embed=true" >
        </iframe> 
      </Paper>
    </View>
  );
  
}
var jwtDecode = require('jwt-decode');
var parse = require('url-parse');
const querystring = require('querystring');

const prefix = 'pac';
const idTokenKey = [prefix, 'idToken'].join('.');

class PacIdToken {


    getIdToken(encoded=false) {

        let fragment = this.getParsedUrlFragment();

        if (typeof fragment['id_token'] !== 'undefined') {
            var unencoded = fragment['id_token'];
            this.setEncodedIdTokenToStore(unencoded);
            const decoded = jwtDecode(unencoded);
            this.setIdTokenToStore(decoded);
            if (!encoded)
                return decoded;
            else
                return unencoded;
        }
        else {
            if (!encoded)
                return this.getIdTokenFromStore();
            else
                return this.getEncodedIdTokenFromStore();
        }
    }
    
    setEncodedIdTokenToStore(encoded) {
        window.localStorage.setItem(idTokenKey+'_enc', encoded);
    }

    setIdTokenToStore(decoded) {
        window.localStorage.setItem(idTokenKey, JSON.stringify(decoded));
    }

    getIdTokenFromStore() {
        let idToken = window.localStorage.getItem(idTokenKey);
        return JSON.parse(idToken);
    }
    
    getEncodedIdTokenFromStore() {
        return window.localStorage.getItem(idTokenKey+'_enc');
    }


    getRefreshToken() {

        let fragment = this.getParsedUrlFragment();

        if (typeof fragment['refresh_token'] !== 'undefined') {
            const token = fragment['refresh_token'];
            this.setRefreshTokenToStore(token);
            return token;
        }
        else {
            return this.getRefreshTokenFromStore();
        }
    }

    getRefreshTokenKey() {

        let idToken = this.getIdToken();

        const keyPrefix = ['pac',
            idToken.pac_app_client_id,
            idToken['cognito:username']
        ].join('.');
        return `${keyPrefix}.refreshToken`;
    }

    setRefreshTokenToStore(token) {
        window.localStorage.setItem(this.getRefreshTokenKey(), token);
    }

    getRefreshTokenFromStore() {
        return window.localStorage.getItem(this.getRefreshTokenKey());
    }
    
    
    getAccessToken() {

        let fragment = this.getParsedUrlFragment();

        if (typeof fragment['access_token'] !== 'undefined') {
            const token = fragment['access_token'];
            this.setRefreshTokenToStore(token);
            return token;
        }
        else {
            return this.getAccessTokenFromStore();
        }
    }

    getAccessTokenKey() {

        let idToken = this.getIdToken();

        const keyPrefix = ['pac',
            idToken.pac_app_client_id,
            idToken['cognito:username']
        ].join('.');
        return `${keyPrefix}.accessToken`;
    }

    setAccessTokenToStore(token) {
        window.localStorage.setItem(this.getAccessTokenKey(), token);
    }

    getAccessTokenFromStore() {
        return window.localStorage.getItem(this.getAccessTokenKey());
    }
    
    getParsedUrlFragment() {
        const url = parse(window.location.href, true);
        let cleanUrlHash = this.stripLeadingHash(url.hash);
        return querystring.parse(cleanUrlHash);
    }

    stripLeadingHash(urlFragment) {

        if (urlFragment.substring(0, 1) === '#') {
            return urlFragment.substring(1);
        }
        else {
            return urlFragment;
        }
    }
    
     copyAccessTokenToLocalSessionStorage(user) {

        console.log('COPYING ACCESS TOKEN');
        console.log('USER: ', user);

        let userPool = user.pool;
        console.log('USER POOL: ', userPool);

        let storage = user.storage;
        console.log('STORAGE: ', storage);

        let username = user.username;
        console.log('USERNAME : ', user.username);

        const pacKeyPrefix = [
            'pac',
            userPool.getClientId(),
            username
        ].join('.');

        const pacAccessTokenKey = `${pacKeyPrefix}.accessToken`;
        console.log('PAC ACCESS TOKEN KEY : ', pacAccessTokenKey);

        let pacAccessToken = window.localStorage.getItem(pacAccessTokenKey);
        console.log('PAC ACCESS TOKEN : ', pacAccessToken);


        const keyPrefix = [
            'CognitoIdentityServiceProvider',
            userPool.getClientId(),
            username
        ].join('.');
        const AccessTokenKey = `${keyPrefix}.accessToken`;

        console.log('COOKIE ACCESS TOKEN KEY : ', AccessTokenKey);

        storage.setItem(AccessTokenKey, pacAccessToken);

    }    
    
     copyAccessTokenToCookieStorage(user) {

        console.log('COPYING ACCESS TOKEN');
        console.log('USER: ', user);

        let userPool = user.pool;
        console.log('USER POOL: ', userPool);

        //let storage = user.storage;
        //console.log('STORAGE: ', storage);

        let username = user.username;
        console.log('USERNAME : ', user.username);


        const pacKeyPrefix = [
            'CognitoIdentityServiceProvider',
            userPool.getClientId(),
            username
        ].join('.');
        

        const pacAccessTokenKey = `${pacKeyPrefix}.accessToken`;
        console.log('PAC ACCESSS TOKEN KEY : ', pacAccessTokenKey);

        let pacAccessToken = window.localStorage.getItem(pacAccessTokenKey);
        console.log('PAC ACCESSS TOKEN : ', pacAccessToken);


        const keyPrefix = [
            'CognitoIdentityServiceProvider',
            userPool.getClientId(),
            encodeURIComponent(username)
        ].join('.');
        const AccessTokenKey = `${keyPrefix}.accessToken`;

        console.log('COOKIE ACCESS TOKEN KEY : ', AccessTokenKey);

        //storage.setItem(IdTokenKey, pacIdToken);
        console.log('CREATING COOKIE : ', AccessTokenKey, pacAccessToken);
        
        this.createCookie(AccessTokenKey,pacAccessToken,1);

    }    

    copyRefreshTokenToLocalSessionStorage(user) {

        console.log('COPYING REFRESH TOKEN');
        console.log('USER: ', user);

        let userPool = user.pool;
        console.log('USER POOL: ', userPool);

        let storage = user.storage;
        console.log('STORAGE: ', storage);

        let username = user.username;
        console.log('USERNAME : ', user.username);

        const pacKeyPrefix = [
            'pac',
            userPool.getClientId(),
            username
        ].join('.');

        const pacRefreshTokenKey = `${pacKeyPrefix}.refreshToken`;
        console.log('PAC REFRESH TOKEN KEY : ', pacRefreshTokenKey);

        let pacRefreshToken = window.localStorage.getItem(pacRefreshTokenKey);
        console.log('PAC REFRESH TOKEN : ', pacRefreshToken);


        const keyPrefix = [
            'CognitoIdentityServiceProvider',
            userPool.getClientId(),
            username
        ].join('.');
        const refreshTokenKey = `${keyPrefix}.refreshToken`;

        console.log('COOKIE REFRESH TOKEN KEY : ', refreshTokenKey);

        storage.setItem(refreshTokenKey, pacRefreshToken);

    }
    
    // unused
    copyIDTokenToLocalSessionStorage(user) {

        console.log('COPYING ID TOKEN');
        console.log('USER: ', user);

        let userPool = user.pool;
        console.log('USER POOL: ', userPool);

        let storage = user.storage;
        console.log('STORAGE: ', storage);

        let username = user.username;
        console.log('USERNAME : ', user.username);

        const pacKeyPrefix = [
            'pac',
            userPool.getClientId(),
            username
        ].join('.');

        const pacIdTokenKey = `${pacKeyPrefix}.idToken`;
        console.log('PAC ID TOKEN KEY : ', pacIdTokenKey);

        let pacIdToken = window.localStorage.getItem(pacIdTokenKey);
        console.log('PAC ID TOKEN : ', pacIdToken);


        const keyPrefix = [
            'CognitoIdentityServiceProvider',
            userPool.getClientId(),
            username
        ].join('.');
        const IdTokenKey = `${keyPrefix}.idToken`;

        console.log('COOKIE ID TOKEN KEY : ', IdTokenKey);

        storage.setItem(IdTokenKey, pacIdToken);

    }    
    
    copyIDTokenToCookieStorage(user) {

        console.log('COPYING ID TOKEN');
        console.log('USER: ', user);

        let userPool = user.pool;
        console.log('USER POOL: ', userPool);

        //let storage = user.storage;
        //console.log('STORAGE: ', storage);

        let username = user.username;
        console.log('USERNAME : ', user.username);


        const pacKeyPrefix = [
            'CognitoIdentityServiceProvider',
            userPool.getClientId(),
            username
        ].join('.');
        

        const pacIdTokenKey = `${pacKeyPrefix}.idToken`;
        console.log('PAC ID TOKEN KEY : ', pacIdTokenKey);

        let pacIdToken = window.localStorage.getItem(pacIdTokenKey);
        console.log('PAC ID TOKEN : ', pacIdToken);


        const keyPrefix = [
            'CognitoIdentityServiceProvider',
            userPool.getClientId(),
            encodeURIComponent(username)
        ].join('.');
        const IdTokenKey = `${keyPrefix}.idToken`;

        console.log('COOKIE ID TOKEN KEY : ', IdTokenKey);

        //storage.setItem(IdTokenKey, pacIdToken);
        console.log('CREATING COOKIE : ', IdTokenKey, pacIdToken);
        
        this.createCookie(IdTokenKey,pacIdToken,1);

    }    
    
    
    
    createCookie(name,value,days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 *1000));
            var expires = "; expires=" + date.toUTCString();
        } else {
            var expires = "";
        }
        document.cookie = name + "=" + value + expires + "; path=/; Domain=.nextcloud.aero";
    }
    




}

export default PacIdToken;

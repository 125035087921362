import React, { useContext }  from 'react';
import { makeStyles }         from '@material-ui/styles';
import { Logger }             from 'aws-amplify';
import List         from '@material-ui/core/List';
import ListItem     from '@material-ui/core/ListItem';
import Collapse     from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import Typography from '@material-ui/core/Typography';

import ExpandLess   from '@material-ui/icons/ArrowDropUp';
import ExpandMore   from '@material-ui/icons/ArrowDropDown';

//import AppContext   from './AppContext';

const logger = new Logger('shared/Menu');

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  text: {
    
    fontFamily: "Inter, sans-serif",
    fontSize: "14px"
  }
}));

export default function NavigationMenu(props) {
  
  const classes = useStyles();
   
  const [open, setOpen] = React.useState(props.open);
  
  const handleClick = () => {
    setOpen(!open);
    if (props.onClick) {
      setTimeout(function(){ props.onClick(props.text, !open) }, 500);
    }
  };
  
  return (
    <React.Fragment>
      <ListItem button onClick={handleClick} dense={true} >
        <ListItemIcon>
          {props.icon}
        </ListItemIcon>
        <ListItemText primary={props.text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" >
        <List component="div" disablePadding className={classes.nested} dense={true}>
        {props.children}
        </List>
      </Collapse>
    </React.Fragment>
  );
    
     
}
import { Auth, Logger } from 'aws-amplify';
import BaseService from './BaseService';
import config from '../config/config.js';
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from "@aws-sdk/client-secrets-manager";

const logger = new Logger('OauthService');
var ClientOAuth2 = require('client-oauth2');

class OauthService extends BaseService {

    constructor(apiName, collectionPath) {
        super(apiName, collectionPath);
    }
    
    async getAuthorizationHeader1() {
        logger.debug("getAuthorizationHeader fetchRecords", this.getEndPoint().OAuth2);
        var githubAuth = new ClientOAuth2(this.getEndPoint().OAuth2);
        
        
        var token_data = await githubAuth.credentials.getToken()
          .catch(error => {
              logger.debug("getAuthorizationHeader fetchRecords error", error);
          });
          
        return token_data.accessToken;
    }
    
    //403
    async getAuthorizationHeader() {
        logger.debug("getAuthorizationHeader fetchRecords");
        try {
            let credentials = await Auth.currentCredentials();
            logger.debug("getAuthorizationHeader credentials", credentials);
        
            const client = new SecretsManagerClient({ region: "us-west-2", credentials });
    
            try {
                logger.debug("getAuthorizationHeader SecretId:", this.getEndPoint().SecretId);
                
                let response = await client.send(new GetSecretValueCommand( {
                    "SecretId": this.getEndPoint().SecretId //"origins-operational-api-m2m-credentials"
                }));
                logger.debug("getAuthorizationHeader SECRETS:", response);
                const secretValue = JSON.parse(response.SecretString);
                logger.debug("getAuthorizationHeader fetchRecords secretValue:", secretValue);
                var oauth_params = {
                    clientId: secretValue['client_id'],
                    clientSecret: secretValue['client_secret'],
                    accessTokenUri: secretValue['token_url'],
                    //authorizationUri: secretValue['token_url'],
                    redirectUri: secretValue['redirect_uri'],
                    scopes: [secretValue['scope']]
                };

                try {
                    var githubAuth = new ClientOAuth2(oauth_params);

                    var token_obj =  await githubAuth.credentials.getToken();
                    logger.debug("getAuthorizationHeader token_obj", token_obj);
                    return token_obj.accessToken;
                    //logger.debug("user", user) //=> { accessToken: '...', tokenType: 'bearer', ... }
                    //var token = user.accessToken;
                    //logger.debug("token fetchRecords token:", token);
                    //return token;
                }
                catch (error) {    
                    logger.debug("getAuthorizationHeader fetchRecords TOKEN ERROR:", error);
                };
            }
            catch (error) {    
                logger.debug("getAuthorizationHeader fetchRecords secrets ERROR:", error);
            };
        }
        catch (error) {    
            logger.debug("getAuthorizationHeader fetchRecords credntials ERROR:", error);
        };
    }
    
    getEndPoint() {
        
        var endpoint;
        var ep;
        config.API.endpoints.forEach(ep => {
            logger.debug("fetchRecords", ep, this.apiName);
            if (ep.name == this.apiName) {
                endpoint = ep;
            }
        });
        logger.debug("getEndPoint", endpoint)
        return endpoint;
    }

}

export default OauthService;
